import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { CandidatoService } from 'src/app/services/candidato.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import * as _ from "lodash";
import { GoogleService } from 'src/app/services/google.service';
import { Gps } from 'src/app/models/interface/Gps.interface';
@Component({
  selector: 'app-crear-candidato',
  templateUrl: './crear-candidato.component.html',
  styleUrls: ['./crear-candidato.component.scss']
})
export class CrearCandidatoComponent implements OnInit {

  public aspirante: any = {};
  profileUrl: Observable<String | null>;
  public origin: Gps = {lat: 4.60971, lng: -74.08175};
  public originDefault: Gps = {lat: 4.60971, lng: -74.08175};
  constructor(public _location: Location,
    private candidatoService: CandidatoService,
    public activatedRoute: ActivatedRoute,
    private storage: AngularFireStorage,
    private googleService: GoogleService
    ) {}

  async ngOnInit() {
    this.aspirante.id = this.activatedRoute.snapshot.params['id'];
    this.aspirante = ( await this.candidatoService.getCandidato(this.aspirante.id)).data()
    console.log(this.aspirante); 
  }
  onSelectedPlace(place){
    console.log({onSelectedPlace: place})
    this.googleService.getGpsPlace(place.description);
  }
  clearPlace(){

  }
  onFocusedPlace(){

  }
  onSearchPlace(term) {
    //console.log(`Bogotá, ${term}, Colombia `)
    //this.serachPlace = `${term}, Bogota, Colombia `;
    if (term === '') { return; }
    this.googleService.getPlacePredictions(`Bogotá ${term}, Colombia `);
  }
  
}
