// const preciosServicios = [
// 	{
// 		"name": "Unitario",
// 		"code": 1,
// 		'horas': [
// 			{
// 				'name': '2',
// 				'value': 2,
// 				"precio": {
// 					"valor": 64900,
// 					"gananciaServer": 34000
// 				}
// 			},
// 			{
// 				'name': '4',
// 				'value': 4,
// 				"precio": {
// 					"valor": 79900,
// 					"gananciaServer": 46000
// 				}
// 			},
// 			{
// 				'name': '6',
// 				'value': 6,
// 				"precio": {
// 					"valor": 94900,
// 					"gananciaServer": 58000
// 				}
// 			},
// 			{
// 				'name': '8',
// 				'value': 8,
// 				"precio": {
// 					"valor": 109900,
// 					"gananciaServer": 70000
// 				}
// 			},
// 		]
// 	},
// 	{
// 		"name": "Plan",
// 		"code": 1,
// 		'horas': [
// 			{
// 				'name': '2',
// 				'value': 2,
// 				"precio": {
// 					"valor": 64900,
// 					"gananciaServer": 34000
// 				}
// 			},
// 			{
// 				'name': '4',
// 				'value': 4,
// 				"precio": {
// 					"valor": 76900,
// 					"gananciaServer": 46000
// 				}
// 			},
// 			{
// 				'name': '6',
// 				'value': 6,
// 				"precio": {
// 					"valor": 89900,
// 					"gananciaServer": 58000
// 				}
// 			},
// 			{
// 				'name': '8',
// 				'value': 8,
// 				"precio": {
// 					"valor": 99900,
// 					"gananciaServer": 70000
// 				}
// 			},
// 		]
// 	},
// ]
const preciosServicios = [
	{
		"name": "Administrativo",
		"code": 1,
		"horas": [
			{
				"name": "4",
				"value": 4,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 67900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 62900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 69900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 69900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 74900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 69900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 77900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 72900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 69900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 34000
								}
							}
						]

					}
				]
			},
		]
	},
	{
		"name": "Unitario",
		"code": 1,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]
					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 79900,
									"gananciaServer": 46000
								}
							}
						]
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]
					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 94900,
									"gananciaServer": 58000
								}
							}
						]
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 109900,
									"gananciaServer": 70000
								}
							}
						]
					}
				]
			},
		]
	},
	{
		"name": "1 por semana",
		"code": 2,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]
					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
							"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 64900,
									"gananciaServer": 34000
								}
							}
						]

					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 76900,
									"gananciaServer": 46000
								}
							}
						]
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]
					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]

					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 89900,
									"gananciaServer": 58000
								}
							}
						]
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"dia": [
					{
						"name": "lunes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "martes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "miercoles",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "jueves",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "viernes",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "sabado",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					},
					{
						"name": "domingo",
						"horario": [
							{
								"name": "mañana",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							},
							{
								"name": "tarde",
								"precio": {
									"valor": 99900,
									"gananciaServer": 70000
								}
							}
						]
					}
				]
			},
		]
	},
	{
		"name": "2 por semana",
		"code": 3,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						},
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						}
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					}
				]
			},
		]
	},
	{
		"name": "3 por semana",
		"code": 4,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						},
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						}
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					}
				]
			},
		]
	},
	{
		"name": "4 por semana",
		"code": 5,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						},
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						}
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					}
				]
			},
		]
	},
	{
		"name": "5 por semana",
		"code": 6,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						},
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						}
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					}
				]
			},
		]
	},
	{
		"name": "6 por semana",
		"code": 7,
		"horas": [
			{
				"name": "2",
				"value": 2,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "3",
				"value": 3,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 64900,
							"gananciaServer": 34000
						}
					}
				]
			},
			{
				"name": "4",
				"value": 4,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 76900,
							"gananciaServer": 46000
						}
					}
				]
			},
			{
				"name": "6",
				"value": 6,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						},
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 89900,
							"gananciaServer": 58000
						}
					}
				]
			},
			{
				"name": "8",
				"value": 8,
				"horario": [
					{
						"name": "mañana",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					},
					{
						"name": "tarde",
						"precio": {
							"valor": 99900,
							"gananciaServer": 70000
						}
					}
				]
			},
		]
	}
]

const preciosDesinfeccion = [
	{
		"name": "Unitario",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 75000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 85000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 95000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 133000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 215000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 405000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 790000,
				"gananciaServer": 73000
			},

		]
	},
	{
		"name": "4porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 180000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 270000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 540000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 485000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 795000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 1390000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 2860000,
				"gananciaServer": 73000
			},

		]
	},
	{
		"name": "8porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 360000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 533000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 705000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 970000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 1590000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 2770000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 5720000,
				"gananciaServer": 73000
			},

		]
	},
	{
		"name": "12porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 540000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 795000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 1056000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 1458000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 2380000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 4150000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 8000000,
				"gananciaServer": 73000
			},

		]
	},
	{
		"name": "16porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 704000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 970000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 1320000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 1850000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 2820000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 5533000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 10000000,
				"gananciaServer": 73000
			},

		]
	},
	{
		"name": "20porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 880000
			},
			{
				"name": "101a200",
				"precio": 1210000
			},
			{
				"name": "201a300",
				"precio": 1700000
			},
			{
				"name": "301a500",
				"precio": 2310000
			},
			{
				"name": "501a1000",
				"precio": 3520000
			},
			{
				"name": "1001a2000",
				"precio": 6920000
			},
			{
				"name": "2001a5000",
				"precio": 13300000
			},

		]
	},
	{
		"name": "24porMes",
		code: 1,
		"area": [
			{
				"name": "0a100",
				"precio": 1060000,
				"gananciaServer": 28000
			},
			{
				"name": "101a200",
				"precio": 1458000,
				"gananciaServer": 28000
			},
			{
				"name": "201a300",
				"precio": 1980000,
				"gananciaServer": 28000
			},
			{
				"name": "301a500",
				"precio": 2770000,
				"gananciaServer": 43000
			},
			{
				"name": "501a1000",
				"precio": 4425000,
				"gananciaServer": 53000
			},
			{
				"name": "1001a2000",
				"precio": 8295000,
				"gananciaServer": 63000
			},
			{
				"name": "2001a5000",
				"precio": 16000000,
				"gananciaServer": 73000
			},

		]
	},
]

const preciosMuebles = [
	{
		"name": "Sofa 1 puesto",
		"value": "sofa1Puesto",
		"precio": 59900,
		"gananciaServer": 18000
	},
	{
		"name": "Sofa 2 puestos",
		"value": "sofa2Puestos",
		"precio": 79900,
		"gananciaServer": 24000
	},
	{
		"name": "Sofa 3 puestos",
		"value": "sofa3Puestos",
		"precio": 99900,
		"gananciaServer": 33000
	},
	{
		"name": "Siila solo asiento",
		"value": "siilaAsiento",
		"precio": 9900,
		"gananciaServer": 4500
	},
	{
		"name": "Silla espalda Y asiento",
		"value": "sillaCompleta",
		"precio": 14900,
		"gananciaServer": 3000
	},
]

const preciosColchones = [
	{
		"name": "Individual o sencillo",
		"value": "sencillo",
		"precio": 59900,
		"gananciaServer": 18000
	},
	{
		"name": "Doble",
		"value": "doble",
		"precio": 79900,
		"gananciaServer": 24000
	},
	{
		"name": "Queen size",
		"value": "queenSize",
		"precio": 99900,
		"gananciaServer": 33000
	},
	{
		"name": "King size",
		"value": "kingSize",
		"precio": 119900,
		"gananciaServer": 38000
	},
	{
		"name": "Presidencial",
		"value": "presidencial",
		"precio": 139900,
		"gananciaServer": 42000
	},
	{
		"name": "californoa king",
		"value": "californoaKing",
		"precio": 149900,
		"gananciaServer": 42000
	},
]

const productosServers = [
	{
		name: "Cofia",
		precio: 10000,
		cantidad: 0,
	},
	{
		name: "Overol",
		precio: 80000,
		cantidad: 0,
	},
	{
		name: "Zapatos",
		precio: 50000,
		cantidad: 0,
	},
	{
		name: "Uniforme",
		precio: 50000,
		cantidad: 0,
	},
]
export {
	preciosMuebles,
	productosServers,
	preciosServicios,
	preciosColchones,
	preciosDesinfeccion,
}
