import * as moment from 'moment';
import * as firebase from 'firebase'; 
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})

export class uniservService {

  constructor(){}

//   /* CRUD aspirantes */
//   public post(metrica) {
//     return firebase.firestore().doc('infoUniServ/' + metrica.id).set(metrica);
//   }
  public getAllInfo(){
    return firebase.firestore().collection('infoUniServ').get();
  }

  public getInfoServerId(id){
    return firebase.firestore().collection('infoUniServ')
    .where("id","==",id)
    .get();
  }

  public guardarCurrent(info){
    return firebase.firestore().doc('infoUniServ/'+info.id).set(Object.assign({}, info));
  }
}