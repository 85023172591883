import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UsuarioService } from '../../services/usuario.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { User } from 'src/app/models/User.model';
import { LocalDataSource } from 'ng2-smart-table';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { localidadesColombia} from "src/app/utility/country";
import { DepartmentsInterface } from 'src/app/models/interface/DepartmentsInterface';
import { CityInterface } from 'src/app/models/interface/CityInterface';
import { Suburbsinterface } from 'src/app/models/interface/Suburbsinterface';
import { Neighborhoodinterface } from 'src/app/models/interface/Neighborhoodinterface';
import { LocationService } from 'src/app/services/location.service';
import { PushNotificationService } from '../../services/push-notification.service';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('mdlCrear', { static: true }) mdlCrear: ElementRef;
  @ViewChild('mdlEdit', { static: true }) mdlEdit: ElementRef;

  displayedUser = {};
  mdlViewUser = false;

  usuarioActivo : any ={};
  frmUsuario: FormGroup;
  submitted = false;
  spinerGuardar = false;
  frmUsuarioEdit: FormGroup;
  submitedEdit = false;
  submitForm = false;
  spinerEdit = false;
  datosEdit = {
    id: '', name: '', apellido: '', email: '', telefono: '', documento: '', direccion: '', rol: '', fecha: '',
  };

  settings = {
    actions: { 
      delete: false,     
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: 'detalle', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      name: { title: 'Nombre' },
      apellido: { title: 'Apellido' },
      rol: { title: 'Rol' },
      email: { title: 'Email' },
      telefono: { title: 'Teléfono' },
      documento: { title: 'Documento' },
      direccion: { title: 'Dirección' },
      cities: {title: 'Ciudad'},
      /*
      fecha: {
        title: 'Fecha de creación', type: 'string',
        valuePrepareFunction: (fec) => {
          return moment(fec).format('DD/MM/YYYY hh:mm:ss') == 'Invalid date' ? 'N/A' : moment(fec).format('DD/MM/YYYY hh:mm:ss');
        }
      },*/
    }
  };

  public usuarios: any[];
  public usuarioExcel: any = [];
  public source: LocalDataSource;
  public localidades: any = localidadesColombia;
  public locationDeparments:  Array<DepartmentsInterface> = [];
  public locationCities: Array<CityInterface> = [];
  public locationSuburbs: Array<Suburbsinterface> = [];
  public locationNeighborhood: Array<Neighborhoodinterface> = [];
  public idLocation: string = '';
  public dataUsuariosServAdmi = [];
  public usuarioEnSesion : any = {
    rol: ''
  };


  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    public fb: FormBuilder,
    private notification: PushNotificationService,
    public usuarioService: UsuarioService,
    public authService: AuthService,
    public locationService: LocationService,
    private rutaActiva: ActivatedRoute
  ) {
    this.usuarioEnSesion = this.authService.storeGetUserData('usuario')
    console.log("este es el usuario en sesion",this.usuarioEnSesion)
    notification.requestPermission().then(token =>{
      console.log("tokken",token)
      this.usuarioEnSesion.token = token
      this.authService.tokenUsuarios(this.usuarioEnSesion).catch(err=>{
        console.log("ocurrio un error al gurdar el token",err)
      });
      console.log(token);
    }).catch(err=>{
      console.log("ocurrio un error al generar el tokken",err);
    })
    this.frmUsuario = this.fb.group({
      rol:          ['', [Validators.required]],
      name:         ['', [Validators.required]],
      email:        ['', [Validators.required, Validators.email]],
      clave:        ['', [Validators.required, Validators.minLength(6)]],
      cities:       ['', [Validators.required]],
      country:      ['', [Validators.required]],
      suburbs:      ['', [Validators.required]],
      apellido:     ['', [Validators.required]],
      telefono:     ['', [Validators.required]],
      documento:    ['', [Validators.required]],
      direccion:    ['', [Validators.required]],
      department:   ['', [Validators.required]],
      idLocation:   ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      fechaCumple:  ['', [Validators.required]]
    });
    this.frmUsuarioEdit = this.fb.group({
      id:         ['', []], 
      fecha:      [, []],
      nombreE:    ['', [Validators.required]],
      celularE:   ['', [Validators.required]],
      perfilE:    ['', [Validators.required]],
      apellidoE:  ['', [Validators.required]],
      documentoE: ['', [Validators.required]],
      direccionE: ['', [Validators.required]],
      emailE:     ['', [Validators.required, Validators.email]],
    });
  }
  messageReceived = '';

  ngOnInit() {
    this.notification.receiveMessage().subscribe(payload =>{
      this.notis(payload.notification.title, payload.notification.body, 'top-right', 'basic', '90000')
      this.messageReceived = payload.notification.title;
    })
    this.getUsuariosUnicaVez();
    this.usuarioActivo = this.authService.storeGetUserData('usuario');
    console.log(this.usuarioActivo)

  }

  public notis(body, title, position, status, duration) {
    this.toastrService.show(
      title,
      body,
      { position, status, duration }

    );
  }

  async getUsuariosUnicaVez() {
    let preInfo: any = [];
    this.authService.getUsuarioAdmin().then((item) => {
      item.docs.forEach(data => {
        const usuario = data.data();
        this.dataUsuariosServAdmi
        preInfo.push(usuario);
        if(item.docs.length == preInfo.length){
          this.dataUsuariosServAdmi = _.orderBy(preInfo, ['name'], ['desc']);
        }
      });
    });
  }

  CrearUsuario() {
    // @ts-ignore
    this.dialogService.open(this.mdlCrear, { context: 'this is some additional data passed to dialog' });
  }

  selectLocation(event, type){
    const name: string = event.target.value;
    
    
    setTimeout(()=>{
      let filter = null;
      if(type =='departments'){
        filter = this.localidades.filter((countries)=> countries.name == name)[0];
        this.locationDeparments = filter[type];

         
      }else if(type =='cities'){
        filter = this.locationDeparments.filter((departments)=> departments.name == name)[0];
        this.locationCities = filter[type];
        
      }else if (type =='suburbs'){
        filter = this.locationCities.filter((cities)=> cities.name == name)[0];
        this.locationSuburbs = filter[type]; 
        this.locationService.getLocationByCity(name).then(res =>{
          const { docs } = res;
          if(docs.length > 0){
            const { id } = docs[0].data();
            this.idLocation = id;

          }else{
            console.error('not data');
          }
          
        })
        
      }else if (type =='neighborhood'){
        filter = this.locationSuburbs.filter((neighborhood)=> neighborhood.name == name)[0];
        this.locationNeighborhood = filter[type];
      }

      // console.log({filter: filter[type]});
    }, 2000);
    
  }
  activeError(form: any, property: string, valid: string = ''){
    try {
      const element = form[property];
      const value = !valid 
        ? (typeof element.errors !== 'undefined' && element.errors)
        : (typeof element.errors[valid] !== 'undefined' && element.errors[valid]);
        /*console.log({
          value,
          valid,
          error: element.errors,
          statu: element.errors[valid],
          form: this.submitForm
        });*/
        
      return (this.submitForm && value);
    } catch (error) {
      return false;
    }
    
  }

  get valoresCrear() {
    return this.frmUsuario.controls;
  }
  get valoresEditar() {
    return this.frmUsuarioEdit.controls;
  }

  //Creación de un usuario
  guardarUsuario() {
    this.submitted = true;
    if(this.frmUsuario.get('email').invalid){
      return false;
    } 
    
    this.spinerGuardar = true;
    const datosUsuario = this.frmUsuario.value;
    datosUsuario.estado = "activo";
    datosUsuario.fecha = Date.now();
    datosUsuario.idLocation = this.idLocation;
    datosUsuario.displayName = `${datosUsuario.name} ${datosUsuario.apellido}`

    
    this.authService.registrarUsuarioAdmin(datosUsuario).then((res) => {
      this.frmUsuario.reset();
      this.submitted = false;
      this.spinerGuardar = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se creo con éxito!');
      let element: HTMLElement = document.getElementById('btn-close-save') as HTMLElement;
      element.click(); 
    })
      .catch((error) => {
        console.log('error', error);
      });
      
  } 

  onCustom(event) {
    switch (event.action) {
      case 'detalle':
        this.verUsuario(event.data);
        break;
    }
  }

  goToEditarUsuario(dialog, data) {
    this.datosEdit.name = data.name;
    this.datosEdit.apellido = data.apellido;
    this.datosEdit.email = data.email;
    this.datosEdit.telefono = data.telefono;
    this.datosEdit.documento = data.documento;
    this.datosEdit.direccion = data.direccion;
    this.datosEdit.id = data.id;
    this.datosEdit.rol = data.rol;
    if (!data.fecha) {
      data.fecha = Date.now();
    }
    this.datosEdit.fecha = data.fecha;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  //Se Actualizan los datos del usuario
  updateUsuario() {}

  eliminarUsuario(data) {}

  verUsuario(obj) {
    this.displayedUser = obj
    this.mdlViewUser = false;
    console.log(this.mdlViewUser)
    setTimeout(() => {
      this.mdlViewUser = true;
    }, 500);
  }

  export() {
    this.usuarioService.exportAsExcelFile(this.usuarioExcel, 'Usuario');
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close') as HTMLElement;
      element.click();
    }, TIME_IN_MS);
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(
      mensaje,
      titulo,
      { position, status });
  }
}