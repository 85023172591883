import * as moment from "moment";

const setServices = {
  //hideSubHeader: true,
  selectMode: 'multi',
  actions: {
    delete: false,
    edit: false,
    add: false,
    filter: false,
    custom: [
      { name: 'edit', title: '<i data-toggle="tooltip" data-placement="bottom" title="Editar servicio" class="nb-compose"></i> ' },
      { name: 'EnviarConfirmacion', title: '<i class="nb-email"></i>' },
      
/*       { name: 'delete', title: '<i data-toggle="tooltip" data-placement="bottom" title="Eliminar servicio" class="nb-trash"></i> ' },
      { name: 'detalle', title: '<i data-toggle="tooltip" data-placement="bottom" title="Detallar servicio" class="nb-search"></i>' } */
    ],
  },
  columns: {
    tipoServicio: {
      title: "Tipo de servicio"
    },
    subCategoria: {
      title: 'Servicio'
    },
    creado: { 
      title: 'Sistema' 
    },
    usuario: { 
      title: 'Cliente' 
    },
    server: { 
      title: 'Server' 
    },
    direccion: { 
      title: 'Dirección' 
    },
    formaDePago: { 
      title: 'Metodo' 
    },
    hora: { 
      title: 'Inicio' 
    },
    horasDeServicio: { 
      title: 'Duracion' 
    },
  },
  pager: {
    display: true,
    perPage: 15
  },
};

const tablaServicios = {
  actions: {
    add:    false,
    edit:   false,
    delete: false,
    filter: false,
    custom: [
      { name: 'editar', title: '<i data-toggle="tooltip" data-placement="bottom" title="Editar servicio" class="nb-compose"></i> ' },
      { name: 'EnviarConfirmacion', title: '<i class="nb-email"></i>' },
    ],
  },
  columns: {
    categoria: {
      title: 'Categoria'
    },
    nombreCliente: { 
      title: 'Cliente' 
    },
    nombreServer: { 
      title: 'Server' 
    },
    tipoServicio: { 
      title: 'Tipo de servicio' 
    },
    fecha: {
      title: 'Fecha'
    },
    hora: { 
      title: 'Inicio' 
    },
    horasDeServicio: { 
      title: 'Horas' 
    },
    direccion: { 
      title: 'Dirección' 
    },
    formaDePago: { 
      title: 'Metodo de pago' 
    },
    conComprobante: { 
      title: 'Comprobante' 
    },
    numFactura: { 
      title: 'Num.Factura' 
    },
    fechaCreacion: { 
      title: 'Fecha de creacion' 
    },
    creado: { 
      title: 'Creado' 
    },
  },
}

const setAllServicesNews = {
  actions: {
    delete: false,
    edit: false,
    add: false,
    custom: [
      { name: 'detalle', title: '<i data-toggle="tooltip" data-placement="bottom" title="Detallar servicio" class="nb-search"></i>' }
    ],
  },
  columns: {
    serverName: {
      title: "Server",
    },
    usuarioName: {
      title: "Cliente",
      width: '25%'
    },
    formaDePago: {
      title: "Fc/ Pago",
    },
    pago: {
      title: "Comprobante",
      width: '60px'
    },
    horasDeServicio: {
      title: "Horas",
      width: '50px'
    },
    hora: {
      title: "Llegada",
      width: '50px'
    },
    otro: {
      title: "Observaciones",
      width: '50px'
    },
    observaciones: {
      title: "Complementos",
      width: '50px'
    },
    fecha: {
      title: "Fecha",
      width: '50px'
    },
  },
  pager: {
    display: true,
    perPage: 15
  },
};

const setMonitorServicesNews = {
  actions: {
    delete: false,
    edit: false,
    add: false,
    custom: [
      { name: 'detalle', title: '<div class="d-flex justify-content-center"><i class="nb-search"></i></div>' },
      { name: 'detalle', title: '<div class="d-flex justify-content-center"><i class="fa fa-caret-down"></i></div>' }
    ],
    
  },
  columns: {
    serverName: {
      title: "Server",
      width: '200px'
    },
    telefonoServ:{
      title: 'Teléfono',
      width: '60px'
    },
    horasDeServicio: {
      title: "Horas",
      width: '40px'
    },
    hora: {
      title: "Llegada",
      width: '40px'
    },
    estado: {
      title: "Estado",
      width: '40px'
    }
  },
  pager: {
    display: true,
    perPage: 15
  },
};

const setNews = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [],
    },
    columns: {
      type: { title: 'Tipo' },
      description: { title: 'Detalle' },
      status: { title: 'Estado' },
    }
};

const setServicesServer = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [],
    },
    columns: {
      id: {title:'Id del servicio'},
      fecha: { title: 'Fecha del servicio' },
      estado: { title: 'Estado' },
      client: { title: 'Usuario',
        valuePrepareFunction: (us) => {
          return us.name + " " + us.apellido;
        }
      },
      horasDeServicio: { title: 'Horas de Servicio' },
      precio: { title: 'Precio' },
    }
};

const  setServicesClient = {
  hideSubHeader: true,
  actions: {
    delete: false,
    edit: false,
    add: false,
    filter: false,
  },
  columns:  {
    id:     {title: 'Id servicio'},
    fecha:  { title: 'Fecha del servicio' },
    hora:  { title: 'Hora de inicio' },
    estado: { title: 'Estado' },
    server: { title: 'Server' },
    horasDeServicio: { title: 'Horas de Servicio' },
    direccion: { title: "Direccion"},
    total: { title: 'Total' },
  }
};

const setServe = {
  actions: {
    delete: false,
    edit: false,
    add: false,
    filter: false,
    custom: [
      { name: 'edit', title: '<i data-toggle="tooltip" data-placement="bottom" title="Editar serve" class="nb-compose"></i> ' },
      // { name: 'delete', title: '<i data-toggle="tooltip" data-placement="bottom" title="Eliminar serve" class="nb-trash"></i> ' },
      { name: 'detalle', title: '<i data-toggle="tooltip" data-placement="bottom" title="Ver serve" class="nb-search"></i>' }
    ],
  },
  columns: {
    cedula: { 
      title: 'Cedula' 
    },
    name: { 
      title: 'Nombre' 
    },
    apellido: { 
      title: 'Apellido' 
    },
    email: { 
      title: 'Email' 
    },
    zona: { 
      title: 'Zona' 
    },
    cities:{
      title: 'Ciudad'
    },
    telefono: { 
      title: 'Teléfono' 
    },
    /*
    fechaNacimiento: {
      title: 'Fecha de Nacimiento' 
    }
    */
  }
};

let ciudadCliente = ''
const setClient = {
  actions: {
    delete: false,
    edit: false,
    add: false,
    filter: false,
    custom: [
      { name: 'editar', title: '<i data-toggle="tooltip" data-placement="bottom" title="Editar cliente" class="nb-compose"></i> ' },
      { name: 'delete', title: '<i data-toggle="tooltip" data-placement="bottom" title="Eliminar cliente" class="nb-trash"></i> ' },
      { name: 'detalle', title: '<i data-toggle="tooltip" data-placement="bottom" title="Ver cliente" class="nb-search"></i>' }
    ],
  },
  columns: {
    name: { 
      title: 'Nombre' 
    },
    apellido: { 
      title: 'Apellido' 
    },

    email: { 
      title: 'Email' 
    },
    telefono: { 
      title: 'Teléfono' 
    },
    direccionesArray: { 
      title: 'Dirección',
      valuePrepareFunction: (dir) => {
        let count = 0
        if(dir){
          let direcciones = ""
          dir.map(element => {
            if(count == 0){
              direcciones = `${element.direccion}`
              ciudadCliente = element.cities
              count++
            }
          });
          return direcciones
        }else{
          return "Sin direcciones"
        }
      }
    },
/*     comentario:{
      title: 'Comentario'
    },
    comentarioServer:{
      title: 'Comentario Server'
    }, */
    documento: { 
      title: 'Documento' 
    },
    cities:{
      title: 'Ciudad',
      valuePrepareFunction: (dir) => {
      return ciudadCliente;
      }
    },
    creado:{ title: 'Creado'},
    fecha:{ title: 'Creacion'},
  
  }
};

export {
  tablaServicios,
  setNews,
  setAllServicesNews,
  setMonitorServicesNews,
  setServicesServer,
  setServicesClient,
  setServe,
  setClient,
  setServices
}