import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Cell, LocalDataSource } from "ng2-smart-table";
import { ServicioService } from "../../services/servicio.service";
import * as _ from "lodash";
import Swal, { SweetAlertType } from "sweetalert2";
import { NovedadesService } from "src/app/services/novedades.service";
import { Metricas } from "src/app/services/metricas.service";
import { NbDialogService } from "@nebular/theme";
import * as moment from "moment";
import { ServerInterface } from "../../models/interface/Server.interface";
import { Helper } from "../../utility/helper";
import { Router } from "@angular/router";
import {
  CardinalPoints,
  Destination,
  DestinationDefault,
  HorasDeServicio,
  ListColorService,
  MarkerOptions,
  Origin,
  OriginDefault,
  ListColoStateNews,
  PropertiesTypeList,
  ResetClientInterface,
  ResetServerInterface,
} from "src/app/utility/dataInfo";
import * as firebase from 'firebase';
import { OptionsColor } from "src/app/utility/OptionsColor";
import { AuthService } from "src/app/services/auth.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { Novedades } from "src/app/utility/novedades";
import { IMyDateRangeModel } from "mydaterangepicker";
import { count, finalize } from "rxjs/operators";
import { ServerService } from "../../services/server.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { Gps } from "src/app/models/interface/Gps.interface";
import { GoogleService } from "src/app/services/google.service";
@Component({
  selector: "app-novedades",
  templateUrl: "./novedades.component.html",
  styleUrls: ["./novedades.component.scss"],
})
export class NovedadesComponent implements OnInit {
  public prevPdf: string = '';

  activeActualizar = false;
  //mapa
  public origin: Gps = Origin;
  public placePredictions: any[];
  public originDefault: Gps = OriginDefault;

  public iconMap = {
    iconUrl: "https://i.ibb.co/WF59xrj/Cotiza.png",
    iconSize: 5,
  };

  public  fechaHoy = moment().format("YYYY-MM-DD");
  public  fechaManana = moment().add(1, "days").format("YYYY-MM-DD");

  public ListServerActive: any = [];
  public dataUsuariosServAdmi = [];
  public usuarioExcel: any = [];
  public usuarioEnSecion: any;
  public responsable: any;
  public urldocument: any;
  public inputFile: any;
  public filenameToRender: any;
  public disabledUpdateBtn: boolean = false;
  public datosEdit: ServerInterface = ResetServerInterface;
  public valdArchivo = false;
  verMdlInfoSevicio = false;
  
  misNovedadesCreadas: any = [];
  novedadesPendientesArray: any = [];
  novedadesEnProcesoArray: any = [];
  novedadesSolucionadasArray: any = [];
  novedadesRelacionadas: any = [];
  novedadesApeladasArray: any = [];
  novedadVisualizada: any;

  public arrayServiciosHoy: any = [];
  public arrayServiciosManana: any = [];
  public arrayServiciosPasadoManana: any = [];
  public arrayServerNoAsiste: any = [];
  public arraySuperNumerariaDiaActual: any = [];
  public arraySuperNumerariaDiaDespues: any = [];
  public arrayServiciosSinSalir: any = [];
  public arrayServiciosSinEmpezar: any = [];
  public arrayServiciosPendientes: any = [];
  public arrayServiciosConfirmados: any = [];
  public arrayServiciosSinConfirmar: any = [];
  public ListColorService: Array<OptionsColor> = ListColorService;

  public nombreServer: any;
  public servicioVisualizado: any;
  public estadosServicio = [
    "Pendiente",
    "Confirmado",
    "Aceptado",
    "En camino",
    "En curso",
    "Terminado",
    "Cancelado",
    "Congelado",
  ];
  public arrayCategoriaNovedad: any = Novedades;
  public arrayTiposNovedad: any = [];
  public categoriaNovedad: string = "";
  public tipoNovedad: string = "";
  public areaNovedad: string = "";
  public intencionNovedad: string = "";
  public descripcionNovedad: string;
  public costoNovedad: number = 0;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      // id: { title: "CUS" },
      department : {title: 'Ciudad'},
      agenteDesignado: { title: "Agennte designado" },
      estado: { title: "Estado" },
      area: { title: "Area" },
      categoria: { title: "Categoria" },
      cliente: { title: "Cliente" },
      costo: { title: "Costo" },
      descripcion: { title: "Descripcion" },
      // emisor: { title: "Creador" },
      fechaCreacion: { title: "Fecha de Creacion" },
      server: { title: "Server" },
      tipo: { title: "Tipo" },
      // servicioAsociado: { title: "Servicio asociado" },
    },
  };
  public settingsNovedadesHoy = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      // custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      department : {title: 'Ciudad'},
      agenteDesignado: { title: "Agennte designado" },
      estado: { title: "Estado" },
      area: { title: "Area" },
      categoria: { title: "Categoria" },
      cliente: { title: "Cliente" },
      costo: { title: "Costo" },
      descripcion: { title: "Descripcion" },
      // emisor: { title: "Creador" },
      server: { title: "Server" },
      tipo: { title: "Tipo" },
    },
  };

  public settingsApeladas = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      id: { title: "CUS" },
      department : {title: 'Ciudad'},
      agenteDesignado: { title: "Agennte designado" },
      estadoApelacion: { title: "Estado apelacion" },
      area: { title: "Area" },
      categoria: { title: "Categoria" },
      cliente: { title: "Cliente" },
      costo: { title: "Costo" },
      descripcion: { title: "Descripcion" },
      // emisor: { title: "Creador" },
      fechaCreacion: { title: "Fecha de Creacion" },
      server: { title: "Server" },
      tipo: { title: "Tipo" },
      servicioAsociado: { title: "Servicio asociado" },
    },
  };

  public settingsServicios = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      estado: {
        type: "html",
        title: "Estado",
        valuePrepareFunction: (cell) => {
          return (
            `<div style={ background-color:${this.helper.ExtractColor(
              cell,
              this.ListColorService
            )};} class="alert ${this.helper.ExtractColor(
              cell,
              this.ListColorService
            )}" role="alert">` +
            cell +
            "</div>"
          );
        },
      },
      nombreServer: { title: "Server" },
      server: { title: "Server",
        valuePrepareFunction: (cell,row) => {
          return row.server.tipoContrato;
        },
      },
      nombreCliente: { title: "Cliente" },
      hora: { title: "Hora" },
      horasDeServicio: { title: "Duracion" },
      servicio: {title: 'Tipo de servcio'},
      anotaciones: {title: 'Anotaciones',
        type: 'html',
        valuePrepareFunction: (cell,row) => {
          const a = row.posiblePlan == 'si' ? 'Posible plan' : '';
          const b = row.requiereSegSoc == 'si' ? 'Seg.Social prioritaria' : '';
          return  `<p>${a} <br> ${b}</p>`;
        },
      },
      cities: { title: "Ciudad" },
      direccion: { title: "Direccion" },
      numServer: { title: "Tel.Server" },
      numClient: { title: "Tel.Cliente" }, 
      checkList: {
        title:"Tareas",
        valuePrepareFunction: (cell) => {
          let string = ''
          console.log(cell);
          if(cell.length>0){
            cell.forEach(task=>{
              string = `${string} / ${task.name}` 
            })
          }
          return string;
        },
      },
      extras: {
        title:"Productos",
        valuePrepareFunction: (cell) => {
          let string = ''
          console.log(cell);
          if(cell.length>0){
            cell.forEach(extra=>{
              string = `${string} / ${extra.name}` 
            })
          }
          return string;
        }, 
      },
      fecha: { title: "Fecha" },
    },
  };
  public settingsServiciosSubasta = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      id: { title: "Id" },
      estado: {
        type: "html",
        title: "Estado",
        valuePrepareFunction: (cell) => {
          return (
            `<div style={ background-color:${this.helper.ExtractColor(
              cell,
              this.ListColorService
            )};} class="alert ${this.helper.ExtractColor(
              cell,
              this.ListColorService
            )}" role="alert">` +
            cell +
            "</div>"
          );
        },
      },
      hora: { title: "Hora" },
      horasDeServicio: { title: "Duracion" },
      direccion: { title: "Direccion" },
      recargoSubasta: { title: "Recargo subasta" },
      fecha: { title: "Fecha" },
    },
    rowClassFunction: (row) => {
      if (row.data.recargoSubasta >= 4800) {
        return "colorRojoSubasta";
      } else {
        return "colorVerdeSubasta";
      }
    },
  };

  public settingsServiciosnumeraria = {
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    columns: {
      nombreServer: { title: "Server" },
      cities: { title: "Ciudad" },
      direccionResidencia: { title: "Dirección" },
      telefono: { title: "Celular" },
    },
  };
  public settingsServerDisponibleHoy = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "pasarASuperNumeraria", title: '<i data-toggle="tooltip" data-placement="bottom" title="Pasar a supernumeraria" class="nb-shuffle"></i>' }],
    },
    columns: {
      nombreServer: { title: "Server" },
      disponibilidadHoy: {title: 'Daiponibilidad'},
      cities: { title: "Ciudad" },
      direccionResidencia: { title: "Dirección" },
      telefono: { title: "Celular" },
    },
  };
  public settingsServerDisponibleManana = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [{ name: "pasarASuperNumeraria", title: '<i data-toggle="tooltip" data-placement="bottom" title="Pasar a supernumeraria" class="nb-shuffle"></i>' }],
    },
    columns: {
      nombreServer: { title: "Server" },
      disponibilidadManana: {title: 'Daiponibilidad'},
      cities: { title: "Ciudad" },
      direccionResidencia: { title: "Dirección" },
      telefono: { title: "Celular" },
    },
  };
  fchHoy = moment().format("YYYY-MM-DD");

  @ViewChild("detalleNovedad", { static: true }) detalleNovedad: ElementRef;
  @ViewChild("mdlVerServicio", { static: true }) mdlVerServicio: ElementRef;
  spinerGuardar = false;
  novedadesCargadas = false;
  allClients: any;
  listaServers = [];
  arrayServciosSubasta: any;
  todasLasNovedades = [];
  countLoader = 0;
  lengthLoader = 0;
  loaderTabs = false;
  constructor(
    public novedadesService: NovedadesService,
    private metricasService: Metricas,
    public helper: Helper,
    private dialogService: NbDialogService,
    private ServicioService: ServicioService,
    private router: Router,
    private authService: AuthService,
    private serverService: ServerService,
    private usuarioService: UsuarioService,
    private sanitizer: DomSanitizer,
    private storage: AngularFireStorage,
    public googleService: GoogleService,
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
    this.responsable = this.usuarioEnSecion.displayName;
  }

  ngOnInit() {
    this.getPlace();
    this.getGps();
    this.getServicios();
    let today = moment().format('YYYY-MM-DD');
    this.getColeccionNovedades(today,today)
  }

  public freeServers_Today    = [];
  public arrayPlanRecurrente  = [];
  public freeServers_Tomorrow = [];
  

  public llamarNumerarias() {
    let data = [];
    let data1 = [];
    this.listaServers = []
    this.serverService.Actives().then((res) => {
      res.docs.forEach((dat) => {
        const element = dat.data();
        this.listaServers.push(element);
        if (element.numeraria !== undefined) {
          element.nombreServer = `${element.name} ${element.apellido}`
          if(element.numeraria.estado === true && element.numeraria.fecha == this.fchHoy ) {
            const a = this.arrayServiciosHoy.filter(serv=>serv.idServer == element.id)
            if(a.length == 0){
              data.push(element);
              this.arraySuperNumerariaDiaActual = data;
            }
          }if(element.numeraria.estado === true && 
            element.numeraria.fecha == moment().add(1, "day").format("YYYY-MM-DD")) {
            const a = this.arrayServiciosManana.filter(serv=>serv.idServer == element.id)
            if(a.length == 0){
              data1.push(element);
              this.arraySuperNumerariaDiaDespues = data1;
            }
          }
        }
      });
    });
  }

  getGps() {
    this.googleService.currentGps.subscribe((origin: Gps) => {
      this.origin =
        origin != null && origin.lat != null && origin.lng != null
          ? origin
          : this.originDefault;
    });
  }

  getPlace() {
    this.googleService.currentData.subscribe((place) => {
      this.placePredictions = place;
    });
  }
  onSelectedPlace(place) {
    this.googleService.getGpsPlace(place.description);
  }

  getColeccionNovedades(star_date,end_date) {
    let preInfoPendientes = [];
    let preInfoEnProceso = [];
    let preInfoSolucionadas = [];
    let preInfoApeladas = [];
    let allNovelty = []
    this.metricasService.getNovedadesServersDirectas(star_date,end_date).then((res) => {
      res.docs.forEach((data) => {
        let novedad = data.data();
        data.ref.update({id: data.id});
        allNovelty.push(novedad)
        if( novedad.estado == 'Apelada'){
          preInfoApeladas.push(novedad);
          this.novedadesApeladasArray = preInfoApeladas;
        }
      });
      this.todasLasNovedades = allNovelty;
    }).catch(err=>{console.error('error al traer novedades',err)});
  }

  async getNovedadesPorArea(beginDate,endDate) {
    let novedadesApeladas     = [];
    let novedadesEnProceso    = [];
    let novedadesPendientes   = [];
    let novedadesSolucionadas = [];
    let tipoConsulta:any
    if(this.usuarioEnSecion.cargo){
      tipoConsulta = await this.metricasService.getNovedadesPorAgente(this.usuarioEnSecion.displayName)
    }else{
      tipoConsulta = await this.metricasService.getNovedadesPorAReaRangoDeFecha(this.usuarioEnSecion.rol,beginDate,endDate)
    }
    for(let i = 0;i < tipoConsulta.size;i++){
      const novedad = tipoConsulta.doc[i].data();
      if(this.usuarioEnSecion.rol == "recusos" && novedad.department == this.usuarioEnSecion.department){
        if (novedad.estado == "Pendiente") {
          novedadesPendientes.push(novedad);
          this.novedadesPendientesArray = novedadesPendientes;
        } else if ( novedad.estado == "En proceso" && novedad.agenteDesignado == this.responsable) {
          novedadesEnProceso.push(novedad);
          this.novedadesEnProcesoArray = novedadesEnProceso;
        } else if ( novedad.estado == "Solucionada" && novedad.agenteDesignado == this.responsable ) {
          novedadesSolucionadas.push(novedad);
          this.novedadesSolucionadasArray = novedadesSolucionadas;
        }else if( novedad.estado == 'Apelada' && novedad.agenteDesignado == this.responsable ){
          novedadesApeladas.push(novedad);
          this.novedadesApeladasArray = novedadesApeladas;
        }
      }else{
        if (novedad.estado == "Pendiente") {
          novedadesPendientes.push(novedad);
          this.novedadesPendientesArray = novedadesPendientes;
        } 
        else if ( novedad.estado == "En proceso" && novedad.agenteDesignado == this.responsable ) {
          novedadesEnProceso.push(novedad);
          this.novedadesEnProcesoArray = novedadesEnProceso;
        } 
        else if ( novedad.estado == "Solucionada" && novedad.agenteDesignado == this.responsable ) 
        {
          novedadesSolucionadas.push(novedad);
          this.novedadesSolucionadasArray = novedadesSolucionadas;
        }
        else if( novedad.estado == 'Apelada' && novedad.agenteDesignado == this.responsable ){
          novedadesApeladas.push(novedad);
          this.novedadesApeladasArray = novedadesApeladas;
        }
      }
    }
  }

  async getNovedadesCreadas(beginDate,endDate) {
    let misNovedadesCreadas = [];
    const querry = await this.metricasService.getMisNovedadesCreadasConRangoFecha(this.usuarioEnSecion.displayName,beginDate,endDate)
    for(let i = 0;i < querry.size;i++){
      misNovedadesCreadas.push(querry.docs[i].data());
    }
    this.misNovedadesCreadas = misNovedadesCreadas;
    this.loaderTabs= false
  }

  changeDateRangeNoveltis(event: IMyDateRangeModel) {
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    if ( this.usuarioEnSecion.rol == "administrador") 
      this.getColeccionNovedades(beginDate,endDate);
    else
      this.getNovedadesPorArea(beginDate,endDate);
    this.getNovedadesCreadas(beginDate,endDate);
  }

  getServiciosSubasta(){
    let data = []
    this.ServicioService.getServiciosSubasta().then(res=>{
      res.docs.forEach(info=>{
        data.push(info.data())
        this.arrayServciosSubasta = data;
      })
    })
  }

  public piture(imgURL) {
    this.filenameToRender = imgURL;
    this.disabledUpdateBtn = this.helper.resetProperty(this.datosEdit);
  }

  onUpload(event) {
    const dia = new Date().getTime();
    const filePath = `Novedades/${dia}`;
    this.ref = this.storage.ref(filePath);
    this.task = this.ref.put(event.target.files[0]);
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe((url) => {
          this.prevPdf = url;
          this.activeActualizar = true;
        });
      })
    ).subscribe();
    this.uploadProgress = this.task.percentageChanges();
    this.downloadURL = this.ref.getDownloadURL();
  }

  baseArchivo = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event);
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
          resolve({
            base: reader.result,
          });
        };
        reader.onerror = (error) => {
          resolve({
            base: null,
          });
        };
      } catch (e) {
        return null;
      }
  });

  async getUsuariosUnicaVez() {
    let preInfo: any = [];
    let count = 0;
    let source: any = [];
    this.usuarioService.getUsersByProperty("estado", "activo").then((source) => {
      source.docs.forEach(data => {
        const element = data.data()
        if (
          element.rol == "logistica" ||
          element.rol == "marketing" ||
          element.rol == "ventas" ||
          element.rol == "contabilidad" ||
          element.rol == "recursos"
        ) {
          element.nameUser = `${element.name} ${element.apellido}`;
          preInfo.push(element);
          this.usuarioExcel.push(element);
          count++;
        } else {
          count++;
        }
        if (count == source.docs.length) {
          //this.usuarioExcel = preExcel;
          this.dataUsuariosServAdmi = _.orderBy(preInfo, ["fecha"], ["desc"]);
        }
      });
    });
  }

  onCustomNews(tipo, categoria) {
    if (categoria == "servicio") {
      if (tipo.action == "detalle") {
        this.servicioVisualizado = tipo.data;
        // this.origin =  this.servicioVisualizado.ubicacionActualServer
        this.geutServicioRealTime();
        this.verMdlInfoSevicio = false;
        setTimeout(()=>{
          this.verMdlInfoSevicio = true;
        },200)
        // this.abrrirModal(this.mdlVerServicio);
      }
    } else if (categoria == "novedad") {
      if (tipo.action == "detalle") {
        this.activeActualizar = false;
        this.novedadesRelacionadas = [];
        this.novedadVisualizada = tipo.data;
        this.prevPdf = this.novedadVisualizada.urlArchivo ?  this.novedadVisualizada.urlArchivo : "";
        // if(this.novedadVisualizada.servicioAsociado != "") this.traerNovedadesAsociadas(tipo.data.servicioAsociado, tipo.data.id);
        this.abrrirModal(this.detalleNovedad);
      }
    }
  }
  activarSupernumeraria(event,date){
    event.data.numeraria = {
      estado: true,
      fecha: date
    }
    // this.serverService.updateServ(event.data)
  }
  desActivarSupernumeraria(event,date){
    event.data.numeraria = {
      estado: false,
      fecha: date
    }
    if(date == this.fechaHoy ){
      let index = this.arraySuperNumerariaDiaActual.findIndex(res=>res.id == event.data.id)
      this.arraySuperNumerariaDiaActual.splice(index, 1);
    }else if(date == this.fechaManana ){
      let index = this.arraySuperNumerariaDiaDespues.findIndex(res=>res.id == event.data.id)
      this.arraySuperNumerariaDiaDespues.splice(index, 1);
    }
    // this.serverService.updateServ(event.data)
  }

  geutServicioRealTime() {
    this.ServicioService.getServicioRealTime(
      this.servicioVisualizado.id
    ).onSnapshot((res) => {
      let servicio = res.docs[0].data();
      this.origin = servicio.ubicacionActualServer;
      this.servicioVisualizado = res.docs[0].data();
    });
  }

  traerNovedadesAsociadas(servicioAsociado, idInicial) {
    console.log(servicioAsociado, idInicial);
    this.novedadesRelacionadas = [];
    this.metricasService.getNovedadesServicio(servicioAsociado).then((res) => {
      if(res.docs.length > 0){
        res.docs.forEach((data) => {
          let novedad = data.data();
          if (data.data().id != idInicial) {
            this.novedadesRelacionadas.push(novedad);
            if (this.novedadesRelacionadas.length == res.docs.length - 1) {
              this.abrrirModal(this.detalleNovedad);
            }
          } else {
            if (this.novedadesRelacionadas.length == res.docs.length - 1) {
              this.abrrirModal(this.detalleNovedad);
            }
          }
        });
      }else{
        this.abrrirModal(this.detalleNovedad);
      }
    });
  }

  public abrrirModal(modal) {
    this.urldocument = this.novedadVisualizada.urlArchivo;
    if (this.urldocument === undefined || this.urldocument === "") {
      this.valdArchivo = false;
    } else {
      this.valdArchivo = true;
    }
    this.dialogService.open(modal, {
      context: "this is some additional data passed to dialog",
    });
  }

  actualizarNovedad(indice) {

    console.log(this.novedadVisualizada);
    let fechaActual = moment().format("YYYY-MM-DD");
    let novedadParaActualizar: any;
    if (indice == -1) {
      novedadParaActualizar = this.novedadVisualizada;
      novedadParaActualizar.actualizar = false;
      if (novedadParaActualizar.estado == "En proceso") {
        novedadParaActualizar.fechaEnProceso = fechaActual;
        novedadParaActualizar.agenteDesignado = this.responsable;
      } else if (novedadParaActualizar.estado == "Solucionada") {
        novedadParaActualizar.fechaSolucionada = fechaActual;
      }
      if(novedadParaActualizar.estado == 'Apelada'){
        if(novedadParaActualizar.estadoApelacion == 'rechazada' && novedadParaActualizar.idPagoServer != undefined){
          novedadParaActualizar.estadoGeneral = 'concluida'
        }else if(novedadParaActualizar.estadoApelacion == 'aceptada' && novedadParaActualizar.idPagoServer == undefined){
          novedadParaActualizar.estadoGeneral = 'concluida'
        }
      }
    } else {
      novedadParaActualizar = this.novedadesRelacionadas[indice];
      novedadParaActualizar.actualizar = false;
      if (novedadParaActualizar.estado == "En proceso") {
        novedadParaActualizar.fechaEnProceso = fechaActual;
        novedadParaActualizar.agenteDesignado = this.responsable;
      } else if (novedadParaActualizar.estado == "Solucionada") {
        novedadParaActualizar.fechaSolucionada = fechaActual;
      }
    }
    console.log(novedadParaActualizar);
    this.metricasService.updateNovedad(novedadParaActualizar).then((res) => {
      Swal.fire("Listo", "La novedad fue actualizada exitosamente", "success");
    }).catch(error=>console.error('error al acualizar la novedad',error))
  }

  async getServicios() {
    this.loaderTabs = true;
    this.countLoader = 0;
    let fechaHoy = moment().format("YYYY-MM-DD");
    let fechaManana = moment().add(1, "days").format("YYYY-MM-DD")
    let fechaFin = moment().add(2, "days").format("YYYY-MM-DD");
    
    let serviciosHoy          = [];
    let serverNoAsiste        = [];
    let serviciosManana       = [];
    let serviciosSinSalir     = [];
    let serviciosSinEmpezar   = [];
    let serviciosPendientes   = [];
    let serviciosConfirmados  = [];
    let serviciosPasadoManana = [];
    let serviciosSinConfirmar = [];

    const querry = await this.ServicioService.getInDateRange(fechaHoy, fechaFin)
    for(let i = 0;i< querry.size; i++){
      let servicio =  querry.docs[i].data();
      if(servicio.estado != "Cancelado" && servicio.estado != "Congelado"){
        servicio.numServer = servicio.server
          ? servicio.server.telefono
          : "Sin server asignada";
        servicio.numClient = servicio.client.telefono;
        servicio.nombreServer  = servicio.server ? servicio.server.name + " " + servicio.server.apellido : "Sin server definida";
        servicio.nombreCliente = servicio.client ? servicio.client.name + " " + servicio.client.apellido : "Sin cliente definido";
        servicio.cities = servicio.destination.cities;
        const hora = parseInt(servicio.hora);
        const horaActual = moment();
        const horaInicio = moment(servicio.fecha).add(hora, "hours");
        const minutosHastaEmpezarServicio = horaInicio.diff(horaActual, "minutes");
        if (servicio.estado == "Pendiente" && minutosHastaEmpezarServicio   <= 180) {
          serviciosPendientes.push(servicio);
        } else if ( (servicio.estado == "Aceptado" || servicio.estado == "Confirmado") && minutosHastaEmpezarServicio   <= 120) {
          serviciosSinSalir.push(servicio);
        } else if (servicio.estado == "En camino" && minutosHastaEmpezarServicio   <= 20) {
          serviciosSinEmpezar.push(servicio);
        }else if(servicio.estado == "Pendiente confirmar"){
          serverNoAsiste.push(servicio)
        }
  
        if (servicio.fecha == fechaHoy ) {
          serviciosHoy.push(servicio);
        } else if(servicio.fecha == fechaManana ){
          serviciosManana.push(servicio);
          if ( servicio.estado == "Aceptado" || servicio.estado == "Pendiente") {
            serviciosSinConfirmar.push(servicio);
          }
          else if (servicio.estado == "Confirmado") {
            serviciosConfirmados.push(servicio);
          }
        }else{
          serviciosPasadoManana.push(servicio)
        }
      }
    }
    
    this.arrayServiciosHoy          = serviciosHoy;
    this.arrayServerNoAsiste        = serverNoAsiste;
    this.arrayServiciosManana       = serviciosManana;
    this.arrayServiciosPasadoManana = serviciosPasadoManana;
    this.arrayServiciosSinSalir     = serviciosSinSalir;
    this.arrayServiciosSinEmpezar   = serviciosSinEmpezar;
    this.arrayServiciosConfirmados  = serviciosConfirmados;
    this.arrayServiciosSinConfirmar = serviciosSinConfirmar;
    this.arrayServiciosPendientes = _.orderBy(serviciosPendientes,["fecha"],["asc"]);
    this.loaderTabs = false;
    
    this.llamarNumerarias();
  }

  validarServerServicio(servicio){
    return new Promise(async resolve =>{
      if(servicio.server && servicio.tipoServicio == "Plan Mensual"){
        let mismaServer = "No";
        const plan = (await this.ServicioService.getPlaneById(servicio.planAsociado)).data()
        if(plan.server.id == servicio.server.id) mismaServer = "Si";
        return resolve(mismaServer)
      }else{
        return resolve("Si")
      }
    })
  }
  //agregar novedades

  public buscarNovedades(event, type) {
    let value = event.target.value;
    if (type == "tipo") {
      let index = this.arrayCategoriaNovedad.findIndex(
        (categoria) => categoria.name == value
      );
      this.arrayTiposNovedad = this.arrayCategoriaNovedad[index].tipo;
    } else if (type == "area") {
      let index = this.arrayTiposNovedad.findIndex(
        (tipo) => tipo.name == value
      );
      this.areaNovedad = this.arrayTiposNovedad[index].area;
      this.costoNovedad = this.arrayTiposNovedad[index].valor;
      this.intencionNovedad = this.arrayTiposNovedad[index].intencion;
    }
  }

  public crearNovedad(descripcion, costo) {
    let novedad = {
      id: new Date().getTime(),
      categoria: this.categoriaNovedad,
      tipo: this.tipoNovedad,
      area: this.areaNovedad,
      emisor: this.responsable,
      descripcion: descripcion,
      intencion: this.intencionNovedad,
      estado: "Pendiente",
      agenteDesignado: "Sin agente",
      costo: parseInt(costo),
      cliente: this.servicioVisualizado.client.name + " " + this.servicioVisualizado.client.apellido,
      clienteId: this.servicioVisualizado.client.id,
      server: this.servicioVisualizado.server.name + " " + this.servicioVisualizado.server.apellido,
      serverId: this.servicioVisualizado.server.id,
      servicioAsociado: this.servicioVisualizado.id,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaEnProceso: "null",
      fechaSolucionada: "null",
      fechaDelServicio: this.servicioVisualizado.fecha,
      ciudad: this.servicioVisualizado.destination.cities,
      urlArchivo: this.prevPdf ? undefined : "",
      department: this.servicioVisualizado.destination.department,
      estadoGeneral: 'vigente',
    };
    if (
      this.tipoNovedad == "Cancelación de servicio" ||
      this.tipoNovedad == "Uniforme" ||
      this.tipoNovedad == "Calidad" ||
      this.tipoNovedad == "Sin productos" ||
      this.tipoNovedad == "Calidad" ||
      this.tipoNovedad == "Daños"
    ) {
      this.serverService.detail(this.servicioVisualizado.server.id).then(data => {
        const server = data.data();
        if (server.strikes === undefined) {
          server.strikes = [];
        }
        server.strikes.push({
          fecha: moment().format("YYYY-MM-DD"),
          razon: this.tipoNovedad,
          revisada: false,
        });
        this.serverService.updateServ(server);
      });
    } else if (
      this.tipoNovedad == "Puntualidad" ||
      this.tipoNovedad == "Accidentes"
    ) {
      this.serverService.detail(this.servicioVisualizado.server.id).then(data => {
        const server = data.data();
        if (server.numFallas === undefined) {
          server.numFallas = 0;
        }
        server.numFallas++;
        if (server.numFallas === 3) {
          if (server.strikes === undefined) {
            server.strikes = [];
          }
          server.strikes.push({
            fecha: moment().format("YYYY-MM-DD"),
            razon: this.tipoNovedad,
            revisada: false,
          });
        }
        this.serverService.updateServ(server);
      });
    }
    this.metricasService.crearNovedad(novedad).then((res) => {
      Swal.fire("Creado", "La novedad fue creada exitosamente", "success");
      this.ServicioService.servicioConNovedad(novedad.servicioAsociado).then(
        (res) => {
          console.log("se actualizo el servicio");
        }
      );
      this.categoriaNovedad = "";
      this.tipoNovedad = "";
      this.descripcionNovedad = "";
      this.costoNovedad = 0;
    });
  }

  actualizarServicio() {
    this.ServicioService.updateServicio(this.servicioVisualizado).then(
      (res) => {
        Swal.fire("Bien", "Servicio actualizado", "success");
      }
    );
  }

  private Message(
    title: string,
    message: string,
    type: SweetAlertType,
    close: boolean = false,
    modal: string = "btn-close-save",
    reset: boolean = false
  ) {
    setTimeout(() => {
      Swal.fire(title, message, type);
      if (reset) {
      }
      if (close) {
        const element: HTMLElement = document.getElementById(
          modal
        ) as HTMLElement;
        element.click();
      }
    }, 900);
  }
  public StatusBySelectd(listColors, status: string) {
    const style = this.helper.ExtractColor(status, listColors, "color");
    return style;
  }
  onDeleteNovelty(){
    Swal.fire({
      title: "Estas seguro(a)?",
      text: "Se eliminara la novedad del sistema",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.metricasService.deleteNovelty(this.novedadVisualizada.id).then(() => {
          Swal.fire("Listo", "la novedad fue eliminada", "success");
        }).catch(() => {
          Swal.fire("Error!","No se pudo eliminar la novedad","error");
        });
      }
    });
  }

  dias = ['domingo','lunes','martes','miercoles','jueves','viernes','sabado'];
  validatePlanRecurrenteServer(server,date){
    return new Promise(resValidatePlanRecurrenteServer =>{
      const number_day = moment(date).day();
      const day = this.dias[number_day];
      let avalible = true;
      const filter = this.arrayPlanRecurrente.filter(res=> res.idServerAsignada == server.id) 
      if(filter.length > 0 ){
        for(let i = 0;i< filter.length; i++){
          if(filter[i].diasAgendado.includes(day)) {
            avalible = false 
            break;
          }
        }
        return resValidatePlanRecurrenteServer(avalible)
      }else{
        return resValidatePlanRecurrenteServer(avalible)
      }
    })
  }
  
  validateBlockedDay(server,date){
    return new Promise(async resValidateBlockedDay => {
      const querry = await this.serverService.getDisponibilidadUnaVes(server.id)
      if(querry.exists) return resValidateBlockedDay( querry.data().fechas.some((fecha) => fecha.fecha == date))
      return resValidateBlockedDay(true)
    })
  }

  validateHorario(server,date){
    return new Promise(resValidateHorario =>{
      let index = parseInt(date);
      if(server.horario[index].manana && server.horario[index].tarde){
        resValidateHorario('Dia completo');
      }else if(server.horario[index].manana){
        resValidateHorario('solo mañana');
      }else if(server.horario[index].tarde){
        resValidateHorario('Solo tarde');
      }else{
        resValidateHorario('no');
      }
    })
  }

  enviarRecordatorioCambioDeEstado(data,category){

    let title = '';
    let body = '';
    if(category == 'pendiente'){
      title = 'Tienes servicios por aceptar'
      body = 'SERV. Ingresa a tu aplicacion y acepta tu nuevo servicio, tienes 1 hora para aceptarlo o sera enviado a otra server, contacta a logistica si tienes problemas https://wa.link/hlv6nd ' 
    }else if(category = 'sinSalir'){
      title = 'Ponte en camino'
      body = 'SERV. Tu servicio sigue en estado confirmado, por favor ponte en camino en la app. De lo contrario notifica a logistica tu novedad. https://wa.link/mljrqw '
    }else if(category = 'sinEmpesar'){
      title = 'Pon tu servicio en curso'
      body = 'SERV. Tu servicio sigue en camino, por favor recuerda ponerlo en curso en la app o tendras problema con el pago del servicio, contacta a logistica ahora https://wa.link/qby5fn '
    }else if(category = 'sinConfirmar'){
      title = 'Recuerda confirmar tu servicio'
      body = 'SERV. Confirma en la app tu servicio del día de mañana. De lo contrario sera asignado a otra profesional, contacta a logistica si tienes problemas https://wa.link/hlv6nd '
    }
    data.forEach(res=>{
      const info = {
        title: title,
        body: body,
        server: `${res.server.telefono}`,
      }
      const requestRecordatorioCambioDeEstado = firebase.functions().httpsCallable("requestRecordatorioCambioDeEstado");
      requestRecordatorioCambioDeEstado(info).then(()=>{
        console.log("sms enviados");

      }).catch(err=>{
        console.log("ocurrio un error",err)
      })
      
    })
  }

  async onTabChange(event){
    if(event.tabTitle == 'Novedades' && !this.novedadesCargadas){
        this.loaderTabs = true;
      this.novedadesCargadas = true
      const today = moment().format('YYYY-MM-DD');
      const startDate = moment().subtract(15,'days').format('YYYY-MM-DD');
      this.getColeccionNovedades(startDate,today);
      this.getNovedadesCreadas(startDate,today);
      this.getServiciosSubasta();
    }
  }
}
