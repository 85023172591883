import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { NbDialogService, NbToastrService, } from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { CompleterService } from "ng2-completer";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import * as _ from "lodash";
/* Servicios */
import { LeadsService } from 'src/app/services/leads.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';



@Component({
  selector: 'app-ver-lead',
  templateUrl: './ver-lead.component.html',
  styleUrls: ['./ver-lead.component.scss']
})
export class VerLeadComponent implements OnInit {

  @Input() item = ''

  public lead: any = {};
  public asesorLead: any;
  public usuarioEnSecion: any;
  public update_at: Date;
  public parsedUpdate;

  constructor(
    public completerService: CompleterService,
    public _location: Location,
    private leadsService: LeadsService,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData('usuario');
    // this.lead.id = this.activatedRoute.snapshot.params['id'];
    // this.candidatoService.getLead(this.lead.id).then(res => {
    //   res.forEach(doc => {
    //     this.lead = doc.data()
    //   });
    // });
  }

  ngOnInit() {
    console.log('Prueba de @Input()',this.item) 
    this.asesorLead = this.usuarioEnSecion.displayName; 
  }

  public goBack() {
    this._location.back();
  }
  public contactarLeadNuevamente() {
    this.lead.agenteComercial = this.usuarioEnSecion.displayName
    this.lead.estado = "lead volver a contactar";
    this.lead.updateContactar =  moment().format('YYYY-MM-DD')
    this.actualizarlead()
  }
  public programarVisita() {
    this.lead.estado = "lead por visitar";
    this.actualizarlead()
  }
  public programarServicio() {
    this.lead.estado = "lead en servicio de prueba";
    this.actualizarlead()
  }
  public leadVendido() {
    this.lead.agenteComercial = this.usuarioEnSecion.displayName
    this.lead.estado = "lead vendido";
    this.lead.updateVendido =  moment().format('YYYY-MM-DD')
    this.actualizarlead()
  }

  public leadPerdido() {
    this.lead.agenteComercial = this.usuarioEnSecion.displayName
    this.lead.estado = "lead perdido";
    this.lead.updatePerdido =  moment().format('YYYY-MM-DD')
    this.actualizarlead()
  }

  public actualizarlead() {
    if (this.lead.nombre && this.lead.estado && _.includes(this.lead.correo, "@") && this.lead.telefono && this.lead.frecuencia && this.lead.horas && this.lead.servicio && this.lead.id) {
      this.leadsService.editLead(this.lead).then((result) => {
        Swal.fire(
          'Éxito',
          'Se actualizo el lead exitosamente',
          'success'
        );

        this._location.back();

      });

    } else {
      Swal.fire(
        'Error',
        'No se pudo actualizar el lead, verifique la información',
        'warning'
      );
    }
  }
}