import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { PlanService } from "src/app/services/plan.service";
import { Metricas } from "src/app/services/metricas.service";
import { ServerService } from "src/app/services/server.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";


import * as _ from "lodash";
import Swal from "sweetalert2";
import * as moment from "moment";

import { AngularFireStorage } from "@angular/fire/storage";
import { DomSanitizer } from "@angular/platform-browser";
import { ServBackendService } from "src/app/services/serv-backend.service";
import { LeadsService } from "src/app/services/leads.service";
import { CandidatesService } from "src/app/services/candidates.service";
import { CandidatoService } from "src/app/services/candidato.service";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexMarkers,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
  ApexTooltip,
  ApexAnnotations,
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
};

@Component({
  selector: "app-metricas",
  templateUrl: "./metricas.component.html",
  styleUrls: ["./metricas.component.scss"],
})
export class MetricasComponent implements OnInit {

  usuarioEnSecion: any;
  baner: any = { text: '', imagen: '', title: '', autor: '', };
  file_baner: any;
  loaderTeamServ = true;
  imagenSelectedPreview: any;
  loaderMetricasPlanes = true;
  loaderMetricasAnuncio = true;
  loaderMetricasServer = true;
  loaderMetricasClientes = false;
  loaderMetricasServicios = true;
  showPopupCreateBaner = false;
  arrayServers: any[];
  arrayTeamServ: any[];
  notifications = [];


  constructor(
    private metricas: Metricas,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private planService: PlanService,
    private leadsService: LeadsService,
    private serverService: ServerService,
    private usuarioService: UsuarioService,
    private servicioService: ServicioService,
    private angularFireStorage: AngularFireStorage,
    private servBackendService: ServBackendService,
    private candidatoService: CandidatoService
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
    console.log(this.usuarioEnSecion);
  }


  async ngOnInit() {
    this.getPlanes();
    await this.getBaner();
    this.getServers();
    // this.getClients();
    this.getServicios();
    this.getTeamServ();
    this.getNotifications();
    this.caclculate_days_chart();
  }

  /* Dashboard de metricas 2023 */

  bogota_ref = ["Bogota", "Bogotá", "Bogota"];
  medellina_ref = ["Medellin", "Medellín", "Antioquia"];

  metricas_planes: any = {};
  metricas_servers: any = {};
  metricas_clientes: any = { todos: 0 };
  metricas_servicios = {
    serviciosHoy: 0,
    serviciosManana: 0,
    serviciosSabana_hoy: 0,
    serviciosBogota_hoy: 0,
    serviciosMedellin_hoy: 0,
    serviciosSabana_manana: 0,
    serviciosBogota_manana: 0,
    serviciosMedellin_manana: 0
  };
  serversDirectas = {
    all: [],
    antioquia: [],
    cundinamarca: [],
  };
  chart_direct_servers_data: any
  async getBaner() {
    const querry = await this.metricas.getUltimoBanerPublicado();
    console.log(querry.docs)
    this.baner = querry.docs[0].data();
    this.loaderMetricasAnuncio = false;
  }
  openPopupEditBaner() {
    this.showPopupCreateBaner = true;
  }

  async handleImageUpload(event: any) {
    this.file_baner = event.target.files[0];
    const a = URL.createObjectURL(event.target.files[0]);
    const safeblobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(a);
    this.baner.imagen = safeblobUrl;
  }

  async saveBanner() {
    const filePath = `banner/${moment()}`;
    const file = await this.angularFireStorage.ref(filePath).put(this.file_baner)
    this.baner.imagen = await file.ref.getDownloadURL();
    this.baner.fecha = moment().format('YYYY-MM-DD');
    this.baner.time = new Date().getTime();
    console.log(this.baner)
    this.metricas.addNewBaner(this.baner).then(() => {
      this.showPopupCreateBaner = false;
      Swal.fire('Listo', 'El baner a sido actualizado', 'success')
    })
  }

  async getServicios() {
    this.loaderMetricasServicios = true;
    let fechaHoy = moment().format("YYYY-MM-DD");
    let fechaManana = moment().add(1, "days").format("YYYY-MM-DD");

    const querry = await this.servicioService.getInDateRange(fechaHoy, fechaManana);
    for (let i = 0; i < querry.size; i++) {
      let servicio = querry.docs[i].data();
      if (servicio.estado != "Cancelado"  && servicio.estado != "Congelado" && servicio.estado != "Suspendido" && servicio.estado != "Orden de servicio" && servicio.estado != "Orden de plan"  && servicio.estado != "Orden de Servicio") {

        if (servicio.fecha == fechaHoy) {
          this.metricas_servicios.serviciosHoy++;
          if (
            servicio.direccion.includes(this.bogota_ref[0]) ||
            servicio.direccion.includes(this.bogota_ref[1]) ||
            servicio.direccion.includes(this.bogota_ref[2])
          ) { this.metricas_servicios.serviciosBogota_hoy++; }
          else if (
            servicio.direccion.includes(this.medellina_ref[0]) ||
            servicio.direccion.includes(this.medellina_ref[1]) ||
            servicio.direccion.includes(this.medellina_ref[2])
          ) { this.metricas_servicios.serviciosMedellin_hoy++; }
          else this.metricas_servicios.serviciosSabana_hoy++;
        }
        else {

          if (
            servicio.direccion.includes(this.bogota_ref[0]) ||
            servicio.direccion.includes(this.bogota_ref[1]) ||
            servicio.direccion.includes(this.bogota_ref[2])
          ) { this.metricas_servicios.serviciosBogota_manana++; }
          else if (
            servicio.direccion.includes(this.medellina_ref[0]) ||
            servicio.direccion.includes(this.medellina_ref[1]) ||
            servicio.direccion.includes(this.medellina_ref[2])
          ) { this.metricas_servicios.serviciosMedellin_manana++; }
          else this.metricas_servicios.serviciosSabana_manana++;
          this.metricas_servicios.serviciosManana++;
        }
      }
    }
    console.log("Asignando tablas");
    this.loaderMetricasServicios = false;
  }

  async getPlanes() {
    // this.metricas_planes.activos = 0;
    // this.metricas_planes.activos_bogota = 0;
    // this.metricas_planes.activos_medellin = 0;

    this.servBackendService.get('api/serv/get_metric_active_planes').subscribe(res => {
      console.log(res)
      if (res.success) {
        this.metricas_planes = res.data;
        const pie_planes_activos = document.getElementById("pie_planes_activos");
        pie_planes_activos.style.cssText = `--percent: ${this.metricas_planes.percentage}; --color:var(--primary); --grosor: 18px; --tamano: 115px; font-size: 24px`;
        this.loaderMetricasPlanes = false;
        if(this.usuarioEnSecion.rol == 'cuentas' || this.usuarioEnSecion.rol == 'administrador'){
          this.notifications.push({type: 'Planes por vencer',value:  this.metricas_planes.por_vencer})
        }
      }
    })
  }

  async getServers() {
    const querry = await this.serverService.Actives();
    this.metricas_servers = await this.seccionarServers(querry.docs);
    this.arrayServers = _.orderBy(this.metricas_servers.array_todas, ['porcentajeOcupacionSemana'], 'desc');
    this.metricas_servers.ordenPorcentajeOcupacion = 'desc';
    const pie_servers_activos = document.getElementById("pie_servers_activos");
    // pie_servers_activos.style.cssText = `--percent: ${this.metricas_servers.porcentaje}; --color: #ffa100;`;
    this.loaderMetricasServer = false
  }

  changeOrderPercentageOccupation() {
    console.log(this.metricas_servers.ordenPorcentajeOcupacion)
    if (this.metricas_servers.ordenPorcentajeOcupacion == 'desc') {
      console.log('desc')
      this.metricas_servers.ordenPorcentajeOcupacion = 'asc'
      this.arrayServers = _.orderBy(this.metricas_servers.array_todas, ['porcentajeOcupacionSemana'], 'asc');
    }
    else {
      console.log('asc')
      this.metricas_servers.ordenPorcentajeOcupacion = 'desc'
      this.arrayServers = _.orderBy(this.metricas_servers.array_todas, ['porcentajeOcupacionSemana'], 'desc');
    }
  }

  seccionarServers(servers) {
    return new Promise((resolve) => {
      let seccionamiento: any = {
        array_todas: [],
        todas: 0,
        bogota: 0,
        medellin: 0,
        directas: 0,
        directas_bogota:0,
        directas_medellin:0,
        porServicio: 0,
        porServicio_bogota: 0,
        porServicio_medellin: 0,
        porcentaje: 0,
        otros:[],
      };
      let seccionamiento_departamento: any = {}
      for (let i = 0; i < servers.length; i++) {
        const server = servers[i].data();
        seccionamiento.array_todas.push(server);
        seccionamiento.todas++;
        seccionamiento_departamento[server.department] == undefined ? seccionamiento_departamento[server.department] = 1 : seccionamiento_departamento[server.department] ++;
        if (server.department == "Antioquia") seccionamiento.medellin++;
        else seccionamiento.bogota++;
        if(server.tipoContrato == 'directo') {
          seccionamiento.directas++;
          this.serversDirectas.all.push(server.id);
          if (server.department == "Antioquia"){
            seccionamiento.directas_medellin++
            this.serversDirectas.antioquia.push(server.id);
          } else {
            seccionamiento.directas_bogota++
            this.serversDirectas.cundinamarca.push(server.id)
          }
        }else{
          seccionamiento.porServicio ++;
          if (server.department == "Antioquia") seccionamiento.porServicio_medellin++
          else seccionamiento.porServicio_bogota++
        }
      }
      seccionamiento.otros = Object.entries(seccionamiento_departamento).map(([key, value]) => {
        return { name: key, value: value };
      });
      seccionamiento.porcentaje = (seccionamiento.bogota * 100) / seccionamiento.todas;
      return resolve(seccionamiento);
    });
  }

  async getClients() {
    this.loaderMetricasClientes = true;
    this.metricas_clientes.todos = 0;
    this.metricas_clientes.clientes_bogota = 0;
    this.metricas_clientes.clientes_medellin = 0;
    const querry = await this.usuarioService.all();
    for (let i = 0; i < querry.size; i++) {
      const client = querry.docs[i].data();

      this.metricas_clientes.todos++;
      const department =
        typeof client.direccionesArray !== "undefined" &&
          typeof client.direccionesArray[0] !== "undefined" &&
          typeof client.direccionesArray[0].department !== "undefined"
          ? client.direccionesArray[0].department
          : typeof client.department !== "undefined"
            ? client.department
            : "Sin direccion";
      if (department == "Bogotá D.C." || department == "Cundinamarca")
        this.metricas_clientes.clientes_bogota++;
      else if (department == "Antioquia")
        this.metricas_clientes.clientes_medellin++;
    }
    this.metricas_clientes.porcentaje = (this.metricas_clientes.clientes_bogota * 100) / this.metricas_clientes.todos;
    console.log('termino cargar cliebntes')
    this.loaderMetricasClientes = false;
    // pie_planes_activos.style.cssText = `--percent: ${this.metricas_planes.porcentaje}; --color: #c90000;`;
  }

  async getTeamServ() {
    const query = await this.authService.getUsuarioAdmin();
    this.arrayTeamServ = query.docs.map(res => {
      ;

      return res.data();
    });
    this.loaderTeamServ = false;
  }

  getNotifications(){
    switch (this.usuarioEnSecion.rol){
      case 'ventas' : 
        this.getLeads();
        this.getPostVentas();
        break
      case 'logistica':
        this.getNovedades();
        break
      case 'recursos':
        this.getCandidatos();
        break
      // case 'cuentas':
      // Se agregara la cantidad de planes por vencer al finalizar getPlanes()
      default:
        this.getLeads();
        this.getPostVentas();
        this.getNovedades();
        this.getCandidatos();
    }
  }

  async getLeads(){
    const estarDate = moment().subtract(30,'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const res = (await this.leadsService.getLeadsRangoFecha_estado(estarDate,endDate,'Interesados')).size;
    this.notifications.push({type: 'Leads',value: res})
  }

  async getPostVentas(){
    const date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    const res = (await this.servicioService.getServiciosIndividualesTerminadosRangoDeFecha(date, date)).size
    this.notifications.push({type: 'Postventas',value: res})
  }

  async getNovedades(){
    const date = moment().format('YYYY-MM-DD');
    const res = (await this.metricas.getNovedadesRnagoFechaCreacion(date,date)).size;
    this.notifications.push({type: 'Novedades',value: res});
  }
  async getCandidatos(){
    const start_date = moment().format('YYYY-MM-DD');
    const end_date = moment().subtract('7','days').format('YYYY-MM-DD');
    const res = (await this.candidatoService.getCandidatos_fecha_estado(start_date, end_date, 'Solicitud recibida')).size;
    this.notifications.push({type: 'Candidatos',value: res});
  }

  verGrafica = false;
  caclculate_days_chart(){
    this.chart_direct_servers_data = {
      days: [],
      servers_with_double_service:[],
      non_direct_servers: [],
      service_administrative: [],

      service_4_hours: [],
      service_6_hours: [],
      scheduled_services: [],
      direct_servers_without_services: [],
      servers: [],
      // Cundinamarca
      cun_service_administrative: [],
      cun_servers_with_double_service:[],
      cun_non_direct_servers: [],
      cun_service_4_hours: [],
      cun_service_6_hours: [],
      cun_scheduled_services: [],
      cun_direct_servers_without_services: [],
      cun_servers: [],
      // Medellin
      med_service_administrative: [],
      med_servers_with_double_service:[],
      med_non_direct_servers: [],
      med_service_4_hours: [],
      med_service_6_hours: [],
      med_scheduled_services: [],
      med_direct_servers_without_services: [],
      med_servers: [],
    }
    let days: any = {};
    for(let i = 0;i < 15;i++){
      days[moment().add(i,'days').format('YYYY-MM-DD')] = {
        servers_with_double_service: 0,
        non_direct_servers: 0,
        service_administrative: 0,
        service_4_hours: 0,
        service_6_hours: 0,
        scheduled_services: 0,
        direct_servers_without_services: 0,
        servers: [],
        // Cundinamarca
        cun_service_administrative: 0,
        cun_service_4_hours: 0,
        cun_service_6_hours: 0,
        cun_scheduled_services: 0,
        cun_direct_servers_without_services: 0,
        cun_servers: [],
        cun_servers_with_double_service: 0,
        cun_non_direct_servers: 0,
        // Medellin
        med_service_administrative: 0,
        med_service_4_hours: 0,
        med_service_6_hours: 0,
        med_scheduled_services: 0,
        med_direct_servers_without_services: 0,
        med_servers: [],
        med_servers_with_double_service: 0,
        med_non_direct_servers: 0,
      }
      this.chart_direct_servers_data.days.push(moment().add(i,'days').format('YYYY-MM-DD')) 
    }
    this.getServises(days);
  }

  getServises(days){
    let array_servers_4horas = []
    this.servicioService.getInDateRange(moment().format('YYYY-MM-DD'),moment().add(14,'days').format('YYYY-MM-DD')).then(async res=>{
      res.docs.forEach(ref => {
        const service = ref.data()
        if(service.estado != "Cancelado"  && service.estado != "Congelado" && service.estado != "Suspendido" && service.estado != "Orden de servicio" && service.estado != "Orden de plan"  && service.estado != "Orden de Servicio" && service.tipoServicio != "Administrativo" ) {
          days[service.fecha].scheduled_services ++;
          days[service.fecha].servers.push(service.idServer);
          if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca"){
            days[service.fecha].cun_scheduled_services ++; 
            days[service.fecha].cun_servers.push(service.idServer);
          } 
          if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ){
            days[service.fecha].med_scheduled_services ++; 
            days[service.fecha].med_servers.push(service.idServer);
          }
          
          if(this.serversDirectas.all.filter(idserver=> idserver == service.idServer).length > 0 && days[service.fecha].servers.filter(idServer=> idServer == service.idServer).length == 1){
          
            if(service.horasDeServicio == '4' ){
              days[service.fecha].service_4_hours ++;
              // console.log(service.server.name);
              if(service.fecha == "2024-09-20") array_servers_4horas.push(service.server.name)
              
              if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_service_4_hours ++; 
              if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_service_4_hours ++;
            }
            else if(service.horasDeServicio == '6'){
              days[service.fecha].service_6_hours ++;
              if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_service_6_hours ++; 
              if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_service_6_hours ++;
            }
          } else if(this.serversDirectas.all.filter(idserver=> idserver == service.idServer).length > 0 && days[service.fecha].servers.filter(idServer=> idServer == service.idServer).length == 2){
            
            days[service.fecha].servers_with_double_service ++;
            if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_servers_with_double_service ++; 
            if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_servers_with_double_service ++;
            
            if(service.horasDeServicio == '4' ){
              days[service.fecha].service_4_hours --;
              if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_service_4_hours --; 
              if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_service_4_hours --;
            } 
            else if(service.horasDeServicio == '6'){
              days[service.fecha].service_6_hours --;
              if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_service_6_hours --; 
              if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_service_6_hours --;
            } 
          }else{
            days[service.fecha].non_direct_servers ++;  
            if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_non_direct_servers ++; 
            if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_non_direct_servers ++;
          }
        }else if(service.tipoServicio == "Administrativo"){
          days[service.fecha].service_administrative
          if(service.destination.department == 'Bogotá' || service.destination.department == 'Bogotá D.C.' || service.destination.department == "Cundinamarca" ) days[service.fecha].cun_service_administrative ++; 
          if(service.destination.department == 'Medellín' || service.destination.department == "Antioquia" ) days[service.fecha].med_service_administrative ++;
        }
      });
      for(let i = 0;i < 15;i++){
        days[moment().add(i,'days').format('YYYY-MM-DD')].direct_servers_without_services = await this.calcularDiferencia('all',days[moment().add(i,'days').format('YYYY-MM-DD')].servers);
        days[moment().add(i,'days').format('YYYY-MM-DD')].cun_direct_servers_without_services = await this.calcularDiferencia('cundinamarca',days[moment().add(i,'days').format('YYYY-MM-DD')].cun_servers);
        days[moment().add(i,'days').format('YYYY-MM-DD')].med_direct_servers_without_services = await this.calcularDiferencia('antioquia',days[moment().add(i,'days').format('YYYY-MM-DD')].med_servers);
  
        // todas
        this.chart_direct_servers_data.service_administrative.push(days[moment().add(i,'days').format('YYYY-MM-DD')].service_administrative);
        this.chart_direct_servers_data.service_4_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].service_4_hours);
        this.chart_direct_servers_data.service_6_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].service_6_hours);
        this.chart_direct_servers_data.scheduled_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].scheduled_services);
        this.chart_direct_servers_data.direct_servers_without_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].direct_servers_without_services);
        this.chart_direct_servers_data.servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].servers);
        this.chart_direct_servers_data.non_direct_servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].non_direct_servers);
        this.chart_direct_servers_data.servers_with_double_service.push(days[moment().add(i,'days').format('YYYY-MM-DD')].servers_with_double_service);
        // Cundinamarca
        this.chart_direct_servers_data.cun_service_administrative.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_service_administrative);
        this.chart_direct_servers_data.cun_service_4_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_service_4_hours);
        this.chart_direct_servers_data.cun_service_6_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_service_6_hours);
        this.chart_direct_servers_data.cun_scheduled_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_scheduled_services);
        this.chart_direct_servers_data.cun_direct_servers_without_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_direct_servers_without_services);
        this.chart_direct_servers_data.cun_servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_servers);
        this.chart_direct_servers_data.cun_servers_with_double_service.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_servers_with_double_service);
        this.chart_direct_servers_data.cun_non_direct_servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].cun_non_direct_servers);
        // Medellin
        this.chart_direct_servers_data.med_service_administrative.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_service_administrative);
        this.chart_direct_servers_data.med_service_4_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_service_4_hours);
        this.chart_direct_servers_data.med_service_6_hours.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_service_6_hours);
        this.chart_direct_servers_data.med_scheduled_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_scheduled_services);
        this.chart_direct_servers_data.med_direct_servers_without_services.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_direct_servers_without_services);
        this.chart_direct_servers_data.med_servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_servers)
        this.chart_direct_servers_data.med_servers_with_double_service.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_servers_with_double_service);
        this.chart_direct_servers_data.med_non_direct_servers.push(days[moment().add(i,'days').format('YYYY-MM-DD')].med_non_direct_servers);
      }
      console.log(this.chart_direct_servers_data);
      const ocurrencias = new Map<number, number>();
      array_servers_4horas.forEach(num => {
          ocurrencias.set(num, (ocurrencias.get(num) || 0) + 1);
      });
      
      // Filtrar los elementos que aparecen solo una vez
      const arraySinDuplicados = array_servers_4horas.filter(num => ocurrencias.get(num) === 1);
      console.log(array_servers_4horas)
      console.log(arraySinDuplicados);
      this.changeCityForChard('all');
    })
  }

  calcularDiferencia(type,a) {
    return new Promise(resolve=>{
      // console.log(type,this.serversDirectas[type],a)
      const setA = new Set(a);
      const setB = new Set(this.serversDirectas[type]);
      const diferencia = new Set(Array.from(setB).filter(x => !setA.has(x)));
      resolve(diferencia.size);
    })
  }

  public chartServiciosServersDirectas: Partial<ChartOptions>;
  public chartData = [];
  changeCityForChard(type){
    console.log(this.chart_direct_servers_data);
    this.chartData = [];
    if(type == 'all'){
      this.chartData.push( {
        name: "Directas sin servicio",
        data: this.chart_direct_servers_data.direct_servers_without_services,
      })
      this.chartData.push( {
          name: "Servicios agendados",
          data: this.chart_direct_servers_data.scheduled_services,
        })
      this.chartData.push( {
          name: "Directas solo 4 horas",
          data: this.chart_direct_servers_data.service_4_hours,
        })
      this.chartData.push( {
          name: "Directas solo 6 horas",
          data: this.chart_direct_servers_data.service_6_hours,
        })
      this.chartData.push( {
        name: "Server no directas agendadas",
        data: this.chart_direct_servers_data.non_direct_servers,
      })
      this.chartData.push( {
        name: "Server con doble servicio",
        data: this.chart_direct_servers_data.servers_with_double_service,
      })
    }else if(type == 'antioquia'){
      this.chartData.push( {
        name: "Directas sin servicio",
        data: this.chart_direct_servers_data.med_direct_servers_without_services,
      })
      this.chartData.push( {
        name: "Servicios agendados",
        data: this.chart_direct_servers_data.med_scheduled_services,
      })
      this.chartData.push( {
        name: "Directas 4 horas",
        data: this.chart_direct_servers_data.med_service_4_hours,
      })
      this.chartData.push( {
        name: "Directas 6 horas",
        data: this.chart_direct_servers_data.med_service_6_hours,
      })
      this.chartData.push( {
        name: "Server no directas agendadas",
        data: this.chart_direct_servers_data.med_non_direct_servers,
      })
      this.chartData.push( {
        name: "Server con doble servicio",
        data: this.chart_direct_servers_data.med_servers_with_double_service,
      })
    }else if(type == 'cundinamarca'){
      this.chartData.push( {
        name: "Directas sin servicio",
        data: this.chart_direct_servers_data.cun_direct_servers_without_services,
      })
      this.chartData.push( {
        name: "Servicios agendados",
        data: this.chart_direct_servers_data.cun_scheduled_services,
      })
      this.chartData.push( {
        name: "Directas 4 horas",
        data: this.chart_direct_servers_data.cun_service_4_hours,
      })
      this.chartData.push( {
        name: "Directas 6 horas",
        data: this.chart_direct_servers_data.cun_service_6_hours,
      })
      this.chartData.push( {
        name: "Server no directas agendadas",
        data: this.chart_direct_servers_data.cun_non_direct_servers,
      })
      this.chartData.push( {
        name: "Server con doble servicio",
        data: this.chart_direct_servers_data.cun_servers_with_double_service,
      })
    }
    this.load_chard();
  }
  load_chard(){
    console.log(this.chartData);
    let coloresGrafica = [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
      "#26a69a",
      "#D10CE8"
    ];
    this.chartServiciosServersDirectas = {
      series: this.chartData,
      chart: {
        height: 550,
        type: "area",
      },
      colors: coloresGrafica,
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Servcios",
        align: "left"
      },
      xaxis: {
        type: "category",
        categories: this.chart_direct_servers_data.days,
        title: {
          text: "Dia"
        },
      },
    /*   tooltip: {
        x: {
          format: "dddd/MM/yy"
        }
      }, */
      markers: {
        size: 1
      },
    };
    this.verGrafica = true;
  }


}
