import { Injectable } from '@angular/core';

import * as firebase from 'firebase';


@Injectable({
    providedIn: 'root'
  })

  export class PatchNotesServices {
  
    constructor() { }
  
    public post(patchNotes) {
      return firebase.firestore().doc('patchNotes/' + patchNotes.id).set(patchNotes);
    }

    public getAll(){
      return firebase.firestore().collection('patchNotes').get();
    }
  
    public delete(id) {
        return firebase.firestore().doc('patchNotes/' + id).delete();
      }
  }