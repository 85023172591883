import { Injectable } from '@angular/core';
import { OptionsColor } from './OptionsColor';
import { ListExtractService } from './dataInfo';
import * as moment from 'moment';
import { User } from '../models/User.model';
import { ServerInterface } from '../models/interface/Server.interface';
import { AngularFireStorage } from 'angularfire2/storage';
import * as _ from 'lodash';
import {settingsUpfile} from './settingsUpfile';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LocalDataSource } from 'ng2-smart-table';

import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";

import { airbnb } from "../../assets/fonts/airbnb.ttf.Base64.encoded";
import { AirbnbCereal_W_Bd } from "../../assets/fonts/AirbnbCereal_W_Bd.base64";
import { JazminAltBoldEncoded } from "../../assets/fonts/JazminAlt-Bold.encoded";

pdfFonts.pdfMake.vfs["AirbnbCereal_W_Bd"] = AirbnbCereal_W_Bd;
pdfFonts.pdfMake.vfs["airbnb_b64"] = airbnb;
pdfFonts.pdfMake.vfs["jazmin_alt"] = JazminAltBoldEncoded;
pdfMake.fonts = {
	airbnb_b64: {
		normal: "airbnb_b64",
		bold: "AirbnbCereal_W_Bd",
		italics: "airbnb_b64",
		bolditalics: "airbnb_b64",
	},
	jazmin_alt: {
		normal: 'jazmin_alt',
		bold: "jazmin_alt",
		italics: "jazmin_alt",
		bolditalics: "jazmin_alt",
	},
}
@Injectable({
  providedIn: 'root'
})

export class Helper {
    constructor(
        private storage: AngularFireStorage,
    ) { }
    public uploadProcess: Observable<number>;
    public uploadFinish: Observable<string>;

    private settingsUpload: settingsUpfile ={
        expRegular: (/\.(jpg|jpeg|png|pdf)$/i),
        typeMatch: ["jpg","jpeg","png","pdf"],
        maxSizeFile: 10,
        multiple: false,
        collback: ()=>{}
    }
    //Se evalua la disponibilidad de la server
    public async seleccionHora(servicesList: any = [], serverList, dateService: any, hourStart: any, hoursService: any ) {
        let serviciosbyServer: any = [];
        let serverDisponibles: any = [];

        if (hoursService && dateService && hourStart) {
            let horaInicioServicio = parseInt(hourStart);
            let horaFinServicio = parseInt(hoursService) + horaInicioServicio;
            //Obtengo todos los servicios para la fecha indicada
            await servicesList.map( async(datos) =>{
                let nuevoObjeto = {};
                let serverServicio = [];
                //Agrupamos los servicios por server
                await datos.forEach(element => {
                        let servIndividual = element.data();
                        if (!nuevoObjeto.hasOwnProperty(servIndividual.idServer)) {
                            nuevoObjeto[servIndividual.idServer] = [];
                        }
                        nuevoObjeto[servIndividual.idServer].push({
                            duracionServicio: servIndividual.horasDeServicio,
                            horaInicio: parseInt(servIndividual.hora),
                            horaFin: parseInt(servIndividual.hora) + parseInt(servIndividual.horasDeServicio),
                        })
                });
                //Obtengo las server que no estan disponible
                serviciosbyServer = nuevoObjeto;
                let serverEliminar = [];
                for (let listServers in serviciosbyServer) {
                        let server = listServers;
                        serviciosbyServer[listServers].forEach(element => {
                            for (let i = element.horaInicio; i < element.horaFin + 1; i++) {
                            if (i >= horaInicioServicio && i <= horaFinServicio) {
                                if (!serverEliminar.includes(server)) {
                                serverEliminar.push(server);
                                break;
                                }
                            }
                            }
                        });
                }
                //Se agregan las servers disponibles a un nuevo array
                if (serverEliminar.length > 0) {
                        let serv = [];
                        serverList.forEach(element => {
                            let server = element;
                            if (!serverEliminar.includes(server.id)) {
                            serv.push(server);
                            }
                        });
                        console.log({serv});
                        return serv;
                }else {
                    console.log({serverList});
                    return serverList;
                }
            })
        }
    }

    /**
     * Estraer color de un array de colores segun
     * la propiedad camp
     * @param camp 
     * @param option 
     */
    public ExtractColor(camp: string, option: Array<OptionsColor>, property: string = 'color'){
        // console.log(camp,option,property)
        const search = option.filter( (item: OptionsColor)=> item.name == camp);
        const color = (typeof search == "object" && search.length) ? search[0][property] : null;
        // console.log('A ver esto que es -------',search,'-----',color)
        return color;
    }

    /**
     * Saber si un Json tiene propiedades
     * @param json 
     */
    public resetProperty(json){
        return ((Object.keys(json)).length > 0)
    }


    /**
     * Convertir una fecha de tipo string en tipo number
     * @param date 
     */
    public dateNumberToString(date){
        const New_format = moment(date).format('YYYY-MM-DD h:mm A');
        return New_format;
    }

    /**
     * Convertir una fecha de tipo number en tipo string
     * @param date 
     */
    public dateStringToNumber(date){
        const New_format = moment(date).format('YYYY-MM-DD h:mm A');
        return Date.parse(New_format.toString());
    }

    /**
     * Convertir una fecha de tipo number en tipo string
     * @param date 
     */
    public dateDefaultNotHour(date){
        const New_format = moment(date).format('YYYY-MM-DD');
        const notHour = New_format.split(" ");
        return notHour[0];
    }

    public async newServiceStructure(service: any, lsitClient: Array<any>, listServer: Array<any>){
        const NewService = [];
        const listOfficeService: any = this.createListService(ListExtractService, service);
        const Client = lsitClient.filter((user: User ) => (user.id && service.userid))[0];
        const Server = lsitClient.filter((server: ServerInterface ) => (server.id && service.idServer))[0];
        NewService
        return NewService;
    };

    public createListService(ListServices, service){
        const listOfficeService: any= [];
        const isServices: any = service['services'];

        (isServices && isServices.length) 
        ? ListServices.map(lItem =>{
            isServices.map((sItem) => { 
                (lItem.value == (sItem.name || sItem.value)) ? listOfficeService.push(lItem): null; 
                // console.log({isServices, [lItem.value]: service[lItem.value]});
            });
          }) 
        : ListServices.map(item =>{
            (service[item.value]) ? listOfficeService.push(item): null; 
            // console.log({ [item.value]: service[item.value]});
            });
        return listOfficeService;
    }

    public notUndefined(data){
        return (typeof data !== 'undefined' && typeof data !== typeof undefined) && typeof data !== null;
    }

    /**
     * 
     * @param data Texto uno
     * @param text Testo dos
     * @param igual Tipo de validacion == o !==
     */

    public compareText(data: string, text: string, igual: boolean = true){
        const d = data.toLocaleLowerCase();
        const t = text.toLocaleLowerCase();
        return (d && t && igual) ? 
                    (d == t) ? true : false
               : (d && t && !igual) ? 
                    (d !== t) ? true : false
                    : false; 
    }

    public slideCouple(elementId: string, messengerId: string, status: boolean = false){
        try {
            const element: HTMLElement = document.getElementById(elementId) as HTMLElement;
            const messenger: HTMLElement = document.getElementById(messengerId) as HTMLElement;
            status ? setTimeout( ()=> {
                
            }, 0): null;
            
        } catch (error) {
          console.error('slideCouple: ',error);
        }
        

    }

    /**
     * 
     * @param list Array de Ojectos JSON
     * @param property Propiedad a agregar o actualizar
     * @param text Texto que ira en la propiedad
     */

    public createBlockUpdate(list: Array<any>, property: string, text: string){
        const update: any = {};
        list.map((item) =>{
            const path = `${item.id}/${property}`;
            update[path] = text;
        });
        return update;
    }

    /**
     * 
     * @param event 
     * @param collback 
     * @param settigns 
     */

    public onFileReader(
        event: any,
        callback: Function = ()=>{},
        settigns: settingsUpfile = this.settingsUpload,
        ){
            console.log(callback)
        try {
            const { files } = event.target;
            // Esta propiedad actualmente es para permitir
            // Procesar multiples archivos
            const File = settigns.multiple ? files[0]: files[0];
            // Obtenemos el tipo de archivo y el peso
            const { size, type } = File;
            // Convertimos el peso de la imagen en megas
            const Size = Math.ceil((size/1000)/1000);
            // Filtramos el tipo de archivo con el array de tipos permitidos
            const Ext = settigns.typeMatch.filter((ext)=> (type.match(`image/${ext}`)))[0];

            //console.log({Size ,name, size, type});
            
            if(Size <= settigns.maxSizeFile){
                const Render = new FileReader();
                if(File && Ext){
                    // Creamos un Id unico en base 64
                    const id    = Math.random().toString(36).substring(2);
                    // Tomamos la fecha del sistema
                    const date  = Date.now();
                    // Nombre para actualizar ? Nuevo archivo
                    const nameFile  = `${date}_${id}`;
                    // Procesar imagen, para obtener la ruta del archibo rederizado
                    // Debemos paras una funcion en callback para recibir este dato
                    Render.onload = (event: ProgressEvent) => {callback( ((<FileReader>event.target).result) )};
                    Render.readAsDataURL(File);
                    // Retornamos un objeto con los datos del archivo
                    // Que se utilizara para subir al servidor
                    return {
                        name: `${nameFile}.${Ext}`,
                        file: File,
                    }
                }else{ throw new Error(`${type} Este formato no es permitido`); }
            }else{ throw new Error(`${Size} Supera el maximo permitido (10Mb)`); }
            
        } catch (error) {
            throw new Error("Error!: "+error);
        }
    }

    /**
     * 
     * @param file 
     * @param path 
     * @param name 
     * @param getUrl 
     * @param percentage 
     */

    public postPicture(
        file:       any, 
        path:       string, 
        name:       string, 
        getUrl:     Function = (event: Observable<string>) => {},
        percentage: Function = (event: Observable<number>) => {}
        ) {

        // Verificar si es una actializacion
        const isupdate = (name.indexOf(path, 0) >= 0);
        // Creamos la path completo carpeta/name.ext
        const namePath  = isupdate ? name : `${path}/${path}_${name}`;
        // Creamos una referencia de path de storege
        const storageRef = this.storage.ref(namePath);
        // Creamos uns referencia de la tarea de subida
        const task = storageRef.put(file);
        // Observamos el progreso de la subida del archivo
        this.uploadProcess = task.percentageChanges();
        percentage(this.uploadProcess); 
        // Observamos y optenemos la url del archivo subido
        task.snapshotChanges().pipe( finalize( ()=> {
            this.uploadFinish = storageRef.getDownloadURL();
            getUrl(this.uploadFinish);
        })).subscribe();
        
        //console.log({ namePath });
        return task;
        
    }

    /**
     * 
     * @param data_text // Texto referencia 
     * @param text // Texto a comparar
     */
    public ValidateToLowerCase(data_text: string, text: string){
        return (data_text && text) && (data_text.toLowerCase() == text.toLowerCase()) 
        ? true : false; 
    }

    /**
     * 
     * @param data // Array para ordenar
     * @param property Propiedad como referencia para ordenar
     * @param order Tipo de orden acendente o decendente: asc | desc
     */
    public orderByArray(data: Array<any>, property: string = 'fechaCreacion', order: string = "asc"){
        const NewOrder: Array<any> = _.orderBy(data, [property]);
        return NewOrder;
    }
    /**
     * 
     * @param data Array de Objectos JSON
     * @param property Propiedad a filtrar y ordenar
     * @param order Tipo de orden   
     */
    public setLocalDataSource (data: Array<any>, property: string = 'fechaCreacion', order: string = "asc"){
        let ordenBy = this.orderByArray(data, property, order);
        return new LocalDataSource(ordenBy);
    }

	public exportCronograma(data:Array<any>){
		const documentDefinition: any = {
			pageSize: {
				width: 1416,
				height: 2004,
			},
			background: function(currentPage, pageSize) {
        if (currentPage == 1) {
            return { image: "background", width: 1416, height: 2004};
        }else{
					return { image: "background_2", width: 1416, height: 2004};
				}
  		},
			// background: [
			// 	{
			// 		image: "background",
			// 		width: 1416,
			// 		height: 2004,
			// 	},
			// ],
			pageMargins: [0,300,0, 200],
			content: [
				{
					absolutePosition: { x: 200, y: 350 },
					stack: [] 
				},
			],
			defaultStyle: {
				font: 'airbnb_b64',
				fontSize: 24
			},
			images: { 
				background: "https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fbackgroud_pdf_cronograma_tareas.png?alt=media&token=6b8cda2d-6b98-44ee-b325-ec8b1b27b11a",
				background_2: "https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/Admin_pictures%2Fbackgroud2_pdf_cronograma_tareas.png?alt=media&token=f37dd1e9-dd85-4e1f-b741-57ededf76f20"
			},
			styles: {
				subTitle: {
					fontSize: 40,
					bold: true,
					color: "#d0e8ff",
					margin: [0,15,0,0]
				},
				taskContent:{
					margin: [20,5,200,0]
				},
				taskTitle:{
					fontSize:30,
					bold: true,
					color: "#d0e8ff"
				},
				taskDescription:{
					fontSize:30,	
					color: "#d0e8ff"
				}
			}
		};
		let cronograma = []
		for(let a = 0;a < data.length;a++){
			cronograma.push( {text: `Lunes${data[a].dia}\n`, style: 'subTitle'})
			for(let b = 0;b < data[a].taskList.length;b++){
				cronograma.push({ 
					text:[
						{text: `${data[a].taskList[b].name}:`, style: 'taskTitle'},
						{text: `${data[a].taskList[b].description}`,style: 'taskDescription'}
					],
					style: 'taskContent'
				})
			}
		}
		documentDefinition.content[0].stack = cronograma;
		pdfMake.createPdf(documentDefinition);
		pdfMake.createPdf(documentDefinition).open();
	}

}

