import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { UsuarioService } from 'src/app/services/usuario.service';

@Injectable({
    providedIn: 'root'
  })
  export class funcionesUsuario {
    constructor(
			public usuarioService: UsuarioService,
			private toastrService: NbToastrService,
			){}
    public comentariosCliente(tipo,usuario,comentario){
      return new Promise(resolve=>{
        if (comentario){
          if(tipo == 'logistica'){
            if(usuario.comentario == undefined){
              console.log("sin comentario anterior")
              usuario.comentario = []
              usuario.comentario.push(comentario);
              //this.ingresarComentarioLogistica = false;
            } else if(typeof usuario.comentario === 'string'){
              console.log("ya tiene un comentario string",usuario.comentario);
              usuario.comentario = []
              usuario.comentario.push(comentario);
              //this.ingresarComentarioLogistica = false;
            } else{
              console.log("ya tiene un comentario en el array",usuario.comentario);
              usuario.comentario.push(comentario);
              console.log(usuario.comentario)
              //this.ingresarComentarioLogistica = false;
            }
          } else if( tipo == 'server'){
            if(usuario.comentarioServer == undefined){
              usuario.comentarioServer = []
              usuario.comentarioServer.push(comentario);
              //this.ingresarComentarioServer = false;
            } else if(typeof usuario.comentarioServer === 'string'){
              console.log("ya tiene un comentario string",usuario.comentario);
              usuario.comentarioServer = []
              usuario.comentarioServer.push(comentario);
            } else{
              usuario.comentarioServer.push(comentario);
              //this.ingresarComentarioServer = false;
            }
          }
          console.log("Lo que va a guardar",usuario)
          this.usuarioService.updateClient(usuario).then(res =>{
          this.showToast('top-right', 'success', 'Éxito!', 'se agrego el comentario');
          if(tipo == 'server'){
            return resolve(usuario.comentarioServer);
          }else{
            return resolve(usuario.comentario);
          }
          });
        }
        else {
          this.showToast('top-right', 'warning', 'warning!', 'Debe ingresar un comentario');
          resolve(false)
        }
      })
        

    }

		showToast(position, status, titulo, mensaje){
			this.toastrService.show(
				mensaje,
				titulo,
			{ position, status });
		}

    public tareasCliente(usuario,tarea){
      return new Promise(async resolve=>{
        console.log('Usuario',usuario.taskList )
        let taskList = usuario.taskList != undefined ? usuario.taskList : [];
        taskList.push(JSON.parse(tarea));
        const data = {
          id: usuario.id,
          taskList: taskList
        }
        console.log(data);
        await this.usuarioService.updateClient(data)
        this.showToast('top-right', 'success', 'Éxito!', 'se agrego la tarea');
        resolve(true)
      })
    }
  }