import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class LoopayService {

  constructor() { }

  getPayOuts(){
    return firebase.firestore().collection('disperciones_loopay').orderBy('createdAt','desc').get(); 
  }
}
