import { Component, OnInit } from "@angular/core";
import { CompleterService } from "ng2-completer";
import { LocalDataSource } from 'ng2-smart-table';
import { IMyDateRangeModel } from 'mydaterangepicker';
import * as moment from "moment";
import 'moment/locale/es';
moment.locale('es');
import * as _ from "lodash";
import * as $ from 'jquery';
/* Servicios */
import { ServicioService } from 'src/app/services/servicio.service';
import { ServerService } from 'src/app/services/server.service';
import { PlanService } from 'src/app/services/plan.service';
import { Router } from '@angular/router';
import { __core_private_testing_placeholder__ } from "@angular/core/testing";


@Component({
	selector: 'app-nueva-disponibilidad',
	templateUrl: './nueva-disponibilidad.component.html',
	styleUrls: ['./nueva-disponibilidad.component.scss']
})

export class NuevaDisponibilidadComponent implements OnInit {
  
  constructor(
    public servicioService: ServicioService,
    public serverService: ServerService,
    public planService:PlanService
    ){
      
    }
    public dias = [];
    public fchLunes = [];
    public fchMartes = []; 
    public fchMiercoles = []; 
    public fchJueves = []; 
    public fchViernes = []; 
    public fchSabado = []; 
    public fchDomingo = [];
    public sourceDisponibilidad: any = [];
  
    public inicioSemana = moment().startOf('isoWeek').format('YYYY-MM-DD'); 
    public inicioSemanaView = moment(this.inicioSemana).format('dddd, D [de] MMMM');
    // public diaInicio = moment().startOf('isoWeek').format('DD');
    public finSemana = moment().endOf('isoWeek').format('YYYY-MM-DD');
    public finSemanaView = moment(this.finSemana).format('dddd, D [de] MMMM');
    public tipo = "Plan Mensual";
    public fecha = new Date(this.inicioSemana)
    public serviciosTipoPlan:any=[];
    public hoy = moment().format('YYYY-MM-DD');
      
    ngOnInit() {
        // for(let i = 1;i <= 7; i++ ){
        //   this.fecha.setDate(this.fecha.getDate() + 1);
        //   let fechaFormat = moment(this.fecha).format('dddd DD-MMM');
        //   if(i ==1 ){
        //     this.fchLunes.push(fechaFormat)
        //   } else if(i == 2 ){
        //     this.fchMartes.push(fechaFormat)
        //   }  else if(i == 3 ){
        //     this.fchMiercoles.push(fechaFormat)
        //   }  else if(i == 4 ){
        //     this.fchJueves.push(fechaFormat)
        //   }  else if(i == 5 ){
        //     this.fchViernes.push(fechaFormat)
        //   }  else if(i == 6 ){
        //     this.fchSabado.push(fechaFormat)
        //   }  else if(i == 7 ){
        //     this.fchDomingo.push(fechaFormat)
        //   }
        //   this.dias.push(fechaFormat);
        // }
        // console.log(this.dias)
        // this.fchMartes = this.dias[2];
        // this.fchMiercoles = this.dias[3];
        // this.fchJueves = this.dias[4];
        // this.fchViernes = this.dias[5];
        // this.fchSabado = this.dias[6];
        // this.fchDomingo = this.dias[7];
        // console.log(this.inicioSemana,this.finSemana);
        this.getServicios();
    }
    public settings = {
      actions:{
        delete: false,
        edit: false,
        add: false,
      },
      columns: {
        nombre: { 
              width:'5%',
              title: "Server"
        },
        lunes: { 
          width:'13.2%',
          type: 'html',
          title: "Lunes",
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                    <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                    <div class="cardTableBody">
                    <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                    <p>Estado del pago: ${item.estadoPago}</p>
                    <p>Servicio: ${item.subCategoria}</p>
                   <p>Estado: ${item.estado}</p>
                    <p>Duracion: ${item.horasDeServicio}</p>
                    </div> 
                    </div>`;
            });
            return list
          }
        },
        martes: { 
          width:'13.2%',
          type: 'html',
          title: "Martes",
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                    <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                    <div class="cardTableBody">
                    <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                    <p>Estado del pago: ${item.estadoPago}</p>
                    <p>Servicio: ${item.subCategoria}</p>
                   <p>Estado: ${item.estado}</p>
                    <p>Duracion: ${item.horasDeServicio}</p>
                    </div> 
                    </div>`;
            });
            return list
          }
        },
        miercoles: { 
          width:'13.2%',
          title: "Miercoles",
          type: 'html',
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                      <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                      <div class="cardTableBody">
                      <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                      <p>Estado del pago: ${item.estadoPago}</p>
                      <p>Servicio: ${item.subCategoria}</p>
                     <p>Estado: ${item.estado}</p>
                      <p>Duracion: ${item.horasDeServicio}</p>
                      </div> 
                      </div>`;
              });
            return list
          }
        },
        jueves: { 
          width:'13.2%',
          title: "Jueves",
          type: 'html',
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                        <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                        <div class="cardTableBody">
                        <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                        <p>Estado del pago: ${item.estadoPago}</p>
                        <p>Servicio: ${item.subCategoria}</p>
                       <p>Estado: ${item.estado}</p>
                        <p>Duracion: ${item.horasDeServicio}</p>
                        </div> 
                        </div>`;
            });
            return list
          }
        },
        viernes: { 
          width:'13.2%',
          title: "Viernes",
          type: 'html',
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                        <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                        <div class="cardTableBody">
                        <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                        <p>Estado del pago: ${item.estadoPago}</p>
                        <p>Servicio: ${item.subCategoria}</p>
                       <p>Estado: ${item.estado}</p>
                        <p>Duracion: ${item.horasDeServicio}</p>
                        </div> 
                        </div>`;
            });
            return list
          }
        },
        sabado: { 
          width:'13.2%',
          title: "Sabado",
          type: 'html',
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                        <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                        <div class="cardTableBody">
                        <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                        <p>Estado del pago: ${item.estadoPago}</p>
                        <p>Servicio: ${item.subCategoria}</p>
                       <p>Estado: ${item.estado}</p>
                        <p>Duracion: ${item.horasDeServicio}</p>
                        </div> 
                        </div>`;
            });
            return list
          }
        },
        domingo: {
          width:'13.2%',
          title: "Domingo",
          type: 'html',
          valuePrepareFunction: (value) => {
            let list: string = '';
            let array = value
            array.map(item => {
              //console.log(item);
              list = list + `<div class="cardTable">
                        <div class="cardTableHeader">Cliente: ${item.nameClient}</div>
                        <div class="cardTableBody">
                        <p>Fecha vencimiento: ${item.fechaVencimiento}</p>
                        <p>Estado del pago: ${item.estadoPago}</p>
                        <p>Servicio: ${item.subCategoria}</p>
                       <p>Estado: ${item.estado}</p>
                        <p>Duracion: ${item.horasDeServicio}</p>
                        </div> 
                        </div>`;
            });
            return list
          }
        },
      },
    };

    public onDateRangeChanged(event: IMyDateRangeModel) {
      this.inicioSemana = moment(event.beginJsDate).format('YYYY-MM-DD');
      this.inicioSemanaView = moment(this.inicioSemana).format('dddd, D [de] MMMM');
      this.finSemana    = moment(event.endJsDate).format('YYYY-MM-DD');
      this.finSemanaView = moment(this.finSemana).format('dddd, D [de] MMMM');
      this.getServicios()
    }
  
  public serversConPlanes: any;
  public getServicios(){
    let lengthServicios:number;
    let countServicios = 0;
    let arraySemanaServer = [];
    this.serverService.Actives().then((res)=>{
      res.docs.forEach(async (data, index)=>{
        const server = data.data();
        // console.log(server);
        let disponibilidadServer: any = [];
        let nombreServer = server.name+ " " + server.apellido;
        disponibilidadServer.nombre = server.name;
        const serverVerificada = {
          nombre: nombreServer,
          id: server.id,
          lunes:     [],
          martes:    [],
          miercoles: [],
          jueves:    [],
          viernes:   [],
          sabado:    [],
          domingo:   []
        }
          //Funcion para traer disponibilidad por planes\\
          this.planService.getPlanesByServerId(server.id).then(value=>{
            // console.log("planes por server",value.docs,value.docs.length);
            lengthServicios = value.docs.length
            if(lengthServicios > 0){
              value.docs.forEach(doc=>{
                //console.log("otro plan");
                let plan = doc.data();
            /*     let pagoVencido = false
                let fechaVencimientoPago: any;
                let fechaInicioPlan = plan.infoServicio[plan.infoServicio.length - 1].fecha
                if(plan.fechaLimitePago == "pago adelantado"){
                  fechaVencimientoPago = moment(fechaInicioPlan).subtract(1, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                }else if(plan.fechaLimitePago == "a 7 días"){
                  fechaVencimientoPago = moment(fechaInicioPlan).add(7, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                }else if(plan.fechaLimitePago == "a 15 días"){
                  fechaVencimientoPago = moment(fechaInicioPlan).add(15, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                }else if(plan.fechaLimitePago == "a 30 días"){
                  fechaVencimientoPago = moment(fechaInicioPlan).add(30, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                }else if(plan.fechaLimitePago == "a 45 días"){
                  fechaVencimientoPago = moment(fechaInicioPlan).add(45, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                }else if(plan.fechaLimitePago == "a 90 días"){
                  fechaVencimientoPago = moment(fechaInicioPlan).add(90, 'd').format("YYYY-MM-DD");
                  plan.fechaVencimientoPago = fechaVencimientoPago;
                } */
                if(plan.estado != 'Cancelado'){
                  // console.log(plan)
                  // console.log("Paso",plan.idServers,fechaVencimientoPago,this.hoy)
                  let cliente = plan.client
                  cliente.name != undefined ? plan.nameClient = cliente.name + cliente.apellido : plan.nameClient = "Indefinido";
                  let lunesVerificado     = false
                  let martesVerificado    = false
                  let miercolesVerificado = false
                  let juevesVerificado    = false
                  let viernesVerificado   = false
                  let sabadoVerificado    = false
                  let domingoVerificado   = false
                  for(let fecha of plan.infoServicio){
                    //console.log("fechas del for",fecha);
                    let fechaDia = new Date(fecha.fecha)
                    if(fechaDia.getDay() == 0 && lunesVerificado == false ){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.lunes.push(plan);
                      lunesVerificado = true
                    } else if(fechaDia.getDay() == 1 && martesVerificado    == false){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.martes.push(plan);
                      martesVerificado = true
                    } else if(fechaDia.getDay() == 2 && miercolesVerificado == false){
                      serverVerificada.miercoles.push(plan); 
                      miercolesVerificado = true
                      plan.horaInicio = fecha.horaInicio;
                    } else if(fechaDia.getDay() == 3 && juevesVerificado    == false){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.jueves.push(plan);
                      juevesVerificado = true
                    } else if(fechaDia.getDay() == 4 && viernesVerificado   == false){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.viernes.push(plan);
                      viernesVerificado = true
                    } else if(fechaDia.getDay() == 5 && sabadoVerificado    == false){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.sabado.push(plan);
                      sabadoVerificado = true
                    } else if(fechaDia.getDay() == 6 && domingoVerificado   == false){
                      plan.horaInicio = fecha.horaInicio;
                      serverVerificada.domingo.push(plan);
                      domingoVerificado = true
                    }
                  }   
                }else{
                  // console.log(plan.idServers,this.hoy)
                }
              })
            }
          })   
        arraySemanaServer.push(serverVerificada)
        // console.log("Array semana server",arraySemanaServer)
      });
      this.sourceDisponibilidad = new LocalDataSource(arraySemanaServer)
      this.serversConPlanes = arraySemanaServer
      console.log("DATOS FINALES PARA LA TABLA",this.serversConPlanes)
    });
  }


  public idPlanSeleccionado = ""
  public planVisualizado: any = "seleccione";
  verPlan(plan){
    this.planVisualizado = plan
    console.log(plan)
    let id = plan.id
    console.log(id)
    let planAntesSeleccionado = $( `.planSeleccionado`)
    console.log(planAntesSeleccionado)
    if(planAntesSeleccionado){
      for(let i = 0;i< planAntesSeleccionado.length;i++){
        planAntesSeleccionado[i].className = this.idPlanSeleccionado 
      }
    }

    if(id != this.idPlanSeleccionado){
      this.idPlanSeleccionado = id;
      let planSeleccionado = $( `.${id}`)
      console.log(planSeleccionado[0])
      for(let i = 0;i< planSeleccionado.length;i++){
        planSeleccionado[i].className = "planSeleccionado" 
      }
    }else{
      this.idPlanSeleccionado = ""
    }
  }
}

//desde el primero
//validar para el pago