const filtrosTablaPlan = [
	{
		'name': 'Filtro'
	},
	{
		'name': 'asesor',
		'categorias': [
			{
				'name': 'Laura Rippe'
			},
			{
				'name': 'Leidy Carolina Barrero Briñez'
			},
		]
	}
]
export {
	filtrosTablaPlan
}