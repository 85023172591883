import { Component, OnInit } from '@angular/core';
import { ServerService } from "../../services/server.service";
import {UsuarioService} from "../../services/usuario.service";
import {UsuarioAdminService} from "../../services/usuarios-admin.service"
import {AuthService} from "../../services/auth.service"
import { ConversationServices} from "../../services/conversation.service"
import * as moment from 'moment';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  listaUsuarios = [];
  public keyAutocomplete: string = "fullName";
  public keyAutocompleteserver: string = "fullNameServer";
  [x: string]: any;
  addInfo: any;
  server: any = {};
  user: any = {};
  conversationId: any;
  conversation: any;
  message: string;
  numero: number;
  convSubs: any;
  cargando =  true;
  cargo = false;
  usuarioEnSecion: any;
  seccionClientes = false;
  seccionServers = false;
  username = '';
  chats = [];
  chatActivo : any;


  constructor(
    public serverService: ServerService,
    public usuarioService: UsuarioService,
    public usuarioAdminService: UsuarioAdminService,
    public authService: AuthService,
    public ConversationServices: ConversationServices,

  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
    console.log(this.usuarioEnSecion);
    this.seccionClientes = true;
    this.seccionServers = false;
    console.log(this.seccionClientes)
    // console.log(this.listaUsuarios);
  }

  ngOnInit() {
    this.getServers();
    this.getUsers();
    this.allServices();
  }

  public async allServices(){
    console.log('Entro en chats');
    const loggedUser = this.usuarioEnSecion.id;
    console.log('revisar aqui lo del usuario', loggedUser);
    this.ConversationServices.getConversationCliente(loggedUser).onSnapshot(res=>{
      this.chats = []
      res.docs.forEach(docs=>{
        this.chats.push(docs.data());
      });
    });
  }


   
  async getServers() {
    let source: any = [];
    let sub = this.serverService.Actives()
      .then((item) => {
        item.docs.forEach((data,index)=>{

          source.push(data.data());
          if(index == (item.docs.length-1)){
            source.filter(server=> (server["fullNameServer"] = `${server.name} ${server.apellido}`) && server.id != "56VnqwDwXhdWQToWbflQMJEMcyB2");// cambiar el 2 final por un 3 para sacar adigital 
            this.listaUsuariosServer = source
          }
        })
      });
      console.log(this.listaUsuarios);
  }

  
  async getUsers() {
    this.UsuarioService.all().then(value => {
      let array: any = [];
        value.docs.forEach(element => {
          let datos: any = element.data();
          datos.fullName = datos.name + " " + datos.apellido;
          array.push(datos);
          this.listaUsuarios = array;
        });
    });
    console.log(this.listaUsuarios);
  }
 public seccionChatsClientes(){
      this.seccionClientes = true;
      this.seccionServers = false;
      console.log("entra en la funcion");
      console.log(this.seccionClientes)
 }

 public seccionChatsServers(){
  this.seccionClientes = false;
  this.seccionServers = true;
  console.log("entra en la funcion");
  console.log(this.seccionClientes)
}

public crearChatServer(server){
  console.log('entra a la funcion');
  console.log(server);
  const idChat = `${this.usuarioEnSecion.id}||${server.id}`;
  console.log(idChat);
  this.ConversationServices.getConversationRealTime(idChat).onSnapshot(data=>{
    if(data.docs.length > 0){
      this.conversation = data.docs[0].data();
      this.validarMensajes();
      console.log( 'este es revisar', this.conversation);
      this.cargo = true;
        this.cargando = false;
    } else {
      const b = {
        id: idChat,
        mensaje:[],
        server: server,
        tipo: "equipo serv",
        idServer: server.id,
        usurioAdmin: this.usuarioEnSecion,
        idUsuarioAdmin: this.usuarioEnSecion.id,
      };
      this.conversation = b;
      this.ConversationServices.updateChat(b);
      console.log( 'se creo aqui', this.conversation);
      this.cargando = false;
    }
  });
}


  public crearChatCliente(usuario){
    console.log('entra a la funcion');
    console.log(usuario);
    const idChat = `${this.usuarioEnSecion.id}||${usuario.id}`;
    console.log(idChat);
    this.ConversationServices.getConversationRealTime(idChat).onSnapshot(data=>{
      if(data.docs.length > 0){
        this.conversation = data.docs[0].data();
        this.validarMensajes();
        console.log( 'este es revisar', this.conversation);
        this.cargo = true;
          this.cargando = false;
      } else {
        const b = {
          tipo: "equipo serv",
          id: idChat,
          mensaje:[],
          idUsuarioAdmin: this.usuarioEnSecion.id,
          idCliente: usuario.id,
          usurioAdmin: this.usuarioEnSecion,
          cliente: usuario,
        };
        this.conversation = b;
        this.ConversationServices.updateChat(b);
        console.log( 'se creo aqui', this.conversation);
        this.cargando = false;
      }
    });
  }

  validarMensajes(){
    if(this.conversation.lastSender !== this.conversation.idUsuarioAdmin){
      this.conversation.read = true;
    }
    this.ConversationServices.updateChat(this.conversation);
  }

  sendMessage(){
    const messageObject: any = {
      hourMessag: moment().format('h:mm a'),
      timestamp: Date.now(),
      sender: this.chatActivo.idUsuarioAdmin,
      receiver: this.chatActivo.idCliente,
      type: 'text',
      content: this.message,
      sendertype: 'usersAdmin',
      receiverType: 'client',
    };
    this.chatActivo.read = false;
    this.chatActivo.mensaje.push(messageObject);
    this.chatActivo.lastSender = this.chatActivo.idUsuarioAdmin;
    console.log('despues de el this.conversation', this.chatActivo);
    this.ConversationServices.updateChat(this.chatActivo).then((data)=>{
      this.message = ""
      }).catch((error) => {
        console.log('aqui esta el erro');
      });
    
  }

  sendMessageServers(){
    const messageObject: any = {
      hourMessag: moment().format('h:mm a'),
      timestamp: Date.now(),
      sender: this.chatActivo.idUsuarioAdmin,
      receiver: this.chatActivo.idServer,
      type: 'text',
      content: this.message,
      sendertype: 'usersAdmin',
      receiverType: 'server',
    };
    this.chatActivo.read = false;
    this.chatActivo.mensaje.push(messageObject);
    this.chatActivo.lastSender = this.chatActivo.idUsuarioAdmin;
    console.log('despues de el this.conversation', this.chatActivo);
    this.ConversationServices.updateChat(this.chatActivo).then((data)=>{
      this.message = ""
      }).catch((error) => {
        console.log('aqui esta el erro');
      });
    
  }


elegirChat(chat){
  this.ConversationServices.getConversationRealTime(chat.id).onSnapshot(data=>{
        this.chatActivo = data.docs[0].data();
        console.log(this.chatActivo);
  })
}



}
