import { Pipe, PipeTransform, } from '@angular/core';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {

  private daysOfWeek: string[] = [
    'Domingo',   // 0
    'Lunes',     // 1
    'Martes',    // 2
    'Miércoles', // 3
    'Jueves',    // 4
    'Viernes',   // 5
    'Sábado'     // 6
  ];

  transform(value): string {
    let index = parseInt(value);
    return this.daysOfWeek[index];
  }

}



@Pipe({
  name: 'loopayStates'
})
export class LoopayStates implements PipeTransform {

  private states = {
    onHold: 'En espera',
    delivered: 'Completado',
    received: 'Completado',
    rejected: 'Rechazado',
    canceled: 'Cancelado'
  }

  transform(value): string {
    return this.states[value] || 'Por gestionar';
  }

}

@Pipe({
  name: 'loopayValues'
})
export class LoopayValues implements PipeTransform {

  private states = {
    beneficiaryName: 'Nombre destinatario',
    state: 'Estado',
    createdAt: 'Fecha creacion',
    amount: 'Valor',
  }

  transform(value): string {
    return this.states[value] || value;
  }

}