import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import {
  CalendarEvent,
  CalendarView,
  CalendarMonthViewBeforeRenderEvent
} from 'angular-calendar';
import { ServicioService } from 'src/app/services/servicio.service';
import { ServerService } from 'src/app/services/server.service';
import { ServiciosComponent } from '../servicios/servicios.component';
import { LocalDataSource } from 'ng2-smart-table';
import { UsuarioService } from 'src/app/services/usuario.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendario-servicios',
  templateUrl: './calendario-servicios.component.html',
  styleUrls: ['./calendario-servicios.component.scss']
})

export class CalendarioServiciosComponent implements OnInit, OnDestroy {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  listaCalendarioServicio: any[];
  listaServicios: any[];
  muestraDetalle = false;
  propiedadSeleccionada: any;
  propiedades = [];
  usuarioActivo: any;
  reservasPropiedad: any[];
  listaMostrar: any[];
  public server: any;
  public serv: any;
  public serverList: any = [];
  public serverCargado: boolean = false;
  public userList: any = [];
  public userCagardo: boolean = false;
  public filtrar: any;
  public keyword2: any = "email";
  public idServer: any;
  public idUser: any;
  activeDayIsOpen: boolean = true;
  public source: LocalDataSource;
  public servicios: Array<Object> = [];
  public user: any;
  public serve: any;
  usuario: any;
  ser: any;
  public arrayServicios: Array<Object> = [];
  public arrayServiciosServer: Array<Object> = [];
  public arrayServiciosUser: Array<Object> = [];
  public nameUser: any;
  public nameServer: any;
  public verInfoServicio: boolean = false;
  daysInWeek = 7;
  private destroy$ = new Subject();
  public detalleServicio: boolean = false;
  public servicio: any;
  public verServicioMensuales: boolean = false;
  clickedDate: Date
  clickedColumn: number;

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: 'detalle', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      formaDePago: { title: 'Forma de Pago' },
      fechaCreacion: {
        title: 'Fecha de creación', type: 'string',
        valuePrepareFunction: (fec) => {
          return moment(fec).format('DD/MM/YYYY hh:mm:ss') == 'Invalid date' ? 'N/A' : moment(fec).format('DD/MM/YYYY hh:mm:ss');
        }
      },
      usuario: { title: 'Usuario' },
      direccion: { title: 'Dirección' },
      estado: { title: 'Estado' },
      precio: { title: 'Precio' },
    }
  };
  selectedDays: any = [];
  constructor(
    private modal: NgbModal,
    public ServicioService: ServicioService,
    public ServerService: ServerService,
    public UsuarioService: UsuarioService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getServers();
    this.getUsers();
    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 2
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5
      }
    };
    this.breakpointObserver
      .observe(
        Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
          ({ breakpoint }) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getServers() {
    this.ServerService.all()
      .then((res) => {
        if (this.serverCargado === false) {
          this.serverList = res;
          this.serverCargado = true;
        }
      });
  }
  getUsers() {
    this.UsuarioService.all().then(value => {
      if (this.userCagardo === false) {
        let array: any = [];
        value.docs.forEach(element => {
          let datos: any = element.data();
          datos.nameUser = datos.name + " " + datos.apellido;
          array.push(datos);
        });
        this.userList = array;
        this.userCagardo = true;
      }
    });
  }
  
  getServicios() {
    let arrayprueba = [];
    this.ServicioService.getServicios().then(value => {
      value.docs.forEach(doc => {
        let aux = (doc.data());
        this.ServerService.detail(aux.idServer).then(data => {
          let info: any = data.data();
          if (!info) {
            aux.server = "N/A";
          }
          else {
            aux.server = info.name + " " + info.apellido;
          }
        })
        this.UsuarioService.detail(aux.userid).then(data => {
          let info: any = data.data();
          if (!info) {
            aux.usuario = "N/A";
          }
          else {
            aux.usuario = info.name + " " + info.apellido;
          }
        })
        arrayprueba.push(aux);
      });
      this.arrayServicios = arrayprueba;

      this.calendarioServicios();
    });
  }

  calendarioServicios() {
    let lista = [];
    this.arrayServicios.forEach(element => {
      let servicio: any = element;
      let numeroHoras = parseInt(servicio.horasDeServicio);
      let horaInicioServicio = parseInt(servicio.hora);
      let horaFinServicio = horaInicioServicio + numeroHoras;
      let horaInicio: string;
      let horaFin: string;
      if (horaInicioServicio < 10) {
        horaInicio = '0' + horaInicioServicio.toString();
      }
      else {
        horaInicio = horaInicioServicio.toString();
      }
      if (horaFinServicio < 10) {
        horaFin = '0' + horaFinServicio.toString();
      }
      else {
        horaFin = horaFinServicio.toString();
      }
      let inicio = moment((servicio.fecha + " " + horaInicio.toString())).toDate();
      let fin = moment((servicio.fecha + " " + horaFin.toString())).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.red,
        title: '',
        id: servicio.id,
        obj: servicio,
      };
      lista.push(evento);
    });
    this.listaCalendarioServicio = lista;
  }

  onSelectServer(event) {
    // console.log('ID del servicio'+event.id);
    let arraypruebaServer = [];
    this.idServer = event.id;
    this.ServicioService.getServiciosServer(this.idServer).then(value => {
      value.forEach(doc => {
        let servicio = doc.data();
        servicio.serverName = (servicio.server) ? servicio.server.name + " " + servicio.server.apellido : 'N/A';
        servicio.usuario = (servicio.client) ? servicio.client.name + " " + servicio.client.apellido : 'N/A';
        arraypruebaServer.push(servicio);
        })
      this.arrayServiciosServer = arraypruebaServer;
      this.calendarioServiciosServer();
    })
  }


  onSelectUser(event) {
    let arraypruebaUser = [];
    this.idUser = event.id;
    this.ServicioService.getByUser(this.idUser).then(value => {
      value.docs.forEach(doc => {
        let aux = doc.data();
        this.ServerService.detail(aux.idServer).then(data => {
          let info: any = data.data();
          if (!info) {
            aux.server = "N/A";
          }
          else {
            aux.server = info.name + " " + info.apellido;
          }
        })
        this.UsuarioService.detail(aux.userid).then(data => {
          let info: any = data.data();
          if (!info) {
            aux.usuario = "N/A";
          }
          else {
            aux.usuario = info.name + " " + info.apellido;
          }
        })
        arraypruebaUser.push(aux);
      })
      this.arrayServiciosUser = arraypruebaUser;

      this.calendarioServiciosUser();
    })
  }

  calendarioServiciosServer() {
    let lista = [];
    this.arrayServiciosServer.forEach(element => {
      let servicioServer: any = element;
      let numeroHoras = parseInt(servicioServer.horasDeServicio);
      let horaInicioServicio = parseInt(servicioServer.hora);
      let horaFinServicio = horaInicioServicio + numeroHoras;
      let horaInicio: string;
      let horaFin: string;
      if (horaInicioServicio < 10) {
        horaInicio = '0' + horaInicioServicio.toString();
      }
      else {
        horaInicio = horaInicioServicio.toString();
      }
      if (horaFinServicio < 10) {
        horaFin = '0' + horaFinServicio.toString();
      }
      else {
        horaFin = horaFinServicio.toString();
      }
      let inicio = moment((servicioServer.fecha + " " + horaInicio.toString())).toDate();
      let fin = moment((servicioServer.fecha + " " + horaFin.toString())).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.blue,
        title: '',
        id: servicioServer.id,
        obj: servicioServer,
      };
      lista.push(evento);
    });
    this.listaCalendarioServicio = lista;
  }

  calendarioServiciosUser() {
    let lista = [];
    this.arrayServiciosUser.forEach(element => {
      let servicioUsuario: any = element;
      let numeroHoras = parseInt(servicioUsuario.horasDeServicio);
      let horaInicioServicio = parseInt(servicioUsuario.hora);
      let horaFinServicio = horaInicioServicio + numeroHoras;

      let horaInicio: string;
      let horaFin: string;

      if (horaInicioServicio < 10) {
        horaInicio = '0' + horaInicioServicio.toString();
      }
      else {
        horaInicio = horaInicioServicio.toString();
      }

      if (horaFinServicio < 10) {
        horaFin = '0' + horaFinServicio.toString();
      }
      else {
        horaFin = horaFinServicio.toString();
      }
      let inicio = moment((servicioUsuario.fecha + " " + horaInicio.toString())).toDate();
      let fin = moment((servicioUsuario.fecha + " " + horaFin.toString())).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.blue,
        title: '',
        id: servicioUsuario.id,
        obj: servicioUsuario,
      };
      lista.push(evento);
    });
    this.listaCalendarioServicio = lista;
  }

 /*  clearServer($event) {
    this.getServicios();
  } */

  clearUser($event) {
    this.getServicios();
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    this.selectedDays = [date.getTime()];
    this.verInfoServicio = false;
    this.servicios = [];
    var count = 0;
    this.verServicioMensuales = true;
    events.forEach(element => {
      this.servicios.push(element.obj);
    });
    if (this.servicios) {
      count++;
      setTimeout(() => {
        this.source = new LocalDataSource(this.servicios);

      }, 1000);
    }
    this.refreshView();
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    const self = this;
    renderEvent.body.forEach(day => {
      if (day.date.getTime() === self.selectedDays[0]) {
        day.cssClass = 'cal-day-selected';
      }
    });
  }

  handleEvent(fecha, event) {
    this.servicios = [];
    this.server = "";
    this.usuario = "";
    this.detalleServicio = true;
    this.servicio = event.event.obj;
  }

  ocultarServicio() {
    this.detalleServicio = false;
  }

  ocultarServicioMes() {
    this.verServicioMensuales = false;
  }

  handleEventDay(fecha, event) {
    this.servicios = [];
    this.server = "";
    this.usuario = "";
    this.detalleServicio = true;
    this.servicio = event.event.obj;
  }

  setView(view: CalendarView) {
    this.view = view;
    this.detalleServicio = false;
    this.verServicioMensuales = false;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  refreshView(): void {
    this.refresh.next();
  }

  onCustom(event) {
    if (event.action = "detalle") {
      this.servicio = event.data;
      this.verInfoServicio = true;
    }
  }

  changedServer(event) {
    this.servicios = [];
  }

  changedUser(event) {
    this.servicios = [];
  }

  ocultarDetalleServicio() {
    this.verInfoServicio = false;
  }
}
