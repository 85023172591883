import { NbDialogService } from '@nebular/theme';
import { Gps } from "src/app/models/interface/Gps.interface";
import { GoogleService } from 'src/app/services/google.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { localidadesColombia, departamentos } from "src/app/utility/country";
import {
  Origin,
  Destination,
  OriginDefault,
  DestinationDefault,
  ResetClientInterface,
  MarkerOptions,
  Avatar,
  CardinalPoints,
  ClientType,
  Genders,
  PropertiesTypeList,
  States,
  TypeDocuments,
} from "src/app/utility/dataInfo";
import * as moment from 'moment';
import { funcionesUsuario } from "src/app/services/funcionesUsuario";
import { AuthService } from "src/app/services/auth.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";

import Swal from 'sweetalert2';

@Component({
  selector: 'app-mdls-clientes',
  templateUrl: './mdls-clientes.component.html',
  styleUrls: ['./mdls-clientes.component.scss']
})
export class MdlsClientesComponent implements OnInit {
  @Input() infoCliente: any = {};
  @Input() tipoModal: any = 'crear';
  @ViewChild("mdlCliente", { static: true }) mdlCliente: ElementRef;

  public origin: Gps = Origin;
  public originDefault: Gps = OriginDefault;
  public destination: Gps = DestinationDefault;
  public arrayDepartamentos: any = departamentos;
  public arrayCiudades: any;
  public placePredictions: any = [];

  public tipoCliente: any = ClientType;
  public TypeDocuments: any = TypeDocuments;
  public genero: any = Genders;
  public tipoPropiedades: any = PropertiesTypeList;
  public localidades: any = localidadesColombia;
  cliente: any = {
    direccionesArray: [],
    comentario: [],
    comentarioServer: [],
    rut: 'falta',
    cedula: 'falta',
    contrato: 'falta',
  };
  nuevaDireccion = {
    lat: 0,
    lng: 0,
    nombreDireccion: "Mi casa",
    country: "Colombia",
    cities: "",
    direccion: "",
    department: "",
    idLocation: "",
    cities_code: "",
    neighborhood: "",
    observaciones: "",
    tipoPropiedad: "",
    department_code: "",
    ninos: false,
    mascotas: false,
    ancianos: false,
  };

  validacionEmail = '';
  ingresarComentarioServer = false;
  ingresarComentarioLogistica = false;
  public comentario = ''
  public usuarioEnSecion: any
  loader: boolean = false;
  constructor(
    private authService: AuthService,
    private googleService: GoogleService,
    private usuarioService: UsuarioService,
    private nbDialogService: NbDialogService,
    private servicioService: ServicioService,
    private funcionesUsuario: funcionesUsuario,
  ) { 
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }

  ngOnInit() {
    this.abrirModal();
    this.getGps();
    this.getPlace();
  }

  abrirModal(){
    console.log(this.infoCliente,this.tipoModal)
    if(this.infoCliente != 'falta'){
      this.cliente = this.infoCliente;
      this.cliente.direccionesArray = this.cliente.direccionesArray == undefined ? [] : this.cliente.direccionesArray;
      this.cliente.comentario = this.cliente.comentario == undefined ? [] : this.cliente.comentario;
      this.cliente.comentarioServer = this.cliente.comentarioServer == undefined ? [] : this.cliente.comentarioServer;
      this.cliente.rut = this.cliente.rut == undefined ? 'falt': this.cliente.rut;
      this.cliente.cedula = this.cliente.cedula == undefined ? 'falt' : this.cliente.cedula;
      this.cliente.contrato = this.cliente.contrato == undefined ? 'falt' : this.cliente.contrato;
      this.cliente.email =  this.cliente.email == undefined ? '' :  this.cliente.email
    }
    // @ts-ignore 
    this.nbDialogService.open(this.mdlCliente)
  }

  getPlace() {
    this.googleService.currentData.subscribe((place) => {
      this.placePredictions = place;
    });
  }
  getGps() {
    this.googleService.currentGps.subscribe((origin: Gps) => {
      this.origin =
        origin != null && origin.lat != null && origin.lng != null
        ? origin
        : this.originDefault;
      this.nuevaDireccion.lat = 
        origin != null && origin.lat != null && origin.lng != null
        ? this.origin.lat
        : 0;
      this.nuevaDireccion.lng =
        origin != null && origin.lat != null && origin.lng != null
        ? this.origin.lng
        : 0;
    });
  }

  buscarCiudades(event) {
    let value = event.target.value;
    this.nuevaDireccion.idLocation =
    value == "Cundinamarca" ? "4fZC8McSlOvpPfIZnG7w" : "4fZC8McSlOvpPfIZnG7w";
    let index = this.arrayDepartamentos.findIndex((data) => data.name == value);
    this.nuevaDireccion.department_code = this.arrayDepartamentos[index].code;
    console.log(this.nuevaDireccion);
    this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
  }

  asignarCitieCode(event){
    let value = event.target.value;
    let index = this.arrayCiudades.findIndex((data) => data.name == value);
    this.nuevaDireccion.cities_code = this.arrayCiudades[index].code;
    console.log(this.nuevaDireccion);
  }

  asignarDocumentCode(event){
    let value = event.target.value;
    let index = this.TypeDocuments[this.cliente.tipo].findIndex((data) => data.name == value);
    this.cliente.typoDocumento_code = this.TypeDocuments[this.cliente.tipo][index].code;
    console.log(this.cliente);
  }

  buscarDireccion(_term: string, _city: string) {
    const text: string = _term;
    const city: string = _city;
    const status: boolean = city.includes("D.C.");
    if (text === "") {
      return;
    }
    const search = `${
      status ? city.replace("D.C.", "") : city
    } ${text}, Colombia`;
    this.googleService.getPlacePredictions(search);
  }

  seleccionarDireccion(place) {
    this.googleService.getGpsPlace(place.description);
    this.nuevaDireccion.direccion = place.description;
  }
  validarCorreo(){
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (emailRegex.test(this.cliente.email)) {
        this.validacionEmail = "valido";
    } else {
      this.validacionEmail  = "incorrecto";
    }
  }
  activeError(form: any, property: string, valid: string = "") {
    try {
      const element = form[property];
      const value = !valid
        ? typeof element.errors !== "undefined" && element.errors
        : typeof element.errors[valid] !== "undefined" && element.errors[valid];
      /*console.log({
          value,
          valid,
          error: element.errors,
          statu: element.errors[valid],
          form: this.submitForm
        });*/

      return value;
    } catch (error) {
      return false;
    }
  }


  guardarDireccion() {
    if(this.nuevaDireccion.lat != 0 && this.nuevaDireccion.lng != 0){
      this.cliente.direccionesArray.push(this.nuevaDireccion);
      // this.clienteEnEdicion.direccionesArray.push(this.nuevaDireccion);
      this.nuevaDireccion = {
        lat: 0,
        lng: 0,
        country: "Colombia",
        nombreDireccion: "Mi casa",
        cities: "",
        direccion: "",
        department: "",
        idLocation: "",
        cities_code: "",
        neighborhood: "",
        observaciones: "",
        tipoPropiedad: "",
        department_code: "",
        ninos: false,
        mascotas: false,
        ancianos: false,
      };
    }
  }
  actualizarDireccion(i) {
    this.cliente.direccionesArray[i].lat = this.nuevaDireccion.lat;
    this.cliente.direccionesArray[i].lng = this.nuevaDireccion.lng;
    this.cliente.direccionesArray[i].direccion = this.nuevaDireccion.direccion;
    console.log(this.cliente.direccionesArray);
    this.nuevaDireccion = {
      lat: 0,
      lng: 0,
      country: "Colombia",
      nombreDireccion: "Mi casa",
      cities: "",
      direccion: "",
      department: "",
      idLocation: "",
      cities_code: "",
      neighborhood: "",
      observaciones: "",
      tipoPropiedad: "",
      department_code: "",
      ninos: false,
      mascotas: false,
      ancianos: false,
    };
  }
  eleminarDireccion(i) {
    this.cliente.direccionesArray.splice(i, 1);
  }

  onUpload(event, tipo) {
    console.log(tipo);
    let file: any;
    let pictureName: any;
    let image = false;
    let nameImage: any;
    let fotoSeleccionada: any;
    console.log("files", event.target.files[0]);
    file = event.target.files[0];
    pictureName = Date.now() + file.name;
    image = true;
    if (event.target.files && file) {
      nameImage = file.name;
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>event.target).result;
        console.log("foto seeccionada", fotoSeleccionada);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log("no asa el primer if");
    }
    if (file && pictureName) {
      this.servicioService
        .putPicture(pictureName, file)
        .then((value) => {
          console.log("value", value);
          if (value.state == "success") {
            this.servicioService.getURL(pictureName).subscribe((url) => {
              if (tipo == "contrato") {
                this.cliente.contrato = url;
              } else if (tipo == "rut") {
                this.cliente.rut = url;
              } else if (tipo == "cedula") {
                this.cliente.cedula = url;
              } else {
                console.log(tipo);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no asa el segundo if");
    }
  }

  onSelectTipoCliente(){
    if(this.cliente.tipo == 'Persona'){
      delete this.cliente.nombreContactoEmpresa
      delete this.cliente.numeroContactoEmpresa
      delete this.cliente.documento
    }else{
      delete this.cliente.documento
      delete this.cliente.genero
      this.cliente.apellido = '';
    }
  }
  AddComentario(tipo) {
    if (tipo == "server") {
      this.ingresarComentarioServer = true;
      console.log(this.comentario, this.usuarioEnSecion.displayName);
    } else if (tipo == "logistica") {
      this.ingresarComentarioLogistica = true;
    }
  }
  guardarComentrario(tipo) {
    if (tipo == "logistica") {;
      this.cliente.comentario.push({
        name: this.usuarioEnSecion.displayName,
        comentario: this.comentario,
        fecha: moment().format('YYYY-MM-DD')
      });
      this.ingresarComentarioLogistica = false;
    } else {
      this.cliente.comentarioServer.push({
        name: this.usuarioEnSecion.displayName,
        comentario: this.comentario,
        fecha: moment().format('YYYY-MM-DD')
      });
      this.ingresarComentarioServer = false;
    }
    this.comentario = '';
  }

  async guardarCliente(){

    this.loader = true;
    this.cliente.estado = 'activo';
    this.cliente.creado = 'Admin';
    this.cliente.primeraCompra = true;
    this.cliente.terminos = true;
    this.cliente.fecha = moment().format('YYYY-MM-DD');
    this.cliente.foto = '"https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/vectores%2FuserDefault.png?alt=media&token=69a1f192-5eed-47d8-a58a-1eadcb01c7d3"'
    if(this.cliente.documento && this.cliente.telefono){
      const isValid = await this.authService.validateNewUser(this.cliente.telefono,this.cliente.documento,)
      if(isValid){
        console.log('Usuario valido')
        this.authService.registerUser(this.cliente).finally(()=>{
          this.loader = false;
        })
      }else{
        Swal.fire('Espera','Ya hay un cliente registrado con el mismo numro de telefono o documento','warning')
        console.log('yA EXCISTE UN USUARIO CON ESE NUMERO DE telefono o documento');
        this.loader = false;
      } 
    }
  }

  noPuntoComa() {
    let input = document.getElementById('joals')

    input.onkeydown = function(e){
      if(e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 69 ){
        e.returnValue = false;
      }
    }
  }

  maxlengthTel(){
    let telefono = document.getElementById('telefono') as HTMLInputElement;
    telefono.addEventListener('input',function(){
      if (this.value.length > 10) 
      this.value = this.value.slice(0,10); 
    })
  }

  actualizarCliente(){
    this.usuarioService.updateClient(this.cliente).then(()=>{
      Swal.fire('Exito!','Los datos fueron actualizados')
      const a = document.getElementById('cerrarModal') as HTMLButtonElement
      a.click()
    }).catch(err=>{
      console.log('Ocurrio un error al actualizar un cliente',err)
      Swal.fire("opps", `A ocurrido un error al actualizar el cliente---${err}`)
    });
  }

}
