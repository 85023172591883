import { Component, OnInit, ViewChild, ElementRef, NgModule, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

/*_________SERVICIOS__________ */
import { CuponService } from '../../services/cupon.service';
import { AuthService } from "src/app/services/auth.service";
import { Metricas } from "src/app/services/metricas.service";
import { LeadsService } from "src/app/services/leads.service";
import { GoogleService } from 'src/app/services/google.service';
import { tablaServicios } from "src/app/utility/settings.table";
import { ServerService } from "src/app/services/server.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";
import { PlanRecurrenteService } from "src/app/services/plan-recurrente.service";
/*******************************/

/*__________UTILIDADES_________*/
import * as _ from "lodash";
import { Subject } from "rxjs";
import * as moment from "moment";
import * as firebase2 from "firebase";
import { Helper } from "src/app/utility/helper";
import Swal, { SweetAlertType } from "sweetalert2";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Novedades } from "src/app/utility/novedades";
import { departamentos } from "src/app/utility/country";
import { ListColorService } from "src/app/utility/dataInfo";
import { OptionsColor } from "src/app/utility/OptionsColor";
import { PropertiesTypeList } from 'src/app/utility/dataInfo';
import { serviciosPrestados } from "src/app/utility/SERVicios";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { festivosColombia } from "src/app/utility/festivosColombia";
import { funcionesUsuario } from "src/app/services/funcionesUsuario";
import { preciosServicios, preciosDesinfeccion, preciosColchones, preciosMuebles } from "src/app/utility/precios";
import { AnonymousSubject } from "rxjs/internal/Subject";
import { count ,finalize} from "rxjs/operators";
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import {
  CalendarEvent,
  CalendarView,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay,
} from "angular-calendar";
import { Observable } from "rxjs";
import { ServerInterface } from '../../models/interface/Server.interface';
import { ResetServerInterface } from '../../utility/dataInfo';
import { Icu } from "@angular/compiler/src/i18n/i18n_ast";
import { flattenStyles } from "@angular/platform-browser/src/dom/dom_renderer";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { NgModel } from "@angular/forms";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { element } from "protractor";
import { DISABLED } from "@angular/forms/src/model";
import { isThisSecond } from "date-fns";
/*******************************/

@NgModule({
  imports:[
    CommonModule
  ]
})


@Component({
  selector: 'app-mdls-agendar',
  templateUrl: './mdls-agendar.component.html',
  styleUrls: ['./mdls-agendar.component.scss']
})
export class MdlsAgendarComponent implements OnInit {

    public arrayFestivos = festivosColombia;
  
    /*  Para el modal */
    @ViewChild("mdlCrear", { static: true }) mdlCrear: ElementRef;
    @ViewChild("mdlEditar", { static: true }) mdlEditar: ElementRef;
    @ViewChild("mdlAgendar", { static: true }) mdlAgendar: ElementRef;
    @Input() clienteLead: any;
    verMdlInfoSevicio = false;
    public  listColorService: Array<OptionsColor> = ListColorService;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
  
    lugarBusqueda: string = "Bogota"
    ciudadBusqueda: string = "";
    ciudadBusquedaAcento: string = "";
    departamentoBusqueda: string = "";
    usuarioEnSecion: any;
  
    public prevPdf: string;
    public inputFile: any;
    public filenameToRender: any;
    public disabledUpdateBtn: boolean = false;
    public datosEdit: ServerInterface = ResetServerInterface;


  
    public listaServers: any;
    public listaClientes: any;
  
  
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
  
    public calendarView = CalendarView;
    public refresh: Subject<any> = new Subject();
  
  
  /*****************VARIABLES FORMULARIO CREAR SERVIVCIO****************/
    public flippedCardAgendarServicio = false;
    public flippedEditServicios = false;
    public keyClienteAutocomplete: string = "nombreCliente";
    public keyServersAutocomplete: string = "nombreServer";
    serversPosibles: any = [] //Servers que no estan en la blackList
    serversSerca: any = [] // Servers que estan en el rango de 25Km
    serversConLaHabilidad: any = [] // Servers que tienen la habilidad que requiere el servicio
    serversConDiasDeTrabajo: any = [] //servers que tienen el dia disponible en su horario
    serversSinDiaBloqueado: any = [] // Servers que no tiene el dia bloquedado
    serversAsignables: any = [] // servers que pasaron todo los fitros 
    copiaServersAsignables: any = [] // servers que pasaron todo los fitros 
    listaServersConRazonBloqueo: any = [] // Todas las servers con variable que indica en que filtro se quedo
    public tipoGestion: string = "";
    public serviciosPrestados: any = serviciosPrestados;
    public categoriasServicios: any;
    public subCategoriasServicios: any;
    public checkListServicioElegido: any;
    public checkListAdicionalServicioElegido: any = [];

    public activarEligeServer = false
  
    public frecuenciasServicios: any = preciosServicios;
    public horasServicios: any;
    public diasServicios: any;
    public horasDeInicio = [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    public horariosServicios: any
  
    public frecuenciasDesinfeccion: any = preciosDesinfeccion
    public areasDesinfeccion: any;
  
    public serviciosDeMuebles: any = preciosMuebles
    public serviciosDecolchones: any = preciosColchones
  
    public planesAsignadosServer: any = [];
    public flippedStickyCard: boolean = false;
    public asignarPostVenta: boolean = false;
  
    public remplazo: boolean = false;
  
    /*))))cambio de direccion(((((*/
    public agregarDireccion: boolean = false
    public arrayDepartamentos: any = departamentos
    public tipoPropiedades:    any = PropertiesTypeList;
    public placePredictions: any;
    public arrayCiudades: any;
    public nuevaDireccion = {
      country: "Colombia",
      nombreDireccion: "Mi casa",
      department: "",
      cities: "",
      neighborhood: "",
      direccion: "",
      observaciones: "",
      lat: 0,
      lng: 0,
      tipoPropiedad: "",
      idLocation:"",
    }
    /*))))))))))))))(((((((((((((*/
    
    /*)))) Agregar comentarios cliente (((((*/
    public fechaActual = moment().format("YYYY-MM-DD");
    public comentario = {
      name: "",
      comentario: "",
      fecha: this.fechaActual,
    };
    public comentarioArray: any = [];
    public ingresarComentarioLogistica: boolean = false;
    public ingresarComentarioServer: boolean = false;
    /*))))))))))))))(((((((((((((*/
  
   /*-------variables que se guardan en el servicio--------*/
    public clienteServicio: any;
    public direccionServicio: any;
    public tipoServicio: string = "";
    public categoriaServicio: string = "";
    public subCategoriaServicio: string = "";
    public checkListFinal: any = [];
    public tipoAgendamiento: string = "falta";
    public horasDeServicio: string = "";
    public fechaServicio: string = "";
    public diaServicio: string = "";
    public horaInicioServicio: string = "";
    public horarioServicio: string = "";
    public serverServicio: any;
    public estadoServicio: "Pendiente"
  
    public metrosDesinfeccionServicio = 0;
    public mueblesDelServicio: any = [
      {
        nombre: "Sofa 1 puesto",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Sofa 2 puestos",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Sofa 3 puestos",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Silla asiento",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Silla completa",
        cantidad: 0,
        precio: 0,
      },
    ];
    public colchonesDelServcio: any = [
      {
        nombre: "Sencillo",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Doble",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Queen size",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "King size",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "Presidencial",
        cantidad: 0,
        precio: 0,
      },
      {
        nombre: "California king",
        cantidad: 0,
        precio: 0,
      },
    ];
  
    public plazoPagoServicio: string = "";
    public metodoPagoServicio: string = "";
    public numFacturaServicio: string = "Falta";
    public mtsTapetePiso: number = 0;
    public estadoDelPagoServicio: string = "";
    public referenciaPagoServicio: string = "";
    public urlComprobanteServicio: string = "";
    public fechaLimitePagoServicio: string = "";
    public pagoDosCuotas = 'no';
    public gananciaServerServicio: number = 0; 
    public precioServicio: number = 0;
    public recargoServicio: number = 0;
    public recargoTransporteServicio: number = 0;
    public descuentoServicio: number = 0;
    public razonRecargo: string = 'Sin recargo';
    verCupones: boolean = false;
    cuponesDisponibles: any = [];
    /*-----------------------------------------------------*/
  
    /*__________ VARIABLES DE PLAN ___________*/
    public numeroServiciosPlan: number = 0;
    public frecuenciaPlan: string = "";
    public diasSeleccionados: any = [];
    public arrayPreInfoServiciosPlan: any = [];
    public flippedPlanes: boolean = false;
    public precioPlan: number = 0;
    public recargoPlan: number = 0;
    public recargoTransportePlan: number = 0;
    public descuentoPlan: number = 0;
    public precioPorServicio: number = 0;
  
    public contadorServersDisponibles = 0
    public loadingTablaPlan: boolean = false
    public loaderAgendandoPlan: boolean = false
    public contadorFechasServiciosPlanVerificadas = 0
    public fechaPostVenta: string = ""
    public loaderAgendarPlan: boolean = false
  /**********************************************************************/
  
  /******************** VARIABLES EDITAR SERVICIO ***********************/
    public servicioEnEdicion: any;
    public loaderServerServicioEnEdicion = false;
    public activeUpdateServer: boolean = false;
    private estadosServicio = ["Pendiente","Aceptado","En camino","En curso", "Terminado","Congelado"];
    public editarHoras = false;
    public editarFecha = false;
    public horarioEdidato   = false;
    public editarDireccion  = false;
    public editarHoraInicio = false;
  
  /*___________ VARIABLES NOVEDADES DEL SERVICIO__________*/
    public novedadesServicioEnEdicion: any = []
  
    public arrayCategoriaNovedad: any = Novedades
    public arrayTiposNovedad: any = [];
    public categoriaNovedad: string = "";
    public tipoNovedad: string = "";
    public areaNovedad: string = "";
    public descripcionNovedad: string;
    public costoNovedad: number = 0;
    cuponElegido: any = '';
    plazoPago: string;
  /**********************************************************************/
    dropdownList = [];
    dropdownSettings:IDropdownSettings={};
    serviciosSelecccionados = [];
    constructor(
      private helper:           Helper,
      private storage:          AngularFireStorage,
      private metricasService:  Metricas,
      private authService:      AuthService,
      private cuponService:     CuponService,
      private leadsService:     LeadsService,
      private googleService:    GoogleService,
      private serverService:    ServerService,
      private usuarioService:   UsuarioService,
      private servicioService:  ServicioService,
      private nbDialogService:  NbDialogService,
      private funcionesUsuario: funcionesUsuario,
      private planRecurrenteService: PlanRecurrenteService,
  
    ){
      this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
    }
    async ngOnInit(){

      
      await this.getServers();
      // this.getServersPruebas();
      // this.getUsers();
      /* Se usan en caso de querer asignar una dirteccion */
      this.getPlace();
      this.getGps();
      this.calcularMesesSiguentes();
      this.onSelecCliente(this.clienteLead);
  
      // @ts-ignore 
      this.nbDialogService.open(this.mdlAgendar)
      /****************************************************/
    }
  
    async getServers() {
      let source = [];
      const a = await this.serverService.Actives();
      a.docs.forEach((res,index)=>{
        // console.log(res.data());
          source.push(res.data());  
        if(index == (a.size - 1) ){
          source.filter((server) => 
            (server["nombreServer"] = `${server.name} ${server.apellido}`) &&
            server.id != "56VnqwDwXhdWQToWbflQMJEMcyB2"
          ); // cambiar el 2 final por un 3 para sacar adigital
          this.listaServers = source;
          console.log("Lista de servers",this.listaServers)
        }
      })
    }
    async getServersPruebas() {
      let source = [];
      const a = await this.serverService.detail("56VnqwDwXhdWQToWbflQMJEMcyB3");
      const server = a.data();
      server.nombreServer = `${server.name} ${server.apellido}`
      source.push(a.data()); 
      this.listaServers = source;
      console.log("Lista server de listar-plan",this.listaServers)
    }
    async getUsers() {
      let source = [];
      let sub = this.usuarioService.getUsersByProperty("estado", "activo").then((res) => {
        res.docs.forEach(data=>{
          const user = data.data();
          user.nombreCliente = `${user.name} ${user.apellido}`
          source.push(user);
          this.listaClientes = source;
        })
        console.log('Lista clientes',this.listaClientes);
      });
    }

    cambiarLugarDeBusqueda(){
      if(this.lugarBusqueda == "Bogota"){
        this.ciudadBusqueda       = "Bogota"
        this.ciudadBusquedaAcento = "Bogotá"
        this.departamentoBusqueda = "Bogota"
      }else if(this.lugarBusqueda == "Medellin"){
        this.ciudadBusqueda       = "Medellin"
        this.ciudadBusquedaAcento = "Medellín"
        this.departamentoBusqueda = "Antioquia"
      }
    }
 
  
    // verClientesDelMes(){
    //   let clientes =  
    //   this.servicioService.getServiciosSemanaActual('2022-10-01','2022-10-31').then(res=>{
    //     res.docs.forEach(element => {
    //       let servicio = element.data();
    //       let filtro = 
    //     });
    //   })
    // }  
    abrirModal(tipo){
      let modal:any;
      if(tipo == "crearServicio"){
        this.borrarFormulario()
        modal = this.mdlCrear;
        this.nbDialogService.open(modal, {context: "this is some additional data passed to dialog"});
      }
    }
  
  /******************FUNCIONES FORMULARIO CREAR SERVICIO***********************/            
    borrarFormulario(){
      this.clienteServicio = {}
      this.direccionServicio = '';
      this.tipoGestion = ""
      this.tipoServicio = "";
      this.categoriaServicio = "";
      this.subCategoriaServicio = "";
      this.checkListFinal = [];
      this.tipoAgendamiento = "flata";
      this.horasDeServicio = "";
      this.fechaServicio = "";
      this.diaServicio = "";
      this.horaInicioServicio = "";
      this.horarioServicio = "";
      this.serverServicio = null
  
      this.mueblesDelServicio = [
        {
          nombre: "Sofa 1 puesto",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Sofa 2 puestos",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Sofa 3 puestos",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Silla asiento",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Silla completa",
          cantidad: 0,
          precio: 0,
        },
      ];
      this.colchonesDelServcio = [
        {
          nombre: "Sencillo",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Doble",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Queen size",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "King size",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "Presidencial",
          cantidad: 0,
          precio: 0,
        },
        {
          nombre: "California king",
          cantidad: 0,
          precio: 0,
        },
      ];
    
      this.plazoPago               = "";
      this.plazoPagoServicio       = "";
      this.metodoPagoServicio      = "";
      this.numFacturaServicio      = "";
      this.estadoDelPagoServicio   = "";
      this.referenciaPagoServicio  = "";
      this.urlComprobanteServicio  = "";
      this.fechaLimitePagoServicio = "";
      this.planesAsignadosServer   = [];
      this.gananciaServerServicio  = 0; 
      this.precioServicio = 0;
      this.recargoServicio = 0;
      this.recargoTransporteServicio = 0;
      this.numeroServiciosPlan = 0;
      this.frecuenciaPlan = "";
      this.diasSeleccionados= [];
      this.arrayPreInfoServiciosPlan = [];
      this.flippedPlanes= false
      this.precioPlan= 0;
      this.recargoPlan= 0;
      this.recargoTransportePlan = 0;
      this.precioPorServicio = 0;
      this.mtsTapetePiso = 0;
  
      this.contadorServersDisponibles = 0
      this.loadingTablaPlan = false
      this.contadorFechasServiciosPlanVerificadas = 0
      this.fechaPostVenta = ""
    }
    onSelecCliente(cliente){
      console.log(cliente);
      this.clienteServicio = cliente
      this.flippedCardAgendarServicio = true;
      if(cliente.blackList){
        this.filtroServersBlackList(cliente.blackList)
      }else{
        this.serversPosibles = this.listaServers;
        console.log(this.serversPosibles)
        // setTimeout(()=>{
        //   (<HTMLInputElement>document.getElementById("eligeDireccion")).disabled = false;
        // },100)
      }
    }
    // Primer filtro general
    filtroServersBlackList(blackList){
      this.serversPosibles = [];
      let contador = 0;
      console.log("ENTRO EN filtroServersBlackList()",this.listaServers)
      if(blackList != undefined){
        this.listaServers.forEach(server => {
          let bloqueada = blackList.filter(serv=>serv.id == server.id)
          console.log(bloqueada)
          if(bloqueada.length > 0){
            console.log("serverBloqueada")
            server.filtroObstructor = "Por blackList"
            this.listaServersConRazonBloqueo.push(server)
            contador++
          }else{
            this.serversPosibles.push(server)
            // this.listaServersConRazonBloqueo.push(server)
            contador++
          } 
          if(contador == this.listaServers.length){
            setTimeout(()=>{
              (<HTMLInputElement>document.getElementById("eligeDireccion")).disabled = false;
            },100)
          }
        });
      }else{
        this.serversPosibles = this.listaServers
        setTimeout(()=>{
          (<HTMLInputElement>document.getElementById("eligeDireccion")).disabled = false;
        },100)
      }
    }
    elegirDireccion(event){
      let a = parseInt(event);
      this.direccionServicio = this.clienteServicio.direccionesArray[a]
      console.log(this.direccionServicio,event)
      console.log(this.direccionServicio.lat)
      console.log(this.direccionServicio.lng)
      this.filtroServersLatLng()
    }
  
    // Segundo filtro general
    filtroServersLatLng(){
      this.serversSerca = []
      let contador = 0
      console.log("ENTRO EN filtroServersLatLng()",this.serversPosibles)
      const haversine = require("haversine-distance");
      const a = {
        latitude: this.direccionServicio.lat,
        longitude: this.direccionServicio.lng,
      };
      this.serversPosibles.forEach(server => {
        const b = {
          latitude: server.gps.lat,
          longitude: server.gps.lng,
        }
        let diferenciaCoordenadas = haversine(a, b);
        console.log(diferenciaCoordenadas,server.name + " " + server.apellido)
        if(diferenciaCoordenadas <= 10000){
          server.distanciaDelCliente = (diferenciaCoordenadas/1000)
          this.serversSerca.push(server);
          // this.listaServersConRazonBloqueo.push(server)
          contador++
        }else{     
          contador++
        }
        if(contador == this.serversPosibles.length){
          console.log("Servers Serca",this.serversSerca);
          setTimeout(()=>{
            (<HTMLInputElement>document.getElementById("elegirGestion")).disabled = false;
          },100)
        }
      });
    }
  
    /*--------Funciones Dirercion diferente--------*/
    getPlace(){
      this.googleService.currentData.subscribe((place)=>{
        this.placePredictions = place;
      })
    }
    getGps(){
      this.googleService.currentGps.subscribe((origin)=>{
        this.nuevaDireccion.lat = (origin != null && origin.lat != null && origin.lng != null ) ? origin.lat : 0;
        this.nuevaDireccion.lng = (origin != null && origin.lat != null && origin.lng != null ) ? origin.lng : 0;
      })
    }
    buscarCiudades(event){
      let value = event.target.value;
      console.log(value,event)
      this.nuevaDireccion.idLocation =  value == "Cundinamarca" ? "4fZC8McSlOvpPfIZnG7w" : "4fZC8McSlOvpPfIZnG7w"
      let index = this.arrayDepartamentos.findIndex(data => data.name == value);
      console.log(index);
      this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
    }
    buscarDireccion(_term: string, _city: string) {
      const text: string    = _term;
      const city: string    = _city;
      const status: boolean = city.includes('D.C.');
      if (text === '') { return; }
      const search = `${status ? city.replace("D.C.", '') : city } ${text}, Colombia`;
      this.googleService.getPlacePredictions(search);
    }
    seleccionarDireccion(place){
      this.googleService.getGpsPlace(place.description);
      this.nuevaDireccion.direccion = place.description;
    }
    guardarDireccion(){
      console.log(this.nuevaDireccion)
      this.clienteServicio.direccionesArray.push(this.nuevaDireccion)
      this.direccionServicio = this.nuevaDireccion;
      this.agregarDireccion = !this.agregarDireccion;
      this.nuevaDireccion = {
        country: "Colombia",
        nombreDireccion: "Mi casa",
        department: "",
        cities: "",
        neighborhood: "",
        direccion: "",
        observaciones: "",
        lat: 0,
        lng: 0,
        tipoPropiedad: "",
        idLocation:"",
      }
      this.usuarioService.updateClient(this.clienteServicio)
    }
    /*------------------------------------------------*/
  
  
    /*))))))))Funciones para elegir servicio((((((((*/
    async elegirServicio(event, type) {
      const name: string = event.target.value;
      let filter = null;
      console.log(name);
      if (type == "categoria") {
        filter = await this.serviciosPrestados.filter((tipoServicio) => tipoServicio.name == name)[0];
        this.categoriasServicios = filter[type];
        this.categoriaServicio = "";
        this.subCategoriaServicio = "";
  
        if(name === "Hogares"){
          this.dropdownList = [
            { item_id: 1, item_text: 'Empleada doméstica'},
            { item_id: 2, item_text: 'Planchado de ropa'},
            { item_id: 3, item_text: 'Asistente de cocina'},
            { item_id: 4, item_text: 'Cuidado de niños'},
            { item_id: 5, item_text: 'Cuidado de adultos mayores'},
            { item_id: 6, item_text: 'Cuidado de mascotas'}
          ];
        }else{ 
          this.dropdownList = [
            { item_id: 1, item_text: 'Aseo y limpieza'},
            { item_id: 2, item_text: 'Limpieza de bodegas'},
            { item_id: 3, item_text: 'Todero'},
            { item_id: 4, item_text: 'Jardinero'}
          ];
        }
  
        this.dropdownSettings = {
          idField: 'item_id',
          textField: 'item_text',
          enableCheckAll: false,
        };
        // this.lugarFiltro = name;
      } else if (type == "subCategoria") {
        filter = await this.categoriasServicios.filter((subCategoria) => subCategoria.name == name)[0];
        this.subCategoriasServicios = filter[type];
        console.log( this.subCategoriasServicios);
        this.subCategoriaServicio = "";
      } else if (type == "checklist") {
        filter = await this.subCategoriasServicios.filter((checkList) => checkList.name == name)[0];
        this.checkListServicioElegido = filter[type];
        if(this.tipoGestion == "server"){
          if(this.categoriaServicio != 'Aseo y servicios generales' && this.categoriaServicio != 'Servicios generales' ){
            this.filtroServersHabilidades();
          }
        }else{
          (<HTMLInputElement>document.getElementById("elegirAgendamiento")).disabled = false;
        }
      }
    }
  
    onMultiServiceSelect(item: any) {
      console.log('onItemSelect', item);
      this.serviciosSelecccionados.push(item)
      console.log(this.serviciosSelecccionados)
    }
    onMultiServiceDeSelect(item: any) {
      const index = this.serviciosSelecccionados.findIndex(res=>res.item_id == item.item_id);
      console.log(index);
      this.serviciosSelecccionados.splice(index,1);
      console.log(this.serviciosSelecccionados)
    }
  
    agregarAlCheckList($event) {
      console.log($event);
      let seleccion = $event;
      this.checkListFinal = seleccion.map(function (name) {
        return name.name;
      });
      console.log(this.checkListFinal);
    }
    public otroCheck(type) {
      let otro = { name: "" };
      if (type == "add") {
        this.checkListAdicionalServicioElegido.push(otro);
      } else if (type == "remove") {
        this.checkListAdicionalServicioElegido.pop();
      }
    }
  
    // Tercer filtro general
    filtroServersHabilidades(){
      console.log("entro en filtroServersHabilidades",this.serversSerca,this.listaServersConRazonBloqueo)
      let contador = 0
      this.serversConLaHabilidad = [];
      this.serversSerca.forEach(async server => {
        let conHbilidad = await server.habilidades.filter(habilidad=> habilidad.lugar == this.tipoServicio && habilidad.subCategoria == this.subCategoriaServicio)
        if(conHbilidad.length > 0){
          this.serversConLaHabilidad.push(server)
          // this.listaServersConRazonBloqueo.push(server)
          contador++
          console.log(server.name)
        }else{
          server.filtroObstructor = "Por habilidades"
          this.listaServersConRazonBloqueo.push(server)
          contador++
        }
        console.log(contador && this.serversSerca.length)
        if(contador == this.serversSerca.length){
          if( this.serversConLaHabilidad.length > 0){
            setTimeout(()=>{
              (<HTMLInputElement>document.getElementById("elegirAgendamiento")).disabled = false;
            },100)
          }else{
            Swal.fire("OOPS😱","No tenemos servers diponibles con esa habilidad")
          }
        }
        /* console.log(server.habilidades) */
      });
    }
    filtroServersHabilidadesMultiple(){
      let contador = 0
      this.serversConLaHabilidad = [];
      console.log(this.serversSerca)
      this.serversSerca.forEach(async server => {
        let conHbilidad = true;
        let habilidadFaltante = 'Falta';
        for(let i = 0;i < this.serviciosSelecccionados.length;i++){
          let result = server.habilidades.filter(habilidad=>habilidad.subCategoria == this.serviciosSelecccionados[i].item_text)
          if(result.length == 0){
            conHbilidad = false
            habilidadFaltante = `${habilidadFaltante} ${this.serviciosSelecccionados[i].item_text},`
          }
          console.log(result,i,this.serviciosSelecccionados.length)
          if(i == (this.serviciosSelecccionados.length - 1) && conHbilidad){
            this.serversConLaHabilidad.push(server)
            // this.listaServersConRazonBloqueo.push(server)
            contador++
            console.log(server.name)
          }else if(i == (this.serviciosSelecccionados.length - 1) && !conHbilidad){
            server.habilidadFaltante = habilidadFaltante;
            this.serversConLaHabilidad.push(server)//Eliminar esta linea para qu efuncione el filtro
            // this.listaServersConRazonBloqueo.push(server)
            contador++
            console.log(`${server.name} no es valida para este servicio`,server)
          }
          if(contador == this.serversSerca.length){
            if( this.serversConLaHabilidad.length > 0){
              setTimeout(()=>{
                (<HTMLInputElement>document.getElementById("elegirAgendamiento")).disabled = false;
              },100)
            }else{
              Swal.fire("OOPS😱","No tenemos servers diponibles con esa habilidad")
            }
          }
        }
      });
    }
    /*))))))))))))))))))))))))(((((((((((((((((((((((*/
    
    /*/////Funciones para el precio del servicio////////*/
    valorarTipoAgendamiento(event){
      let name: string = event.target.value;
      console.log(name,this.categoriaServicio)
      this.metodoPagoServicio = '';
      this.plazoPagoServicio = '';
      this.urlComprobanteServicio = '';
      this.fechaLimitePagoServicio = '';
      this.numFacturaServicio = '';
      this.estadoDelPagoServicio = '';
      this.plazoPago = ''
      if(name == 'Administrativo'){
        this.metodoPagoServicio = 'Nequi';
        this.plazoPagoServicio = "pago adelantado";
        this.urlComprobanteServicio = 'Pago';
        this.plazoPago = "pago adelantado";
        this.fechaLimitePagoServicio = moment().format("YYYY-MM-DD");
        this.numFacturaServicio = 'serv';
        this.estadoDelPagoServicio = "Pago";
        name = "Administrativo" 
        this.valorarPrecioPorHoras(name,'horas')
      }else if((name == "Plan Individual" || name == 'Multiples servicios') && this.categoriaServicio != 'Desinfección' && this.categoriaServicio != 'Limpieza especializada'){
        console.log("Entro en el if");
        this.valorarPrecioPorHoras('Unitario','horas')
      }else if((name == "Plan Individual" || name == 'Multiples servicios') && this.categoriaServicio == 'Desinfección'){
        name = "Unitario" 
        this.valorPrecioDesinfeccion(name,"area")
      }else{
        console.log(name,this.categoriaServicio)
      }
    }
    /********** POR HORAS **********/
    async valorarPrecioPorHoras(event,tipo){
    
      console.log("valorarPrecioPorHoras---",event,'----',tipo);
  
      if( this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios" || this.tipoAgendamiento == "Administrativo"){
        console.log('sirve entro a los planes individuales');
        let variable: any;
        if(tipo == "horario" || tipo == "precio" || tipo == 'horas' || tipo == 'precioPlan'){
          variable = event;
        }else{
          variable =  event.target.value
        }
        console.log(variable,tipo,this.tipoAgendamiento);
        let filter = null;
        if(tipo == "horas"){//que da igual
          console.log(this.frecuenciasServicios,variable)
          filter = await this.frecuenciasServicios.filter((tipoServicio) => tipoServicio.name == variable)[0];
          console.log(filter,tipo)
          this.horasServicios = filter[tipo];
        }else if(tipo == "dia"){//se queta
          console.log(this.horasServicios,variable)
          filter = await this.horasServicios.filter((horasServicio) => horasServicio.value == variable)[0];
          console.log(filter,this.diaServicio)
          this.diasServicios = filter[tipo];
          this.elegirDuracion()
        }else if(tipo == 'horario'){// se deja
          console.log(this.diasServicios,variable)
          filter = await this.diasServicios.filter((dia) => dia.name == this.diaServicio)[0];
          this.horariosServicios = filter[tipo];
          console.log("this.horariosServicios",this.horariosServicios)
          this.filtroServersDiasBloqueados()
          // this.filtroServersDiasBloqueados()
        }else if(tipo == 'precio'){ //se deja igual
          console.log(this.horariosServicios,variable)
          filter = await this.horariosServicios.filter((horario) => horario.name == this.horarioServicio)[0];
          console.log(filter)
          let precio = filter[tipo];
          this.precioServicio = precio.valor;
          this.gananciaServerServicio = precio.gananciaServer
          console.log(this.gananciaServerServicio);
          this.filtroServersDiasSeleccionados()
        }
        // else if(tipo == 'precioPlan'){
        //   let filterDos: any;
        //   filter = await this.diasServicios.filter((dia) => dia.name == this.arrayPreInfoServiciosPlan[variable].dia)[0];
        //   this.horariosServicios = filter["horario"];// creo que se dejaria igual
        //   console.log("horario para ese servicio",this.horariosServicios,this.arrayPreInfoServiciosPlan[variable])
        //   filterDos = await this.horariosServicios.filter((horario) => horario.name == this.arrayPreInfoServiciosPlan[variable].horario)[0];
        //   console.log(filterDos)
        //   let precio = filterDos['precio'];
        //   this.arrayPreInfoServiciosPlan[variable].precio = precio.valor;
        //   this.arrayPreInfoServiciosPlan[variable].gananciaServer = precio.gananciaServer
        //   this.calcularPrecioPlan()
        //   console.log(variable,this.arrayPreInfoServiciosPlan.length)
        //   if(variable == (this.arrayPreInfoServiciosPlan.length - 1)){
        //     this.filtroServersPlanHorarioDisponible()
        //   }
        // }
      }else if( this.tipoAgendamiento == "Plan Mensual"){
        // console.log('entro a planes mensuales');
        let variable: any;
        if(tipo == "precio" || tipo == 'horas' || tipo == 'precioPlan'){
          // console.log('entrada 1 aprovada ',tipo)
          variable = event;
        }else{
          variable =  event.target.value
        }
        let filter = null;
        if(tipo == "horas"){
          console.log('Entro en horas---frecuencia del servicio',this.frecuenciasServicios,'variable',variable)
          filter = await this.frecuenciasServicios.filter((tipoServicio) => tipoServicio.name == variable)[0];
          console.log(filter,tipo)
          this.horasServicios = filter[tipo];
        }else if(tipo == 'horario'){
          this.diasSeleccionados = [];
          this.arrayPreInfoServiciosPlan = [];
          // this.refreshView();
          console.log(this.horasServicios,variable)
          if(this.frecuenciaPlan !== '1 por semana'){
            filter = await this.horasServicios.filter((hora) => hora.name == variable)[0];
            console.log(filter)
            this.horariosServicios = filter['horario'];
          }else{
            filter = await this.horasServicios.filter((hora) => hora.name == variable)[0];
            console.log(filter)
            this.diasServicios = filter['dia'];
          }
        }else if(tipo == 'precioPlan'){
          console.log('entro al precioPlan----this.horariosServicios',this.diasServicios,this.arrayPreInfoServiciosPlan[variable]);
          if(this.frecuenciaPlan === '1 por semana'){
            filter = await this.diasServicios.filter((horario) => horario.name == this.arrayPreInfoServiciosPlan[variable].dia)[0];
            this.horariosServicios = filter['horario'];
          }
          let filterDos: any;
          filterDos = await this.horariosServicios.filter((horario) => horario.name == this.arrayPreInfoServiciosPlan[variable].horario)[0];
          let precio = filterDos['precio'];
          console.log('se imprime los precios',precio);
          this.arrayPreInfoServiciosPlan[variable].precio = precio.valor;
          console.log('se imprime el primer array',this.arrayPreInfoServiciosPlan[variable].precio);
          this.arrayPreInfoServiciosPlan[variable].gananciaServer = precio.gananciaServer
          console.log('se imprime el segundo  array ',this.arrayPreInfoServiciosPlan[variable].gananciaServer);
          this.calcularPrecioPlan()
          console.log('se imprime la calculadora de precios',this.calcularPrecioPlan());
          console.log(variable,this.arrayPreInfoServiciosPlan.length)
          if(variable == (this.arrayPreInfoServiciosPlan.length - 1)){
            console.log('entra al ultimo if y se va al filtro de horas disponibles');
            // this.filtroServersPlanDiasSeleccionados();
          }
        }
      }
    }
      elegirDuracion(){
      this.fechaServicio = ''
      this.serversAsignables = [];
      this.horaInicioServicio = "";
      (<HTMLInputElement>document.getElementById("eligeHora")).disabled = true;
    }
    elegirFecha(tipo,dia){
      this.serversAsignables = [];
      this.horaInicioServicio = "";
      if(this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios"  || this.tipoAgendamiento == "Administrativo" ){
        (<HTMLInputElement>document.getElementById("eligeHora")).disabled = true;    
      } 
      if(this.tipoGestion == "orden" && this.tipoAgendamiento !== "Plan Mensual" ){
        (<HTMLInputElement>document.getElementById("eligeHora")).disabled = false;   
      }
      let date:any; 
      let dow: any;
      if(tipo == "planMensual"){
        dow = dia.day
      }else{
        date = moment(this.fechaServicio);
        dow = date.day();
      }
     let recargoServicioPlan = 0 
     console.log("Dia seleccionado",dow);
      if (dow == 0) {
        this.recargoServicio += 10000;
        recargoServicioPlan = 10000; 
        this.diaServicio = "domingo";
      } else if (dow == 1) {
        this.diaServicio = "lunes";
      } else if (dow == 2) {
        this.diaServicio = "martes";
      } else if (dow == 3) {
        this.diaServicio = "miercoles";
      } else if (dow == 4) { 
        this.diaServicio = "jueves";
      } else if (dow == 5) {
        this.diaServicio = "viernes";
      } else if (dow == 6) {
        this.diaServicio = "sabado";
      }
      if(tipo == "porHoras"){
        this.valorarPrecioPorHoras(this.diaServicio,'horario')
        this.razonRecargo = 'Recargo dominical de 10000';
      }else if(tipo == "desinfeccion" || tipo == "limpiezaEspecializada"){
        this.razonRecargo = 'Recargo dominical de 10000';
        this.filtroServersDiasBloqueados()
    
      }else if(tipo == "planMensual"){
        let dateIndex: any;
        console.log(dia)
        if (this.diasSeleccionados) {
          dateIndex = this.diasSeleccionados.findIndex(
            (selectedDay) => selectedDay.date.getTime() === dia.date.getTime()
          );
        }
        if(dateIndex >= 0){
          delete dia.cssClass;
          this.diasSeleccionados.splice(dateIndex, 1);
          this.arrayPreInfoServiciosPlan.splice(dateIndex, 1);
        }else{
          if(this.numeroServiciosPlan > this.diasSeleccionados.length){
            this.diasSeleccionados.push(dia);
            let idServicio = new Date().getTime()
            let infoServicio = {
              fecha: moment(dia.date.getTime()).format("YYYY-MM-DD"),
              id: idServicio,
              idServicios: idServicio,
              hora: "",
              idServer: "",
              dia: this.diaServicio,
              horario: "",
              precio: 0,
              recargo: recargoServicioPlan,
              recargoTransporte: 0,
              total: 0,
              gananciaServer: 0,
              estado: "Pendiente",
              razonRecargo: "Sin recargo"
            };
            if(recargoServicioPlan > 0){
              infoServicio.razonRecargo = 'Recargo dominical de 10000'
            }
            this.arrayPreInfoServiciosPlan.push(infoServicio);
            console.log("Pre info servicios",this.arrayPreInfoServiciosPlan)
            if (this.numeroServiciosPlan == this.diasSeleccionados.length) {
              this.filtroServersPlanDiasBloqueados()
            }
          }else{
            // Swal.fire("","Ya seleccionaste todas las fechas del plan","warning")
            this.flippedPlanes = !this.flippedPlanes
          }
        }
        this.refreshView();
      }else{
        console.log(tipo)
      }
    }
    refreshView(){
      this.refresh.next();
    }
  
    agregarRecargoPlan(){
      this.arrayPreInfoServiciosPlan.map(res=>{
        if(res.razonRecargo == 'Recargo dominical y festivo'){
          res.recargo = this.recargoPlan + 10000;
        }else{
          res.recargo = this.recargoPlan;
        }
        res.recargoTransporte = this.recargoTransportePlan
      })
      this.calcularPrecioPlan();
    }
    
    pagosProgramadosPlan = []
    calcularPrecioPlan() {
      console.log("Fehas plan",this.arrayPreInfoServiciosPlan,"Meses plan",this.mesesSelccionadoPlan)
      let i = 0
      let countDays = 0
      let sumPre = 0;
      let sumRec: number = 0;
      let sumRecTrans: number = 0;
      let contadorDiasPorMes = 0
      let mesPrevio = moment(this.arrayPreInfoServiciosPlan[0].fecha).format("M");
      this.mesesSelccionadoPlan[i].fechaInicio = this.arrayPreInfoServiciosPlan[0].fecha
      this.arrayPreInfoServiciosPlan.map((valor) => {
        console.log("Mes comparado",mesPrevio ,"----------", moment(valor.fecha).format("M"))
        if(mesPrevio == moment(valor.fecha).format("M")){   
          contadorDiasPorMes++
          sumPre = valor.precio + sumPre;
          sumRec = valor.recargo + sumRec;
          sumRecTrans = valor.recargoTransporte + sumRecTrans;
          valor.total = valor.precio + valor.recargo;
          this.mesesSelccionadoPlan[i].precio = sumPre;
          this.mesesSelccionadoPlan[i].recargo = sumRec;
          this.mesesSelccionadoPlan[i].recargoTransporte = sumRecTrans;
          this.mesesSelccionadoPlan[i].total = (sumPre + sumRec + sumRecTrans);
          this.mesesSelccionadoPlan[i].serviciosVinculados.push(valor.id);
          this.mesesSelccionadoPlan[i].servicosDelMes = contadorDiasPorMes;
          this.mesesSelccionadoPlan[i].precioIndividual = (sumPre + sumRec + sumRecTrans)/ contadorDiasPorMes;
          valor.cobroAsociado = this.mesesSelccionadoPlan[i].id;
          countDays++
          console.log("Contador de dias y lengthArreglo",countDays,this.arrayPreInfoServiciosPlan.length);
          if(countDays == this.arrayPreInfoServiciosPlan.length){
            this.mesesSelccionadoPlan[i].fechaFin = this.arrayPreInfoServiciosPlan[(countDays-1)].fecha;
            console.log("Ultimo dia",this.mesesSelccionadoPlan)
            this.precioPlan = this.mesesSelccionadoPlan[0].precio;
            this.precioPorServicio = this.mesesSelccionadoPlan[0].precioIndividual;
          }
        }else{
          // console.log("Inex dia",countDays,valor.fecha,this.mesesSelccionadoPlan[i].id)
          this.mesesSelccionadoPlan[i].fechaFin = this.arrayPreInfoServiciosPlan[(countDays-1)].fecha;
          i++;
          mesPrevio = moment(valor.fecha).format("M");
          console.log(this.mesesSelccionadoPlan[i],i);
          valor.cobroAsociado = this.mesesSelccionadoPlan[i].id;
          this.mesesSelccionadoPlan[i].fechaInicio = this.arrayPreInfoServiciosPlan[(countDays)].fecha;
          this.mesesSelccionadoPlan[i].serviciosVinculados.push(valor.id);
          console.log("-----mesSiguiente",this.mesesSelccionadoPlan);
          countDays++
          sumPre = valor.precio;
          sumRec =  valor.recargo;
          contadorDiasPorMes = 1;
        }
      });
      
      console.log(this.precioPlan);
    }
    // i  = indice del servicio en this.arrayPreInfoServiciosPlan
    elegirHora(tipo,horaServicioPlan,i){
      console.log('hora del plan',horaServicioPlan)
      let horaInicio = parseInt(horaServicioPlan)
      if(tipo == "porHoras"){
        console.log('entro a por horas', this.horaInicioServicio)
        let hora = parseInt(this.horaInicioServicio);
        if(hora <= 14){
          console.log('la hora del servicio es',hora,'y es de mañana');
          this.horarioServicio = "mañana";
        }else if(hora > 14 ){
          console.log('la hora del servicio es',hora,'y es de tarde')
          this.horarioServicio = "tarde";
        }
        this.valorarPrecioPorHoras(this.horarioServicio,'precio')
      }else if(tipo == "porHorasPlan"){
        if(horaInicio <= 14){
          this.arrayPreInfoServiciosPlan[i].horario = "mañana";
        }else if(horaInicio > 14){
          this.arrayPreInfoServiciosPlan[i].horario = "tarde";
        }
        this.valorarPrecioPorHoras(i,'precioPlan')
      }
    }
  
    verificarFrecuenciaPlan(){
      if (this.diasSelccionadoPlan.length == 1) {
        this.frecuenciaPlan = "1 por semana";
      } else if (this.diasSelccionadoPlan.length == 2) {
        this.frecuenciaPlan = "2 por semana";
      } else if (this.diasSelccionadoPlan.length == 3) {
        this.frecuenciaPlan = "3 por semana";
      } else if (this.diasSelccionadoPlan.length == 4) {
        this.frecuenciaPlan = "4 por semana";
      } else if (this.diasSelccionadoPlan.length == 5) {
        this.frecuenciaPlan = "5 por semana";
      }else if (this.diasSelccionadoPlan.length == 6 || this.diasSelccionadoPlan.length == 7 ) {
        this.frecuenciaPlan = "6 por semana";
      }
      this.valorarPrecioPorHoras( this.frecuenciaPlan ,'horas')
  }
  
    /*********** DESINFECCION ***********/
    async valorPrecioDesinfeccion(event,tipo){
      let name: string = ""
      if(tipo == 'area'){
        name = event
      }else{
        name = event.target.value;
      }
      let filter: any;
      if(tipo == "area"){
        filter = await this.frecuenciasDesinfeccion.filter((frecuencia)=> frecuencia.name == name)[0]
        this.areasDesinfeccion = filter[tipo];   
        console.log(this.areasDesinfeccion);
      }else if(tipo == 'precio'){
        filter = await this.areasDesinfeccion.filter((area) => area.name == name)[0];
        this.precioServicio = filter[tipo];   
        this.gananciaServerServicio = filter["gananciaServer"];
        console.log(this.gananciaServerServicio);  
        this.filtroServersDiasSeleccionados();
        // this.filtroServersHorarioDisponible();
      }
    }
    /********** MUEBLES ***********/
    valorPrecioMuebles(tipo,a,i){
      let filter = this.serviciosDeMuebles.filter(mueble=>mueble.value == tipo)[0];
      console.log(tipo,a,i,filter)
     console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
      if(tipo == "sofa1Puesto" && this.mueblesDelServicio[i].cantidad >= 0 && (this.mueblesDelServicio[i].cantidad + a) >=0 ){
        this.mueblesDelServicio[i].cantidad += a
        this.mueblesDelServicio[i].precio =  this.mueblesDelServicio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
  
      }else  if(tipo == "sofa2Puestos" && this.mueblesDelServicio[i].cantidad >= 0  && (this.mueblesDelServicio[i].cantidad + a) >=0){
        this.mueblesDelServicio[i].cantidad += a
        this.mueblesDelServicio[i].precio =  this.mueblesDelServicio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "sofa3Puestos" && this.mueblesDelServicio[i].cantidad >= 0  && (this.mueblesDelServicio[i].cantidad + a) >=0){
        this.mueblesDelServicio[i].cantidad += a
        this.mueblesDelServicio[i].precio =  this.mueblesDelServicio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "siilaAsiento" && this.mueblesDelServicio[i].cantidad >= 0  && (this.mueblesDelServicio[i].cantidad + a) >=0){
        this.mueblesDelServicio[i].cantidad += a
        this.mueblesDelServicio[i].precio =  this.mueblesDelServicio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "sillaCompleta" && this.mueblesDelServicio[i].cantidad >= 0  && (this.mueblesDelServicio[i].cantidad + a) >=0){ 
        this.mueblesDelServicio[i].cantidad += a
        this.mueblesDelServicio[i].precio =  this.mueblesDelServicio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.mueblesDelServicio[i],this.gananciaServerServicio)
        
      }
    }
    /*********** COLCHONES ***********/
    valorPrecioColchones(tipo,a,i){
      let filter = this.serviciosDecolchones.filter(colchon=>colchon.value == tipo)[0];
      console.log(tipo,a,i,filter)
      console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
  
      if(tipo == "sencillo" && this.colchonesDelServcio[i].cantidad >= 0 && (this.colchonesDelServcio[i].cantidad + a) >= 0){
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
  
      }else  if(tipo == "doble" && this.colchonesDelServcio[i].cantidad >= 0  && (this.colchonesDelServcio[i].cantidad + a) >= 0){
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "queenSize" && this.colchonesDelServcio[i].cantidad >= 0  && (this.colchonesDelServcio[i].cantidad + a) >= 0){
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "kingSize" && this.colchonesDelServcio[i].cantidad >= 0  && (this.colchonesDelServcio[i].cantidad + a) >= 0){
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "presidencial" && this.colchonesDelServcio[i].cantidad >= 0  && (this.colchonesDelServcio[i].cantidad + a) >= 0){ 
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
        
      }else  if(tipo == "californoaKing" && this.colchonesDelServcio[i].cantidad >= 0  && (this.colchonesDelServcio[i].cantidad + a) >= 0){ 
        this.colchonesDelServcio[i].cantidad += a
        this.colchonesDelServcio[i].precio =  this.colchonesDelServcio[i].precio + (a * filter.precio)
        this.precioServicio = this.precioServicio +  (a * filter.precio)
        this.gananciaServerServicio = this.gananciaServerServicio + (a * filter.gananciaServer)
        console.log( this.colchonesDelServcio[i],this.gananciaServerServicio)
      }
    }
    /********** Tapetes ***********/
    public precioTapetes = 0
    public cmTapetes: string = ""
    valoracioncmTatepes(){
      if (this.cmTapetes == "1-19200") {
        this.precioTapetes = 79900;
        this.gananciaServerServicio = 24000;
      } else if (this.cmTapetes == "19201-21600") {
        this.precioTapetes = 89900;
        this.gananciaServerServicio = 27000;
      } else if (this.cmTapetes == "21601-24000") {
        this.precioTapetes = 99900;
        this.gananciaServerServicio = 30000;
      } else if (this.cmTapetes == "24001-28000") {
        this.precioTapetes = 120000;
        this.gananciaServerServicio = 36000;
      } else if (this.cmTapetes == "28001-36000") {
        this.precioTapetes = 150000;
        this.gananciaServerServicio = 45000;
      } else if (this.cmTapetes == "36001-41800") {
        this.precioTapetes = 170000;
        this.gananciaServerServicio = 51000;
      } else if (this.cmTapetes == "41801-49200") {
        this.precioTapetes = 200000;
        this.gananciaServerServicio = 60000;
      } else if (this.cmTapetes == "49201-56600") {
        this.precioTapetes = 230000;
        this.gananciaServerServicio = 69000;
      } else if (this.cmTapetes == "56601-61600") {
        this.precioTapetes = 250000;
        this.gananciaServerServicio = 75000;
      } else if (this.cmTapetes == "Mas de 61600") {
        this.precioTapetes = 300000;
        this.gananciaServerServicio = 90000;
      }else{
        this.precioTapetes = 0
      }
      this.precioServicio = this.precioTapetes + (this.mtsTapetePiso*15000)
    }
  /******************* Maquinas *******************/
    
    alquilerDeMaquinaria: boolean = false
    valoracionMaquinaria(){
      if(this.alquilerDeMaquinaria == true){
        this.precioServicio += 10000
        this.gananciaServerServicio += 30000
      }else{
        this.precioServicio -= 10000
        this.gananciaServerServicio -= 30000
      }
    }
    ///////////////////////////////////////////////////
  
    // Cuarto filtro Individual
    filtroServersDiasBloqueados(){
      console.log("Entro en filtroServersDiasBloqueados()",this.fechaServicio,this.serversConLaHabilidad,this.listaServersConRazonBloqueo)
      if(this.tipoGestion == "server"){
        this.loadingTablaPlan = true;
        let contador = 0;
        this.serversSinDiaBloqueado = [];
        this.serversConLaHabilidad.forEach(server => {
          // console.log(1)
          this.serverService.getDisponibilidad(server.id).onSnapshot(async value=>{
            if (value.data() != undefined) {
              let info = value.data();
              let bloqueada = await info.fechas.filter(fecha => fecha.fecha == this.fechaServicio)
              if(bloqueada.length == 0){
                this.serversSinDiaBloqueado.push(server)
                this.listaServersConRazonBloqueo.push(server)
                contador++
              }else{
                server.filtroObstructor = "Por dias bloqueados"
                this.listaServersConRazonBloqueo.push(server)
                contador++
                console.log("SERVER BLOQUEADA",server.name,server.apellido);
              }
            }else{
              this.serversSinDiaBloqueado.push(server)
              this.listaServersConRazonBloqueo.push(server)
              contador++
              // console.log("Server sin bloqueos")
            }
            console.log(contador, this.serversConLaHabilidad.length)
            if(contador == this.serversConLaHabilidad.length){
              if(this.serversSinDiaBloqueado.length == 0){
                this.loadingTablaPlan = false;
                Swal.fire("OOPS😱","No encontramos servers diponibles conesa habilidad para ese dia");
              }else{
                setTimeout(()=>{
                  this.loadingTablaPlan = false;
                  (<HTMLInputElement>document.getElementById("eligeHora")).disabled = false;
                },100)
              }
            }
          })
        });
      }else{
        (<HTMLInputElement>document.getElementById("eligeHora")).disabled = false;
      }
    }
    filtroServersPlanDiasBloqueados(){
      console.log("Entro en filtroServersPlanDiasBloqueados()",this.listaServersConRazonBloqueo,this.serversConLaHabilidad)
      if(this.tipoGestion == "server"){
        this.loadingTablaPlan = true;
        let contador = 0;
        this.serversSinDiaBloqueado = []
        this.serversConLaHabilidad.forEach(server => {
          this.serverService.getDisponibilidad(server.id).onSnapshot(async value=>{
            if (value.data() != undefined) {
              let info = value.data();
              let disponible = true;
              let contadorServer = 0;
              for(let servicio of this.arrayPreInfoServiciosPlan){
                if(servicio.fecha !== undefined){
                  let bloqueada = await info.fechas.filter(fecha => fecha.fecha == servicio.fecha)
                  // console.log("Dias bloqueados",bloqueada)
                  if(bloqueada.length > 0 ){
                     disponible = false
                     contadorServer ++
                  }else{
                    contadorServer++
                  }
                  if(contadorServer == this.arrayPreInfoServiciosPlan.length){
                    if(disponible == true){
                      this.serversSinDiaBloqueado.push(server)
                      // this.listaServersConRazonBloqueo.push(server)
                      contador++
                      // console.log("SERVER CON ESOS DIAS NO BLOQUEDOS",this.serversSinDiaBloqueado)
                    }else{
                      server.filtroObstructor = "Por dias bloqueados"
                      this.listaServersConRazonBloqueo.push(server)
                      contador++
                    }
                  }
                }else{
                  contadorServer++
                }
              }
            }else{
              this.serversSinDiaBloqueado.push(server)
              // this.listaServersConRazonBloqueo.push(server)
              contador++
              // console.log("Server sin dias bloqueados",server.name)
            }
            console.log(contador, this.serversConLaHabilidad.length)
            if(contador == this.serversConLaHabilidad.length){
              this.flippedPlanes = !this.flippedPlanes
              this.filtroServersPlanDiasSeleccionados();
              // this.loadingTablaPlan = false
            }
          })
        });
      }else{
        this.flippedPlanes = !this.flippedPlanes
        console.log("Es una ordennnn")
      }
    }
  
    // Quinto filtro individual
    filtroServersDiasSeleccionados(){
      this.loadingTablaPlan = true;
      this.serversConDiasDeTrabajo = []
      console.log("Entro en filtroServersDiasSeleccionados--hora inicio del servicio",this.horaInicioServicio,this.serversSinDiaBloqueado,this.listaServersConRazonBloqueo);
      if(this.tipoGestion == "server"){
        let contador = 0;
        console.log(this.diaServicio);
        this.serversSinDiaBloqueado.forEach(server=>{
          const filter = server.horario.filter(info=>info.dia == this.diaServicio)
          if(filter.length > 0){
            // console.log(parseInt(this.horaInicioServicio),parseInt(filter[0].inicio),(parseInt(this.horaInicioServicio) + parseInt(t/his.horasDeServicio)),parseInt(filter[0].fin));
            if(filter[0].franjaHoraria === 'diaCompleto'){
              this.serversConDiasDeTrabajo.push(server)
              this.listaServersConRazonBloqueo.push(server)
            }else if(parseInt(this.horaInicioServicio) >= parseInt(filter[0].inicio) && (parseInt(this.horaInicioServicio) + parseInt(this.horasDeServicio)) <= parseInt(filter[0].fin)){
              // console.log("Paso el if")
              this.serversConDiasDeTrabajo.push(server)
              this.listaServersConRazonBloqueo.push(server)
            }else{
              server.filtroObstructor = "Por horario server"
              this.listaServersConRazonBloqueo.push(server)
            }
            // console.log({filter: filter,server: server.name});
            contador++;
          }else{
            server.filtroObstructor = "Por horario server"
            this.listaServersConRazonBloqueo.push(server)
            contador++;
          }
          if(contador == this.serversSinDiaBloqueado.length){
            if(this.serversConDiasDeTrabajo.length == 0){
              this.loadingTablaPlan = false;
              Swal.fire("OOPS😱","No encontramos servers con ese horario para este servicio");
            }else{
              this.filtroServersHorarioDisponible()
            }
          }
        })
      }else{
        this.activarEligeServer = true
        this.loadingTablaPlan = false
      }
    }
    filtroServersPlanDiasSeleccionados(){
      this.serversConDiasDeTrabajo = []
  
      console.log("Entro en filtroServersDiasSeleccionados",this.listaServersConRazonBloqueo,this.serversSinDiaBloqueado);
      if(this.tipoGestion == "server"){
        let contador = 0;
        this.serversSinDiaBloqueado.forEach(async server=>{
          let disponible = true;
          let contadorServer = 0;
          for(let servicio of this.arrayPreInfoServiciosPlan){
            const bloqueada = await server.horario.filter(info => info.dia == servicio.dia.toLowerCase())
            // console.log(`horario de la server ${server.name}`,bloqueada)
            if(bloqueada.length > 0 ){
              if(bloqueada[0].franjaHoraria === 'diaCompleto'){
                contadorServer ++;
              }else if(parseInt(servicio.hora) >= parseInt(bloqueada[0].inicio) && (parseInt(servicio.hora) + parseInt(this.horasDeServicio)) <= parseInt(bloqueada[0].fin)){
                contadorServer ++;
              }else{
                disponible = false;
                contadorServer ++;
              }
            }else{
              disponible = false;
              contadorServer++;
            }
            if(contadorServer == this.arrayPreInfoServiciosPlan.length){
              console.log("Server disponible--",disponible,"--",server.name)
              if(disponible == true){
                this.serversConDiasDeTrabajo.push(server);
                // this.serversAsignables.push(server);
                // this.listaServersConRazonBloqueo.push(server)
                contador++
                // console.log("SERVER CON ESOS DIAS ACTIVOS",server.name)
              }else{
                server.filtroObstructor = "Por horario server"
                this.listaServersConRazonBloqueo.push(server)
                contador++
              }
              console.log(contador,this.serversSinDiaBloqueado.length)
              if(contador == this.serversSinDiaBloqueado.length){
                // this.filtroServersPlanHorarioDisponible();
                this.filtroPlanRecurrenteServers();
              }
            }
          }
        })
      }
    }
  
  
    // Sexto filtro individual
    filtroServersHorarioDisponible(){
      console.log("entro en filtroServersHorarioDisponible()",this.serversConDiasDeTrabajo,this.listaServersConRazonBloqueo)
      if(this.tipoGestion == "server" && this.subCategoriaServicio != "Alquiler de aspiradora" && this.subCategoriaServicio != "Alquiler de hidrolavadora" && this.subCategoriaServicio!= "Alquiler de purificador de aire"){
        let contador = 0;
        this.serversAsignables = [];
        this.copiaServersAsignables = [];
        this.serverServicio = null;
        let horaServicio = parseInt(this.horaInicioServicio)
        let horaFinalServcio = horaServicio + parseInt(this.horasDeServicio)
        // console.log(`horario Seleccionado inicio ${horaServicio} fin ${horaFinalServcio}`)
        this.serversConDiasDeTrabajo.forEach(server => {
          let disponible: boolean = true;
          let contadorServicios = 0;
          this.servicioService.getByServerOnADate(server.id,this.fechaServicio).then(res=>{
            console.log(server.name + " " + server.apellido,res.docs.length)
            if(res.docs.length > 0){
              res.docs.forEach(data=>{
                let servicio = data.data()
                if(servicio.estado != "Cancelado" && servicio.estado != "Anulado" && servicio.estado != "Congelado"){
                  console.log(servicio.estado)
                  let horaI = parseInt(servicio.hora)
                  let horaF = horaI + servicio.horasDeServicio;
                  console.log(`Hora de inicio ${horaI} hora fin ${horaF}`)
                  if(horaServicio == horaI){
                    contadorServicios++
                    disponible = false
                    console.log("Server Ocupada",server.name)
                  }else if(horaServicio >= horaI && horaServicio <= horaF){
                    contadorServicios++
                    disponible = false
                    console.log("Server Ocupada",server.name)
                  }else if(horaFinalServcio >= horaI && horaFinalServcio <= horaF){
                    contadorServicios++
                    disponible = false
                    console.log("Server Ocupada",server.name)
                  }else if(horaF >= horaServicio && horaF <= horaFinalServcio){
                    contadorServicios++
                    disponible = false
                    console.log("Server Ocupada",server.name)
                  }else{
                    contadorServicios++
                    disponible == false ? disponible = false : disponible = true
                  }
                }else{
                  contadorServicios++
                }
              
                console.log("Contador servicios",contadorServicios, res.docs.length)
                if(contadorServicios == res.docs.length){
                  contador++
                  if(disponible == true){
                    console.log("SERVER TOTALMENTE DISPONIOBLE!!")
                    this.serversAsignables.push(server)
                    this.copiaServersAsignables.push(server)
                    this.listaServersConRazonBloqueo.push(server)
                  }else{
                    server.filtroObstructor = "Por agenda llena"
                    this.listaServersConRazonBloqueo.push(server)
                  }
                  if(contador == this.serversConDiasDeTrabajo.length){
                    if(this.serversAsignables.length == 0){
                      Swal.fire("OOPS😱","No tenemos server disponibles para esa hora, prueba otro horario o cambia el día","error")
                    }else{
                       this.serversAsignables = _.orderBy(this.serversAsignables,['calificacion','distanciaDelCliente'],['desc','asc'])
                      //  this.filtroPlanRecurrenteServers()
                      this.calcularPorcentajeOcupacionServesDisponibles();
                      //  this.activarEligeServer = true
                    }
                  }
                }
              })
            }else{
              this.serversAsignables.push(server)
              this.copiaServersAsignables.push(server)
              this.listaServersConRazonBloqueo.push(server)
              contador++
              console.log("constador general",contador, this.serversConDiasDeTrabajo.length)
              if(contador == this.serversConDiasDeTrabajo.length){
                 this.serversAsignables = _.orderBy(this.serversAsignables,['calificacion','distanciaDelCliente'],['desc','asc'])
                 this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['calificacion','distanciaDelCliente'],['desc','asc'])
                //  this.filtroPlanRecurrenteServers()
                this.calcularPorcentajeOcupacionServesDisponibles();
                // this.activarEligeServer = true
              }
              console.log("SERVER TOTALMENTE DISPONIOBLE!!")
            }
          })
        });
      }else{
        if(this.tipoGestion == "server" ){
          this.calcularPorcentajeOcupacionServesDisponibles();
          // this.activarEligeServer = true
          this.serversAsignables = this.serversConDiasDeTrabajo
          this.copiaServersAsignables = this.serversConDiasDeTrabajo
        }
        console.log("Es una ordennnnnn")
      }
    }
    filtroServersPlanHorarioDisponible(){
      if(this.tipoGestion == "server"){
      
        this.loadingTablaPlan = true
        let serversParaFiltro: any;
        let lengthServerParFiltro = 0
        if(this.contadorFechasServiciosPlanVerificadas == 0){
          serversParaFiltro = this.serversConDiasDeTrabajo
          lengthServerParFiltro = this.serversConDiasDeTrabajo.length
        }else{
          serversParaFiltro = this.serversAsignables;
          lengthServerParFiltro = this.serversAsignables.length
        }
        console.log("entro en filtroServersHorarioDisponible() servers a verificar",serversParaFiltro)
        let contador = 0
        this.serversAsignables = []
        this.copiaServersAsignables = []
        this.serverServicio = null
        let horaServicio = parseInt(this.arrayPreInfoServiciosPlan[this.contadorFechasServiciosPlanVerificadas].hora)
        let horaFinalServcio = horaServicio + parseInt(this.horasDeServicio)
        console.log(`horario Seleccionado inicio ${horaServicio} fin ${horaFinalServcio} `)
        serversParaFiltro.forEach(server => {
          let disponible: boolean = true;
          let contadorServicios = 0;
          this.servicioService.getByServerOnADate(server.id,this.arrayPreInfoServiciosPlan[this.contadorFechasServiciosPlanVerificadas].fecha).then(res=>{
            console.log(server.name + " " + server.apellido,res.docs.length)
            if(res.docs.length > 0){
              res.docs.forEach(data=>{
                let servicio = data.data()
                if(servicio.estado != "Cancelado" && servicio.estado != "Anulado" && servicio.estado != "Congelado" ){
                  let horaI = parseInt(servicio.hora)
                  let horaF = horaI + parseInt(servicio.horasDeServicio);
                  console.log(`Hora de inicio ${horaI} hora fin ${horaF}`)
                  if(horaServicio == horaI){
                    contadorServicios++
                    disponible = false
                    console.log("HORA INICIO IGUAL A OTRO SERVICIO")
                  }else if(horaServicio >= horaI && horaServicio <= horaF){
                    contadorServicios++
                    disponible = false
                    console.log("HORA INICIO ESTA ENTRE OTTRO SERVICIO")
                  }else if(horaFinalServcio >= horaI && horaFinalServcio <= horaF){
                    contadorServicios++
                    disponible = false
                    console.log("El horarioTermina en medio de otro servicio") 
                  }else if(horaF >= horaServicio && horaF <= horaFinalServcio){
                    contadorServicios++
                    disponible = false
                    console.log("El horario esta dentro del que se intenra agendar")
                  }else{
                    contadorServicios++
                    disponible == false ? disponible = false : disponible = true
                  }
                }else{
                  console.log(servicio.estado)
                  contadorServicios++
                }
              
                console.log("Contador servicios",contadorServicios, res.docs.length)
                if(contadorServicios == res.docs.length){
                  contador++
                  if(disponible == true){
                    console.log("SERVER TOTALMENTE DISPONIOBLE!!")
                    this.serversAsignables.push(server)
                    this.copiaServersAsignables.push(server)
                    // this.listaServersConRazonBloqueo.push(server)
                  }else{
                    server.filtroObstructor = "Por agenda llena"
                    this.listaServersConRazonBloqueo.push(server)
                  }
                  if(contador == lengthServerParFiltro){
                    if(this.serversAsignables.length == 0){
                      Swal.fire("OOPS😱","No tenemos server disponibles para esa hora, prueba otro horario o cambia el día","error")
                    }else{
                      this.contadorFechasServiciosPlanVerificadas++
                      console.log("TERMINO DE VERIFICAR CON SERVICIOS",this.serversAsignables)
                      if(this.contadorFechasServiciosPlanVerificadas < this.arrayPreInfoServiciosPlan.length){
                        this.filtroServersPlanHorarioDisponible()
                      }else{
                        this.serversAsignables = _.orderBy(this.serversAsignables,[,'distanciaDelCliente'],['asc'])
                        this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['distanciaDelCliente'],['asc'])
                        this.filtroPlanRecurrenteServers()
                        // this.loadingTablaPlan = false
                        // this.calcularPorcentajeOcupacionPlanes() --
  
                        this.contadorFechasServiciosPlanVerificadas = 0
                      }
                    }
                  }
                }
              })
            }else{
              this.serversAsignables.push(server)
              this.copiaServersAsignables.push(server)
              contador++
              console.log("constador general",contador, lengthServerParFiltro)
              if(contador == lengthServerParFiltro){
                this.contadorFechasServiciosPlanVerificadas++
                console.log("TERMINO DE VERIFICAR EN UNA QUE NO TIEN SERVICIOS",this.serversAsignables)
                if(this.contadorFechasServiciosPlanVerificadas < this.arrayPreInfoServiciosPlan.length){
                  this.filtroServersPlanHorarioDisponible(); 
                }else{
                   this.serversAsignables = _.orderBy(this.serversAsignables,['distanciaDelCliente'],['asc'])
                   this.filtroPlanRecurrenteServers()
                  //  this.loadingTablaPlan = false
                  //  this.calcularPorcentajeOcupacionPlanes() --
  
                  this.contadorFechasServiciosPlanVerificadas = 0
                }
              }
              console.log("SERVER TOTALMENTE DISPONIOBLE!!")
            }
          })
        });
      }else{
        console.log("Es una ordennnnn")
      }
    }
  
    
    filtroSeguridadSocial(event){
      console.log(event.target.checked)
      let serversConSeguridad: any;
      if(event.target.checked){
        if(this.tipoAgendamiento === 'Plan mensual'){
          serversConSeguridad = this.serversAsignables.filter(server=>server.fechaVencimientoSeguridadSocial > moment().format('YYYY-MM-DD'));
        }else{
          serversConSeguridad = this.serversAsignables.filter(server=>server.fechaVencimientoSeguridadSocial > this.fechaServicio);
        }
        this.serversAsignables = serversConSeguridad;
      }else{
        this.serversAsignables = this.copiaServersAsignables;
      }
    }
  
    filtroPlanRecurrenteServers(){
      console.log("Entro en filtroPlanRecurrenteServers")
      this.serversAsignables = [];
      let fechas = [];
      let horaServicio = 0;
      let horaFinalServcio = 0;
      if(this.tipoAgendamiento == 'Plan Individual'  || this.tipoAgendamiento == "Administrativo"){
        horaServicio = parseInt(this.horaInicioServicio)
        horaFinalServcio = horaServicio + parseInt(this.horasDeServicio)
        let numDia =  moment(this.fechaServicio).day()
        let dia = ""
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }
        fechas.push(dia)
      }else if(this.tipoAgendamiento === 'falta'){
        horaServicio = parseInt(this.servicioEnEdicion.hora)
        horaFinalServcio = horaServicio + parseInt(this.servicioEnEdicion.horasDeServicio)
        let numDia =  moment(this.servicioEnEdicion.fecha).day()
        let dia = ""
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }
        fechas.push(dia)
      }else{
        horaServicio = parseInt(this.arrayPreInfoServiciosPlan[0].hora)
        horaFinalServcio = horaServicio + parseInt(this.horasDeServicio)
        this.arrayPreInfoServiciosPlan.forEach(element => {
          let numDia =  moment(element.fecha).day()
          let dia = ""
          if (numDia == 0) {
            dia = "domingo";
          } else if (numDia == 1) {
            dia = "lunes";
          } else if (numDia == 2) {
            dia = "martes";
          } else if (numDia == 3) {
            dia = "miercoles";
          } else if (numDia == 4) {
            dia = "jueves";
          } else if (numDia == 5) {
            dia = "viernes";
          } else if (numDia == 6) {
            dia = "sabado";
          }
          let diaAgregado = fechas.filter(day=>day == dia)
          if(diaAgregado.length == 0){
            fechas.push(dia);
          }
        });
      }
      let count = 0;
      let mensaje = 'dias '
      let countServers = 0;
      this.serversConDiasDeTrabajo.forEach(server => {
        let disponible = true;
        this.planRecurrenteService.getPlanByIdServer(server.id).then(res=>{
          if(res.docs.length > 0){
            count = 0
            res.docs.forEach(data => {
              let planRecurrente = data.data(); 
              this.planesAsignadosServer.push(planRecurrente);
              console.log(this.planesAsignadosServer);
              console.log(data.data().diasAgendado);
              for(let fch of fechas){
                console.log(fch)
                if(data.data().diasAgendado.includes(fch)){
                  console.log('a',fch);
                  let horaI = parseInt(data.data().horas);
                  let horaF = horaI + parseInt(data.data().horas);
                  if (horaServicio == horaI) {
                    disponible = false;
                    console.log("Server Ocupada");
                  } else if (horaServicio >= horaI && horaServicio <= horaF) {
                    disponible = false;
                    console.log("hoRA SELCCIONADA ESTA ENTRE OTTRO SERVICIO");
                  } else if (horaFinalServcio >= horaI && horaFinalServcio <= horaF) {
                    disponible = false;
                    console.log("El horarioTermina en medio de otro servicio");
                  }
                  mensaje = mensaje + " " + fch
                }else{
                  console.log("b",fch);
                }
              }
              count++
              // console.log("Contador planes activos de filtro",count,res.docs.length)
              if(count == res.docs.length){
                console.log("serverDisponible planes", disponible)
                if(!disponible){
                  // Swal.fire('warning',`La server ya tiene plan para los ${mensaje}`)
                  server.filtroObstructor = "Por plan recurrente"
                  this.listaServersConRazonBloqueo.push(server)
                  countServers ++
                  console.log("Contador en filtro planes recurretes1",countServers,(this.serversConDiasDeTrabajo.length))
                  if(countServers == (this.serversConDiasDeTrabajo.length)){
                    this.calcularPorcentajeOcupacionPlanes()
                  }
                }else{
                  this.serversAsignables.push(server);
                  this.listaServersConRazonBloqueo.push(server)
                  countServers ++
                  console.log("Contador en filtro planes recurretes2",countServers,(this.serversConDiasDeTrabajo.length))
                  if(countServers == (this.serversConDiasDeTrabajo.length)){
                    this.calcularPorcentajeOcupacionPlanes()
                  }
                }
              }
            }); 
          }else{
            this.serversAsignables.push(server);
            this.listaServersConRazonBloqueo.push(server)
            countServers ++
            console.log("Contador en filtro planes recurretes3",countServers,(this.serversConDiasDeTrabajo.length))
            if(countServers == (this.serversConDiasDeTrabajo.length)){
              this.calcularPorcentajeOcupacionPlanes()
            }
          }
        })
      });
    }
  
  
    calcularPorcentajeOcupacionServesDisponibles(){
      let fchInicio = moment().format("YYYY-MM-DD");
      let fchFin = moment().add(7,'days').format("YYYY-MM-DD");
  
      console.log("Entor en calcular porcenntaje",this.listaServersConRazonBloqueo);
      this.listaServersConRazonBloqueo.map(server=>{
        if(server.razonBloqueo){
          console.log("Paso 1-server bloqueada")
          server.porcentajeOcupacionSemana = 0;
        }else{
          console.log("Paso 1")
          this.servicioService.getByServerInDateRange(server.id,fchInicio,fchFin).then(res=>{
            console.log("Paso 2",res.docs.length)
            let horasAsignadas = 0
            if(res.docs.length > 0){
              let count = 0
              res.docs.forEach(data=>{
                horasAsignadas = horasAsignadas + data.data().horasDeServicio
                count++
                if(count == res.docs.length){
                  server.porcentajeOcupacionSemana = (horasAsignadas/server.horasDisponible)*100;
                  //  this.activarEligeServer = true
                  //  this.loadingTablaPlan = false
                }
              })
            }else{
              server.porcentajeOcupacionSemana = 0
            }
          })
        }
      })
  
      this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['distanciaDelCliente'],['asc'])
      this.activarEligeServer = true
      this.loadingTablaPlan = false
    }
  
    calcularPorcentajeOcupacionPlanes(){
      console.log("Entro en calcularPorcentajeOcupacionPlanes")
      let allServers = []
      let countServer = 0;
      this.serversAsignables.map(server=>{
        console.log("Paso 1");
        let countPlan = 0;
        this.listaServersConRazonBloqueo.push(server)
        countServer ++
        if(countServer == this.serversAsignables.length-1 ){
          allServers = _.orderBy(this.listaServersConRazonBloqueo,["porcentajeOcupacionSemana", "distanciaDelCliente"],["desc", "asc"]);
  
          let result = allServers.filter((item,index)=>{
            return allServers.indexOf(item) === index;
          })
  
          this.listaServersConRazonBloqueo = result;
          this.activarEligeServer = true
          this.loadingTablaPlan = false
          //////////////////////////////////
          this.activeUpdateServer = true
          this.loaderServerServicioEnEdicion = false
        }
  
        // this.servicioService.getPlanesServer(server.id).then(res=>{
        //   // console.log("Planes de server",res.docs.length)
        //   let horasOcupada = 0
        //   if(res.docs.length > 0){
        //     res.docs.forEach(data=>{
        //       let planPrevio = data.data();
        //       if(planPrevio.estado == "Activo" || planPrevio.estado == "Por vencer" || planPrevio.estado == "Completado" ){
        //         console.log(planPrevio.diasDeServicio,planPrevio.horasDeServicio,server.name)
        //         horasOcupada += (planPrevio.diasDeServicio.length) * planPrevio.horasDeServicio
        //         countPlan++
        //         // console.log(res.docs.length)
        //         if(countPlan == res.docs.length){
        //           console.log( `${horasOcupada} / ${server.horasDisponible}`)
        //           server.porcentajeOcupacionSemana = (horasOcupada/server.horasDisponible)*100
        //           this.listaServersConRazonBloqueo.push(server)
        //           countServer ++
        //           if(countServer == this.serversAsignables.length-1 ){
        //             this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['porcentajeOcupacionSemana','distanciaDelCliente'],['desc','asc'])
        //             this.activarEligeServer = true
        //             this.loadingTablaPlan = false
        //             //////////////////////////////////
        //             this.activeUpdateServer = true
        //             this.loaderServerServicioEnEdicion = false
        //           }
        //         }
        //       }else{
        //         countPlan++
        //         // console.log(res.docs.length)
        //         if(countPlan == res.docs.length){
        //           console.log( `${horasOcupada} / ${server.horasDisponible}`)
        //           server.porcentajeOcupacionSemana = (horasOcupada/server.horasDisponible)*100
        //           this.listaServersConRazonBloqueo.push(server)
        //           countServer ++
        //           if(countServer == this.serversAsignables.length-1 ){
        //             this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['porcentajeOcupacionSemana','distanciaDelCliente'],['desc','asc'])
        //             this.activarEligeServer = true
        //             this.loadingTablaPlan = false
        //             //////////////////////////////////
        //             this.loaderServerServicioEnEdicion = false
        //             this.activeUpdateServer = true
        //           }
        //         }
        //       }
        //     })
        //   }else{  
        //     server.porcentajeOcupacionSemana = 0;
        //     this.listaServersConRazonBloqueo.push(server)
        //     countServer ++
        //     if(countServer == this.serversAsignables.length-1 ){
        //       // this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['distanciaDelCliente'],['asc'])
        //       this.listaServersConRazonBloqueo = _.orderBy(this.listaServersConRazonBloqueo,['porcentajeOcupacionSemana','distanciaDelCliente'],['desc','asc'])
  
        //       this.activarEligeServer = true
        //       this.loadingTablaPlan = false
        //     }
        //   }
        // })
  
  
      })
      // console.log(this.serversAsignables,this.listaServersConRazonBloqueo);
    }
    limitarDescuento(){
      console.log('aaaazaaaa',this.descuentoPlan)
      if(this.descuentoPlan > 99){
        this.descuentoPlan = 0;
      } 
      if(this.descuentoServicio > 99){
        this.descuentoServicio = 0
      }
    }
  
    onSelecServer(server,type){
      if(type == 'Individual'){
        this.serverServicio = server;
      }else if (type == 'Plan'){
        this.planesAsignadosServer = [];
        this.serverServicio = undefined;
        console.log(server);
        this.filtroHorarioUnicaServer(server);
      }
    }
  
    filtroHorarioUnicaServer(server){
      console.log("filtroHorarioUnicaServer");
      let razonBloqueo = "";
      let contadorServicios = 0;
      let disponible: boolean = true;
      const  fechaBusqueda = this.arrayPreInfoServiciosPlan[0].fecha;
  
      let horaServicio = parseInt(this.arrayPreInfoServiciosPlan[this.contadorFechasServiciosPlanVerificadas].hora);
      let horaFinalServcio = horaServicio + parseInt(this.horasDeServicio);
  
      this.servicioService.getByServerFromADate(server.id,fechaBusqueda).then((res) => {
        console.log(server.name + " " + server.apellido, res.docs.length);
        if (res.docs.length > 0) {
          res.docs.forEach((data,index) => {
            let servicio = data.data();
            if (
              servicio.estado != "Cancelado" &&
              servicio.estado != "Anulado" &&
              servicio.estado != "Congelado"
            ) {
              let horaI = parseInt(servicio.hora);
              let horaF = horaI + parseInt(servicio.horasDeServicio);
              let fechaConConicidencia = this.arrayPreInfoServiciosPlan.filter( res=>{ 
                if(servicio.fecha === res.fecha){
                  if(parseInt(res.hora) == horaI) {
                    return true;
                  }else if (parseInt(res.hora) >= horaI && parseInt(res.hora) <= horaF) {
                    return true;
                  }else if ( (parseInt(res.hora) + parseInt(this.horasDeServicio)) >= horaI && (parseInt(res.hora) + parseInt(this.horasDeServicio)) <= horaF
                  ) {
                    return true;
                  }
                }
              } )
              console.log(servicio,fechaConConicidencia)
              razonBloqueo = razonBloqueo + `${servicio.fecha} / `
              disponible = disponible  == false ? false : fechaConConicidencia.length == 0 ? true : false 
            }
            console.log(disponible)
            if(index == (res.docs.length - 1) && disponible){
              this.serverServicio = server;
              this.servicioService.getPlanRecurrenteIdServer(server.id).then(res=>{
                console.log(res.docs.length);
                this.flippedStickyCard = true;
                if(res.docs.length > 0){
                  res.docs.forEach(data => {
                    let planRecurrente = data.data();
                    this.planesAsignadosServer.push(planRecurrente);
                    console.log(this.planesAsignadosServer);
                  });
                };
              });
            }else if(index == (res.docs.length - 1) && !disponible){
              Swal.fire("Espera",`La server ya tien servicio las fechas ${razonBloqueo}`,"warning")
            }
          });
        } else {
          console.log("SERVER TOTALMENTE DISPONIOBLE!!");
          this.serverServicio = server;
          this.servicioService.getPlanRecurrenteIdServer(server.id).then(res=>{
            console.log(res.docs.length);
            this.flippedStickyCard = true;
            if(res.docs.length > 0){
              res.docs.forEach(data => {
                let planRecurrente = data.data();
                this.planesAsignadosServer.push(planRecurrente);
                console.log(this.planesAsignadosServer);
              });
            };
          });
             
        }
      });
    }
  
  // calcular dias para gendar
  // trare planes de cada Server
  // validar los dias para agendar con los planes ya existentes
  // si tiene los mismos revisar el la server yla cantidad de horas que trabaja ese dia y dividir
  
    public piture(imgURL){
      this.filenameToRender = imgURL;
      this.disabledUpdateBtn = this.helper.resetProperty(this.datosEdit);
    }
  
    onUpload(event) {
      const dia= new Date().getTime()
      const filePath = `Novedades/${dia}`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(event.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.prevPdf= url;
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  
  /*   
    console.log("Entro en filtroPlanRecurrente()");
    this.planRecurrenteService.getPlanByIdServer(id).then(res=>{
      res.docs.forEach(data=> {
        console.log(data.data().diasAgendado);
        for(let fch of fechas){
          if(data.data().diasAgendado.includes(fch)){
            console.log('a',fch);
            disponible = false;
            mensaje = mensaje + " " + fch
          }else{
            console.log("b",fch);
          }
        }
        count++
        if(count == res.docs.length){
          if(!disponible){
            Swal.fire('warning',`La server ya tiene plan para los ${mensaje}`)
          }
        }
      });
    }).catch(err=>{
      console.log('Ocurrio un error,',err);
    })
  */
  
  
    verificarEstadoPago(plazoPago){
      if(plazoPago == "pago adelantado"){
        if(this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios" || this.tipoAgendamiento == "Administrativo"){
          this.fechaLimitePagoServicio = moment(this.fechaServicio).subtract(1,"days").format("YYYY-MM-DD")
        }else{
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan,["fecha"],["asc"]);
          let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha
          this.fechaLimitePagoServicio = moment(primeraFecha).subtract(1,"days").format("YYYY-MM-DD")
        }
        this.plazoPagoServicio = "Pago adelantado";
        this.estadoDelPagoServicio = "Pago"
        console.log(this.plazoPagoServicio,this.fechaLimitePagoServicio);
      }else{
        let a = parseInt(plazoPago)
        if(this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios"  || this.tipoAgendamiento == "Administrativo"){
          this.fechaLimitePagoServicio = moment(this.fechaServicio).add(a,"days").format("YYYY-MM-DD")
        }else{
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan,["fecha"],["asc"]);
          let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha
          this.fechaLimitePagoServicio = moment(primeraFecha).add(a,"days").format("YYYY-MM-DD")
        }
        this.plazoPagoServicio = `A ${a} días`
        this.estadoDelPagoServicio = "Pendiente"
        this.urlComprobanteServicio = "Falta"
        console.log(this.plazoPagoServicio,this.fechaLimitePagoServicio);
      }
    }
  
    verCuponosDisponibles(){
      console.log('entro en verCuponosDisponibles()')
      if(this.cuponesDisponibles.length === 0){
        this.cuponService.getCuponesActivos().then(res=>{
          res.docs.forEach(data=>{
            const cupon = data.data()
            console.log(cupon)
            const cuponRedimido = cupon.clientesArray.filter(cliente=>cliente.id == this.clienteServicio.id)
            console.log(cuponRedimido)
            if(cuponRedimido.length === 0){
              this.cuponesDisponibles.push(cupon)
            }
          })
        }).catch(err=>{
          console.log('ocurrio un error al trar los cupones',err)
        })
      }else{
        console.log('CuponesYaRevisados')
      }
    }
    cuponSeleccionado(event){
      console.log('Cupon usado',this.cuponesDisponibles[event.target.selectedIndex])
      this.cuponElegido = this.cuponesDisponibles[event.target.selectedIndex]
      
    }
  
    onUploadComprobante(event) {
      let file: any;
      let pictureName: any;
      let image = false;
      let nameImage: any;
      let fotoSeleccionada: any;
      console.log("files", event.target.files[0]);
      file = event.target.files[0];
      pictureName = Date.now() + file.name;
      image = true;
      if (event.target.files && file) {
        nameImage = file.name;
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          fotoSeleccionada = (<FileReader>event.target).result;
          console.log("foto seeccionada", fotoSeleccionada);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        console.log("no pasa el primer if");
      }
      if (file && pictureName) {
        this.servicioService.putPicture(pictureName, file)
          .then((value) => {
            console.log("value", value);
            if (value.state == "success") {
              this.servicioService.getURL(pictureName).subscribe((url) => {
                this.urlComprobanteServicio = url;
                this.servicioEnEdicion ? this.servicioEnEdicion.comprobante = url : null
                console.log(url);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("no pasa el segundo if");
      }
      console.log(this.urlComprobanteServicio);
    }
    agendarServicioIndividual(){
      if(this.descuentoServicio > 99){
        this.descuentoServicio = 0
      }
      let servicio: any = {
        asesor:this.usuarioEnSecion.displayName,
        categoria: this.categoriaServicio,
        checkList: this.checkListFinal,
        // cities: this.direccionServicio.cities,
        client: this.clienteServicio,
        comprobante: this.urlComprobanteServicio,
        // country: this.direccionServicio.country, //Ya esta en destination
        creado: "admin",
        // department: this.direccionServicio.department, //Ya esta en destination
        destination: this.direccionServicio,
        descuento: this.descuentoServicio,
        dia: this.diaServicio,
        direccion: this.direccionServicio.direccion,
        estado:  this.tipoGestion == "server" ? "Pendiente" : "Orden de servicio",
        estadoPago: this.estadoDelPagoServicio,
        fecha: this.fechaServicio,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.fechaLimitePagoServicio,
        formaDePago: this.metodoPagoServicio,
        hora: `${this.horaInicioServicio}:00`,
        horario: this.horarioServicio,
        horasDeServicio: parseInt(this.horasDeServicio),
        id: new Date().getTime(),
        idServer:  this.tipoGestion == "server" ? this.serverServicio.id : "",
        // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
        novedad: false,
        numFactura: this.numFacturaServicio,
        observaciones: this.direccionServicio.observaciones, //Ya esta en destination
        precio: this.precioServicio,
        recargo: this.recargoServicio,
        razonRecargo: this.razonRecargo,
        referenciaPago: this.referenciaPagoServicio,
        server:  this.tipoGestion == "server" ? this.serverServicio : "",
        servicio: this.tipoServicio,
        subCategoria: this.subCategoriaServicio,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: this.tipoAgendamiento == 'Multiples servicios' ? 'Plan Individual' : this.tipoAgendamiento,
        total: this.precioServicio + this.recargoServicio + this.recargoTransporteServicio - this.descuentoServicio,
        userid: this.clienteServicio.id,
        sillas: this.categoriaServicio     == 'Limpieza especializada' ? this.mueblesDelServicio  : "No aplica",
        cmTapete: this.categoriaServicio   == 'Limpieza especializada' ? this.cmTapetes           : "No aplica",
        colchones: this.categoriaServicio  == 'Limpieza especializada' ? this.colchonesDelServcio : "No aplica",
        tapetePiso: this.categoriaServicio == 'Limpieza especializada' ? this.mtsTapetePiso       : "No aplica",
        metrosDesinfeccion: this.categoriaServicio == 'Desinfección'   ? this.metrosDesinfeccionServicio  : "No aplica",
        subasta: this.tipoGestion == "server" ? false : true,
        estadoPagoServer: "Pendiente",
        gananciaServer: this.gananciaServerServicio + (this.mtsTapetePiso*4500),
        responsablePostventa: this.usuarioEnSecion.displayName,
        gananciaServ: (this.precioServicio + this.recargoServicio) - this.gananciaServerServicio,
      }
      if( this.tipoGestion == "server"){
        if(this.serverServicio.numeraria.estado === true && servicio.fecha === moment().add(1,'days').format('YYYY-MM-DD')){
          this.serverServicio.numeraria.estado === false
        }
      }
      if(this.clienteServicio.serversAgendadas == undefined){
        this.clienteServicio.serversAgendadas = ['1']
      }
  
      const arrayServers = this.clienteServicio.serversAgendadas.filter(server=>server == servicio.idServer)
      if(this.asignarPostVenta == true || arrayServers.length == 0 ){
        servicio.estadoPostventa = "postventa pendiente";
        servicio.responsablePostventa = this.usuarioEnSecion.displayName;
        servicio.fechaHoraPostventa = moment(this.fechaServicio).add(1,'days').format('YYYY-MM-DD');
      }
      
      this.servicioService.registerServicio(servicio).then(res=>{
        this.agregarAlHistorialDepagos(servicio)
        if(this.cuponElegido != ''){
          this.actualizarCupon(this.clienteServicio)
        }
        if(servicio.client.rol === 'usuario lead'){
          servicio.client.rol = 'usuario'
          this.usuarioService.updateClient(servicio.client)
        }
        if(arrayServers.length == 0){
          this.clienteServicio.serversAgendadas.push(servicio.idServer)
          this.actualizarServersCliente(this.clienteServicio)
        }
        Swal.fire("Agendado","El servicio a sido agendado","success");
        if(this.tipoAgendamiento == 'Multiples servicios'){
          this.numFacturaServicio = '';
          this.serverServicio = null;
          this.fechaServicio = '';
          this.tipoAgendamiento = '';
        }else {
          const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
          element.click();
        }
        this.enviarConfirmacion(servicio);
  
      })
    }
    beforeMonthViewRender(calendar: CalendarMonthViewBeforeRenderEvent): void {
      calendar.body.forEach((day) => {
        for (let fechaSeleccionada of this.diasSeleccionados) {
          if (fechaSeleccionada.date.getTime() == day.date.getTime()) {
            day.cssClass = "diaSeleccionado-calendario-agendarPlan";
          } else {
          }
        }
      });
    }
  
    actualizarCupon(info){
      this.cuponElegido.clientesArray.push({
        id: info.id,
        nombre: info.name,
        telefono: info.telefono,
        documento: info.documento,
        fechaUso: moment().format('YYYY-MM-DD')
      })
      this.cuponService.updateCupon(this.cuponElegido)
    }
  
  /*########## COMENTARIOS CLIENTE #########*/
    AddComentario(tipo) {
      this.comentario.name = this.usuarioEnSecion.displayName;
      if (tipo == "server") {
        this.ingresarComentarioServer = true;
      } else if (tipo == "logistica") {
        this.ingresarComentarioLogistica = true;
      }
    }
    cancelarComentario() {
      this.ingresarComentarioLogistica = false;
      this.ingresarComentarioServer = false;
    }
    guardarComentario(tipo) {
      this.comentario.name = this.usuarioEnSecion.displayName;
      const ret: any = this.funcionesUsuario.comentariosCliente(
        tipo,
        this.clienteServicio,
        this.comentario
      );
      this.ingresarComentarioLogistica = false;
      this.ingresarComentarioServer = false;
      this.comentario = {
        name: "",
        comentario: "",
        fecha: this.fechaActual,
      };
    }
  /*########################################*/
    contadorServiviciosCreados = 0;
    agendarServiciosDelPlan(){
      this.loaderAgendandoPlan = true;
      this.contadorServiviciosCreados = 0
      this.arrayPreInfoServiciosPlan.forEach(element => {
        let servicio: any = {
          asesor:/* this.usuarioEnSecion.displayName */ 'Santiago Peñarete',
          categoria: this.categoriaServicio,
          checkList: this.checkListFinal,
          // cities: this.direccionServicio.cities,
          client: this.clienteServicio,
          comprobante: this.urlComprobanteServicio,
          // country: this.direccionServicio.country, //Ya esta en destination
          creado: "admin",
          // department: this.direccionServicio.department, //Ya esta en destination
          destination: this.direccionServicio,
          dia: element.dia,
          direccion: this.direccionServicio.direccion,
          estado: "Orden de plan",
          estadoPago: this.estadoDelPagoServicio,
          fecha: element.fecha,
          fechaCreacion: moment().format("YYYY-MM-DD"),
          fechaLimitePago: this.fechaLimitePagoServicio,
          formaDePago: this.metodoPagoServicio,
          hora: element.hora + ":00",
          horario: element.horario,
          horasDeServicio: parseInt(this.horasDeServicio),
          id: element.id,
          idServer: this.tipoGestion == "server" ? this.serverServicio.id : "",
          // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
          novedad: false,
          numFactura: this.numFacturaServicio,
          observaciones: this.direccionServicio.observaciones,
          precio: element.precio, 
          recargo: element.recargo,
          recargoTransporte: element.recargoTransporte,
          referenciaPago: this.referenciaPagoServicio,
          server:  this.tipoGestion == "server" ? this.serverServicio : "",
          servicio: this.tipoServicio,
          subCategoria: this.subCategoriaServicio,
          // tipoPropiedad: this.direccionServicio.tipoPropiedad,
          tipoServicio: this.tipoAgendamiento,
          total:  element.precio + element.recargo + this.recargoPlan,
          userid: this.clienteServicio.id,
          subasta: this.tipoGestion == "server" ? false : false,
          estadoPagoServer: "Pendiente",
          gananciaServer: element.gananciaServer,
         
          gananciaServ: (element.precio + element.recargo + this.recargoPlan) - element.gananciaServer,
        }
        console.log("Datos del servicio que guarda",servicio)
        this.servicioService.registerServicio(servicio).then(res=>{
          console.log("ServicioGuardado")
          this.contadorServiviciosCreados++
          if(this.contadorServiviciosCreados == this.arrayPreInfoServiciosPlan.length){
            this.agendarPlan()
          }
          // Swal.fire("Agendado","El servicio a sido agendado","success");
        })
      });
    }
  
    agendarPlan(){
      if(this.descuentoPlan > 99){
        this.descuentoPlan = 0;
      } 
      let plan: any = {
        idNotificacion: new Date().getTime(),
        asesor: this.usuarioEnSecion.displayName,
        cantidadReagendanientos: 0,
        cantidadServicios: this.mesesSelccionadoPlan[0].servicosDelMes,
        categoria: this.categoriaServicio,
        cities: this.direccionServicio.cities ? this.direccionServicio.cities : "Colombia",
        client: this.clienteServicio,
        comprobanteActual: this.urlComprobanteServicio,
        // country: this.direccionServicio.country,
        correoPorVencer: false,
        creado: 'admin',
        checkList: this.checkListFinal,
        // department: this.direccionServicio.department,
        descuento: this.descuentoPlan,
        destination: this.direccionServicio,
        direccion: this.direccionServicio.direccion,
        enviarCorreo: true,
        estado: this.tipoGestion == "server" ? "Activo" : "Orden de plan",
        estadoConfirmacion: "Pendiente",
        estadoPago: this.estadoDelPagoServicio,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaInicio: this.arrayPreInfoServiciosPlan[0].fecha,
        fechaLimitePago: this.fechaLimitePagoServicio,
        plazoDePago: this.plazoPagoServicio,
        fechaReagendamiento: moment().format("YYYY-MM-DD"),
        fechaVencimiento: "", // se ajusta aotmaticamentre al cargar la tabla de planes
        formaDePago: this.metodoPagoServicio,
        frecuencia: this.frecuenciaPlan,
        horasDeServicio: parseInt(this.horasDeServicio),
        idLocation: "4fZC8McSlOvpPfIZnG7w",
        historialServicios: this.arrayPreInfoServiciosPlan,
        horaDeInicio: this.arrayPreInfoServiciosPlan[0].hora,
        infoServicio:  this.arrayPreInfoServiciosPlan,
        // neighborhood:  this.direccionServicio.neighborhood,
        numeroFactura:  this.numFacturaServicio,
        observaciones: this.direccionServicio.observaciones,
        pagoDosCuotas: this.pagoDosCuotas == 'si' ? true : false,
        precio: this.precioPlan,
        precioIndividual: this.precioPorServicio,
        recurrente: "si",
        referenciaPago: this.referenciaPagoServicio,
        server: this.tipoGestion == "server" ? this.serverServicio : { nameServer: "Orden de servicio",id: 123,},
        idServers: this.tipoGestion == "server" ? this.serverServicio.id : "",
        serverName:  this.tipoGestion == "server" ? this.serverServicio : "Orden de servicio",
        servicio: this.tipoServicio,
        serviciosConsumidos: 0,
        serviciosRestantes: this.numeroServiciosPlan,
        subCategoria: this.subCategoriaServicio,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: "Plan Mensual",
        recargo: this.recargoPlan,
        total: this.precioPlan + (this.recargoPlan*this.numeroServiciosPlan) - this.descuentoPlan,
        userid: this.clienteServicio.id,
        totalServicios: this.numeroServiciosPlan,
        diasDeServicio: []
      }
      if(plan.destination.department === "Cundinamarca"){
        plan.asesor = 'Leidy Carolina Barrero Briñez';
      }else if(plan.destination.department === "Antioquia"){
        plan.asesor = 'Laura Rippe';
      }
      if(this.pagoDosCuotas == 'si'){
        plan.cuota = (plan.total/2)
      }
      if(this.clienteServicio.serversAgendadas == undefined){
        this.clienteServicio.serversAgendadas = ['1']
      }
      const arrayServers = this.clienteServicio.serversAgendadas.filter(server=>server == plan.idServers)
      if(this.asignarPostVenta == true || arrayServers.length == 0){
        plan.estadoPostventa = "postventa pendiente";
        plan.responsablePostventa = this.usuarioEnSecion.displayName;
        let a = Math.abs(moment(this.arrayPreInfoServiciosPlan[0].fecha).diff(this.arrayPreInfoServiciosPlan[this.arrayPreInfoServiciosPlan.length-1].fecha,'days'))
        plan.fechaPostventa = moment(this.arrayPreInfoServiciosPlan[0].fecha).add((a/2),'days').format("YYYY-MM-DD");
      }
    
      if(this.usuarioEnSecion.rol === 'ventas'){
        plan.asesor = this.usuarioEnSecion.displayName
      }
      let diasServicio = [];
      let diasConfirmados = [];
      let diasConfirmadosPlan = [];
  
      let contadorDias = 0;
      this.arrayPreInfoServiciosPlan.forEach(element => {
        let numDia =  moment(element.fecha).day()
        let dia = ""
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }
        let diaAgregado = diasServicio.filter(day=>day == dia)
        if (diaAgregado.length >= 0 && diaAgregado.length  <= 1) {
          diasServicio.push(dia);
          // console.log(diasServicio);
          contadorDias++;
        }else if(diaAgregado.length == 2){
          diasServicio.push(dia);
          diasConfirmados.push(dia);
          diasConfirmadosPlan.push({dia:numDia});
          contadorDias++;
        } else {
          contadorDias++;
        }
        console.log(contadorDias)
        if(contadorDias == this.arrayPreInfoServiciosPlan.length){
          let planRecurrente = {
            id: "",
            horas: plan.horasDeServicio,
            clientId: plan.client.id,
            checkList: this.checkListFinal,
            clienteName: `${plan.client.name}  ${plan.client.apellido}`,
            horaInicio: this.arrayPreInfoServiciosPlan[0].hora,
            diasAgendado: diasConfirmados,
            numServicios: plan.cantidadServicios,
            horasDeServicio: plan.horasDeServicio,
            precioServicio: plan.precioIndividual,
            idServerAsignada: plan.server.id,
            serverName: `${plan.server.name}  ${plan.server.apellido}`,
          }
          console.log(plan,planRecurrente)
          plan.diasDeServicio = diasConfirmadosPlan;
          this.servicioService.registrarPlan(plan,planRecurrente).then(async (res) => {
            if(this.cuponElegido != ''){
              await this.actualizarCupon(this.clienteServicio)
            }
            if( this.tipoGestion == "server"){
              let nuevaServerFavorita = {
                foto: plan.server.foto,
                id: plan.idServers,
                nombreServer: plan.serverName
              }
              this.clienteServicio.serverFavorita = nuevaServerFavorita
              if(arrayServers.length == 0){
                this.clienteServicio.serversAgendadas.push(plan.idServers)
              }
              await this.actualizarServersCliente(this.clienteServicio)
            }
  
            await this.agregarMultiplesPagosAlHistorial(plan)
            this.loadingTablaPlan = false
            Swal.fire("Agendado","Has logrado crear un plan con exito","success").then(res=>{
              const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
              element.click();
            })
          }); 
        }
      });
    }
  
  
    // Funcion para guaradar en el cliente la nueva server que se le asigno
    actualizarServersCliente(cliente){
      this.usuarioService.updateClient(cliente)
    }
  
    public agregarAlHistorialDepagos(info){
      this.servicioService.getPagosUsuarioByClientId(info.client.id).then(res=>{
        let infoDelPago: any = {
          total: info.total,
          precio: info.precio,
          recargo: info.recargo,
          recargoTransporte: info.recargoTransporte,
          descuento: info.descuento,
          estadoDelPago: info.estadoPago,
          horas:     info.horasDeServicio,
          tipoServicio: info.tipoServicio,
          factura:  '',
          comprobante:       info.tipoServicio == "Plan Mensual" ? info.comprobanteActual : info.comprobante,
          numeroServicios:   info.tipoServicio == "Plan Mensual" ? info.cantidadServicios : 1, 
          idPlanOServicio:   info.tipoServicio == "Plan Mensual" ? "" : info.id,
          fechaAgendamiento: info.tipoServicio == "Plan Mensual" ? info.fechaReagendamiento : info.fecha,
        };
        if(info.pagoDosCuotas){
          infoDelPago.cuota = info.cuota;
          infoDelPago.comprobanteDos = 'falta';
        }
        if(res.docs.length == 0){
          let pagoUsuario = {
            id: info.client.id,
            IdCliente: info.client.id,
            cobrosRealizados: [],
          };
          pagoUsuario.cobrosRealizados.push(infoDelPago);
          this.servicioService.postPagosUsuario(pagoUsuario,infoDelPago.tipoServicio).then(
            (res) => {
              console.log("se guardo el cobro");
            }
          );
        }else{
          res.docs.forEach((data) => {
            let historialPagos = data.data();
            console.log("historial de pagos", historialPagos);
            console.log(infoDelPago);
            historialPagos.cobrosRealizados.unshift(infoDelPago);
            this.servicioService.guardaruevoCobro(historialPagos, infoDelPago.tipoServicio).then(
              (res) => {
                console.log("el cobro fue subido");
              }
            );
          });
        }
      })
    }
  
    public agregarMultiplesPagosAlHistorial(info){
      console.log("Entro en agregarMultiplesPagosAlHistorial")
      let idPlan = this.servicioService.idPlanAgendado;
      let cobros = []
      
      this.mesesSelccionadoPlan.forEach((mesInfo:any,index:number)=>{
        let cobro = {
          id: mesInfo.id,
          horas: info.horasDeServicio,
          precio: mesInfo.precio,
          recargo: mesInfo.recargo,
          recargoTransporte: mesInfo.recargoTransporte,
          descuento: info.descuento,
          total: (mesInfo.precio +  mesInfo.recargo + mesInfo.recargoTransporte) - info.descuento,
          tipoServicio: info.tipoServicio,
          factura: "",
          comprobante:  index == 0 ? info.comprobanteActual : "" ,
          estadoDelPago: index == 0 ? info.estadoPago : "Pendiente",
          numeroServicios:   mesInfo.servicosDelMes,
          idPlanOServicio:   idPlan,
          verifidadoEnBanco: false,
          fechaAgendamiento: info.fechaReagendamiento,
          fechaInicio: mesInfo.fechaInicio,
          fechaFin: mesInfo.fechaFin,
          clientId: info.client.id
        }
        console.log(cobro)
        this.servicioService.postPagoPlanUsuario(cobro);
      })
    }
  
    // public agregarMultiplesPagosAlHistorial(info){
    //   console.log("Entro en agregarMultiplesPagosAlHistorial")
    //   let idPlan = this.ServicioService.idPlanAgendado;
    //   let cobros = []
      
    //   this.mesesSelccionadoPlan.forEach((mesInfo:any,index:number)=>{
    //     let cobro = {
    //       id: mesInfo.id,
    //       horas: info.horasDeServicio,
    //       precio: mesInfo.precio,
    //       recargo: info.recargo,
    //       descuento: info.descuento,
    //       total: mesInfo.total,
    //       tipoServicio: info.tipoServicio,
    //       factura:     index == 0 ? info.numeroFactura : "",
    //       comprobante:  index == 0 ? info.comprobanteActual : "" ,
    //       estadoDelPago: index == 0 ? info.estadoPago : "Pendiente",
    //       numeroServicios:   mesInfo.servicosDelMes,
    //       idPlanOServicio:   info.id,
    //       fechaAgendamiento: info.fechaReagendamiento,
    //       fechaInicio: mesInfo.fechaInicio,
    //       fechaFin: mesInfo.fechaFin,
    //       clientId: info.client.id
    //     }
    //     console.log(cobro)
    //     this.ServicioService.postPagoPlanUsuario(cobro)
    //   })
    // }
  /******************************************************************************/
  
    enviarConfirmacion(event) {
      let notificacionConfirmacion = event;
      notificacionConfirmacion.idTemplate = "d-d19f6afa0d294d3ea4e9019932b8cfb2";
      notificacionConfirmacion.fechaParseCliente = moment(event.fecha).format("dddd DD [de] MMMM [de] YYYY");
      console.log(notificacionConfirmacion.fechaParseCliente);
      const requestCorreoSendgrid = firebase2
        .functions()
        .httpsCallable("requestCorreoAspirantes");
      requestCorreoSendgrid({
        name: notificacionConfirmacion.client.name,
        correo: notificacionConfirmacion.client.email,
        telefono: notificacionConfirmacion.client.telefono,
        servicioCompleto: notificacionConfirmacion,
        idTemplate: notificacionConfirmacion.idTemplate,
      }).then((result) => {
        console.log(result.data);
      });
    }
  
    onCustom(event){
      // console.log(event)
      if(event.action == "editar"){
        this.loaderServerServicioEnEdicion = false;
        this.servicioEnEdicion = event.data;
        this.verMdlInfoSevicio = false
        setTimeout(()=>{
          this.verMdlInfoSevicio = true
        },200)
        this.editarHoras = false;
        this.editarFecha = false;
        this.horarioEdidato = false;
        this.editarDireccion = false;
        this.editarHoraInicio = false;
        this.activeUpdateServer = false;
        // // @ts-ignore 
        // this.nbDialogService.open(this.mdlEditar, { context: 'this is some additional data passed to dialog' });
      }
    }
  


    verificarServersSolicitadas(){
      this.listaClientes.forEach(async element => {
        console.log(element.serversAgendadas)
        if(element.serversAgendadas == undefined){
          // console.log('1',element.serversAgendadas)
          element.serversAgendadas = ['1']
          this.usuarioService.updateClient(element).then(()=>{
            console.log('5')
          })
          // await this.verSiervicios(element)
        }else{
        }
      });
    }
  
    verSiervicios(cliente){
      console.log('1')
      let count = 0
      this.servicioService.getByUser(cliente.id).then(async res=>{
        console.log('2-----',res.docs.length)
        if(res.docs.length > 0){
          res.docs.forEach(async data=>{
            let servicio = data.data()
            const a = cliente.serversAgendadas.filter(server=>server == servicio.idServer)
            // console.log(a)
            if(a.length == 0){
              cliente.serversAgendadas.push(servicio.idServer)
            }
            count++
            if(count == res.docs.length){
              await this.guardarCliente(cliente)
            }
          })
  
        }else{
          await this.guardarCliente(cliente)
        }
      })
    }
  
    guardarCliente(cliente){
      // console.log(cliente)
      this.usuarioService.updateClient(cliente).then(()=>{
        console.log('5')
      })
    }
    public diasSelccionadoPlan = [];
    public mesesSelccionadoPlan = [];
    public arrayMeses = [];
    public fechasPlan = [];
  
    calcularMesesSiguentes( ){
      for(let i = 0;i < 7;i++){
        const m = moment().add(i,'months').format('MMMM')
        const mI = moment().add(i,'months').format('M')
        this.arrayMeses.push({
          name:m,
          index:mI
        })
      }
      // console.log(this.arrayMeses)
    }
    seleccionariDiasPlan(i){
      console.log(i)
      let index = this.diasSelccionadoPlan.findIndex(res=>res.diaInt == i)
      console.log("Index dias",index)
      let diaString = ""
      if(i == 0){
        diaString = "domingo"
      }else if(i == 1){
        diaString = "lunes"
      }else if(i == 2){
        diaString = "martes"
      }else if(i == 3){
        diaString = "miercoles"
      }else if(i == 4){
        diaString = "jueves"
      }else if(i == 5){
        diaString = "viernes"
      }else if(i == 6){
        diaString = "sabado"
      }
      if(index == -1){
        this.diasSelccionadoPlan.push({
          hora: 6,
          diaInt:i,
          diaString: diaString
        })
      }else{
        this.diasSelccionadoPlan.splice(index,1)
      }
      this.mesesSelccionadoPlan = [];
      // console.log(this.diasSelccionadoPlan)
    }
    seleccionarMesesPlan(indexMes,indexArray){
      this.verificarFrecuenciaPlan();
      let index = this.mesesSelccionadoPlan.findIndex(res=>res.mes == indexMes)
      // console.log("Index planes",index,indexArray)
     
      if(index == -1){
        this.mesesSelccionadoPlan.push({
          mes:indexMes,
          indexArray:indexArray,
          precio:0,
          total:0,
          id: new Date().getTime(),
          precioIndividual:0,
          fechaInicio: "",
          fechaFin: "",
          serviciosVinculados: [],
        })
      }else{
        this.mesesSelccionadoPlan.splice(index,1)
      }
      // console.log(this.mesesSelccionadoPlan)
    }
    pruebaValirarDia(i):string{
      // console.log("Propiuedadpara  ngClase",i)
      const result = this.diasSelccionadoPlan.findIndex(res=>res.diaInt == i)
      if(result == -1){
        return  "div-diasPlan"
      }else{
        return  "div-diasPlanActive"
      }
    }
    cssMesPlanes(i):string{
      // console.log("Propiuedadpara  ngClase",i)
      const result = this.mesesSelccionadoPlan.findIndex(res=>res.mes == i)
      if(result == -1){
        return  "div-diasPlan"
      }else{
        return  "div-diasPlanActive"
      }
    }
    seleccionarHoraDiasPLan(i,event){
      const info = event.target.value;
      const index = this.diasSelccionadoPlan.findIndex(res=>res.diaInt == i)
      this.diasSelccionadoPlan[index].hora = info
    }
  
    generarFechasPlan(){
      let contadorDias = 0;
      this.arrayPreInfoServiciosPlan = [];
  
      this.mesesSelccionadoPlan.forEach(async (res:any)=>{
        console.log("mes--",res.mes,"indexMes--",res.indexArray)
        let mesCalculado = moment().format("YYYY-M-D")
        if(res.indexArray > 0){
          mesCalculado = moment().add(res.indexArray,"month").startOf("month").format("YYYY-M-D")
        }
        console.log("Mes a calcular",mesCalculado)
        // this.arrayPreInfoServiciosPlan.push(res.mes);
        for(let dia of this.diasSelccionadoPlan){
          const nextDate = new Date(mesCalculado);
          console.log("Paso1-------------",nextDate);
          let a = 0;
          nextDate.setDate(nextDate.getDate() + (dia.diaInt + 7 - nextDate.getDay())% 7);
          console.log("Paso2",nextDate);
          for(let i = 0;i<5;i++){
            let fecha = "";
            let fechaProb = "";
            console.log(i,a,"Antes de sumar",nextDate,"-------------")
            // fecha.setDate(nextDate.getDate() + ((i+a)*7));
            fecha =  moment(nextDate).add((i+a)*7,'days').format("YYYY-MM-DD")
            // fechaProb = moment(nextDate).add((i+a)*7,'days').format("YYYY-MM-DD");
            console.log("despues de la suma",fecha);
            console.log("despues de la suma Fecha prob",fechaProb);
            if(moment(fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
              fecha = moment(fecha).add(7,'days').format("YYYY-MM-DD")
              // fecha.setDate(fecha.getDate() + 7);
              // console.log("Aumento");
              a = 1;
            }
            let filterFestivos = this.arrayFestivos.filter(res=>res.fecha == moment(fecha).format("YYYY-MM-DD") );
            // console.log("Filtro festivos",filterFestivos,moment(fecha).format("YYYY-MM-DD"));
            if(filterFestivos.length == 0){
              // console.log(parseInt(moment(fecha).format("M")),res.mes)  
              if(parseInt(moment(fecha).format("M")) == res.mes){
                let idServicio = new Date().getTime()
                /* Precio servicio */
                let horario = dia.hora > 14 ? "tarde" : "mañana"
                let tipoHorario = dia.hora > 14 ? 1 : 0
                // console.log(horario,dia.hora);
                if(this.frecuenciaPlan === '1 por semana'){
                  console.log(this.diasServicios);
                  let filter = await this.diasServicios.filter((horario) => horario.name == dia.diaString)[0];
                  this.horariosServicios = filter['horario'];
                }
  
                // console.log(this.horariosServicios)
                // console.log(this.horariosServicios,this.horariosServicios[i].precio)
                // filterPrecioDos = await this.horariosServicios.filter((horario) => horario.name == horario)[0];
                // console.log(filterPrecioDos)
                let precio =  this.horariosServicios[tipoHorario].precio;
                let infoServicio = {
                  fecha: moment(fecha).format("YYYY-MM-DD"),
                  id: idServicio,
                  idServicios: idServicio,
                  hora: dia.hora,
                  idServer: "",
                  dia: dia.diaString,
                  horario: "",
                  precio: precio.valor,
                  recargo: dia.diaInt == 0 ? 10000 : 0,
                  recargoTransporte: 0,
                  total: 0,
                  gananciaServer: precio.gananciaServer,
                  estado: "Pendiente",
                  razonRecargo:  dia.diaInt == 0 ? 'Recargo dominical y festivo' : 'Sin recargo'
                };
                this.arrayPreInfoServiciosPlan.push(infoServicio)
              }
            }
          
          }
          // console.log(moment(nextDate).format("YYY-MM-DD"))
          contadorDias++
          // console.log(contadorDias,this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)
          
          if(contadorDias == (this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)){
            this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan,["id"],["asc"])
            console.log("Array de servicios plan",this.arrayPreInfoServiciosPlan)
            this.validarIdServiciosPlan();
            this.filtroServersPlanDiasBloqueados();
          }
  
        }
      })
    }
  
  
    validarIdServiciosPlan(){
      // let result = data.filter((item,index)=>{
      //   return data.indexOf(item) === index;
      // })
      let i = 1;
      let count = 0
      let idsEnUso = [];
      this.arrayPreInfoServiciosPlan.forEach(info=>{
        // console.log(info.id)
        const filtroId = idsEnUso.findIndex(res=>res == info.id)
        console.log(filtroId,info.id)
        if(filtroId == -1){
          idsEnUso.push(info.id)
        }else{
          info.id = info.id + i;
          info.idServicios = info.id
          i++
          idsEnUso.push(info.id)
        }
        count++
  
        if(count==this.arrayPreInfoServiciosPlan.length){
          console.log(this.arrayPreInfoServiciosPlan);
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan,["fecha"],["asc"])
          // console.log(this.arrayPreInfoServiciosPlan);
          this.calcularPrecioPlan();
        }
      })
    }
    eliminarServicioDeAgendamiento(index){
      console.log(index);
      this.arrayPreInfoServiciosPlan.splice(index,1)
      this.calcularPrecioPlan();
    }
  
  
    revisarFacturas(){
      let arrayPagos = [];
      this.servicioService.getAllPagosUsuario().then(res=>{
        res.docs.forEach(data=>{
          let info:any = data.data()
          // console.log(info.fechaInicio,info.fechaFin)
          if(info.estadoDelPago == 'Pendiente'){
  
            let filtro = this.listaClientes.filter(res=>res.id == info.clientId)[0];
            console.log(filtro,info.clientId)
            if(filtro != undefined){
              info.Acliente = `${filtro.name} ${filtro.apellido}`
            }else{
              info.Acliente = `NaN`
            }
            arrayPagos.push(info);
          }
        })
        console.log(arrayPagos);
        this.serverService.exportAsExcelFile(arrayPagos, "PagosPendientesSeptiembre");
  
      })
    }
  
    getServer(id){
    return new Promise(async resolve =>{
      const server = (await this.serverService.detail(id)).data()
      resolve(server)
    })
  
  }
  
  }

