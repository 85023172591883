import { Http,Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServBackendService {

  public baseURL = 'https://serv-admin.herokuapp.com/';
  // public baseURL = 'http://localhost:8080/';
  // public baseURL = 'http://localhost:40409/';
  headers: Headers;
  constructor(
    private http: Http,
  ) { 
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  } 

  get(object: string) {
    return this.http.get(this.baseURL + '' + object, { headers: this.headers })
      .pipe(map((res: any) => res.json()));
  }

  post(end_point,data){
    return this.http.post(`${this.baseURL}${end_point}`, JSON.stringify(data), { headers: this.headers })
    .pipe(
      map((res: any) => res.json())
    );
  }

  put(end_point,data){
    return this.http.put(`${this.baseURL}${end_point}`, JSON.stringify(data), { headers: this.headers })
    .pipe(
      map((res: any) => res.json())
    );
  }
  
}
