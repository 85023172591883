import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { CompleterService } from "ng2-completer";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from "moment";
import { ColorMonitoring,ColorMonitoring_text } from "../../utility/dataInfo";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AuthService } from "../../services/auth.service";
import * as firebase from "firebase";
require("firebase/functions")


/* Servicios */
import { CandidatoService } from "src/app/services/candidato.service";
import { InfoUniservSerice } from "src/app/services/infoUniserv.service";

@Component({
  selector: "app-candidatos",
  templateUrl: "./candidatos.component.html",
  styleUrls: ["./candidatos.component.scss"],
})
export class CandidatosComponent implements OnInit {

  initial_start_date = moment().subtract(30, 'days').format('YYYY-MM-DD')
  initial_end_date = moment().add(1,'days').format('YYYY-MM-DD')
  public settings_aspirantes = {
    selectMode: "multi",
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "delete", title: '<i class="nb-trash"></i> ' },
        { name: "detalle", title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      fechaCreacion: {
        title: "Fecha",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
      ciudad: {
        title: "Ciudad",
        valuePrepareFunction: (cell,row) => {
         return `${cell} ${row.zona}`;
        },
      },
      // zona: {
      //   title: "Zona",
      // },
      especialidad: {
        title: "Especialidad",
      },
      nombre: {
        title: "Nombre",
      },
      telefono:{
        title: "Telefono",
      },
      edad: {
        title: "Edad",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell > '25' ? 4 : 3;
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${cell}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      utilizaGoogleMaps: {
        title: "Maps",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'si' ? 4 : 3;
          let a = cell == null ? 'no' : cell
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${a}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${a}</div>`;
        },
      },
      tieneMoto: {
        title: "Moto?",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'si' ? 4 : 3;
          let a = cell == null ? 'no' : cell
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${a}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${a}</div>`;
        },
      },
      dispuestoEnMovilizarce: {
        title: "Dispuesto a movilizarce",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'si' ? 4 : 3;
          let a = cell == null ? 'no' : cell
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${a}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${a}</div>`;
        },
      },
      smartphone: {
        title: "Datos moviles",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'si' ? 4 : 3;
          let a = cell == null ? 'no' : cell
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${a}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${a}</div>`;
        },
      },
      edadDeHijos: {
        title: "Hijos",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'menos de 3 años' ? 3 : 4;
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${cell}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      ingresaTemprano: {
        title: "6am",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'si' ? 4 : 3;
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${cell}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      disponibilidadDeTiempo: {
        title: "Disponibilidad",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'tiempo completo' ? 4 : 3;
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${cell}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      tipoPersona: {
        title: "Perosnalidad",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'soy una persona tranquila y callada' ? 4 : 3;
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${cell}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${cell}</div>`;
        },
      },
      experienciaEnAseo1: {
        title: "Experiencia",
        type: "html",
        valuePrepareFunction: (cell) => {
          let i = cell == 'experiencia' ? 4 : 3;
          let a = cell == 'experiencia' ? 'si' : 'No';
          if(i == 4) return `<div class="info-tabla ${ColorMonitoring_text[i]}">${a}</div>`;
          else return `<div class="info-tabla ${ColorMonitoring[i]}">${a}</div>`;
        },
      },
    },
  };

  public settings_citados = {
    selectMode: "multi",
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "delete", title: '<i class="nb-trash"></i> ' },
        { name: "detalle", title: '<i class="nb-search"></i>' },

      ],
    },
    columns: {
      fechaCreacion: {
        title: "Fecha de registro",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
      fechaEntrevista: {
        title: "Fecha Entrevista",
        type: "string",
      },
      ciudad: {
        title: "Ciudad",
      },
      especialidad: {
        title: "Especialidad",
      },
      nombre: {
        title: "Nombre",
      },
      apellido: {
        title: "Apellidos",
      },
    },

    attr: {
      class: "table table-bordered",
    },
    // rowClassFunction: (row) => {
    //   if (row.data.estado == "Entrevista Online") {
    //     return "colorAzul";
    //   }
    //   if (row.data.estado == "Visita Domiciliaria") {
    //     return "colorAzul";
    //   }
    //   if (row.data.estado == "Induccion y prueba") {
    //     return "colorAzul";
    //   }
    //   if (row.data.estado == "Activacion") {
    //     return "colorAmarillo";
    //   }
    // },
  };
  public settings5 = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },

    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "delete", title: '<i class="nb-trash"></i> ' },
        { name: "detalle", title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      estado: {
        title: "Estado del aspirante",
        filtering: { filterString: "entrevista" },
      },
      fechaEntrevista: {
        title: "Fecha citación",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
      ciudad: {
        title: "Ciudad",
      },
      especialidad: {
        title: "Especialidad",
      },
      nombre: {
        title: "Nombre",
      },
      apellido: {
        title: "Apellidos",
      },
    },

    attr: {
      class: "table table-bordered",
    },
    rowClassFunction: (row) => {
      if (row.data.estado == "Entrevista Online") {
        return "colorAzul";
      }
      if (row.data.estado == "Visita Domiciliaria") {
        return "colorAzul";
      }
      if (row.data.estado == "Induccion y prueba") {
        return "colorAzul";
      }
      if (row.data.estado == "Activacion") {
        return "colorAmarillo";
      }
    },
  };
  public settings3 = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },

    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "delete", title: '<i class="nb-trash"></i> ' },
        { name: "detalle", title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      estado: {
        title: "Estado del aspirante",
        filtering: { filterString: "entrevista" },
      },
      ciudad: {
        title: "Ciudad",
      },
      especialidad: {
        title: "Especialidad",
      },
      nombre: {
        title: "Nombre",
      },
      apellido: {
        title: "Apellidos",
      },
      fechaCreacion: {
        title: "Fecha solicitud",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
      fechaEntrevista: {
        title: "Fecha Entrevista",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
      fechaActivacion: {
        title: "Fecha activación",
        type: "string",
        valuePrepareFunction: (fec) => {
          moment.locale("es");
          return moment(fec).format("DD/MM/YYYY hh:mm:ss") == "Invalid date"
            ? "N/A"
            : moment(fec).format("dddd DD/MM/YYYY HH:MM");
        },
      },
    },

    attr: {
      class: "table table-bordered",
    },
    rowClassFunction: (row) => {
      if (row.data.estado == "Entrevista Online") {
        return "colorAzul";
      }
      if (row.data.estado == "Visita Domiciliaria") {
        return "colorAzul";
      }
      if (row.data.estado == "Induccion y prueba") {
        return "colorAzul";
      }
      if (row.data.estado == "Activacion") {
        return "colorAmarillo";
      }
    },
  };
  public settings7 = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },

    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "delete", title: '<i class="nb-trash"></i> ' },
        { name: "detalle2", title: '<i class="nb-play"></i>' },
      ],
    },
    columns: {
      id: {
        title: "id",
      },
      fechaEntrevista: {
        title: "Fecha Citación",
      },
      cursoAlmaServ: {
        title: "AlmaServ",
      },
      cursoAseoEmpresas: {
        title: "Aseo empresas",
      },
      cursoAseoHogar: {
        title: "Aseo Hogar",
      },
      /*cursoTodero: {
    title: "Todero"
 },*/
      /* cursoManejoMaquinas: {
    title: "Manejo maquinas"
 },*/
      name: {
        title: "Nombre",
      },
      apellido: {
        title: "Apellidos",
      },
      email: {
        title: "Correo",
      },
      telefono: {
        title: "Telefono",
      },
    },

    rowClassFunction: (row) => {
      if (
        row.data.cursoAlmaServ == "Aprobado" &&
        row.data.cursoAseoEmpresas == "Aprobado" &&
        row.data.cursoAseoHogar == "Aprobado"
      ) {
        return "colorVerde";
      } else {
        return "colorRojo";
      }
    },
  };
  public zonas: any = [
    { value: "norte", viewValue: "Zona Norte" },
    { value: "sur", viewValue: "Zona Sur" },
    { value: "occidente", viewValue: "Zona occidente" },
    { value: "oriente", viewValue: "Zona Oriente" },
    { value: "centro", viewValue: "Zona Centro" },
    { value: "sabana", viewValue: "Sabana" },
    { value: "chia", viewValue: "chia" },
    { value: "funza", viewValue: "funza" },
  ];

  public enviado: boolean;
  public aspirante: any = {};
  public aspirantecorreo: any = {};
  public estudiante: any = {};
  public data: any = [];
  public data2: any = [];
  public data3: any = [];
  public data4: any = [];
  public data5: any = [];
  public data6: any = [];
  public data7: any = [];
  public excelData2 = [];
  public source_aspirantes: any;
  public source_citados: any;
  public source3: any;
  public source4: any;
  public source5: any;
  public source6: any;
  public source7: any;
  /*  Para el modal */
  @ViewChild("crearAspirante", { static: true }) crearAspirante: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  preInfoServer: any;
  calendarioSitacion: boolean = false;
  aspirantesSeleccionados: any;
  fechaCitacion: any = '';
  data$: any;
  loading: boolean = false;
  mdlVerCandidatoEntrevista: boolean;
  mdlVerCandidatoVerificacion: boolean;
  mdlVerCandidato: boolean;
  candidato_id: any;

  /*****/
  constructor(
    public completerService: CompleterService,
    private dialogService: NbDialogService,
    private candidatoService: CandidatoService,
  ) {
    /*const callable = fns.httpsCallable('requestMensajeAspirante');
      this.data$ = callable({ name: 'michael' }); */
  }

  ngOnInit() {
    this.data = [];
    this.data2 = [];
    this.data3 = [];
    this.data4 = [];
    this.data5 = [];
    this.data6 = [];
    this.data7 = [];
    this.onCargar(this.initial_start_date, this.initial_end_date);
  }

  /**
   * Funcion para cargar todas las aspirantes existentes en el sistema
  */
  public async onCargar(start_date, end_date) {
    this.data  = [];
    this.data2 = [];
    this.data3 = [];
    this.data4 = [];
    this.data5 = [];
    this.data6 = [];
    this.data7 = [];
    let hoy = moment();
    this.candidatoService.getCandidatos_fecha_estado(start_date, end_date, 'Solicitud recibida').then((res) => {
      res.forEach(async (doc) => {
        let infoAspirante = doc.data()
        console.log(infoAspirante.nombre,doc.id)
        infoAspirante.edad = Math.abs(hoy.diff(infoAspirante.fechaDeNacimiento, 'years'))
        this.data.push(infoAspirante)
      });
      this.data = _.orderBy(this.data, ["fechaCreacion"], ["desc"]);
      this.source_aspirantes = new LocalDataSource(this.data);
    })
      .catch((err) => {
        console.log(err);
      });
    const query = await this.candidatoService.getCandidatosEntrevista_fecha(start_date, end_date)
    this.data2 = query.docs.map((doc) => {
      return { ...doc.data() }
    })
    // if (this.data.some(res => res.email == data.email)) {

    //   this.dataDuplicados.push(data)
    //   console.log('Duaplicado', data.email)
    // }
    //  this.data2.push(doc.data())


    // this.data2.sort = _.orderBy(this.data2, ["fechaEntrevista"], ["desc"]);
    this.source_citados = new LocalDataSource(this.data2);

    // this.candidatoService.getCandidatosPorEstado('Visita Domiciliaria').then((res) => {
    //     res.forEach((doc) => this.data3.push(doc.data()));

    //     this.data3 = _.orderBy(this.data3, ["fechaCreacion"], ["desc"]);
    //     this.source3 = new LocalDataSource(this.data3);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    // this.candidatoService.getCandidatosPorEstado('Induccion y contrato').then((res) => {
    //     res.forEach((doc) => this.data4.push(doc.data()));

    //     this.data4 = _.orderBy(this.data4, ["fechaInduccion"], ["desc"]);
    //     this.source4 = new LocalDataSource(this.data4);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    // this.candidatoService.getCandidatosPorEstado('Activacion').then((res) => {
    //     res.forEach((doc) => this.data5.push(doc.data()));

    //     this.data5 = _.orderBy(this.data5, ["fechaActivacion"], ["desc"]);
    //     this.source5 = new LocalDataSource(this.data5);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    // this.candidatoService.getCandidatosPorEstado('Verificacion de aspirante').then((res) => {
    //     res.forEach((doc) => this.data6.push(doc.data()));

    //     this.data6 = _.orderBy(this.data6, ["fechaVerificacion"], ["desc"]);
    //     this.source6 = new LocalDataSource(this.data6);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  onDateRangeChanged(event){
    const endDate   = moment(event.endJsDate).format("YYYY-MM-DD");
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    if (beginDate != "Invalid date" && endDate != "Invalid date") {
      this.onCargar(beginDate, endDate);
    }
  }
  export_excel() {
    this.candidatoService.exportAsExcelFile(this.data, "aspirantes");
  }

  public onVer(event) {
    this.candidato_id = event.data.id;
    console.log(event.data)
    this.mdlVerCandidato = false;
    setTimeout(() => {
      this.mdlVerCandidato = true;
    }, 100);
    // if (event.data.estado == "Entrevista Online") {
    //   this.mdlVerCandidatoEntrevista = false;
    //   setTimeout(() => {
    //     this.mdlVerCandidatoEntrevista = true;
    //   }, 100);
    //   // this.router.navigate([ "dashboard/ver-candidato-entrevista/" + event.data.id,]);
    // } else if (event.data.estado == "Induccion y contrato") {
    //   this.mdlVerCandidatoVerificacion = false;
    //   setTimeout(() => {
    //     this.mdlVerCandidatoVerificacion = true;
    //   }, 100);
    //   // this.router.navigate([ "dashboard/ver-candidato-verificacion/" + event.data.id,]);
    // } else {
    //   this.mdlVerCandidato = false;
    //   setTimeout(() => {
    //     this.mdlVerCandidato = true;
    //   }, 100);
    //   // this.router.navigate(["dashboard/ver-candidato/" + event.data.id]);
    // }
  }

  public onVer2(event) {
    console.log(event.data);
    this.candidatoService
      .getCandidatoIdEstudiante(event.data.id)
      .then((res) => {
        let aspirante = res.docs[0].data();
        aspirante.estado = "Induccion y contrato";
        this.candidatoService.editCandidato(aspirante);
        console.log(this.aspirantecorreo);
        Swal.fire({
          type: "success",
          text: "Éxito, estudiante enviado a induccion",
          showConfirmButton: true,
        });
        this.onCargar(this.initial_start_date, this.initial_end_date);
      });
  }

  public crear() {
    this.enviado = false;
    this.aspirante.id = this.makeId();
    this.aspirante.estado = "Solicitud recibida";
    this.aspirante.fechaCreacion = Date.now();
    if (
      this.aspirante.nombre &&
      this.aspirante.apellido &&
      _.includes(this.aspirante.email, "@") &&
      this.aspirante.nota &&
      this.aspirante.ciudad &&
      this.aspirante.especialidad &&
      this.aspirante.estado &&
      this.aspirante.id
    ) {
      this.candidatoService
        .post(this.aspirante)
        .then((result) => {
          Swal.fire("Éxito", "Se agregó el aspirante exitosamente", "success");
          this.cerrarModal();
          this.onCargar(this.initial_start_date, this.initial_end_date);
        })
        .catch((err) => {
          this.enviado = true;
          Swal.fire(
            "Error",
            "No se pudo agregar el aspirante, verifique la información",
            "warning"
          );
        });
    } else {
      Swal.fire(
        "Error",
        "No se pudo agregar el aspirante, verifique la información",
        "warning"
      );
    }
  }

  /**
   * Funcion para borrar una aspirante
   * @param event aspirante que se desea borrar
   */
  public onEliminar(event) {
    Swal.fire({
      title: "<strong>¿Deseas eliminar el aspirante?</strong>",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(
      (res) => {
        if (res.value) {
          this.candidatoService.eliminarCandidato(event.data);
          Swal.fire("Éxito", "Aspirante eliminado con éxito", "success");
          this.onCargar(this.initial_start_date, this.initial_end_date);
        }
      },
      (err) => {
        console.log(err);
        Swal.fire("Error", "Problema eliminando el aspirante", "warning");
      }
    );
    console.log(event);
    this.spinerGuardar = false;
  }

  /**
   * Funcion para abrir el modal de aspirantes
   */
  public modalAspirante() {
    this.aspirante = {};
    this.aspirante.estado = "Solicitud recibida";
    this.openDialogSave(this.crearAspirante);
  }

  /**
   * Funcion para abrir los modales
   * @param dialog Modal que se desee abrir
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  /**
   * Funcion para cerrar el modal
   */
  public cerrarModal() {
    this.spinerEdit = false;
    const element: HTMLElement = document.getElementById(
      "btn-close"
    ) as HTMLElement;
    element.click();
  }

  /**
   * Funcion que determina que accion se selecciono en la tabla
   * @param event datos seleccionados
   */
  onCustom(event) {
    switch (event.action) {
      case "delete":
        this.onEliminar(event);
        break;
      case "detalle":
        this.onVer(event);
        break;
      case "detalle2":
        this.onVer2(event);
        break;
    }
  }

  /**
   * Funcion para crear id aleatorio
   */
  public makeId() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onUserRowSelect(event) {
    console.log(event)
    if (event.selected.length > 0) {
      this.calendarioSitacion = true
    } else {
      this.calendarioSitacion = false
    }
    this.aspirantesSeleccionados = event.selected
  }
  
  onSendSubpoena() {

    if (this.fechaCitacion == '') {
      Swal.fire('Espera', 'Indica una fecha y hora para la citación', 'warning')
    } else {
      this.loading = true;
      const send_promise = this.aspirantesSeleccionados.map(element => {
        console.log('a');
        const aspirante = {
          nombre: `${element.nombre} ${element.apellido}`,
          telefono: element.telefono,
          email: element.email,
          fechaCitacion: this.fechaCitacion,
          id: element.id,
          vacante: element.especialidad ? element.especialidad : "empleada domestica"
        };
        console.log(aspirante)
        const requestMensajeAspirante = firebase.functions().httpsCallable("requestMensajeAspirante");
        return requestMensajeAspirante(aspirante);
      });

      Promise.all(send_promise)
        .then(() => {
          console.log("Todos los mensajes se han enviado correctamente.");
          this.fechaCitacion = '';
          // this.onCargar(this.initial_start_date,this.initial_end_date)
          this.loading = false;
          Swal.fire('Listo', 'Las citaciones fueron enviadas correctamente', 'success')
        })
        .catch(err => {
          console.log("Ocurrió un error", err.code, err.message, err.details);
          this.fechaCitacion = '';
          this.onCargar(this.initial_start_date,this.initial_end_date)
          this.loading = false;
          Swal.fire('Error', 'Ocurio un erro en el envio de las citaciones', 'error')

        });

      // this.aspirantesSeleccionados.forEach(element => {
      //   const aspirante = {
      //     nombre: `${element.nombre} ${element.apellido}`,
      //     telefono: element.telefono,
      //     email: element.email,
      //     fechaCitacion: this.fechaCitacion,
      //     id: element.id,
      //     vacante: element.especialidad ? element.especialidad : "empleada domestica"
      //   }
      //   const requestMensajeAspirante = firebase.functions().httpsCallable("requestMensajeAspirante");
      //   requestMensajeAspirante(aspirante).then(() => {
      //     console.log("a")
      //   }).catch(err => {
      //     console.log("ocurrio un error", err.code, err.message, err.details)
      //   })
      // });
    }
  }
}
