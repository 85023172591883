import { ServicioService } from "../../services/servicio.service";
import { Router } from "@angular/router";
import { ServerService } from "src/app/services/server.service";
import { LocalDataSource } from "ng2-smart-table";
import { AuthService } from "src/app/services/auth.service";
import * as _ from "lodash";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import Swal from "sweetalert2";
/* Servicios */
import * as moment from "moment";
import { Novedades } from "src/app/utility/novedades";
import { Metricas } from "src/app/services/metricas.service";
import { PlanService } from "src/app/services/plan.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { posventaService } from "src/app/services/posventa.service";
import { UsuarioAdminService } from 'src/app/services/usuarios-admin.service'

import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
@Component({
  selector: "app-postventa",
  templateUrl: "./postventa.component.html",
  styleUrls: ["./postventa.component.scss"],
})
export class PostventaComponent implements OnInit {
  @ViewChild("mdlHacerPosventa", { static: true }) mdlHacerPosventa: ElementRef;
  @ViewChild("mdlVerPostventa", { static: true }) mdlVerPostventa: ElementRef;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  fechaHoy = Date.now();

  start_date =  moment().subtract(8, 'days').format('YYYY-MM-DD');
  end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');

  public telefonoUsuarioTabla: number;
  public responsable: any;

  dataPendientes = [];
  dataSatasfactoria = [];
  dataFallidas = [];
  dataNoContesta = [];

  sourcePendeintes: any = [];
  sourcePlanPendeinte: any = [];
  sourceSatasfactoria: any = [];
  sourceFallidas: any = [];
  sourceNoContesta: any = [];

  filtroResponsable = ''
  permisoTotal = false;

  dateRange = {
    beginDate: moment().subtract(8, 'days').format('YYYY-MM-DD'),
    endDate:moment().subtract(1, 'days').format('YYYY-MM-DD') 
  };

  public puntuacionActitud: string = "0";
  public puntuacionCalidad: string = "0";
  public puntuacionUniforme: string = "0";
  public puntuacionPuntualidad: string = "0";
  public puntuacionImplementos: string = "0";
  public itemsMejora = [{ name: 'Lavado baños' }, { name: 'Reciclaje' }, { name: 'Limpieza cocina' }, { name: 'Preparación alimentos' }, { name: 'Oranización espacios' }]
  public razonesMejoraServicio = [];
  public calificacionPosventa: number = 0;
  public comentarioPosventa: string = "";
  public comentarioPrivadoPosventa: string = "";
  postventa = {
    calidad: '0',
    puntualidad: '0',
    actitud: '0',
  }
  public estadoPostventa: string = "";
  public productosCliente: string = "";

  public novedadesServicioEnPostventa: any = [];
  public arrayCategoriaNovedad: any = Novedades;
  public arrayTiposNovedad: any = [];
  public categoriaNovedad: string = "";
  public tipoNovedad: string = "";
  public areaNovedad: string = "";
  public intencionNovedad: string = "";
  public descripcionNovedad: string;
  public costoNovedad: number = 0;
  public fechaPospuesta: any;
  public asesores: any = [];
  public dataAllPostventas: any;

  public puntuacionAreaComercial: any;
  public puntuacionAreaLogistica: any;
  public puntuacionAreaApp: any;
  tablaVisualizada = '1';
  public servicioEnPostventa;
  cafilicacionPrevia: any;
  constructor(
    private router: Router,
    private metricasService: Metricas,
    private authService: AuthService,
    private planService: PlanService,
    private serverService: ServerService,
    private UsuarioAdminService: UsuarioAdminService,
    private usuarioService: UsuarioService,
    private servicioService: ServicioService,
    private posventaService: posventaService,
    private nbDialogService: NbDialogService,
  ) {
    this.responsable = this.authService.storeGetUserData("usuario");
    console.log(this.responsable)
    if (this.responsable.rol == "administrador") {
      this.permisoTotal = true
    } else {
      this.filtroResponsable = this.responsable.displayName
    }
  }

  ngOnInit() {
    console.log(this.start_date,this.end_date);
    this.getPostventasPendientes();

    // this.getPosventasPlanes(this.fechaInicio, this.fechaFin);
    // let date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    // let date_i =  moment().subtract(8, 'days').format('YYYY-MM-DD')
    // this.getPosventaIndividualPendiente(date_i, date);
    // this.getPosventaSatisfactorioa(this.fechaInicio, this.fechaFin);
    // // this.getPosventaPospuesta(this.fechaInicio, this.fechaFin);
    // this.getPosventaFallida(this.fechaInicio, this.fechaFin);
    // this.getAllPostventas(this.fechaInicio, this.fechaFin);
  }

  onRangeDatePostventas(event: IMyDateRangeModel){
    this.dateRange.endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    this.dateRange.beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    console.log(this.dateRange)
    this.getPostventasPendientes();
  }

  async getPostventasPendientes(){
    this.loading = true;
    this.dataPendientes = [];
    this.dataFallidas = [];
    this.dataNoContesta = [];
    this.dataSatasfactoria = [];

    const query = await this.servicioService.getServiciosIndividualesPostventa(this.dateRange.beginDate,this.dateRange.endDate, this.responsable.displayName);
    const promises = query.docs.map(async (res) => {
      const service = res.data();
      service.telefono = service.client === undefined ? "Sin cliente" : service.client.telefono;
      service.client = service.client === undefined ? { name: 'sin', apellido: 'cliente' } : service.client;
      service.server = service.server === undefined ? { name: 'sin', apellido: 'cliente' } : service.server;
      service.ciudad = service.destination.cities ? service.destination.cities : service.direccion;
      if(service.estadoPostventa == 'postventa fallida'){
        this.dataFallidas.push(service);
      }else if(service.estadoPostventa == 'postventa terminada'){
        this.dataSatasfactoria.push(service);
      }else if(service.estadoPostventa == 'no contesta'){
        this.dataNoContesta.push(service);
      }else {
        const result = await this.validateAftermarket(service.userid, service.idServer);
        if (result) {
          this.dataPendientes.push(service);
        }
      }
    });
    await Promise.all(promises);
    console.log(this.dataPendientes)
    this.sourcePendeintes = this.dataPendientes;
    this.sourceSatasfactoria = this.dataSatasfactoria;
    this.sourceFallidas = this.dataFallidas;
    this.sourceNoContesta = this.dataNoContesta;
    this.tableDataSource = new MatTableDataSource(this.sourcePendeintes);
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    this.loading = false;
  }


  validateAftermarket(idUser,idServer){
    return new Promise(async resolve=>{
      const query = await this.posventaService.getCalificacionServerUser(idUser,idServer);
      if(query.size == 0) resolve(true);
      else resolve(false);
    })
  }
  // getPosventasPlanes(fchInicio, fchFin) {
  //   this.dataPlanPendeinte = [];
  //   let count = 0;
  //   let consulta: any;
  //   console.log("Entro en getPlanes posventa", this.permisoTotal);
  //   if (this.permisoTotal) {
  //     consulta = this.planService.getPlanesPosventa()
  //   } else {
  //     consulta = this.planService.getPlanesConPosventaAsignada(this.filtroResponsable)
  //   }
  //   consulta.then((res) => {
  //     // console.log(res.docs);
  //     res.docs.forEach((doc) => {
  //       let plan = doc.data();
  //       plan.telefono = plan.client.telefono
  //       this.dataPlanPendeinte.push(plan);
  //       count++;
  //       if (count == res.docs.length) {
  //         this.sourcePlanPendeinte = this.dataPlanPendeinte;
  //       }
  //     });
  //   });
  // }

  // getPosventaIndividualPendiente(fchInicio, fchFin) {
  //   console.log('entro en getPosventaIndividualPendiente',fchInicio, fchFin)
  //   this.dataIndividualPendeinte = [];
  //   let count = 0;
  //   this.posventasIndividualesPendientes = [];
  //   let consulta: any;
    // const query = this.permisoTotal ? this.servicioService.getServiciosIndividualesTerminadosRangoDeFecha(fchInicio, fchFin) : this.servicioService.getServiciosParaPostventa(fchInicio, fchFin, this.responsable.displayName);
  //   query.then(async (res) => {
  //     console.log('Servicios en postventa',res.size)
  //       this.dataIndividualPendeinte = await this.getDataServices(res.docs)
  //       this.sourceIndividualPendeinte = this.dataIndividualPendeinte;
  //       console.log(this.sourceIndividualPendeinte);
  //       this.tableDataSource = new MatTableDataSource(this.sourceIndividualPendeinte);
  //       this.tableDataSource.paginator = this.paginator;
  //       this.tableDataSource.sort = this.sort;
  //       this.loadTime();
  //   });
  // }

  getDataServices(array): any {
    return new Promise(resolve => {
      let services = []
      for (let i = 0; i < array.length; i++) {
        let service = array[i].data();
        // console.log(service.asesor,this.responsable.displayName)
        // if (service.calificacion == undefined) {
          service.telefono = service.client === undefined ? "Sin cliente" : service.client.telefono;
          service.client = service.client === undefined ? { name: 'sin', apellido: 'cliente' } : service.client
          service.server = service.server === undefined ? { name: 'sin', apellido: 'cliente' } : service.server
          service.ciudad = service.destination.cities ? service.destination.cities : service.direccion
          services.push(service)
        // }
      }
      return resolve(services)
    })
  }

  // public rangoFechaPosventaIndividualPendiente(event: IMyDateRangeModel) {
  //   const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
  //   const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
  //   this.getPosventaIndividualPendiente(beginDate, endDate);
  // }

  // public filtroAsesor(event) {
  //   let asesor = event.target.value;
  //   if (asesor === 'Global') {
  //     this.sourceIndividualPendeinte = this.dataIndividualPendeinte;
  //     this.sourcePlanPendeinte = this.dataPlanPendeinte;
  //     this.sourceSatasfactoria = this.dataSatasfactoria;
  //     this.sourcePospuesta = this.dataPospuesta;
  //     this.sourceFallidas = this.dataFallidas;
  //   } else {
  //     this.sourceIndividualPendeinte = this.dataIndividualPendeinte.filter(postventa => postventa.responsablePostventa == asesor);
  //     this.sourcePlanPendeinte = this.dataPlanPendeinte.filter(postventa => postventa.responsablePostventa == asesor);
  //     this.sourceSatasfactoria = this.dataSatasfactoria.filter(postventa => postventa.responsablePostventa == asesor);
  //     this.sourcePospuesta = this.dataPospuesta.filter(postventa => postventa.responsablePostventa == asesor);
  //     this.sourceFallidas = this.dataFallidas.filter(postventa => postventa.responsablePostventa == asesor);
  //   }
  // }

  rangoFechaAllPosventas(event: IMyDateRangeModel) {
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    this.getAllPostventas(beginDate, endDate);
  }

  getAllPostventas(fchInicio, fchFin) {
    this.dataAllPostventas = []
    this.posventaService.getServiciosPosventas(fchInicio, fchFin).then(res => {
      res.docs.forEach(data => {
        this.dataAllPostventas.push(data.data())
        // console.log(this.dataAllPostventas.length,res.docs.length)
        if (this.dataAllPostventas.length === res.docs.length) {
          if (this.permisoTotal) {
            this.metricasAsesores();
          } else {
            this.metricasUnicoAsesor();
          }
        }
      })
    })
  }

  metricasAsesores() {
    this.asesores = [
      {
        nombre: 'Maria Camila Cadavid Alzate',
        pendientes: 0,
        satisfacorias: 0,
        fallidas: 0,
        pospuestas: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Alexandra Gamboa',
        pendientes: 0,
        satisfacorias: 0,
        fallidas: 0,
        pospuestas: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Meztly Castillo',
        pendientes: 0,
        satisfacorias: 0,
        fallidas: 0,
        pospuestas: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Maria Alejandra Arteaga Hernandez',
        Interesados: 0,
        Cotizar: 0,
        Visita: 0,
        Prueba: 0,
        Plan: 0,
        Perdido: 0,
        ServicioUnico: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Sin asignar',
        pendientes: 0,
        satisfacorias: 0,
        fallidas: 0,
        pospuestas: 0,
        totalAsignados: 0,
      },
    ]
    let a = 0;
    this.dataAllPostventas.forEach(element => {
      if (element.responsablePostventa == 'Maria Camila Cadavid Alzate') {
        a = 0
      } else if (element.responsablePostventa == 'Alexandra Gamboa') {
        a = 1;
      } else if (element.responsablePostventa == 'Meztly Castillo') {
        a = 2;
      } else {
        a = 3;
      }
      if (element.estadoPostventa == 'postventa pendiente') {
        this.asesores[a].pendientes++
      } else if (element.estadoPostventa == 'postventa terminada') {
        this.asesores[a].satisfacorias++
      } else if (element.estadoPostventa == 'postventa postpuesta') {
        this.asesores[a].pospuestas++
      } else if (element.estadoPostventa == 'postventa fallidaa') {
        this.asesores[a].fallidas++
      }
      this.asesores[a].totalAsignados++
    });
  }

  metricasUnicoAsesor() {
    this.asesores = [
      {
        nombre: this.filtroResponsable,
        pendientes: 0,
        satisfacorias: 0,
        fallidas: 0,
        pospuestas: 0,
        totalAsignados: 0,
      }
    ];
    let a = 0;
    this.dataAllPostventas.forEach(element => {
      // console.log(element.responsablePostventa)
      if (element.responsablePostventa == this.filtroResponsable) {
        if (element.estadoPostventa == 'postventa pendiente') {
          this.asesores[a].pendientes++
        } else if (element.estadoPostventa == 'postventa terminada') {
          this.asesores[a].satisfacorias++
        } else if (element.estadoPostventa == 'postventa postpuesta') {
          this.asesores[a].pospuestas++
        } else if (element.estadoPostventa == 'postventa fallidaa') {
          this.asesores[a].fallidas++
        }
        this.asesores[a].totalAsignados++
      }
    });
  }

  cambiarfechasposventa() {
    this.servicioService.updatePlanPosventa(
      this.servicioEnPostventa.id,
      this.fechaPospuesta
    );
  }

  public crearNovedad(descripcion, costo) {
    let novedad = {
      id: new Date().getTime(),
      categoria: this.categoriaNovedad,
      tipo: this.tipoNovedad,
      area: this.areaNovedad,
      descripcion: descripcion,
      intencion: this.intencionNovedad,
      emisor: this.responsable.displayName,
      estado: "Pendiente",
      estadoGeneral: 'vigente',
      agenteDesignado: "Sin agente",
      costo: parseInt(costo),
      cliente:
        this.servicioEnPostventa.client.name +
        " " +
        this.servicioEnPostventa.client.apellido,
      clienteId: this.servicioEnPostventa.client.id,
      server:
        this.servicioEnPostventa.server.name +
        " " +
        this.servicioEnPostventa.server.apellido,
      serverId: this.servicioEnPostventa.server.id,
      servicioAsociado: this.servicioEnPostventa.id,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaEnProceso: "null",
      fechaSolucionada: "null",
      fechaDelServicio: this.servicioEnPostventa.fecha,
      ciudad: this.servicioEnPostventa.destination.cities,
      department: this.servicioEnPostventa.destination.department,
      urlArchivo: "",

    };
    if (
      this.tipoNovedad == "Cancelación de servicio" ||
      this.tipoNovedad == "Uniforme" ||
      this.tipoNovedad == "Calidad" ||
      this.tipoNovedad == "Sin productos" ||
      this.tipoNovedad == "Calidad" ||
      this.tipoNovedad == "Daños"
    ) {
      this.serverService.detail(this.servicioEnPostventa.server.id).then(data => {
        const server = data.data();
        console.log(server);
        if (server.strikes === undefined) {
          server.strikes = [];
        }
        server.strikes.push({
          fecha: moment().format("YYYY-MM-DD"),
          razon: this.tipoNovedad,
          revisada: false,
        });
        this.serverService.updateServ(server);
      });
    } else if (
      this.tipoNovedad == "Puntualidad" ||
      this.tipoNovedad == "Accidentes"
    ) {
      this.serverService.detail(this.servicioEnPostventa.server.id).then(data => {
        const server = data.data()
        console.log(server);
        if (server.numFallas === undefined) {
          server.numFallas = 0;
        }
        server.numFallas++;
        if (server.numFallas === 3) {
          if (server.strikes === undefined) {
            server.strikes = [];
          }
          server.strikes.push({
            fecha: moment().format("YYYY-MM-DD"),
            razon: this.tipoNovedad,
            revisada: false,
          });
        }
        this.serverService.updateServ(server);
      });
    }
    this.metricasService.crearNovedad(novedad).then((res) => {
      Swal.fire("Creado", "La novedad fue creada exitosamente", "success");
      this.servicioService
        .servicioConNovedad(novedad.servicioAsociado)
        .then((res) => {
          console.log("se actualizo el servicio");
        });
      this.categoriaNovedad = "";
      this.tipoNovedad = "";
      this.descripcionNovedad = "";
      this.costoNovedad = 0;
    });
  }

  postVentaVisualizada: any;
  getPostventa(servicio) {
    this.posventaService.getPosventaIdServer(servicio.idServer).then((res) => {
      res.docs.forEach((data) => {
        const postventa = data
          .data()
          .postventasCreadas.filter((info) => info.idServicio == servicio.id);
        this.postVentaVisualizada = postventa[0];
        // @ts-ignore
        this.nbDialogService.open(this.mdlVerPostventa);
      });
    });
  }

  exportExcel(tipo) {
    let array: any;
    let name = "";
    let expo = [];
    if (tipo == "realizadas") {
      array = this.dataSatasfactoria;
      name = "posventas_reaizadas";
    } else if (tipo == "pospuestas") {
      array = this.dataNoContesta;
      name = "posventas_pospuesatas";
    } else if (tipo == "posventa") {
      array = this.dataPendientes;
      name = "posventas_pendientes";
    } else if (tipo == "fallidas") {
      array = this.dataFallidas;
      name = "posventas_fallidas";
    }

    array.forEach((element) => {
      expo.push({
        idCliente: element.client.id,
        clienteName: element.client.name + " " + element.client.apellido,
        email: element.client.email,
        telefono: element.client.telefono,
      });
    });
    this.usuarioService.exportAsExcelFile(expo, name);
  }


  /* ------------ New tabs whit angularMaterial -------------*/

  loading = true;
  tableDisplayedColumns = ['fecha', 'usuario', 'telefono', 'server', 'servicio', 'ciudad']
  tableDataSource: MatTableDataSource<any>;
  expandedElement: any | null;


  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  changeTable(option) {
    this.tablaVisualizada = option;
    switch (option) {
      case '1':
        this.tableDataSource = new MatTableDataSource(this.sourcePendeintes);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        console
        break;
      case '2':
        this.tableDataSource = new MatTableDataSource(this.sourceNoContesta);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        console
        break;
      case '3':
        this.tableDataSource = new MatTableDataSource(this.sourceSatasfactoria);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        console
        break;
      case '4':
        this.tableDataSource = new MatTableDataSource(this.sourceFallidas);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        console
        break;
      default:
        console.log('opcion Invalida');
    }
  }


  async desplegarPostventa(info) {
    console.log(info)
    this.servicioEnPostventa = info;
    this.calificacionPosventa = info.calificacion ? info.calificacion : 0;
    if(this.calificacionPosventa > 0){
      const query = await this.posventaService.getCalificacionServicio(info.id);
      this.cafilicacionPrevia = query.docs[0].data();
      this.postventa.actitud = this.cafilicacionPrevia.actitud;
      this.postventa.calidad = this.cafilicacionPrevia.calidad;
      this.postventa.puntualidad = this.cafilicacionPrevia.puntualidad;
    }else{
      this.postventa.actitud = '0';
      this.postventa.calidad = '0';
      this.postventa.puntualidad = '0';
    }
    this.comentarioPosventa = info.comentarioPostventa ? info.comentarioPostventa : '';
    this.expandedElement = this.expandedElement === info ? null : info;
  }


  calcularCalificacionGeneral(){
    console.log(this.postventa)
    this.calificacionPosventa = Math.round((parseInt(this.postventa.calidad) + parseInt(this.postventa.puntualidad) + parseInt(this.postventa.actitud)) / 3)
  }

  async guargarCalificacion(servicio) {

    if(servicio.estadoPostventa !== undefined && this.calificacionPosventa > 0){
      // eslint-disable-next-line prefer-const 
      this.loading = true;
      let calificacion = {
        asesor: this.responsable.displayName,
        idServicio: servicio.id,
        idServer: servicio.idServer,
        calificacion: this.calificacionPosventa,
        fecha: moment().format('YYYY-MM-DD'),
        fechaServicio: servicio.fecha,
        comentario: this.comentarioPosventa,
        comentarioPrivado: this.comentarioPrivadoPosventa,
        cliente: `${servicio.client.name} ${servicio.client.apellido}`,
        idUser: servicio.client.id,
        estado: servicio.estadoPostventa,
        calidad: this.postventa.calidad,
        puntualidad: this.postventa.puntualidad,
        actitud: this.postventa.actitud,
      };
  
      this.posventaService.postCalificacion(calificacion).then(() => {
        servicio.calificacion = this.calificacionPosventa;
        this.servicioService.updateServicio(servicio).then(async () => {
          console.log("Calificacion guardada");
          this.getPostventasPendientes();
          Swal.fire(
            "Listo",
            "Se guardo la calificacion con exito",
            "success"
          );
        })
      }).catch(err => {
        console.log("Ocurrio un error al guardar la calificacion", err);
      });
    }else{
      Swal.fire(
        "Espera",
        "Selecciona el estado de la postventa",
        "warning"
      );
    }
  }

  actualizarCalificacion(servicio){
    if(servicio.estadoPostventa !== "no contesta"){

      let calificacion = {
        id: this.cafilicacionPrevia.id,
        asesor: this.responsable.displayName,
        idServicio: servicio.id,
        idServer: servicio.idServer,
        calificacion: this.calificacionPosventa,
        fecha: moment().format('YYYY-MM-DD'),
        comentario: this.comentarioPosventa,
        cliente: `${servicio.client.name} ${servicio.client.apellido}`,
        idUser: servicio.client.id,
        estado: servicio.estadoPostventa,
        calidad: this.postventa.calidad,
        puntualidad: this.postventa.puntualidad,
        actitud: this.postventa.actitud,
      }
      this.posventaService.setCalificacion(calificacion).then(res=>{

        this.servicioEnPostventa.postventa = servicio.estadoPostventa;
        this.servicioService.updateServicio(this.servicioEnPostventa).then(async () => {
          console.log("Calificacion guardada");
          this.getPostventasPendientes();
          Swal.fire(
            "Subida",
            "Se guardo la postventa con exito",
            "success"
          );
        })
      })
    }else{
      Swal.fire(
        "Espera",
        "Selecciona el nuevo estado de la postventa",
        "warning"
      );
    }
  }


  ajustarCalificaciones() {
    this.sourceSatasfactoria.forEach(element => {
      if (!Number.isNaN(Math.trunc(element.calificacionPromedio))) {
        element.calificacion = Math.trunc(element.calificacionPromedio)
        console.log('b')
        this.servicioService.updateServicio(element).then(() => {
          console.log('a')
        })
      }
    });
  }

}