import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  public getNotificacionesUsuarios(idUsuario){
    return firebase.firestore().collection('notificacionesAdmin/')
    .where('idUsuario','==',idUsuario)
  }
  public getNotificacionesByType(type){
    return firebase.firestore().collection('notificacionesAdmin/')
    .where('type','==',type)
  } 

  public getNotificacionesByTitle(title){
    return firebase.firestore().collection('notificacionesAdmin/')
    .where('title','==', title).get()
  }

  deleteNotificaciones(noti){
    let idNoti = noti.toString();
    return firebase.firestore().collection('notificacionesAdmin').doc(idNoti).delete().catch(err=>{console.log(err)})
  }

  add(data){
    return firebase.firestore().collection('notificacionesAdmin').add(data).then(res=>{
      res.update({id: res.id});
    })
  }
}
