import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./public/login/login.component";
import { DashboardComponent } from "./public/dashboard/dashboard.component";
import { HomeComponent } from "./public/home/home.component";
import { UsuariosComponent } from "./public/usuarios/usuarios.component";
import { ServersComponent } from "./public/servers/servers.component";
import { TransaccionesComponent } from "./public/transacciones/transacciones.component";
import { ServiciosComponent } from "./public/servicios/servicios.component";
import { VerUsurarioComponent } from "./public/ver-usurario/ver-usurario.component";
import { VerServerComponent } from "./public/ver-server/ver-server.component";
import { VerTransaccionComponent } from "./public/ver-transaccion/ver-transaccion.component";

import { CalendarioServiciosComponent } from "./public/calendario-servicios/calendario-servicios.component";
import { CuponesComponent } from "./public/cupones/cupones.component";
import { ListarPlanComponent } from "./public/listar-plan/listar-plan.component";
import { CandidatosComponent } from "./public/candidatos/candidatos.component";
import { VerCandidatoComponent } from "./public/candidatos/ver-candidato/ver-candidato.component";
import { DisponibilidadComponent } from "./public/disponibilidad/disponibilidad.component";
import { NuevaDisponibilidadComponent } from "./public/disponibilidad/nuevaDisponibilidad/nueva-disponibilidad.component";

import { VerPlanComponent } from "./public/listar-plan/ver-plan/ver-plan.component";
import { AuthGuard } from "./guards/auth.guard";
import { PostventaComponent } from "./public/postventa/postventa.component";
import { LeadsComponent } from "./public/leads/leads.component";
import { ClientsComponent } from "./public/client/clients.component";
import { LocationComponent } from "./public/location/location.component";
import { VerPostventaComponent } from "./public/ver-postventa/ver-postventa.component";
import { VerLeadComponent } from "./public/ver-lead/ver-lead.component";
import { CrearCandidatoComponent } from "./public/candidatos/crear-candidato/crear-candidato.component";
import { VerCandidatoEntrevistaComponent } from "./public/candidatos/ver-candidato-entrevista/ver-candidato-entrevista.component";
import { VerCandidatoVerificacionComponent } from "./public/candidatos/ver-candidato-verificacion/ver-candidato-verificacion.component";
import { PagosServerComponent } from "./public/pagos-server/pagos-server.component";
import { VerPagoServerComponent } from "./public/pagos-server/ver-pago-server/ver-pago-server.component";
import { VerPagadosComponent } from "./public/pagos-server/ver-pagados/ver-pagados.component";
import { MetricasComponent } from "./public/metricas/metricas.component";
import { FacturacionCarteraComponent } from "./public/facturacion-cartera/facturacion-cartera.component";
import { NovedadesComponent } from "./public/novedades/novedades.component";
import { ObservadorComponent } from "./public/observador/observador.component";

import { NuevoServiciosComponent } from "./public/servicios/nuevoServicios.component";
import { PatchNotesComponent } from "./public/patch-notes/patch-notes.component";
import { ModalServerComponent } from "./public/modals/modal-server/modal-server.component";
import { ChatComponent } from "./public/chat/chat.component";
import { GestionarPreciosComponent } from "./public/gestionar-precios/gestionar-precios.component";
import { ClientesMarketingComponent } from "./public/clientes-marketing/clientes-marketing.component";
import { ExportarMetricasComponent } from "./public/exportar-metricas/exportar-metricas.component";
import { CandidatesComponent } from './public/candidates/candidates.component'
import { LeadsCaComponent } from "./public/leads-ca/leads-ca.component";
import { CandidatesServComponent } from "./public/candidates-serv/candidates-serv.component";

const routes: Routes = [
	{
		path: "",
		component: LoginComponent,
	},
	{
		path: "login",
		component: LoginComponent,
	},
	{
		path: "dashboard",
		canActivate: [AuthGuard],
		component: DashboardComponent,
		children: [
			{
				path: "nuevoServicios",
				canActivate: [AuthGuard],
				component: NuevoServiciosComponent,
			},
			{
				path: "exportar-metricas",
				canActivate: [AuthGuard],
				component: ExportarMetricasComponent,
			},
			{
				path: "home",
				canActivate: [AuthGuard],
				component: HomeComponent,
			},
			{
				path: "metricas",
				canActivate: [AuthGuard],
				component: MetricasComponent,
			},
			{
				path: "novedades",
				canActivate: [AuthGuard],
				component: NovedadesComponent,
			},

			{
				path: "usuarios",
				canActivate: [AuthGuard],
				component: UsuariosComponent,
			},
			{
				path: "clientes",
				canActivate: [AuthGuard],
				component: ClientsComponent,
			},
			{
				path: "clientesMarketing",
				canActivate: [AuthGuard],
				component: ClientesMarketingComponent,
			},

			{
				path: "ver-usuario/:idUsuario",
				canActivate: [AuthGuard],
				component: VerUsurarioComponent,
			},
			{
				path: "ver-server/:idServer",
				canActivate: [AuthGuard],
				component: VerServerComponent,
			},
			{
				path: "ver-pago-server/:id",
				canActivate: [AuthGuard],
				component: VerPagoServerComponent,
			},
			{
				path: "ver-pagados/:id",
				canActivate: [AuthGuard],
				component: VerPagadosComponent,
			},
			{
				path: "servers",
				canActivate: [AuthGuard],
				component: ServersComponent,
			},
			{
				path: "pago-servers",
				canActivate: [AuthGuard],
				component: PagosServerComponent,
			},

			{
				path: "servicios",
				canActivate: [AuthGuard],
				component: ServiciosComponent,
			},
			{
				path: "leads",
				canActivate: [AuthGuard],
				component: LeadsComponent,
			},
			{
				path: "leads_ca",
				canActivate: [AuthGuard],
				component: LeadsCaComponent,
			},
			{
				path: "cupones",
				canActivate: [AuthGuard],
				component: CuponesComponent,
			},
			{
				path: "ver-postventa/:idServicio",
				canActivate: [AuthGuard],
				component: VerPostventaComponent,
			},
			{
				path: "lista-plan",
				canActivate: [AuthGuard],
				component: ListarPlanComponent,
			},
			{
				path: "ver-plan/:id",
				canActivate: [AuthGuard],
				component: VerPlanComponent,
			},
			{
				path: "calendario-servicios",
				canActivate: [AuthGuard],
				component: CalendarioServiciosComponent,
			},
			{
				path: "transacciones",
				canActivate: [AuthGuard],
				component: TransaccionesComponent,
			},
			{
				path: "ver-transaccion/:idTransaccion",
				canActivate: [AuthGuard],
				component: VerTransaccionComponent,
			},
			{
				path: "candidatos",
				canActivate: [AuthGuard],
				component: CandidatosComponent,
			},
			{
				path: "ver-candidato/:id",
				canActivate: [AuthGuard],
				component: VerCandidatoComponent,
			},
			{
				path: "ver-candidato-entrevista/:id",
				canActivate: [AuthGuard],
				component: VerCandidatoEntrevistaComponent,
			},
			{
				path: "ver-candidato-verificacion/:id",
				canActivate: [AuthGuard],
				component: VerCandidatoVerificacionComponent,
			},
			{
				path: "ver-candidato-induccion/:id",
				canActivate: [AuthGuard],
				component: CrearCandidatoComponent,
			},
			{
				path: "ver-candidato-activacion/:id",
				canActivate: [AuthGuard],
				component: CrearCandidatoComponent,
			},
			{
				path: "ver-candidato-visita/:id",
				canActivate: [AuthGuard],
				component: CrearCandidatoComponent,
			},
			{
				path: "crear-candidato/:id",
				canActivate: [AuthGuard],
				component: CrearCandidatoComponent,
			},
			{
				path: "ver-lead/:id",
				canActivate: [AuthGuard],
				component: VerLeadComponent,
			},
			{
				path: "postventa",
				canActivate: [AuthGuard],
				component: PostventaComponent,
			},
			{
				path: "disponibiladad",
				canActivate: [AuthGuard],
				component: DisponibilidadComponent,
			},
			{
				path: "nuevaDisponibilidad",
				canActivate: [AuthGuard],
				component: NuevaDisponibilidadComponent,
			},
			{
				path: "location",
				canActivate: [AuthGuard],
				component: LocationComponent,
			},
			{
				path: "facturacion-cartera",
				canActivate: [AuthGuard],
				component: FacturacionCarteraComponent,
			},
			{
				path: "observadorServers",
				canActivate: [AuthGuard],
				component: ObservadorComponent,
			},
			{
				path: "patch-notes",
				canActivate: [AuthGuard],
				component: PatchNotesComponent,
			},
			{
				path: "chat",
				canActivate: [AuthGuard],
				component: ChatComponent,
			},
			{
				path: "modal-server",
				canActivate: [AuthGuard],
				component: ModalServerComponent,
			},
			{
				path: "gestionarPrecios",
				canActivate: [AuthGuard],
				component: GestionarPreciosComponent,
			},
			{
				path: "candidates",
				canActivate: [AuthGuard],
				component: CandidatesComponent,
			},
			{
				path: "candidates_serv",
				canActivate: [AuthGuard],
				component: CandidatesServComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
