import * as firebase from 'firebase';
import { NbDialogService } from "@nebular/theme";
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LocalDataSource } from "ng2-smart-table";
//Services 
import { PlanService} from "../../services/plan.service"
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";
import Swal from 'sweetalert2';




@Component({
  selector: 'app-clientes-marketing',
  templateUrl: './clientes-marketing.component.html',
  styleUrls: ['./clientes-marketing.component.scss']
})
export class ClientesMarketingComponent implements OnInit {

  @ViewChild("mdlMarketing", { static: true }) mdlMarketing: ElementRef;

  generalLoader = true;
  arrayPlanes = [];
  allClients = [];
  clientsForMarketing = [];

  clientsWithoutServices: any = []; 
  clientsWithMonthlyPlan: any = [];
  clientsWithOnlyIndividualServices: any = [];

  clientsWithoutServices_copy = []; 
  clientsWithMonthlyPlan_copy = [];
  clientsWithOnlyIndividualServices_copy = [];

  departmentFilter = 'all';
  typeFilter = 'all'
  settingsUserTable = {
    selectMode: "multi",
    actions: {    
      add: false,
      edit: false,
      delete: false,
      filter: false,
    },
    columns: {
      fullName:     { title: 'Nobre'     },
      phoneNumber:  { title: 'Telefono'  },
      email:        { title: 'Email'     },
      department:   { title: 'Ubicasion' },
      type:         { title: 'Tipo'      },
    },
  };

  tipoDeMensaje = '';
  textForSms = '';
  textForEmail: any = {
    ciudad: ''
  }
  constructor(
    private planService: PlanService,
    private dialogService: NbDialogService,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit() {
    this.getPlanes();
    // this.getClientes()
  }
  getPlanes(){
    this.planService.getAllPlan().then(res=>{
      this.arrayPlanes = res.docs.map(data=>{
        return {
          ...data.data()
        }
      })
      this.getClientes();
    })
  }

  async getClientes() {
    const res = await this.usuarioService.all();
    let clientsWithMonthlyPlan = [];
    let clientsWithoutServices = [];
    let clientsWithOnlyIndividualServices = [];
    const users = res.docs.map(data =>{
      const client = data.data();
      let info:any = {
        email: client.email,
        name: client.name,
        fullName: `${client.name} ${client.apellido}`,
        phoneNumber: `${client.telefono}`,
        phoneNumberWithIndicative: `57${client.telefono}`,
        department:
        typeof client.direccionesArray !== 'undefined' &&
        typeof client.direccionesArray[0] !== 'undefined' &&
        typeof client.direccionesArray[0].department !== 'undefined'
        ? client.direccionesArray[0].department
        : typeof client.department !== 'undefined'
          ? client.department
          : "Sin direccion",
        type:  typeof client.tipo !== 'undefined' ? client.tipo : 'Falta',
        whithServices: client.tieneServiciosTerminados, 
        whithMonthlyPlan: false,
        cancellation: '',
      }
      // console.log(info.department);
      const withPlan = this.arrayPlanes.filter(plan=>plan.userid == client.id)
      const canceledPlanes =  this.arrayPlanes.filter(plan=>plan.userid == client.id && plan.estado == 'Cancelado')
      if(withPlan.length > 0){
        info.totalPlanes = withPlan.length
        clientsWithMonthlyPlan.push(info)
        info.whithMonthlyPlan = true
        if(canceledPlanes.length > 0){
          for(let plan of canceledPlanes){
            info.cancellation = `${info.cancellation} / ${plan.motivoCancelacion} `
          }
        }
      }else if(client.tieneServiciosTerminados){
        clientsWithOnlyIndividualServices.push(info)
      }else{
        clientsWithoutServices.push(info)
      }
      return info
    });
    
    this.clientsWithoutServices = new LocalDataSource(clientsWithoutServices);
    this.clientsWithoutServices_copy = clientsWithoutServices;

    this.clientsWithMonthlyPlan = new LocalDataSource(clientsWithMonthlyPlan);
    this.clientsWithMonthlyPlan_copy = clientsWithMonthlyPlan;

    this.clientsWithOnlyIndividualServices = new LocalDataSource(clientsWithOnlyIndividualServices);
    this.clientsWithOnlyIndividualServices_copy = clientsWithOnlyIndividualServices;
     
    this.allClients = users;
    this.generalLoader = false;
    console.log(this.allClients);
  }

  actualizarClietes(){
    this.allClients.forEach(res=>{
      this.usuarioService.updateClient(res)
    })
  }

  filter(){
    if(this.departmentFilter == 'all' && this.typeFilter == 'all'){
      this.clientsWithoutServices = new LocalDataSource(this.clientsWithoutServices_copy)
      this.clientsWithMonthlyPlan = new LocalDataSource(this.clientsWithMonthlyPlan_copy)
      this.clientsWithOnlyIndividualServices = new LocalDataSource(this.clientsWithOnlyIndividualServices_copy )
    }else if(this.departmentFilter != 'all' &&  this.typeFilter == 'all'){
      if(this.departmentFilter == 'Cundinamarca'){
        this.textForEmail.ciudad = 'Bogota'
        let a = this.clientsWithoutServices_copy.filter(res=>res.department == this.departmentFilter || res.department == 'Bogotá D.C.')
        this.clientsWithoutServices = new LocalDataSource(a)

        let b = this.clientsWithMonthlyPlan_copy.filter(res=>res.department == this.departmentFilter || res.department == 'Bogotá D.C.')
        this.clientsWithMonthlyPlan = new LocalDataSource(b)

        let c = this.clientsWithOnlyIndividualServices_copy.filter(res=>res.department == this.departmentFilter || res.department == 'Bogotá D.C.')
        this.clientsWithOnlyIndividualServices = new LocalDataSource(c)
      }else{
        this.textForEmail.ciudad = 'Medellin'
        let a = this.clientsWithoutServices_copy.filter(res=>res.department == this.departmentFilter)
        this.clientsWithoutServices = new LocalDataSource(a)

        let b = this.clientsWithMonthlyPlan_copy.filter(res=>res.department == this.departmentFilter)
        this.clientsWithMonthlyPlan = new LocalDataSource(b)

        let c = this.clientsWithOnlyIndividualServices_copy.filter(res=>res.department == this.departmentFilter)
        this.clientsWithOnlyIndividualServices = new LocalDataSource(c)

      }

    }else if(this.departmentFilter == 'all' &&  this.typeFilter != 'all'){
      let a = this.clientsWithoutServices_copy.filter(res=>res.type == this.typeFilter)
      this.clientsWithoutServices = new LocalDataSource(a)
      let b = this.clientsWithMonthlyPlan_copy.filter(res=>res.type == this.typeFilter)
      this.clientsWithMonthlyPlan = new LocalDataSource(b)
      let c = this.clientsWithOnlyIndividualServices_copy.filter(res=>res.type == this.typeFilter)
      this.clientsWithOnlyIndividualServices = new LocalDataSource(c)
      
    }else if(this.departmentFilter != 'all' &&  this.typeFilter != 'all'){
      if(this.departmentFilter == 'Cundinamarca'){
        this.textForEmail.ciudad = 'Bogota'
        let a = this.clientsWithoutServices_copy.filter(res=>(res.department == this.departmentFilter || res.department == 'Bogotá D.C.') && res.type == this.typeFilter)
        this.clientsWithoutServices = new LocalDataSource(a)
        let b = this.clientsWithMonthlyPlan_copy.filter(res=>(res.department == this.departmentFilter || res.department == 'Bogotá D.C.') && res.type == this.typeFilter)
        this.clientsWithMonthlyPlan = new LocalDataSource(b)
        let c = this.clientsWithOnlyIndividualServices_copy.filter(res=>(res.department == this.departmentFilter || res.department == 'Bogotá D.C.') && res.type == this.typeFilter)
        this.clientsWithOnlyIndividualServices = new LocalDataSource(c)
      }else{
        let a = this.clientsWithoutServices_copy.filter(res=>res.department == this.departmentFilter && res.type == this.typeFilter)
        this.clientsWithoutServices = new LocalDataSource(a)

        let b = this.clientsWithMonthlyPlan_copy.filter(res=>res.department == this.departmentFilter && res.type == this.typeFilter)
        this.clientsWithMonthlyPlan = new LocalDataSource(b)

        let c = this.clientsWithOnlyIndividualServices_copy.filter(res=>res.department == this.departmentFilter && res.type == this.typeFilter)
        this.clientsWithOnlyIndividualServices = new LocalDataSource(c)

      }
    }else{
      console.log('Error')
    }
  }
  onUserRowSelect(event){
    this.clientsForMarketing = event.selected
  }

  changeTable(){
    this.clientsWithoutServices = new LocalDataSource(this.clientsWithoutServices_copy);
    this.clientsWithMonthlyPlan = new LocalDataSource(this.clientsWithMonthlyPlan_copy);
    this.clientsWithOnlyIndividualServices = new LocalDataSource(this.clientsWithOnlyIndividualServices_copy);
    this.clientsForMarketing  = [];
    this.filter();
  }

  export(type){
    switch (type){
      case '0':
        this.usuarioService.exportAsExcelFile( this.clientsWithMonthlyPlan.data,"Usuarios_sin_servicios");
        break;
      case '1':
        this.usuarioService.exportAsExcelFile( this.clientsWithOnlyIndividualServices.data,"Usuarios_sin_servicios");
        break;
      case '2':
        this.usuarioService.exportAsExcelFile( this.clientsWithoutServices.data,"Usuarios_sin_servicios");
        break;
      case '3':
        this.usuarioService.exportAsExcelFile( this.allClients,"Usuarios");
        break
    }
  }

  openModalMarketing(clientes,type){
    this.tipoDeMensaje = type;
    console.log(this.clientsForMarketing)
    if(this.clientsForMarketing.length == 0){
      switch (clientes){
        case '0':
          this.clientsForMarketing = this.clientsWithMonthlyPlan.data;
          break;
        case '1':
          this.clientsForMarketing = this.clientsWithOnlyIndividualServices.data;
          break;
        case '2':
          this.clientsForMarketing = this.clientsWithoutServices.data;
          break;
      }
    }
    this.textForSms = '';
    console.log(this.clientsForMarketing)
    // @ts-ignore
    this.dialogService.open(this.mdlMarketing, {
      context: "this is some additional data passed to dialog",
    });
  }

} 
 