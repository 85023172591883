import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ver-novedad',
  templateUrl: './ver-novedad.component.html',
  styleUrls: ['./ver-novedad.component.scss']
})
export class VerNovedadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
