import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getUrl(filePath){
    return firebase.storage().ref(filePath).getDownloadURL();
  }
  getData(filePath){
    return firebase.storage().ref(filePath).getMetadata();
  
  }
}
