import { ServicioService } from 'src/app/services/servicio.service';
import { ServerService } from 'src/app/services/server.service';
import { AuthService } from '../../services/auth.service';
import { TransaccionesService } from '../../services/transacciones.service'
import { Menu, Menu4_0} from 'src/app/module/menus/menu.module';
import { RRule, RRuleSet, Weekday } from 'rrule';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbSidebarService, NbDialogService } from '@nebular/theme';

import { PatchNotesServices } from '../../services/patchNotes.services';
import { NotificationsService } from '../../services/notifications.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject } from "rxjs";
import * as _ from "lodash";
import { Novedades } from "src/app/utility/novedades";
import {
  CalendarView,
  CalendarMonthViewBeforeRenderEvent,
} from "angular-calendar";
import { LeadsComponent } from '../leads/leads.component';
import { PlanService } from 'src/app/services/plan.service';
import Swal from 'sweetalert2';
import { DisponibilidadAppService } from 'src/app/services/disponibilidad-app.service';
import { Metricas } from 'src/app/services/metricas.service';
import { festivosColombia } from 'src/app/utility/festivosColombia';
import { PlanRecurrenteService } from 'src/app/services/plan-recurrente.service';
const colors: any = {
  plan: {
    primary: "var(--primary)",
    secondary: "var(--primary)",
  },
  individual: {
    primary: "var(--primary)",
    secondary: "var(--primary)",
  },
  bloqueado: { primary: "#c20303", secondary: "#973121"},
  secondary:{ primary: '#ff8900', secondary: '#FAE3E3' },
  blue_2:{ primary: '#00dbff', secondary: '#00dbff' }
};

let keyPress = "null";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {

  @ViewChild('mdlServers', { static: true }) mdlServers: ElementRef;
  @ViewChild('mdlAgendaServer', { static: true }) mdlAgendaServer: ElementRef;
  @ViewChild('mdlNewNovelty', { static: true }) mdlNewNovelty: ElementRef;
  @ViewChild('mdlAdministrativeService', { static: true }) mdlAdministrativeService: ElementRef;
  @ViewChild('mdlParche', { static: true }) mdlParche: ElementRef;
  @ViewChild('mdlNotificaciones', { static: true }) mdlNotificaciones: ElementRef;
  @ViewChild('mdlDisponibilidadApp', { static: true }) mdlDisponibilidadApp: ElementRef;

  view: CalendarView = CalendarView.Month;
  spinerGuardar = 0;
  public count = 0;
  public showNotifications = false;
  public infoNotification: any;
  notificaciones: any = [];
  public title: any = {};
  public body: any = {};
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  public usuarioActivo: any = {};
  logueado = false;
  items = null;
  serversMedellinDisponibles: any = [];
  serversBogotaDisponibles: any = [];
  serverList: any = [];
  servicios: any = [];
  tablaBogota = true;
  tablaMedellin = false;
  public fechaActual = moment().format("YYYY-MM-DD");

  activeDayIsOpen: boolean = true;
  listaCalendarioServicio = [];
  serviciosDiarios = [];
  settingsCalendario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      tipoServicio: { title: "Tipo de servicio" },
      client: {
        title: "Cliente",
        valuePrepareFunction: (client) => {
          return client != undefined ? `${client.name} ` : '';
        },
      },
      fecha: { title: "Fecha del servicio" },
      hora: { title: "hora",
        valuePrepareFunction: (value,row) => {
          return value != undefined ? `De ${value} a ${parseInt(value) + parseInt(row.horasDeServicio)}:00` : '';
        },
      },
      horasDeServicio: { title: "Duracion" },
      estado: { title: "Estado" },
    },
  };

  planesRecurrentes = [];
  settingsPlanesRecurrentes = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      clienteName: { title: "Cliente" },
      diasAgendado: { title: "Dias" },
      fechaDeInicio: { title: "Fecha inicio" },
      fechaDeVencimiento: { title: "Fecha vencimiento" },
      horaInicio: { title: "Hora" },
    },
  };

  selectedDays: number[] = [];
  fechaSeleccionada = [];
  fechaParaDesbloquear = [];
  arrayServersCiudad: any;
  arrayPatchNotes: any = [];
  viewpatch: any;
  isSidebarOpen: boolean = false;
  mdlViewUser: boolean;


  eventsCalendarDisponibilidadApp = [];
  generalLoader: boolean = false;

  public infoServer: any| null = null;
  public idServerSeleccionada = "falta";
  public serverAnterior = "falta";
  public infoServerSeleccionada: any = {tipoContrato: ''};

  birthdaysCalendarEvents = [];

  serversDirectas = [];
  serversSinServicio = 0;
  serversSinServicioTarde = 0;
  servers_directas_4_horas = [];
  servers_directas_6_horas = [];
  servers_sin_servicio = [];
  serversConServicioDia = [];
  constructor(
    public metricasService: Metricas,
    public router: Router,
    private authService: AuthService,
    private serverService: ServerService,
    private dialogService: NbDialogService,
    private sidebarService: NbSidebarService,
    private servicioService: ServicioService,
    public notificationsService: NotificationsService,
    private disponibilidadAppService: DisponibilidadAppService,
    private planRecurrenteService: PlanRecurrenteService
  ) {
    this.body = this.notificaciones.body;
    this.title = this.notificaciones.title;
    this.usuarioActivo = this.authService.storeGetUserData('usuario');
    if (this.usuarioActivo.rol == "retos4.0") {
      this.items = Menu4_0
    } else {
      this.items = Menu;
    }
    // else if (this.usuarioActivo.rol == "contabilidad") {
    //   this.items = MenuContabilidad;
    // } else if (this.usuarioActivo.rol == "ventas") {
    //   this.items = MenuComercial;
    // } else if (this.usuarioActivo.rol == "logistica") {
    //   this.items = MenuLogistica;
    // } else if (this.usuarioActivo.rol == "recursos") {
    //   this.items = MenuTalento;
    // } else {
    //   this.items = Menu;
    // }
  }

  ngOnInit() {
    this.getServers();

    // this.getPatchNotes();
    this.getNotificaciones();

    this.getDisponibilidadApp()
  }

  openModal(patch) {
    this.viewpatch = patch;
    // @ts-ignore
    this.dialogService.open(this.mdlParche);
  }


  toggle() {
    this.sidebarService.toggle(false, "menu-sidebar");
    return true
  }

  salir() {
    this.authService.logoutUser();
  }

  abrirModalServers() {
    // this.openDialogSave(this.mdlServers);
    this.openDialogSave(this.mdlAgendaServer);
  }
  onOpenModalNewNovelty() {
    // this.openDialogSave(this.mdlServers);
    this.openDialogSave(this.mdlNewNovelty);
  }
  
  onOpenModalAdministrativeService() {
    this.administrativeService = {
      startTime: "8:00",
      duration: '8'
    }
    // this.openDialogSave(this.mdlServers);
    this.openDialogSave(this.mdlAdministrativeService);
  }

  openModaHorarioServer() {
    this.openDialogSave(this.mdlServers);
  }

  openMdlApp() {
    this.openDialogSave(this.mdlDisponibilidadApp);
  }

  getDisponibilidadApp() {
    this.disponibilidadAppService.get().then(res => {
      res.docs.forEach(data => {
        this.eventsCalendarDisponibilidadApp.push(data.data());
      })
    })
  }

  getNotificaciones() {
    this.notificaciones = [];
    this.notificationsService.getNotificacionesByType(this.usuarioActivo.rol).onSnapshot(snapshot => {
      for (let a = 0; a < snapshot.docChanges().length; a++) {
        const res = snapshot.docChanges()[a]
        if (res.type == 'added') {
          let noti = res.doc.data();
          this.notificaciones.push(noti);
        }
      }
    });
  }

  deleteNotificaciones(noti) {
    this.notificationsService.deleteNotificaciones(noti.id);
  }

  abrirModalNotificaciones() {
    this.showNotifications = !this.showNotifications
  }
  public openDialogSave(modal) {
    this.dialogService.open(modal, {
      context: "nada",
    });
  }

  public fechaFiltro = moment().format("YYYY-MM-DD");
  public fch = this.fechaFiltro;
  public arrayDisponibilidadServes: any = [];
  public arrayDisponibilidadServesBogota: any = [];
  public arrayDisponibilidadServesMedellin: any = [];
  public arrayServers: any = [];

  async getServers() {
    let preSourse = [];
    this.serverService.Actives().then((res) => {
      res.docs.forEach((data, index) => {

        preSourse.push(data.data());
        this.arrayServers = _.orderBy(preSourse, ["name"], ["asc"]);
        if(data.data().tipoContrato == 'directo') this.serversDirectas.push(data.id);
        this.arrayServersCiudad = this.arrayServers.filter(
          (server) => (server.department == "Cundinamarca" || server.department == "Bogotá D.C.")
        );
        if (index == res.docs.length - 1) {
          console.log("Servers consultadas")
          this.revisarDisponibilidad();
          this.birthdaysCalendar();
        }
      })
    });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  verificarFecha() {
    this.fechaFiltro = moment(this.fch).format("YYYY-MM-DD");
    this.arrayDisponibilidadServes = [];
    this.arrayDisponibilidadServesBogota = [];
    this.arrayDisponibilidadServesMedellin = [];

    this.serversSinServicio = 0;
    this.serversSinServicioTarde = 0;
    this.servers_directas_4_horas = [];
    this.servers_directas_6_horas = [];
    this.serversConServicioDia = [];
    this.servers_sin_servicio = [];
    this.revisarDisponibilidad();
  }

  revisarDisponibilidad() {
    console.log("Array de serversd dashboard",this.arrayServers);
    this.arrayServers.forEach((element) => {
      let disponibilidadServer = {
        id: element.id,
        nombre: `${element.name} ${element.apellido}`,
        departamento: element.department,
        hora6: "libre",
        hora7: "libre",
        hora8: "libre",
        hora9: "libre",
        hora10: "libre",
        hora11: "libre",
        hora12: "libre",
        hora13: "libre",
        hora14: "libre",
        hora15: "libre",
        hora16: "libre",
        hora17: "libre",
        hora18: "libre",
        hora19: "libre",
        hora20: "libre",
        hora21: "libre",
      };
      this.servicioService.getDisponibilidadById(element.id).then(async (res) => {
        if (res.docs.length != 0) {
          // console.log("Server con fechasBloqueadas")
          res.docs.forEach(async (data) => {
            let fechas = data.data();
            let disponible = fechas.fechas.filter( (fecha) => fecha.fecha == this.fechaFiltro );
            if (disponible.length > 0) {
              for (let i = 6; i <= 20; i++) {
                disponibilidadServer[`hora${i}`] = "bloqueada";
              }
              this.arrayDisponibilidadServes.push(disponibilidadServer);
              // console.log('Dia bloqueado',this.arrayDisponibilidadServes.length,this.arrayServers.length)
              if (this.arrayDisponibilidadServes.length == this.arrayServers.length) {
                // seccionasr por ubicacion
                let bogota = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Cundinamarca" || server.departamento == "Bogotá D.C." );
                let medellin = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Antioquia" );
                this.arrayDisponibilidadServesBogota = _.orderBy( bogota, ["nombre"], ["asc"] );
                this.arrayDisponibilidadServesMedellin = _.orderBy( medellin, ["nombre"], ["asc"] );
                this.servers_sin_servicio = _.orderBy( this.servers_sin_servicio, ["tipoContrato"], ["asc"] );
              }
            } else {
              // console.log("server que pasaron teniendo fehcasbloqueadas")
              await this.buscarServicios( element.id, disponibilidadServer.nombre, element.department,element.tipoContrato);
            }
          });
        } else {
          // console.log("server sin fechas bloqueadas")
          await this.buscarServicios( element.id, disponibilidadServer.nombre, element.department,element.tipoContrato );
        }
      });
    });
  }

  buscarServicios(id, name, departamento,tipoContrato) {
    // console.log("buscarServicios",departamento);
    this.servicios = [];
    this.serversBogotaDisponibles = [];
    this.serversMedellinDisponibles = [];
    let disponibilidadServer = {
      id: id,
      nombre: name,
      departamento: departamento,
      hora6: "libre",
      hora7: "libre",
      hora8: "libre",
      hora9: "libre",
      hora10: "libre",
      hora11: "libre",
      hora12: "libre",
      hora13: "libre",
      hora14: "libre",
      hora15: "libre",
      hora16: "libre",
      hora17: "libre",
      hora18: "libre",
      hora19: "libre",
      hora20: "libre",
    };
    this.servicioService.getByServerOnADate(id, this.fechaFiltro).then((res_servicios) => {
      // console.log(this.contador_1,'Servicios server',res_servicios.docs.length);
      if (res_servicios.docs.length == 0) {
        this.serversSinServicio++
        this.servers_sin_servicio.push({name:name,lugar:departamento,tipoContrato:tipoContrato})
        this.arrayDisponibilidadServes.push(disponibilidadServer);
        // console.log('sin', this.arrayDisponibilidadServes.length,this.arrayServers.length)
        if ( this.arrayDisponibilidadServes.length == this.arrayServers.length ) {
          let bogota = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Cundinamarca" || server.departamento == "Bogotá D.C.");
          let medellin = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Antioquia" );
          this.arrayDisponibilidadServesBogota = _.orderBy( bogota, ["nombre"], ["asc"] );
          this.arrayDisponibilidadServesMedellin = _.orderBy( medellin, ["nombre"], ["asc"] );
          this.servers_sin_servicio = _.orderBy( this.servers_sin_servicio, ["tipoContrato"], ["asc"] );
        }
      } else {
        let contadorGeneral = 0;
        res_servicios.docs.forEach((data) => {
          const servicio = data.data();
          const inicio = parseInt(servicio.hora);
          const fin = parseInt(servicio.horasDeServicio) + inicio;
          // if(fin > 24) console.log( servicio.horasDeServicio ,inicio,servicio.id)
          // if(this.contador_1 == 37) console.log( servicio.horasDeServicio ,inicio,servicio.id,fin)
          this.serversConServicioDia.push(servicio.idServer)

          if(this.serversDirectas.filter(idserver=> idserver == servicio.idServer).length > 0 && this.serversConServicioDia.filter(idServer=> idServer == servicio.idServer).length == 1){
            if(parseInt(servicio.horasDeServicio) == 4 ){
              this.servers_directas_4_horas.push(`${servicio.server.name}`);
            }
            else if(parseInt(servicio.horasDeServicio) == 6){
              this.servers_directas_6_horas.push(`${servicio.server.name}`);
            }
          } else if(this.serversDirectas.filter(idserver=> idserver == servicio.idServer).length > 0 && this.serversConServicioDia.filter(idServer=> idServer == servicio.idServer).length == 2){
            
            if(parseInt(servicio.horasDeServicio) == 4){
              const index = this.servers_directas_4_horas.findIndex(res=>res == servicio.server.name );
              this.servers_directas_4_horas.splice(index,1);
            }
            else if(parseInt(servicio.horasDeServicio) == 6){
              const index = this.servers_directas_6_horas.findIndex(res=>res == servicio.server.name );
              this.servers_directas_6_horas.splice(index,1);
            }
          }
          
          for (let i = inicio; i < fin; i++) {
            if (i == 6) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora6 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora6 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora6 = "asignada-fin";
              } else {
                disponibilidadServer.hora6 = "asignada-unaHora";
              }
            } else if (i == 7) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora7 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora7 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora7 = "asignada-fin";
              } else {
                disponibilidadServer.hora7 = "asignada";
              }
            } else if (i == 8) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora8 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora8 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora8 = "asignada-fin";
              } else {
                disponibilidadServer.hora8 = "asignada";
              }
            } else if (i == 9) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora9 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora9 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora9 = "asignada-fin";
              } else {
                disponibilidadServer.hora9 = "asignada";
              }
            } else if (i == 10) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora9 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora10 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora10 = "asignada-fin";
              } else {
                disponibilidadServer.hora10 = "asignada";
              }
            } else if (i == 11) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora11 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora11 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora11 = "asignada-fin";
              } else {
                disponibilidadServer.hora11 = "asignada";
              }
            } else if (i == 12) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora12 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora12 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora12 = "asignada-fin";
              } else {
                disponibilidadServer.hora12 = "asignada";
              }
            } else if (i == 13) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora13 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora13 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora13 = "asignada-fin";
              } else {
                disponibilidadServer.hora13 = "asignada";
              }
            } else if (i == 14) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora14 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora14 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora14 = "asignada-fin";
              } else {
                disponibilidadServer.hora14 = "asignada";
              }
            } else if (i == 15) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora15 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora15 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora15 = "asignada-fin";
              } else {
                disponibilidadServer.hora15 = "asignada";
              }
            } else if (i == 16) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora16 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora16 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora16 = "asignada-fin";
              } else {
                disponibilidadServer.hora16 = "asignada";
              }
            } else if (i == 17) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora17 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora17 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora17 = "asignada-fin";
              } else {
                disponibilidadServer.hora17 = "asignada";
              }
            } else if (i == 18) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora18 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora18 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora18 = "asignada-fin";
              } else {
                disponibilidadServer.hora18 = "asignada";
              }
            } else if (i == 19) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora19 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora19 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora19 = "asignada-fin";
              } else {
                disponibilidadServer.hora19 = "asignada";
              }
            } else if (i == 20) {
              if (i == fin - 1 && i == inicio) {
                disponibilidadServer.hora20 = "asignada-unaHora";
              } else if (i == inicio) {
                disponibilidadServer.hora20 = "asignada-inicio";
              } else if (i == fin - 1) {
                disponibilidadServer.hora20 = "asignada-fin";
              } else {
                disponibilidadServer.hora20 = "asignada";
              }
            }
            // console.log(i, fin-1);
            if (i == fin - 1) {
              contadorGeneral++;
              // console.log('Contador general',contadorGeneral,res_servicios.docs.length)
              if (contadorGeneral == res_servicios.docs.length) {
                if (disponibilidadServer.hora16 == 'libre' && disponibilidadServer.hora17 == 'libre' && disponibilidadServer.hora18 == 'libre' && disponibilidadServer.hora19 == 'libre' && disponibilidadServer.hora20 == 'libre') {
                  this.serversSinServicioTarde++
                }
                this.arrayDisponibilidadServes.push(disponibilidadServer);
                // console.log('con servicio', this.arrayDisponibilidadServes.length,this.arrayServers.length)
                if ( this.arrayDisponibilidadServes.length == this.arrayServers.length) {
                  let bogota = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Cundinamarca"  || server.departamento == "Bogotá D.C.");
                  let medellin = this.arrayDisponibilidadServes.filter( (server) => server.departamento == "Antioquia" );
                  this.arrayDisponibilidadServesBogota = _.orderBy( bogota, ["nombre"], ["asc"] );
                  this.arrayDisponibilidadServesMedellin = _.orderBy( medellin, ["nombre"], ["asc"]);
                  this.servers_sin_servicio = _.orderBy( this.servers_sin_servicio, ["tipoContrato"], ["asc"] );
                }
              }
            }
          }
        });
      }
    }).catch(() => {
      console.error("Ocurrio un error al acargar los servicios")
    });
    /*  this.servicioService.getServiciosSemanaActualByID(id,this.fechaFiltro,this.fechaFiltro).then(async (resultado:any) => {
      const allServices = resultado.docs;
      if(allServices.length == 0){
        this.serverList.forEach(server => {        
          if(server.department == "Antioquia"){
            this.serversMedellinDisponibles.push(server);
          }else{
            this.serversBogotaDisponibles.push(server);
          }
        });
      }
      else{
        allServices.forEach(element => {
          const servicio = element.data()
          this.servicios.push(servicio);
          if(this.servicios.length == allServices.length){
            this.verificarOcupacion();
          }
        });
      }
    }) */
  }

  verificarOcupacion() {
    this.serverList.forEach((element) => {
      let serverDisponible = true;
      let count = 0;
      for (let servicio of this.servicios) {
        if (element.id == servicio.idServer) {
          serverDisponible = false;
        }
        count++;
        if (count == this.servicios.length && serverDisponible == true) {
          if (element.department == "Antioquia") {
            this.serversMedellinDisponibles.push(element);
          } else {
            this.serversBogotaDisponibles.push(element);
          }
        }
      }
    });
  }

  verInfoServer(id) {
    this.idServerSeleccionada = id;
    console.log(this.idServerSeleccionada,this.serverAnterior);
    if ( this.idServerSeleccionada != this.serverAnterior && this.serverAnterior != "falta" ) {
      const ServAnterior: HTMLElement = document.getElementById( this.serverAnterior) as HTMLElement;
      ServAnterior.className = "a";
      this.serverAnterior = id;
    }
    if (this.serverAnterior == "falta") this.serverAnterior = id;
    const servSeleccionada: HTMLElement = document.getElementById( id ) as HTMLElement;
    servSeleccionada.className = "serverSelecionada";
    this.infoServer = this.arrayServers.filter((server) => server.id == id)[0];
    console.log(this.infoServer)
  }

  async verAgendaServer(server, i) {
    this.serviciosDiarios = [];
    this.listaCalendarioServicio = [];
    console.log(server);
    const result_servicios = await this.buscarServiciosAgenda(i);
    const result_diuasBloqueados = await this.busacarDiasBloqueados(i);
    const result_planesRecuurentes = await this.busacarPlanesRecurrentes(i);

    this.planesRecurrentes =  this.arrayServersCiudad[i].arrayPlanesRecurrentes
    this.arrayServersCiudad[i].consultado = true;
    this.listaCalendarioServicio = server.arrayEventosCalendario;
    this.idServerSeleccionada = server.id;
    this.infoServerSeleccionada = server;
    this.refreshView();
    if ( this.idServerSeleccionada != this.serverAnterior && this.serverAnterior != "falta") {
      const ServAnterior: HTMLElement = document.getElementById( this.serverAnterior ) as HTMLElement;
      ServAnterior.className = "a";
      this.serverAnterior = server.id;
      this.fechaSeleccionada = [];
      this.selectedDays = [];
    }
    if (this.serverAnterior == "falta") {
      this.serverAnterior = server.id;
    }
    const servSeleccionada: HTMLElement = document.getElementById(
      server.id
    ) as HTMLElement;
    servSeleccionada.className = "serverSelecionada";
    // console.log(server)
  }

  buscarServiciosAgenda(i) {
    return new Promise(resolve=>{
      this.generalLoader = true;
      const server = this.arrayServersCiudad[i]
      console.log(server.arrayEventosCalendario);
      if(server.consultado){
        this.generalLoader = false;
        return resolve(true);
      }

      let events = [];
      const daysOfWeek: { [key: number]: Weekday } = {
        0: RRule.SU,
        1: RRule.MO,
        2: RRule.TU,
        3: RRule.WE,
        4: RRule.TH,
        5: RRule.FR,
        6: RRule.SA
      };
      const selectedDays = this.arrayServersCiudad[i].horario.filter(res=> res.start_time == "")
      console.log(selectedDays)
      if(selectedDays.length > 0){
        const rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: selectedDays.map(day => daysOfWeek[day.day]),
          dtstart: new Date(),
          until: new Date(moment().add(3,'months').format('YYYY-MM-DD'))
        });
        events = rule.all().map(date => ({
          start: new Date(date),
          title: 'El horario indica que no trabaja este dia',
          obj: {fecha: moment(date).format('YYYY-MM-DD'), tipoServicio: "No trabaja este dia, segun horario" },
          color: colors.secondary
        }));
      }

      this.servicioService.getByServerFromADate(server.id, moment().startOf("month").format("YYYY-MM-DD")).then((res) => {
        for (let a = 0; a < res.docs.length; a++) {
          const service = res.docs[a].data();
          let inicio = moment(service.fecha).toDate();
          let fin = moment(service.fecha).toDate();
          const evento = {
            start: inicio,
            end: fin,
            color:
              service.tipoServicio == "Plan Mensual"
                ? colors.plan
                : service.tipoServicio == "Plan mensual"
                  ? colors.plan
                  : colors.individual,
            title:
              service.tipoServicio == "Plan Mensual"
                ? "Servicio de Plan"
                : service.tipoServicio == "Plan mensual"
                  ? "Servcio de plan"
                  : "Servicio individual",
            id: service.id,
            obj: service,
          };
          events.push(evento)
        }
        this.arrayServersCiudad[i].arrayEventosCalendario = events;
        this.listaCalendarioServicio = this.arrayServersCiudad[i].arrayEventosCalendario;
        // console.log('cargo todo',this.arrayServers[i], this.arrayServersCiudad[i]);
        this.generalLoader = false;
        resolve(true);
      })
    })
  }

  busacarPlanesRecurrentes(index){
    return new Promise (resolve=>{
      this.generalLoader = true;
      const server = this.arrayServersCiudad[index]
      if(server.consultado){
        this.generalLoader = false;
        return resolve(true);
      }
      let events = [];
      this.planRecurrenteService.getPlanByIdServer(server.id).then((res)=>{
        if (res.size > 0) {
          this.arrayServersCiudad[index].arrayPlanesRecurrentes = res.docs.map(data=> {return{...data.data() }}); 
          this.generalLoader = false;
          return resolve(true);
        } else {
          this.arrayServersCiudad[index].arrayPlanesRecurrentes = [];
          this.generalLoader = false;
          return resolve(true);
        }
      })

    });
  }

  busacarDiasBloqueados(index) {
    return new Promise (resolve=>{
      this.generalLoader = true;
      const server = this.arrayServersCiudad[index]
      if(server.consultado){
        this.generalLoader = false;
        return resolve(true);
      }
      let events = [];
      this.servicioService.getDisponibilidadByIdDashboard(server.id).onSnapshot((res) => {
        if (res.exists) {
          const fehcasBloqueadas = res.data();
          this.arrayServersCiudad[index].arrayDiasBloqueados = fehcasBloqueadas; 
          let fechas = res.data().fechas;
          // console.log(fechas.length)
          if (fechas.length > 0) {
            for(let i = 0;i< fechas.length;i++){
              let inicio = moment(fechas[i].fecha).toDate();
              let fin = moment(fechas[i].fecha).toDate();
              let evento = {
                start: inicio,
                end: fin,
                color: colors.bloqueado,
                title: "Bloqueado",
                id: fechas[i].id,
                obj: {fecha: fechas[i].fecha, tipoServicio: "Dia bloqueado" },
                fechaBloqueada: fechas[i].fecha
              };
              events.push(evento);
            }
          }
          this.generalLoader = false;
          this.arrayServersCiudad[index].arrayEventosCalendario = this.arrayServersCiudad[index].arrayEventosCalendario.concat(events);

          return resolve(true);
        } else {
          this.arrayServersCiudad[index].arrayDiasBloqueados = {
            fechas: [],
            idServer: server.id
          }
          this.generalLoader = false;
          return resolve(true);
        }
      })
    })
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    let info = [];
    this.serviciosDiarios = [];
    console.log(events)

    document.onkeydown = function (e) {
      keyPress = e.key;
      // console.log(keyPress)
      return e.key;
    };
    document.onkeyup = function (e) {
      keyPress = "null";
      // console.log(keyPress)
      return e.key;
    };
    // console.log(keyPress)
    if (keyPress == "Control") {
      if (events.length == 0) {
        this.selectedDays.push(date.getTime());
        this.fechaSeleccionada.push(moment(date).format("YYYY-MM-DD"));
      }
    } else {
      this.selectedDays = [];
      this.selectedDays = [date.getTime()];
      events.forEach((data) => {
        info.push(data.obj);
        if (info.length == events.length) {
          this.serviciosDiarios = info;
        }
      });
      this.fechaSeleccionada = [];
      console.log( this.fechaSeleccionada);
      this.fechaSeleccionada.push(moment(date).format("YYYY-MM-DD"));
      if (events.length > 0  && events[0].title == "Bloqueado") {
        this.fechaParaDesbloquear = [];
        this.fechaParaDesbloquear.push(moment(date).format("YYYY-MM-DD"));
      }
    }
    console.log(this.infoServerSeleccionada.arrayDiasBloqueados);
    this.refreshView();
  }

  beforeMonthViewRenderApp(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    const self = this;
    console.log('Esto es self', self)

    renderEvent.body.forEach((day) => {

      if (self.eventsCalendarDisponibilidadApp.some(res => res.fecha == moment(day.date).format('YYYY-MM-DD')))
        day.cssClass = "cal-day-blocked";

      if (self.selectedDays.some(res => res == day.date.getTime())) {
        if (day.cssClass == "cal-day-blocked")
          day.cssClass = "cal-day-blocked-selected";
        else
          day.cssClass = "cal-day-selected";
      }
    });
  }
  beforeMonthViewRenderServers(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    const self = this;
    if (self.selectedDays.length > 0) {
      renderEvent.body.forEach((day) => {
        for (let fecha of self.selectedDays) {
          if (day.date.getTime() === fecha) {
            day.cssClass = "cal-day-selected";
          }
        }
      });
    }
  }

  refreshView(): void {
    this.refresh.next();
  }

  bloquearFechasApp() {
    for (let _fecha of this.fechaSeleccionada) {
      const info = this.disponibilidadAppService.add({ fecha: _fecha })
      this.eventsCalendarDisponibilidadApp.push(info);
    }
    this.selectedDays = [];
    this.fechaSeleccionada = [];
    // this.refresh = new Subject();
    this.refreshView();
  }

  desbloquearFechaApp() {
    for (let _fecha of this.fechaSeleccionada) {
      const index = this.eventsCalendarDisponibilidadApp.findIndex(res => res.fecha == _fecha)[0];
      this.eventsCalendarDisponibilidadApp.splice(index, 1)
      this.disponibilidadAppService.delete(this.eventsCalendarDisponibilidadApp[index].id);
    }
    this.selectedDays = [];
    this.fechaSeleccionada = [];
    // this.refresh = new Subject();
    this.refreshView();
  }

  bloquearDiaServer() {
    const a = document.getElementById("btnBloquearDais") as HTMLButtonElement;
    a.disabled = true;
    console.log(this.fechaSeleccionada)
    for (let fecha of this.fechaSeleccionada) {
      this.infoServerSeleccionada.arrayDiasBloqueados.fechas.push({
        fecha: fecha,
      });
      let inicio = moment(fecha).toDate();
      let fin = moment(fecha).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.bloqueado,
        title: "Bloqueado",
        id: 123,
        obj: [],
      };
      Swal.fire("Éxito", "Dia bloqueado", "success");
      this.infoServerSeleccionada.arrayEventosCalendario.push(evento);
    }
    console.log(this.infoServerSeleccionada.arrayDiasBloqueados)
    this.servicioService.guardarDiasBloqueados(this.infoServerSeleccionada.arrayDiasBloqueados).then(() => {
      console.log("bloqueado")
      this.selectedDays = [];
      this.fechaSeleccionada = [];
      this.refreshView();
      a.disabled = false;
    });
  }
  desbloquearDias() {
    console.log(this.fechaParaDesbloquear)
    for (let fecha of this.fechaParaDesbloquear) {
      let filter = this.infoServerSeleccionada.arrayDiasBloqueados.fechas.filter(fch => fch.fecha == fecha);
      for(let i =0;i < filter.length;i++){
        console.log(filter[i]);
        const index = this.infoServerSeleccionada.arrayDiasBloqueados.fechas.findIndex(fch => fch.fecha == filter[i].fecha)
        const index2 = this.infoServerSeleccionada.arrayEventosCalendario.findIndex(fch => fch.fechaBloqueada == filter[i].fecha)
        if (index !== -1) {
          this.infoServerSeleccionada.arrayDiasBloqueados.fechas.splice(index, 1)
          this.infoServerSeleccionada.arrayEventosCalendario.splice(index2, 1)
        }
      }
    }
    console.log(this.infoServerSeleccionada.arrayDiasBloqueados);
    console.log(this.infoServerSeleccionada.arrayEventosCalendario);

    this.servicioService.guardarDiasBloqueados(this.infoServerSeleccionada.arrayDiasBloqueados)
      .then(() => {
        this.selectedDays = [];
        this.fechaSeleccionada = [];
        this.refreshView();
      });
  }

  public ciudadTabla = 'Bogota'
  cambiarCiudad(ciudad) {
    if (ciudad == "bogota") {
      this.ciudadTabla = 'Bogota'
      this.serverAnterior = "falta";
      this.arrayServersCiudad = this.arrayServers.filter(
        (server) => (server.department == "Cundinamarca" || server.department == "Bogotá D.C.")
      );
    } else if (ciudad == "medellin") {
      this.ciudadTabla = 'Medellin'
      this.serverAnterior = "falta";
      this.arrayServersCiudad = this.arrayServers.filter(
        (server) => server.department == "Antioquia"
      );
    }
  }


  filtroServer(event) {
    this.arrayServersCiudad = this.arrayServers.filter(
      (server) => server.name.includes(event.target.value)
    );
  }
  arrayCategoriaNovedad = Novedades;
  arrayTiposNovedad = [];
  newNovelty = {
    estado: '',
    categoria:'',
    tipo: '',
    area: '',
    costo: '',
    descripcion: '',
    intencion: '',
    subCategoria: '',
    fechaCreacion:  moment().format("YYYY-MM-DD"),
    fechaInicioIncapacidad: '',
    fechaFinIncapacidad: ''
  }
  
  onOpenViewUser() {
    this.mdlViewUser = false;
    console.log(this.mdlViewUser)
    setTimeout(() => {
      this.mdlViewUser = true;
    }, 500);
  }

  public buscarNovedades(event, type) {
    let value = event.target.value;
    console.log(value, type);
    if (type == "tipo") {
      let index = this.arrayCategoriaNovedad.findIndex(categoria => categoria.name == value);
      console.log(index);
      this.arrayTiposNovedad = this.arrayCategoriaNovedad[index].tipo;
      console.log(this.arrayTiposNovedad);
    } else if (type == "area") {
      let index = this.arrayTiposNovedad.findIndex(tipo => tipo.name == value);
      console.log(this.arrayTiposNovedad[index])
      this.newNovelty.area = this.arrayTiposNovedad[index].area;
      this.newNovelty.costo = this.arrayTiposNovedad[index].valor
      this.newNovelty.estado = this.arrayTiposNovedad[index].estado;
      this.newNovelty.intencion = this.arrayTiposNovedad[index].intencion;
      this.newNovelty.subCategoria = this.arrayTiposNovedad[index].subCategoria ? this.arrayTiposNovedad[index].subCategoria : ''
      console.log(this.newNovelty.area);
    }
  }

  async crearNovedad(){
    let novedad: any = {
      id: new Date().getTime(),
      categoria: this.newNovelty.categoria,
      tipo: this.newNovelty.tipo,
      area: this.newNovelty.area,
      descripcion: this.newNovelty.descripcion,
      emisor: this.usuarioActivo.displayName,
      estado: this.newNovelty.estado,
      intencion: this.newNovelty.intencion,
      agenteDesignado: "Sin agente",
      costo: parseInt(this.newNovelty.costo),
      cliente: '',
      clienteId: '',
      server: this.infoServerSeleccionada.name ? this.infoServerSeleccionada.name + " " + this.infoServerSeleccionada.apellido : "Sin server",
      serverId: this.infoServerSeleccionada.id ? this.infoServerSeleccionada.id : "Sin server",
      servicioAsociado: '',
      fechaCreacion: this.newNovelty.fechaCreacion,
      fechaEnProceso: "null",
      fechaSolucionada: "null",
      fechaDelServicio: '',
      ciudad: this.infoServerSeleccionada.cities,
      urlArchivo: '',
      tipoServicio: '',
      department: this.infoServerSeleccionada.department,
      estadoGeneral: 'vigente',
    }
    // TO DO - agregr ela funcionalidad de subir archivo a la novedad;
    if(this.newNovelty.subCategoria != "") {
      novedad.subCategoria = this.newNovelty.subCategoria;
      if(this.newNovelty.fechaInicioIncapacidad == "" || this.newNovelty.fechaFinIncapacidad == "") {
        Swal.fire("Espera", `Indica el periodo que durara la  ${this.newNovelty.tipo}`, "warning");
        return;
      }
      novedad.fechaInicioIncapacidad = this.newNovelty.fechaInicioIncapacidad;
      novedad.fechaFinIncapacidad = this.newNovelty.fechaFinIncapacidad;
    }
    
    if(novedad.tipo == "No trabajo este dia"){
      const novedadesCreadas = await this.metricasService.getNovedadServerPorTipoFecha(novedad.serverId,novedad.tipo,novedad.fechaCreacion);
      if(novedadesCreadas.size > 0 ){
        Swal.fire("Creado", "Novedad creada exitosamente", "success");
        this.newNovelty.estado = '';
        this.newNovelty.area = '';
        this.newNovelty.categoria = '';
        this.newNovelty.costo = '';
        this.newNovelty.descripcion = '';
        this.newNovelty.tipo = '';
        this.newNovelty.fechaCreacion = '';
        this.newNovelty.intencion = '';
        this.newNovelty.fechaInicioIncapacidad = '';
        this.newNovelty.fechaFinIncapacidad = '';
        this.arrayTiposNovedad = [];
        return;
      }
    }
  
    this.metricasService.crearNovedad(novedad).then(()=> {
      Swal.fire("Creado", "La novedad fue creada exitosamente", "success");
      this.newNovelty.estado = '';
      this.newNovelty.area = '';
      this.newNovelty.categoria = '';
      this.newNovelty.costo = '';
      this.newNovelty.descripcion = '';
      this.newNovelty.tipo = '';
      this.newNovelty.fechaCreacion = '';
      this.newNovelty.intencion = '';
      this.newNovelty.fechaInicioIncapacidad = '';
      this.newNovelty.fechaFinIncapacidad = '';
      this.arrayTiposNovedad = [];
    })
  }

  validarNovedadYaCreada(){
  }

  administrativeService: any;
  async generateAdministrativeService(){

    this.generalLoader = true;
    let dia = ''
    let dia_i = moment(this.fechaSeleccionada[0]).day()
    if (dia_i == 0) {
      dia = "domingo";
    } else if (dia_i == 1) {
      dia = "lunes";
    } else if (dia_i == 2) {
      dia = "martes";
    } else if (dia_i == 3) {
      dia = "miercoles";
    } else if (dia_i == 4) {
      dia = "jueves";
    } else if (dia_i == 5) {
      dia = "viernes";
    } else if (dia_i == 6) {
      dia = "sabado";
    }
    const server = (await this.serverService.detail(this.idServerSeleccionada)).data();
    let servicio: any = {
      id: new Date().getTime(),
      hora: this.administrativeService.startTime,
      horario: 'mañana',
      horasDeServicio: this.administrativeService.duration,

      server: server,
      idServer: this.idServerSeleccionada,
      client: {name:"Serv"},
      userid: "servClient2024",
      dia: dia,
      destination: {
        cities: "Bogotá, D.C.",
        country: "Colombia",
        department: "Bogotá D.C.",
        direccion: "Cl. 59 #6-36, Localidad de Chapinero, Bogotá",
        lat: 4.6443471,
        lng:  -74.06213740000001,
        neighborhood: "Chapinero",
        nombreDireccion: "Oficina SERV",
        observaciones:  "oficina 401",
        tipoPropiedad: "Oficina"
      },
      direccion: "Cl. 59 #6-36, Localidad de Chapinero, Bogotá, Colombia",
      fecha: this.fechaSeleccionada[0],
      estado:"Aceptado",
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaLimitePago: moment().format("YYYY-MM-DD"),
      asesor: '',
      categoria: '',
      checkList: [],
      comprobante: '',
      creado: "admin",
      calificacion: 'falta',
      estadoPago: '',
      formaDePago: '',
      novedad: false,
      numFactura: '',
      precio: 0,
      descuento: 0,
      recargo: 0,
      recargoTransporte: 0,
      razonRecargo: '',
      referenciaPago: '',
      total: 0,
      servicio: 'Administrativo',
      subCategoria: '',
      tipoServicio: 'Administrativo',
      estadoPagoServer: "Pendiente",
      subasta: false,
      metrosDesinfeccion: "No aplica",
      gananciaServer: 0,
      responsablePostventa: '',
      gananciaServ: 0,
      bonoPuntualidad_App: true,
    }

    if(this.ciudadTabla == 'Medellin'){
      servicio.destination = {
        cities: "Medellin",
        country: "Colombia",
        department: "Antioquia",
        direccion: "Cq. 4 #73-64, Laureles - Estadio, Medellín, Laureles, Medellín, Antioquia",
        lat: 6.245235399999999,
        lng:  -75.5930052,
        neighborhood: "Laureles",
        nombreDireccion: "Oficina SERV",
        observaciones:  "edificio ivy coworking",
        tipoPropiedad: "Oficina"
      },
      servicio.direccion = "circular 4 #73-64, Laureles - Estadio, Medellín, Laureles, Medellín, Antioquia"
    }
    this.servicioService.registerServicio(servicio).then(()=>{
      this.generalLoader = false;
      Swal.fire('Listo','EL servicio administrativo se agendo con exito','success');
    })

  }

  arrayFestivos = festivosColombia;
  isHolyDayCalendar(day: any) {
    const date = day.date;
    if (this.arrayFestivos.some(res=> res.fecha == moment(date).format("YYYY-MM-DD"))) {
      return 'holiday-cal-number';
    }else{
      return 'cal-day-number';
    }
  }

  async birthdaysCalendar(){
    let events = [];
    this.arrayServers.forEach(server=>{
      const fecha = `${moment().format('YYYY')}-${moment(server.fechaNacimiento).format('MM-DD')}`
      let inicio = moment(fecha).toDate();
      let fin = moment(fecha).toDate();

      const evento = {
        start: inicio,
        end: fin,
        color: colors.individual,
        title: `Cumpleaños server ${server.name} ${server.apellido}`,
        id: server.id,
        obj: {fecha: '', tipoServicio: `Cumpleaños server`, client: {name: `${server.name} ${server.apellido}`} },
      };
      events.push(evento);

      /* Cumpleaños de estar en serv */
      const fecha2 = `${moment().format('YYYY')}-${moment(server.fecha).format('MM-DD')}`
      // console.log(fecha2,moment().diff(fecha2,'days') )
      if(moment().diff(fecha2,'days') > 300){
        let inicio2 = moment(fecha2).toDate();
        let fin2 = moment(fecha2).toDate();
        const evento2 = {
          start: inicio2,
          end: fin2, 
          color: colors.blue_2,
          title: `Cumpleaños server ${server.name} ${server.apellido}`,
          id: server.id,
          obj: {fecha: '', tipoServicio: `Cumpleaños server`, client: {name: `${server.name} ${server.apellido}`} },
        };
        events.push(evento2);
      }

    
    })

    const query = await this.authService.getUsuarioAdmin();
    for(let user of query.docs){
      const fecha = `${moment().format('YYYY')}-${moment(user.data().fechaCumple).format('MM-DD')}`
      let inicio = moment(fecha).toDate();
      let fin = moment(fecha).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.secondary,
        title: `Cumpleaños ${user.data().displayName}`,
        id: user.id,
        obj: {fecha: '', tipoServicio: `Cumpleaños`,client: {name: user.data().displayName} },
      };
      events.push(evento);
    }

    this.birthdaysCalendarEvents = events;
  }

}

