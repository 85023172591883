import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { CompleterService } from "ng2-completer";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import * as _ from "lodash";
/* Servicios */
import { CandidatoService } from 'src/app/services/candidato.service';
import { Location } from '@angular/common';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ver-candidato',
  templateUrl: './ver-candidato.component.html',
  styleUrls: ['./ver-candidato.component.scss']
})

export class VerCandidatoComponent implements OnInit {

  @Input() candidato_id: any;
  @ViewChild("mdlVerCandidato", { static: true }) mdlVerCandidato: ElementRef;

  public descuentoSeguridad: any;
  public pictureName3: any;
  public file3: any;
  public nameImage3: string = '';
  public fotoSeleccionada3: any;
  public image3: boolean = false;

  public contrato: any;
  public pictureName2: any;
  public file2: any;
  public nameImage2: string = '';
  public fotoSeleccionada2: any;
  public image2: boolean = false;
  public route: ActivatedRoute;

  public antecedentes2: any;
  public pictureName: any;
  public file: any;
  public nameImage: string = '';
  public fotoSeleccionada: any;
  public image: boolean = false;
  public candidato: any = {};
  public zonas: any = [];

  profileUrl: Observable<String | null>;
  disponibleParaCreacion: boolean = false;
  preInfoServer: any;
  agregarServer: boolean = false;
  tipoModalServer = "crear";
  constructor(
    private router: Router,
    private dialogService: NbDialogService,
    public completerService: CompleterService,
    public _location: Location,
    private candidatoService: CandidatoService,
    public activatedRoute: ActivatedRoute,
    private storage: AngularFireStorage
  ) {

  }

  async ngOnInit() {
    // const foto = this.storage.ref('Aspirante/Cedula_'+123456789+'/Foto');
    // this.profileUrl = foto.getDownloadURL();
    console.log(this.candidato_id);
    this.candidato = (await this.candidatoService.getCandidato(this.candidato_id)).data();
    console.log(this.candidato);
    this.verificarInfo();
    this.openDialogSave(this.mdlVerCandidato);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  //Permite cargar documentos a storage
  onUpload(event) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;
    this.image = true;
    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onUpload2(event) {
    this.file2 = event.target.files[0];
    this.pictureName2 = Date.now() + this.file2.name;
    this.image2 = true;
    if (event.target.files && this.file2) {
      this.nameImage2 = this.file2.name;
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada2 = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onUpload3(event) {
    this.file3 = event.target.files[0];
    this.pictureName3 = Date.now() + this.file3.name;
    this.image3 = true;
    if (event.target.files && this.file3) {
      this.nameImage3 = this.file3.name;
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada3 = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  CancelUpload() {
    this.nameImage = '';
    this.file = undefined;
    this.image = false;
  }

  public actualizarAspirante() {
    if (this.file && this.pictureName) {
      this.candidatoService.putAntecedentes(this.pictureName, this.file).then(value => {
        if (value.state == 'success') {
          this.candidatoService.getURLAntecedentes(this.pictureName).subscribe(url => {
            this.antecedentes2 = url;
            console.log(this.antecedentes2)
            this.candidato.antecedentes2 = this.antecedentes2
            //funcion edit candidato
            this.candidatoService.editCandidato(this.candidato).then((result) => {

            }).catch(err => {
              Swal.fire(
                'Error',
                'Problemas con la base de datos',
                'warning'
              );
            });
            //fin funcion edit candidato
          });
        };
      });
    }
    if (this.file2 && this.pictureName2) {
      this.candidatoService.putContrato(this.pictureName2, this.file2).then(value => {
        if (value.state == 'success') {
          this.candidatoService.getURLContrato(this.pictureName2).subscribe(url => {
            this.contrato = url;
            console.log(this.contrato)
            this.candidato.contrato = this.contrato
            //funcion edit candidato
            this.candidatoService.editCandidato(this.candidato).then(() => {
            }).catch(err => {
              Swal.fire(
                'Error',
                'Problemas con la base de datos',
                'warning'
              );
            });
            //fin funcion edit candidato
          });
        };
      });
    }
    if (this.file3 && this.pictureName3) {
      this.candidatoService.putDescuentoSeguridad(this.pictureName3, this.file3).then(value => {
        if (value.state == 'success') {
          this.candidatoService.getURLDescuentoSeguridad(this.pictureName3).subscribe(url => {
            this.descuentoSeguridad = url;
            console.log(this.descuentoSeguridad)
            this.candidato.descuentoSeguridad = this.descuentoSeguridad
            //funcion edit candidato
            this.candidatoService.editCandidato(this.candidato).then(() => {
            }).catch(err => {
              Swal.fire(
                'Error',
                'Problemas con la base de datos',
                'warning'
              );
            });
            //fin funcion edit candidato
          });
        };
      });
    }
    //funcion edit candidato
    this.candidatoService.editCandidato(this.candidato).then(() => {
      this.verificarInfo();
      Swal.fire(
        'Éxito',
        'Se actualizo el candidato exitosamente',
        'success'
      );

    }).catch(err => {
      Swal.fire(
        'Error',
        'Problemas con la base de datos',
        'warning'
      );
    });
    //fin funcion edit candidato

  }

  verificarInfo() {
    if (this.candidato.especialidad && this.candidato.comoTeEnteraste && this.candidato.utilizaGoogleMaps && this.candidato.nivelEducativo && this.candidato.smartphone &&
      this.candidato.numeroDeHijos &&
      this.candidato.edadDeHijos &&
      this.candidato.ingresaTemprano &&
      this.candidato.diponibilidadDeTiempo &&
      this.candidato.tipoPersona &&
      this.candidato.experienciaEnAseo1 &&
      this.candidato.estado &&
      this.candidato.estadoCivil &&
      this.candidato.obligacionesMensuales &&
      this.candidato.tieneQueTomarAlimentador &&
      this.candidato.nombreAdicional &&
      this.candidato.parentescoAdicional &&
      this.candidato.telefonoAdicional &&
      this.candidato.estudiaActualmente &&
      this.candidato.tiempoDeExperiencia &&
      this.candidato.cotizaIndependiente &&
      this.candidato.contratoDePrestaciones &&
      this.candidato.estado &&
      this.candidato.ultimoJefe &&
      this.candidato.ultimaEmpresa &&
      this.candidato.ultimaEmpresaTelefono &&
      this.candidato.ultimaEmpresaTelefono2 &&
      this.candidato.ultimaEmpresaDuracion &&
      this.candidato.ultimaEmpresaMotivo &&
      this.candidato.ultimaEmpresaLimpiaba &&
      this.candidato.ultimaEmpresaJornada &&
      this.candidato.ultimaEmpresaJornada &&
      this.candidato.estado &&
      this.candidato.NombreJefeReferencia2 &&
      this.candidato.nombreEmpresaCasa2 &&
      this.candidato.telefono21 &&
      this.candidato.telefono22 &&
      this.candidato.tiempoTrabajado2 &&
      this.candidato.razonSalida2 &&
      this.candidato.relizabasFuncionAseo2 &&
      this.candidato.tipoDeJornadaLaboral2 &&
      this.candidato.salarioEmpresa2 &&
      this.candidato.estado &&
      this.candidato.estado &&
      // this.candidato.imgCedula &&
      // this.candidato.imgCedula2 &&
      this.candidato.estado &&
      this.candidato.formatoReferencias &&
      this.candidato.antecedentes2 &&
      this.candidato.contrato &&
      this.candidato.contrato &&
      this.candidato.contrato &&
      this.candidato.descuentoSeguridad
    ) {
      this.disponibleParaCreacion = true
    } else {
      console.log(
        this.candidato.especialidad,
        this.candidato.comoTeEnteraste,
        this.candidato.utilizaGoogleMaps,
        this.candidato.nivelEducativo,
        this.candidato.smartphone,
        this.candidato.numeroDeHijos,
        this.candidato.edadDeHijos,
        this.candidato.ingresaTemprano,
        this.candidato.diponibilidadDeTiempo,
        this.candidato.tipoPersona,
        this.candidato.experienciaEnAseo1,
        this.candidato.estado,
        this.candidato.estadoCivil,
        this.candidato.obligacionesMensuales,
        this.candidato.tieneQueTomarAlimentador,
        this.candidato.nombreAdicional,
        this.candidato.parentescoAdicional,
        this.candidato.telefonoAdicional,
        this.candidato.estudiaActualmente,
        this.candidato.tiempoDeExperiencia,
        this.candidato.cotizaIndependiente,
        this.candidato.contratoDePrestaciones,
        this.candidato.estado,
        this.candidato.ultimoJefe,
        this.candidato.ultimaEmpresa,
        this.candidato.ultimaEmpresaTelefono,
        this.candidato.ultimaEmpresaTelefono2,
        this.candidato.ultimaEmpresaDuracion,
        this.candidato.ultimaEmpresaMotivo,
        this.candidato.ultimaEmpresaLimpiaba,
        this.candidato.ultimaEmpresaJornada,
        this.candidato.ultimaEmpresaJornada,
        this.candidato.estado,
        this.candidato.NombreJefeReferencia2,
        this.candidato.nombreEmpresaCasa2,
        this.candidato.telefono21,
        this.candidato.telefono22,
        this.candidato.tiempoTrabajado2,
        this.candidato.razonSalida2,
        this.candidato.relizabasFuncionAseo2,
        this.candidato.tipoDeJornadaLaboral2,
        this.candidato.salarioEmpresa2,
        this.candidato.estado,
        this.candidato.estado,
        // this.candidato.imgCedula,
        // this.candidato.imgCedula2,
        this.candidato.estado,
        this.candidato.formatoReferencias,
        this.candidato.antecedentes2,
        this.candidato.contrato,
        this.candidato.descuentoSeguridad
      )
    }
  }

  crearServer() {

    this.preInfoServer = this.candidato
    this.agregarServer = true
    setTimeout(() => {
      this.agregarServer = false
    }, 500);
  }
}

