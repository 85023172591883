import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';


@Injectable({
  providedIn: 'root'
})

export class LocationService {

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { }

  /**
   * Function to create location in DB (Cloud Firestore)
   * @param location 
   */
  public post(location) {
    return firebase.firestore().doc('location/' + location.id).set(location);
  }

  /**
   * Function to get by id location (Cloud Firestore)
   * @param id 
   */
  public getLocation(id) {
    return firebase.firestore().collection('location').where('id', "==", id).get();
  }

    /**
   * Function to get by city location (Cloud Firestore)
   * @param id 
   */
  public getLocationByCity(name: string) {
    return firebase.firestore().collection('location').where('city', "==", name).get();
  }

  /**
   * Function to edit by id location (Cloud Firestore)
   * @param location 
   */
  public editLocation(location) {
    return firebase.firestore().collection('location').doc(location.id).set(location);
  }

  /**
   * Function to get all locations (Cloud Firestore)
   */
  public getLocations() {
    return firebase.firestore().collection('location').get();
  }

  /**
   * Function to delete by id location (Cloud Firestore)
   * @param location 
   */
  public deleteLocation(location) {
    return firebase.firestore().collection('location').doc(location.id).delete();
  }
}
