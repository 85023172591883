import { Injectable } from "@angular/core";
import * as firebase from 'firebase';
import { async } from "q";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MessagePayload } from "../models/interface/Notificaciones.interface";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService{
    messagingFirebase : firebase.messaging.Messaging;
    constructor(){
        this.messagingFirebase = firebase.messaging();
    }
    requestPermission = () =>{
        return new Promise( async (resolve, reject)=>{
            const permiso = await Notification.requestPermission();
            if(permiso === "granted"){
                const tokenFirebase = await this.messagingFirebase.getToken();
                resolve(tokenFirebase);
            }else{
                reject (new Error ("No se otorgó permiso"))
            }
        })
    }
    
    private messaginOvervable = new Observable <MessagePayload> (observe =>{
        this.messagingFirebase.onMessage(payload =>{
            observe.next(payload)
        })
    })
    receiveMessage(){
        return this.messaginOvervable;
    }
    
}