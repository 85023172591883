
  import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable()
export class ConversationServices {
    constructor(
        private angularFireAuth: AngularFireAuth,
        private db: AngularFireDatabase,
    ) { }

    getByIdConversation(uid) {
        return this.db.list('conversations/' + uid).valueChanges();
    }
    addConversation(conversation) {
        return this.db.object('conversations/' + conversation.uid + '/' + conversation.timestamp).set(conversation);
    }
    edit(message) {
        return this.db.object('/conversations/' + message.uid + '/' + message.timestamp).set(message);
    }
    getConversation(idChat) {
        return firebase.firestore().collection('/conversations/').where('id','==',idChat).get();
    }
    getConversationRealTime(idChat) {
        return firebase.firestore().collection('/conversations/').where('id','==',idChat);
    }
    getConversationCliente(idUsuarioAdmin) {
        return firebase.firestore().collection('/conversations/').where('idUsuarioAdmin','==', idUsuarioAdmin );
    }
    
    updateChat(chat) {
        console.log(chat);
        return firebase.firestore().doc('/conversations/' + chat.id).set(chat);
    }

}
