import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  constructor() {}

  public getAllLeads() {
    return firebase.firestore().collection("leadsCotizaciones").get();
  }
  
  public getLeadsRangoFecha(fecha_inicio,fecha_fin){
    return firebase.firestore().collection("leadsCotizaciones")
    .where('fechaDeCreacion', ">=", fecha_inicio)
    .where('fechaDeCreacion', "<=", fecha_fin)
    .get();
  }
  public getLeadsRangoFecha_estado(fecha_inicio,fecha_fin,estado){
    return firebase.firestore().collection("leadsCotizaciones")
    .where('fechaDeCreacion', ">=", fecha_inicio)
    .where('fechaDeCreacion', "<=", fecha_fin)
    .where('estado', "==", estado)
    .get();
  }
  public getLeadsFecha(fecha){
    return firebase.firestore().collection("leadsCotizaciones")
    .where('fechaDeCreacion', "==", fecha)
    .get();
  }

  public getLeadbyId(id){
    return firebase.firestore().collection("leadsCotizaciones")
    .where("id","==",id)
    .get();
  }

  public updateLead(lead: any) {
    return firebase
      .firestore().collection("leadsCotizaciones").doc(lead.id).update(lead);
  }

  public eliminarLeads(id) {
    return firebase.firestore().collection("leadsCotizaciones").doc(id).delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }

  public editLead(lead) {
    return firebase.firestore().collection("leadsCotizaciones").doc(lead.id).set(lead);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  postLead(lead: any) {
    return firebase.firestore().collection('leadsCotizaciones').add(Object.assign({}, lead))
      .then((ref: any) => {
        lead.id = ref.id;
        firebase.firestore().doc(`leadsCotizaciones/${ref.id}`).update({ id: ref.id });
      })
      .catch((err) => console.error('Ocurrio un error al crear el lead', err));
  }
  getLeadsBycorreo(correo: any) {
    return firebase.firestore().collection('leadsCotizaciones').where('correo','==',correo).get();
  }

  /* Leads-canada */
  getLeadsCa(){
    return firebase.firestore().collection('leadsCotizacionesCanada').get();
  }

}
