import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

import { AuthService } from "../services/auth.service";
import { take, map, tap } from 'rxjs/operators';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService, private router: Router, private location: Location
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.authService.user$.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['login']);
        } else {
          const isLocalhost = this.location.isCurrentPathEqualTo('localhost');
          if (this.authService.firstState) {
            this.authService.firstState = false
            if (window.location.hostname != 'localhost') this.router.navigate(['dashboard/metricas']);
          }
          return true;
        }
      })
    );
  }
}

