import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-metrics',
  templateUrl: './list-metrics.component.html',
  styleUrls: ['./list-metrics.component.scss']
})
export class ListMetricsComponent implements OnInit {
  
  @Input() data: Array<object>=[];
  @Input() categoria: string;
  @Output() changeChart = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    console.log("llega esto", this.data,this.categoria);
  }

  public goChangeChart(item){
    this.changeChart.emit(item.name);
  }
}
