import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalDataSource } from 'ng2-smart-table';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { CuponService } from '../../services/cupon.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cupones',
  templateUrl: './cupones.component.html',
  styleUrls: ['./cupones.component.scss']
})
export class CuponesComponent implements OnInit {

  /*  Para el modal */
  @ViewChild('mdlCrear', { static: true }) mdlCrear: ElementRef;
  @ViewChild('mdlEdit', { static: true }) mdlEdit: ElementRef;
  @ViewChild('mdlDetail', { static: true }) mdlDetail: ElementRef;

  frmCupon: FormGroup;
  submitted = false;
  spinerGuardar = false;

  cupones = [];
  frmCuponEdit: FormGroup;
  submitedEdit = false;
  spinerEdit = false;
  toggle = false;

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [],
    },
    columns: {
      nombre: { title: 'Nombre' },
      valor: { title: 'Valor' },
      porcentaje: { title: 'porcentaje' },
      aplicablePara: { title: 'Aplicable' },
      fechaCreacion: { title: 'Fecha de Creación'},
      fechaExpiracion: { title: 'Fecha de expiracion'},
      clientesArray: { title: 'Redimidos', 
      valuePrepareFunction: (value) => {
        return value.length;
      },
    },
    }
  };
  cuponInDetail: any;
  usuarioEnSecion: any = {rol:''};

  constructor(
    private toastrService: NbToastrService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private cuponService: CuponService,
    private dialogService: NbDialogService,
  ) {
  this.usuarioEnSecion =  this.authService.storeGetUserData('usuario')
    if(this.usuarioEnSecion.rol == 'administrador'){
      this.settings.actions.custom = [    
        { name: 'delete', title: '<i class="nb-trash"></i>' },
        { name: 'detail', title: '<i class="nb-search"></i>' }
      ]
    }

    this.toggle === false
    this.frmCupon = this.fb.group({
      valor: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      fechaExpiracion: ['',[Validators.required]],
      porcentaje: ['',[Validators.required]],
      aplicablePara: ['',[Validators.required]]
    });

    this.frmCuponEdit = this.fb.group({
      id: ['', []],
      nombre: ['', [Validators.required]],
      valor: ['', [Validators.required]],
      porcentaje: ['', [Validators.required]],
    });

  }

  ngOnInit() {
    this.ObtenerCupones();
  }

  public escoger(){
    var element = <HTMLInputElement> document.getElementById("dn");
    var isChecked = element.checked;
    if( this.toggle === false){
      this.toggle = true;
      console.log(this.toggle)

    } else if ( this.toggle === true) {
      this.toggle = false;
      console.log(this.toggle)

    }
  }
  public ObtenerCupones() {
    let cupones = []
    this.cuponService.getCupones().then(value => {
      value.forEach(doc => {
        cupones.push(doc.data());
      })
      this.cupones = _.orderBy(cupones, ['fechaCreacion'], ['desc']);
    });
  }

  public CrearCupon() {
    // @ts-ignore
    this.dialogService.open(this.mdlCrear, { context: 'this is some additional data passed to dialog' });
  }

  get valoresCrear() {
    return this.frmCupon.controls;
  }
  get valoresEditar() {
    return this.frmCuponEdit.controls;
  }

  public guardarCupon() {
    if (this.frmCupon.value.valor<1 ) {
      this.frmCupon.value.valor = 0;
    }

    if (this.frmCupon.value.porcentaje<1 ) {
      this.frmCupon.value.porcentaje = 0;
    }
    
    this.spinerGuardar = true;
    const datoCupon = this.frmCupon.value;
    datoCupon.fechaCreacion = moment().format('YYYY-MM-DD');
    datoCupon.clientesArray = [];
    const a = this.cupones.filter(cupon=> cupon.nombre == datoCupon.nombre && cupon.aplicablePara == datoCupon.aplicablePara);
    console.log(a)
    if(a.length == 0){
      this.cuponService.guardarCupon(datoCupon).then(()=>{
        this.frmCupon.reset();
        this.submitted = false;
        this.spinerGuardar = false;
        this.showToast('top-right', 'success', 'Éxito!', 'Se creo con éxito!');
        let element: HTMLElement = document.getElementById('btn-close-save') as HTMLElement;
        element.click();
        // this.ObtenerCupones();
        return true;
      }).catch(err=>{
        console.log('ocurrio un error',err)
        return false;
      });
    }else{
      alert('El cupon ya existe')
      this.spinerGuardar = false;
    }
  }
  
  public updateCupon() {
    this.submitedEdit = true;
    let cupon = this.frmCuponEdit.value;
    if (this.frmCuponEdit.invalid) {
      return false; 
    }
    this.spinerEdit = true;
    this.cuponService.updateCupon(cupon);
    this.frmCuponEdit.reset();
    this.submitedEdit = false;
    this.spinerEdit = false;
    this.showToast('top-right', 'success', 'Éxito!', 'Se modifico con el registro!');
    const element: HTMLElement = document.getElementById('btn-close-edit') as HTMLElement;
    element.click();
    // this.cupones=[{}];
    // this.ObtenerCupones();
    return true;
  }

  public onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.goToEditarCupon(this.mdlEdit, event.data);
        break;
      case 'detail':
        this.goToDetailCupon(this.mdlDetail,event.data);
        break;
      case 'delete':
        this.eliminarCupon(event.data);
        break;
    }
  }

  public goToEditarCupon(dialog, data) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
  public goToDetailCupon(dialog, data) {
    this.cuponInDetail = data
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  public eliminarCupon(data) {
    this.cuponService.delateCupon(data.id).then(()=>{
      alert('cupon eliminado')
    })
  }


  public showToast(position, status, titulo, mensaje) {
    this.toastrService.show(
      mensaje,
      titulo,
      { position, status });
  }



  public openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
}
