import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class PriceService {

  database =  firebase.firestore().collection('precios/')
  constructor(
  ) { }
    
  get(){
    return this.database.get()
  }

  getSubColecction(doc,subcolecction){
    return this.database.doc(doc).collection(subcolecction).get();
  }

  getspecificField(field, data){
    return this.database.where(field,'==',data).get()
  }

  update(info){
    return this.database.doc(info.id).update(info)
  }
  updateSubColecction(doc,info){
    return this.database.doc(doc).collection('valores').doc(info.id).update(info)
  }

  geForTypeHours(type: string,hours: string){
    return this.database.where('tipoAgendamiento','==',type).where('horas','==',hours).get()
  }

  addNewPrice(info){
    return this.database.add(info).then(res=>{
      res.update({id: res.id});
    })
  }

}