import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { LocalDataSource } from "ng2-smart-table";
import * as _ from "lodash";
import * as moment from "moment";
import * as firebase from "firebase";
// Interface
import { Gps } from "src/app/models/interface/Gps.interface";
// Services
import { AuthService } from "src/app/services/auth.service";
import { GoogleService } from "src/app/services/google.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { funcionesUsuario } from "src/app/services/funcionesUsuario";

import {} from "src/app/utility/dataInfo";
import { User } from "src/app/models/User.model";
import Swal, { SweetAlertType } from "sweetalert2";
import { setClient } from "src/app/utility/settings.table";
import { LocationService } from "src/app/services/location.service";
import { CityInterface } from "src/app/models/interface/CityInterface";
import { localidadesColombia, departamentos } from "src/app/utility/country";
import { Suburbsinterface } from "src/app/models/interface/Suburbsinterface";
import { DepartmentsInterface } from "src/app/models/interface/DepartmentsInterface";
import { Neighborhoodinterface } from "src/app/models/interface/Neighborhoodinterface";
import {
  Origin,
  Destination,
  OriginDefault,
  DestinationDefault,
  ResetClientInterface,
  MarkerOptions,
  Avatar,
  CardinalPoints,
  ClientType,
  Genders,
  PropertiesTypeList,
  States,
  TypeDocuments,
} from "src/app/utility/dataInfo";
import { JSONUsersLocalDataSource } from "src/app/models/interface/LocalDataSouce/UsersStatus";
import { Helper } from "src/app/utility/helper";
import { ServicioService } from "src/app/services/servicio.service";
import {PlanService} from "../../services/plan.service"

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent implements OnInit {
  /*  Para el modal */
  tipoModal = "";
  verModalClientes = false;
  clienteEnEdicion: any;

  datosEdit: User = ResetClientInterface;
  datosUserReset: User;
  settings = {
    selectMode: "multi",
    actions: { ...setClient.actions },
    columns: {
      ...setClient.columns,
    },
  };
  settingsPlanes = {
    selectMode: "multi",
    actions: { ...setClient.actions },
    columns: {
      fechaInicio:      { width:'10%', title: 'Fecha inicio' },
      fechaVencimiento: { width:'20%', title: 'Fecha de vencimiento' },
      usuario:          { width:'10%', title: 'Usuario' },
      plazoDePago:      { with: '10%', title: "plazo de pago"},
      fechaLimitePago:  { with: '10%', title: "Limite de pago"},
      estadoPago:       { width:'10%', title: 'Estado Pago' },
      estado:           { width:'10%', title: 'Estado' },
      serverName:       { width:'10%', title: 'Server'},
      horasDeServicio:  { width:'10%', title: 'Duración' },
      cantidadServicios:{ width:'10%', title: 'Cantidad' },
      formaDePago:      { width:'10%', title: 'Forma de Pago' },
      numeroFactura:    { width:'10%', title: 'Numero de factura' },
      fechaCreacion:    { width:'10%', title: 'Fecha creacion' },
      id:               {width:'10%', title: 'id'},
    },
  };
  settingsElo = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [{ name: "detalle", title: '<i class="nb-search"></i>' }],
    },
    columns: {
      id: { title: "idUser" },
      nombre: { title: "nombre" },
      apellido: { title: "apellido" },
      email: { title: "email" },
    },
  };


 
  public idLocation: string = "";

  public tipoPropiedades: any = PropertiesTypeList;
  public localidades: any = localidadesColombia;
  public locationDeparments: Array<DepartmentsInterface> = [];
  public locationCities: Array<CityInterface> = [];
  public locationSuburbs: Array<Suburbsinterface> = [];
  public locationNeighborhood: Array<Neighborhoodinterface> = [];
  //public kitObsequiado = false;

  public usuarios: any[];
  public usuarioExcel: any = [];
  public unitarioExcel: any = [];
  public bronceExcel: any = [];

  public tableLocalDataSource: JSONUsersLocalDataSource = {
    sourceActive: new LocalDataSource([]),
    lengthActive: 0,
    sourceInactive: new LocalDataSource([]),
    lengthInactive: 0,
  };
  public source: LocalDataSource;
  public sourceBronce: any;

  public eloCliente = "";
  dataActivosMes = [];
  public datasinServicios = [];
  public dataUnitario = [];
  public dataBronce = [];
  public dataPlata = [];
  public dataOro = [];
  public dataPlanes = [];
  public dataDiamante = [];
  public sinServicios = [];
  public unitario = [];
  public bronce = [];
  public plata = [];
  public oro = [];
  public diamante = [];
  public planes = [];
  public count = 0;
  ingresarComentarioServer: boolean = false;
  ingresarComentarioLogistica: boolean = false;
  fechaActual = moment().format("YYYY-MM-DD");
  usuarioEnSecion: any;
  planesClientes: any = [];
  public comentario = {
    name: "",
    comentario: "comentario",
    fecha: this.fechaActual,
  };
  public arrayComentario = [];
  public arrayComentarioServer = [];
  flipped: boolean;
  serverFavoritaSeleccionada: any;
  arrayServers: any = [];
  public keyword: any = "nameUser";
  urlCedula: any = "falta";
  urlRut: any = "falta";
  urlContrato: any = "falta";
  claveConfir = "";
  allClients = [];
  Spinner: boolean = true;


  constructor(
    public http: HttpClient,
    public helper: Helper,
    public googleService: GoogleService,
    public toastrService: NbToastrService,
    public router: Router,
    public dialogService: NbDialogService,
    public usuarioService: UsuarioService,
    public authService: AuthService,
    public locationService: LocationService,
    public serviciosService: ServicioService,
    public funcionesUsuario: funcionesUsuario,
    public planService: PlanService,
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }

  ngOnInit() {
    // this.getPlanes();
    this.getClients();
  }

  getPlanes(){
    this.planService.getAllPlan().then(resp=>{
      resp.docs.forEach(data =>{
        if(data.data().estado != 'Cancelado' && data.data().estado != 'Congelado' && data.data().estado != 'Completado'){
          this.planesClientes.push(data.data());
          this.dataPlanes = this.planesClientes;
        }
      })
    })
  }

  public allClientesActivos: any;
  getClients() {
    let count = 0;
    let pararFuncion = false;
    let clients: any;
    let nuncaAgendo = []
    let uinicoServicio = []
    let bronce = []
    let plata = []
    let oro = []
    let diamante = []
    let activos = [];
    let fechaInicio = moment().subtract(45,'days').format('YYYY-MM-DD') 
    let fechaFinal =  moment().format('YYYY-MM-DD')

    this.usuarioService.all().then((res) => {
      let clientActive: Array<any> = [];
      let clientInactive: Array<any> = [];
      const documentos = res.docs;

      // Iterar sobre los QueryDocumentSnapshot y obtener los datos e ID de cada documento
      for(let i = 0;i < res.size;i++){
        const client = documentos[i].data();
        if(client.direccionesArray){
          clientActive.push(client);
        }else{
          firebase.firestore().collection('users').doc(client.id).update({direccionesArray:  []}).then(()=>console.log('U'))
        }
      }

      // res.docs.forEach( (data,index) => {
      //   const element = data.data()
      //   let fecha = moment(element.fecha).format("YYYY-MM-DD");
      //   element.fecha = fecha;y0-=====================0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-
      //   if(element.estado == "activo"){
      //     clientActive.push(element)
      //   }else if(element.estado == "desactivado"){
      //     clientInactive.push(element)
      //   }
        
      //   // // Usuarios con servicio en ultimos 45 dias
      //   // if(element.fechaUltimoServicio > moment().subtract(45,'days').format("YYYY-MM-DD")){
      //   //   activos.push(element)
      //   // }
      //   // // Separacion por servicios agendados
      //   // if(element.numeroDeServicios == 0){
      //   //   element.elo = 'nunca agendo'
      //   //   nuncaAgendo.push(element)
      //   // }else if(element.numeroDeServicios == 1){
      //   //   element.elo = 'unitario'
      //   //   uinicoServicio.push(element)
      //   // }else if(element.numeroDeServicios >= 2 && element.numeroDeServicios <= 20 && element.fechaUltimoServicio > moment().subtract(45,'days').format("YYYY-MM-DD")){
      //   //   element.elo = 'bronce'
      //   //   bronce.push(element)
      //   // }else if(element.numeroDeServicios >= 21 && element.numeroDeServicios <= 50 && element.fechaUltimoServicio > moment().subtract(45,'days').format("YYYY-MM-DD")){
      //   //   element.elo = 'plata'
      //   //   plata.push(element)
      //   // }else if(element.numeroDeServicios == 51 && element.numeroDeServicios <= 75 && element.fechaUltimoServicio > moment().subtract(45,'days').format("YYYY-MM-DD")){
      //   //   element.elo = 'oro'
      //   //   oro.push(element)
      //   // }else if(element.numeroDeServicios >= 75 && element.fechaUltimoServicio > moment().subtract(45,'days').format("YYYY-MM-DD")){
      //   //   element.elo = 'diamante';
      //   //   diamante.push(element)
      //   // }     

        this.allClients = clientActive;
        // this.usuarioExcel.push({
        //   Nombre: element.name,
        //   Apellido: element.apellido,
        //   Email: element.email,
        //   Telefono: element.telefono,
        //   Dirección: element.direccion,
        //   Documento: element.documento,
        //   TipoDeCliente: element.elo,
        //   id: element.id,
        //   estadoPlanes: "",
        //   Estado: element.estado,
        //   tipoDeEspacio: element.tipo,
          
        // });
        // if(index == (res.docs.length - 1)){
        //   // this.dataBronce = bronce;
        //   // this.dataPlata = plata;
        //   // this.dataOro = oro;
        //   // this.dataDiamante = diamante;
        //   // this.datasinServicios = nuncaAgendo;
        //   // this.dataUnitario = uinicoServicio;
        //   // this.dataActivosMes = activos;
        //   this.tableLocalDataSource = {
        //     sourceActive: this.helper.setLocalDataSource(clientActive, "name"),
        //     lengthActive: clientActive.length,
        //     sourceInactive: this.helper.setLocalDataSource(
        //       clientInactive,
        //       "name"
        //     ),
        //     lengthInactive: clientInactive.length,
        //   };
        //   this.Spinner = false;
        // }

        this.tableLocalDataSource = {
          sourceActive: this.helper.setLocalDataSource(clientActive, "name"),
          lengthActive: clientActive.length,
          sourceInactive: this.helper.setLocalDataSource(
            clientInactive,
            "name"
          ),
          lengthInactive: clientInactive.length,
        };
        this.Spinner = false;
      });
    // });
  }

  arrayDepartamentos: any = departamentos;
  arrayCiudades: any;

  nuevaDireccion = {
    country: "Colombia",
    nombreDireccion: "Mi casa",
    department: "",
    cities: "",
    neighborhood: "",
    direccion: "",
    observaciones: "",
    lat: 0,
    lng: 0,
    tipoPropiedad: "",
    idLocation: "",
  };



  getClientesApp(){
    this.serviciosService.getClientesApp().then(res=>{
      console.log(res.docs.length);
    })
    this.allClients.forEach(usuario=>{
      if(usuario.creado == 'app' && usuario.fecha >= '2023-01-01' && usuario.direccionesArray[0].department == 'Cundinamarca'){
      }
    })
  }

  getServicios() {
    let inicioMes = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    let contador = 0;
    this.usuarioExcel.forEach((element) => {
      let activo = false;
      let totalServicios = 0;
      this.serviciosService
        .getByUser(element.id)
        .then((value) => {
          contador++;
          // value.docs.forEach((doc) => {
          //   const servisios = doc.data();
          //   totalServicios++;
          //   if (servisios.fecha >= inicioMes) {
          //     activo = true;
          //   }
          // });
          // if (totalServicios == 0) {
          //   this.sinServicios.push(element);
          // } else if (totalServicios == 1) {
          //   this.unitario.push(element);
          // } else if (
          //   totalServicios >= 2 &&
          //   totalServicios <= 20 &&
          //   activo === true
          // ) {
          //   this.bronce.push(element);
          // } else if (
          //   totalServicios >= 21 &&
          //   totalServicios <= 50 &&
          //   activo === true
          // ) {
          //   this.plata.push(element);
          // } else if (
          //   totalServicios >= 51 &&
          //   totalServicios <= 75 &&
          //   activo === true
          // ) {
          //   this.oro.push(element);
          // } else if (totalServicios >= 76 && activo === true) {
          //   this.diamante.push(element);
          // } else {
          // }
          this.usuarioService.guardarTotalServicios(element.id,value.docs.length).then(()=>{
          })

          // if (contador <= this.usuarioExcel.length) {
          //   this.datasinServicios = _.orderBy(
          //     this.sinServicios,
          //     ["nombre"],
          //     ["desc"]
          //   );
          //   this.dataUnitario = _.orderBy(this.unitario, ["nombre"], ["desc"]);
          //   this.dataBronce = _.orderBy(this.bronce, ["nombre"], ["desc"]);
          //   this.dataPlata = _.orderBy(this.plata, ["nombre"], ["desc"]);
          //   this.dataOro = _.orderBy(this.oro, ["nombre"], ["desc"]);
          //   this.dataDiamante = _.orderBy(this.diamante, ["nombre"], ["desc"]);
          //   this.dataPlanes = _.orderBy(this.dataPlanes)
          // }
        })
        .catch((err) => {});
    });
    // console.log(element)
  }

  onUpload(event, tipo) {
    console.log(tipo);
    let file: any;
    let pictureName: any;
    let image = false;
    let nameImage: any;
    let fotoSeleccionada: any;
    console.log("files", event.target.files[0]);
    file = event.target.files[0];
    pictureName = Date.now() + file.name;
    image = true;
    if (event.target.files && file) {
      nameImage = file.name;
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>event.target).result;
        console.log("foto seeccionada", fotoSeleccionada);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log("no asa el primer if");
    }
    if (file && pictureName) {
      this.serviciosService
        .putPicture(pictureName, file)
        .then((value) => {
          console.log("value", value);
          if (value.state == "success") {
            this.serviciosService.getURL(pictureName).subscribe((url) => {
              if (tipo == "contrato") {
                this.urlContrato = url;
                console.log("tipo contrato", this.urlContrato);
              } else if (tipo == "rut") {
                this.urlRut = url;
                console.log("tipo rut", this.urlRut);
              } else if (tipo == "cedula") {
                this.urlCedula = url;
                console.log("tipo cedula", this.urlCedula);
              } else {
                console.log(tipo);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no asa el segundo if");
    }
  }

  onCustom(event) {
    this.idLocation = "";
    switch (event.action) {
      case "editar":
        this.clienteEnEdicion = event.data;
        this.tipoModal = 'editar'
        this.mdlClientes()
        //this.kitObsequiado = event.data.kitObsequio;
        break;
      case "delete":
        this.eliminarUsuario(event.data);
        break;
      case "detalle":
        this.verUsuario(event.data);
        break;
    }
  }

  // pasarDireccionesAlNuevoFormato(){
  //   console.log(this.allClientesActivos.length)
  //   this.allClientesActivos.forEach(element => {
  //     if(element.direccionesArray){
  //     }else{
  //       if(element.country != undefined && element.gps != undefined && element.department != undefined && element.idLocation != undefined && element.neighborhood != undefined && element.observaciones != undefined &&  element.tipoPropiedad && element.idLocation){
  //         console.log("Cliente",element)
  //         let nuevaDireccion = {
  //           country: element.country,
  //           nombreDireccion: "Mi casa",
  //           department: element.department,
  //           cities:  element.cities,
  //           neighborhood:  element.neighborhood,
  //           direccion:  element.direccion,
  //           observaciones:  element.observaciones,
  //           lat: element.gps.lat,
  //           lng: element.gps.lng,
  //           tipoPropiedad:  element.tipoPropiedad,
  //           idLocation: element.idLocation,
  //         }
  //         element.direccionesArray = []
  //         element.direccionesArray.push(nuevaDireccion);
  //         console.log(element.direccionesArray);
  //         this.usuarioService.updateClient(element).then(res=>{
  //          console.log("direccion actualizada")
  //         })
  //       }else{
  //         this.serviciosService.getServiciosUsuario(element.id).then(res=>{
  //           console.log(res.docs.length);
  //           if(res.docs.length > 0 ){
  //             res.docs.forEach(data => {
  //               if(data.data().fecha > "2021-01-01"){
  //                 console.log(data.data().fecha)
  //               }
  //             });
  //           }
  //         })

  //       }
  //     }
  //   });
  // }

  notUn(info: any) {
    return !this.helper.notUndefined(info);
  }

  public notSpace(data) {
    return !data && data == "" && data == " ";
  }

  public formatDate(date) {
    return typeof date == "string"
      ? this.helper.dateDefaultNotHour(date)
      : typeof date == "number"
      ? this.helper.dateDefaultNotHour(date)
      : "";
  }

  /**
   *
   * @param data
   *
   */
  eliminarUsuario(data) {
    Swal.fire({
      title: "Estas seguro(a)?",
      text: "Esta cuenta será desactivada de inmediatamento!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, desactivar ahora!",
    }).then((result) => {
      if (result.value) {
        this.usuarioService.delete(data.id)
          .then(() => {
            Swal.fire("Éxito!", "Cliente desactivado con éxito", "success");
          })
          .catch(() => {
            Swal.fire(
              "Error!",
              "Intentó en desactivar cliente fallido",
              "error"
            );
          });
      }
    });
  }

  verUsuario(obj) {
    const id  = JSON.stringify(obj.id);
    this.router.navigate(["dashboard/ver-usuario", id]);
  }

  export(tipo) {
    if (tipo == "cotizantes") {
      this.usuarioService.exportAsExcelFile(
        this.datasinServicios,
        "Usuarios sin servicios"
      );
    } else if (tipo == "unitarios") {
      this.usuarioService.exportAsExcelFile(
        this.dataUnitario,
        "Usuarios unitarios"
      );
    } else if (tipo == "bronces") {
      this.usuarioService.exportAsExcelFile(
        this.dataBronce,
        "Usuarios bronces"
      );
    } else if (tipo == "platas") {
      this.usuarioService.exportAsExcelFile(this.dataPlata, "Usuario platas");
    } else if (tipo == "oros") {
      this.usuarioService.exportAsExcelFile(this.dataOro, " oros");
    } else if (tipo == "diamantes") {
      this.usuarioService.exportAsExcelFile(
        this.dataDiamante,
        "Usuario diamates"
      );
    } else {
      this.usuarioExcel.map(info =>{
        
        let estadoPlanes = '';
                
        let planes = this.dataPlanes.filter(plan => plan.userid == info.id);
/*         console.log(planes); */
        if(planes.length > 0){
          for(let plan of planes){
            info.estadoPlanes = info.estadoPlanes + plan.estado
          }
        }
      })
      this.usuarioService.exportAsExcelFile(this.usuarioExcel, "Usuario");
    }
  }

  public usersSaved: any = []
  exportarParaEcxel(){
    this.allClients.forEach(client => {
      let servicioExport ={
        nombre: client.name + " " + client.apellido,
        id: client.id,
        departamento: typeof client !== 'undefined' && 
                      typeof client.direccionesArray !== 'undefined' && 
                      typeof client.direccionesArray[0] !== 'undefined' && 
                      typeof client.direccionesArray[0].department !== 'undefined'
                      ? client.direccionesArray[0].department
                      : "no",
        fechaCreacion: client.fecha,
        creado: client.creado ? client.creado : "admin",
        telefono: client.telefono,
        email: client.email,
        typoDocumento: client.typoDocumento,
        documento: client.documento,
        'Fecha ultimo servicio': client.fechaUltimoServicio
      }
      this.usersSaved.push(servicioExport)
      if(this.usersSaved.length == this.allClients.length ){
        this.usuarioService.exportAsExcelFile(this.usersSaved, "Users");
      }
    });
  }


  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  resetLocation(type) {
    if (type == "departments") {
      this.locationDeparments = [];
      this.locationCities = [];
      this.locationSuburbs = [];
      this.locationNeighborhood = [];

      if (this.datosEdit) {
        this.datosEdit.department = "";
        this.datosEdit.cities = "";
        this.datosEdit.suburbs = "";
        this.datosEdit.neighborhood = "";
      }
    } else if (type == "cities") {
      this.locationSuburbs = [];
      this.locationNeighborhood = [];
      if (this.datosEdit) {
        this.datosEdit.suburbs = "";
        this.datosEdit.neighborhood = "";
      }
    } else if (type == "suburbs") {
      this.locationNeighborhood = [];
      if (this.datosEdit) {
        this.datosEdit.neighborhood = "";
      }
    }

    console.log({ edit: this.datosEdit });
  }

  toggleView() {
    this.flipped = !this.flipped;
  }

  mapearDepartametosRegstados(){
    let map: any = {
      sinDirecciones: 0,
      sinVariable: 0
    }
  this.allClients.forEach(res=>{
    if(res.direccionesArray !== undefined){
      if(res.direccionesArray.length > 0){
        if(map[res.direccionesArray[0].department] == undefined){

          map[res.direccionesArray[0].department] = 1
        } else{
          map[res.direccionesArray[0].department] ++
        }
      }else{
        map.sinDirecciones++
      }
    }else{
      map.sinVariable ++
    }
  })
  console.log(map)
  }

  mdlClientes(){
    this.verModalClientes = false
    setTimeout(()=>{
      this.verModalClientes = true
    },200)
  }


  ajustardirecciones(){
    console.log(this.arrayDepartamentos);
    this.allClients.forEach((cliente:any)=>{
      // console.log("------------",cliente.direccionesArray)
      if(cliente.direccionesArray != undefined){

        if(cliente.direccionesArray[0].lat != undefined && cliente.direccionesArray[0].lat != ''){

          console.log("####",cliente.direccionesArray[0]);

          if(cliente.direccionesArray[0].cities == "Bogotá D.C."){
            cliente.direccionesArray[0].cities = "Bogotá, D.C.";
            cliente.direccionesArray[0].department = "Bogotá D.C.";
          }
          if(cliente.direccionesArray[0].cities == "Medellin"){
            cliente.direccionesArray[0].cities = "Medellín";
          }

          console.log(cliente.direccionesArray[0].department,'---',cliente.direccionesArray[0].cities)
          // let index = this.arrayDepartamentos.findIndex((data) => data.name == cliente.direccionesArray[0].department);
          // cliente.direccionesArray[0].department_code = this.arrayDepartamentos[index].code;
          // console.log(cliente.direccionesArray);
  
          // this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
          // let index2 = this.arrayCiudades.findIndex((data) => data.name == cliente.direccionesArray[0].cities);
          // cliente.direccionesArray[0].cities_code = this.arrayCiudades[index2].code;
          // console.log("######",cliente.direccionesArray);

          this.usuarioService.updateClient(cliente).then(()=>console.log("A"));
          

          // if(cliente.direccionesArray[0].department != undefined && cliente.direccionesArray[0].department != '' && cliente.direccionesArray[0].cities !=  undefined ){
            // if(cliente.direccionesArray[0].department == "app" && cliente.direccionesArray[0].cities == "Medellin") {
            //   cliente.direccionesArray[0].department = "Antioquia"
            // }
            // if(cliente.direccionesArray[0].department == "Medellin"){
            //   cliente.direccionesArray[0].department = "Antioquia"
            // }
            // if(cliente.direccionesArray[0].cities == "Medellin"){
            //   cliente.direccionesArray[0].department = "Antioquia"
            // }
            // if(cliente.direccionesArray[0].department == "Bogota D.C"){
            //   cliente.direccionesArray[0].department = "Bogotá D.C."
            // }  
            // if(cliente.direccionesArray[0].department == "app" && (cliente.direccionesArray[0].cities == "Bogotá" || cliente.direccionesArray[0].cities == "Bogota D.C" || cliente.direccionesArray[0].cities == "Bogotá D.C." )) {
            //   cliente.direccionesArray[0].department = "Bogotá D.C."
            //   cliente.direccionesArray[0].cities = "Bogotá D.C."
            // }else if(cliente.direccionesArray[0].cities == "Bogotá" || cliente.direccionesArray[0].cities == "Bogota D.C" || cliente.direccionesArray[0].cities == "Bogotá D.C."){
            //   cliente.direccionesArray[0].department = "Bogotá D.C."
            //   cliente.direccionesArray[0].cities = "Bogotá D.C."
            // }
  
  
          //   if(cliente.direccionesArray[0].department == "app" && (cliente.direccionesArray[0].cities == "Chia" || cliente.direccionesArray[0].cities == "Cota" || cliente.direccionesArray[0].cities == "Cajica")) {
          //     cliente.direccionesArray[0].department = "Cundinamarca"
          //   }
  
          //   console.log(cliente.direccionesArray[0].department)
          //   let index = this.arrayDepartamentos.findIndex((data) => data.name == cliente.direccionesArray[0].department);
          //   cliente.direccionesArray[0].department_code = this.arrayDepartamentos[index].code;
          //   console.log(cliente.direccionesArray);
    
          //   this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
          //   let index2 = this.arrayCiudades.findIndex((data) => data.name == cliente.direccionesArray[0].cities);
          //   cliente.direccionesArray[0].cities_code = this.arrayCiudades[index2].code;
          //   console.log("######",cliente.direccionesArray);
  
          //   this.usuarioService.updateClient(cliente).then(()=>console.log("A"));
          // }

        }
        
      }
    })
  }


  ticketPromedio(){
    let datos1:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos2:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos3:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos4:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos5:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos6:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos7:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos8:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    let datos9:any = [{numServicios:0,total:0},{numServicios:0,total:0}]
    this.serviciosService.getByStateInDateRange("Terminado",'2022-01-01','2022-09-30').then(res=>{
      res.docs.forEach((data,index)=>{
        let servicio = data.data();
        let a = servicio.tipoServicio == 'Plan Mensual' ? 0 : 1;
        if(moment(servicio.fecha).format('MM') == '01'){
          datos1[a].numServicios ++
          datos1[a].total += servicio.precio
          datos1[a].ticket = datos1[a].total / datos1[a].numServicios 

        }else if(moment(servicio.fecha).format('MM') == '02'){
          datos2[a].numServicios ++
          datos2[a].total += servicio.precio
          datos2[a].ticket = datos2[a].total / datos2[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '03'){
          datos3[a].numServicios ++
          datos3[a].total += servicio.precio
          datos3[a].ticket = datos3[a].total / datos3[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '04'){
          datos4[a].numServicios ++
          datos4[a].total += servicio.precio
          datos4[a].ticket = datos4[a].total / datos4[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '05'){
          datos5[a].numServicios ++
          datos5[a].total += servicio.precio
          datos5[a].ticket = datos5[a].total / datos5[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '06'){
          datos6[a].numServicios ++
          datos6[a].total += servicio.precio
          datos6[a].ticket = datos6[a].total / datos6[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '07'){
          datos7[a].numServicios ++
          datos7[a].total += servicio.precio
          datos7[a].ticket = datos7[a].total / datos7[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '08'){
          datos8[a].numServicios ++
          datos8[a].total += servicio.precio
          datos8[a].ticket = datos8[a].total / datos8[a].numServicios 
        }else if(moment(servicio.fecha).format('MM') == '09'){
          datos8[a].numServicios ++
          datos8[a].total += servicio.precio
          datos9[a].ticket = datos9[a].total / datos9[a].numServicios 
        }
          
        if(index == res.docs.length-1){
        }
      })
    })
  }

  cambiarAuthInfoUsuario(){
    let info ={
      email: '',
      password: '123456',
    }
    let newInfo = {
      email: '',
      password: '123456',
    }
    this.authService.changeEmailUser(info,newInfo)
  }

  async revisarRecurrenciaUsuariosPorLotes(){
    console.log('Entro en revisarRecurrenciaUsuariosPorLotes')
    this.allClients
    let tamanoLote = 600 
    let numeroLotes = Math.ceil(this.allClients.length / tamanoLote) 
    let listaFinal = []
    console.log(numeroLotes);
    for (let i = 0; i < numeroLotes; i++) { 
      let inicio = i * tamanoLote 
      let fin = inicio + tamanoLote 
      let lote = this.allClients.slice(inicio, fin) 
      console.log(inicio,fin)
      let resultadoLote = await this.revisarRecurrenciaUsuarios(lote)
      listaFinal = listaFinal.concat(resultadoLote)
    }
    this.usuarioService.exportAsExcelFile( listaFinal,"Usuarios");
  }

  async revisarRecurrenciaUsuarios(clientesFiltrados){

    let promises = clientesFiltrados.map(async (cliente) => {
      let info: any = await this.getServiciosUsuario(cliente.id);
      let infoPlanes: any = await this.getPlanesUsuario(cliente.id);
      return {
        name: cliente.name,
        id: cliente.id,
        primerServicio: info.primerServicio == null ? moment(cliente.fecha).format('YYYY-MM-DD') : info.primerServicio,
        ultimoServicio: info.ultimoServicio == null ? moment(cliente.fecha).format('YYYY-MM-DD') : info.ultimoServicio,
        totalServicios: info.totalServicios,

        // estadoPlan: infoPlanes.estadoPlan,
        // totalPlanes: infoPlanes.totalPlanes,

        departamento:
        typeof cliente.direccionesArray !== 'undefined' && 
        typeof cliente.direccionesArray[0] !== 'undefined' && 
        typeof cliente.direccionesArray[0].department !== 'undefined'
        ? cliente.direccionesArray[0].department
        : "no", 
        fechaCreacion: cliente.fecha,
        creado: cliente.creado ? cliente.creado : "admin",
        telefono: cliente.telefono,
        email: cliente.email,
      };
    });
  
    let infoExport = await Promise.all(promises);
    // console.log(JSON.stringify(infoExport));
    return infoExport

  }

  getServiciosUsuario(userId){
    return new Promise(async resolve=>{
      let info: any = {}
      const ref = firebase.firestore().collection('servicios/')
      .where('userid', '==', userId)
      .where('estado','==','Terminado')
      .orderBy('fecha')
 
      await ref.get().then(async (querySnapshot) => {
        console.log('Servicios',querySnapshot.size)
        info.totalServicios = querySnapshot.size;
        if(querySnapshot.size > 0){
          info.primerServicio = await querySnapshot.docs[0].data().fecha;
          info.ultimoServicio = await querySnapshot.docs[querySnapshot.docs.length-1].data().fecha;
        }else{
          info.primerServicio = null;
          info.ultimoServicio = null;
        }
        resolve(info)
      });
    })
  }

  getPlanesUsuario(userId){
    return new Promise(resolve=>{
      let info: any = {}
      const ref = firebase.firestore().collection('plan/')
      .where('userid', '==', userId)
 
      ref.get().then(async (querySnapshot) => {
        console.log('Planes',querySnapshot.size)
        info.totalPlanes = querySnapshot.size;
        if(querySnapshot.size == 1){
          info.estadoPlan = await querySnapshot.docs[querySnapshot.docs.length-1].data().estado;
        }else if(querySnapshot.size > 1){
          querySnapshot.docs.map(async res=>{
            info.estadoPlan = `${await res.data().estado} ${info.estadosPlanes}`;
          })
        }else{
          info.estadoPlan = 'No tiene planes'
        }
        resolve(info)
      });
    })
  }

  

  // async updateAddres(){
  //   for(let a = 3999;a < this.allClients.length;a++){
  //     const usuario =  this.allClients[a];
  //     if(usuario.direccionesArray){
  //       for(let i = 0; i < usuario.direccionesArray.length; i++){
  //         if(usuario.direccionesArray[i].lat && (!usuario.direccionesArray[i].cities || usuario.direccionesArray[i].cities == '')){
  //           usuario.direccionesArray[i] = await this.getLocation(usuario.direccionesArray[i]);
  //           console.log(usuario.direccionesArray[i].cities,usuario.direccionesArray[i].department);
  //         }
  //       }
  //       firebase.firestore().collection('users').doc(usuario.id).update({direccionesArray:  usuario.direccionesArray}).then(()=>console.log('U'))
  //     }else{
  //       console.log('Sin direcciones')
  //     }
  //   }
  // }

  // getLocation(info) {
  //   return new Promise(resolve=>{
  //     const geocodingAPI = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${info.lat},${info.lng}&key=AIzaSyDI4W-h2XGvY8tD2KdbnDOa9O1diULjh3g`;
  //     let a = this.http.get<any>(geocodingAPI).subscribe((response) => {
  //       a.unsubscribe();
  //       if (response.status === 'OK') {
  //         // console.log(response.results[0].address_components)
  //         for (const component of response.results[0].address_components) {
  //           if (component.types.includes('locality')) {
  //             info.cities = component.long_name;
  //           }else if(component.types.includes('administrative_area_level_2')){
  //             info.cities = component.long_name;
  //           }else if(component.types.includes('administrative_area_level_1')){
  //             info.department = component.long_name;
  //           }
  //         }
  //         // Verifica si la ciudad no es Bogotá ni Medellín
  //         if (info.cities == 'Bogotá'){
  //           info.department = 'Bogotá D.C.'
  //           info.cities = 'Bogotá, D.C.';
  //         }
  //         return resolve(info);
  //       } else {
  //         console.log('Error al obtener la ubicación: ' + response.status);
  //         return resolve(info);
  //       }
  //     },
  //       (error) => {
  //         console.log('Error al obtener la ubicación: ' + error);
  //         return resolve(info);
  //       }
  //     );
  //   })
  // }
}
