import { environment } from './config';
const Novedades = [
    {
        "name": "Logistica",
        "tipo": [
            {
                "name": "Hora extra",
                "area": "Comercial",
                'valor': 0,
                'estado': 'Pendiente',
                'intencion': 'suma',
            },
            {
                "name": "Disponibilidad",
                "area": "Contabilidad",
                'valor': 10000,
                'estado': 'Solucionada',
                'intencion': 'suma',
            },
            {
                "name": "Multa",
                "area": "Contabilidad",  
                'valor': 35000,
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "Supernumeraria",
                "area": "Contabilidad",
                'valor': 24000,
                'estado': 'Solucionada',
                'intencion': 'suma',
            },
            {
                "name": "Mala calidad",
                "area": "Contabilidad",
                'valor': 0,
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "Adelantos",
                "area": "Contabilidad",
                'valor': 0,
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
        ]
    },
    {
        "name": "Server directa",
        "tipo": [
            {
                "name": "No trabajo este dia",
                "area": "Contabilidad",
                'valor': (environment.basic_salary/30) + (environment.transport_allowance/30),
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "No trabajo medio dia",
                "area": "Contabilidad",
                'valor': ((environment.basic_salary/30)/2) + (environment.transport_allowance/30),
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "Transporte adicional",
                "area": "Contabilidad",
                'valor': 7000,
                'estado': 'Solucionada',
                'intencion': 'suma',
            },
            {
                "name": "Cliente cancela",
                "area": "Contabilidad",
                'valor': 0,
                'estado': 'Solucionada',
                'intencion': 'suma'
            },
            {
                "name": "Mala calidad",
                "area": "Contabilidad",
                'valor': 0,
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "Abonos",
                "area": "Contabilidad",
                'valor': 0,
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
            {
                "name": "Bono felicitaciones",
                "area": "Contabilidad",
                'valor': 20000,
                'estado': 'Solucionada',
                'intencion': 'suma',
            },
            {
                "name": "Incapacidad",
                "area": "Contabilidad",
                "subCategoria": "licencia",
                'valor': 0,// falta calcular, es el el valor del dia * 66,6% es loque se paga y resta el resto 
                'estado': 'Solucionada',
                'intencion': 'resta', 
            },
            {
                "name": "Licencia de luto",
                "area": "Contabilidad",
                "subCategoria": "licencia",
                'valor': 0, 
                'estado': 'Solucionada',
                'intencion': 'resta',
            },
        ]
    },
    {
        "name": "Emergencias",
        "tipo": [
            {
                'name': 'Accidentes',
                'area': 'Recursos',
                'valor': 0,
                'urgencia': 'alta',
                'estado': 'Pendiente',
                'intencion': 'resta',
            },
            {
                'name': 'Daños',
                'area': 'Recursos',
                'valor': 0,
                'urgencia': 'alta',
                'estado': 'Pendiente',
                'intencion': 'resta',
            },
            {
                'name': 'Robo o perdida',
                'area': 'Recursos',
                'valor': 0,
                'urgencia': 'alta',
                'estado': 'Pendiente',
                'intencion': 'resta',
            },
            {
                'name': 'Otro',
                'area': 'Recursos',
                'valor': 0,
                'urgencia': 'baja',
                'estado': 'Pendiente',
                'intencion': 'resta',
            },
        ]

    },
    // {
    //     "name": "Servers",
    //     "tipo": [
    //         {
    //             "name": "Puntualidad",
    //             "area": "Recursos",
    //             'valor': 5000,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Sin productos",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Cliente solicita garantía",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Cancelación de servicio",
    //             "area": "Recursos",
    //             'valor': 35000,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Uniforme",
    //             "area": "Recursos",
    //             'valor': 5000,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Calidad",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "No uso la app",
    //             "area": "Recursos",
    //             'valor': 5000,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Server no contesta",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Server no le funciona la app",
    //             "area": "Tecnologia",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Server con problemas en plan",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Server con problemas en servicio",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Server no quiere volver al plan",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "No aparece mi plan recurrente",
    //             "area": "Comercial",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             "name": "Otro",
    //             "area": "Recursos",
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //     ]

    // },
    // {
    //     'name': 'Cliente',
    //     'tipo': [
    //         {
    //             'name': 'Cliente no se encuentra',
    //             'area': 'Comercial',
    //             'valor': 10000,
    //             'urgencia': 'alta',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'Cliente cancela',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'alta',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'El espacio es muy grande',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'baja',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'No me alcanzo el tiempo',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'baja',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'El cliente no fue amable',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'baja',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'Pasaje adicional',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'media',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'Cliente desea modificar plan',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'baja',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'Cliente solicita cambio de server asignada',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'urgencia': 'baja',
    //             'estado': 'Pendiente'
    //         },
    //         {
    //             'name': 'Otro',
    //             'area': 'Comercial',
    //             'valor': 0,
    //             'estado': 'Pendiente'
    //         },
    //     ]
    // },
]
export{
    Novedades
} 

