import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbrnum'
})
export class AbbrnumPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value= Math.round(value); 
    args = Math.pow(10,args);

    // Enumerate number abbreviations
    var abbrev = [ "k", "m", "b", "t" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= value) {
             value = Math.round(value*args/size)/args;

             // Handle special case where we round up to the next abbreviation
             if((value == 1000) && (i < abbrev.length - 1)) {
                 value = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             value += abbrev[i];

             // We are done... stop
             break;
        }
    }

    return value;
  }

}
