import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import Chart from 'chart.js';
import { IMyDpOptions } from 'mydatepicker';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { IMyDateRangeModel } from 'mydaterangepicker';
import { ServerService } from 'src/app/services/server.service';
import { ServerInterface } from 'src/app/models/interface/Server.interface';
import { MarketingService } from 'src/app/services/marketing.service';

type ChartData = [{ date, value, count }];
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public data: any;

  public amountService: number;
  public amountTransation: number;
  public OcupacionServerCompleta: number;
  public OcupacionServerFecha: number;
  public Copiacurrent_number_server_working: number;
  public current_repurchase_percentage_clientFechas: number;

  public transacciones: Array<Object> = [];
  public valor: number = 0;
  public totalTransaccion: number;
  public total: any = 0;
  public valorTransaccDias;
  public myDateRangePickerOptions;
  public spinerGrafica = true;
  public usuarios: any[];
  public usuariosCompletos: any[];
  public serviciosCompletos: Array<Object> = [];
  public UserUnicos: Array<Object> = [];
  public transaccionesCompletas: Array<Object> = [];
  server: any = [];
  public serverCompletos: Array<Object> = [];
  public calcular: boolean;

  public mesActual: boolean = false;
  public model: any = {
    beginDate: { year: moment().year(), month: (moment().add().month() + 1), day: 1 },
    endDate: { year: moment().year(), month: (moment().add().month() + 1), day: moment().endOf('month').date() }
  };
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
  };
  // Click
  public CountServiceClick: number = 16;
  public CountClientClick: number = 10;
  // Variable para fecha para el filtrado
  public datePrevious: any = moment().subtract(1, 'M').format('MM');
  public currentDate: any = moment().format('MM');
  public nextDate: any = moment().add(1, 'M').format('MM');

  public currentYear = moment(new Date()).format('YYYY');
  public currentMonth = moment(new Date()).format('MM');
  public currentDay = moment(new Date()).format('DD');

  // Variable de lista de servicios
  public servicios: Array<Object> = [];
  public servicesPrevious: Array<Object> = [];
  public servicesCurrent: Array<Object> = [];
  // Variables de total de horas por periodo
  public current_period_hours: number = 0;
  public hours_previous_period: number = 0;
  // Variables de total de horas por periodo
  public total_income_current_month: number = 0;
  public total_income_previous_month: number = 0;
  // Variable de cantidad de servicio por server
  public current_id_servicio_fecha: Array<Object> = [];
  public previous_id_servicio_fecha: Array<Object> = [];
  // Variable de total de servicio
  public current_total_service: Number = 0;
  public previous_total_service: Number = 0;
  // Total de server trabajando
  public current_number_server_working: number = 0;
  public previous_number_server_working: number = 0;
  // 
  public current_repurchase_percentage_client: number = 0;
  public previous_repurchase_percentage_client: number = 0;
  // 
  public current_service_by_client: Array<Object> = [];
  public previous_service_by_client: Array<Object> = [];
  // Churn 
  public current_service_churn_client: Array<Object> = [];
  public previous_service_churn_client: Array<Object> = [];
  // 
  public current_average_hours_worked: number = 0;
  public previous_average_hours_worked: number = 0;
  // 
  public current_server_occupancy_percentage: number = 0;
  public previous_server_occupancy_percentage: number = 0;
  // Cantidad de server activas e inactivas
  public all_server_active: number = 0;
  public all_server_deactivate: number = 0;
  // Cantidad de usuario registrados segun el periodo
  public current_registered_user: any = [];
  public previous_registered_user: any = [];
  public total_client_active: number = 0;
  // Estadisticas actuales
  public current_statistic_data: any = {};
  // Calculos 
  public current_calculation_cac: number = 0;
  public previous_calculation_cac: number = 0;
  // tabla metricas
  public table_json: any = [];

  /*  Para el grafico */
  @ViewChild('grafico1', { static: false }) grafico1: ElementRef;
  @ViewChild('grafico3', { static: false }) grafico3: ElementRef;
  @ViewChild('grafico4', { static: false }) grafico4: ElementRef;


  @ViewChild('lineRegisterCLient', { static: false }) lineRegisterCLient: ElementRef;
  @ViewChild('lineServiceOrder', { static: false }) lineServiceOrder: ElementRef;
  @ViewChild('chartCircle', { static: false }) chartCircle: ElementRef;

  constructor(
    public usuarioService: UsuarioService,
    public ServicioService: ServicioService,
    public serverService: ServerService,
    public marketingService: MarketingService,
  ) { }

  ngOnInit() {
    moment.locale('es')
    this.getServicios();
  }

  //Cuenta nuemero usuario registrados y Grafica
  getAllUsers() {
    this.previous_registered_user = [];
    this.current_registered_user = [];

    this.usuarioService.all().then((users) => {

      users.docs.map((data) => {
        this.usuarios.push(data.data());
        this.usuariosCompletos.push(data.data());
        const template: any = {} = data.data();
        const create_at = moment(new Date(template.fecha)).format("MM");
        template['day'] = moment(new Date(template.fecha)).format('DD');
        template['month'] = moment(new Date(template.fecha)).format('MMMM');
        template['year'] = moment(new Date(template.fecha)).format('YYYY');

        if (template.estado == 'activo'/* && (template.estado).toLowerCase() == 'activo */) {
          this.total_client_active = (this.total_client_active + 1);
        }
        /*
        * Filtrar todos los servicios del mes anterior
        * Filtrar todos los servicios del mes actual
        * Esto con el fin de hacer una comparacion de
        * Crecimiento como en clientes y venta de servicios
        */
        if (
          this.datePrevious == create_at &&
          /* ((parseInt(template.day) <= parseInt(this.currentDay))) && */
          template.year == this.currentYear
        ) {
          //console.log({ create_at, datePrevious: this.datePrevious });
          this.previous_registered_user.push(template);
          console.log(this.previous_registered_user.length);
          console.log(this.previous_registered_user);
        } else if (
          (this.currentDate == create_at) &&
          (template.year == this.currentYear)
        ) {
          //console.log({  create_at, currentDate: this.currentDate });
          this.current_registered_user.push(template);
        }


      });
      /*console.log({
        pre: this.previous_registered_user.length,
        cur: this.current_registered_user.length,
        day: parseInt(moment().format('YYYY-MM-DD h:mm A'))
      });*/


      // Agrupar datos por propiedad 
      const groupCurrentDate = this.createGroupData(this.current_registered_user, 'day');
      const groupPreviousDate = this.createGroupData(this.previous_registered_user, 'day');
      // Organizar datos para el chart
      const chartCurrentDate = this.orderDataByChart(groupCurrentDate, 'date', 'count', 'Mes actual', ['#ffaa00']);
      const chartPreviousDate = this.orderDataByChart(groupPreviousDate, 'date', 'count', 'Mes anterior', ['darkcyan']);

      const datasets = chartPreviousDate.datasets[0];
      chartCurrentDate.datasets.push(datasets);
      this.createDataByCanvas(chartCurrentDate, 'lineRegisterCLient');

      if (chartCurrentDate && chartPreviousDate) {
        this.getAllStatistic();
      }
    });
  }

  //Cuenta nuemero servers registrados y Grafica
  getAllServers() {
    this.serverService.all().then((result)=> {
      result.docs.forEach(data=>{
        if ((data.data().estado).toString() == "activo") {
          this.all_server_active += 1;

        } else {
          this.all_server_deactivate += 1;
        }
        this.serverCompletos.push(data.data());
        this.server.push(data.data());
      });
    })
  }

  // Metricas Nuevas Estadisticas
  getAllStatistic() {

    // Calcular el CAC. 
    /**
     * - Marketing
     * - Numero de cliente nuevos del periodo actual
     */
    this.marketingService.all().then((items) => {
      let current_cost_month: number = 0;
      let previous_cost_month: number = 0;


      items.docs.forEach((item: any) => {
        const template = item.data();
        const create_at = moment(new Date(template.cread_at)).format("MM");
        const printed: number = template['printed'];
        const social: number = template['social'];
        const person: number = template['person'];

        /*console.log({
          template,
          create_at,
          datePrevious: this.datePrevious,
          currentDate: this.currentDate,
          dateCurrent: Date.parse(moment().subtract(15, 'd').format('YYYY-MM-DD h:mm A'))
        });*/

        if (this.datePrevious == create_at) {
          previous_cost_month = ((printed + social + person) + previous_cost_month);
        } else if (this.currentDate == create_at) {
          current_cost_month = ((printed + social + person) + current_cost_month);
        }


      });

      const CRU: number = this.current_registered_user.length;
      const PRU: number = this.previous_registered_user.length;
      const TlSC: any = this.current_total_service;
      const TlSP: any = this.previous_total_service;

      this.current_calculation_cac = CRU ? ((current_cost_month / CRU)) : current_cost_month;
      this.previous_calculation_cac = PRU ? ((previous_cost_month / PRU)) : previous_cost_month;

      const TCPCC: number = (((TlSC * this.CountServiceClick) + (this.CountClientClick * CRU)) / 100)
      const TPPCC: number = (((TlSP * this.CountServiceClick) + (this.CountClientClick * PRU)) / 100)
      const CHRATE: number = (this.previous_repurchase_percentage_client / this.current_repurchase_percentage_client);

      console.log({
        TlSC,
        TlSP,
        current_cost_month,
        previous_cost_month,
        CRU,
        PRU,
        current_calculation_cac: this.current_calculation_cac,
        previous_calculation_cac: this.previous_calculation_cac,
        previous_repurchase_percentage_client: this.previous_repurchase_percentage_client,
        current_repurchase_percentage_client: this.current_repurchase_percentage_client,
      });

      this.table_json = [
        {
          name: 'Tiempo de vida del cliente',
          current: ((this.total_income_current_month / this.total_client_active) / (this.current_repurchase_percentage_client / this.total_client_active)),
          previous: ((this.total_income_previous_month / this.total_client_active) / (this.previous_repurchase_percentage_client / this.total_client_active)),
        },
        {
          name: 'Costo de adquisición del cliente',
          current: this.current_calculation_cac,
          previous: this.previous_calculation_cac,
        },
        {
          name: 'Porcentaje de conversión',
          current: TCPCC,
          previous: TPPCC,
        },
        {
          name: 'Churn Rate',
          current: CHRATE,
          previous: false,
        },
        {
          name: 'Porcentaje de recompra',
          current: (this.current_repurchase_percentage_client),
          previous: (this.previous_repurchase_percentage_client),
        }
      ]
      console.log({
        table_json: this.table_json,

      });

    })
    this.spinerGrafica = false;


  }
  /*
    Permite obtener el numeros de servicios agendados
    grafica cantidad de servicio vs fecha
    Se calculan datos estadisticos de los servicios y servers
  */
  getServicios() {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = moment(new Date(y, m, 1)).subtract(1, 'M').format('YYYY-MM-DD');
    var lastDay = moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD');
    this.current_period_hours = 0;
    this.current_total_service = 0;
    this.total_income_current_month = 0;
    this.current_server_occupancy_percentage = 0;
    this.servicesCurrent = [];
    this.current_id_servicio_fecha = [];
    this.current_service_by_client = [];

    this.previous_total_service = 0;
    this.hours_previous_period = 0;
    this.total_income_previous_month = 0;
    this.previous_server_occupancy_percentage = 0;
    this.servicesPrevious = [];
    this.previous_id_servicio_fecha = [];
    this.previous_service_by_client = [];
    // Horas trabajadas por periodos

    const current_horas_disponibles = parseInt(this.currentDay) * 8;

    //console.log({current_horas_disponibles, parseInt: currentDay});


    this.ServicioService.getInDateRange(firstDay, lastDay).then(items => {
      items.docs.forEach(doc => {
        const template = doc.data();
        const idClient = template.userid;
        const idServer = template.idServer;
        const create_at = moment(new Date(template.fechaCreacion)).format("MM");
        const hours_plus = template.horasDeServicio;
        // Creamos propiedades para vista en la grafica
        template['day'] = moment(new Date(template.fechaCreacion)).format('DD');
        template['month'] = moment(new Date(template.fechaCreacion)).format('MMMM');
        template['year'] = moment(new Date(template.fechaCreacion)).format('YYYY');
        //

        /*
         * Filtrar todos los servicios del mes anterior
         * Filtrar todos los servicios del mes actual
         * Esto con el fin de hacer una comparacion de
         * Crecimiento como en clientes y venta de servicios
         */


        if (
          (this.datePrevious == create_at) &&
          (template.year == this.currentYear) &&
          (parseInt(template.day) <= parseInt(this.currentDay)) &&
          template.idServer) {

          this.servicesPrevious.push(template);
          this.previous_id_servicio_fecha.push(idServer);
          // Sumar horas de servicios anteriores
          this.hours_previous_period += hours_plus;
          // Sumar el total de servicios
          this.total_income_previous_month = (template.total + this.total_income_previous_month)
          this.previous_service_by_client.push(idClient);
        } else if (
          (this.currentDate == create_at) &&
          (template.year == this.currentYear) &&
          template.idServer
        ) {

          this.servicesCurrent.push(template);
          this.current_id_servicio_fecha.push(idServer);
          // Sumar horas deservicios actuales
          this.current_period_hours += hours_plus;
          // Sumar el total se los servicios
          this.total_income_current_month = (template.total + this.total_income_current_month)
          this.current_service_by_client.push(idClient);
        }


        this.servicios.push(template);
        //this.serviciosCompletos.push(template);

      })
      //console.log({servicesCurrent: this.servicesCurrent });
      // Total de cantidad de servicios segun el periodo
      this.current_total_service = this.servicesCurrent.length;
      this.previous_total_service = this.servicesPrevious.length;
      // Agrupar datos por propiedad
      // Ojo:  this.previous_service_churn_client = this.createGroupData(this.servicesCurrent, 'day');
      // Agrupar datos por propiedad
      const groupCurrentStatus = this.createGroupData(this.servicesCurrent, 'estado');
      const groupCurrentDate = this.createGroupData(this.servicesCurrent, 'day');
      const groupPreviousDate = this.createGroupData(this.servicesPrevious, 'day');
      // Organizar datos para el chart
      const chartCurrentStatus = this.orderDataByChart(groupCurrentStatus, 'date', 'count', 'Estados de servicios', ['#0095ff', '#ff3d71', '#ffaa00', '#2ce69b', 'darkcyan', 'purple']);
      const chartCurrentDate = this.orderDataByChart(groupCurrentDate, 'date', 'count', 'Mes actual', ['#0095ff']);
      const chartPreviousDate = this.orderDataByChart(groupPreviousDate, 'date', 'count', 'Mes anterior', ['#ff3d71']);
      const datasets = chartPreviousDate.datasets[0];
      chartCurrentDate.datasets.push(datasets);
      this.createDataByCanvas(chartCurrentDate, 'lineServiceOrder');
      this.createDataByCanvas(chartCurrentStatus, 'chartCircle', 'pie');

      // Promedio de compra por promedio
      this.current_number_server_working = this.NumeroServer(this.current_id_servicio_fecha);
      this.previous_number_server_working = this.NumeroServer(this.previous_id_servicio_fecha);
      // Promedio de compra por promedio
      this.current_repurchase_percentage_client = this.Recompra(this.current_service_by_client);
      this.previous_repurchase_percentage_client = this.Recompra(this.previous_service_by_client);
      // No se para que es esto
      this.Copiacurrent_number_server_working = this.current_number_server_working;

      // Porcentaje de ocupacion de server periodo actual
      this.current_average_hours_worked = this.current_period_hours / this.current_number_server_working;
      this.current_server_occupancy_percentage = ((this.current_average_hours_worked * 100) / current_horas_disponibles);
      // Porcentaje de ocupacion de server periodo acteririo
      this.previous_average_hours_worked = this.hours_previous_period / this.previous_number_server_working;
      this.previous_server_occupancy_percentage = ((this.previous_average_hours_worked * 100) / current_horas_disponibles);

      // 
      this.OcupacionServerCompleta = this.current_server_occupancy_percentage;
      this.current_repurchase_percentage_clientFechas = this.current_repurchase_percentage_client;

      if (chartCurrentDate && chartCurrentStatus) {
        this.getAllServers();
        this.getAllUsers();
      }

    });
  };

  /*Numero de server que laborarón en las fechas seleccionadas*/
  NumeroServer(IdServer) {
    let ServUnicos = _(IdServer)
      .groupBy(x => x)
      .map((value, key) => ({ server: key }))
      .value();
    return ServUnicos.length;
  }

  //Se calculan el numero de usuarios que han solicitado mas de un servicio
  Recompra(IdUserByServicio) {
    let UserRepitieron: number = 0;
    let UserUnicos = _(IdUserByServicio)
      .groupBy(x => x)
      .map((value, key) => ({ usuario: key, servicios: value.length }))
      .value();
    UserUnicos.forEach(element => {
      let aux: any = element;
      if (aux.servicios > 1) {
        UserRepitieron = UserRepitieron + 1;
      }
    });
    let RecompraPorcent = (UserRepitieron * 100 / UserUnicos.length);
    return RecompraPorcent;
  }

  /**
   * Creacion de grupos de datos
   * @param arrayInput 
   * @param property 
   */
  public createGroupData(arrayInput, property: string = 'day') {
    const groupDataByProperty: any = [] = this.groupByDay(arrayInput, property, false);
    const data = [];
    for (var key in groupDataByProperty) {
      if (groupDataByProperty.hasOwnProperty(key)) {
        data.push({
          date: key,
          count: groupDataByProperty[key].length,
        });
      }
    }
    return data;
  }

  // Generador de datos para canvas o chats
  public createDataByCanvas(dataChart: any, grafico: string, typeChart: string = 'line') {
    try {
      const idChart = this[grafico].nativeElement.getContext('2d');
      this.paintChart(idChart, dataChart, typeChart);
      //console.log({ chart: dataChart});
    } catch (error) {
      console.error("Error!:", error);
    }
  }
  /**
   * Funcion que retorna 
   * @param data 
   * @param propertyByLabel 
   * @param propertyByData 
   * @param titleByData 
   * @param color 
   */
  public orderDataByChart(
    data: any[],
    propertyByLabel: string = 'date',
    propertyByData: string = 'count',
    titleByData: string = 'Mes actual',
    color: Array<string> = ['#3298FF'],
  ) {
    // Ordenamos la informacion segun la propiedad
    data = _.orderBy(data, propertyByLabel, 'asc');
    return {
      labels: data.map(item => item[propertyByLabel]),
      datasets: [{
        label: titleByData,
        borderColor: color,
        backgroundColor: color,
        data: data.map(item => item[propertyByData]),
        fill: false
      }]
    };
  }
  /**
   * Funcion para pintar el contenido en la grafica
   * @param idCanvas  // Identificar el canvar
   * @param dataChart // Datos a pintar en el canvas 
   * @param typeChart // Tipo o estilo de canvas
   */
  public paintChart(idCanvas, dataChart: Object, typeChart: string = 'line') {
    new Chart(
      idCanvas,
      {
        "type": typeChart,
        "data": dataChart,
        "options": {
          responsive: false,
          hoverMode: 'index',
          stacked: false,
          "legend": {
            "display": true
          },
          "scales": {
            "yAxes": [{
              "display": true,
              "ticks": {
                "beginAtZero": true
              }
            }]
          }
        }
      }
    );
  }

  public groupByDay(data, property, date: boolean = true) {
    // Agrupamos segun la fecha
    const reducerDate = (accumulator, currentValue) => {
      const valProperty = moment(currentValue[property]).startOf('day').format('YYYY-MM-DD');
      (accumulator[valProperty] = accumulator[valProperty] || []).push(currentValue);
      return accumulator;
    };
    // Agreupamos segun una propiedad
    const reducerStandar = (accumulator, currentValue) => {
      const valProperty = currentValue[property];
      (accumulator[valProperty] = accumulator[valProperty] || []).push(currentValue);
      return accumulator;
    };
    return data.reduce((date) ? reducerDate : reducerStandar, {});
  };
  //Filtros de estadisticas y graficos en base a la fecha
  // public onDateRangeChanged(event: IMyDateRangeModel) {
  //   const beginDate = event.beginJsDate;
  //   const endDate = event.endJsDate;
  //   // Vamos un mes atras
  //   const new_previpus = moment(beginDate).format('MM');
  //   const new_beginDate = moment(beginDate).format('YYYY-MM-DD');
  //   const new_endDate = moment(endDate).format('YYYY-MM-DD');
  //   console.log({
  //     beginDate,
  //     endDate,
  //     new_beginDate,
  //     new_endDate
  //   })
  //   // moment().subtract(1, 'M').format('MM');
  // }

}
