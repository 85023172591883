import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CuponService {
  constructor(private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { }

  public guardarCupon(cupon) {
    return firebase.firestore().collection('cupon').add(cupon).then(ref => {
      firebase.firestore().doc('cupon/' + ref.id).update({id:ref.id});
    });
  }
  public getCupones() {
    return firebase.firestore().collection('cupon').get();
  }
  public getCuponesActivos(){
    return firebase.firestore().collection('cupon')
    .where('fechaExpiracion','>=',moment().format('YYYY-MM-DD'))
    .get();
  }
  

  public getCuponId(id) {
    return firebase.firestore().doc('cupon/' + id).get();
  }
  
  public updateCupon(cupon) {
    return firebase.firestore().doc('cupon/' + cupon.id).update(Object.assign({},cupon));
  }

  public delateCupon(id){
    return firebase.firestore().doc('cupon/' + id).delete()
  }
}
