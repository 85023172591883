import { Component, ElementRef, OnInit, ViewChild, Input } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { AngularFireStorage } from "angularfire2/storage";
import { AuthService } from "src/app/services/auth.service";
import { Helper } from "src/app/utility/helper";
import Swal from "sweetalert2";


@Component({
  selector: 'app-verUsuarios',
  templateUrl: './ver_usuarios.component.html',
  styleUrls: ['./ver_usuarios.component.scss']
})

export class verUsuarios implements OnInit {

  @ViewChild("mdlViewUser", { static: true }) mdlViewUser: ElementRef;
  @Input() adminUser: any = {};
  isEditMode = false;
  generalLoader = false;
  filenameToRender: any = '';
  usuarioEnSesion: any = {};
  constructor(
    private helper: Helper,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private angularFireStorage: AngularFireStorage,
  ) {
    this.usuarioEnSesion = this.authService.storeGetUserData('usuario')
    console.log('Entro en verUsuarios',this.adminUser)
  } 
  ngOnInit(): void {
    this.filenameToRender = this.adminUser.foto ? this.adminUser.foto : '';
    // @ts-ignore
    this.dialogService.open(this.mdlViewUser, {
      context: "this is some additional data passed to dialog",
    });
  }

  validateEditAcces(){
    if(this.usuarioEnSesion.id == this.adminUser.id || this.usuarioEnSesion.rol == 'administrador' ) return true
    else return false
  }

  toggleisEditMode () {
    this.isEditMode = !this.isEditMode;
  }

  picture(imgURL){
    this.filenameToRender = imgURL;
  }

  onUploadPhoto(event){
    console.log(event.target.files[0]);
    this.helper.onFileReader(event, this.picture.bind(this));

      const filePath = `admin_users/${this.adminUser.id}/foto`;
      this.angularFireStorage.ref(filePath).put(event.target.files[0]).then((res) => {
        let sub = this.angularFireStorage.ref(filePath).getDownloadURL().subscribe((url) => {
          sub.unsubscribe();
          this.adminUser.foto = url;
        });
      })
      .catch((err) => {
        console.log("Ocurrio un error---", err);
      });
    // }
  }

  onUpdateUser(){
    console.log(this.adminUser);
    this.authService.updateUsersAdmin(this.adminUser).then(()=>{
      Swal.fire('listo','La informacion a sido actualizada','success');
      this.toggleisEditMode();
    })
  }

}