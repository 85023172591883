import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { CompleterService } from "ng2-completer";
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import * as _ from "lodash";
import * as  moment from 'moment';
/* Servicios */
import { Location } from '@angular/common';
import { ServicioService } from 'src/app/services/servicio.service';
import { ServerService } from 'src/app/services/server.service';
import { AuthService } from 'src/app/services/auth.service';
import { posventaService } from "src/app/services/posventa.service";


@Component({
  selector: 'app-ver-postventa',
  templateUrl: './ver-postventa.component.html',
  styleUrls: ['./ver-postventa.component.scss'],
})

export class VerPostventaComponent implements OnInit {

  public servicioPostventa: any = {};
  public serverPostventa: any = {};
  public servicioId: any;
  public novedades: any;
  public calificacion: Number;
  public actitud: number = 1;
  public calidad: number = 1;
  public puntualidad: number = 1;
  public uniforme: number = 1;
  public asesorPostventa: any;
  public usuarioEnSecion:any;
  fechaActual = moment().format('YYYY-MM-DD')
  constructor(
    public completerService: CompleterService,
    public _location: Location,
    public activatedRoute: ActivatedRoute,
    public serverService: ServerService,
    public ServicioService: ServicioService,
    public authService: AuthService,
    private posventaService: posventaService,
  ) {
    this.servicioId = this.activatedRoute.snapshot.params.idServicio;
    this.usuarioEnSecion = this.authService.storeGetUserData('usuario');
    console.log(this.servicioId)
     }
     
  ngOnInit() { this.getServicio(this.servicioId)
    this.asesorPostventa = this.usuarioEnSecion.displayName;
    
   }

  public goBack() {
    this._location.back();
  }

  public getServicio(id) {
    this.ServicioService.getServiciosbyId(id).then(value => {
      let servicio:any = value.data()
      this.serverService.detail(servicio.idServer).then(res=>{
        console.log(res.data());
        servicio.server = res.data();
        this.servicioPostventa = servicio
        console.log(this.servicioPostventa);
      })
    });
  }
  public updateCalificacion(){
    this.servicioPostventa.calificacionPromedio = ((Number(this.servicioPostventa.calidadPromedio) + Number(this.servicioPostventa.actitudPromedio) + Number(this.servicioPostventa.puntualidadPromedio) + Number(this.servicioPostventa.uniformePromedio))/4)
    console.log(this.servicioPostventa.calificacionPromedio)
  }

public postponerPostventa(){
  this.servicioPostventa.estadoPostventa = "postventa postpuesta";
  this.actualizarPostventa()
}

public postventaFallida(){
  this.servicioPostventa.estadoPostventa = "postventa fallida";
  if (this.servicioPostventa.novedadPostventa == undefined ){this.servicioPostventa.novedadPostventa = "Sin novedad"}
  let postventa = {
    puntuacion: this.servicioPostventa.calificacionPromedio,
    comentario: this.servicioPostventa.novedadPostventa,
    fecha: this.fechaActual
  }
  if(this.servicioPostventa.server.postventas != undefined){
    this.servicioPostventa.server.postventas.push(postventa);
  } else{
    this.servicioPostventa.server.postventas = [];
    this.servicioPostventa.server.postventas.push(postventa);
  }
  this.serverService.updateServ(this.servicioPostventa.server)
  this.actualizarPostventa()
}

public terminarPostventa(){
  this.servicioPostventa.estadoPostventa = "postventa terminada";
  if (this.servicioPostventa.novedadPostventa == undefined ){this.servicioPostventa.novedadPostventa = "Sin novedad"}
  else {
    let listNews: any = [];
    let templateNews: any = { type: this.servicioPostventa.novedadPostventa, description: 'Postventa', status: 'Pendiente' }
    listNews.push(templateNews);
  this.servicioPostventa.news = listNews
  }


  
  let postventa = {
    fecha:          this.fechaActual,
    asesor:         this.asesorPostventa,
    novedad:        this.servicioPostventa.novedadPostventa,
    comentario:     this.servicioPostventa.comentarioPostventa,
    puntuacion:     this.servicioPostventa.calificacionPromedio,
  }
  if(this.servicioPostventa.server.postventas != undefined){
    this.servicioPostventa.server.postventas.push(postventa);
  } else{
    this.servicioPostventa.server.postventas = [];
    this.servicioPostventa.server.postventas.push(postventa);
  }
  
  this.serverService.updateServ(this.servicioPostventa.server)
  console.log(this.servicioPostventa.calificacionPromedio)
  this.actualizarPostventa()
}


  public actualizarPostventa() { 
   
   this.serverService.detail(this.servicioPostventa.idServer).then(res => {
     this.serverPostventa = res.data();
     if(this.serverPostventa.calificacionPromedio == "" || this.serverPostventa.calificacionPromedio == undefined ){ console.log("tiene calificaciones guardadas")} else { console.log("no tiene calificaciones guardadas")};
    console.log(this.serverPostventa)
    console.log("erre");
    this.serverPostventa.calidadPromedio = this.servicioPostventa.calidadPromedio
    this.serverPostventa.actitudPromedio = this.servicioPostventa.actitudPromedio
    this.serverPostventa.puntualidadPromedio = this.servicioPostventa.puntualidadPromedio
    this.serverPostventa.uniformePromedio = this.servicioPostventa.uniformePromedio
    this.serverPostventa.calificacionPromedio = this.servicioPostventa.calificacionPromedio
    this.serverPostventa.asesorPostventa = this.serverPostventa.asesorPostventa
     this.serverService.updateByServer(this.serverPostventa);
     console.log("server con calificacion actualizada");
  });
 
      this.posventaService.updateServicio(this.servicioPostventa).then((result) => {
        Swal.fire(
          'Éxito',
          'Se actualizo la postventa exitosamente',
          'success'
        );
        this._location.back();
        
      });
  }

}


