import { Component, OnInit, Input } from '@angular/core';
import * as firebase from 'firebase';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mdls-envio-mensajes',
  templateUrl: './mdls-envio-mensajes.component.html',
  styleUrls: ['./mdls-envio-mensajes.component.scss']
})
export class MdlsEnvioMensajesComponent implements OnInit {
  @Input() clientsForMarketing;
  @Input() tipoDeMensaje;
  generalLoader: boolean = false;
  textForSms = '';
  textForEmail: any = {
    ciudad: ''
  }
  countSentMessages: any = 0;
  constructor() { }

  ngOnInit() {
  }

  sendSms(){
    this.countSentMessages = 0;
    this.generalLoader = true
    // let info = {
    //   text: this.textForSms,
    //   users: this.clientsForMarketing, //Deben tener el atributo phoneNumber y name
    // }
    // console.log(info)
    for (const client of this.clientsForMarketing) {
      let info = {
        text: this.textForSms,
        user: client, //Deben tener el atributo phoneNumber y name
      }
      const enviarSmsTextoVariable = firebase.functions().httpsCallable("enviarSmsTextoVariable");
      enviarSmsTextoVariable(info).then((result)=>{
        this.countSentMessages++
        if(this.countSentMessages == this.clientsForMarketing.length){
          this.generalLoader = false
          Swal.fire('Listo','Los mensajes se an enviado','success');
        }
      }).catch(err=>{
        this.generalLoader = false;
        console.log("ocurrio un error",err)
        Swal.fire('Error','Ocurrio un problema al enviar los mensajes','warning')
      })
    }
  }

  sendEmail(){
    this.countSentMessages = 0;
    this.generalLoader = true;

    let info = {
      users: this.clientsForMarketing,
      template: this.textForEmail,
      templateId: 'd-e9f70ca09ff94770b3478c7bfb0b5e8c',
    }

      
    const enviarSmsTextoVariable = firebase.functions().httpsCallable("enviarEmailTextoVariable");
    enviarSmsTextoVariable(info).then((result)=>{ 
      this.countSentMessages++
      if(this.countSentMessages == this.clientsForMarketing.length){
        this.generalLoader = false
        Swal.fire('Listo','Los mensajes se an enviado','success');
      }
    }).catch(err=>{
      console.log("ocurrio un error",err)
      this.generalLoader = false;
      Swal.fire('Error','Ocurrio un problema al enviar los mensajes','warning')
    })
  }
}
