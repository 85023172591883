//asd
const localidadesColombia =  [
    {
        "name": "Colombia",
        "code": 57,
        "acronym": "CO",
        "departments": [
            {
                "name": "Cundinamarca",
                "code": 1,
                "cities": [
                    {
                        "name": "Bogotá D.C.",
                        "code": 1,
                        "suburbs":[
                            {
                                "name": "Antonio Nariño",
                                "neighborhood":[
                                    {
                                        "name":"Ciudad Jardín"
                                    },
                                    {
                                        "name":"Restrepo"
                                    }
                                    
                                ]
                            },
                            {
                                "name": "Barrios Unidos",
                                "neighborhood": [
                                    {
                                        "name":"Los Andes"
                                    },
                                    {
                                        "name":"12 de Octubre"
                                    },
                                    {
                                        "name":"Los Alcázares"
                                    },
                                    {
                                        "name":"Parque Salitre"
                                    }
                                ]
                            },
                            {
                                "name": "Bosa",
                                "neighborhood":[
                                    {
                                        "name":"Apogeo" 
                                    },
                                    {
                                        "name":"Bosa Occidental" 
                                    },
                                    {
                                        "name":"Bosa Central" 
                                    },
                                    {
                                        "name":"El Porvenir" 
                                    },
                                    {
                                        "name":"Tintal Sur" 
                                    }
                                ]

                            },
                            {
                                "name": "Chapinero",
                                "neighborhood":[
                                    {
                                        "name":"El Refugio"  
                                    },
                                    {
                                        "name":"San Isidro-Patios" 
                                    },
                                    {
                                        "name":"Pardo Rubio" 
                                    },
                                    {
                                        "name":"Chicó Lago" 
                                    },
                                    {
                                        "name":"Chapinero" 
                                    }
                                ]
                            },
                            {
                                "name": "Ciudad Bolívar",
                                "neighborhood":[
                                    {
                                        "name":"El Mochuelo"
                                    },
                                    {
                                        "name":"Monteblanco"
                                    },
                                    {
                                        "name":"Arborizadora"
                                    },
                                    {
                                        "name":"San Francisco"
                                    },
                                    {
                                        "name":"Lucero"
                                    },
                                    {
                                        "name":"El Tesoro"
                                    },
                                    {
                                        "name":"Ismael Perdomo"
                                    },
                                    {
                                        "name":"Jerusalén"
                                    }
                                ]
                            },
                            {
                                "name": "Engativá",
                                "neighborhood":[
                                    {
                                        "name":"Las Ferias"

                                    },
                                    {
                                        "name":"Minuto de Dios"

                                    },
                                    {
                                        "name":"Boyacá Real"

                                    },
                                    {
                                        "name":"Santa Cecilia"

                                    },
                                    {
                                        "name":"Bolivia"

                                    },
                                    {
                                        "name":"Garcés Navas"

                                    },
                                    {
                                        "name":"Engativá"

                                    },
                                    {
                                        "name":"Jardín Botánico"

                                    },
                                    {
                                        "name":"Álamos"

                                    }
                                ]
                            },
                            {
                                "name": "Fontibón",
                                "neighborhood":[
                                    {
                                        "name":"Fontibón"
                                    },
                                    {
                                        "name":"Fontibón-San Pablo"
                                    },
                                    {
                                        "name":"Zona Franca"
                                    },
                                    {
                                        "name":"Ciudad Salitre Occidente"
                                    },
                                    {
                                        "name":"Granjas de Techo"
                                    },
                                    {
                                        "name":"Modelia"
                                    },
                                    {
                                        "name":"Capellanía"
                                    },
                                    {
                                        "name":"Aeropuerto Eldorado"
                                    }
                                ]
                            },
                            {
                                "name": "Kennedy",
                                "neighborhood":[
                                    {
                                        "name":"Castilla" 
                                    },
                                    {
                                        "name":"Castilla" 
                                    },
                                    {
                                        "name":"Carvajal" 
                                    },
                                    {
                                        "name":"Kennedy Central" 
                                    },
                                    {
                                        "name":"Timiza" 
                                    },
                                    {
                                        "name":"Tintal Norte" 
                                    },
                                    {
                                        "name":"Calandaima" 
                                    },
                                    {
                                        "name":"Corabastos" 
                                    },
                                    {
                                        "name":"Gran Britalia" 
                                    },
                                    {
                                        "name":"Patio Bonito" 
                                    },
                                    {
                                        "name":"Las Margaritas" 
                                    },
                                    {
                                        "name":"Bavaria" 
                                    }
                                ]
                            },
                            {
                                "name": "La Candelaria",
                                "neighborhood":[
                                    {
                                        "name":"La Candelaria" 
                                    }
                                ]
                            },
                            {
                                "name": "Mártires",
                                "neighborhood":[
                                    {
                                        "name":"Santa Isabel" 
                                    },
                                    {
                                        "name":"La Sabana" 
                                    }
                                ]
                            },
                            {
                                "name": "Puente Aranda",
                                "neighborhood":[
                                    {
                                        "name":"Ciudad Montes"
                                    },
                                    {
                                        "name":"Muzú"
                                    },
                                    {
                                        "name":"San Rafael"
                                    },
                                    {
                                        "name":"Zona Industrial"
                                    },
                                    {
                                        "name":"Puente Aranda"
                                    }
                                ]
                            },
                            {
                                "name": "Rafael Uribe",
                                "neighborhood":[
                                    {
                                        "name":"San José"
                                    },
                                    {
                                        "name":"Quiroga"
                                    },
                                    {
                                        "name":"Marco Fidel Suárez"
                                    },
                                    {
                                        "name":"Marruecos"
                                    },
                                    {
                                        "name":"Diana Turbay"
                                    }
                                ]
                            },
                            {
                                "name": "San Cristóbal",
                                "neighborhood":[
                                    {
                                        "name":"San Blas"
                                    },
                                    {
                                        "name":"Sociego"
                                    },
                                    {
                                        "name":"20 de Julio"
                                    },
                                    {
                                        "name":"La Gloria"
                                    },
                                    {
                                        "name":"Los Libertadores"
                                    }
                                ]
                            },
                            {
                                "name": "Santa fe",
                                "neighborhood":[
                                    {
                                        "name":"Sagrado Corazón"
                                    },
                                    {
                                        "name":"La Macarena"
                                    },
                                    {
                                        "name":"Las Nieves"
                                    },
                                    {
                                        "name":"Las Cruces"
                                    },
                                    {
                                        "name":"Lourdes"
                                    }
                                ]
                            },
                            {
                                "name": "Suba",
                                "neighborhood":[
                                    {
                                        "name":"La Academia"
                                    },
                                    {
                                        "name":"Guaymaral"
                                    },
                                    {
                                        "name":"San José de Bavaria"
                                    },
                                    {
                                        "name":"Britalia"
                                    },
                                    {
                                        "name":"El Prado"
                                    },
                                    {
                                        "name":"La Alhambra"
                                    },
                                    {
                                        "name":"Casablanca Suba"
                                    },
                                    {
                                        "name":"Niza"
                                    },
                                    {
                                        "name":"La Floresta"
                                    },
                                    {
                                        "name":"Suba"
                                    },
                                    {
                                        "name":"El Rincón"
                                    },
                                    {
                                        "name":"Tibabuyes"
                                    }
                                    
                                ]
                            },
                            {
                                "name": "Teusaquillo",
                                "neighborhood":[
                                    {
                                        "name":"Ciudad Salitre Oriental"
                                    },
                                    {
                                        "name":"Galerías"
                                    },
                                    {
                                        "name":"Teusaquillo"
                                    },
                                    {
                                        "name":"Parque Simón Bolívar"
                                    },
                                    {
                                        "name":"La Esmeralda"
                                    },
                                    {
                                        "name":"Quinta Paredes"
                                    }
                                ]
                            },
                            {
                                "name": "Tunjuelito",
                                "neighborhood":[
                                    {
                                        "name": "Tunjuelito",
                                        
                                    }
                                ]
                            },
                            {
                                "name": "Usaquén",
                                "neighborhood":[
                                    {
                                        "name":"Paseo de los Libertadores"
                                    },
                                    {
                                        "name":"Verbenal"
                                    },
                                    {
                                        "name":"La Uribe"
                                    },
                                    {
                                        "name":"San Cristóbal Norte"
                                    },
                                    {
                                        "name":"Toberín"
                                    },
                                    {
                                        "name":"Usaquén"
                                    },
                                    {
                                        "name":"Country Club"
                                    },
                                    {
                                        "name":"Santa Bárbara"
                                    },
                                    {
                                        "name":"Los Cedros"
                                    }
                                    
                                ]
                            },
                            {
                                "name": "Usme",
                                "neighborhood":[
                                    {
                                        "name":"La Flora"
                                    },
                                    {
                                        "name":"Danubio"
                                    },
                                    {
                                        "name":"Gran Yomasa"
                                    },
                                    {
                                        "name":"Comuneros"
                                    },
                                    {
                                        "name":"Alfonso López"
                                    },
                                    {
                                        "name":"Parque Entrenubes"
                                    },
                                    {
                                        "name":"Ciudad Usme"
                                    }
                    
                                ]
                            }
                            
                        ]

                    
                    },
                    {
                        "name": "Chia",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Fagua"
                                    },
                                    {
                                        "name": "Tiquiza"
                                    },
                                    {
                                        "name": "Fonqueta"
                                    },
                                    {
                                        "name": "Cerca de Piedra"
                                    }
                                ]
                            },
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Bojaca"
                                    },
                                    {
                                        "name": "La Balsa"
                                    },
                                    {
                                        "name": "Samaria"
                                    }
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Yerbabuena"
                                    },
                                    {
                                        "name": "Fusca"
                                    }
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Cajica",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                    
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    }, 
                                    {
                                        "name": "Zona Oriental"
                                    },                                
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Madrid",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Mosquera",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "La calera",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Funza",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    }, 
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                    
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Siberia",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                    {
                        "name": "Soacha",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"La despensa",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Relleno"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"San mateo",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Relleno"
                                    },   
                                ]
                            },
                        ]
                    
                    },
                    {
                        "name": "Cota",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Vuelta Grande",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    },
                                    {
                                        "name": "Relleno"
                                    },                                                                     
                                ]
                            },
                            {
                                "name":"Parcelas",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Relleno"
                                    },   
                                ]
                            },
                        ]
                    
                    },
                    {
                        "name": "Fusagasuga",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Norte",
                                "neighborhood":[
                                    {
                                        "name": "Los robles"
                                    },
                                    {
                                        "name": "San Rafael"
                                    }
                                ]
                            },
                            {
                                "name":"Oriental",
                                "neighborhood":[
                                    {
                                        "name": "El Jordán"
                                    },
                                    {
                                        "name": "La Palma"
                                    }
                                ]
                            },

                        ]
                    },
                    {
                        "name": "Zipaquirá",
                        "code": 1,
                        "suburbs":[
                            {
                                "name":"Zona central",
                                "neighborhood":[
                                    {
                                        "name": "Zona central"
                                    }, 
                                    {
                                        "name": "Zona Oriental"
                                    },                                                                    
                                ]
                            },
                            {
                                "name":"Zona oriental",
                                "neighborhood":[
                                    {
                                        "name": "Zona oriental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    }, 
                                ]
                            },
                            {
                                "name":"Zona occidental",
                                "neighborhood":[
                                    {
                                        "name": "Zona occidental"
                                    },
                                    {
                                        "name": "Zona Oriental"
                                    },                                 
                                ]
                            }
                        ]
                    
                    },
                ]

            },
            {
                "name": "Antioquia",
                "code": 4,
                "cities":[
                    {
                        "name": "Medellin",
                        "code":4,
                        "suburbs":[
                            {
                                "name": "Zona 1 - Nororiental",
                                "neighborhood":[
                                    {
                                        "name":"Comuna 1 - Popular"
                                    },
                                    {
                                        "name":"Comuna 2 - Santa Cruz"
                                    },
                                    {
                                        "name":"Comuna 3 - Manrique"
                                    },
                                    {
                                        "name":"Comuna 4 - Aranjuez"
                                    }
                                ]
                            },
                            {
                                "name": "Zona 2 - Noroccidental",
                                "neighborhood":[
                                    {
                                        "name":"Comuna 5 - Castilla"
                                    },
                                    {
                                        "name":"Comuna 6 - Doce de octubre"
                                    },
                                    {
                                        "name":"Comuna 7 - Robledo"
                                    }
                                ]
                            },
                            {
                                "name": "Zona 3 - Centro oriental",
                                "neighborhood":[
                                    {
                                        "name":"Comuna 8 - Villa Hermosa"
                                    },
                                    {
                                        "name":"Comuna 9 - Buenos Aires"
                                    },
                                    {
                                        "name":"Comuna 10 - La Candelaria"
                                    }
                                ]
                            },
                            {
                                "name": "Zona 4 - Centro occidental",
                                "neighborhood":[
                                    {
                                        "name": "Comuna 11 - Laureles—Estadio"
                                    },
                                    {
                                        "name": "Comuna 12 - La América"
                                    },
                                    {
                                        "name": "Comuna 13 - San Javier"
                                    }
                                ]
                            },
                            {
                                "name": "Zona 5 - Suroriental",
                                "neighborhood":[
                                    {
                                        "name": "Comuna 14 - Poblado"
                                    }
                                ]
                            },
                            {
                                "name":"Zona 6 - Suroccidental",
                                "neighborhood":[
                                    {
                                        "name":"Comuna 15 - Guayabal"
                                    },
                                    {
                                        "name":"Comuna 16 - Belén"
                                    }
                                ]
                            }
                        ],
                        
                    },
                    {
                         
                        "name": "Envigado",
                        "code":5,
                        "suburbs":[
                            {
                                "name": "Zona 1",
                                "neighborhood":[
                                    {
                                        "name":"Barrio las Vegas"
                                    },
                                    {
                                        "name":"Relleno"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 2",
                                "neighborhood":[
                                    {
                                        "name":"Barrio El Portal"
                                    },
                                    {
                                        "name":"Barrio San Marcos"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 3",
                                "neighborhood":[
                                    {
                                        "name":"Barrio La Sebastiana"
                                    },
                                    {
                                        "name":"Barrio Las Flores"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 4",
                                "neighborhood":[
                                    {
                                        "name":"Barrio El Esmeraldal"
                                    },
                                    {
                                        "name":"Barrio Loma del Atravesado"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 5",
                                "neighborhood":[
                                    {
                                        "name":"Barrio Loma de Las Brujas"
                                    },
                                    {
                                        "name":"Barrio La Pradera"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 6",
                                "neighborhood":[
                                    {
                                        "name":"Barrio El Chinguí"
                                    },
                                    {
                                        "name":"Barrio El Salado"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 7",
                                "neighborhood":[
                                    {
                                        "name":"Barrio Las Antillas"
                                    },
                                    {
                                        "name":"Barrio El Trianón"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 8",
                                "neighborhood":[
                                    {
                                        "name":"Barrio Las Casitas"
                                    },
                                    {
                                        "name":"Barrio Primavera"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 9",
                                "neighborhood":[
                                    {
                                        "name":"Barrio Los Naranjos"
                                    },
                                    {
                                        "name":"Barrio Mesa"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 10",
                                "neighborhood":[
                                    {
                                        "name":"Vereda El Escobero"
                                    },
                                    {
                                        "name":"Vereda Santa Catalina"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 11",
                                "neighborhood":[
                                    {
                                        "name":"Vereda El Vallano"
                                    },
                                    {
                                        "name":"Relleno"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 12",
                                "neighborhood":[
                                    {
                                        "name":"Vereda Las Palmas"
                                    },
                                    {
                                        "name":"Relleno"
                                    },
                                ]
                            },
                            {
                                "name": "Zona 13",
                                "neighborhood":[
                                    {
                                        "name":"Vereda Pantanillo"
                                    },
                                    {
                                        "name":"Vereda Perico"
                                    },
                                ]
                            },
                         

                        ]
                    },
                    {
               
                        "name": "Sabaneta",
                        "code":6,
                        "suburbs":[
                            {
                                "name": "Estación Itagüí",
                                "neighborhood":[
                                    {
                                        "name":"San Rafael"
                                    },
                                    {
                                        "name":"Las Casitas"
                                    },
                                ]
                            },
                            {
                                "name": "Estación Sabaneta",
                                "neighborhood":[
                                    {
                                        "name":"Entreamigos"
                                    },
                                    {
                                        "name":"Calle del banco"
                                    },
                                ]
                            },
                            {
                                "name": "Estación Estrella",
                                "neighborhood":[
                                    {
                                        "name":"Ancón Sur"
                                    },
                                    {
                                        "name":"Vegas de San José"
                                    },
                                ]
                            },
                        ],
                    },
                    {
                         
                        "name": "Rio negro",
                        "code":7,
                        "suburbs":[
                            {
                                "name": "Comuna 1",
                                "neighborhood":[
                                    {
                                        "name":"Belchite"
                                    },
                                    {
                                        "name":"El Centro"
                                    },
                                ]
                            },
                            {
                                "name": "Comuna 2",
                                "neighborhood":[
                                    {
                                        "name":"El Faro"
                                    },
                                    {
                                        "name":"San Antonio"
                                    },
                                ]
                            },

                        ],
                    },
                    {
                         
                        "name": "Itagüi",
                        "code":8,
                        "suburbs":[
                            {
                                "name": "Comuna 1",
                                "neighborhood":[
                                    {
                                        "name":"Santa Catalina"
                                    },
                                    {
                                        "name":"Samaria Robles de Sur"
                                    },
                                ]
                            },
                            {
                                "name": "Comuna 2",
                                "neighborhood":[
                                    {
                                        "name":"La Finca"
                                    },
                                    {
                                        "name":"Yarumito"
                                    },
                                ]
                            },

                        ],
                    },
                    {
                         
                        "name": "Bello",
                        "code":9,
                        "suburbs":[
                            {
                                "name": "Comuna 1",
                                "neighborhood":[
                                    {
                                        "name":"Los Sauces"
                                    },
                                    {
                                        "name":"El Cafetal"
                                    },
                                ]
                            },
                            {
                                "name": "Comuna 2",
                                "neighborhood":[
                                    {
                                        "name":"Barrio Nuevo"
                                    },
                                    {
                                        "name":"La Cabañita"
                                    },
                                ]
                            },

                        ],
                    },
                    {
                         
                        "name": "Copacabana",
                        "code":10,
                        "suburbs":[
                            {
                                "name": "Comuna 1",
                                "neighborhood":[
                                    {
                                        "name":"Santa Catalina"
                                    },
                                    {
                                        "name":"Samaria Robles de Sur"
                                    },
                                ]
                            },
                            {
                                "name": "Comuna 2",
                                "neighborhood":[
                                    {
                                        "name":"La Finca"
                                    },
                                    {
                                        "name":"Yarumito"
                                    },
                                ]
                            },

                        ],
                    }
                ]
            }

        ]
    }
];



const departamentos = [
    {
        "name": "Antioquia",
        "code": "05",
        "ciudades": [
            { "name":"Bello","code":"05088"},
            { "name":"Copacabana","code":"05212"},
            { "name":"Envigado","code":"05266"},
            { "name":"Itagüi","code":"05360"},
            { "name":"Medellín","code":"05001"},
            { "name":"Rio negro","code":"05615"},
            { "name":"Sabaneta","code":"05631"},
            { "name":"El retiro","code":" 05360"},
            { "name":"La ceja","code":" 055010"},
            { "name":"Caldas","code":" 055448"},
        ]
    },
    {
        "name": "Bogotá D.C.",
        "code": "11",
        "ciudades":[
            { "name":"Bogotá, D.C.","code":"11001"},
        ]
    },
    {
        "name": "Cundinamarca",
        "code": "25",
        "ciudades": [
            { "name":"Cajica","code":"25126"},
            { "name":"Chia","code":"25175"},
            { "name":"Cota","code":"25214"},
            { "name":"Funza","code":"25286"},
            { "name":"La calera","code":"25377"},
            { "name":"Madrid","code":"25430"},
            { "name":"Mosquera","code":"25473"},
            { "name":"Siberia","code":""},
            { "name":"Soacha","code":"15757"},
            { "name":"Tocancipa ","code":"251010"},
            { "name":"Zipaquira","code":"25899"},
        ]
    },
]
export {
    departamentos,
    localidadesColombia,
}