import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbDialogService,NbToastrService } from '@nebular/theme';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import Swal, { SweetAlertType } from 'sweetalert2';
import {DiaInterface} from 'src/app/models/interface/precios/dia.interface';
import {HorarioInteface} from 'src/app/models/interface/precios/horario.interface';
import {HorasInterface} from 'src/app/models/interface/precios/horas.interface';
import {ValorInterface} from 'src/app/models/interface/precios/valor.interface';
import { preciosServicios } from 'src/app/utility/precios';
import * as firebase2 from 'firebase';
import { CalendarEvent,CalendarView,CalendarMonthViewBeforeRenderEvent,CalendarMonthViewDay } from 'angular-calendar';
/* Servicios */
import { PlanRecurrenteService } from 'src/app/services/plan-recurrente.service';
import { funcionesUsuario } from 'src/app/services/funcionesUsuario';
import { ServicioService } from 'src/app/services/servicio.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ServerService } from 'src/app/services/server.service';
import { serviciosPrestados } from 'src/app/utility/SERVicios';
import { PlanService } from 'src/app/services/plan.service';
import { truncateSync } from 'fs';
import { HorasDeServicio } from 'src/app/utility/dataInfo';
import { NuevaDisponibilidadComponent } from '../../disponibilidad/nuevaDisponibilidad/nueva-disponibilidad.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { info } from 'console';
 

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#000AFF',
    secondary: '2F0CD7'
  }
};


@Component({
  selector: 'app-ver-plan',
  templateUrl: './ver-plan.component.html',
  styleUrls: ['./ver-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerPlanComponent implements OnInit {

  public congelado = false;
  public descongelable = false;
  public pictureName: any;
  public file: any;
  public nameImage: string = '';
  public fotoSeleccionada: any;
  public image: boolean = false;
  public nuevaServer: boolean = false;
  public keyServersAutocomplete: string = "nombreServer";
  public serverList: any = [];
  public flipped: boolean;
  public flipped2: boolean;
  public flippedInfoReg: boolean = true;
  public flippedInfoComReg: boolean = false;
  public contactado;

  /*  Para el modal */
  @ViewChild("reagendarPlanModal", { static: true }) reagendarPlanModal: ElementRef;
  @ViewChild("reagendarModal", { static: true }) reagendarModal: ElementRef;
  @ViewChild("editServicio", { static: true }) editServicio: ElementRef;
  @ViewChild("mdlVerCobro", { static: true }) mdlVerCobro: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /***/

  public planMensual: any = {};
  public server: any = {};
  public usuario: any = {};
  public source: any = [];
  public id: any;
  public editService: any = {};
  public historial = {
    name: '',
    cambios: '',
  }
  public currentPlanMensual: any;
  public horaInicioPlanRec: string = "falta";
  public diasPlanRecurrente: any;
  public diasSeleccionables = ['lunes','martes','miercoles','jueves','viernes','sabado','domingo']
  settings = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      severCompleteName: { title: 'Server' },
      fecha: { title: 'Fecha del servicio' },
      estado: { title: 'Estado' },
      horasDeServicio: { title: 'Duracion del servicio' },
      hora: { title: 'Hora de inicio' },
    },
    pager: {
      perPage: 10
    },
  };
  public sourceHistorial: any = [];
  settingsHistorial = {
    //hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
      ],
    },
    columns: {
      name: { title: 'Server' },
      cambios: { title: 'Fecha del servicio' },
    },
    pager: {
      perPage: 10
    },
  };
  public usuarioEnSecion: any;
  public serverSeleccionada: any = [];
  public diaSeleccionado;
  public horasDisponibles: any = [];
  public selectedOptionsDias: any = [];
  public serverNueva;
  public estadoPago = '';
  public cambioMultiple = false;
  c: number = 0;
  disponibilidadArray: any = [];
  serverSeleccionadaDatos: any;
  motivoCancelacion: string = "NaN";
  horasServicio: any;

  public comprobanteAvisualizar: string = ""
  public cobroClientePlan: any;
  public historialDeCobros: any = [];
  public cobroVisualizado: any;
  settingsPagosUsuario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'verComprobante', title: '<i class="nb-search"></i>' },
        { name: 'verCobro', title: '<i class="nb-compose"></i>' },
      ],
    },
    columns: {
      numeroServicios: { title: "num.Servicios" },
      comprobante: { title: "Comprobante",  
                    valuePrepareFunction: (comprobante) => {
                      if(comprobante == "falta" || comprobante == "Falta"  ){
                        return comprobante
                      }else{
                        return `Subido`;
                      }
                    } 
                  },
      estadoDelPago: { title: "Estado del pago" },
      factura: { title: "Factura" },
      fechaAgendamiento: { title: "Fecha de creacion" },
      horas: { title: "Horas" },
      precio: { title: "Precio" },
      recargo: { title: "Recargo" },
      descuento: { title: "Descuento" },
      total: { title: "Total" },
    },
    pager: {
      perPage: 15
    },
  }


  constructor(
    public router: Router,
    private planRecurrenteService: PlanRecurrenteService,
    private funcionesUsuario: funcionesUsuario,
    private ServicioService: ServicioService,
    private usuarioService: UsuarioService,
    public activatedRoute: ActivatedRoute,
    public dialogService: NbDialogService,
    private ServerService: ServerService,
    public toastrService: NbToastrService,
    private PlanService: PlanService,
    public authService: AuthService,
    public _location: Location,
  ) {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.usuarioEnSecion = this.authService.storeGetUserData('usuario');
  }

  ngOnInit() {
    this.getPlan(this.id);
    this.getServers();
  }

  
  /**
   * Funcion para cargar el plan desde la BD
   * @param id 
   */
  public getPlan(id) {
    this.PlanService.getPlanbyId(id).then(async value => {
      this.planMensual = value.data();
      console.log(this.planMensual);
      if(isNaN(this.planMensual.cantidadReagendanientos)){
        console.log("validacion correcta");
        console.log(this.planMensual.cantidadReagendanientos);
      }
      if(this.planMensual.estado == 'Congelado'){
        console.log("plan conglado");
        this.congelado = true;
      }
      this.currentPlanMensual = (await this.planRecurrenteService.getPlanRecurrenteId(id)).data();
      console.log(this.currentPlanMensual);
      this.selectedOptionsDias =  this.currentPlanMensual.diasAgendado

      this.getUser(this.planMensual);
      this.getCobros(this.planMensual);
    });
  }

  public getServer(id) {
    return new Promise(success => {
      this.ServerService.detail(id).then((data) => {
        const server = data.data()
        let nombreCompleto = server.name && server.apellido ? server.name + " " + server.apellido : "Esperando confirmacion de server";
        success(nombreCompleto)
      });
    });
  }

  public getCobros(plan){
    this.ServicioService.getPagosUsuarioByClientId(plan.client.id).then(res=>{
      res.docs.forEach(data=>{
        let cobros = data.data();
        this.cobroClientePlan = cobros;
        let cobrosPlan = cobros.cobrosRealizados.filter(cobro=> cobro.idPlanOServicio == plan.id)
        this.historialDeCobros = cobrosPlan
      })
    })
  }
  verComprobante(id: string ='verComprobanteTs'){
    console.log("etro e ver comprobante");
    console.log(this.comprobanteAvisualizar);
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  public getUser(plan) {
    this.usuarioService.detail(plan.userid).then(res => {
      this.usuario = res.data();
      this.getServiciosPlan();
    }, err => {
      console.log(err);
      this.getServiciosPlan();
    });
  }

  public goBack() {
    this._location.back();
  }

  public actualizarPlanRecurrente() {
    this.planRecurrenteService.editPlanRecurrente(this.currentPlanMensual).then(res => {
      console.log(res);
      this.usuario.planRecurrente = 'Si';
      this.usuarioService.updateClient(this.usuario);
      alert('Se actualizo el usuario exitosamente');
    });
  }

  public onNgModelChangeDias($event){
    this.diasPlanRecurrente = $event;
    if(this.editarDiasPlanRec == true){
      this.currentPlanMensual.diasAgendado = $event;
    }
    console.log(this.diasPlanRecurrente);
  }

  public editarDiasPlanRec = false
  public crearPlanRecurrente(){
    if(this.diasPlanRecurrente != undefined && this.horaInicioPlanRec != 'falta'){
      let planRecurrente = {
        id: this.planMensual.id,
        horas:         this.planMensual.horasDeServicio,
        clientId:      this.planMensual.client.id,
        horaInicio:    this.horaInicioPlanRec,
        diasAgendado:  this.diasPlanRecurrente,
        numServicios: this.planMensual.cantidadServicios,
        precioServicio: this.precioPlanRec,
      }
      this.ServicioService.asignarPlanRecurrete(planRecurrente).then(res=>{
        this.MessageReg('Inforacion gurdada','El plan recurrete fue creado con exito','success',false);
      })
    }else{
      this.MessageReg('Alerta','Completa los dos campos','warning',false);
    }
  }

  //Permite cargar la foto
  onUpload(event,fechaDelCobro) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;
    this.image = true;
    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      this.subirImagen(fechaDelCobro);
      this.planMensual.estadoPago = 'Pago';
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public subirImagen(fechaDelCobro) {
    this.ServicioService.putPicture(this.pictureName, this.file).then(value => {
      if (value.state == 'success') {
        this.ServicioService.getURL(this.pictureName).subscribe(url => {
          let cobros = []
          this.cobroClientePlan.cobrosRealizados.map(info=>{
            if(info.idPlanOServicio == this.planMensual.id && info.fechaAgendamiento == fechaDelCobro){
              info.comprobante = url;
              info.estadoDelPago = "Pago";
              cobros.push(info);
            }else{
              cobros.push(info)
            }
            if(cobros.length == this.historialDeCobros.length){
              this.cobroClientePlan.cobrosRealizados = cobros;
              this.ServicioService.actualizarCobro(this.cobroClientePlan)
              Swal.fire("Actualizado","fue actualizado con exito","success").then(res=>{
                const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
                element.click();
              })
            }
          }) 
          if(fechaDelCobro == this.planMensual.fechaReagendamiento){
            this.planMensual.comprobanteActual = url;
            this.planMensual.estadoPago = 'Pago';
            this.source.forEach(async (element) => {
              element.comprobante = url;
              await this.actualizarServicio(element);
            });
            this.actualizarPlan();
         
          }
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  toggleView() { 
    this.flipped = !this.flipped; 
  }

  toggleView2() { 
    this.flipped2 = !this.flipped2; 
  }

  public getServiciosPlan() {
    console.log('Revisando servicios')
    let pushArray: any = [];
    let eliminado = 0;
    this.planMensual.infoServicio.forEach(element => {
      let id = element.idServicios ? element.idServicios : element.id
     console.log("Servicio qu va a busacar",id)
      this.ServicioService.getServiciosbyId(id).then(async value => {
        let x = value.data();
        console.log(x);
        if(x == undefined || x == null){
          console.log("servicioEliminado");
          eliminado++
        }else{
          if(x.idServer == 123){
            x.severCompleteName = x.server.nameServer
            pushArray.push(x);
          }else{
            console.log(x.server)
            x.severCompleteName = x.server.name + " " + x.server.apellido
            pushArray.push(x);
          }
          console.log(pushArray);
          if (pushArray.length == (this.planMensual.infoServicio.length - eliminado)) {
            this.source = pushArray;
            console.log(this.source);
            // this.pudeDesconglarPlan();
          }
        }
      });
    });
    
  }
  public prueba(a){

    console.log(this.editService.hora);
  }

  public actualizarFactura() {
    let hoy = moment();
    this.source.forEach(async (element) => {
      if (moment(element.fecha) >= hoy) {
        element.numFactura = this.planMensual.numeroFactura;
        await this.actualizarServicio(element);
      }
    });
    this.actualizarPlan();
  }

  public actualizarPago() {
    let hoy = moment();
    this.source.forEach(async (element) => {
      if (moment(element.fecha) >= hoy) {
        element.estadoPago = this.planMensual.estadoPago;
        await this.actualizarServicio(element);
      }
    });
    this.actualizarPlan();
  }

  public actualizarDireccion() {
    console.log(this.planMensual.direccion);
    let hoy = moment();
    this.source.forEach(async (element) => {
      if (moment(element.fecha) >= hoy) {
        element.direccion = this.planMensual.direccion;
        await this.actualizarServicio(element);
      }
    });
    this.actualizarPlan();
  }

  public async verificarEstado() {
    if (this.planMensual.estado == 'Cancelado') {
      await this.cancelarServiciosFuturos(this.source)
      this.planMensual.motivoCancelacion = ""
      this.planMensual.fechaCancelacion = moment().format('YYYY-MM-DD');
      this.actualizarPlan();
      this.getPlan(this.id);
    } else if (this.planMensual.estado == 'Congelado') {
      await this.cogelarServiciosFuturos(this.source)
      this.actualizarPlan();
      this.congelado = true;
      this.getPlan(this.id);
    } else {
      this.actualizarPlan();
      this.getPlan(this.id);
    }
  }

  public cogelarServiciosFuturos(servicios) {
    return new Promise(success => {
      let hoy = moment();
      servicios.forEach(async (element) => {
        if (moment(element.fecha) >= hoy) {
          element.estado = 'Congelado';
          await this.actualizarServicio(element);
        }
      });
      success(servicios)
    })
  }

  public decongelar(){
    if(1 == 1){

    }
  }


  public cancelarServiciosFuturos(servicios) {
    return new Promise(success => {
      let hoy = moment();
      servicios.forEach(async (element) => {
        if (moment(element.fecha) >= hoy) {
          element.estado = 'Anulado';
          await this.actualizarServicio(element);
        }
      });
      success(servicios)
    })
  }

  public actualizarServicio(servicio) {
    return new Promise(success => {
      this.ServicioService.updateServicio(servicio).then((res: any) => {
        success(res);
        console.log("servicio actualizado");
      });
    });
  }

  public llamadaReagendamiento() {
    console.log(this.planMensual.llamadaReagendamiento)
    this.actualizarPlan();
  }

  public verServicio(id) {
    this.router.navigate(['dashboard/ver-servicio', id]);
  }

  public onCustom(event) {
    if (event.action == "detalle") {
      this.verServicio(event.data.id);
    } else if (event.action == 'edit') {
      this.openModalEditService(event.data);
    }else if(event.action == "verComprobante"){
      this.comprobanteAvisualizar = event.data.comprobante
      setTimeout( ()=> {
        this.verComprobante()
      }, 1000);
    }else if(event.action == "verCobro"){
      this.cobroVisualizado = event.data
      console.log(this.cobroVisualizado)
       // @ts-ignore 
      this.dialogService.open(this.mdlVerCobro, { context: 'this is some additional data passed to dialog' });
    }
  }

  public openModal() {
    this.openDialogSave(this.reagendarModal);
  }
  /**
   * Funcion para abrir los modales
   * @param dialog Modal que se desee abrir
   */
  public openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  public actualizarPlan() {
    console.log(this.planMensual);
    this.ServicioService.updatePlan(this.planMensual);
    this.historial.name =  this.usuarioEnSecion.displayName
    this.openModal();
  }

  public onActualizarPlan() {
    this.ServicioService.updatePlan(this.planMensual);
  }

  public openModalEditService(servicio) {
    this.editService = servicio;
    console.log(this.editService);
    // this.getServicesByDate(this.editService.fecha);
    this.openDialogSave(this.editServicio);
  }

  public goToReagendar() {
    this.router.navigate(['dashboard/multiples-servicios', { reagendar: JSON.stringify(this.planMensual) }]);
  }

  public onUpdatedService() { }

  public getDisponibility(services) {
    this.serverList.forEach(element => {
      element.servicios = services.filter(obj => obj.idServer == element.id);
    });
  }

  public onOpenServices() {
    this.openDialogSave(this.editServicio);
  }

  public onActualizarServicio(service) {
    service.server = this.serverNueva;
    service.idServer = this.serverNueva.id;
    service.estado = "Aceptado";
    service.serverReasignada = true;
    let co = 0
    for(let servis of this.planMensual.infoServicio){
      console.log(servis,service)                              
      if(service.id == servis.idServicios){
       console.log("paso el if", this.planMensual.infoServicio[co].fecha );
        this.planMensual.infoServicio[co].fecha = service.fecha
        this.ServicioService.updatePlan(this.planMensual);
      }
      co++
    }
    this.ServicioService.updateServicio(service).then((res: any) => {
      console.log(res);
      this.Message('Resgistro!', 'Servicio actualizado con éxito!', 'success', false, 'btn-close-save', false);
    });
    // let count = this.planMensual.infoServicio.length;
    // let con = 0;
    // this.planMensual.infoServicio.forEach(element => {
    //   if(element.idServicios = service.id){
    //     element.fecha = service.fecha;
    //     con++
    //   }else{con++};
    // });
    // if(count == con){
    //   this.ServicioService.updatePlan(this.planMensual);
    // }
    this.serverSeleccionada = [];
    this.horasDisponibles = [];
    this.pudeDesconglarPlan();
  }

  public btnGuardarServer = false;
  public infoServiciosLength: number = 0;
  public infoServiciosContador: number = 0;
  getDisponibilidadMultipe(){
    this.cambioMultiple = true
    this.disponibilidadArray = []
    this.infoServiciosContador = 0
    this.infoServiciosLength = this.planMensual.infoServicio.length
    this.planMensual.infoServicio.forEach( async element => { 
      console.log(element.fecha);
      let disponibilidad  = await this.verificarDisponibilidad(element.fecha)
      console.log(disponibilidad);
    });    
  }
  private Message(
    title: string, 
    message: string, 
    type: SweetAlertType, 
    close: boolean, 
    modal: string = 'btn-close-save', 
    reset: boolean){
    setTimeout( ()=> {
      Swal.fire(title, message, type);
    }, 900);
  }

  public pudeDesconglarPlan(){
    console.log('descongelando');
    let length = this.source.length;
    let count = 0;
    let hoy = moment().format('YYYY-MM-DD')
    console.log(this.source,hoy)
    this.source.forEach(element => {
      console.log(element)
      if(element.estado == "Pendiente"){
        console.log('pendiente');
        if(element.fecha > hoy){
          this.descongelable = true
        }else{console.log(element.fecha,hoy)}
      }
    });
    if(this.descongelable == true){
      this.planMensual.estado = 'Activo'
      this.verificarEstado();
    }
  }

  public onChangeServer() {
    this.serverNueva = this.serverList.filter((server)=> server.id == this.serverSeleccionada)[0];
    console.log(this.serverNueva);
    // let indexServer = this.serverList.findIndex(server => server.id == service.idServer);
  }

  public getServicesByDate(date) {
    this.ServicioService.getByDate(date).then(element => {
      let services = [];
      let count = 0;
      element.forEach(doc => {
        services.push(doc.data());
        count++;
        if (count == element.docs.length) {
          this.getDisponibility(services);
        }
      });
    });
  }

  /**
   * Funcion para cerrar el modal
   */
  public cerrarModal() {
    this.spinerEdit = false;
    const element: HTMLElement = document.getElementById("btn-close") as HTMLElement;
    element.click();
  }

  public actualizarHistorial() {
    this.cerrarModal();
    if (this.planMensual.historial == undefined) {
      this.planMensual.historial = [this.historial];
      this.sourceHistorial.push(this.historial);
    } else {
      this.planMensual.historial.push(this.historial);
      this.sourceHistorial.push(this.historial);
    }
    if(this.motivoCancelacion != "NaN"){
      this.planMensual.motivoCancelacion = this.motivoCancelacion 
    }

    this.ServicioService.updatePlan(this.planMensual);
    this.historial = {
      name: '',
      cambios: '',
    }
    console.log(this.planMensual.historial)
    console.log(this.sourceHistorial);
  }

  async getServers() {
    let source = [];
    const a = await this.ServerService.Actives();
    a.docs.forEach((res,index)=>{
      // console.log(res.data());
      source.push(res.data());
      if(index == (a.size - 1) ){
        source.filter((server) =>
          (server["nombreServer"] = `${server.name} ${server.apellido}`) &&
          server.id != "56VnqwDwXhdWQToWbflQMJEMcyB2"
        ); // cambiar el 2 final por un 3 para sacar adigital
        this.serverList = source;
        console.log("Lista server de listar-plan",this.serverList)
      }
    })
  }

  public onSelectServer(event) {
    console.log("evento",event);
    this.serverSeleccionadaDatos = event
    this.planMensual.server = event
    this.planMensual.idServers = event.id;
    this.serverSeleccionada = event.id;
    console.log("Server seleccionada",this.serverSeleccionadaDatos);
    if(this.planMensual.estado == "Orden de plan"){this.planMensual.estado = "Activo"};
  }

  public precioPlan() {
    let precioIndividual;
    let servicioTotal;
    let cantidadServicio = parseInt(this.planMensual.cantidadServicios);
    let duracion = parseInt(this.planMensual.horasDeServicio);
    switch (duracion) {
      case 2:
        switch (cantidadServicio) {
          case 2: case 4: case 8: case 12:
            precioIndividual = 42500;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 16: case 20: case 24:
            precioIndividual = 40000;
            servicioTotal = precioIndividual * cantidadServicio
            break;
        }
        break;
      case 4:
        switch (cantidadServicio) {
          case 2: case 4:
            precioIndividual = 57500;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 8:
            precioIndividual = 54375;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 12:
            servicioTotal = 650000;
            precioIndividual = servicioTotal / cantidadServicio;
            break;
          case 16:
            servicioTotal = 865000;
            precioIndividual = servicioTotal / cantidadServicio
            break;
          case 20:
            servicioTotal = 1080000;
            precioIndividual = servicioTotal / cantidadServicio
            break;
          case 24:
            servicioTotal = 1300000;
            precioIndividual = servicioTotal / cantidadServicio
            break;
        }
        break;
      case 6:
        switch (cantidadServicio) {
          case 2: case 4: case 8:
            precioIndividual = 67500;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 12: case 16: case 20:
            precioIndividual = 65000;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 24:
            precioIndividual = 68750;
            servicioTotal = precioIndividual * cantidadServicio
            break;
        }
        break;
      case 8:
        switch (cantidadServicio) {
          case 2: case 4: case 8:
            precioIndividual = 77500;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 12: case 16: case 20:
            precioIndividual = 75000;
            servicioTotal = precioIndividual * cantidadServicio
            break;
          case 24:
            servicioTotal = 1900000;
            precioIndividual = servicioTotal / cantidadServicio;
            break;
        }
        break;
    }
    if (!this.planMensual.descuento) {
      this.planMensual.precioIndividual = precioIndividual;
      this.planMensual.precio = servicioTotal;
    } else {
      this.planMensual.precio = servicioTotal - this.planMensual.descuento;
      this.planMensual.precioIndividual = this.planMensual.precio / cantidadServicio;
    }
    this.planMensual.totalServicios = cantidadServicio;
    this.setNamePLan();
  }

  public setNamePLan() {
    if (this.planMensual.cantidadServicios) {
      if (this.planMensual.cantidadServicios == 2) {
        this.planMensual.NombreServicio = "SERV LITE";
      } else if (this.planMensual.cantidadServicios == 4) {
        this.planMensual.NombreServicio = "SERV PLUS";
      } else if (this.planMensual.cantidadServicios == 8) {
        this.planMensual.NombreServicio = "SERV PRO";
      } else if (this.planMensual.cantidadServicios >= 12 && this.planMensual.cantidadServicios <= 20) {
        this.planMensual.NombreServicio = "SERV YOU (DISEÑO A LA MEDIDA)";
      } else if (this.planMensual.cantidadServicios == 24) {
        this.planMensual.NombreServicio = "SERV FULL";
      }
    } else {
      this.planMensual.NombreServicio = "-";
    }
    this.actualizarPlan();
  }

  public calcularHorasPosibles(numeroHoras) {
    let horaMaximaServicio = this.horaMaxima - numeroHoras;
    let arregloHoras = [];
    for (let i = this.horaMinima; i <= horaMaximaServicio; i++) {
      arregloHoras.push(i);
    }
    return arregloHoras;
  }

  public verificarDisponibilidad(fecha){
    console.log("entro en la funcion",fecha)
    let horasTotales = [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
    let horasPosibles;
    let horasEliminar: any = [];
    this.ServicioService.getByServerOnADate(this.serverSeleccionada, fecha).then(async (Dato) => {
      console.log(Dato.docs);
      if(Dato.docs.length > 0){
        Dato.forEach(async element => {
          let horaOcupada = false;
          var info = element.data();
          console.log("Servicios que ya tenia ese dia",info);
          console.log(info);
          if (info.estado != "Anulado" && info.estado != "Cancelado" && info.estado != "Congelado") {
            horaOcupada = true;
            let hora = parseFloat(info.hora);
            horasPosibles = this.calcularHorasPosibles(this.planMensual.horasDeServicio);
            let length = horasPosibles.length;
            this.horasDisponibles = [];
            for (let i = 0; i < length; i++) {
              if (horasPosibles[i] >= (hora - (this.planMensual.horasDeServicio)) && horasPosibles[i] <= (hora + info.horasDeServicio)) {
                horasEliminar.push(horasPosibles[i]);
              }else{
                this.horasDisponibles.push(horasPosibles[i])
                console.log(this.horasDisponibles)
              }
              if((this.horasDisponibles.length + horasEliminar.length) == horasPosibles.length){
                if(this.cambioMultiple == true){
                  await this.verificarDisponibilidadCambioMultiple(this.horasDisponibles,fecha)
                }
              }
            }
          }
         /*  for ( let i = 0; i < horasPosibles.length; i++) {
            let encontro = false;
            for (let j = 0; j < horasEliminar.length; j++) {
              // console.log(horasPosibles,horasEliminar)
              // console.log(horasPosibles[i],horasEliminar[j])
              if ((horasPosibles[i] == horasEliminar[j])) {
                encontro = true;
              }
            }
            if (!encontro) {
              let array = []
              array.push(horasPosibles[i]);
              this.horasDisponibles.push(horasPosibles[i]);
              console.log(this.horasDisponibles);
            } 
            // console.log( horasPosibles.length,i)
          }*/
        });
      
      }else{
        this.horasDisponibles = this.calcularHorasPosibles(this.planMensual.horasDeServicio);
        if(this.cambioMultiple == true){
          let disponible = {
            fecha: fecha,
            estado: "disponible"
          }
          this.disponibilidadArray.push(disponible)
          this.infoServiciosContador ++
          console.log(this.infoServiciosContador ,this.infoServiciosLength)
          if(this.infoServiciosContador == this.infoServiciosLength){
            this.btnGuardarServer = true
          }
        }
        console.log(this.horasDisponibles);
      }
    })
  }

  public verificarDisponibilidadCambioMultiple(array, fecha){
    console.log("entro a verificar el array",array,fecha)
    let sinDisponibilidad = true
    let c = this.c
    let hora = parseInt(this.source[c].hora)
    let disponible = array.filter(horaI=>horaI == hora)[0]
    console.log(disponible)
    if(disponible){
      console.log("horaDisponible");
        sinDisponibilidad = false
        let disponible = {
          fecha: fecha,
          estado: "disponible"
        }
        this.disponibilidadArray.push(disponible)
        console.log(this.disponibilidadArray)
        this.infoServiciosContador++
        // console.log(this.infoServiciosContador ,this.infoServiciosLength)
        if(this.infoServiciosContador == this.infoServiciosLength){
          this.btnGuardarServer = true
        }
    }else{
      let disponible = {
        fecha: fecha,
        estado: "no disponible"
      }
      this.disponibilidadArray.push(disponible);
      console.log(this.disponibilidadArray)
      this.infoServiciosContador++
      // console.log(this.infoServiciosContador ,this.infoServiciosLength)
      if(this.infoServiciosContador == this.infoServiciosLength){
        this.btnGuardarServer = true
      }
    }
    this.c ++
  }

  public asignarServermultiplesServicios(){
    console.log(this.disponibilidadArray)
    let count = 0;
    let pasa = true
    for(let disponibilidad of this.disponibilidadArray){
      count++
      console.log(disponibilidad)
      if(disponibilidad.estado == "no disponible"){
        console.log("no disponible")
        pasa = false
        alert(`Revisar la disponibilidad de la server para el dia ${disponibilidad.fecha}`)
      }
      if(count ==  this.disponibilidadArray.length){
        console.log("Todos verificado para cambio")
        if(pasa == true ){
          let contador = 0
          this.source.forEach(async (element) => {
            element.idServer = this.serverSeleccionada;
            element.server = this.serverSeleccionadaDatos;
            if(element.estado == "Orden de plan"){element.estado= "Pendiente"};
            console.log(element)
            await this.actualizarServicio(element);
            contador++
            if(contador == this.source.length ){
              alert("La server se a cambiado")
              this.actualizarPlan();
            }
          });
        }

      }
    }
  }
  /* Variables para reagendamintoPlan */
  public planEnReagendamiento: any;
  public serviciosPrestados: any = serviciosPrestados;
  public categoria: any;
  public subCategoria: any;
  public horasDeServicioReg: any;
  public diaInterface: Array<DiaInterface> = [];
  public horasInterface: Array<HorasInterface> = [];
  public valorInterface: Array<ValorInterface> = [];
  public horarioInterface: Array<HorarioInteface> = [];
  public preciosServicios: any = preciosServicios;

  public checklist: any;
  public checkListFinal: any = null;
  public checkListAdicionalServicioElegido: any = [];

  public frecuenciaReg: string = "";
  public diasSeleccionados: any = [];
  public arrayFechasServicios: any = [];
  public arrayPreInfoServiciosPlan: any = [];
  public tipoAgendamiento: string ;
  public keywordServer = "nombreServer"
  public listaCalendarioServicio: any = []
  public horasDeInicio = [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
 
  public serversDisponibles: any = [];
  public recargo = 0; 
  public descuento = 0;
  public precioPlanReg: number;

  public flippedPlanes = false
  public refresh: Subject<any> = new Subject();
  public view: CalendarView = CalendarView.Month;
  public viewDate: Date = new Date();
  public CalendarView = CalendarView;
  

  public selectedMonthViewDay: CalendarMonthViewDay;
  public arrayServiciosByServer = [];
  public horaMinima: number = 6;
  public horaMaxima: number = 22;
  public horasTotales: any = [];

  public selectDireccionReg: string = "direc1";
  public serverSelectReg: any;
  public cantidadServiciosReg: number = 0;
  public infoDate = [];
  public horasEliminar = [];
  public horasPosibles = [];
  public horario: string = "";
  public estadoPagoReg: string;
  public metodoPagoReg: string = "";
  public fechaLimitePagoReg: string;
  public plazoDePagoReg: string = "";
  public numeroFacturaReg: string = "falta";
  public referenciaPagoReg: string = "falta";
  public comprobanteReg: string = "";

  public servicioReg: string = "";
  public categoriaReg: string = "";
  public subCategoriaReg: string = "";
  public precioPlanRec: number = 0;

  editPrecioPlan:boolean = false;
  editRecargo:boolean = false;
  editDescuento:boolean = false;
  public datosParaRegPlan: any;
  /* funciones para el reagendamiento */
  
  
  abrirReagendarPlan(){
    this.planEnReagendamiento = this.planMensual;
    this.planEnReagendamiento.destination = null
    this.usuarioService.detail(this.planMensual.userid).then((item) => {
      this.planEnReagendamiento.client = item.data();
      console.log(this.planEnReagendamiento);
    });
    this.resetModalReagendar()
    this.openDialogSave(this.reagendarPlanModal);
    console.log(this.planEnReagendamiento);
  }
  public indexLugar = 0
  resetModalReagendar(){
    // thuisa,ms sd
    this.indexLugar = null
    this.servicioReg = "";
    this.categoriaReg = "";
    this.plazoDePagoReg = "";
    this.subCategoriaReg = "";
    this.tipoAgendamiento = "";
    this.diasSeleccionados = [];
    this.horasDeServicioReg = 0;
    this.cantidadServiciosReg = 0;
    this.arrayPreInfoServiciosPlan = [];
    this.metodoPagoReg = this.planEnReagendamiento.formaDePago;
  }

 
  elegirDireccionPlanReg(event){
    let a = parseInt(event) 
    this.planEnReagendamiento.destination = this.planEnReagendamiento.client.direccionesArray[a]
    this.planEnReagendamiento.direccion = this.planEnReagendamiento.destination.direccion
  }

  OpcionFiltro(){
    if(this.tipoAgendamiento == "server"){
      this.filtroServersBlackList(this.planEnReagendamiento.client.blackList)
   }else{
    (<HTMLInputElement>document.getElementById("elegirServicio")).disabled = false;
   }
  }

  public serversPosibles: any = [];
  public serversSerca: any = [];
  public serversConLaHabilidad: any = [];
  public serversSinDiaBloqueado: any = [];
  public serversAsignables: any = [];
  public loadingTablaPlan: boolean = false


  filtroServersBlackList(blackList){
    this.serversPosibles = [];
    let contador = 0;
    console.log("ENTRO EN filtroServersBlackList()")
    if(blackList != undefined){
      this.serverList.forEach(server => {
        let bloqueada = blackList.filter(serv=>serv.id == server.id)
        console.log(bloqueada)
        if(bloqueada.length > 0){
          console.log("serverBloqueada")
          contador++
        }else{
          this.serversPosibles.push(server)
          contador++
        } 
        if(contador == this.serverList.length){
          setTimeout(()=>{
            this.filtroServersLatLng()
          },100)
        }
      });
    }else{
      this.serversPosibles = this.serverList
      setTimeout(()=>{
        this.filtroServersLatLng()
      },100)
    }
  }

  filtroServersLatLng(){
    this.serversSerca = []
    let contador = 0
    console.log("ENTRO EN filtroServersLatLng()",this.serversPosibles)
    const haversine = require("haversine-distance");
    const a = {
      latitude: this.planEnReagendamiento.destination.lat,
      longitude: this.planEnReagendamiento.destination.lng,
    };
    this.serversPosibles.forEach(server => {
      const b = {
        latitude: server.gps.lat,
        longitude: server.gps.lng,
      }
      let diferenciaCoordenadas = haversine(a, b);
      console.log(diferenciaCoordenadas)
      if(diferenciaCoordenadas <= 25000){
        this.serversSerca.push(server);
        // console.log("Servers serca",server,server.name);
        contador++
      }else{
        contador++
      }
      if(contador == this.serversPosibles.length){
        console.log("Servers Serca",this.serversSerca);
        (<HTMLInputElement>document.getElementById("elegirServicio")).disabled = false;
      }
    });
  }

  async espesificacionesDelServicio(event, type){
    const name: string = event.target.value; 
    let filter = null
    console.log(name);
    if(type == "categoria"){
      filter = await this.serviciosPrestados.filter((tipoServicio) => tipoServicio.name == name)[0];
      this.categoria = filter[type];
    } else if(type == "subCategoria"){
      filter = await this.categoria.filter((subCategoria) => subCategoria.name == name)[0];
      this.subCategoria = filter[type];
    } else if(type == "checklist"){
      filter = await this.subCategoria.filter ((checkList) => checkList.name == name)[0];
      this.checklist = filter[type];
      if(this.tipoAgendamiento == 'server'){
        this.filtroServersHabilidades()
      }else{
        (<HTMLInputElement>document.getElementById("elegirHoras")).disabled = false;
      }
    }
  }
  filtroServersHabilidades(){
    let contador = 0
    this.serversConLaHabilidad = [];
    this.serversSerca.forEach(async server => {
      let conHbilidad = await server.habilidades.filter(habilidad=> habilidad.lugar == this.servicioReg && habilidad.subCategoria == this.subCategoriaReg)
      // console.log(conHbilidad);
      if(conHbilidad.length > 0){
        this.serversConLaHabilidad.push(server)
        contador++
        console.log(this.serversConLaHabilidad)
      }else{
        contador++
      }
      console.log(contador && this.serversSerca.length)
      if(contador == this.serversSerca.length){
        if( this.serversConLaHabilidad.length > 0){
          setTimeout(()=>{
            (<HTMLInputElement>document.getElementById("elegirHoras")).disabled = false;
          },100)
        }else{
          Swal.fire("OOPS😱","No tenemos servers diponibles con esa habilidad")
        }
      }
      /* console.log(server.habilidades) */
    
    });
  }

  public valorarFrecuencia( type) {
    const name: string = this.cantidadServiciosReg.toString();
    console.log(this.cantidadServiciosReg);
    if (type == 'frecuencia') {
     if (this.cantidadServiciosReg >= 2 && this.cantidadServiciosReg <= 3) {
        this.frecuenciaReg = "Unitario";
      } else if (this.cantidadServiciosReg >= 4 && this.cantidadServiciosReg <= 7) {
        this.frecuenciaReg = "1 por semana";
      } else if (this.cantidadServiciosReg >= 8 && this.cantidadServiciosReg <= 16) { 
        this.frecuenciaReg = "2 a 4 por semana";
      } else if (this.cantidadServiciosReg >= 20) {
        this.frecuenciaReg = "5 a 6 por semana";
      }
    }
    this.valoracionPrecioPlanes(name, type);
  }

  elegirFecha(dia){
    let date:any; 
    let diaServicio: string = ""
    let dow = dia.day
    let recargoServicioPlan = 0 
    console.log(dow);
    if (dow == 0) {
      this.recargo += 10000;
      recargoServicioPlan = 10000; 
      diaServicio = "domingo";
    } else if (dow == 1) {
      diaServicio = "lunes";
    } else if (dow == 2) {
      diaServicio = "martes";
    } else if (dow == 3) {
      diaServicio = "miercoles";
    } else if (dow == 4) {
      diaServicio = "jueves";
    } else if (dow == 5) {
      diaServicio = "viernes";
    } else if (dow == 6) {
      diaServicio = "sabado";
    }
 
    let dateIndex: any;
    console.log(dia)
    if (this.diasSeleccionados) {
      dateIndex = this.diasSeleccionados.findIndex(
        (selectedDay) => selectedDay.date.getTime() === dia.date.getTime()
      );
    }
    if(dateIndex >= 0){
      delete dia.cssClass;
      this.diasSeleccionados.splice(dateIndex, 1);
      this.arrayPreInfoServiciosPlan.splice(dateIndex, 1);
    }else{
      if(this.cantidadServiciosReg > this.diasSeleccionados.length){
        this.diasSeleccionados.push(dia);
        let idServicio = new Date().getTime()
          let infoServicio = {
          fecha: moment(dia.date.getTime()).format("YYYY-MM-DD"),
          id: idServicio,
          idServicios: idServicio,
          hora: "",
          idServer: "",
          dia: diaServicio,
          horario: "",
          precio: 0,
          recargo: recargoServicioPlan,
          total: 0,
          gananciaServer: 0,
          estado: "Pendiente",
        };
        this.arrayPreInfoServiciosPlan.push(infoServicio);
        console.log("Pre info servicios",this.arrayPreInfoServiciosPlan)
        if(this.cantidadServiciosReg == this.diasSeleccionados.length && this.tipoAgendamiento == "server") {
          this.filtroServersPlanDiasBloqueados();
        }else if(this.cantidadServiciosReg == this.diasSeleccionados.length){
          this.flippedPlanes = !this.flippedPlanes
        }
      }else{
        console.log("Ya selecciono el maximo")
      }
    }
    this.refreshView();
  }
  refreshView(){
    this.refresh.next();
  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    console.log("Entro en la funcion de beforeMonthViewRender")
    if (this.diasSeleccionados) {
      body.forEach(day => {
        for (let fechaNoDisponible of this.diasSeleccionados) {
          if (fechaNoDisponible.date.getTime() == day.date.getTime()) {
            day.cssClass = 'diaSeleccionado-calendario-agendarPlan';
          }
        }
      });
    }else{
      console.log(this.diasSeleccionados);
    }
  }

  filtroServersPlanDiasBloqueados(){
    console.log("Entro en filtroServersPlanDiasBloqueados()")
      this.loadingTablaPlan = true
      let contador = 0
      this.serversSinDiaBloqueado = []
      this.serversConLaHabilidad.forEach(server => {
        console.log(1)
        this.ServerService.getDisponibilidad(server.id).onSnapshot(async value=>{
          if (value.data() != undefined) {
            let info = value.data();
            let disponible = true
            let contadorServer = 0;
            for(let servicio of this.arrayPreInfoServiciosPlan){
              let bloqueada = await info.fechas.filter(fecha => fecha.fecha == servicio.fecha)
              if(bloqueada.length > 0 ){
                 disponible = false
                 contadorServer ++
              }else{
                contadorServer++
              }
              if(contadorServer == this.arrayPreInfoServiciosPlan.length){
                if(disponible == true){
                  this.serversSinDiaBloqueado.push(server)
                  contador++
                  console.log("SERVER CON ESOS DIAS NO BLOQUEDOS",this.serversSinDiaBloqueado)
                }else{
                  contador++
                }
              }
            }
          }else{
            this.serversSinDiaBloqueado.push(server)
            contador++
            console.log(value,"Server sin bloqueoS",this.serversSinDiaBloqueado)
          }
          console.log(contador, this.serversConLaHabilidad.length)
          if(contador == this.serversConLaHabilidad.length){
            this.loadingTablaPlan = false
            this.flippedPlanes = !this.flippedPlanes
          }
        })
      });
    
  }

  public toggleViewPlanes(){
    this.flippedPlanes = !this.flippedPlanes;
  }
  onSelectServerReagendar(event){
    console.log(event)
    this.serverSelectReg = event;
  }
 
  
  public elegirHora(event, i) {
    let numero = parseInt(event)
    if (numero >= 1 && numero < 14) {
      this.arrayPreInfoServiciosPlan[i].horario = "mañana"
      console.log(this.arrayPreInfoServiciosPlan[i].horario);
      this.horario = "mañana"
    } else {
      this.arrayPreInfoServiciosPlan[i].horario = "tarde"
      this.horario = "tarde"
    }
    this.valoracionPrecioPlanes(i, "valoracion");
  }
  async valoracionPrecioPlanes(name: any, type: string) {
    let filter = null;
    let num = 0;
    if (type == "frecuencia") {
      filter = await this.preciosServicios.filter((tipoServicio) => tipoServicio.name == this.frecuenciaReg)[0];
      this.horasInterface = filter["horas"];
      console.log(this.horasInterface)
    } else if (type == 'dia') {
      const nameString: string = name.target.value;
      console.log("entro en dia",nameString);
      filter = await this.horasInterface.filter((horasServicio) => horasServicio.name == nameString)[0];
      this.diaInterface = filter[type];
      console.log(this.diaInterface)
    }else if("valoracion"){
      let filterDos: any;
      filter = await this.diaInterface.filter((dia) => dia.name == this.arrayPreInfoServiciosPlan[name].dia)[0];
      this.horarioInterface = filter["horario"];
      console.log("horasio para ese servicio",this.horarioInterface)
      filterDos = await this.horarioInterface.filter((horario) => horario.name == this.arrayPreInfoServiciosPlan[name].horario)[0];
      console.log(filterDos)
      let precio = filterDos['precio'];
      this.arrayPreInfoServiciosPlan[name].precio = precio.valor;
      this.arrayPreInfoServiciosPlan[name].gananciaServer = precio.gananciaServer
      console.log(  this.arrayPreInfoServiciosPlan[name])
      this.calcularPrecioPlan()
      console.log(name,this.arrayPreInfoServiciosPlan.length)
      if(name == (this.arrayPreInfoServiciosPlan.length - 1) && this.tipoAgendamiento == "server"){
        this.filtroServersPlanHorarioDisponible()
      }
    } 
  }

  public contadorFechasServiciosPlanVerificadas = 0
  filtroServersPlanHorarioDisponible(){
      this.loadingTablaPlan = true
      let serversParaFiltro: any;
      let lengthServerParFiltro = 0
      if(this.contadorFechasServiciosPlanVerificadas == 0){
        serversParaFiltro = this.serversSinDiaBloqueado
        lengthServerParFiltro = this.serversSinDiaBloqueado.length
      }else{
        serversParaFiltro = this.serversAsignables;
        lengthServerParFiltro = this.serversAsignables.length
      }
      console.log("entro en filtroServersHorarioDisponible() servers a verificar",serversParaFiltro)
      let contador = 0
      this.serversAsignables = []
      this.planEnReagendamiento.server = null
      let horaServicio = parseInt(this.arrayPreInfoServiciosPlan[this.contadorFechasServiciosPlanVerificadas].hora)
      let horaFinalServcio = horaServicio + parseInt(this.horasDeServicioReg)
      console.log(`horario Seleccionado inicio ${horaServicio} fin ${horaFinalServcio} `)
      serversParaFiltro.forEach(server => {
        let disponible: boolean = true;
        let contadorServicios = 0;
        this.ServicioService.getByServerOnADate(server.id,this.arrayPreInfoServiciosPlan[this.contadorFechasServiciosPlanVerificadas].fecha).then(res=>{
          console.log(server.name + " " + server.apellido,res.docs.length)
          if(res.docs.length > 0){
            res.docs.forEach(data=>{
              let servicio = data.data()
              if(servicio.estado != "Cancelado" || servicio.estado != "Anulado" || servicio.estado != "Congelado"){
                let horaI = parseInt(servicio.hora)
                let horaF = horaI + servicio.horasDeServicio;
                console.log(`Hora de inicio ${horaI} hora fin ${horaF}`)
                if(horaServicio == horaI){
                  contadorServicios++
                  disponible = false
                  console.log("Server Ocupada")
                }else if(horaServicio >= horaI && horaServicio <= horaF){
                  contadorServicios++
                  disponible = false
                  console.log("hoRA SELCCIONADA ESTA ENTRE OTTRO SERVICIO")
                }else if(horaFinalServcio >= horaI && horaFinalServcio <= horaF){
                  contadorServicios++
                  disponible = false
                  console.log("El horarioTermina en medio de otro servicio")
                }else{
                  contadorServicios++
                  disponible == false ? disponible = false : disponible = true
                }
              }else{
                contadorServicios++
              }
            
              console.log("Contador servicios",contadorServicios, res.docs.length)
              if(contadorServicios == res.docs.length){
                contador++
                if(disponible == true){
                  console.log("SERVER TOTALMENTE DISPONIOBLE!!")
                  this.serversAsignables.push(server)
                } 
                if(contador == lengthServerParFiltro){
                  if(this.serversAsignables.length == 0){
                    Swal.fire("OOPS😱","No tenemos server disponibles para esa hora, prueba otro horario o cambia el día","error")
                  }else{
                    this.contadorFechasServiciosPlanVerificadas++
                    console.log("TERMINO DE VERIFICAR CON SERVICIOS",this.serversAsignables)
                    if(this.contadorFechasServiciosPlanVerificadas < this.arrayPreInfoServiciosPlan.length){
                      this.filtroServersPlanHorarioDisponible()
                    }else{
                      this.loadingTablaPlan = false
                      this.contadorFechasServiciosPlanVerificadas = 0
                    }
                  }
                }
              }
            })
          }else{
            this.serversAsignables.push(server)
            contador++
            console.log("constador general",contador, lengthServerParFiltro)
            if(contador == lengthServerParFiltro){
              this.contadorFechasServiciosPlanVerificadas++
              console.log("TERMINO DE VERIFICAR EN UNA QUE NO TIEN SERVICIOS",this.serversAsignables)
              if(this.contadorFechasServiciosPlanVerificadas < this.arrayPreInfoServiciosPlan.length){
                this.filtroServersPlanHorarioDisponible()
              }else{
                this.loadingTablaPlan = false
                this.contadorFechasServiciosPlanVerificadas = 0
              }
            }
            console.log("SERVER TOTALMENTE DISPONIOBLE!!")
          }
        })
      });
    
  }

  agregarAlCheckList($event) {
    console.log($event);
    let seleccion = $event;
    this.checkListFinal = seleccion.map(function (name) {
      return name.name;
    });
    console.log(this.checkListFinal);
  }
  public otroCheck(type) {
    let otro = { name: "" };
    if (type == "add") {
      this.checkListAdicionalServicioElegido.push(otro);
    } else if (type == "remove") {
      this.checkListAdicionalServicioElegido.pop();
    }
  }
  calcularPrecioPlan() {
    let sumPre = 0;
    let sumRec: number = 0;
    this.arrayPreInfoServiciosPlan.map((valor) => {
      sumPre = valor.precio + sumPre;
      sumRec = valor.recargo + sumRec;
      valor.total = valor.precio + valor.recargo;
      this.precioPlanReg = sumPre;
      this.recargo = sumRec;
      this.planEnReagendamiento.total = this.precioPlanReg + this.recargo - this.descuento;
      this.planEnReagendamiento.precioIndividual = this.planEnReagendamiento.total /this.cantidadServiciosReg;
    });
 
    console.log(this.precioPlan);
  }
  recalcularPrecioPlan(tipo){
    if(tipo == 'precioIndi'){
      let precio =  this.planEnReagendamiento.precioIndividual*this.cantidadServiciosReg
      if(precio > this.precioPlanReg){
        this.recargo = precio - this.precioPlanReg
        this.descuento = 0
      }else if(precio < this.precioPlanReg){
        this.descuento = this.precioPlanReg - precio
        this.recargo = 0
      }

    }
    this.planEnReagendamiento.total = this.precioPlanReg + this.recargo - this.descuento
  }
  verificarEstadoPago(plazoPago){
    this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan,["fecha"],["asc"]);
    if(plazoPago == "pago adelantado"){ 
      let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha
      this.planEnReagendamiento.fechaLimitePago = moment(primeraFecha).subtract(1,"days").format("YYYY-MM-DD")    
      this.planEnReagendamiento.plazoDePago  = "Pago adelantado";
      this.planEnReagendamiento.estadoPago = "Pago"
      console.log(this.planEnReagendamiento.plazoDePago , this.planEnReagendamiento.fechaLimitePago);
    }else{
      let a = parseInt(plazoPago)
      let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha
      this.planEnReagendamiento.fechaLimitePago = moment(primeraFecha).add(a,"days").format("YYYY-MM-DD")
      this.planEnReagendamiento.plazoDePago = `A ${a} días`
      this.planEnReagendamiento.estadoPago = "Pendiente"
      this.comprobanteReg= "falta"
      console.log(this.planEnReagendamiento.plazoDePago , this.planEnReagendamiento.fechaLimitePago);
    }
  }

  cargarComprobanteReagendamiento(event) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;
    this.image = true;
    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      this.subirImgReagendamiento();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public subirImgReagendamiento() {
    this.ServicioService.putPicture(this.pictureName, this.file).then(value => {
      if (value.state == 'success') {
        this.ServicioService.getURL(this.pictureName).subscribe(url => {
          this.comprobanteReg = url;    
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  reagendarServiciosDelPlan(){
    let contador = 0
    this.arrayPreInfoServiciosPlan.forEach(element => {
      let servicio: any = {
        asesor:this.usuarioEnSecion.displayName,
        planAsociado: this.planEnReagendamiento.id,
        categoria: this.categoriaReg,
        checkList: this.checkListFinal,
        // cities: this.direccionServicio.cities,
        client: this.planEnReagendamiento.client,
        comprobante: this.comprobanteReg,
        // country: this.direccionServicio.country, //Ya esta en destination
        creado: "admin",
        // department: this.direccionServicio.department, //Ya esta en destination
        destination: this.planEnReagendamiento.destination,
        dia: element.dia,
        direccion: this.planEnReagendamiento.destination.direccion,
        estado:  this.tipoAgendamiento == "server" ? "Pendiente" : "Orden de servicio",
        estadoPago: this.estadoPago,
        fecha: element.fecha,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.planEnReagendamiento.fechaLimitePago,
        formaDePago: this.metodoPagoReg,
        hora: element.hora + ":00",
        horario: element.horario,
        horasDeServicio: parseInt(this.horasDeServicioReg),
        id: element.id,
        idServer: this.tipoAgendamiento == "server" ? this.serverSelectReg.id : "",
        // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
        novedad: false,
        numFactura: this.numeroFacturaReg,
        observaciones: this.planEnReagendamiento.destination.observaciones,
        precio: element.precio,
        recargo: element.recargo,
        referenciaPago: this.referenciaPagoReg,
        server:  this.tipoAgendamiento == "server" ? this.serverSelectReg : "",
        servicio: this.servicioReg,
        subCategoria: this.subCategoriaReg,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: "Plan Mensual",
        total:  element.precio + element.recargo,
        userid: this.planEnReagendamiento.client.id,
        subasta: false,
        estadoPagoServer: "Pendiente",
        gananciaServer: element.gananciaServer,
        gananciaServ: (element.precio + element.recargo) - element.gananciaServer,
      }
      console.log("Datos del servicio que guarda",servicio)
      this.ServicioService.registerServicio(servicio).then(res=>{
        console.log("ServicioGuardado")
        contador++
        if(contador == this.arrayPreInfoServiciosPlan.length){
          Swal.fire("Re-agendado","Has logrado reagendar un plan con exito","success").then(res=>{
            const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
            element.click();
          })
        }
        // Swal.fire("Agendado","El servicio a sido agendado","success");
      })
    });
  }

  reagendarPlan(){
    if(this.planEnReagendamiento.cantidadReagendanientos){
      this.planEnReagendamiento.cantidadReagendanientos = this.planEnReagendamiento.cantidadReagendanientos + 1
    }else{
      this.planEnReagendamiento.cantidadReagendanientos = 1
    }
    if(this.planEnReagendamiento.comprobantePagoHistorial){
      if(this.comprobanteReg != "falta"){
        this.planEnReagendamiento.comprobantePagoHistorial.push(this.comprobanteReg);
      }
    }else{
    this.planEnReagendamiento.comprobantePagoHistorial = [];
    this.planEnReagendamiento.comprobantePagoHistorial.push(this.comprobanteReg);
    }
    
    this.arrayPreInfoServiciosPlan.forEach(info => {
      this.planEnReagendamiento.historialServicios.push(info) 
    });

   
    let plan: any = {
      id: this.planEnReagendamiento.id,
      asesor: this.planEnReagendamiento.asesor,
      cantidadReagendanientos: this.planEnReagendamiento.cantidadReagendanientos,
      cantidadServicios:  this.cantidadServiciosReg,
      categoria: this.categoriaReg,
      // cities: this.direccionServicio.cities,
      client: this.planEnReagendamiento.client,
      comprobanteActual: this.comprobanteReg,
      comprobantePagoHistorial: this.planEnReagendamiento.comprobantePagoHistorial,
      correoPorVencer: false,
      creado: 'admin',
      // department: this.direccionServicio.department,
      descuento: this.descuento,
      destination: this.planEnReagendamiento.destination,
      direccion: this.planEnReagendamiento.destination.direccion,
      enviarCorreo: true,
      estado: this.tipoAgendamiento == "server" ? "Activo" : "Orden de plan",
      estadoConfirmacion: "Pendiente",
      estadoPago:   this.planEnReagendamiento.estadoPago,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaInicio: this.arrayPreInfoServiciosPlan[0].fecha,
      fechaLimitePago:  this.planEnReagendamiento.fechaLimitePago,
      plazoDePago: this.planEnReagendamiento.plazoDePago,
      fechaReagendamiento: moment().format("YYYY-MM-DD"),
      fechaVencimiento: "", // se ajusta aotmaticamentre al cargar la tabla de planes
      formaDePago: this.metodoPagoReg,
      frecuencia: this.frecuenciaReg,
      horasDeServicio: parseInt(this.horasDeServicioReg),
      idLocation: "4fZC8McSlOvpPfIZnG7w",
      historialServicios: this.planEnReagendamiento.historialServicios,
      infoServicio:  this.arrayPreInfoServiciosPlan,
      // neighborhood:  this.direccionServicio.neighborhood,
      numeroFactura: this.numeroFacturaReg,
      observaciones: this.planEnReagendamiento.destination.observaciones,
      precio: this.precioPlanReg,
      precioIndividual: this.planEnReagendamiento.destination,
      recurrente: "si",
      referenciaPago: this.referenciaPagoReg,
      server: this.tipoAgendamiento == "server" ? this.serverSelectReg : { nameServer: "Orden de servicio",id: 123,},
      idServers: this.tipoAgendamiento == "server" ? this.serverSelectReg.id : "",
      serverName:  this.tipoAgendamiento == "server" ? this.serverSelectReg.name + " " + this.serverSelectReg.apellido : "Orden de servicio",
      servicio: this.servicioReg,
      serviciosConsumidos: 0,
      serviciosRestantes: this.cantidadServiciosReg,
      subCategoria: this.subCategoriaReg,
      // tipoPropiedad: this.direccionServicio.tipoPropiedad,
      tipoServicio: "Plan Mensual",
      recargo: this.recargo,
      total: this.precioPlanReg + this.recargo - this.descuento,
      userid: this.planEnReagendamiento.client.id,
      totalServicios: this.cantidadServiciosReg,
    }
   
    let diasServicio = [];
    let contadorDias = 0;
    this.arrayPreInfoServiciosPlan.forEach(element => {
      let numDia =  moment(element.fecha).day()
      let dia = ""
      if (numDia == 0) {
        dia = "domingo";
      } else if (numDia == 1) {
        dia = "lunes";
      } else if (numDia == 2) {
        dia = "martes";
      } else if (numDia == 3) {
        dia = "miercoles";
      } else if (numDia == 4) {
        dia = "jueves";
      } else if (numDia == 5) {
        dia = "viernes";
      } else if (numDia == 6) {
        dia = "sabado";
      }

      let diaAgregado = diasServicio.filter(day=>day == dia)
      if(diaAgregado.length == 0){
        diasServicio.push(dia);
        console.log(diasServicio);
        contadorDias++
      }else{
        contadorDias++
      }
      console.log(contadorDias)
      if(contadorDias == this.arrayPreInfoServiciosPlan.length){
        let planRecurrente = {
          id: plan.id,
          horas: plan.horasDeServicio,
          clientId: plan.client.id,
          clienteName: `${plan.client.name}  ${plan.client.apellido}`,
          horaInicio: this.arrayPreInfoServiciosPlan[0].hora,
          diasAgendado: diasServicio,
          numServicios: plan.cantidadServicios,
          precioServicio: plan.precioIndividual,
          idServerAsignada: plan.server.id,
          serverName: `${plan.server.name}  ${plan.server.apellido}`,
        }
        console.log(plan,planRecurrente)
        this.ServicioService.updatePlan(plan).then(res=>{
          this.crearPagoUsuario(plan);
          this.ServicioService.asignarPlanRecurrete(planRecurrente)
         this.reagendarServiciosDelPlan()
        }); 
      }
    });
  }

  crearPagoUsuario(info){
    let clienteId = this.planEnReagendamiento.client.id;
    console.log("id que busca",clienteId)

    let infoDelPago = {
      total: info.total,
      precio: info.precio,
      recargo: info.recargo,
      descuento: info.descuento,
      estadoDelPago: info.estadoPago,
      horas:     info.horasDeServicio,
      tipoServicio: info.tipoServicio,
      factura:            info.numeroFactura,
      comprobante:        info.comprobanteActual,
      numeroServicios:    info.cantidadServicios, 
      idPlanOServicio:    info.id,
      fechaAgendamiento:  info.fechaReagendamiento,
    }
    this.ServicioService.getPagosUsuarioByClientId(clienteId).then(res=>{
      console.log("res.docs",res.docs);
        res.docs.forEach(data=>{
          let historialPagos = data.data();
          console.log("historial de pagos",historialPagos)
          historialPagos.cobrosRealizados.unshift(infoDelPago)
          this.ServicioService.guardaruevoCobro(historialPagos,true).then(res=>{
            console.log("el cobro fue subido")
          })
        })
      
    })
  }

  resetReg(){
    this.planEnReagendamiento = [];
  }

  private MessageReg(
    title: string, 
    message: string, 
    type: SweetAlertType, 
    close: boolean, 
    modal: string = 'btn-close-save'){
    setTimeout( ()=> {
      Swal.fire(title, message, type).then(res=>{
        if(close){
          const element: HTMLElement = document.getElementById(modal) as HTMLElement;
          element.click();
          setTimeout(()=>{
            this._location.back();
          },500)
        }
      }) ;
    }, 900);
  }

  public editValores(valor){
    console.log('entro en editar',valor);
    if(valor == "valor"){
      this.editPrecioPlan = !this.editPrecioPlan;
    } else  if(valor == "recargo"){
      this.editRecargo = !this.editRecargo;
    } else  if(valor == "descuento"){
      this.editDescuento = !this.editDescuento;
    }
  }

/* 
Funcioes para agregar comentarios
*/

public fechaActual = moment().format('YYYY-MM-DD')
  public comentario = {
    name: "",
    comentario: "",
    fecha: this.fechaActual,
  }
  public comentarioArray: any = [];
  public ingresarComentarioLogistica: boolean = false;
  public ingresarComentarioServer: boolean = false;
AddComentario(tipo) {
  this.comentario.name = this.usuarioEnSecion.displayName;
  if(tipo == 'server'){
    this.ingresarComentarioServer = true;
  } else if(tipo == 'logistica'){
    this.ingresarComentarioLogistica = true;
  }
}

cancelarComentario() {
  this.ingresarComentarioLogistica = false;
  this.ingresarComentarioServer = false;
}

GuardarComentario(tipo) {
  this.comentario.name = this.usuarioEnSecion.displayName;
  const ret:any = this.funcionesUsuario.comentariosCliente(tipo,this.planEnReagendamiento.client,this.comentario);
        this.ingresarComentarioLogistica = false;
        this.ingresarComentarioServer = false;
    this.comentario = {
      name: '',
      comentario: '',
      fecha: this.fechaActual,
    }
}
/* ------------------------------- */

  /* correoConfirmacion(){
    let notificacionConfirmacion = this.planMensual;
    notificacionConfirmacion.diasServicio = "";
    for(let fechas of this.planMensual.infoServicio){
      if(notificacionConfirmacion.diasServicio.includes(moment(fechas.fecha).format("dddd")) == false){
        notificacionConfirmacion.diasServicio = notificacionConfirmacion.diasServicio + moment(fechas.fecha).format("dddd")+"," + " ";
      }
    }
    notificacionConfirmacion.idTemplate = " d-7ee9b3b6bdf14c43865abd6e73bd1a72";
    const requestCorreoSendgrid = firebase2.functions().httpsCallable('requestCorreoAspirantes');
    requestCorreoSendgrid({name: notificacionConfirmacion.client.name, correo: notificacionConfirmacion.client.email, telefono: notificacionConfirmacion.client.telefono, servicioCompleto: notificacionConfirmacion, idTemplate: notificacionConfirmacion.idTemplate }).then(result => {
      console.log(result.data)
    });
    console.log("correo enviado");
  } */












} 


// si se reagenda un plan que el servicio y la categoria ya venga designada? o que se pueda escoger 