import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NbMenuItem } from "@nebular/theme";
export const Menu: NbMenuItem[] = [
  {
    title: "Actualizaciones",
    icon: "bi bi-box-arrow-in-down",
    link: "patch-notes",
  },
  {
    title: "Chat",
    icon: "bi bi-chat-right-dots",
    link: "chat",
  },
  {
    title: "Metricas",
    icon: "bi bi-graph-up",
    link: "metricas",
  },
  {
    title: "Disponibilidad",
    icon: "bi bi-calendar2-check",
    link: "nuevaDisponibilidad",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Usuarios",
    icon: "bi bi-person-vcard",
    link: "usuarios",
  },
  {
    title: "Clientes",
    icon: "bi bi-person-check",
    children: [
      {
        title: "Clientes",
        icon: "bi bi-person-check",
        link: "clientes",
      },
      {
        title: "Marketing",
        icon: "bi bi-person-bounding-box",
        link: "clientesMarketing",
      },
    ],
  },
  {
    title: "Servers",
    icon: "bi bi-people",
    home: true,
    children: [
      {
        title: "Servers",
        icon: "bi bi-person-hearts",
        link: "servers",
      },
      {
        title: "observador",
        icon: "bi bi-person-square",
        link: "observadorServers",
      },
    ],
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
  {
    title: "Contabilidad",
    icon: "bi bi-calculator",
    children: [
      {
        title: "Nómina",
        icon: "bi bi-cash-coin",
        link: "pago-servers",
      },
      {
        title: "Facturación",
        icon: "bi bi-bank",
        link: "facturacion-cartera",
      },
    ],
  },

  {
    title: "Candidatos",
    icon: "bi bi-person-plus",
    children: [
      {
        title: "Candidatos",
        icon: "bi bi-person-plus",
        link: "candidatos",
      },
      {
        title: "Candidatos app",
        icon: "bi bi-box-arrow-in-down",
        link: "candidates",
      },
      {
        title: "Candidatos Serv",
        icon: "bi bi-box-arrow-in-down",
        link: "candidates_serv",
      },
    ],
  },

  {
    title: "Postventa",
    icon: "bi bi-bag-heart",
    link: "postventa",
  },

  {
    title: "Leads",
    icon: "bi bi-magnet",
    link: "leads",
  },
  {
    title: "Leads_ca",
    icon: "bi bi-magnet",
    link: "leads_ca",
  },
  {
    title: "Precios",
    icon: "bi bi-currency-dollar",
    home: true,
    children: [
      {
        title: "Precios",
        icon: "bi bi-currency-dollar",
        link: "gestionarPrecios",
      },
      {
        title: "Cupones",
        icon: "bi bi-ticket-perforated",
        link: "cupones",
      },
    ],
  },
  {
    title: "Resumen Metricas",
    icon: "bi bi-file-earmark-bar-graph",
    link: "exportar-metricas",
  },
];
export const Menu4_0: NbMenuItem[] = [
  {
    title: "observador",
    icon: "bi bi-person-square",
    link: "observadorServers",
  },
];

export const MenuLogistica: NbMenuItem[] = [
  {
    title: "Chat",
    icon: "bi bi-chat-right-dots",
    link: "chat",
  },
  {
    title: "Metricas",
    icon: "bi bi-graph-up",
    link: "metricas",
  },
  {
    title: "Disponibilidad",
    icon: "bi bi-calendar2-check",
    link: "nuevaDisponibilidad",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Clientes",
    icon: "bi bi-person-check",
    children: [
      {
        title: "Clientes",
        icon: "bi bi-person-check",
        link: "clientes",
      },
      {
        title: "Marketing",
        icon: "bi bi-person-bounding-box",
        link: "clientesMarketing",
      },
    ],
  },
  {
    title: "Servers",
    icon: "bi bi-people",
    home: true,
    children: [
      {
        title: "Servers",
        icon: "bi bi-person-hearts",
        link: "servers",
      },
      {
        title: "observador",
        icon: "bi bi-person-square",
        link: "observadorServers",
      },
    ],
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
  {
    title: "Contabilidad",
    icon: "bi bi-calculator",
    children: [
      {
        title: "Nómina",
        icon: "bi bi-cash-coin",
        link: "pago-servers",
      },
      {
        title: "Facturación",
        icon: "bi bi-bank",
        link: "facturacion-cartera",
      },
    ],
  },
  {
    title: "Candidatos",
    icon: "bi bi-person-plus",
    link: "candidatos",
  },
  {
    title: "Postventa",
    icon: "bi bi-bag-heart",
    link: "postventa",
  },

  {
    title: "Leads",
    icon: "bi bi-magnet",
    link: "leads",
  },
  {
    title: "Resumen Metricas",
    icon: "bi bi-file-earmark-bar-graph",
    link: "exportar-metricas",
  },
];
export const MenuTalento: NbMenuItem[] = [
  {
    title: "Chat",
    icon: "bi bi-chat-right-dots",
    link: "chat",
  },
  {
    title: "Actualizaciones",
    icon: "bi bi-box-arrow-in-down",
    link: "patch-notes",
  },
  {
    title: "Metricas",
    icon: "bi bi-graph-up",
    link: "metricas",
  },
  {
    title: "Disponibilidad",
    icon: "bi bi-calendar2-check",
    link: "nuevaDisponibilidad",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Servers",
    icon: "bi bi-people",
    link: "servers",
  },
  {
    title: "observador",
    icon: "bi bi-person-square",
    link: "observadorServers",
  },
  {
    title: "Clientes",
    icon: "bi bi-person-check",
    link: "clientes",
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
  {
    title: "Observador",
    icon: "bi bi-person-square",
    link: "observadorServers",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Candidatos",
    icon: "bi bi-person-plus",
    link: "candidatos",
  },
];
export const MenuComercial: NbMenuItem[] = [
  {
    title: "Chat",
    icon: "bi bi-chat-right-dots",
    link: "chat",
  },
  {
    title: "Actualizaciones",
    icon: "bi bi-box-arrow-in-down",
    link: "patch-notes",
  },
  {
    title: "Metricas",
    icon: "bi bi-graph-up",
    link: "metricas",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Servers",
    icon: "bi bi-people",
    link: "servers",
  },
  {
    title: "Clientes",
    icon: "bi bi-person-check",
    link: "clientes",
  },
  {
    title: "Disponibilidad",
    icon: "bi bi-calendar2-check",
    link: "nuevaDisponibilidad",
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
  {
    title: "Cupones",
    icon: "bi bi-ticket-perforated",
    link: "cupones",
  },
  {
    title: "Postventa",
    icon: "bi bi-bag-heart",
    link: "postventa",
  },
  {
    title: "Leads",
    icon: "bi bi-magnet",
    link: "leads",
  },
];
export const MenuContabilidad: NbMenuItem[] = [
  {
    title: "Chat",
    icon: "bi bi-chat-right-dots",
    link: "chat",
  },
  {
    title: "Metricas",
    icon: "bi bi-graph-up",
    link: "metricas",
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
  {
    title: "Actualizaciones",
    icon: "bi bi-box-arrow-in-down",
    link: "patch-notes",
  },
  {
    title: "Monitoreo",
    icon: "bi bi-search-heart",
    link: "novedades",
  },
  {
    title: "Clientes",
    icon: "bi bi-person-check",
    link: "clientes",
  },
  {
    title: "Contabilidad",
    icon: "bi bi-calculator",
    children: [
      {
        title: "Nómina",
        icon: "bi bi-cash-coin",
        link: "pago-servers",
      },
      {
        title: "Facturación",
        icon: "bi bi-bank",
        link: "facturacion-cartera",
      },
    ],
  },
  {
    title: "Agenda",
    icon: "bi bi-cart4",
    link: "nuevoServicios",
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MenuModule {}
