import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})

export class CandidatoService {

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
    private storage: AngularFireStorage
  ) { }
  
  /* CRUD aspirantes */
  public post(candidato) {
    return firebase.firestore().doc('aspirantes/' + candidato.id).set(candidato);
  }

  public putAntecedentes (pictureName, file) {
    return this.storage.ref(`antecedentes2/${pictureName}`).put(file);
  }
  public getURLAntecedentes(pictureName) {
    return this.storage.ref(`antecedentes2/${pictureName}`).getDownloadURL();
  }
  public putContrato (pictureName, file) {
    return this.storage.ref(`contratos/${pictureName}`).put(file);
  }
  public getURLContrato(pictureName) {
    return this.storage.ref(`contratos/${pictureName}`).getDownloadURL();
  }

  public putDescuentoSeguridad (pictureName, file) {
    return this.storage.ref(`contratos/${pictureName}`).put(file);
  }
  public getURLDescuentoSeguridad(pictureName) {
    return this.storage.ref(`contratos/${pictureName}`).getDownloadURL();
  }
  public getCandidato(id) {
    return firebase.firestore().collection('aspirantes').doc(id).get()
  }
  
  public actualizarCandidatoEstudiante(aspirante){
    console.log(aspirante)
    return firebase.firestore().doc('aspirantes/'+ aspirante.id).set(aspirante)
  }

  public getLead(id) {
    return firebase.firestore().collection('leadsCotizaciones').where('id', "==", id).get()
  }
  public getLeadEmail(email) {
    return firebase.firestore().collection('leadsCotizaciones').where('correo', "==", email).get()
  }
  public getServiciosNovedades() {
    return firebase.firestore().collection('servicios').where('novedad', "==", true).get()
  }
  public getServiciosFecha () {
    return firebase.firestore().collection('servicios').where('fecha' ,'>=', '2020-07-26' ).get()
  }
  
  public editServicio(servicioPostventa) {
    return firebase.firestore().collection('servicios').doc(servicioPostventa.id).set(servicioPostventa)
  }

/**********************************************************/
  public getCandidatos() {
    return firebase.firestore().collection('aspirantes').get()
  }
  public getCandidatosPorEstado(estado){
    return firebase.firestore().collection('aspirantes').where('estado', '==', estado).get()
  }

  public getCandidatosFecha(fchInicio,fchFin){
    return firebase.firestore().collection('aspirantes').where('fechaCreacion', '>=', fchInicio).where('fechaCreacion', '<=', fchFin).get()
  }
  public getCandidatos_fecha_estado(start_date,end_date,estado){
    let int_start_date = new Date(start_date).getTime()
    let int_end_date = new Date(end_date).getTime()
    return firebase.firestore().collection('aspirantes')
    .where('estado', '==', estado)
    .where('fechaCreacion', '>=', int_start_date)
    .where('fechaCreacion', '<=', int_end_date)
    .get()
  }
  public getCandidatosEntrevista_fecha(start_date,end_date){
    return firebase.firestore().collection('aspirantes')
    .orderBy('fechaEntrevista','desc')
    .where('estado', '==', 'Entrevista Online')
    .where('fechaEntrevista', '>=', start_date)
    .where('fechaEntrevista', '<=', end_date)
    .get()
  }
  public getCandidatoIdEstudiante(id){
    return firebase.firestore().collection('aspirantes')
    .where('idEstuduiante', '==', id)
    .get()
  }
  public getCandidatoId(id){
    return firebase.firestore().collection('aspirantes')
    .where('id', '==', id)
    .get()
  }
  public editCandidato(candidato) {
    return firebase.firestore().doc('aspirantes/' + candidato.id).set(candidato)
  }
  
  delete(id){
    return firebase.firestore().doc('aspirantes/' + id).delete()
  }

  /*************************************************/

  public getFoto(Cedula : number) {
    return this.storage.ref('Aspirante/Cedula_'+Cedula+'/Foto').getDownloadURL();
  }
  public getAntecedentes(Cedula){
    return this.storage.ref('Aspirante/' +Cedula+'/Antecedentes').getDownloadURL();
  }
  public getCedula(documento){
    return this.storage.ref(`Aspirante/${documento}/Cedula`).getDownloadURL();
  }
  public deleteCandidato(id) {
    return firebase.firestore().doc('aspirantes/' + id).delete()
  }
  public eliminarLead(id) {
    return firebase.firestore().collection('leadsCotizaciones').doc(id).delete();
  }
  public eliminarCandidato(candidato) {
    return firebase.firestore().collection('aspirantes').doc(candidato.id).delete();
  }

  public exportAsExcelFile(json, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
