import { IMyDateRangeModel } from 'mydaterangepicker';
import { LocalDataSource } from 'ng2-smart-table';
import { Component, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as _ from 'lodash';
/* Services */
import { ServerService } from 'src/app/services/server.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { PagosServerService } from 'src/app/services/pagos-server.service';

@Component({
  selector: 'app-ver-pagados',
  templateUrl: './ver-pagados.component.html',
  styleUrls: ['./ver-pagados.component.scss']
})

export class VerPagadosComponent implements OnInit {
  public model: any = {
    beginDate: { year: moment().year(), month: (moment().add().month() + 1), day: 1 },
    endDate: { year: moment().year(), month: (moment().add().month() + 1), day: moment().endOf('month').date() }
  };
  public myDateRangePickerOptions;
  public myDatePickerOptions: IMyDpOptions = { dateFormat: 'dd.mm.yyyy' };

  public comprobante;
  public server: any;
  public servicios: any = [];
  public totalServicios;
  public pagoParcialServer;
  public pagoParcialServerView;
  public gananciaParcialServ;
  public pagoNetoServer;
  public gananciaNetoServ: number = 0;
  public saldos: number = 0;
  public seguridadSocial: number = 0;
  public uniformes: number = 0;
  public recargoGenaral;
  public horasTotales: number = 0;
  public observacionesPago: string = '';

  public startDate;
  public endDate;
  public jueves;
  public miercoles;

  public source: any;
  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'detalle', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      estado: { title: 'Estado' },
      tipoServicio: { title: 'Tipo servicio' },
      fechaFormat: { title: 'Fecha del servicio' },
      precio: { title: 'Precio' },
      horasDeServicio: { title: 'Horas' },
      formaDePago: { title: 'Forma de pago' },
      recargo: {
        title: 'Recargo'
      }
    }
  };

  constructor(
    public ServerService: ServerService,
    private rutaActiva: ActivatedRoute,
    public servicioService: ServicioService,
    public _location: Location,
    public datepipe: DatePipe,
    public router: Router,
    private pagosServer: PagosServerService
  ) { }

  ngOnInit() {
    let idComprobante = this.rutaActiva.snapshot.params['id'];
    this.getComprobante(idComprobante);
  }

  public getComprobante(id) {
    this.pagosServer.getPagosServer(id).then(result => {
      console.log(result.docs.length);
      result.forEach(doc => {
        console.log(doc.data());
        this.comprobante = doc.data();
        console.log(this.comprobante.pagoParcialServer)
        this.server = this.comprobante.sever;
      });
        this.asignacionValores();
    });
  }

  public asignacionValores(){
    this.servicios = this.comprobante.servicios
    this.totalServicios = this.comprobante.totalServicios;
    this.pagoParcialServer = this.comprobante.pagoParcialServer;
    this.gananciaParcialServ = this.comprobante.gananciaParcialServ;
    this.saldos = this.comprobante.saldo;
    this.seguridadSocial = this.comprobante.seguridadSocial;
    this.uniformes = this.comprobante.uniformes;
    this.pagoNetoServer = this.comprobante.pagoNetoServer;
    this.gananciaNetoServ = this.comprobante.gananciaNetaServ;
    this.recargoGenaral = this.comprobante.recargoServicios;
    this.observacionesPago = this.comprobante.observacionesPago;
    this.generarIntervaloActual(this.comprobante.fechaInicio,this.comprobante.fechaFin);
  }



  /* public onDateRangeChanged(event: IMyDateRangeModel) {
    const beginDateFormat = moment(event.beginJsDate).format('YYYY-MM-DD');
    const endDateFormat = moment(event.endJsDate).format('YYYY-MM-DD');
    this.startDate = beginDateFormat;
    this.endDate = endDateFormat;
    console.log(`fecha inicio: ${beginDateFormat}, fecha final: ${endDateFormat}`);
    this.generarIntervaloActual(beginDateFormat, endDateFormat);
  }

  public getCurrentWeek() {
    let nextWednesday = new Date();
    let previousThursday = new Date();
    // get next Wednesday
    nextWednesday.setDate(nextWednesday.getDate() + (3 - 7 - nextWednesday.getDay()) % 7);
    if (nextWednesday.getDay() == 3 && nextWednesday.getHours() > 19 && nextWednesday.getMinutes() > 30) {
      nextWednesday.setDate(nextWednesday.getDate() + (3 + 7 - nextWednesday.getDay()));
    }
    this.miercoles = this.datepipe.transform(nextWednesday, 'yyyy-MM-dd');

    // get previous Thursday
    previousThursday.setDate(previousThursday.getDate() + (4 - 7 - previousThursday.getDay()) % 7);
    if (previousThursday.getDay() == 4 && previousThursday.getHours() > 19 && previousThursday.getMinutes() > 30) {
      previousThursday.setDate(previousThursday.getDate() + (4 - 7 - previousThursday.getDay()));
    }
    previousThursday.setDate(previousThursday.getDate() - 7);
    this.jueves = this.datepipe.transform(previousThursday, 'yyyy-MM-dd');
    this.startDate = this.jueves;
    this.endDate = this.miercoles;
    this.generarIntervaloActual(this.jueves, this.miercoles);
  }

  public semanas(tipo) {
    if (tipo == 'next') {
      this.jueves = moment(this.jueves).add(7, 'days').format('YYYY-MM-DD');
      this.miercoles = moment(this.miercoles).add(7, 'days').format('YYYY-MM-DD');
      this.generarIntervaloActual(this.jueves, this.miercoles);
      this.startDate = this.jueves;
      this.endDate = this.miercoles;
      console.log('SEMANA SIGUIENTE' + 'fecha inicial ' + this.jueves + 'fecha final ' + this.miercoles);
    } else if (tipo == 'back') {
      this.jueves = moment(this.jueves).subtract(7, 'days').format('YYYY-MM-DD');
      this.miercoles = moment(this.miercoles).subtract(7, 'days').format('YYYY-MM-DD');
      this.generarIntervaloActual(this.jueves, this.miercoles);
      this.startDate = this.jueves;
      this.endDate = this.miercoles;
      console.log('SEMANA ANTERIOR' + 'fecha inicial ->' + this.jueves + 'fecha final->' + this.miercoles);
    }
  } */

  public generarIntervaloActual(start, end) {
    this.servicioService.getByServerInDateRange(this.server.id, start, end).then(result => {
      let serviciosData = [];
      result.forEach(doc => {
        let servicio = doc.data();
        if (servicio.estado == 'Terminado' && !servicio.pagadoServer) {
          servicio.fecha = servicio.fecha ? moment(servicio.fecha).format('YYYY-MM-DD') : 'N/A';
          serviciosData.push(servicio);
        }
      });
      this.asignacionDeServicios();
    })
  }

  public asignacionDeServicios() {
    this.servicios.forEach(servicio => {
      servicio.recargo = servicio.recargo >= 0 ? servicio.recargo : 0;
      servicio.fechaFormat = this.datepipe.transform(servicio.fecha, 'yyyy EEEE MMMM d')
      if (servicio.horasDeServicio == 2) {
        this.horasTotales = this.horasTotales + servicio.horasDeServicio;
      }
      if (servicio.horasDeServicio == 4) {
        this.horasTotales = this.horasTotales + servicio.horasDeServicio;
      }
      if (servicio.horasDeServicio == 6) {
        this.horasTotales = this.horasTotales + servicio.horasDeServicio;
      }
      if (servicio.horasDeServicio == 8) {
        this.horasTotales = this.horasTotales + servicio.horasDeServicio;
      }
    });
    this.source = _.orderBy(this.servicios, ['fecha'], ['desc']);
  }

  public goBack() {
    this._location.back()
  }

  public onCustom(event) {
    if (event.action == "detalle") {
      this.verServicio(event.data.id);
    }
  }

  public verServicio(id) {
    this.router.navigate(['dashboard/ver-detalle-servicio', id]);
  }

}