import { IMyDateRangeModel } from 'mydaterangepicker';
import { LocalDataSource } from 'ng2-smart-table';
import { Component, OnInit, OnChanges, SimpleChange, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Metricas } from 'src/app/services/metricas.service'
import * as _ from 'lodash';
/* Services */
import { ServerService } from 'src/app/services/server.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { PagosServerService } from 'src/app/services/pagos-server.service';
import { element } from 'protractor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-pago-server',
  templateUrl: './ver-pago-server.component.html',
  styleUrls: ['./ver-pago-server.component.scss']
})

export class VerPagoServerComponent implements OnInit {

  @Input() infoDelPago: any;

  public model: any = {
    beginDate: { year: moment().year(), month: (moment().add().month() + 1), day: 1 },
    endDate: { year: moment().year(), month: (moment().add().month() + 1), day: moment().endOf('month').date() }
  };
  public myDateRangePickerOptions;
  public myDatePickerOptions: IMyDpOptions = { dateFormat: 'dd.mm.yyyy' };

  public server: any;
  public servicios: any = [];
  public totalServicios: number = 0;
  public pagoParcialServer: number = 0;
  public pagoParcialServerView: number = 0;
  public gananciaParcialServ: number = 0;
  public pagoNetoServer: number = 0;
  public gananciaNetoServ: number = 0;
  public saldos: number = 0;
  public seguridadSocial: number = 0;
  public uniformes: number = 0;
  public recargoGenaral: number = 0;
  public recargoPlaceholder;
  public horasTotales: number = 0;
  public observacionesPago: string = '';

  public startDate;
  public endDate;
  public jueves;
  public miercoles;

  public comprobante;
  public idComprobante;

  activatedRoute: any;
  novedades: any = [];
  constructor(
    public ServerService: ServerService,
    private rutaActiva: ActivatedRoute,
    public servicioService: ServicioService,
    public _location: Location,
    public datepipe: DatePipe,
    public router: Router,
    private metricasService: Metricas,
    private pagosServer: PagosServerService
  ) {
  }

  ngOnInit() {
    this.idComprobante = this.rutaActiva.snapshot.params['id'];
    this.onLoad()
  }

  public async onLoad(){
    // console.log(this.infoDelPago);
    this.comprobante = this.infoDelPago;
    this.server = this.comprobante.sever;
    console.log(this.comprobante)
    this.novedades = await this.getNovedadesAsociadas(this.comprobante.id)
    console.log(this.novedades)
    this.asignacionValores();

  }


  getNovedadesAsociadas(id){
    return new Promise(result=>{
      this.metricasService.getNovedadesPago(id).then(res=>{
        if(res.docs.length > 0){
          const novedades = res.docs.map(data=>{
            return {
              ...data.data()
            }
          })
          const otrasovedades = novedades.filter(res=> res.tipo !== "Puntualidad" && res.tipo != 'Servicio sin seguridad social' && res.tipo !== "No uso la app")
          result(otrasovedades)
        }else{
          result([])
        }
      })
    })
  }


  public asignacionValores(){

    this.observacionesPago = this.comprobante.observacionesPago;
    this.jueves = this.comprobante.fechaInicio;
    this.miercoles = this.comprobante.fechaFin;
    this.generarIntervaloActual(this.comprobante.fechaInicio,this.comprobante.fechaFin);

  }

  public generarIntervaloActual(start, end) {
    this.servicioService.getByServerInDateRange(this.server.id, start, end).then(result => {
      let serviciosData = [];
      result.forEach(doc => {
        let servicio = doc.data();
        if (servicio.estado == 'Terminado' && !servicio.pagadoServer) {
          servicio.fecha = servicio.fecha ? moment(servicio.fecha).format('YYYY-MM-DD') : 'N/A';
          serviciosData.push(servicio);
        }
      });
    })
  }

  public goBack() {
    this._location.back()
  }

  public onCreatePay() {
    if (this.gananciaNetoServ > 0 && this.pagoNetoServer > 0) {
      let confirmacion = confirm(`Va a crear un pago por ${this.pagoNetoServer} a la Server ${this.server.name} ${this.server.apellido}`);
      if (confirmacion) {
        this.generarComprobantePago()
      } else {
        alert('El pago fue cancelado');
      }
    } else {
      alert('Porfavor rellene los campos de pagos netos');
    }
  }

  public onCustom(event) {
    if (event.action == "detalle") {
      this.verServicio(event.data.id);
    }
  }

  public generarComprobantePago() {
    this.pagosServer.updatePagoServer({id:this.idComprobante,observacionesPago: this.observacionesPago, estadoComprobante: 'Pagado' }).then(async res => {
      await this.actualizarServicios(this.servicios);
      alert('Se creo exitosamente el pago');
    },err=>{
      console.log(err);
    });
  }

  public actualizarServicios(servicios) {
    return new Promise(success => {
      servicios.forEach(async (element) => {
        element.pagadoServer = true;
        this.servicioService.actualizarEstadoDePago(element.id,'Pagado').then((res: any) => {
        });
      });
      success(servicios)
      console.log('prueba de servicio pagado' + servicios)
    })
  }

  public verServicio(id) {
    this.router.navigate(['dashboard/ver-detalle-servicio', id]);
  }

  revertirCausado(){
    Swal.fire({  
      
      title: `Espera`,
      text: `Seguro qeu queres devolver todos los servicios y novedades asociadas a este pago a pendiente`,
      showConfirmButton: true,
      confirmButtonText: 'Revertir causado',
      showCancelButton: true,
      cancelButtonText: "Atras",
      confirmButtonColor: '#1C6FF3',
      cancelButtonColor: '#DDE9FD',
      customClass: {
        cancelButton: 'color: #1C6FF3',
      }
    }).then(async res=>{
      if(res.value == true){
        const a = await this.descausarServicios();
        
        this.novedades.forEach(element=>{
          console.log(element.id)
          this.metricasService.updateNovedad({id:element.id , estadoGeneral: 'vigente'})
        })
        console.log(this.comprobante.id)
        this.pagosServer.deletePagosServer(this.comprobante.id)
        Swal.fire('Listo','Se devolvienron los servicios a pendiente','success')
      }else{
        return;
      }
    })
  }

  descausarServicios(){
    return new Promise(result =>{
      for(let i = 0;i< this.comprobante.servicios.lengt;i++){
        this.servicioService.updateServicioTerminado({id: this.comprobante.servicios[i].id,estadoPagoServer: "Pendiente" })
      }
      return result('success')
    })
  }

  recalcularValores(){
    let abonoArticulos = 0;
    console.log(this.comprobante.pagoNetoServer,this.comprobante.valorNovedades,this.comprobante.valorNovedadesApeladas, this.comprobante.valorNovedadesAdicionales ,this.comprobante.recargoServicios, this.comprobante.recargoTransporte)
    this.comprobante.pagoNetoServer = this.comprobante.gananciaBase + (this.comprobante.gananciaBonoPuntualidad_App + this.comprobante.gananciaBonoSeguridadSocial) +
                                      (this.comprobante.valorNovedades + this.comprobante.valorNovedadesAdicionales + this.comprobante.valorNovedadesApeladas) + 
                                      (this.comprobante.recargoServicios + this.comprobante.recargoTransporteServicios);
    console.log("Pago total en deducciones despues", this.comprobante.pagoNetoServer);
  }

  actualizarCobro(){
    this.pagosServer.updatePagoServer(this.comprobante).then(res=>{
      Swal.fire('Listo','La informacion a sido actualizada','success')
    })
  }

  onViewPayment(){
    this.comprobante.comprobanteDePago
  }
  onDowloadPayment(){
    
    fetch(this.comprobante.comprobanteDePago)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.server.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error("Error al descargar el archivo:", error);
    });

  }
}