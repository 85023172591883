import { Injectable } from "@angular/core";
import { AngularFireStorageModule } from "angularfire2/storage";
import { Http } from "@angular/http";
import * as moment from "moment";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase } from "angularfire2/database";
import * as firebase from "firebase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AngularFirestore } from "angularfire2/firestore";
import { disconnect } from "process";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class posventaService {
  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http
  ) {}

  /* CRUD posventas */
  public post(posventa) {
    return firebase
      .firestore()
      .doc("posventas/" + posventa.id)
      .set(posventa);
  }
  public getPosventas() {
    return firebase.firestore().collection("posventas").get();
  }

  public getPosventaIdServer(id) {
    return firebase
      .firestore()
      .collection("posventas")
      .where("idServer", "==", id)
      .get();
  }

  getServiciosPosventas(fchInicio, fchFin){
    return firebase.firestore().collection("servicios")
    .where("fechaHoraPostventa", ">=", fchInicio)
    .where("fechaHoraPostventa", "<=", fchFin)
    .get();
  }

  public getSeriviciosEstadoPostventaFechas(fchInicio, fchFin, estado) {
    return firebase.firestore().collection("servicios")
    .where("estadoPostventa", "==", estado)
    .where("fechaHoraPostventa", ">=", fchInicio)
    .where("fechaHoraPostventa", "<=", fchFin)
    .get();
  }
  public getSeriviciosEstadoPostventa(estado) {
    return firebase.firestore().collection("servicios")
    .where("estadoPostventa", "==", estado)
    .get();
  }
  
  public getSeriviciosEstadoPostventaAsignadaFecha(fchInicio,fchFin,estado,responsable) {
    return firebase.firestore().collection("servicios")
    .where("estadoPostventa", "==", estado)
    .where("fechaHoraPostventa", "<=", fchFin)
    .where("fechaHoraPostventa", ">=", fchInicio)
    .where('responsablePostventa','==',responsable)
    .get();
  }
  public getSeriviciosEstadoPostventaAsignada(estado,responsable) {
    return firebase.firestore().collection("servicios")
    .where("estadoPostventa", "==", estado)
    .where('responsablePostventa','==',responsable)
    .get();
  }

  public getPlanesPostventa(fchInicio,fchFin) {
    return firebase
      .firestore()
      .collection("plan")
      .where("estadoPostventa", "==", "postventa postpuesta")
      .where('fechaPostventa','>=',fchInicio)
      .where('fechaPostventa','<=',fchFin)
      .get();
  }



  public eliminarCalificacion(info){
    return firebase.firestore().doc(`posventas/`+ info.id).set(info)
  }

  public updateServicio(servicioPostventa: any) {
    return firebase
      .firestore()
      .doc(`servicios/${servicioPostventa.id}`)
      .update(servicioPostventa);
  }
  public updatePsoventa(posventa) {
    return firebase
      .firestore()
      .doc("posventas/" + posventa.id)
      .update(Object.assign({}, posventa));
  }

  /* Posvetnas serv */
  public postServ(posventa) {
    return firebase
      .firestore().doc("postventasServ/" + posventa.id).set(posventa);
  }

  public postCalificacion(calificacion) {
    return firebase.firestore().collection('calificaciones').add(calificacion);
  }
  public setCalificacion(calificacion) {
    return firebase.firestore().collection('calificaciones').doc(`${calificacion.id}`).set(calificacion);
  }

  public getCalificacionServicio(id) {
    return firebase.firestore().collection('calificaciones').where("idServicio","==",id).get();
  }
  public getCalificacioes() {
    return firebase.firestore().collection('calificaciones').get();
  }
  public getCalificacioesServer(id) {
    return firebase.firestore().collection('calificaciones').where('idServer','==',id).get();
  }
  public deleteCalificacioes(id) {
    return firebase.firestore().collection('calificaciones').doc(id).delete();
  }
  public updateCalificacioes(id,info) {
    return firebase.firestore().collection('calificaciones').doc(id).set(info);
  }

  getCalificacionServerUser(idUser,idServer){
    return firebase.firestore().collection('calificaciones').where("idUser","==",idUser).where("idServer","==",idServer).get();
  }

}
