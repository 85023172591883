/* 
const serviciosPrestadoss = [
    {
        "name":"Limpieza y sevicios generales",
        "code":1,
        "categoria":[
            {
                "name":"SERV Hogares",
                "subCategoria":[
                    {
                        "name":"Aseo general",
                        "checklist":[
                            { "name":"Aseo General"},
                            { "name":"Lavado de ropa"},
                            { "name":"Plancahado"},
                            { "name":"Cocina Basica"},
                        ]
                    },
                    {
                        "name":"Limpieza profunda cocina",
                        "checklist":[
                        ]
                    },
                    {
                        "name":"Planchado de ropa",
                        "checklist":[

                        ]
                    },
                    {
                        "name":"Asistente de cocina",
                        "checklist":[

                        ]
                    },
                    {
                        "name":"Orden closets",
                        "checklist":[

                        ]
                    },
                    {
                        "name":"Limpieza de baño profundo",
                        "checklist":[

                        ]
                    },
                    {
                        "name":"Lavado de ropa",
                        "checklist":[

                        ]
                    },
                        
                ]

                
            },
            {
                "name":"SERV Empresarial",
                "subCategoria":[
                    {
                        "name":"Aseo general",
                        "checklist":[
                            
                        ]
                    },
                    {
                        "name":"Limpieza de bodegas",
                        "checklist":[

                        ]
                    },
                    {
                        "name":"Todero",
                        "checklist":[

                        ]
                    },{
                        "name":"Jardinero",
                        "checklist":[

                        ]
                    },                  
                        
                ]

                
            },
            {
                "name":"L.E de muebles",
                "subCategoria":[]
            },
            {
                "name":"L.E de tapetes",
                "subCategoria":[]
            }  
        ]
    },
    {
        "name":"Desinfección",
        "code":2,
        "categoria":[
            {
                "name":"Hogares",
                "subCategoria":[
                    {
                        "name":"Hogares",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Automovil",
                "subCategoria":[
                    {
                        "name":"Automovil",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Propiedad Horizotal",
                "subCategoria":[
                    {
                        "name":"Propiedad Horizontal",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Bodegas",
                "subCategoria":[
                    {
                        "name":"Bodegas",
                        "checklist":[
                        ]
                    }
                ]
             
            },        
        ]
    },
    {
        "name":"Asistente Personal",
        "code":3,
        "categoria":[
            {
                "name":"Instalador",
                "subCategoria":[
                    {
                        "name":"Instalador",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Cheff",
                "subCategoria":[
                    {
                        "name":"Cheff",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Asistencia Cocina",
                "subCategoria":[
                    {
                        "name":"Asistencia Cocina",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Asistente de eventos",
                "subCategoria":[
                    {
                        "name":"Asistente de evetos",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Asistente para mudanzas",
                "subCategoria":[
                    {
                        "name":"Asistente para mudanzas",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Empaquetado de productos",
                "subCategoria":[
                    {
                        "name":"Empaquetado de productos",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },
            {
                "name":"Mensajero por horas",
                "subCategoria":[
                    {
                        "name":"Mensajero por horas",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            }, 
            {
                "name":"Conductor por horas",
                "subCategoria":[
                    {
                        "name":"Conductor por horas",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },       
        ]

    },
    {
        "name":"Cuidadores",
        "code":4,
        "categoria":[
            {
                "name":"Cuidado de niños",
                "subCategoria":[
                    {
                        "name":"Cuidado de niños",
                        "checklist":[
                           
                        ]
                    }
                ] 
            },
            {
                "name":"Cuidado de adultos mayores",
                "subCategoria":[
                    {
                        "name":"Cuidado de adultos mayores",
                        "checklist":[
                           
                        ]
                    }
                ]
            },
            {
                "name":"Cuidado de mascotas",
                "subCategoria":[
                    {
                        "name":"Cuidado de macotaas",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },    
        ]
    },
    {
        "name":"Limpieza especializada y máquinas",
        "code":4,
        "categoria":[
            {
                "name":"Muebles",
                "subCategoria":[
                    {
                        "name":"Limpieza especializada de muebles",
                        "checklist":[
                           
                        ]
                    }
                ] 
            },
            {
                "name":"Tapetes",
                "subCategoria":[
                    {
                        "name":"Limpieza especializada de tapetes",
                        "checklist":[
                           
                        ]
                    }
                ]
            },
            {
                "name":"Colchones",
                "subCategoria":[
                    {
                        "name":"Cuidado de Limpieza especializada de colchones",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },    
            {
                "name":"Hidrolavadora",
                "subCategoria":[
                    {
                        "name":"Alquiler de hidrolavadora",
                        "checklist":[
                           
                        ]
                    }
                ]
             
            },    
            {
                "name":"Aspiradora",
                "subCategoria":[
                    {
                        "name":"Alquiler de aspiradora",
                        "checklist":[
                           
                        ]
                    }
                ]
            },    
        ]
    },

] */

const serviciosPrestados = [
	{
		"name": "aseo y servicios generales",
		"categoria":[
			{ "name": 'Empleado/a doméstico/a',  	 value: 'empleada domestica' },
			{ "name": 'Limpieza profunda del hogar', value: 'aseo hogar' },
			{ "name": 'Limpieza de empresas',   	 value: 'aseo empresa' },
			{ "name": 'Limpieza de Airbnbs',    	 value: 'aseo airbnb' },
			{ "name": 'Cocinero/a', value: 'cocina'},
			{ "name": 'Planchado',  value: 'planchado'},
			{ "name": 'Conserje, servicios generales para empresa', value: 'conserje'},

			{ "name": 'Niñera/o',   value: 'cuidado niños'},
			{ "name": 'Cuidado de adulto mayor', 		value: 'cuidado adultos mayores'},
			{ "name": 'Enfermera post-cirugia', value: 'enfermera postcirugia'},
		
			{ "name": 'Todero/a',   value: 'todero'},
			{ "name": 'Pintor',     value: 'pintor'},
			{ "name": 'Mudanza',    value: 'mudanza'},
			{ "name": 'Cerrajero',  value: 'cerrajero'},
			{ "name": 'Ayudante/a', value: 'ayudante'},
		]
	},
	{
		"name": "servicios especiales",
		"categoria":[
			{ "name": 'Fotografo',  value: 'fotografo'},
			{ "name": 'Entrenador/a personal', value: 'entrenador personal' },
			{ "name": 'Estilista',  value: 'estilista'},
			{ "name": 'Masajista',  value: 'masajista'},
			{ "name": 'Arreglo de uñas', value: 'arreglo de uñas' },
			
			{ "name": 'Guía turístico/a', value: 'guia turistico' },
			{ "name": 'Trasporte aeropuerto/hotel', value: 'transporte aeropuerto' },
		]
	}
]
export {
    serviciosPrestados
}