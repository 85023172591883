import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import {AuthService} from '../../services/auth.service'
import{PatchNotesServices} from '../../services/patchNotes.services'
import * as _ from 'lodash'; 
@Component({
  selector: 'app-patch-notes',
  templateUrl: './patch-notes.component.html',
  styleUrls: ['./patch-notes.component.scss']
})
export class PatchNotesComponent implements OnInit {

  @ViewChild('mdlParche', { static: true }) mdlParche: ElementRef;
  @ViewChild('mdlCrearParche', { static: true }) mdlCrearParche: ElementRef;
  currentDate = moment().format('YYYY-MM-DD')
  arrayPatchNotes = [];
  newPatch = {
    titulo:'',
    resumen:'',
    fecha:'',
    actualizaciones:[],
    id: 0,
  };
  viewpatch: any;
  usuarioEnSecion: any;

  constructor(
    private dialogService: NbDialogService,
    private patchNotesServices: PatchNotesServices,
    private authService: AuthService,
  ) { 
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }

  ngOnInit() {
    this.getPatchNotes();
  }

  getPatchNotes(){
    this.patchNotesServices.getAll().then(res=>{
      res.docs.forEach(data=>{
        this.arrayPatchNotes.push(data.data())
        console.log(this.arrayPatchNotes)
        this.arrayPatchNotes = _.orderBy(this.arrayPatchNotes, ["fecha"], ["desc"]);
        this.cutText(data.data().id)
      })
    })
  }
  cutText(id){
    let maxChars = 200;
    setTimeout(()=>{
      const a = document.getElementById(id) as HTMLParagraphElement
      console.log(a.textContent.length);
      if(a.textContent.length > maxChars){
        a.textContent = a.textContent.substr(0,maxChars) + '...'
        console.log( a.textContent)
      }
    },500)
  }


  openModal(patch){
    this.viewpatch = patch
    // @ts-ignore
    this.dialogService.open(this.mdlParche);
  }
  abrirmdlCrearParche(){
    // @ts-ignore
    this.dialogService.open(this.mdlCrearParche);
  }
  abrirmdlEliminar(){
    // @ts-ignore
    this.dialogService.open(this.mdlEliminar);
  }


  mostrarImg(event){
    console.log(event)
    const reader = URL.createObjectURL(event.target.files[0]);
    console.log(reader);
    const a  = document.getElementById("imgPreview") as HTMLImageElement
    a.src = reader
    console.log(a,reader)
  }
  addUpdate(){
    this.newPatch.actualizaciones.push({
      titulo: '',
      informacion: '', 
    })
    console.log(this.newPatch)
  }

  saveNewPatch(){
    this.newPatch.id = new Date().getTime()
    this.patchNotesServices.post(this.newPatch)
  }

  delete(id){
    let confirm1 = confirm(`Se eliminara las notas del parche!`)
    if(confirm1 == true){
      this.patchNotesServices.delete(id).then(()=>{
        console.log('Eliminado')
      })
    }
  }
  


}
