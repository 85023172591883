import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import { AspirantesServService } from 'src/app/services/aspirantes-serv.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-candidates-serv',
  templateUrl: './candidates-serv.component.html',
  styleUrls: ['./candidates-serv.component.scss']
})
export class CandidatesServComponent implements OnInit {
  
  tableDataSource: any;
  @ViewChild('paginator' , { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  tableDisplayedColumns = ['fechaCreacion','nombre','telefono','email','especialidad','ciudad','hojaVida','habilidades'];
  usuarioEnSecion = {};
  constructor(
    private authService: AuthService,
    private  aspirantesServService: AspirantesServService ,
  ){
    this.usuarioEnSecion =  this.authService.storeGetUserData('usuario')
  }
  ngOnInit() {
    this.onCargar();
  }

  public async onCargar() {
    let data = [];
    const query = await this.aspirantesServService.get()
    query.docs.forEach(res=>{
      data.push(res.data());
    })
    this.tableDataSource = new MatTableDataSource(data)
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    console.log(this.tableDataSource)
  }
}