import { Component, OnInit } from '@angular/core';
import { IMyDateRangeModel } from "mydaterangepicker";
import { CandidatesService } from '../../services/candidates.service'
import * as moment from 'moment';
import { GoogleService } from '../../services/google.service'
import * as _ from 'lodash';
import { error } from 'console';
import Swal from 'sweetalert2';
const haversine = require("haversine-distance");

declare let google: any;
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

  candidates_list = [];
  filtered_candidates_list = [];
  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: "deletePlan", title: '<i class="nb-trash"></i>' },
      ],
    },
    columns: {
      full_name:{ title: "Nombre" },
      experience_string: {title: "Expreiencia"},
      direccion:{ title: "Direccion" },
      cities:   { title: "Ciudad" },
      email:    { title: "Email" },
      telefono: {title: "Telefono"},
      tipodocumento: {title: "Tipo documento"},
      fecha: {title: "Fecha de registro"},
      diferenciaKilimetros: {title: "Distancia respecto a la busqueda"},
    },
  };

  experiences = [
    { label: 'Niñera/o', value: 'niñera' },
    { label: 'Conductor/a', value: 'conductor' },
    { label: 'Asistente creativo/a', value: 'asistente creativa' },
    { label: 'Guía turístico/a', value: 'guia' },
    { label: 'Chef', value: 'chef' },
    { label: 'Estilista', value: 'estilista' },
    { label: 'Entrenador/a', value: 'entrenador' },
    { label: 'Areas de bienestar', value: 'areas bienestar' },
    { label: 'Conserje', value: 'conserje' },
    { label: 'Empleado/a doméstico/a', value: 'empleada domestica' },
    { label: 'Auxiliar de servicios generales ', value: 'auxiliar general' },
    { label: 'Todero/a', value: 'todero' },
    { label: 'Interna', value: 'interna' },
  ];
  experiences_selected = [];

  address: any = '';
  placePredictions: any = [];
  destination = {
    latitude: 0,
    longitude: 0,
  };

  constructor(
    private GoogleService: GoogleService,
    private candidatesService: CandidatesService
  ) { }

  endDate   = moment().format("YYYY-MM-DD");
  beginDate = moment().subtract(15,'days').format("YYYY-MM-DD");

  ngOnInit() {
    this.getPlace();
    this.getCandidates(this.beginDate,this.endDate);
  }

  onSelectDateRange(event: IMyDateRangeModel){
    const endDate   = moment(event.endJsDate).format("YYYY-MM-DD");
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    if (beginDate != "Invalid date" && endDate != "Invalid date") {
      this.getCandidates(beginDate, endDate);
    }
  }

  async getCandidates(begin_date,end_date){
    console.log("getCandidates");
    let candidates_list = []
    const querry = await this.candidatesService.getByDateRange(begin_date,end_date);
    console.log(querry.size);
    for(let i = 0;i < querry.size;i++){
      let candidate = querry.docs[i].data();
      candidate.full_name = `${candidate.name} ${candidate.apellido}`;
      candidate.experience_string = candidate.areas_of_experience.join(', ')
      candidate.diferenciaKilimetros = "Escribe una direccion"
      candidates_list.push(candidate)
    }
    this.candidates_list = candidates_list;
    this.filtered_candidates_list = candidates_list;
  } 

  // TODO Ajustar query para que se ejecute directamente en la base de datos
  async onFilterCanditates(){
    if(this.experiences_selected.length > 0) {
      const a = await this.filterCanditatesByExperiences();
    }
    else this.filtered_candidates_list = this.candidates_list;
    if(this.address !== '') this.getCoords();
  }

  async filterCanditatesByExperiences(){
    return new Promise(async resolve =>{
      let candidates_list = [];
      for(let i = 0; i<this.candidates_list.length; i++){
        const isValid = await this.filterExperinces(i)
        if(isValid) candidates_list.push(this.candidates_list[i])
      }
      this.filtered_candidates_list = candidates_list
      resolve(true)
    }) 
  }

  filterExperinces(i){
    this.candidates_list[i]
    return this.experiences_selected.some(item => this.candidates_list[i].areas_of_experience.includes(item));
  }

  getPlace() {
    this.GoogleService.currentData.subscribe((place) => {
      this.placePredictions = place;
    });
  }

  getCoords(){
    const geocode = new google.maps.Geocoder();
    const a = geocode.geocode({ address: this.address.description });
    a.then(async (res) => {
      console.log(res, res.results[0].geometry.location)
      this.destination.latitude = res.results[0].geometry.location.lat();
      this.destination.longitude =  res.results[0].geometry.location.lng();
      this.sortByLocation();
    }).catch(error=>{
      console.error("Error de geocoder",error);
      Swal.fire("Espera","Direccion invalida, selecciona una direccion","warning");
    })
  }

  buscarDireccion(_term: string, ) {
    const text: string = _term;
    if (text === "") {
      return;
    }
    const search = `${text}, Colombia`;
    this.GoogleService.getPlacePredictions(search);
  }

  activeError(form: any, property: string, valid: string = "") {
    try {
      const element = form[property];
      const value = !valid
        ? typeof element.errors !== "undefined" && element.errors
        : typeof element.errors[valid] !== "undefined" && element.errors[valid];
      return value;
    } catch (error) {
      return false;
    }
  }

  async sortByLocation(){

    for(let i = 0;i < this.filtered_candidates_list.length; i++){
      const b = {
        latitude: this.filtered_candidates_list[i].ubicacion.lat,
        longitude: this.filtered_candidates_list[i].ubicacion.lng,
      }
      this.filtered_candidates_list[i].diferenciaCoordenadas = haversine(this.destination, b);
      this.filtered_candidates_list[i].diferenciaKilimetros =`${(this.filtered_candidates_list[i].diferenciaCoordenadas/1000).toFixed(2)} Km`
    }
    this.filtered_candidates_list = _.orderBy( this.filtered_candidates_list,['diferenciaCoordenadas'],['asc'])
    console.log(this.filtered_candidates_list)
  }

}
