import * as XLSX from 'xlsx';
import * as firebase from 'firebase';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from '../models/User.model';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireDatabase } from 'angularfire2/database';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private angularFireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    public router: Router,
    private storage: AngularFireStorage,

  ) { }



  all() {
    // return this.db.list('users/').valueChanges();
    return firebase.firestore().collection('users/').get();
  }
  detail(id: string) {
    // return this.db.object('users/' + id).valueChanges();
    return firebase.firestore().collection('users/').doc(id).get();
  }
  getUsersByProperty(property: string, value: any){
    return firebase.firestore().collection('users/').where(property,"==",value).get();
  }
  updateClient(usuario){
    usuario.id = !usuario.id ? usuario.uid : usuario.id;
    console.log(usuario)
    // return this.db.database.ref('users/' + usuario.id).update(usuario); 
    return firebase.firestore().collection('users/').doc(usuario.id).update(usuario);
  }

  allRealtime() {
    return this.db.list('users/').valueChanges();
  }
  guardarClienteFirestore(cliente){
    return firebase.firestore().doc('users/' + cliente.id).set(cliente);
  }

  public putPicture(pictureName, file) {
    return this.storage.ref('users/' + pictureName).put(file);
  }

  public getURL(pictureName) {
    return this.storage.ref('users/' + pictureName).getDownloadURL();
  }

  getAllUnicaVez(){
    let source = [];
    return this.db.list('users/').valueChanges()
  }

  /*getUsuarioById(id) {
    return this.db.list('users/' + id).valueChanges();
  }*/

  async delete(id) {
    return await this.db.object('users/' + id).update({ estado: 'desactivado' })
      .then(() => { return 'Cliente desactivado con éxito!' })
      .catch(() => { throw 'Inténto en desactivar cliente, fallido!' });
  }
 
  public exportAsExcelFile(json, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public guardarTotalServicios (id, totalServicios){
    return firebase.firestore().doc('users/' + id).update({numeroDeServicios: totalServicios})
  }

  updateKitClient(id,info){
    return firebase.firestore().doc('users/' + id).update({kit: info})
  }
  updateFavoriteServerClient(id,info){
    return firebase.firestore().doc('users/' + id).update({serverFavorita: info})
  }

}
  