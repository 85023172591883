// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  hashDB: {
    apiKey: "AIzaSyBtrum3w1Mg3XfP7yiO3WuM-kZm7M7keOs",
    authDomain: "serv-f2b29.firebaseapp.com",
    databaseURL: "https://serv-f2b29.firebaseio.com",
     projectId: "serv-f2b29",
   storageBucket: "serv-f2b29.appspot.com",
   messagingSenderId: "98296449606",
   appId: "1:98296449606:web:5d1d2be226b26445385847",
  },
/*   apiKey: "AIzaSyD5vKFHDAeZuRSHvb4km-r7zDml1rdkXDE",
   authDomain: "ambiente-de-pruebas-serv.firebaseapp.com",
  databaseURL: "https://ambiente-de-pruebas-serv-default-rtdb.firebaseio.com",
   projectId: "ambiente-de-pruebas-serv",
   storageBucket: "ambiente-de-pruebas-serv.appspot.com",
   messagingSenderId: "406486275098",
   appId: "1:406486275098:web:e94174403ac865a2892da3",
 measurementId: "G-ZSQ11W8X1F" */
  /*-----------------------------------*/
/*   apiKey: "AIzaSyBtrum3w1Mg3XfP7yiO3WuM-kZm7M7keOs",
  authDomain: "serv-f2b29.firebaseapp.com",
  databaseURL: "https://serv-f2b29.firebaseio.com",
   projectId: "serv-f2b29",
 storageBucket: "serv-f2b29.appspot.com",
 messagingSenderId: "98296449606" */

  

  googlemap: {
    apiKey: 'AIzaSyCjgDSWwtQstvHkCB-gytm1l_o7ByUJMyw',
    clientId: '<mandatory>',
    language: 'en',
    libraries: ['geometry', 'places']
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
