import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServicioService } from 'src/app/services/servicio.service';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.scss']
})

export class TransaccionesComponent implements OnInit {
  export;
  /*Para el modal*/
  @ViewChild('mdlEdit', { static: true }) mdlEdit: ElementRef;
  public transacciones: Array<Object> = [];
  public source: LocalDataSource;
  public user: any;
  public usuario: any;
  public count = 0;
  public transaccionId: any;
  public submitedEdit = false;
  public spinerEdit = false;
  public idUsuario: any;
  public email: any;
  public keyword: any = "email";
  public userCargado: boolean = false;
  public userList: any = [];
  public transaccion: any = {};
  public editar: boolean;
  public nameUser: any;
  public idUser: any;
  public fecha: any;
  public total: any;
  public estado: any;
  public idPayco: any;
  public refPayco: any;
  public token: any;
  public respuesta: any;
  public formaDePagoServicio: any;
  public editIdPayco: boolean;
  public idPaycoCopia: any;

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      formaDePago: { title: 'Forma de Pago' },
      fechaHora: {
        title: 'Fecha y hora', type: 'string',
        valuePrepareFunction: (fec) => {
          return moment(fec).format('DD/MM/YYYY hh:mm:ss') == 'Invalid date' ? 'N/A' : moment(fec).format('DD/MM/YYYY hh:mm:ss');
        }
      },
      usuario: { title: 'Usuario' },
      detalle: { title: 'Detalle' },
      respuesta: { title: 'Estado' },
      estado: { title: 'Estado epayco' },
      subtotal: { title: 'Subtotal' },
      impuesto: { title: 'impuesto' },
      total: { title: 'Total' }
    }
  };
  constructor(public servicioService: ServicioService,
    public router: Router,
    public fb: FormBuilder,
    private dialogService: NbDialogService,
    public ServiceServicio: ServicioService,
    private toastrService: NbToastrService,
    private UsuarioServicio: UsuarioService, ) { }

  ngOnInit() {
    this.getTransacciones();
  }

  /*Se obtienen un registro de todas las transacciones realizadas
  para cargar en la tabla*/
  public getTransacciones() {
    this.servicioService.getTransaccion().then(value => {
      value.forEach(doc => {
        let aux = (doc.data());
        this.servicioService.getUser(aux).subscribe(infoUser => {
          this.user = infoUser;
          this.usuario = (this.user.name + ' ' + this.user.apellido);
          aux.usuario = this.usuario;
          this.count++;
          aux.id = doc.id;
          if (aux.detalle == undefined) {
            aux.detalle = "servicio dia";
          }
          if (aux.estado == undefined) {
            aux.estado = "N/A"
          }
          if (aux.respuesta == undefined) {
            aux.respuesta = "N/A"
          }
          this.transacciones.push(aux);
          this.transacciones = _.orderBy(this.transacciones, ['fechaHora'], ['desc']);
          if (this.count = this.transacciones.length) {
            this.source = new LocalDataSource(this.transacciones);
          }
        });
      });
    });
  }

  public getUsuario() {
    this.UsuarioServicio.all().then((value) => {
      value.docs.forEach(data=>{
        this.userList.push(data.data());
      })
    });
  }

  public onSelectUser(event) {
    this.idUsuario = event.id;
    this.transaccion.userid = this.idUsuario;
  }

  public CrearUsuario() {
    // @ts-ignore
    this.dialogService.open(this.mdlCrear, { context: 'this is some additional data passed to dialog' });
  }

  public onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.goToEditarTransaccion(this.mdlEdit, event.data);
        break;
      case 'detalle':
        this.verTransacccion(event.data.id);
        break;
    }
  }
  consultarTransaccion() { }

  public goToEditarTransaccion(dialog, transaccion) {
    this.transaccion = {};
    this.getUsuario();
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    this.transaccion.idServicio = transaccion.idServicio;
    this.editar = false;
    this.nameUser = transaccion.usuario;
    this.idUser = transaccion.userid;
    this.transaccion.userid = this.idUser;
    this.fecha = moment(transaccion.fechaHora).format('YYYY-MM-DD');
    this.transaccion.fechaHora = this.fecha;
    this.transaccion.impuesto = transaccion.impuesto;
    this.transaccion.subtotal = transaccion.subtotal;
    this.transaccion.total = transaccion.total;
    this.formaDePagoServicio = transaccion.formaDePago;
    this.transaccion.formaDePago = transaccion.formaDePago;
    this.estado = transaccion.estado;
    this.idPayco = transaccion.id_payco;
    this.idPaycoCopia = transaccion.id.id_payco;
    this.refPayco = transaccion.ref_payco;
    this.token = transaccion.token;
    this.respuesta = transaccion.respuesta;
    this.transaccion.id = transaccion.id;
    if (transaccion.descuento) {
      this.transaccion.descuento = transaccion.descuento;
    }
    else {
      this.transaccion.descuento = 0;
    }
  }

  public metodoPagoServicio() {

  }

  public calendario() {
    let date = new Date(this.fecha);
    this.transaccion.fechaHora = new Date(date.setDate(date.getDate() + 1));
  }

  public editarUser() {
    this.editar = true;
  }

  public updateTransaccion() {
    this.submitedEdit = true;
    if (!this.transaccion.descuento) {
      this.transaccion.descuento = 0;
    } else if (!this.transaccion.subtotal) {
      this.transaccion.subtotal = 0;
    } else if (!this.transaccion.impuesto) {
      this.transaccion.impuesto = 0;
    } else if (this.transaccion.descuento >= 0 && this.transaccion.subtotal >= 0 && this.transaccion.impuesto >= 0) {
      this.spinerEdit = true;
      this.transaccion.total = (this.transaccion.subtotal + this.transaccion.impuesto) - this.transaccion.descuento;
      if (this.transaccion.formaDePago == "tarjeta") {
        if (this.idPayco && this.respuesta && this.refPayco && this.estado && this.token) {
          if (this.idPaycoCopia != this.idPayco) {
            this.transaccion.id_payco = this.idPayco;
            this.transaccion.respuesta = this.respuesta;
            this.transaccion.ref_payco = this.refPayco;
            this.transaccion.estado = this.estado;
            this.transaccion.token = this.token;
          }
          this.ServiceServicio.updateTransaccion(this.transaccion);
          this.spinerEdit = false;
          this.showToast('top-right', 'success', 'Éxito!', 'Transacción modificada!');
          return true;
        }
        else {
          this.spinerEdit = false;
          this.showToast('top-right', 'warning', 'Warning!', 'Debes llenar todos los campos!');
        }
      }
      else {
        this.ServiceServicio.updateTransaccion(this.transaccion);
        this.spinerEdit = false;
        this.showToast('top-right', 'success', 'Éxito!', 'Transacción modificada!');
        return true;
      }
    }
    else {
      this.showToast('top-right', 'warning', 'Warning!', 'Por favor ingresar valores positivos');
    }
  }

  public verTransacccion(idTransaccion) {
    this.router.navigate(['dashboard/ver-transaccion', idTransaccion]);
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(
      mensaje,
      titulo,
      { position, status });
  }
}
