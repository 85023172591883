import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  private novedadesCollection: AngularFirestoreCollection<any>;
  public novedades: Observable<any[]>;
  novedadDoc: AngularFirestoreDocument<any>;
  constructor(private afs: AngularFirestore) {
    this.novedadesCollection = afs.collection<any>("servicios");
    this.novedades = this.novedadesCollection.valueChanges();
  
   }


   getNovedades() {
    return (this.novedades = this.novedadesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as any;
        data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  obtenerNovedades() {
    this.novedadesCollection = this.afs.collection("servicios", (ref) =>
      ref.where("novedad", "==", true)
    );
    return (this.novedades = this.novedadesCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as any;
          data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  edidNovedad(novedad : any) : void {
    let id = novedad.id;
    this.novedadDoc = this.afs.doc<any>(
      `servicios/1614126951993`
    );
    this.novedadDoc.update(novedad);
  }
  
  
}
