import { Gps } from '../models/interface/Gps.interface';
import { ServerInterface } from '../models/interface/Server.interface';
import { User } from '../models/User.model';
import { OptionsColor } from './OptionsColor';

const DEFAULT: String = ''
const Origin: Gps = { lat: 4.60971, lng: -74.08175 };
const Destination: Gps = { lat: 4.605397, lng: -74.083771 };
const OriginDefault: Gps = { lat: 4.60971, lng: -74.08175 };
const DestinationDefault: Gps = { lat: 4.605397, lng: -74.083771 };
const Avatar: string = 'https://firebasestorage.googleapis.com/v0/b/serv-f2b29.appspot.com/o/vectores%2FuserDefault.png?alt=media&token=69a1f192-5eed-47d8-a58a-1eadcb01c7d3';

const MarkerOptions = {
  origin: {
    icon: '/assets/map/server.png',
    ...Origin
  },
  destination: {
    icon: '/assets/map/client-home.png',
    ...DestinationDefault,
    infoWindow: `
        <h4>Hello<h4>
        <a href='http://www-e.ntust.edu.tw/home.php' target='_blank'>Taiwan Tech</a>
        `
  },
};

const CardinalPoints: any = [
  { name: 'Norte', value: 'Norte' },
  { name: 'Sur', value: 'Sur' },
  { name: 'Occidente', value: 'Occidente' },
  { name: 'Oriente', value: 'Oriente' },
  { name: 'Sur Occidente', value: 'SurOccidente' },
  { name: 'Sur Oriente', value: 'SurOriente' },
  { name: 'Nor Occidente', value: 'NorOccidente' },
  { name: 'Nor Oriente', value: 'NorOriente' },
];


const LaboralRisk: any = [
  { name: 'Axa Colpatria', value: 'Axa Colpatria' },
  { name: 'Aurora Seguros', value: 'Aurora Seguros' },
  { name: 'Colmena Seguros', value: 'Colmena Seguros' },
  { name: 'Equidad Seguros', value: 'Equidad Seguros' },
  { name: 'Positiva Seguros', value: 'Positiva Seguros' },
  { name: 'Seguros Alfa', value: 'Seguros Alfa' },
  { name: 'Seguros Bolívar', value: 'Seguros Bolívar' },
  { name: 'Sura', value: 'Sura' },
  { name: 'Ninguna', value: 'Ninguna' },
]

const Compensation: any = [
  { name: 'Colsubsidio', value: 'Colsubsidio' },
  { name: 'Cafam', value: 'Cafam' },
  { name: 'Compensar', value: 'Compensar' },
  { name: 'Ninguna', value: 'Ninguna' },
]

const Nationality: any = [
  { name: 'Argentina', value: 'Argentina' },
  { name: 'Boliviana', value: 'Boliviana' },
  { name: 'Canadiense', value: 'Canadiense' },
  { name: 'Chilena', value: 'Chilena' },
  { name: 'Colombiana', value: 'Colombiana' },
  { name: 'Ecuatoriana', value: 'Ecuatoriana' },
  { name: 'Mexicana', value: 'Mexicana' },
  { name: 'Panameña', value: 'Panameña' },
  { name: 'Peruana', value: 'Peruana' },
  { name: 'Uruguya', value: 'Uruguya' },
  { name: 'Venezolana', value: 'Venezolana' },

]

const HealthAgency: any = [
  { name: 'Cafesalud ', value: 'Cafesalud' },
  { name: 'Colseguros ', value: 'Colseguros ' },
  { name: 'Comfenalco Antioquia', value: 'Comfenalco Antioquia' },
  { name: 'Comfenalco Valle', value: 'Comfenalco Valle' },
  { name: 'Compensar', value: 'Compensar' },
  { name: 'Coomeva ', value: 'Coomeva' },
  { name: 'Cruz Blanca', value: 'Cruz Blanca' },
  { name: 'Famisanar', value: 'Famisanar' },
  { name: 'Humana Vivir', value: 'Humana Vivir' },
  { name: 'Saludcoop', value: 'Saludcoop' },
  { name: 'Saviasalud', value: 'Saviasalud' },
  { name: 'Coopsalud', value: 'Coopsalud' },
  { name: 'Salud Colombia', value: 'Salud Colombia' },
  { name: 'Salud Colmena', value: 'Salud Colmena' },
  { name: 'Salud Total', value: 'Salud Total' },
  { name: 'Salud Vida', value: 'Salud Vida' },
  { name: 'Sanitas ', value: 'Sanitas' },
  { name: 'Servicios Medicos Colpatria', value: 'Servicios Medicos Colpatria' },
  { name: 'Servicio Occidental de Salud ', value: 'Servicio Occidental de Salud ' },
  { name: 'SuSalud ', value: 'SuSalud ' },
  { name: 'Solsalud', value: 'Solsalud' },
  { name: 'Red Salud Atención Humana', value: 'Red Salud Atención Humana' },
  { name: 'Sura ', value: 'Sura' },
  { name: 'Convida ', value: 'Convida' },
  { name: 'Capital Salud ', value: 'Capital Salud' },
  { name: 'Nueva EPS ', value: 'Nueva EPS' },
  { name: 'propia', value: 'Por cuenta propia' },
  { name: 'Ninguna', value: 'Ninguna' },
]

const BloodType: any = [
  { name: 'A +', value: 'A +' },
  { name: 'A -', value: 'A -' },
  { name: 'B +', value: 'B +' },
  { name: 'B -', value: 'B -' },
  { name: 'O +', value: 'O +' },
  { name: 'O -', value: 'O -' },
  { name: 'AB +', value: 'AB +' },
  { name: 'AB -', value: 'AB -' },
]

const States: any = [
  { name: 'activo', value: 'activo' },
  { name: 'desactivado', value: 'desactivado' },
]
const ClientType: any = [
  { name: 'Empresa', value: 'Empresa' },
  { name: 'Persona', value: 'Persona' },
];
const Genders: any = [
  { name: 'Femenino', value: 'Femenino' },
  { name: 'Masculino', value: 'Masculino' },
];

const Children: any = [
  { name: '0', value: 'Hijos' },
  { name: '1', value: 'Hijos' },
  { name: '2', value: 'Hijos' },
  { name: '3', value: 'Hijos' },
  { name: '4', value: 'Hijos' },
  { name: '5', value: 'Hijos' },
  { name: '6', value: 'Hijos' },

]

const PensionFund: any = [
  { name: 'Colfondos Pensiones y Cesantías', value: 'fondo' },
  { name: 'Protección S.A.', value: 'fondo' },
  { name: 'Porvenir S.A.', value: 'fondo' },
  { name: 'Old Mutual', value: 'fondo' },
  { name: 'Ninguna', value: 'Ninguna' },

]

const TypeDocuments: any = {
  Empresa: [
    { name: 'Nit', value: 'Nit', code: '31' }
  ],
  Persona: [
    { name: 'Cédula de ciudadania', value: 'Cédula de ciudadania', code: '13' },
    { name: 'Pasaporte', value: 'Pasaporte', code: '41' },
    { name: 'Cédula extranjera', value: 'Cédula extranjera', code: '22' },
    { name: 'PEP', value: 'PEP', code: '47' },
    { name: 'PPT', value: 'PPT', code: '47' },
  ]
};

const socialSecurity: any = {
  1: "Vencida",
  2: "Con planilla",
  3: "Sin planilla"
};

const PropertiesTypeList: any = [
  { name: 'Apartamento', value: 'Apartamento' },
  { name: 'Casa', value: 'Casa' },
  { name: 'Local', value: 'Local' },
  { name: 'Oficina', value: 'Oficina' },
  { name: 'Garaje', value: 'Garaje' },
  { name: 'Bodega', value: 'Bodega' },
  { name: 'Propiedad horizontal', value: 'PropiedadHorizontal' },
];

const BankAccount: any = [
  { name: 'Nequi', value: 'Nequi' },
  { name: 'TPaga', value: 'Tpaga' },
  { name: 'Bancolombia', value: 'Bancolombia' },
  { name: 'Ahorro a la mano', value: 'Ahorro a la mano' },
  // { name: 'Nequi', value: 'Nequi' },
  // { name: 'Bancolombia', value: 'Bancolombia' },
  // { name: 'Bancolombia', value: 'Bancolombia' },
  // { name: 'Banco de Bogotá', value: 'Banco de Bogotá' },
  // { name: 'Davivienda', value: 'Davivienda' },
  // { name: 'BBVA', value: 'BBVA' },
  // { name: 'AV Villas', value: 'AV Villas' },
  // { name: 'Agrario', value: 'Agrario' },
  // { name: 'Caja social', value: 'Caja social' },
  // { name: 'Cooperativo Coopcentral', value: 'Cooperativo Coopcentral' },
  // { name: 'Falabella', value: 'Falabella' },
  // { name: 'GNB Sudameris', value: 'GNB Sudameris' },
  // { name: 'Banco Pichincha', value: 'Banco Pichincha' },
  // { name: 'Popular', value: 'Popular' },
  // { name: 'Procredit Colombia', value: 'Procredit Colombia' },
  // { name: 'Banco Santander', value: 'Banco Santander' },
  // { name: 'Banco Serfinanza', value: 'Banco Serfinanza' },
  // { name: 'Banco W', value: 'Banco W' },
  // { name: 'Banco de occidente', value: 'Banco de occidente' },
  // { name: 'Bancoldex', value: 'Bancoldex' },
  // { name: 'Confiar cooperativa Financiera', value: 'Confiar cooperativa Financiera' },
  // { name: 'Daviplata', value: 'Daviplata' },
  // { name: 'Itau', value: 'Itau' },
  // { name: 'Rappipay', value: 'Rappipay' },
  // { name: 'Scotiabank Colpatria', value: 'Scotiabank Colpatria' },
  // { name: 'Ninguna', value: 'Ninguna' },
]


const habilidades: any = [
  "empleada domestica",
  "aseo hogar",
  "aseo empresa",
  "aseo airbnb",

  "cuidado niños",
  "cuidado adultos mayores",
  "enfermera postsirugia",

  "cocina",
  "planchado",
  
  "conserje",
  "todero",
  "pintor",
  "mudanza",
  "cerrajero",
  "ayudante",
  "fotografo",
  "transporte aeropuerto",
  "entrenador personal",
  "guia turistico",
  "estilista",
  "arreglo de uñas",
  "masajista",
]
const habilidadesWithIcon: any = [
  { 
    name: "aseo hogar", 
    icon: "icon_AseoHogar" 
  },
  { 
    name: "cocina", 
    icon: "icon_Cocina" 
  },
  { 
    name: "planchado", 
    icon: "icon_Planchado" 
  },
  { 
    name: "aseo empresa", 
    icon: "icon_AseoEmpresa" 
  },
  { 
    name: "desinfección", 
    icon: "icon_Desinfecion" 
  },
  { 
    name: "maquinas", 
    icon: "icon_Maquinas" 
  },
  { 
    name: "todero", 
    icon: "icon_Todero" 
  },
  { 
    name: "fotografo", 
    icon: "icon_Fotografo" 
  },
  { 
    name: "conductor", 
    icon: "icon_Conductor" 
  },
  { 
    name: "guia turistico", 
    icon: "icon_GuiaTuristico" 
  },
  { 
    name: "cuidado niños", 
    icon: "icon_CuidadoNiños" 
  },
  { 
    name: "cuidado mascotas", 
    icon: "icon_CiudadoMascotas" 
  },
  { 
    name: "cuidado adultos mayores", 
    icon: "icon_CuidadoAdultosMayores" 
  }
]

const DocumentTypes: any = [
  { name: 'Cédula de ciudadania', value: 'Cédula de ciudadania', code: '13' },
  { name: 'Pasaporte', value: 'Pasaporte', code: '41' },
  { name: 'Cédula extranjera', value: 'Cédula extranjera', code: '22' },
  { name: 'PEP', value: 'PEP', code: '47' },
  { name: 'PPT', value: 'PPT', code: '47' },
]

const ListExtractService: any = [
  "limpieza",
  "alimentos",
  "lavado",
  "planchado",
  "anciano",
  "mascota",
  "nino",
];

const ColorService: any = {
  red: "Rechazado",
  blue: "Aceptado",
  grey: "Pendiente",
  green: "Terminado",
  orange: "En curso",
  purple: "Cancelado",
  darkcyan: "En camino",
};
// Array<OptionsColor>
const ListColorService: Array<OptionsColor> = [
  {
    name: "Rechazado",
    color: 'red'
  },
  {
    name: "Anulado",
    color: 'cancelado'
  },
  {
    name: "Cancelado",
    color: 'cancelado'
  },
  {
    name: "Aceptado",
    color: 'aceptado'
  },
  {
    name: "Activo",
    color: 'purple'
  },
  {
    name: "Pendiente",
    color: 'grey'
  },
  {
    name: "Terminado",
    color: 'terminado'
  },
  {
    name: "En camino",
    color: 'enCamino'
  },
  {
    name: "Pendiente confirmar",
    color: 'pendienteConfirmar'
  },
  {
    name: "En curso",
    color: 'enCurso'
  },
  {
    name: "Confirmado",
    color: 'confirmado'
  },
  {
    name: "Congelado",
    color: 'congelado'
  }
];

const HorasDeServicio: Array<any> = [
  { name: 2 },
  { name: 4 },
  { name: 6 },
  { name: 8 }
]

const ColorMonitoring: any = {
  1: "blue",
  2: "orange",
  3: "red",
  4: "blue",
  5: "orange",
  6: "red",
  7: "green",
  8: "orange",
  9: "yellow",
  10: "purple",
};
const ColorMonitoring_text: any = {
  1: "blue_text",
  2: "orange_text",
  3: "red_text",
  4: "blue_text",
  5: "orange_text",
  6: "red_text",
  7: "green_text",
  8: "orange_text",
  9: "yellow_text",
  10: "purple_text",
};

const MessageMonitoring = {
  1: "Hora de salida, La server no ha notificado salida.",
  2: "En camino, La server no ha notificado salida.",
  3: "Alerta, posible abandono del servicio.",
  4: "Servicio en oferta sin server, recomendar o asignar una server.",
  5: "Servicio en perdida, una hora para el servicio.",
  6: "Alerta, Perdida del servicio.",
  7: "Que buena noticia, server super puntual.",
  8: "Que puntualidad.",
  9: "Alerta, La server no ha notificado inicio del servici.",
  10: "Este servicio evidencia un retraso en su finalización.",
  11: "Este servicio debe estar finalizado",
};
const ColorNews: any = {
  red: "Novedad Sin Solucion",
  blue: "Aceptado",
  grey: "Sin Novedades",
  green: "Novedad Solucionada",
  orange: "Servicio Con Novedades",
};

const CalendarResponsive = {
  small: {
    breakpoint: '(max-width: 576px)',
    daysInWeek: 2
  },
  medium: {
    breakpoint: '(max-width: 768px)',
    daysInWeek: 3
  },
  large: {
    breakpoint: '(max-width: 960px)',
    daysInWeek: 5
  }
};

const ListColoStateNews: Array<OptionsColor> = [
  {
    name: "Pendiente",
    status: "danger",
    color: "red"
  },
  {
    name: "En Proceso",
    status: "warning",
    color: "orange"
  },
  {
    name: "Solucionada",
    status: "success",
    color: "green"
  },
];

// Interfac Resetiadas
const ResetClientInterface: User = {
  id: '',
  name: '',
  apellido: '',
  fechaNacimiento: 0,
  email: '',
  estado: '',
  rol: '',
  tipo: '',
  foto: '',
  telefono: 0,
  typoDocumento: '',
  tipoPropiedad: '',
  observaciones: '',
  comentario: '',
  documento: '',
  direccion: '',
  direccion2: '',
  country: '',
  department: '',
  cities: '',
  suburbs: '',
  neighborhood: '',
  genero: '',

  terminos: false,
  fecha: 0,
  cread_at: 0,
  update_at: 0,
  gps: Origin,
  idLocation: '',
  kitObsequio: false,
};

const ResetServerInterface: ServerInterface = {
  id: '',
  cedula: 0,
  name: '',
  apellido: '',
  tipoPropiedad: '',
  direccionResidencia: '',
  habilidades: [],
  country: '',
  department: '',
  cities: '',
  suburbs: '',
  neighborhood: '',
  observaciones: '',
  email: '',
  genero: '',
  tipoDeSangre: '',
  numeroHijos: 0,
  telefono: 0,
  numeroCuenta: 0,
  tipoCuentaBancaria: '',
  fechaNacimiento: '',
  balance: 0,
  frase: '',
  foto: '',
  estado: '',
  terminos: true,
  typoDocumento: '',
  fecha: 0,
  gps: Destination,
  idLocation: '',
  rol: "Server",
  experiencia: [],
  estudios: []
};

export {
  Origin,
  habilidades,
  Destination,
  OriginDefault,
  DestinationDefault,
  MarkerOptions,
  ResetClientInterface,
  ResetServerInterface,
  Avatar,
  PropertiesTypeList,
  CardinalPoints,
  CalendarResponsive,
  ColorMonitoring,
  ColorMonitoring_text,
  MessageMonitoring,
  ListExtractService,
  ListColorService,
  ListColoStateNews,
  HorasDeServicio,
  ColorService,
  Genders,
  TypeDocuments,
  socialSecurity,
  ClientType,
  States,
  BankAccount,
  DocumentTypes,
  BloodType,
  Children,
  PensionFund,
  Compensation,
  LaboralRisk,
  HealthAgency,
  Nationality,
  habilidadesWithIcon,
}


