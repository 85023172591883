import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http } from '@angular/http';
import * as moment from 'moment';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AngularFirestore } from 'angularfire2/firestore';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})

export class Metricas {

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { }

  public post(metrica) {
    return firebase.firestore().collection('metricas').add(metrica);
  }
  public getMetricas(){
    return firebase.firestore().collection('metricas').get();
  }
  public getMetricasMes(mes,anio){
    return firebase.firestore().collection('metricas')
    .where('mes','==',mes)
    .where('anio','==',anio)
    .get();
  }

  getMetricasSemanaPasada(){
    return firebase.firestore().collection('metricas')
    .orderBy('rango_fechaInicio','desc').limit(2)
    .where('rangoMedicion','==','Semana') 
    .get();
  }
  
  public getMetricasTipoMes(anio, mes){
    return firebase.firestore().collection('metricas')
    .where('mes','==',mes)
    .where('anio','==',anio)
    .get();
  }

  public getAllMetricasByYear(year){
   return firebase.firestore().collection('metricas')
   .where('anio','==',year)
   .where("rangoMedicion","==","Mes")
   .get()
  }

  public getLengthServicios(){
    return firebase.firestore().collection('servicios').get();
  }

  crearNovedad(novedad){
    return firebase.firestore().doc('novedades/' + novedad.id).set(novedad);
  }

  updateNovedad(novedad){
    return firebase.firestore().collection("novedades").doc(`${novedad.id}`).update(novedad);
  }


  getNovedades(){
    return firebase.firestore().collection('novedades').where("fechaCreacion", "<", "2024-01-01").get();
    // return firebase.firestore().collection('novedades').get();
  }
  getNovedadesPorARea(area){
    return firebase.firestore().collection('novedades')
    .where("area","==",area)
    .get();
  }
  getNovedadesPorAReaRangoDeFecha(area,fechaInicio,fechaFinal){
    return firebase.firestore().collection('novedades')
    .where("area","==",area)
    .where("fechaCreacion",">=",fechaInicio)
    .where("fechaCreacion","<=",fechaFinal)
    .get();
  }
  getNovedadesPorAreaRealTime(area){
    return firebase.firestore().collection('novedades')
    .where("area","==",area)
  }

  getNovedadesPorAgente(agente){
    return firebase.firestore().collection('novedades')
    .where("agenteDesignado","==", agente)
    .get();
  }
  getNovedadesPorAgenteRealTime(agente){
    return firebase.firestore().collection('novedades')
    .where("agenteDesignado","==", agente)
  }

  getMisNovedadesCreadas(emisor){
    return firebase.firestore().collection('novedades')
    .where("emisor","==",emisor)
    .get();
  }
  getMisNovedadesCreadasConRangoFecha(emisor,fechaInicio,fechaFinal){
    return firebase.firestore().collection('novedades')
    .where("emisor","==",emisor)
    .where("fechaCreacion",">=",fechaInicio)
    .where("fechaCreacion","<=",fechaFinal)
    .get();
  }

  getNovedadesPago(id){
    return firebase.firestore().collection('novedades')
    .where("idPagoServer","==",id)
    .get();
  }

  getNovedadesServicio(idServicio){
    return firebase.firestore().collection('novedades')
    .where("servicioAsociado","==",idServicio).get();
  }

  getNovedadesPorServer(id){
    return firebase.firestore().collection('novedades')
    .where("serverId","==",id).get();
  }
  getNovedadesApeladasServer(id){
    return firebase.firestore().collection('novedades')
    .where("serverId","==",id)
    .where('estado','==','Apelada')
    .get();
  }
  getNovedadesVigentesServer(id,fecha){
    return firebase.firestore().collection('novedades')
    .where("serverId","==",id)
    .where('estadoGeneral','==','vigente')
    .where('fechaCreacion','<=',fecha)
    .get();
  }
  getNovedadesVigentesServerDirecta(id,fecha){
    return firebase.firestore().collection('novedades')
    .where("serverId","==",id)
    .where('estadoGeneral','==','vigente')
    .where('categoria','==','Server directa')
    .where('fechaCreacion','<=',fecha)
    .get();
  }

  getNovedadesVigentes(fecha){
    return firebase.firestore().collection('novedades')
    .where('estadoGeneral','==','vigente')
    .where('fechaCreacion','<=',fecha)
    .get()
  }

  getNovedadesServersDirectas(star_date,end_date){
    return firebase.firestore().collection('novedades')
    .where('categoria','==','Server directa')
    .where('fechaCreacion','>=',star_date)
    .where('fechaCreacion','<=',end_date)
    .get();
  }

  getNovedadesRnagoFechaCreacion(fchInicio,fchFin){
    return firebase.firestore().collection('novedades')
    .where("fechaCreacion",">=",fchInicio)
    .where("fechaCreacion","<=",fchFin)
    .get();
  }
  getNovedades_tipo_ragoFechaCreacion(fchInicio,fchFin){
    return firebase.firestore().collection('novedades')
    .where("fechaCreacion",">=",fchInicio)
    .where("fechaCreacion","<=",fchFin)
    .where("tipo",'==','Multa')
    .get();
  }

  getNovedadesRnagoFechaServicion(fchInicio,fchFin){
    return firebase.firestore().collection('novedades')
    .where("fechaDelServicio",">=",fchInicio)
    .where("fechaDelServicio","<=",fchFin)
    // .where("tipo","==",'Cancela Server')
    .get();
  }
  
  getNovedadesPorTipo(tipo){
    return firebase.firestore().collection('novedades')
    .where("tipo","==",tipo)
    .get();
  }

  deleteNovelty(id){
    return firebase.firestore().doc('novedades/' + id).delete()
  }

  deleteServiceNovelty(id){
    return firebase.firestore().collection('novedades').where('servicioAsociado','==',id).get().then(res=>{
      res.docs.forEach(data=>{
        this.deleteNovelty(data.id);
      })
    })
  }

  getNovedadServerPorTipoFecha(id,tipo,fecha){
    return firebase.firestore().collection('novedades').where('serverId','==',id).where('tipo','==',tipo).where('fechaCreacion','==',fecha).get();
  }

  /* Baner */

  getBanerDia(){
    return firebase.firestore().collection('baners')
      .where('fecha','==',moment().format('YYYY-MM-DD'))
      .get();
  }

  getUltimoBanerPublicado(){
    return firebase.firestore().collection('baners').orderBy('time','desc').limit(1).get()
  }

  async addNewBaner(info){
    const a = await firebase.firestore().collection('baners').add(info)
    return  a.update({id:a.id})
  }


 

}