import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { LocalDataSource } from "ng2-smart-table";
import { CompleterService } from "ng2-completer";
import { NbDialogService } from "@nebular/theme";
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as _ from "lodash";
/* Servicios */
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})

export class LocationComponent implements OnInit {

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        // { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'edit', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      country: { title: 'Pais' },
      city: { title: 'Ciudad' },
      supportPhone: { title: 'Telefono de soporte' },
      officeAddress: { title: 'Oficina' },
    },
  };
  public location: any = {};
  public send: boolean = false;
  public data: any = [];
  public source;

  /*  Para el modal */
  @ViewChild("createLocation", { static: true }) createLocation: ElementRef;
  @ViewChild("editLocation", { static: true }) editLocation: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  constructor(
    public completerService: CompleterService,
    public locationService: LocationService,
    public dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.data = [];
    this.load();
  }

  /**
   * Function to load all locations in DB
   */
  public load() {
    this.data = [];
    this.locationService.getLocations().then(result => {
      result.forEach(doc => {
        doc.data().createdAt = moment(doc.data().createdAt).format('YYYY-MM-DD');
        this.data.push(doc.data());
      });
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
      this.source = new LocalDataSource(this.data);
    }).catch((err) => {
      console.log(err);
    });
  }

  /**
   * Function to create a new location in DB
   */
  public onCreateLocation() {
    this.send = false;
    this.location.id = this.makeId();
    this.location.createdAt = Date.now();
    if (this.location.city != '' && this.location.country != '' && this.location.supportPhone != '') {
      this.locationService.post(this.location).then((result) => {
        Swal.fire(
          'Éxito',
          'Se agregó la locacion exitosamente',
          'success'
        );
        this.closeModal();
        this.load();
      }).catch(err => {
        this.send = true
        Swal.fire(
          'Error',
          'No se pudo agregar la locacion, verifique la información',
          'warning'
        );
      });
    } else {
      Swal.fire(
        'Error',
        'No se pudo agregar la locacion, verifique la información',
        'warning'
      );
    }
  }

  /**
   * Function to update location in DB
   */
  public onUpdateLocation() {
    this.send = false;
    if (this.location.city != '' && this.location.country != '' && this.location.supportPhone != '') {
      this.locationService.editLocation(this.location);
      Swal.fire(
        'Éxito',
        'Se actualizo la locacion exitosamente',
        'success'
      );
      this.closeModal();
      this.load();
    } else {
      this.send = true;
      Swal.fire(
        'Error',
        'No se pudo actualizar la locacion, verifique la información',
        'warning'
      );
    }
  }

  /**
   * Function to delete location in DB
   * @param location
   */
  public deleteLocation(location) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar la locacion?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(respond => {
      if (respond.value) {
        this.locationService.deleteLocation(location);
        Swal.fire(
          'Éxito',
          'Locacion eleminada con exito',
          'success'
        );
        this.load();
      }
    }, err => {
      console.log(err);
      Swal.fire(
        'Error',
        'Ocurrio un error al borrar la locacion, porfavor intetelo nuevamente',
        'warning'
      )
    });
    this.spinerGuardar = false;
  }

  /**
   * Function to open modal to create location
   */
  public onOpenModalCreate() {
    this.location = {};
    this.openDialogSave(this.createLocation);
  }

  /**
   * Function to open modal to edit location
   */
  public openModalEdit(location) {
    this.location = location;
    this.openDialogSave(this.editLocation);
  }

  /**
   * Function open modals with Ref 
   * @param dialog
   */
  public openDialogSave(dialog) {
    this.dialogService.open(dialog);
  }

  /**
   * Function to close modal
   */
  public closeModal() {
    this.spinerEdit = false;
    const element: HTMLElement = document.getElementById("btn-close") as HTMLElement;
    element.click();
  }

  /**
   * Function to assign a custom action to ng2-to-smart-table
   * @param event
   */
  public onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.deleteLocation(event.data);
        break;
      case 'edit':
        this.openModalEdit(event.data);
        break;
    }
  }

  /**
   * Function to create random label
   */
  public makeId() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}

