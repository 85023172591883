import { Component, OnInit } from "@angular/core";
import { CompleterService } from "ng2-completer";
import * as moment from "moment";
import * as _ from "lodash";
/* Servicios */
import { ServicioService } from 'src/app/services/servicio.service';
import { ServerService } from '../../services/server.service';
import { Router } from '@angular/router';



@Component({
	selector: 'app-disponibilidad',
	templateUrl: './disponibilidad.component.html',
	styleUrls: ['./disponibilidad.component.scss']
})

export class DisponibilidadComponent implements OnInit {
	public comienzo = moment().format('YYYY-MM-DD');
	public fin = moment().add(6, 'days').format('YYYY-MM-DD');
	public arrayServicios: any = [];
	public servers: any = [];
	public servicios: any = [];
	public tabla: any = [];
	public horarioServer: any = {}
	public horarioHeader: any = [];
	public cargando: boolean = true;

	constructor(
		public completerService: CompleterService,
		private ServicioService: ServicioService,
		private ServerService: ServerService,
		public router: Router
	) {
		this.cargarHorario();
	}

	ngOnInit() {
		this.cargando = true;
		this.getServers();
	}

	/**
	 * funcion para obtener servicios de una server filtrados por fecha
	 * @param server server
	 * @param comienzo fecha inicio
	 * @param fin fecha fin
	 */
	public getServiciosByServer(server, servicios) {
		return new Promise((success, err) => {
			this.arrayServicios = [];
			this.arrayServicios = servicios.filter(servicio => servicio.idServer == server.id);
			success(this.arrayServicios);
		});
	}

	/**
	 * funcion para obtener servicios de una server filtrados por fecha
	 * @param server server
	 * @param comienzo fecha inicio
	 * @param fin fecha fin
	 */
	public getServiciosSemana(comienzo, fin) {
		return new Promise((success, err) => {
			let arrayprueba = [];
			this.arrayServicios = [];
			this.ServicioService.getInDateRange(comienzo, fin).then(value => {
				value.docs.forEach(doc => {
					let aux = (doc.data());
					// aux.server = server;
					if (aux.estado != 'anulado') arrayprueba.push(aux);
				});
				this.arrayServicios = arrayprueba;
				success(this.arrayServicios);
			});
		});
	}

	/**
	 * Funcion para cargar todas las servers activas del sistema
	 */
	public getServers() {
		this.ServerService.Actives().then((result) => {
			let serversArray = [];
			let count = 0;
			result.docs.forEach(data => {
				const element = data.data()
				if (element.estado == undefined) element.estado = "N/A"
				let server = {
					cedula: element.cedula,
					nombre: element.name,
					apellido: element.apellido,
					direccion: element.direccionResidencia,
					email: element.email,
					telefono: element.telefono,
					nacimiento: element.fechaNacimiento,
					balance: element.balance,
					habilidades: element.habilidades ? element.habilidades : [],
					frase: element.frase,
					estado: element.estado,
					terminos: element.terminos,
					id: element.id,
					createdAt: element.fecha
				}
				count++;
				if ((server.estado == 'Activo' || server.estado == 'activo') && server.id) {
					serversArray.push(server);
				}

				if (count == result.docs.length) {
					this.servers = serversArray;
				}
			});
			this.servers = _.orderBy(this.servers, ['nombre'], ['asc']);
			this.verDisponibilad(this.comienzo, this.fin)
		})
	}

	/**
	 * Funcion para cambiar filtro de semana
	 * @param tipo next y back dependiendo del tipo, filtra la proxima semana o la anterior
	 */
	public semanas(tipo) {
		if (tipo == 'next') {
			this.cargando = true;
			this.comienzo = moment(this.comienzo).add(7, 'days').format('YYYY-MM-DD');
			this.fin = moment(this.fin).add(7, 'days').format('YYYY-MM-DD');
			this.cargarHorario();
			this.verDisponibilad(this.comienzo, this.fin);
		} else if (tipo == 'back') {
			this.cargando = true;
			this.comienzo = moment(this.comienzo).subtract(7, 'days').format('YYYY-MM-DD');
			this.fin = moment(this.fin).subtract(7, 'days').format('YYYY-MM-DD');
			this.cargarHorario();
			this.verDisponibilad(this.comienzo, this.fin);
		}
	}

	verServer(obj) {
		let server = JSON.stringify(obj);
		this.router.navigate(['dashboard/ver-server', server]);
	}

	/**
	 * Funcion que filtra y une todos los datos de la tabla segun las fechas que se escojan
	 * @param comienzo fecha inicio
	 * @param fin fecha final
	 */
	public async verDisponibilad(comienzo, fin) {
		this.tabla = [];
		let count = 0;
		let tablaArray = [];
		this.servicios = [];
		this.servicios = await this.getServiciosSemana(comienzo, fin);
		for (let index = 0; index < this.servers.length; index++) {
			const server = this.servers[index];
			// console.log(server);
			let disponibilidad: any;
			let serviciosServer: any = [];
			serviciosServer = await this.getServiciosByServer(server, this.servicios);
			disponibilidad = await this.getDisponibilidadServer(server.id);
			if (serviciosServer.length > 0) {
				this.horarioServer = {}
				let horarioSemana = this.horariosSemana(serviciosServer, disponibilidad);
				this.horarioServer = {
					server: server.nombre + ' ' + server.apellido,
					zona: server.direccion,
					serverCompleta: server,
					especialidad: server.habilidades.length > 0 ? server.habilidades[0].nombre : 'N/A',
					horario: horarioSemana
				}
				tablaArray.push(this.horarioServer)
			} else {
				this.horarioServer = {}
				let horarioSemana = this.horariosSemana(serviciosServer, disponibilidad);
				this.horarioServer = {
					server: server.nombre + ' ' + server.apellido,
					zona: server.direccion,
					serverCompleta: server,
					especialidad: server.habilidades.length > 0 ? server.habilidades[0].nombre : 'N/A',
					horario: horarioSemana
				}
				tablaArray.push(this.horarioServer)
			}
			count++
			if (count == this.servers.length) {
				this.tabla = tablaArray;
			}
		}
		this.cargando = false;
	}

	/**
	 * Funcion para cargar el header de la tabla
	 */
	public cargarHorario() {
		this.horarioHeader = [];
		let fecha = moment(this.comienzo).subtract(1, 'd').format('YYYY-MM-DD');
		for (let index = 0; index < 7; index++) {
			fecha = moment(fecha).add(1, 'd').format('YYYY-MM-DD');
			let dia = moment(fecha).format('DD');
			let mes = moment(fecha).format('MMMM');
			let anio = moment(fecha).format('YYYY');
			let fechaFormat = this.diaSemana(dia, mes, anio)
			this.horarioHeader.push({ dateLabel: fechaFormat, dateNumber: fecha });
		}
	}
	// let fechaformat = this.diaSemana()

	public diaSemana(dia, mes, anio) {
		let dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
		let dt = new Date(mes + ' ' + dia + ', ' + anio + ' 12:00:00');
		return dias[dt.getUTCDay()];
	};

	public getDisponibilidadServer(id) {
		return new Promise(success => {
			let disponibilidadServer = [];
			this.ServicioService.getDisponibilidadById(id).then(value => {
				if (value.docs != undefined) {
					let info = value.docs;
					let date = [];
					info.forEach(element => {
						let informacion = element.data();
						informacion.fechas.forEach(obj => {
							let cadena = obj.fecha.split("-");
							obj.fecha = moment(new Date(cadena[0], parseInt(cadena[1]) - 1, cadena[2])).format('YYYY-MM-DD');
							date.push(obj);
						});
					});
					disponibilidadServer = date;
					success(disponibilidadServer);
				} else {
					success([]);
				}
			});
		})
	}


	/**
	 * Funcion que crea el array de la semana de cada server y le asigna la disponibilidad segun los servicios
	 * @param servicios array con los servicios de la server
	 */
	public horariosSemana(servicios, disponibilidad) {
		let semanaCompleta = []
		let fecha = moment(this.comienzo).subtract(1, 'd').format('YYYY-MM-DD');
		for (let index = 0; index < 7; index++) {
			fecha = moment(fecha).add(1, 'd').format('YYYY-MM-DD');
			let diaServicios = {
				m: '',
				t: '',
				n: '',
				mEstado: '',
				tEstado: '',
				nEstado: '',
				totalServicios: 0,
				fecha: fecha,
			}
			let disponibilidadDia = disponibilidad.filter(servicio => servicio.fecha == fecha);
			if (servicios.length > 0 && disponibilidadDia.length == 0) {
				let serviciosDia = servicios.filter(servicio => servicio.fecha == fecha);
				if (serviciosDia.length > 0) {
					serviciosDia.forEach(element => {
						let tipo = '';
						if (!element.planRecurrente) {
							tipo = element.tipoServicio == "Plan Mensual" ? 'P' : 'X';
						} else if (element.planRecurrente) {
							tipo = 'R';
						}
						let estado = element.estado == "Terminado" ? 'verde' : 'naranja';
						let tiempo = Number(element.hora.split(':')[0]);
						let servicioHoras = Number(element.horasDeServicio) + Number(tiempo);
						if (tiempo <= 12 || servicioHoras <= 12) {
							if (diaServicios.m != 'R' && tipo != 'R') {
								diaServicios.m = diaServicios.m == 'P' ? 'P' : tipo;
							} else {
								diaServicios.m = 'R';
							}
							diaServicios.mEstado = diaServicios.mEstado == 'naranja' ? 'naranja' : estado;
						}
						if ((tiempo > 12 && tiempo <= 18) || (servicioHoras > 12 && tiempo <= 18)) {
							if (diaServicios.t != 'R' && tipo != 'R') {
								diaServicios.t = diaServicios.t == 'P' ? 'P' : tipo;
							} else {
								diaServicios.t = 'R';
							}
							diaServicios.tEstado = diaServicios.tEstado == 'naranja' ? 'naranja' : estado;
						}
						if ((tiempo > 18 && tiempo <= 24) || (servicioHoras > 18 && tiempo <= 24)) {
							if (diaServicios.n != 'R' && tipo != 'R') {
								diaServicios.n = diaServicios.n == 'P' ? 'P' : tipo;
							} else {
								diaServicios.n = 'R';
							}
							diaServicios.nEstado = diaServicios.nEstado == 'naranja' ? 'naranja' : estado;
						}
					});
				}
			} else if (disponibilidadDia.length > 0) {
				diaServicios = {
					m: 'B',
					t: 'B',
					n: 'B',
					mEstado: 'rojo',
					tEstado: 'rojo',
					nEstado: 'rojo',
					totalServicios: 0,
					fecha: fecha,
				}
			}
			semanaCompleta.push(diaServicios)
		}
		return (semanaCompleta);
	}

}

