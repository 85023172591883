import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventIncrement]'
})
export class PreventIncrementDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key =="ArrowUp" || event.key =="ArrowDown") {
      event.preventDefault();
    }
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
      event.preventDefault();
    }
  }
  constructor() { }

}
