import { Component, OnInit,ViewChild, ElementRef, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UsuarioService } from '../../services/usuario.service';
import { environment } from "../../../environments/environment";
import Swal from 'sweetalert2';
import { User } from 'src/app/models/User.model';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment'
import{PatchNotesServices} from '../../services/patchNotes.services'
import * as _ from 'lodash'; 
//import { Process } from "../../typings";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  @ViewChild('mdlCrearParche', { static: true }) mdlCrearParche: ElementRef;
  currentDate = moment().format('YYYY-MM-DD')
  arrayPatchNotes = [];
  public user: any;
  public usuario: any;
  public loading: boolean = false;
  // password: string = '';
  showPassword: boolean = false;

  
  frmLogin: FormGroup;
  submitted = false;
  viewpatch: any;

  constructor(public fb: FormBuilder,
    private toastrService: NbToastrService,
    public router: Router,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private dialogService: NbDialogService,
    private patchNotesServices: PatchNotesServices,
  ) {
    this.frmLogin = this.fb.group({
      usuario: ['', [Validators.required]],
      clave: ['', [Validators.required, Validators.minLength(6)]],
    });  
  }  
  
  get valoresLogin() { return this.frmLogin.controls; }
    
  ngOnInit() {
    //console.log({API_KEY: $ENV.API_KEY});    
    //console.log({AUTH_DOMAIN: $ENV.AUTH_DOMAIN});    
    //console.log({DB_URL: $ENV.DB_URL});    
    //console.log({PROJECT_ID: $ENV.PROJECT_ID});    
    //console.log({BUCKET: $ENV.BUCKET});    
    //console.log({SENDER_ID: $ENV.SENDER_ID});    
    this.getPatchNotes();
  }   
  getPatchNotes(){
    this.patchNotesServices.getAll().then(res=>{
      res.docs.forEach(data=>{
        this.arrayPatchNotes.push(data.data())
        console.log(this.arrayPatchNotes)
        this.arrayPatchNotes = _.orderBy(this.arrayPatchNotes, ["fecha"], ["desc"]);
      })
    })
  }
  
  openModal(patch){
    this.viewpatch = patch
    // @ts-ignore
    this.dialogService.open(this.mdlParche);
  }
  abrirmdlCrearParche(){
    // @ts-ignore
    this.dialogService.open(this.mdlCrearParche);
  }

  //Inicio de sesión
  OnIniciarSesion() {
    this.submitted = true;
    const login = this.frmLogin.value;
    if (this.frmLogin.invalid) { return false; }
    this.loadTime();
    
    this.authService.loginEmailUser(login.usuario, login.clave)
      .then((data) => {
        const userFirebase = {} = data.user;
        console.log(userFirebase);
        if (userFirebase) {
          this.authService.getUsuarioAdminId(userFirebase.uid).then((res) => {
            console.log("este es el usuario que entra", res)
            res.docs.forEach(usr=>{
              const usuario = usr.data()
              usuario.displayName = `${usuario.name} ${usuario.apellido}`
              console.log(usuario);
              if(usuario.rol ==  "administrador" || usuario.rol == "contabilidad" || usuario.rol == "logistica" || usuario.rol == "marketing" || usuario.rol == "recursos" || usuario.rol == "tecnologia" || usuario.rol == "ventas" || usuario.rol == "cuentas"){
                this.authService.storeUserData(data, usuario);
                // this.showToast('top-right', 'success', 'Éxito!', 'Se verifico con éxito');
                this.onLoginRedirect();
              }else if (usuario.rol == "retos4.0"){
                this.authService.storeUserData(data, usuario);
                // this.showToast('top-right', 'success', 'Éxito!', 'Se verifico con éxito');
                this.router.navigate(['dashboard/observadorServers']);
              }else{
                console.log("Rol del usuario que intentio entrar",usuario.rol)
                setTimeout( ()=> { Swal.fire('Oops...', 'por favor verifique su correo electrónico y contraseña', 'error') }, 900 )
                this.authService.logoutUser();
              }
              // if (usuario.rol == "administrador") {
              //   const sesion = {
              //     photoURL: usuario.foto ? usuario.foto : '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol : 'administrador',
              //     privilege: 'null',
              //     id: usuario.id
              //   }
   
                
              // } else if(usuario.rol == "contabilidad"){
              //   const sesion ={
              //     photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol: 'contabilidad',
              //     privilege: 'null',
              //     id: usuario.id
              //   }
  
              //   this.authService.storeUserData(data, sesion);
              //   this.showToast('top-right', 'success', 'Éxito!', 'se verificó con éxito');
              //   this.onLoginRedirect();
              // } else if(usuario.rol == "logistica"){
              //   const sesion ={
              //     photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol: 'logistica',
              //     privilege: 'null',
              //     id: usuario.id
              //   }
  
              //   this.authService.storeUserData(data,sesion);
              //   this.showToast('top-right', 'success', 'Éxito!', 'se verificó con éxito');
              //   this.onLoginRedirect();
              // } else if(usuario.rol == "marketing"){
              //   const sesion ={
              //     photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol: 'marketing',
              //     privilege: 'null',
              //     id: usuario.id
              //   } 
              //   this.authService.storeUserData(data,sesion);
              //   this.showToast('top-right','success','Éxito', 'se verificó con éxito');
              //   this.onLoginRedirect();
              // } else if(usuario.rol == "recursos"){
              //   const sesion ={
              //    photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //    displayName: (`${usuario.name} ${usuario.apellido}`),
              //    idLocation: usuario.idLocation,
              //    rol: usuario.rol ? usuario.rol: 'recursos',
              //    privilege: 'null',
              //    id: usuario.id
              //   }
              //   this.authService.storeUserData(data,sesion);
              //   this.showToast('top-right','success','Éxito', 'se verificó con éxito');
              //   this.onLoginRedirect();
  
              // } else if(usuario.rol == "tecnologia"){
              //   const sesion={
              //     photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol: 'tecnologia',
              //     privilege: 'null',
              //     id: usuario.id
              //   }
              //   this.authService.storeUserData(data, sesion);
              //   this.showToast('top-right','success','Éxito','se verificó con éxito');
              //   this.onLoginRedirect();
  
              // } else if(usuario.rol == "ventas"){
              //   const sesion={
              //     photoURL: usuario.foto ? usuario.foto: '/assets/img/userDefault.png',
              //     displayName: (`${usuario.name} ${usuario.apellido}`),
              //     idLocation: usuario.idLocation,
              //     rol: usuario.rol ? usuario.rol: 'ventas',
              //     privilege: 'null',
              //     id: usuario.id
              //   }
              //   this.authService.storeUserData(data, sesion);
              //   this.showToast('top-right','success','Éxito','se verificó con éxito');
              //   this.onLoginRedirect();
  
              // } 
            })
          })
        } else {
          this.authService.logoutUser();
          setTimeout( ()=> { Swal.fire('Oops...', 'por favor verifique su correo electrónico y contraseña', 'error') }, 900 )
        }
      }).catch(error => setTimeout( ()=> { Swal.fire('Oops...', error, 'error') }, 900 ));
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show( mensaje, titulo, { position, status });
  }

  //Redirecciona al home
  onLoginRedirect(): void {
    this.router.navigate(['dashboard/usuarios']);
  }

  loadTime() {
    this.loading = !this.loading;
    //console.log({ start: this.loading });
    (typeof this.loading) == 'boolean' ? setTimeout(
      () => {
        this.loading = !this.loading;
       //console.log({ load: this.loading })
      },
      2000
    ) : (this.loading = false);
  }
  

  onSendPasswordResetEmail(){

    Swal.fire({
      input: 'email',
      inputPlaceholder:'Correo',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
    }). then(async res=>{
      if(res.value){
        try{
          await this.authService.sendPasswordResetEmail(res.value);
          Swal.fire('Listo','El correo se a enviado, ingresa para cambiar tu contraseña','success')
        }
        catch(error){
          console.log(error)
          if(error.code == "auth/user-not-found"){
            Swal.fire('Error',`El correo que ingresaste no se encuentra registrado`,'error')
          }else{
            Swal.fire('Error',`Ocurrio un error ${error}`,'error')
          }
        }
      }
    })

  }


  
  prueba(){
    const credentials ={
      email: 'yeimisgarcia42@gmil.com',
      password: '40993042'
    }
    const newCredentials ={
      email: 'yeimisgarcia42@gmail.com',
      password: '40993042'
    }
    this.authService.changeEmailUser(credentials,newCredentials)
  }

}
