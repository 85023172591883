import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss']
})
export class EditableFieldComponent {

  @Input() value: string;
  @Input() label: string;
  @Input() fieldType = 'text';
  @Output() valueChange = new EventEmitter<string>();
  isEditing = false;
  tempValue: string;

  constructor() {
    this.tempValue = this.value;
  }

  ngOnChanges() {
    this.tempValue = this.value;
  }

  edit() {
    this.tempValue = this.value; 
    this.isEditing = true;
  }

  save() {
    this.value = this.tempValue;
    // this.valueChange.emit(this.value);
    this.isEditing = false;
  }

  onValueChange() {
    console.log("cambio",this.tempValue);
    this.value = this.tempValue;
    this.valueChange.emit(this.value);
  }

  cancel() {
    this.isEditing = false;
  }
}
