import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class AspirantesServService {

  constructor() { }

  get(){
    return firebase.firestore().collection('aspirantesServ').get();
  }
}
