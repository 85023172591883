
import { Directive, HostListener, ElementRef } from '@angular/core';
@Directive({
  selector: '[autoGrow]'
})
export class AutoGrowTextZoneDirective {
  
  constructor(private el: ElementRef) { }
  @HostListener('input', ['$event'])
  onKeyUp(event: Event): void {
    const target = event.target as HTMLTextAreaElement;
    this.autoGrowTextZone(target);
  }

  autoGrowTextZone(textArea: HTMLTextAreaElement): void {
    textArea.style.height = "0px";
    textArea.style.height = textArea.scrollHeight + 5 + "px";
  }

}
