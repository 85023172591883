import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadsService } from 'src/app/services/leads.service';

import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
@Component({
  selector: 'app-leads-ca',
  templateUrl: './leads-ca.component.html',
  styleUrls: ['./leads-ca.component.scss']
})
export class LeadsCaComponent implements OnInit {
  tableDataSource: any;
  @ViewChild('paginator' , { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  tableDisplayedColumns = ['fechaDeCreacion','nombre','telefono','correo','servicio','como','ciudad'];
  
  constructor(
    private leadsService: LeadsService,
  ){}

  ngOnInit() {
    this.onCargar();
  }

  public async onCargar() {
    let data = [];
    const query = await this.leadsService.getLeadsCa()
    query.docs.forEach(res=>{
      data.push(res.data());
    })
    this.tableDataSource = new MatTableDataSource(data)
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    console.log(this.tableDataSource)
  }

}
