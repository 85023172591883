import { Component, OnInit } from "@angular/core";
/*_________SERVICIOS__________ */
import * as firebase from "firebase";
import { AuthService } from "src/app/services/auth.service";
import { ServicioService } from "src/app/services/servicio.service";
/*__________UTILIDADES_________*/
import * as _ from "lodash";
import { Subject } from "rxjs";
import * as moment from "moment";
// import * as firebase2 from "firebase";
import { IMyDateRangeModel } from "mydaterangepicker";
import { ListColorService } from "src/app/utility/dataInfo";
import { OptionsColor } from "src/app/utility/OptionsColor";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CalendarView } from "angular-calendar";
import { Observable } from "rxjs";
import {
	AngularFireStorageReference,
	AngularFireUploadTask,
} from "@angular/fire/storage";
/*******************************/

@Component({
	selector: "app-exportar-metricas",
	templateUrl: "./exportar-metricas.component.html",
	styleUrls: ["./exportar-metricas.component.scss"],
})
export class ExportarMetricasComponent implements OnInit {
	// Sesion storage
	public currentUser: any = {};
	public dateRange: any = {};
	public kpisComercial = {
		"Servicios Agendados": 0,
		"Servicios Terminados": 0,
		"Total Vendido": 0,
		"Total Vendido Terminado": 0,
		"Ticket Promedio": 0,
		"Plan Individual": 0,
		"Plan Mensual": 0,
		Administrativo: 0,
		Terminado: 0,
		"Orden de servicio": 0,
		Pendiente: 0,
		Aceptado: 0,
		Confirmado: 0,
		"En camino": 0,
		"En curso": 0,
		Congelado: 0,
		Cancelado: 0,
		Aplicacion: 0,
		Administrador: 0,
		Cundinamarca: 0,
		"Bogotá D.C.": 0,
		Antioquia: 0,
		"Norte de Santander Department": 0,
	};

	// Basura
	database = firebase.firestore();

	/*  Para el modal */
	public listColorService: Array<OptionsColor> = ListColorService;
	ref: AngularFireStorageReference;
	task: AngularFireUploadTask;
	uploadProgress: Observable<number>;
	downloadURL: Observable<string>;

	lugarBusqueda: string = "Todos";

	public arrayServicios: any = [];
	public arrayServiciosOrdenes: any = [];
	public arrayServiciosAceptados: any = [];
	public arrayServiciosEnProceso: any = [];
	public arrayServiciosPendientes: any = [];
	public arrayServiciosTerminados: any = [];
	public arrayServiciosCancelados: any = [];
	public arrayServiciosConfirmados: any = [];

	public listaServers: any;
	public listaClientes: any;

	view: CalendarView = CalendarView.Month;
	CalendarView = CalendarView;
	viewDate: Date = new Date();

	public calendarView = CalendarView;
	public refresh: Subject<any> = new Subject();

	public placePredictions: any;
	public nuevaDireccion = {
		country: "Colombia",
		nombreDireccion: "Mi casa",
		department: "",
		cities: "",
		neighborhood: "",
		direccion: "",
		observaciones: "",
		lat: 0,
		lng: 0,
		tipoPropiedad: "",
		idLocation: "",
	};

	/*-------variables que se guardan en el servicio--------*/
	public horasDeServicio: string = "";
	public serverServicio: any;

	/*__________ VARIABLES DE PLAN ___________*/
	public numeroServiciosPlan: number = 0;
	public frecuenciaPlan: string = "";
	public diasSeleccionados: any = [];
	public arrayPreInfoServiciosPlan: any = [];
	public flippedPlanes: boolean = false;
	public precioPlan: number = 0;
	public recargoPlan: number = 0;
	public recargoTransportePlan: number = 0;
	public descuentoPlan: number = 0;
	public precioPorServicio: number = 0;

	public contadorServersDisponibles = 0;
	public loadingTablaPlan: boolean = false;
	public loaderAgendandoPlan: boolean = false;
	public contadorFechasServiciosPlanVerificadas = 0;
	public fechaPostVenta: string = "";
	public loaderAgendarPlan: boolean = false;
	/**********************************************************************/
	dropdownSettings: IDropdownSettings = {};

	allServices = [];
	generalLoader: boolean = false;

	constructor(
		private authService: AuthService,
		private servicioService: ServicioService
	) {
		this.currentUser = this.authService.storeGetUserData("usuario");
	}

	ngOnInit() { }

	public onGetDateRange(event: IMyDateRangeModel) {
		this.dateRange.startDate = moment(event.beginJsDate).format("YYYY-MM-DD");
		this.dateRange.endDate = moment(event.endJsDate).format("YYYY-MM-DD");
	}

	public onExportServices() {
		this.generalLoader = true;
		this.servicioService
			.getInDateRange(
				this.dateRange.startDate,
				this.dateRange.endDate
			)
			.then(async (res) => {
				console.log(res.docs.length);
				let promises = res.docs.map((data) => this.formatDataReport(data));
				let responseServices = await Promise.all(promises);
				console.log(responseServices);
				console.log(this.kpisComercial);

				this.servicioService.exportAsExcelReportComercial({
					fileName: `Reporte Comercial ${this.dateRange.startDate} - ${this.dateRange.endDate}`,
					firstSheet: responseServices,
					firstSheetName: `Servicios`,
					secondSheet: [this.kpisComercial],
					secondSheetName: `Metricas`,
				})
				this.generalLoader = false;
			});
	}

	public async formatDataReport(service) {
		let formatService = {
			ID:
				service.data().client !== undefined
					? service.data().client.id
					: "No se encontro cliente",
			TIPO: service.data().tipoServicio,
			ESTADO: service.data().estado,
			CLIENTE:
				service.data().nombreCliente !== undefined
					? service.data().nombreCliente
					: "Sin nombre",
			"FECHA DEL SERVICIO": service.data().fecha,
			"HORA INICIO": service.data().hora,
			// TODO revisar que putas esto
			// "HORAS DE SERVICIO": isNaN(service.data().horasDeServicio)
			// 	? 6
			// 	: service.data().horasDeServicio,
			"HORAS DE SERVICIO": isNaN(service.data().horasDeServicio)
				? "No tiene horas asociadas"
				: service.data().horasDeServicio,
			// TODO revisar que putas esto
			// TOTAL: isNaN(service.data().total) ? 86600 : service.data().total,
			TOTAL: service.data().total,
			"CATEGORIA SERVICIO": service.data().categoria,
			"SUBCATEGORIA SERVICIO": service.data().subCategoria,
			PLATAFORMA: service.data().creado,
			"FECHA DE CREACION": service.data().fechaCreacion,
			DEPARTAMENTO:
				typeof service.data() !== "undefined" &&
					typeof service.data().destination !== "undefined" &&
					typeof service.data().destination.department !== "undefined"
					? service.data().destination.department
					: "No asociado",
		};
		const precio_terminado = await this.filterFinishServices(formatService);
		this.kpisComercial["Servicios Agendados"] =
			this.kpisComercial["Servicios Agendados"] + 1;
		this.kpisComercial["Servicios Terminados"] = 
			precio_terminado > 0 ? this.kpisComercial["Servicios Terminados"] + 1 : this.kpisComercial["Servicios Terminados"] ; 

		this.kpisComercial["Total Vendido"] =
			this.kpisComercial["Total Vendido"] + formatService.TOTAL;
		this.kpisComercial["Total Vendido Terminado"] =
			this.kpisComercial["Total Vendido Terminado"] + precio_terminado
			
		this.kpisComercial["Ticket Promedio"] =
			this.kpisComercial["Total Vendido Terminado"] / this.kpisComercial["Servicios Terminados"]
		this.kpisComercial[formatService.TIPO] =
			this.kpisComercial[formatService.TIPO] + 1;
		this.kpisComercial[formatService.ESTADO] =
			this.kpisComercial[formatService.ESTADO] + 1;
		this.kpisComercial[this.selectPlatform(formatService.PLATAFORMA)] =
			this.kpisComercial[this.selectPlatform(formatService.PLATAFORMA)] + 1;
		this.kpisComercial[formatService.DEPARTAMENTO] = this.kpisComercial[ formatService.DEPARTAMENTO]
			? this.kpisComercial[formatService.DEPARTAMENTO] + 1
			: 0;

		return formatService;
	}

	public selectPlatform(platform) {
		switch (platform) {
			case "app":
				return "Aplicacion";
			case "admin":
				return "Administrador";
		}
	}

	filterFinishServices = (service) =>
		service.ESTADO == "Terminado" && service.TIPO != "Administrativo"
			? service.TOTAL
			: 0;
}
