import * as _ from "lodash";
import * as firebase from 'firebase';
import { Subject } from "rxjs";
import * as moment from "moment";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import Swal, { SweetAlertType } from "sweetalert2";
import { IMyDateRangeModel } from "mydaterangepicker";
import { preciosServicios } from "src/app/utility/precios";
import { AuthService } from "src/app/services/auth.service";
import { PlanService } from "src/app/services/plan.service";
import { Metricas } from "src/app/services/metricas.service";
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { serviciosPrestados } from "src/app/utility/SERVicios";
import { ServerService } from "src/app/services/server.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";
import { festivosColombia } from "src/app/utility/festivosColombia";
import { posventaService } from "src/app/services/posventa.service";
import { funcionesUsuario } from "src/app/services/funcionesUsuario";
import { Component, OnInit, ViewChild, ElementRef, Query } from "@angular/core";
import { PagosServerService } from 'src/app/services/pagos-server.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { Helper } from "src/app/utility/helper";

import { PlanRecurrenteService } from "src/app/services/plan-recurrente.service";
import {
  CalendarView,
  CalendarMonthViewDay,
} from "angular-calendar";
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { filtrosTablaPlan } from "../../utility/filtros";
import { PriceService } from "src/app/services/prices.service";
import { ServBackendService } from "src/app/services/serv-backend.service";
import { CodigoReferidoServerService } from "src/app/services/codigo-referido-server.service";

@Component({
  selector: "app-listar-plan",
  templateUrl: "./listar-plan.component.html",
  styleUrls: ["./listar-plan.component.scss"],
})
export class ListarPlanComponent implements OnInit {
  public arrayFestivos = festivosColombia;
  public filtrosTablaPlan = filtrosTablaPlan;
  public categoriaFiltro: any;

  public myDateRangePickerOptions;

  public flippedCardVerPlan = false;
  public all = false;
  public dataTodos: any = [];
  public dataActivos: any = [];
  public dataPorVencer: any = [];
  public dataOrdenPlanes: any = [];
  public dataDesactivados: any = [];
  public dataSuspendidos: any = [];
  public dataCongelados: any = [];
  public preInfoTodos: any = [];
  public preInfoActivos: any = [];
  public preInfoPorVencer: any = [];
  public preInfoOrdenPlanes: any = [];
  public preInfoDesactivados: any = [];
  public preInfoCongelados: any = [];
  public preInfoSuspendido: any = [];

  public servicioExport: Array<Object> = [];

  public planRecurrente: any = {};
  public planVisualizado: any = {
    id: ''
  };
  public horasDeInicio = [
    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  public horasDeServicio = [2, 3, 4, 6, 8]
  
  public keyServersAutocomplete: string = "nombreServer";
  public tipoDeAsignacion: string = "Selecciona";
  public fechaInicioMultipleActualizacion = 'Actualizar hora';

  public fechaCongelacion: any;
  estadoPlan = '';
  motivoCancelacion = '';
  motivoCancelacionOtro = '';
  editMotivoCancelacion: boolean = false;
  /*  Para el  */
  @ViewChild("mdlVerPlan", { static: true }) mdlVerPlan: ElementRef;
  @ViewChild("mdlReagendar", { static: true }) mdlReagendar: ElementRef;

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: "deletePlan", title: '<i class="nb-trash"></i>' },
        { name: "openPlan", title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      usuario: { title: "Usuario" },
      serverName: { title: "Server" },

      ciudad: { title: "Ciudad",
        valuePrepareFunction: (value: any, row: any) => {
          return row.destination.cities;
        },
      },
      departamento: { title: "Departamento",
        valuePrepareFunction:(value: any, row: any) => {
          return row.destination.department
          // return `${value.department}`;
        },
      },
      cantidadServicios: { title: "Cantidad de servicios" },
      estado: { title: "Estado" },
      estadoPago: { title: "Estado Pago" },
      fechaInicio: {  title: "Fecha inicio" },
      fechaVencimiento: {  title: "Fecha de vencimiento" },
      fechaCreacion: {  title: "Fecha creacion" },
      diasDeServicioString: {title: "Dias" },
      creado: { title: "creado" },
    },
  };
  public fechaAlarmaA = moment().subtract(6, "days").format("YYYY-MM-DD");//6 o mas dias desde la fecha de vencimiento Dias en rojo
  public fechaAlarmaM = moment().subtract(4, "days").format("YYYY-MM-DD");//4 o 5 dias desde la fecha de vencimiento  en naranja
  public fechaAlarmaB = moment().format("YYYY-MM-DD");                    //1 a 3 dias desde la fecha de vencimiento en amarillo
  
  settingsPorReagendar = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        // { name: "deletePlan", title: '<i class="nb-trash"></i>' },
        { name: "openPlan", title: '<i class="nb-search"></i>' },
        { name: "reschedule", title: '<i class="nb-arrow-retweet"></i>' },
      ],
    },
    columns: {
      usuario: { title: "Usuario" },
      serverName: { title: "Server" },
      ciudad: { title: "Ciudad",
        valuePrepareFunction: (value: any, row: any) => {
          return row.destination.cities;
        },
      },
      cantidadServicios: { title: "Cantidad de servicios" },
      estado: { title: "Estado" },
      estadoPago: { title: "Estado Pago" },
      numeroFactura: { width: "10%", title: "Numero de factura" },
      fechaInicio: {  title: "Fecha inicio" },
      fechaVencimiento: {  title: "Fecha de vencimiento" },
      fechaCreacion: {  title: "Fecha creacion" },
      diasDeServicioString: {title: "Dias" },
      creado: { title: "creado" },
    },

    rowClassFunction: (row) => {
      if (row.data.estado == "Por vencer") {
        return "colorVerde";
      } else if (row.data.estado == "Completado") {
        return "colorRojo";
      } else if (row.data.fechaVencimiento <= this.fechaAlarmaA) {
        return "colorRojo";
      } else if (row.data.fechaVencimiento <= this.fechaAlarmaM) {
        return "colorNaranja  ";
      } else if (row.data.fechaVencimiento <= this.fechaAlarmaB) {
        return "colorAmarillo";
      } else {
        return "";
      }
    },
  };

  settingsServiciosPlan = {
    //hideSubHeader: true,
    selectMode: "multi",
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      // custom: [{ name: "editar", title: '<i class="nb-compose"></i>' }],
    },
    columns: {
      server: {
        title: "Server",
        valuePrepareFunction: (value) => {
          return `${value.name} ${value.apellido}`;
        },
      },
      fecha: { title: "Fecha del servicio" },
      estado: { title: "Estado" },
      horasDeServicio: { title: "Duracion del servicio" },
      hora: { title: "Hora de inicio" },
    },
    pager: {
      perPage: 10,
    },
  };

  settingsPagosUsuario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: "verComprobante", title: '<i class="nb-search"></i>' },
        { name: "verCobro", title: '<i class="nb-compose"></i>' },
      ],
    },
    columns: {
      comprobante: {
        title: "Comprobante",
        valuePrepareFunction: (comprobante) => {
          if (comprobante == "falta" || comprobante == "Falta" || comprobante == "") {
            return "Falta";
          } else {
            return `Subido`;
          }
        },
      },

      numeroServicios: { title: "Total servicio" },
      fechaInicio: { title: "Fecha inicial" },
      fechaFin: { title: "Fecha final" },
      estadoDelPago: { title: "Estado del pago" },
      factura: { title: "Factura" },
      fechaAgendamiento: { title: "Fecha de creacion" },
      precio: { title: "Precio" },
      recargo: { title: "Recargo" },
      descuento: { title: "Descuento" },
      total: { title: "Total" },
    },
    pager: {
      perPage: 15,
    },
  };

  public loaderAsignarServer = false;
  public usuarioEnSecion: any;
  public comprobanteAvisualizar: string = "";
  public historialDeCobros: any = [];
  public cobroVisualizado: any = null;
  public copiaCobroVisualizado: any = {};
  public razonCambioCobro = ""
  tipoFiltro: any;
  valorFiltro: any;

  public cambiarEstadoPlanVisualizado = false;
  public nuevaFechaCongelacion = false;

  /*Multi select serviceio*/
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};
  serviciosSelecccionados = [];
  clienteDelPlanVisualizado: any;
  editarDireccion: boolean;
  statesList = ['Activo','Por vencer','Completado','Congelado','Orden de plan','Cancelado','Suspendido'];
  statesForQuery: string[] = ['Activo','Por vencer','Completado','Congelado','Orden de plan'];

  /* Decuento */
  tipoDeDescuento: string = "porcentual";
  usarCodigoReferido = false;
  codigoReferido = '';
  idCodigoReferido = '';

  /* Productos */
  totalValueExtras = 0;
  productList = [];
  activeAddNewProduct: boolean = false;
  newProduct = {
    name: '',
    value: 0,
  };
    
  aceptaRemplazos = 'no';
  constructor(
    public router: Router,
    public datePipe: DatePipe,
    public authService: AuthService,
    public PlanService: PlanService,
    private helper: Helper,
    private metricasService: Metricas,
    public ServerService: ServerService,
    private priceService: PriceService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public usuarioService: UsuarioService,
    public ServicioService: ServicioService,
    public posventaService: posventaService,
    private funcionesUsuario: funcionesUsuario,
    private pagosServerService: PagosServerService,
    public planRecurrenteService: PlanRecurrenteService,
    public servBackendService: ServBackendService,
    public codigoReferidoServerService: CodigoReferidoServerService,
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");

    console.log(this.fechaAlarmaA, this.fechaAlarmaM, this.fechaAlarmaB);
  }

  ngOnInit() {
    let estados: any = { activo: [] };
    console.log(!estados.activo)
    this.dropdownList = [
      { item_id: 1, item_text: 'Empleada doméstica' },
      { item_id: 2, item_text: 'Planchado de ropa' },
      { item_id: 3, item_text: 'Cuidado de niños' },
      { item_id: 4, item_text: 'Cuidado de adultos mayores' },
      { item_id: 5, item_text: 'Cuidado de mascotas' }
    ];
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
    };
  }

  public servicioEnEdicion: any;
  // public listaServers: any;
  public listaServersConRazonBloqueo = [];
  public serversConDiasDeTrabajo = [];
  public serversAsignables = [];
  public copiaServersAsignables = [];
  public activarEligeServer = false;

  cambiarDireccionPlan() {
    this.usuarioService.detail(this.planVisualizado.userid).then(res => {
      let cliente = res.data();
      this.planVisualizado.destination = cliente.direccionesArray[0]
      console.log(cliente);
      this.ServicioService.updatePlan(this.planVisualizado);
      this.serviciosPlanVisualizado.forEach(element => {
        if (element.estado == 'Aceptado' || element.estado == 'Confirmado') {
          element.destination = cliente.direccionesArray[0];
          element.direccion = cliente.direccionesArray[0].direccion;
        }
        this.ServicioService.updateServicio(element).then((res: any) => {
          console.log("servicio actualizado");
        });
      });
    })
  }

  mostrarTodos() {
    this.all = true;
    this.getPlanes(null, null)
  }

  public async getPlanes(startDate, endDate) {
    this.loadingTablaPlan = true;
    this.preInfoDesactivados = [];
    this.preInfoOrdenPlanes = [];
    this.preInfoActivos = [];
    this.preInfoPorVencer = [];
    this.preInfoTodos = [];
    this.preInfoCongelados = [];
    this.preInfoSuspendido = [];
    if (this.all == false) {
      this.servBackendService.post('api/serv/get_all_planes_by_fechaCreacion',{startDate, endDate} ).subscribe(res => {
        console.log(res)
        if (res.success) {
          this.asignarTabla(res.data);
        }
      })
    } else if (this.all == true) {
      console.log(this.statesForQuery); 
      let statesForQuery = this.statesForQuery;
      this.servBackendService.post('api/serv/get_planes_by_state',{ statesForQuery } ).subscribe(res => {
        console.log(res)
        if (res.success) {
          this.asignarTabla(res.data);
        }
      })
    }
  }

  asignarTabla(info){
    this.dataTodos = _.orderBy(info.todos, ["fechaCreacion"], ["desc"]);
    this.dataActivos = _.orderBy(info.activo, ["fechaVencimiento"], ["desc"]);
    this.dataPorVencer = _.orderBy(info.porVencer, ["fechaVencimiento"], ["asc"]);
    this.dataCongelados = _.orderBy(info.congelados, ["fechaCreacion"], ["desc"]);
    this.dataSuspendidos = _.orderBy(info.suspendido, ["fechaCreacion"], ["desc"]);
    this.dataOrdenPlanes = _.orderBy(info.ordenPlanes, ["fechaCreacion"], ["desc"]);
    this.dataDesactivados = _.orderBy(info.desactivados, ["fechaCreacion"], ["desc"]);
    this.tableDataSource = new MatTableDataSource(this.dataTodos)
    this.tableDataSource.paginator = this.paginator
    this.tableDataSource.sort = this.sort;
    this.loadingTablaPlan = false;
    console.log(info.export);
    this.servicioExport = info.export;
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    let startDate;
    let endDate;
    const beginDateFormat = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDateFormat = moment(event.endJsDate).format("YYYY-MM-DD");
    console.log(beginDateFormat + endDateFormat);
    startDate = beginDateFormat;
    endDate = endDateFormat;
    this.all = false;
    this.getPlanes(startDate, endDate);
  }

  onActionPlanesTable(event){
    switch (event.action) {
      case 'openPlan': 
        this.openPlan(event.data)
        break;
      case 'reschedule':{
        this.openModalReagendar(event.data);
        break;
      }
      case 'deletePlan': {
        this.deletePlan(event.data);
        break;
      }
    }
  }

  onCustom(event) {

    if (event.action == "verComprobante") {
      this.comprobanteAvisualizar = event.data.comprobante;
      setTimeout(() => {
        this.verComprobante();
      }, 1000);
    }
    else if (event.action == "verCobro") {
      this.copiaCobroVisualizado = {}
      this.razonCambioCobro = "";
      this.cobroVisualizado = event.data;
      this.copiaCobroVisualizado = {
        precio: event.data.precio,
        recargo: event.data.recargo,
        descuento: event.data.descuento,
      }
      console.log(this.cobroVisualizado);
    }
  }

  public async openPlan(event) {
    console.log(event)
    this.loadingTablaPlan = true;
    this.planVisualizado = event;
    await this.traerServiciosPlan();
    this.tipoAgendamiento = "";
    this.multiplesServicios = false;
    this.asignarPostVenta = false;
    this.planAceptadoPreviamente = false;
    this.fechaPostVenta = "";
    this.planEnReagendamiento = "no";
    this.tipoDeAsignacion = "Selecciona";
    this.serversAsignables = [];
    this.copiaServersAsignables = [];
    this.listaServersConRazonBloqueo = [];
    this.filteredOptions = [];
    this.estadoPlan = this.planVisualizado.estado;
    this.horaInicioPlanReg =  this.planVisualizado.horaDeInicio;
    this.cambiarEstadoPlanVisualizado = false;
    this.motivoCancelacionOtro = '';
    await this.setVariablesParaVisualizacion(event);
    await this.traerPlanRecurrente(event);
  
    this.loadingTablaPlan = false;
    // @ts-ignore
    this.dialogService.open(this.mdlVerPlan, {
      context: "this is some additional data passed to dialog",
    });
  }

  openModalReagendar(plan) {
    if (plan.estadoPago == "Pago") {
      this.prepararMdlReagendar(plan);
    } else {
      Swal.fire(
        "Espera",
        "Antes de reagendar el plan previo debe estar pago,si ya lo esta sube el comprobante"
      );
    }
  }

  setVariablesParaVisualizacion(info) {
    this.diasSelccionadoPlan = [];
    this.copiaDiasSelccionadoPlan = [];
    this.arrayPreInfoServiciosPlan = [];
    this.botonActualizarServicio = false;
    this.listaServersConRazonBloqueo = [];
    this.horasDeServicioReg = this.planVisualizado.horasDeServicio
    this.horaInicioPlanReg = this.planVisualizado.horaDeInicio
    info.diasDeServicio.forEach(data => {
      this.seleccionariDiasPlan(data.dia, 'paraVisualizar')
    })
    this.usuarioService.detail(this.planVisualizado.userid).then(res => {
      this.clienteDelPlanVisualizado = res.data();
      this.clientTaskList = this.clienteDelPlanVisualizado.taskList;
    })
  }

  async traerPlanRecurrente(info) {
    console.log('Entor en traerPlanRecurrente()')
    if (this.planVisualizado.estado != 'Congelado' && this.planVisualizado.estado != "Cancelado") {

      const querry = await this.planRecurrenteService.getPlanRecurrenteId(this.planVisualizado.id)
      if (querry.exists) this.planRecurrente = querry.data();
      else this.crearPlanRecurrente(info)
    }
    this.getCobros(info);
  }

  public getCobros(plan) {
    console.log("Entor en getCobros()", plan.id)
    let cobros = []
    this.historialDeCobros = [];
    this.ServicioService.getPagosUsuarioByIdPlanOServicio(plan.id).then((res) => {
      res.docs.forEach((data) => {
        let cobro = data.data();
        cobros.push(cobro);
        // let cobrosPlan = cobros.cobrosRealizados.filter(
        //   (cobro) => cobro.idPlanOServicio == plan.id
        // );
        this.historialDeCobros = cobros;
      });
    });
  }
  
  deletePlan(element) {
    let confirmacion = confirm(`se eliminara el plan`);
    if (confirmacion == true) {
      let confirmacionServicios = confirm(
        `Eliminaran tambien los servicio asociados al plan`
      );
      if (confirmacionServicios == true) {
        element.infoServicio.forEach((element) => {
          if(element.fecha > moment().format("YYYY-MM-DD")){
            this.ServicioService.delete(element.id).then((res) => {
              console.log("el servcio fue eliminado");
            });
          }
        });
        this.PlanService.eliminarPlan(element).then((res) => {
          this.PlanService.eliminarPlanRecurrente(element.id);
          this.PlanService.getProximasFacturasPlan(element.id).then(res => {
            res.docs.forEach(data => {
              this.ServicioService.eliminarCobro(data.data().id)
                .then(() => console.log('Cobro eleimido'))
                .catch(err => console.log('Error al eliminar el cobro', err))
            });
          })
          alert("eliminado");
        })
          .catch((err) => {
            console.log("ocurrio un errro al eleiminr el plan", err);
          });
      } else {
        this.PlanService.eliminarPlan(element).then((res) => {
          this.PlanService.eliminarPlanRecurrente(element.id);
          this.PlanService.getProximasFacturasPlan(element.id).then(res => {
            res.docs.forEach(data => {
              this.ServicioService.eliminarCobro(data.data().id)
                .then(() => console.log('Cobro eleimido'))
                .catch(err => console.log('Error al eliminar el cobro', err))
            });
          })
          alert("eliminado");
        })
          .catch((err) => {
            console.log("ocurrio un errro al eleiminr el plan", err);
          });
      }
    } else {
      let confirmacionAdicinalServicios = confirm(
        `Desea eliminar solo los servicios`
      );
      if (confirmacionAdicinalServicios == true) {
        element.infoServicio.forEach((element) => {
          console.log(element);
          this.ServicioService.delete(element.id);
        });
      }
      // alert("no se eliminara");
    }
  }

  verComprobante(id: string = "verComprobanteTs") {
    console.log("etro e ver comprobante");
    console.log(this.comprobanteAvisualizar);
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }
  public serviciosPlanVisualizado = [];
  public botonActualizarServicio = false;
  async traerServiciosPlan() {
    let servicios = [];
    let ids_array = this.planVisualizado.infoServicio.map(res => { return res.id.toString(); });
    console.log(ids_array);
    for (let i = 0; i < ids_array.length; i++) {
      try {
        console.log('ID', ids_array[i]);
        // const service_query = await firebase.firestore().collection('servicios').where('id','==',ids_array[i]).get();
        const service_query = await this.ServicioService.detaill(ids_array[i])
        if (service_query && service_query.exists) {
          servicios.push(service_query.data());
        } else {
          console.warn(`El documento con id ${ids_array[i]} no existe en la base de datos.`);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    this.serviciosPlanVisualizado = servicios;
    console.log(this.serviciosPlanVisualizado);
    this.loadingTablaPlan = false;
  }

  // Filtro que se ejecuta cunado se edita directamente un solo servicio


  filtroSeguridadSocial(event) {
    console.log(event.target.checked);
    let serversConSeguridad: any;
    if (event.target.checked) {
      serversConSeguridad = this.serversAsignables.filter(
        (server) => server.fechaVencimientoSeguridadSocial > moment().format("YYYY-MM-DD")
      );
      this.serversAsignables = serversConSeguridad;
    } else {
      this.serversAsignables = _.orderBy(
        this.serversAsignables,
        ["calificacion", "distanciaDelCliente"],
        ["desc", "asc"]
      );
      this.serversAsignables = this.copiaServersAsignables;
    }
  }

  async actualizarEstado() {
    this.loadingTablaPlan = true;

    let editados: any = {
      responsable: this.usuarioEnSecion.displayName,
      fecha: moment().format("YYYY-MM-DD"),
    };

    if( this.planVisualizado.estado != 'Cancelado' && this.estadoPlan == "Cancelado" && this.motivoCancelacion == "Otro" && this.motivoCancelacionOtro.length < 5){
      Swal.fire('Espera','Ingresa el motivo de cancelacio','warning');
      this.loadingTablaPlan = false;
      return
    }
 
    if (this.estadoPlan == "Congelado") {
      this.planVisualizado.fechaCongelacion = this.fechaCongelacion;
      this.serviciosPlanVisualizado.forEach((element) => {
        if (element.estado == "Aceptado" || element.estado == "Pendiente" || element.estado == "Orden de plan" || element.estado == "Orden de servicio") {
          editados.estado = `De ${element.estado} a Congelado`;
          if (!element.historialEdiciones) element.historialEdiciones = [];
          element.historialEdiciones.push(editados);
          element.estado = "Congelado";
          element.fechaCongelacion = this.fechaCongelacion;
          this.ServicioService.updateServicio(element).catch(error=>console.error('error al actualizar el servicio',error))
        }
      });
      await this.PlanService.eliminarPlanRecurrente(this.planVisualizado.id);
    } else if (this.estadoPlan == "Activo") {
      this.serviciosPlanVisualizado.forEach((element) => {
        if (element.estado == "Cancelado" || element.estado == "Congelado" || element.estado == "Suspendido") {
          editados.estado = `De ${element.estado} a Aceptado`;
          if (!element.historialEdiciones) element.historialEdiciones = [];
          element.historialEdiciones.push(editados);
          element.estado = "Aceptado";
          if (element.idServer = '123') {
            element.server = '';
            element.idServer = '';
            element.estado = 'Orden de plan'
          }
          console.log(element)
          this.ServicioService.updateServicio(element).then((res) => {
            console.log("ServicioActualizado");
          });
        }
      });
    } else if (this.estadoPlan == "Suspendido") {
      this.serviciosPlanVisualizado.forEach((element) => {
        if (element.estado == "Aceptado" || element.estado == "Pendiente" || element.estado == "Congelado") {
          editados.estado = `De ${element.estado} a Suspendido`;
          if (!element.historialEdiciones) {
            element.historialEdiciones = [];
          }
          element.historialEdiciones.push(editados);
          element.estado = "Suspendido";
          this.ServicioService.updateServicio(element).then((res) => {
            console.log("ServicioActualizado");
          });
        }
      })

    }else if(this.estadoPlan == "Cancelado") {
      
      this.serviciosPlanVisualizado.forEach((element) => {
        if (element.estado == "Aceptado" || element.estado == "Pendiente" || element.estado == "Orden de servicio" || element.estado == 'Orden de plan' || element.estado == 'Congelado') {
          editados.estado = `De ${element.estado} a Cacelado`;
          if (!element.historialEdiciones) {
            element.historialEdiciones = [];
          }
          element.historialEdiciones.push(editados);
          element.estado = "Cancelado";
          if (element.fecha > moment().endOf('month').format('YYYY-MM-DD')) {
            element.estado = 'Cancelado';
            this.ServicioService.updateServicio(element)
          } else {
            this.ServicioService.updateServicio(element).then((res) => {
              console.log("ServicioActualizado");
            });
          }
        }
      });
      await this.PlanService.eliminarPlanRecurrente(this.planVisualizado.id).catch(err => { console.log('Error al borrarplan recurrente', this.planVisualizado) });
      this.historialDeCobros.forEach(cobro => {
        if (cobro.fechaInicio > moment().endOf('month').format('YYYY-MM-DD')) {
          this.ServicioService.eliminarCobro(cobro.id).then(() => console.log('Cobro eleimido'))
            .catch(err => console.log('Error al eliminar el cobro', err))
        }
      })
      this.planVisualizado.fechaDeCancelacion = moment().format('YYYY-MM-DD');
      this.planVisualizado.motivoCancelacion = this.motivoCancelacion != 'Otro' ? this.motivoCancelacion : this.motivoCancelacionOtro
    
    }


    editados.estado = `De ${this.planVisualizado.estado} a ${this.estadoPlan}`;
  
    if (!this.planVisualizado.historialEdiciones) {
      this.planVisualizado.historialEdiciones = [];
    }

    this.planVisualizado.estado = this.estadoPlan;
    this.planVisualizado.historialEdiciones.push(editados);
    this.ServicioService.updatePlan(this.planVisualizado).then((res) => {
      this.loadingTablaPlan = false;
      this.cambiarEstadoPlanVisualizado = false;
      this.traerServiciosPlan();
      Swal.fire("Actualizado", "Se actualizo el plan", "success");
    });
  }

  actulizarPlanRecuurente(){
    this.planRecurrenteService.editPlanRecurrente(this.planRecurrente);
  }

  eleiminarCobrosCancelados() {
    this.dataDesactivados.forEach(element => {
      this.ServicioService.getPagosUsuarioByIdPlanOServicio(element.id).then(res => {
        res.docs.forEach(data => {
          let cobro = data.data()
          if (cobro.fechaInicio > moment().endOf('month').format('YYYY-MM-DD')) {
            console.log(element.estado, 'cobro a eliminar', cobro.fechaInicio);
            this.ServicioService.eliminarCobro(cobro.id)
              .then(() => console.log('Cobro eleimido'))
              .catch(err => console.log('Error al eliminar el cobro', err))
          }
        })
      })
    });
  }
 

  onSelecServer(server) {
    if (server.filtroObstructor == '') {
      console.log(server);
      this.asignarNuevaServer(server);
      // this.filtroHorarioUnicaServer(server,'')
    } else {
      console.log('Bloqueada', server)
    }
  }

  getServerSeleccionada(info) {
    return new Promise(async resolve => {
      const server = (await this.ServerService.detail(info.id)).data()
      resolve(server)
    })
  }

  actualizarServicio(servicio, revisarFecha) {
    console.log("Servicio a aditar", servicio, revisarFecha);
    this.ServicioService.getServiciosbyId(servicio.id).then((res) => {
      let editados: any = { a: false };
      if (res.data().fecha != servicio.fecha) {
        editados.fechaEditada = `De ${res.data().fecha} a ${servicio.fecha}`;
        editados.a = true;
      }
      if (res.data().hora != servicio.hora) {
        editados.horaInicio = `De ${res.data().hora} a ${servicio.hora}`;
        editados.a = true;
      }
      if (res.data().idServer != servicio.idServer) {
        editados.server = `De ${res.data().server.name} ${res.data().server.apellido
          } a ${servicio.server.name} ${this.servicioEnEdicion.server.apellido}`;
        editados.a = true;
      }
      if (res.data().estado != servicio.estado) {
        editados.estado = `De ${res.data().estado} a ${servicio.estado}`;
        editados.a = true;
      }
      if (editados.a) {
        editados.responsable = this.usuarioEnSecion.displayName;
        editados.fecha = moment().format("YYYY-MM-DD");
        console.log("Cosas que edito", editados);
        if (!servicio.historialEdiciones) {
          servicio.historialEdiciones = [];
        }
        servicio.historialEdiciones.push(editados);
      }
    });
    this.ServicioService.updateServicio(servicio).then((res) => {
      Swal.fire("Actualizado", "Fue actualizado con exito", "success");
      if (revisarFecha) {
        let contador = 0;
        this.planVisualizado.infoServicio.map((res) => {
          console.log(res.id, servicio.id);
          if (res.id == servicio.id) {
            res.fecha = servicio.fecha;
          }
          contador++;
          if (contador == this.planVisualizado.infoServicio.length) {
            console.log(this.planVisualizado);
            this.ServicioService.updatePlan(this.planVisualizado).then((res) => { this.loadingTablaPlan = false; });
          }
        });
      }
    });
  }

  // actualizarPlan() {

  //   if (this.planVisualizado.estado === 'Activo') {
  //     this.actualizarPlanRecurrente(this.planVisualizado)
  //   }
  // }

  generateAllInfoPlan() {
    this.dataTodos.forEach(res => {
      if (res.infoServicio !== undefined && res.estado !== 'Cancelado' && res.estado !== 'Congelado') {
        this.crearPlanRecurrente(res)
      }
    })
  }
  public crearPlanRecurrente(plan) {
    // console.log("Entro en CrearPlanRecurrente()")
    let diasServicio = [];
    let diasConfirmados = [];
    let contadorDias = 0;
    // console.log(plan.creado,/* plan.horaDeInicio */plan.infoServicio);
    // let horaInicio =  plan.horaDeInicio != undefined ? plan.horaDeInicio : plan.infoServicio[0].hora
    // console.log(horaInicio,plan.client.name)
    plan.infoServicio.forEach((element) => {
      let numDia = moment(element.fecha).day();
      let dia = "";
      if (numDia == 0) {
        dia = "domingo";
      } else if (numDia == 1) {
        dia = "lunes";
      } else if (numDia == 2) {
        dia = "martes";
      } else if (numDia == 3) {
        dia = "miercoles";
      } else if (numDia == 4) {
        dia = "jueves";
      } else if (numDia == 5) {
        dia = "viernes";
      } else if (numDia == 6) {
        dia = "sabado";
      }

      let diaAgregado = diasServicio.filter((day) => day == dia);
      if (diaAgregado.length >= 0 && diaAgregado.length <= 1) {
        diasServicio.push(dia);
        // console.log(diasServicio);
        contadorDias++;
      } else if (diaAgregado.length == 2) {
        diasServicio.push(dia);
        diasConfirmados.push(dia);
        contadorDias++;
      } else {
        contadorDias++;
      }
      // console.log(contadorDias, plan.infoServicio.length)
      if (contadorDias == plan.infoServicio.length) {
        let planRecurrente = {
          id: plan.id,
          horas: plan.horasDeServicio,
          clientId: plan.client.id,
          clienteName: `${plan.client.name}  ${plan.client.apellido}`,
          horaInicio: plan.horaDeInicio != undefined ? plan.horaDeInicio : plan.infoServicio[0].hora,
          diasAgendado: diasConfirmados,
          numServicios: plan.cantidadServicios,
          precioServicio: plan.precioIndividual,
          idServerAsignada: plan.estado != 'Orden de plan' ? `${plan.server.id}` : '',
          serverName: plan.estado != 'Orden de plan' ? `${plan.server.name}  ${plan.server.apellido}` : '',
        };
        console.log(planRecurrente, plan);
        this.planRecurrenteService.post(planRecurrente).then(() => {
          console.log("A");
        });
      }
    });
  }

  public actualizarPlanRecurrente(plan) {
    console.log("Entro en actualizarPlanRecurrente()")
    let diasServicio = [];
    let diasConfirmados = [];
    let contadorDias = 0;
    plan.infoServicio.forEach((element) => {
      let numDia = moment(element.fecha).day();
      let dia = "";
      if (numDia == 0) {
        dia = "domingo";
      } else if (numDia == 1) {
        dia = "lunes";
      } else if (numDia == 2) {
        dia = "martes";
      } else if (numDia == 3) {
        dia = "miercoles";
      } else if (numDia == 4) {
        dia = "jueves";
      } else if (numDia == 5) {
        dia = "viernes";
      } else if (numDia == 6) {
        dia = "sabado";
      }

      let diaAgregado = diasServicio.filter((day) => day == dia);
      if (diaAgregado.length >= 0 && diaAgregado.length <= 2) {
        diasServicio.push(dia);
        // console.log(diasServicio);
        contadorDias++;
      } else if (diaAgregado.length == 3) {
        diasServicio.push(dia);
        diasConfirmados.push(dia);
        contadorDias++;
      } else {
        contadorDias++;
      }
      console.log(contadorDias == this.arrayPreInfoServiciosPlan.length)
      if (contadorDias == this.arrayPreInfoServiciosPlan.length) {
        let planRecurrente = {
          id: plan.id,
          horas: plan.horasDeServicio,
          clientId: plan.client.id,
          clienteName: `${plan.client.name}  ${plan.client.apellido}`,
          horaInicio: this.arrayPreInfoServiciosPlan[0].hora,
          diasAgendado: diasConfirmados,
          numServicios: plan.cantidadServicios,
          precioServicio: plan.precioIndividual,
          idServerAsignada: plan.server.id,
          serverName: `${plan.server.name}  ${plan.server.apellido}`,
        };
        this.planRecurrenteService.post(planRecurrente);
      }
    });
  }

  asignarPostventaPlan() {
    this.planVisualizado.estadoPostventa = "postventa pendiente";
    this.planVisualizado.fechaPostventa = moment(this.fechaPostVenta).format( "YYYY-MM-DD" );
    this.ServicioService.updatePlan(this.planVisualizado).then((res) => { this.loadingTablaPlan = false; });
  }

  public multiplesServicios = false;
  public serviciosParaCambio: any = [];
  public activarEligeServerMultiple = false;
  public contadorFechasServiciosPlanVerificadas = 0;

  public removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  async validarServerActual() {
    const idServer = this.planEnReagendamiento.idServers;
    console.log("filtroHorarioUnicaServer");
    let disponible: boolean = true;
    let razonBloqueo = "";
    let servicios = this.arrayPreInfoServiciosPlan
    let horasDeServicio = this.horasDeServicioReg
    servicios = _.orderBy(servicios, ['fecha'], ['asc'])
    const fechaBusqueda = servicios[0].fecha

    const querry = await this.ServicioService.getByServerFromADate(idServer, fechaBusqueda)

    for (let i = 0; i < querry.size; i++) {
      const servicio = querry.docs[i].data();
      if (servicio.estado != "Cancelado" && servicio.estado != "Anulado" && servicio.estado != "Congelado") {
        let hora_inicio_servicio_validado = parseInt(servicio.hora);
        let hora_final_servicio_validado = hora_inicio_servicio_validado + parseInt(servicio.horasDeServicio);
        const fechaConConicidencia = servicios.filter(res => {
          if (servicio.fecha === res.fecha) {
            console.log(res.hora, hora_inicio_servicio_validado, hora_final_servicio_validado)
            if (parseInt(res.hora) == hora_inicio_servicio_validado) {
              return true;
            } else if (parseInt(res.hora) >= hora_inicio_servicio_validado && parseInt(res.hora) <= hora_final_servicio_validado) {
              return true;
            } else if ((parseInt(res.hora) + horasDeServicio) >= hora_inicio_servicio_validado && (parseInt(res.hora) + horasDeServicio) <= hora_final_servicio_validado) {
              return true
            }
          }
        })
        if (fechaConConicidencia.length > 0) {
          razonBloqueo = razonBloqueo + `${fechaConConicidencia[0].fecha} /`;
          disponible = false;
        }
      }
    }

    if (disponible) {
      this.serverSelectReg = (await this.ServerService.detail(idServer)).data();
      this.loadingTablaPlan = false;
      console.log('serverDisponible',this.serverSelectReg)
    } else {
      Swal.fire("Espera", `La server ya tien servicio las siquentes fechas que intentas asignar ${razonBloqueo}`, "warning")
      this.loadingTablaPlan = false;
    }

  }

  onUserRowSelect(event) {
    this.botonActualizarServicio = false;
    this.serviciosParaCambio = event.selected;
    this.serversAsignables = [];
    this.copiaServersAsignables = [];
    console.log(this.serviciosParaCambio);
    if (this.serviciosParaCambio.length > 0) {
      this.multiplesServicios = true;
    } else {
      this.multiplesServicios = false;
    }
  }

  buscarDisponiblilidadParaElPlan(tipo) {
    if (tipo == 'plan') {
      this.serviciosParaCambio = [];
      this.tipoDeAsignacion = "PlanyServicios"
      console.log('Entro en buscarDisponiblilidadParaElPlan')
      this.serviciosPlanVisualizado.forEach((res, index) => {
        if (res.fecha > moment().format('YYYY-MM-DD') && res.estado !== 'Cancelado') {
          this.serviciosParaCambio.push(res)
        }
        if (index == this.serviciosPlanVisualizado.length - 1) {
          if (this.serviciosParaCambio.length > 0) {
            this.filtrarServers('editar')
          } else {
            Swal.fire('Espera', 'No quedan servicio pendioentes por asignar', 'warning')
          }
        }
      });
    } else {
      this.filtrarServers('editar')
    }
  }

  infoEdicionServicio: any = {
    responsable: '',
    fecha: moment().format("YYYY-MM-DD"),
  }
  serverSelecionada: any = {};
  async asignarNuevaServer(info) {
    this.serverSelecionada = await this.getServerSeleccionada(info)
    this.serverSelectReg = await this.getServerSeleccionada(info)
    console.log(this.serviciosParaCambio);
    this.botonActualizarServicio = true;
  }

  asignarServer() {
    let infoEditada = {
      responsable: this.usuarioEnSecion.displayName,
      fecha: moment().format("YYYY-MM-DD"),
      server: '',
    }
    this.loadingTablaPlan = true;
    this.infoEdicionServicio
    let contador = 0;
    console.log(this.serviciosParaCambio)
    this.serviciosParaCambio.forEach((servicio) => {
      infoEditada.server = `De ${servicio.server.name} ${servicio.server.apellido} a ${this.serverSelecionada.name} ${this.serverSelecionada.apellido}`;
        servicio.server = this.serverSelecionada;
        servicio.serverName = `${this.serverSelecionada.name} ${this.serverSelecionada.apellido}`;
        servicio.idServer = this.serverSelecionada.id;
        servicio.estado = "Aceptado";
      if (this.fechaInicioMultipleActualizacion != "Actualizar hora") {
        this.infoEdicionServicio.horaInicio = `De ${servicio.hora} a ${this.fechaInicioMultipleActualizacion}:00`
        servicio.hora = `${this.fechaInicioMultipleActualizacion}:00`;
      }
      if (!servicio.historialEdiciones) servicio.historialEdiciones = [];
      if (!this.planVisualizado.historialEdiciones) this.planVisualizado.historialEdiciones = [];
      
      servicio.historialEdiciones.push(this.infoEdicionServicio)

      this.planVisualizado.historialEdiciones.push(this.infoEdicionServicio);

      this.ServicioService.updateServicio(servicio).then(async (res) => {
        contador++;
        console.log("Se actualizo el servicio,", servicio);
        if (contador == this.serviciosParaCambio.length && this.tipoDeAsignacion == "servicios" || this.tipoDeAsignacion == 'remplazo') {
          Swal.fire("Actualizado", "Los servicios que seleccionaste fueron actualizados", "success");
          this.multiplesServicios = false;
          this.serviciosParaCambio = [];
          this.traerServiciosPlan();
        } else if (contador == this.serviciosParaCambio.length && this.tipoDeAsignacion == "PlanyServicios") {
          this.planVisualizado.server = this.serverSelecionada;
          this.planVisualizado.idServers = this.serverSelecionada.id;
          this.planVisualizado.serverName = `${this.serverSelecionada.name} ${this.serverSelecionada.apellido}`;
          this.planVisualizado.estadoConfirmacion = "Aceptado";
          if (this.planVisualizado.estado == "Orden de plan") {
            this.planVisualizado.estado = 'Activo'
          }
          if (this.planVisualizado.client.serverFavorita == undefined) {
            this.planVisualizado.client.serverFavorita = {
              id: this.planVisualizado.server.id,
              foto: this.planVisualizado.server.foto != undefined ? this.planVisualizado.server.foto : "",
              nombreServer: `${this.planVisualizado.server.name} ${this.planVisualizado.server.apellido}`
            }
            await this.usuarioService.updateFavoriteServerClient(this.planVisualizado.client.id, this.planVisualizado.client.serverFavorita);
            this.ServicioService.updatePlan(this.planVisualizado).then(async (res) => {
              this.ServicioService.updateServerPlanRecurrente(this.planVisualizado.id, this.planVisualizado.server)

              Swal.fire(
                "Actualizado",
                "El plan y los servicios que seleccionaste fueron actualizados",
                "success"
              );
              this.serverSeleccionada = {};
              this.multiplesServicios = false;
              this.serviciosParaCambio = [];
              await this.traerServiciosPlan();
              this.loadingTablaPlan = false;
            });
          } else if (this.planVisualizado.idServers != this.planVisualizado.client.serverFavorita.id) {
            Swal.fire({
              title: "La server asigada es diferente, deseas agregar la anterior server a la black list?",
              showConfirmButton: true,
              confirmButtonText: 'Si',
              showCancelButton: true,
              cancelButtonText: "No",
            }).then(res => {
              console.log(res)
              if (res.value) {
                if (this.planVisualizado.client.blackList === undefined) {
                  this.planVisualizado.client.blackList = [];
                }
                console.log("Nueva server a black list", this.planVisualizado.client.serverFavorita)
                this.planVisualizado.client.blackList.push({
                  foto: this.planVisualizado.client.serverFavorita.foto,
                  id: this.planVisualizado.client.serverFavorita.id,
                  nombreServer: this.planVisualizado.client.serverFavorita.nombreServer
                })
                console.log("Nueva server favorita", this.planVisualizado.server)
                this.planVisualizado.client.serverFavorita = {
                  id: this.planVisualizado.server.id,
                  foto: this.planVisualizado.server.foto,
                  nombreServer: this.planVisualizado.server.name
                }
                this.actualizarServersCliente(this.planVisualizado.client);
                this.usuarioService.updateFavoriteServerClient(this.planVisualizado.client.id, this.planVisualizado.client.serverFavorita);
              }
              this.ServicioService.updatePlan(this.planVisualizado).then(async (res) => {
                this.ServicioService.updateServerPlanRecurrente(this.planVisualizado.id, this.planVisualizado.server)

                Swal.fire(
                  "Actualizado",
                  "El plan y los servicios que seleccionaste fueron actualizados",
                  "success"
                );
                this.multiplesServicios = false;
                this.serviciosParaCambio = [];
                await this.traerServiciosPlan();
                this.loadingTablaPlan = false;
              });
            });

          } else {
            this.ServicioService.updatePlan(this.planVisualizado).then(async (res) => {
              this.ServicioService.updateServerPlanRecurrente(this.planVisualizado.id, this.planVisualizado.server)
              Swal.fire(
                "Actualizado",
                "El plan y los servicios que seleccionaste fueron actualizados",
                "success"
              );
              this.multiplesServicios = false;
              this.serviciosParaCambio = [];
              await this.traerServiciosPlan();
              this.loadingTablaPlan = false;
            });
          }
        }
      });
    });
  }

  onUpload(event, cobro, type) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;
    this.image = true;
    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      this.subirImagen(cobro, type);
      this.planVisualizado.estadoPago = "Pago";
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public subirImagen(cobro, type) {
    this.ServicioService.putPicture(this.pictureName, this.file).then((value) => {
      if (value.state == "success") {
        this.ServicioService.getURL(this.pictureName).subscribe((url) => {
          console.log(type)
          if (type == "primero") {
            cobro.comprobante = url;
          } else if (type == 'segundo') {
            cobro.comprobanteDos = url;
          }
          cobro.estadoDelPago = "Pago";
          this.ServicioService.actualizarCobro(cobro);
          const index_cobro = this.historialDeCobros.findIndex(res=>res.id == cobro.id);
          if (moment(cobro.fechaInicio).format("MM-YYYY") == moment().format("MM-YYYY") || index_cobro == this.historialDeCobros.length-1) {
            this.planVisualizado.comprobanteActual = url;
            this.planVisualizado.estadoPago = "Pago";
            this.serviciosPlanVisualizado.forEach(async (element) => {
              element.comprobante = url;
              element.estadoPago = "Pago";
              await this.actualizarServicio(element, false);
            });
            this.ServicioService.updatePlan(this.planVisualizado).then((res) => { this.loadingTablaPlan = false; });
          }
          Swal.fire("Actualizado", "fue actualizado con exito", "success").then((res) => {
            const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
            element.click();
          });
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  actualizarCobro() {
    console.log(this.copiaCobroVisualizado, this.cobroVisualizado)
    if (
      this.copiaCobroVisualizado.recargo != this.cobroVisualizado.recargo ||
      this.copiaCobroVisualizado.precio != this.cobroVisualizado.precio ||
      this.copiaCobroVisualizado.descuento != this.cobroVisualizado.descuento &&
      this.razonCambioCobro === ""
    ) {
      const a = document.getElementById("alerta-razoncambio-cobro") as HTMLParagraphElement
      a.style.display = "block"
    } else {
      if (this.cobroVisualizado.cambios === undefined) {
        this.cobroVisualizado.cambios = [];
      }
      this.cobroVisualizado.cambios.push({
        responsable: this.usuarioEnSecion.displayName,
        fecha: moment().format("YYYY-MM-DD"),
        descripcion: this.razonCambioCobro,
      })
      console.log("Guardadno factura", this.cobroVisualizado)
      this.ServicioService.actualizarCobro(this.cobroVisualizado).then(res=>{
        if (moment(this.cobroVisualizado.fechaInicio).format("M") == moment().format("M")) {
          this.planVisualizado.comprobanteActual = this.cobroVisualizado.comprobante
          this.planVisualizado.estadoPago = this.cobroVisualizado.estadoDelPago;
          this.ServicioService.updatePlan(this.planVisualizado)
        }
        Swal.fire('Listo','Se actulizo con exito','success');
      })
    }
  }

  /* Variables para reagendamintoPlan */
  public planEnReagendamiento: any = "no";
  public clientePlanReg: any = {};
  serviciosPrestados: any = ['Hogares','Empresas','Airbnb'];
  arrayCategoriaServicio = serviciosPrestados;
  arraySubCategoriaServicio: any;
  servicioSeleccionado: any;
  public horasDeServicioReg: any;
  public diaInterface: any = [];
  public horasInterface: any = [];
  public valorInterface: any = [];
  public preciosServicios: any = preciosServicios;



  public frecuenciaReg: string = "";
  public diasSeleccionados: any = [];
  public arrayFechasServicios: any = [];
  public arrayPreInfoServiciosPlan: any = [];
  public tipoAgendamiento: string = "";
  public keywordServer = "nombreServer";
  public listaCalendarioServicio: any = [];

  public serversDisponibles: any = [];

  priceList = [];
  public recargo = 0;
  public recargoTransporte = 0;
  public descuento = 0;
  private gananciaServerReg = 0
  public precioPlanReg = 0;
  private precioIndividualReg = 0
  private final_plan_price = 0;
  private infoValoresPrecio: any;

  public flippedPlanes = false;
  public refresh: Subject<any> = new Subject();
  public view: CalendarView = CalendarView.Month;
  public viewDate: Date = new Date();
  public CalendarView = CalendarView;

  public selectedMonthViewDay: CalendarMonthViewDay;
  public arrayServiciosByServer = [];
  public horaMinima: number = 6;
  public horaMaxima: number = 22;
  public horasTotales: any = [];

  horaInicioPlanReg = '';
  horarioReg = '';

  public selectDireccionReg: string = "direc1";
  public serverSelectReg: any = null;
  public cantidadServiciosReg: number = 0;
  public infoDate = [];
  public horasEliminar = [];
  public horasPosibles = [];
  public horario: string = "";
  public metodoPagoReg: string = "";
  public fechaLimitePagoReg: string;
  public plazoDePagoReg: string = "";
  // public numeroFacturaReg: string = "falta";
  public referenciaPagoReg: string = "falta";
  public comprobanteReg: string = "";

  public servicioReg: string = "";
  public categoriaReg: string = "";
  public subCategoriaReg: string = "";

  editPrecioPlan: boolean = false;
  editRecargo: boolean = false;
  editDescuento: boolean = false;
  public datosParaRegPlan: any;
  public pictureName: any;
  public file: any;
  public nameImage: string = "";
  public fotoSeleccionada: any;
  public image: boolean = false;

  public indexLugar = 0;
  public loadingTablaPlan = false;
  public loaderReagendandoPlan = false;
  public asignarPostVenta = false;
  public planAceptadoPreviamente = false;
  public fechaPostVenta = "";
  /* Tareas */

  clientTaskList = [];
  activeAddNewTask: boolean = false;
  arrayDirationTask = [
    {name: '30 minutos', value: 30},
    {name: '60 minutos', value: 60},
    {name: '90 minutos', value: 90},
    {name: '120 minutos', value: 120},
  ]
  newTask = {
    name: '',
    duration: 0,
    description:'',
  }
  taskHours = 0;

  daySelectedTaskList = 0;
  editar_taskList = false;
  newTaskList = [];

  /* funciones para el reagendamiento */
  public async prepararMdlReagendar(info) {
    try{
      this.loadingTablaPlan = true;
      this.planEnReagendamiento = { ...info };
      this.arrayMeses = [];
      this.listaServersConRazonBloqueo = [];
      this.selectDireccionReg = "direc1";
      this.serverSelectReg = null;
      this.infoDate = [];
      this.horasEliminar = [];
      this.horasPosibles = [];
      this.horario = "";
      this.metodoPagoReg = "";
      this.fechaLimitePagoReg = undefined;
      this.plazoDePagoReg = "";
      // this.numeroFacturaReg = "falta";
      this.referenciaPagoReg = "falta";
      this.comprobanteReg = "";
      this.servicioReg = "";
      this.categoriaReg = "";
      this.subCategoriaReg = "";
      this.precioPlanReg = 0;
      this.indexLugar = null;
      this.servicioReg = "";
      this.categoriaReg = "";
      this.plazoDePagoReg = "";
      this.subCategoriaReg = "";
      this.tipoAgendamiento = "";
      this.diasSeleccionados = [];
      this.asignarPostVenta = false;
      this.fechaPostVenta = "";
      this.horasDeServicioReg = 0;
      this.cantidadServiciosReg = 0;
      this.diasSelccionadoPlan = []
      this.mesesSelccionadoPlan = [];
      this.arrayPreInfoServiciosPlan = [];
      this.planAceptadoPreviamente = false;
      this.planEnReagendamiento.destination = null;
      this.clientePlanReg = {};
      this.recargo = 0;
      this.recargoTransporte = 0;
      this.descuento = 0;
      this.gananciaServerReg = 0
      this.precioPlanReg = 0;
      this.precioIndividualReg = 0
      this.final_plan_price = 0;
      this.taskHours = 0;

      this.planRecurrente = (await this.planRecurrenteService.getPlanRecurrenteId(info.id)).data();
      this.planEnReagendamiento.client = (await this.usuarioService.detail(info.userid)).data();
      // console.log(this.planEnReagendamiento)
      this.clientePlanReg = (await this.usuarioService.detail(this.planEnReagendamiento.client.id)).data();
      this.clientTaskList = this.clientePlanReg.taskList ? await this.clientePlanReg.taskList.map(res=>{ return{...res,seleccionado: false}}) : [];
      const query = await this.priceService.getspecificField('tipoAgendamiento','Plan');
      this.priceList =  query.docs.map(res=>{
        return {...res.data()}
      })
      console.log( this.priceList);
      this.calcularMesesSiguentes();
    }catch(error){
      console.log(error);
    }
  }

    /*########## COMENTARIOS CLIENTE #########*/
    public fechaActual = moment().format("YYYY-MM-DD");
    public comentario = {
      name: "",
      comentario: "",
      fecha: this.fechaActual,
    };
    public comentarioArray: any = [];
    public ingresarComentarioLogistica: boolean = false;
    public ingresarComentarioServer: boolean = false;
    AddComentario(tipo) {
      this.comentario.name = this.usuarioEnSecion.displayName;
      if (tipo == "server") {
        this.ingresarComentarioLogistica = false;
        this.ingresarComentarioServer = true;
      } else if (tipo == "logistica") {
        this.ingresarComentarioServer = false;
        this.ingresarComentarioLogistica = true;
      }
    }
    cancelarComentario() {
      this.ingresarComentarioLogistica = false;
      this.ingresarComentarioServer = false;
      this.comentario.comentario = '';
    }
    async guardarComentario(tipo) {
      this.comentario.name = this.usuarioEnSecion.displayName;
      const ret = await this.funcionesUsuario.comentariosCliente(
        tipo,
        this.planEnReagendamiento.client,
        this.comentario
      );
      if(tipo == 'server'){
        this.clientePlanReg.comentarioServer = ret
      }else{
        this.clientePlanReg.comentario = ret;
      }
      this.ingresarComentarioLogistica = false;
      this.ingresarComentarioServer = false;
      this.comentario = {
        name: "",
        comentario: "",
        fecha: this.fechaActual,
      };
    }
    /*########################################*/

  elegirDireccionPlanReg(event) {
    let a = parseInt(event);
    this.planEnReagendamiento.destination =
      this.planEnReagendamiento.client.direccionesArray[a];
    this.planEnReagendamiento.direccion =
      this.planEnReagendamiento.destination.direccion;
  }

  async elegirServicio(event, type) {
    const name: string = event.target.value;
    let filter = null;
    if (type == "categoria") {
      console.log(this.arrayCategoriaServicio);
      filter = await this.arrayCategoriaServicio.filter((categoria) => categoria.name == name)[0];
      console.log(filter,type)
      this.arraySubCategoriaServicio = filter[type];
      console.log(this.arraySubCategoriaServicio);
      this.subCategoriaReg = "";
    } 
    else if (type == "subCategoria") {
      console.log(this.subCategoriaReg);
      const query_service = await this.priceService.getspecificField('servicio',this.subCategoriaReg);
      this.servicioSeleccionado = query_service.docs[0].data();
      const query_prices = await query_service.docs[0].ref.collection('valores').get();
      const allPriceList = query_prices.docs.map(res=>{
        return {...res.data()}
      })
      this.priceList = allPriceList.filter(res=>res.tipoAgendamiento == 'plan mensual')
      // if(this.servicioSeleccionado.agendamiento.includes('plan mensual')) this.validoParaPlan = true;
      console.log(this.priceList);
    }
  }



  onMultiServiceSelect(item: any) {
    console.log('onItemSelect', item);
    this.serviciosSelecccionados.push(item)
    console.log(this.serviciosSelecccionados)
  }

  onMultiServiceDeSelect(item: any) {
    const index = this.serviciosSelecccionados.findIndex(res => res.item_id == item.item_id);
    console.log(index);
    this.serviciosSelecccionados.splice(index, 1);
    console.log(this.serviciosSelecccionados)
  }

  public valorarFrecuencia() {
    const name: string = this.cantidadServiciosReg.toString();
    console.log(this.cantidadServiciosReg);

    if (this.diasSelccionadoPlan.length == 1) {
      this.frecuenciaReg = "1 por semana";
    } else if (this.diasSelccionadoPlan.length == 2) {
      this.frecuenciaReg = "2 por semana";
    } else if (this.diasSelccionadoPlan.length == 3) {
      this.frecuenciaReg = "3 por semana";
    } else if (this.diasSelccionadoPlan.length == 4) {
      this.frecuenciaReg = "4 por semana";
    } else if (this.diasSelccionadoPlan.length == 5) {
      this.frecuenciaReg = "5 por semana";
    } else if (this.diasSelccionadoPlan.length == 6 || this.diasSelccionadoPlan.length == 7) {
      this.frecuenciaReg = "6 por semana";
    }
  }

    /*---------Tareas cliente--------*/
    async onSaveNewTaskPlan(type){
      this.activeAddNewTask = false;
      let cliente = type == 'reagendar' ? this.clientePlanReg : this.clienteDelPlanVisualizado;
      if(this.newTask.description.length > 10 && this.newTask.duration > 0){
        let newTaskRef = JSON.stringify(this.newTask);
        await this.funcionesUsuario.tareasCliente(cliente,newTaskRef);
        if(type == 'reagendar') this.diasSelccionadoPlan.forEach(res=>res.taskList.push(JSON.parse(newTaskRef)))
        this.clientTaskList.push(JSON.parse(newTaskRef));
        this.newTask = {
          name: '',
          duration: 0,
          description:'',
        }
      }else{
        this.activeAddNewTask = true;
      }
    }
    
    onAddTaskToPlan(i){
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado = !this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado;
      if(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado){
        this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours += parseInt(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].duration);
      }else{
        this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours -= parseInt(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].duration);
        this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = 
          this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours < 0 
          ? 0 
          : this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours
      }
    }
  
    changeTaskHours(){
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = 0;
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskList.map(res=>{
        this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours += parseInt(res.duration);
      })
      // console.log(this.taskHours);
    }

    validateTaskList(){
      const int_horasDeServicio = parseInt(this.horasDeServicioReg);
      let isValid = true;
      for(let i = 0; i < this.diasSelccionadoPlan.length;i++ ){
        const taskSelected =  this.diasSelccionadoPlan[i].taskList.filter(res=>res.seleccionado == true);
        // console.log( 'dias',i,this.diasSelccionadoPlan[i].taskListHours, int_horasDeServicio*60,"----", taskSelected.length ,(int_horasDeServicio - 1) )
        if(  this.diasSelccionadoPlan[i].taskListHours == int_horasDeServicio*60 || taskSelected.length == (int_horasDeServicio - 1) ){
          this.diasSelccionadoPlan[i].taskListAlert = '';
        }else{
          this.diasSelccionadoPlan[i].taskListAlert = 'La lista de tareas no es valida';
          // isValid = false;
          isValid = true;
        }
      }
      return isValid;
    }

    selectedDayTaskList(i,type){
      this.daySelectedTaskList = i;
      if(type == 'reagendar'){
        this.diasSelccionadoPlan.map(res=>res.selected = false);
        this.diasSelccionadoPlan[i].selected = true;
      }else if(this.editar_taskList){
        this.clientTaskList.map(res=>{
          const value = this.newTaskList[this.daySelectedTaskList].taskList.some(task=>task.name == res.name);
          res.seleccionado = value;
          this.updateDurationNewTaskList();
        })
      }
      console.log(this.diasSelccionadoPlan,this.daySelectedTaskList);
    }

    createTaskListPlan(){
      return new Promise(resolve=>{
        let taskListFinal = [];
        for(let i = 0;i<this.diasSelccionadoPlan.length-1;i++){
          let array = this.diasSelccionadoPlan[i].taskList.filter(res=> res.seleccionado == true);
          taskListFinal.push({
            dia: this.diasSelccionadoPlan[i].diaInt,
            taskList: array
          })
        }
        return resolve(taskListFinal);
      })
    }
  
    // Editar taskLista plan
    async editarTaskList(){
      let checkListActual_json = JSON.stringify(this.planVisualizado.checkList)
      let checkListActual = JSON.parse(checkListActual_json);
      this.newTaskList = [];
      checkListActual.forEach(res=>{
        this.newTaskList.push({dia: res.dia,taskList: res.taskList,duracion: 0})
      })
      this.clientTaskList = this.clienteDelPlanVisualizado.taskList;

      this.clientTaskList.map(res=>{
        const value = checkListActual[this.daySelectedTaskList].taskList.some(task=>task.name == res.name);
        if(value){
          this.newTaskList[this.daySelectedTaskList].duracion += parseInt(res.duration);
        } 
        res.seleccionado = value;
      })
      await this.updateDurationNewTaskList();
      this.editar_taskList = true;
      console.log(this.planVisualizado.checkList,this.newTaskList);
    }
  
    onAddTaskToDay(i){
      console.log(this.daySelectedTaskList);
      if (!this.clientTaskList[i].seleccionado) {
        this.newTaskList[this.daySelectedTaskList].duracion += parseInt(this.clientTaskList[i].duration);
        this.newTaskList[this.daySelectedTaskList].taskList.push(this.clientTaskList[i]);
      } else {
        const index = this.newTaskList[this.daySelectedTaskList].taskList.indexOf(this.clientTaskList[i]);
        if (index !== -1) {
          this.newTaskList[this.daySelectedTaskList].duracion -= parseInt(this.newTaskList[this.daySelectedTaskList].taskList[index].duration);
          this.newTaskList[this.daySelectedTaskList].duracion  = this.newTaskList[this.daySelectedTaskList].duracion < 0 ? 0 : this.newTaskList[this.daySelectedTaskList].duracion
          this.newTaskList[this.daySelectedTaskList].taskList.splice(index, 1);
        }
      }
      this.clientTaskList[i].seleccionado = !this.clientTaskList[i].seleccionado;
      console.log(this.newTaskList);
    }

    validateNewTaskList(){
      const int_horasDeServicio = parseInt(this.planVisualizado.horasDeServicio);
      let isValid = true;
      for(let i = 0; i < this.newTaskList.length;i++ ){
        this.newTaskList[i].duracion 
        if(  this.newTaskList[i].duracion > int_horasDeServicio*60 ) {
          isValid = false;
          break;
        }
      }
      return isValid;
    }

    updateDurationNewTaskList(){
      this.newTaskList[this.daySelectedTaskList].duracion = 0;
      this.newTaskList[this.daySelectedTaskList].taskList.map(res=>{
        this.newTaskList[this.daySelectedTaskList].duracion += parseInt(res.duration);
      })
      console.log(this.newTaskList);
    }
  
    saveNewTaskList(){
      console.log(parseInt(this.planVisualizado.horasDeServicio)*60);
      console.log(this.newTaskList);
      let json = JSON.stringify(this.newTaskList);
      this.planVisualizado.checkList = JSON.parse(json);
      this.ServicioService.updatePlan({id: this.planVisualizado.id,checkList: this.newTaskList})
      this.serviciosPlanVisualizado.forEach(servicio=>{
        if(servicio.fecha > moment().format('YYYY-MM-DD')){
          let day = parseInt(moment(servicio.fecha).format('d'));
          console.log(day)
          let taskList = this.newTaskList.filter(res=>res.dia == day)[0]
          console.log(`tareas para el ${servicio.fecha} `,taskList);
          this.ServicioService.actualizarServicio({id:servicio.id,checkList: taskList.taskList})
        }
      })
      Swal.fire("Listo","La lista de tareas se actulizo para el plan y los servcios restantes",'success');
      this.editar_taskList = false;
    }
  
/* ------------------------------------- */

/*---------------- Productos---------------- */
onSaveNewProduct(){
  this.totalValueExtras = 0;
  let newProductRef = JSON.stringify(this.newProduct);
  this.productList.push(JSON.parse(newProductRef));
  this.productList.forEach(product=>{
    this.totalValueExtras += product.value;
  });
  this.activeAddNewProduct = false;
  this.calcularPrecioPlan();
}
/*-------------------------------------------*/


  refreshView() {
    this.refresh.next();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    console.log("Entro en la funcion de beforeMonthViewRender");
    if (this.diasSeleccionados) {
      body.forEach((day) => {
        for (let fechaNoDisponible of this.diasSeleccionados) {
          if (fechaNoDisponible.date.getTime() == day.date.getTime()) {
            day.cssClass = "diaSeleccionado-calendario-agendarPlan";
          }
        }
      });
    } else {
      console.log(this.diasSeleccionados);
    }
  }

  public toggleViewPlanes() {
    this.flippedPlanes = !this.flippedPlanes;
  }

  onSelectServerReagendar(event) {
    if (event.filtroObstructor == '') {
      console.log(event);
      this.serverSelectReg = event;
    }
  }

  validarCodigoReferido(){
    this.codigoReferidoServerService.getByCode(this.codigoReferido).then(async res=>{
      if(res.size == 0) Swal.fire('Upps..','El codigo ingresado no es valido','warning');
      else{
        let filter = await this.codigoReferidoServerService.detailInSubcoleccion(res.docs[0].id,'redeemed',this.planEnReagendamiento.client.id)
        console.log(filter.exists)
        if(filter.exists) Swal.fire('Upps..','El codigo ingreasdo ya fue redimido para este usuriao','warning');
        else{
          this.tipoDeDescuento = "porcentual";
          this.idCodigoReferido = res.docs[0].id;
          this.descuento = 10;
          this.agregarDescuentoPlan();
        }
      }
    })
  }

  agregarRecargoPlan() {
    console.log( this.arrayPreInfoServiciosPlan);
    this.arrayPreInfoServiciosPlan.map(res => {
      res.recargo = res.recargoDominical + this.recargo;
      res.recargoTransporte = this.recargoTransporte
    })
    this.calcularPrecioPlan();
  }
  
  agregarDescuentoPlan() {
    console.log(this.arrayPreInfoServiciosPlan);
    const descuento = this.tipoDeDescuento == 'porcentual' ? (this.precioPlanReg*this.descuento)/100 : this.descuento 
    this.arrayPreInfoServiciosPlan.map(res => {
      res.descuento = descuento > 0 ? descuento/this.arrayPreInfoServiciosPlan.length : descuento;
      console.log(res.descuento)
    })
    this.calcularPrecioPlan();
  }
  
  calcularPrecioPlan(){
    let total_surcharge = 0;
    let total_transportation_surcharge = 0;
    let total_service_price = 0;
    const services = _.orderBy(this.arrayPreInfoServiciosPlan,['fecha'],['asc']);
    for(let service of services){
      total_surcharge += service.recargo;
      total_transportation_surcharge += service.recargoTransporte;
    }
    
    console.log( this.tipoDeDescuento == 'porcentual' && this.descuento > 0, this.tipoDeDescuento , this.descuento)
    this.precioPlanReg = this.precioIndividualReg * this.arrayPreInfoServiciosPlan.length
    let descuento = this.tipoDeDescuento == 'porcentual' && this.descuento > 0 ? ((this.precioPlanReg*this.descuento)/100) : this.descuento;
    this.final_plan_price = (this.precioPlanReg + total_surcharge + total_transportation_surcharge - descuento) + this.totalValueExtras;
    this.editRecargo = false;
    this.loadingTablaPlan = false;
    this.flippedPlanes = true;
  }


  verificarEstadoPago(plazoPago) {
    this.arrayPreInfoServiciosPlan = _.orderBy(
      this.arrayPreInfoServiciosPlan,
      ["fecha"],
      ["asc"]
    );
    if (plazoPago == "pago adelantado") {
      let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha;
      this.planEnReagendamiento.fechaLimitePago = moment(primeraFecha)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.planEnReagendamiento.plazoDePago = "Pago adelantado";
      this.planEnReagendamiento.estadoPago =
        this.tipoAgendamiento == "Plan Individual" ? "Aceptada" : "Pago";
      console.log(
        this.planEnReagendamiento.plazoDePago,
        this.planEnReagendamiento.fechaLimitePago
      );
    } else {
      let a = parseInt(plazoPago);
      console.log(this.arrayPreInfoServiciosPlan);
      let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha;
      this.planEnReagendamiento.fechaLimitePago = moment(primeraFecha)
        .add(a, "days")
        .format("YYYY-MM-DD");
      this.planEnReagendamiento.plazoDePago = `A ${a} días`;
      this.planEnReagendamiento.estadoPago = "Pendiente";
      this.comprobanteReg = "falta";
      console.log(
        this.planEnReagendamiento.plazoDePago,
        this.planEnReagendamiento.fechaLimitePago
      );
    }
  }
  cargarComprobanteReagendamiento(event) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;
    this.image = true;
    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      this.subirImgReagendamiento();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public subirImgReagendamiento() {
    this.ServicioService.putPicture(this.pictureName, this.file)
      .then((value) => {
        if (value.state == "success") {
          this.ServicioService.getURL(this.pictureName).subscribe((url) => {
            this.comprobanteReg = url;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  limitarDescuento() {

    if (this.tipoDeDescuento == 'porcentual' && this.descuento > 99) this.descuento = 0;
    else if(this.descuento === null) this.descuento = 0;
    this.agregarDescuentoPlan();
    
  }

  contadorServiviciosCreados = 0;
  reagendarServiciosDelPlan() {
    let contador = 0;
    this.contadorServiviciosCreados = 0
    this.arrayPreInfoServiciosPlan.forEach((element) => {
      let servicio: any = {
        asesor: this.usuarioEnSecion.displayName,
        planAsociado: this.planEnReagendamiento.id,
        checkList: (this.diasSelccionadoPlan.filter(res=>res.diaInt == parseInt(moment(element.fecha).format("d")))[0].taskList).filter(res=>res.seleccionado == true),
        client: this.planEnReagendamiento.client,
        comprobante: this.comprobanteReg,
        creado: "admin",
        calificacion: 'falta',
        destination: this.planEnReagendamiento.destination,
        dia: element.dia,
        direccion: this.planEnReagendamiento.destination.direccion,
        estado: "Aceptado",
        estadoPago: this.planEnReagendamiento.estadoPago,
        fecha: element.fecha,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.planEnReagendamiento.fechaLimitePago,
        formaDePago: this.metodoPagoReg,
        hora: element.hora + ":00",
        horario: element.horario,
        horasDeServicio: parseInt(this.horasDeServicioReg),
        id: element.id,
        idServer:
          this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual" ? this.serverSelectReg.id : "",
        // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
        novedad: false,
        numFactura: 'Falta',
      
        referenciaPago: this.referenciaPagoReg,
        server: this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual" ? this.serverSelectReg : "",

        categoria: this.categoriaReg,
        servicio: this.servicioReg == '' ? this.planEnReagendamiento.servicio : this.servicioReg,
        subCategoria: this.subCategoriaReg == '' ? this.planEnReagendamiento.subCategoria : this.subCategoriaReg,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: "Plan Mensual",
        precio: element.precio,
        recargo: element.recargo,
        recargoTransporte: element.recargoTransporte,
        descuento: element.descuento,
        total: element.precio + element.recargo + element.recargoTransporte - element.descuento,
        userid: this.planEnReagendamiento.client.id,
        subasta: false,
        estadoPagoServer: "Pendiente",
        gananciaServer: element.gananciaServer,

        gananciaServ: element.precio + element.recargo - element.gananciaServer,
        bonoSeguridadSocial: true,
        bonoPuntualidad_App: true,
        
        aceptaRemplazos: this.aceptaRemplazos,
      };
      console.log("Datos del servicio que guarda", servicio);
      this.ServicioService.registerServicio(servicio).then((res) => {
        console.log("ServicioGuardado");
        this.contadorServiviciosCreados++;
        if (this.contadorServiviciosCreados == this.arrayPreInfoServiciosPlan.length) {
          this.loadingTablaPlan = false;
          this.loaderReagendandoPlan = false;
          Swal.fire("Re-agendado", "Has logrado reagendar un plan con exito", "success"
          ).then((res) => {
            const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
            element.click();
            this.all = true;
            this.getPlanes('', '');
          });
        }
        // Swal.fire("Agendado","El servicio a sido agendado","success");
      });
    });
  }

  async reagendarPlan() {
    this.loaderReagendandoPlan = true
    this.loadingTablaPlan = true

    if ((this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual") && this.serverSelectReg == null) {
      this.loaderReagendandoPlan = false
      this.loadingTablaPlan = false
      Swal.fire('Espera', `Selecciona una server antes de continuar`)
    } else {

      if (this.planEnReagendamiento.cantidadReagendanientos) {
        this.planEnReagendamiento.cantidadReagendanientos = this.planEnReagendamiento.cantidadReagendanientos + 1;
      } else {
        this.planEnReagendamiento.cantidadReagendanientos = 1;
      }

      if (this.planEnReagendamiento.historialServicios === undefined) {
        this.planEnReagendamiento.historialServicios = [];
      }
      this.arrayPreInfoServiciosPlan.forEach((info) => {
        this.planEnReagendamiento.historialServicios.push(info);
      });

      let plan: any = {
        id: this.planEnReagendamiento.id,
        asesor: this.planEnReagendamiento.asesor,
        ejecutivoCuenta: this.usuarioEnSecion.displayName,
        cantidadReagendanientos: this.planEnReagendamiento.cantidadReagendanientos,
        cantidadServicios: this.arrayPreInfoServiciosPlan.length,
        categoria: this.categoriaReg,
        client: this.planEnReagendamiento.client,
        comprobanteActual: this.comprobanteReg,
        correoPorVencer: false,
        creado: "admin",
        calificacion: 'falta',
        checkList: await this.createTaskListPlan(),
        destination: this.planEnReagendamiento.destination,
        direccion: this.planEnReagendamiento.destination.direccion,
        enviarCorreo: true,
        // department: this.direccionServicio.department,
        estado: this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual" ? "Activo" : "Orden de plan",
        estadoConfirmacion: "Aceptado",
        estadoPago: this.planEnReagendamiento.estadoPago,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaInicio: this.arrayPreInfoServiciosPlan[0].fecha,
        fechaLimitePago: this.planEnReagendamiento.fechaLimitePago,
        plazoDePago: this.planEnReagendamiento.plazoDePago,
        fechaReagendamiento: moment().format("YYYY-MM-DD"),
        fechaVencimiento: this.arrayPreInfoServiciosPlan[this.arrayPreInfoServiciosPlan.length-1].fecha,
        formaDePago: this.metodoPagoReg,
        frecuencia: this.frecuenciaReg,
        horasDeServicio: parseInt(this.horasDeServicioReg),
        horaDeInicio: this.arrayPreInfoServiciosPlan[0].hora,
        infoServicio: this.arrayPreInfoServiciosPlan,
        numeroFactura: 'Falta',
        // pagoDosCuotas:
        recurrente: "si",
        referenciaPago: this.referenciaPagoReg,
        server: this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual" ? this.serverSelectReg : '',
        idServers:this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual" ? this.serverSelectReg.id : "",
        serverName:
          this.tipoAgendamiento == "server" || this.tipoAgendamiento == "serverActual"
            ? this.serverSelectReg.name + " " + this.serverSelectReg.apellido
            : "Orden de plan",
        servicio: this.servicioReg == '' ? this.planEnReagendamiento.servicio : this.servicioReg,
        serviciosConsumidos: 0,
        serviciosRestantes: this.cantidadServiciosReg,
        subCategoria: this.subCategoriaReg == '' ? this.planEnReagendamiento.subCategoria : this.subCategoriaReg,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: "Plan Mensual",
        descuento: this.descuento,
        precio: this.precioPlanReg,
        precioIndividual: this.precioIndividualReg,
        recargo: this.recargo * this.arrayPreInfoServiciosPlan.length,
        recargoTransporte: this.recargoTransporte * this.arrayPreInfoServiciosPlan.length,
        total: this.final_plan_price,
        userid: this.planEnReagendamiento.client.id,
        usuario: `${this.planEnReagendamiento.client.name} ${this.planEnReagendamiento.client.apellido}`,
        totalServicios: this.cantidadServiciosReg,
        diasDeServicio: [],
        aceptaRemplazos: this.aceptaRemplazos,
        idInfoPago: ''
      };

      if(this.productList.length > 0){
        plan.extras = this.productList;
        plan.valorExtras = this.totalValueExtras;
      }

      if (plan.client.serversAgendadas == undefined) {
        plan.client.serversAgendadas = ["1"];
      }
  
      plan.estadoPostventa = "postventa pendiente";
      plan.fechaPostventa = moment(this.arrayPreInfoServiciosPlan[0].fecha).add(1, "days").format("YYYY-MM-DD");
      
      let diasPlanRecurente = [];
      let diasPlan = [];
      for(let day of this.diasSelccionadoPlan){
        diasPlanRecurente.push(day.diaString);
        diasPlan.push({ dia: day.diaInt });
      }
      const newPlanRecurrente = {
        id: plan.id,
        horas: plan.horasDeServicio,
        horasDeServicio: plan.horasDeServicio,
        clientId: plan.client.id,
        clienteName: `${plan.client.name}  ${plan.client.apellido}`,
        horaInicio: this.arrayPreInfoServiciosPlan[0].hora,
        diasAgendado: diasPlanRecurente,
        numServicios: plan.cantidadServicios,
        precioServicio: plan.precioIndividual,
        idServerAsignada: this.tipoAgendamiento == 'server' ? plan.server.id : '',
        serverName: this.tipoAgendamiento == 'server' ? `${plan.server.name}  ${plan.server.apellido}` : '',
        fechaDeInicio: plan.fechaInicio,
        fechaDeVencimiento: null,
      }

      plan.diasDeServicio = diasPlan
      const idInfoPago = await this.crearPagoPlan(plan);
      plan.idInfoPago = idInfoPago;
      this.ServicioService.updatePlan(plan).then(async (res) => {
        if (this.tipoAgendamiento == "server") {
          const arrayServers = plan.client.serversAgendadas.filter( (server) => server == plan.idServers );
          let nuevaServerFavorita = {
            foto: plan.server.foto,
            id: plan.idServers,
            nombreServer: plan.serverName
          }
          plan.client.serverFavorita = nuevaServerFavorita
          if (arrayServers.length == 0) {
            plan.client.serversAgendadas.push(plan.idServers);
          }
          this.actualizarServersCliente(plan.client);
        }
        // await this.agregarMultiplesPagosAlHistorial(plan);
        // await this.crearPagoPlan(plan,'null');
        this.planRecurrenteService.post(newPlanRecurrente);
        this.reagendarServiciosDelPlan();
      });
    }
  }
  // Funcion para guaradar en el cliente la nueva server que se le asigno
  actualizarServersCliente(cliente) {
    this.usuarioService.updateClient(cliente);
  }

  crearPagoUsuario(info) {
    let clienteId = this.planEnReagendamiento.client.id;
    console.log("id que busca", clienteId);
    let infoDelPago = {
      total: info.total,
      precio: info.precio,
      recargo: info.recargo,
      descuento: info.descuento,
      estadoDelPago: info.estadoPago,
      horas: info.horasDeServicio,
      tipoServicio: info.tipoServicio,
      factura: info.numeroFactura,
      comprobante: info.comprobanteActual,
      numeroServicios: info.cantidadServicios,
      idPlanOServicio: info.id,
      fechaAgendamiento: info.fechaReagendamiento,
    };
    this.ServicioService.getPagosUsuarioByClientId(clienteId).then((res) => {
      console.log("res.docs", res.docs);
      res.docs.forEach((data) => {
        let historialPagos = data.data();
        console.log("historial de pagos", historialPagos);
        historialPagos.cobrosRealizados.unshift(infoDelPago);
        this.ServicioService.guardaruevoCobro(historialPagos, true).then(
          (res) => {
            console.log("el cobro fue subido");
          }
        );
      });
    });
  }

  public crearPagoPlan(info){
    return new Promise(async resolve=>{
      let cobro = {
        id: '',
        horas: info.horasDeServicio,
        precio: info.precio,
        recargo: info.recargo,
        recargoTransporte: info.recargoTransporte,
        descuento: info.descuento,
        total: info.total,
        tipoServicio: info.tipoServicio,
        factura: "",
        comprobante: info.comprobanteActual,
        estadoDelPago: info.estadoPago,
        numeroServicios: info.cantidadServicios,
        idPlanOServicio: info.id,
        verifidadoEnBanco: false,
        fechaAgendamiento: info.fechaReagendamiento,
        fechaInicio: info.fechaInicio,
        fechaFin: info.fechaVencimiento,
        clientId: info.client.id
      } 
      const id = await  this.ServicioService.addPagoPlanUsuario(cobro);
      resolve(id)
    })
  }

  // public agregarMultiplesPagosAlHistorial(info) {
  //   console.log("Entro en agregarMultiplesPagosAlHistorial")
  //   let idPlan = this.ServicioService.idPlanAgendado;
  //   let cobros = []

  //   this.mesesSelccionadoPlan.forEach((mesInfo: any, index: number) => {
  //     let cobro = {
  //       id: mesInfo.id,
  //       horas: info.horasDeServicio,
  //       precio: mesInfo.precio,
  //       recargo: mesInfo.recargo,
  //       descuento: info.descuento,
  //       total: mesInfo.total,
  //       tipoServicio: info.tipoServicio,
  //       factura: "",
  //       comprobante: index == 0 ? info.comprobanteActual : "",
  //       estadoDelPago: index == 0 ? info.estadoPago : "Pendiente",
  //       numeroServicios: mesInfo.servicosDelMes,
  //       idPlanOServicio: info.id,
  //       verificadoEnBanco: false,
  //       fechaAgendamiento: info.fechaReagendamiento,
  //       fechaInicio: mesInfo.fechaInicio,
  //       fechaFin: mesInfo.fechaFin,
  //       clientId: info.client.id
  //     }
  //     console.log(cobro)
  //     this.ServicioService.postPagoPlanUsuario(cobro)
  //   })
  // }

  public exportExcel() {
    this.PlanService.exportAsExcelFile(this.servicioExport, "Plnes");
  }

  async validarFiltroTabla(event, tipo) {
    console.log(event);
    let even = event.target.value;
    let filter = null;
    if (tipo === "filtro") {
      this.tipoFiltro = even;
      filter = await this.filtrosTablaPlan.filter(
        (filtro) => filtro.name == even
      )[0];
      this.categoriaFiltro = filter["categorias"];
      console.log(this.categoriaFiltro);
    } else if (tipo === "categoria") {
      this.valorFiltro = even;
    }
  }

  verPlanVizualizado() {
    console.log('este es el plan vizualizado', this.planVisualizado);
  }

  filtroPlanRecurrente() {
    let id = '56VnqwDwXhdWQToWbflQMJEMcyB3';
    let fechas = ['lunes', 'martes'];
    let count = 0;
    let mensaje = 'dias';
    let disponible = true;
    console.log("Entro en filtroPlanRecurrente()");
    this.planRecurrenteService.getPlanByIdServer(id).then(res => {
      res.docs.forEach(data => {
        console.log(data.data().diasAgendado);
        for (let fch of fechas) {
          if (data.data().diasAgendado.includes(fch)) {
            console.log('a', fch);
            disponible = false;
            mensaje = mensaje + " " + fch
          } else {
            console.log("b", fch);
          }
        }
        count++
        if (count == res.docs.length) {
          if (!disponible) {
            Swal.fire('warning', `La server ya tiene plan para los ${mensaje}`)
          }
        }
      });
    }).catch(err => {
      console.log('Ocurrio un error,', err);
    })
  }

  // Generar todos los planes recurrentes nuevamente a cada uno de los planes activos,
  //  Primero borrar la coleccion de plan-recurrente
  agendarSer() {
    console.log("Ento en crearPlanRecurrentePlanes()")
    this.dataTodos.forEach(plan => {
      if (plan.estado != "Cancelado" && plan.estado != "Congelado") {
        let diasServicio = [];
        let diasConfirmados = [];
        let contadorDias = 0;
        console.log("Proxomo plan----------------------", plan.infoServicio)
        plan.infoServicio.forEach((element) => {
          let numDia = moment(element.fecha).day();
          let dia = "";
          if (numDia == 0) {
            dia = "domingo";
          } else if (numDia == 1) {
            dia = "lunes";
          } else if (numDia == 2) {
            dia = "martes";
          } else if (numDia == 3) {
            dia = "miercoles";
          } else if (numDia == 4) {
            dia = "jueves";
          } else if (numDia == 5) {
            dia = "viernes";
          } else if (numDia == 6) {
            dia = "sabado";
          }

          let diaAgregado = diasServicio.filter((day) => day == dia);
          console.log(diasServicio, dia, diaAgregado.length)
          if (diaAgregado.length == 0 /* && diaAgregado.length  <= 1 */) {
            diasServicio.push(dia);
            // console.log(diasServicio);
            contadorDias++;
          } else if (diaAgregado.length == 1) {
            diasServicio.push(dia);
            diasConfirmados.push(dia);
            contadorDias++;
          } else {
            contadorDias++;
          }
          // console.log(contadorDias,plan.infoServicio.length)
          if (contadorDias == plan.infoServicio.length) {
            let planRecurrente = {
              id: plan.id,
              horas: plan.horasDeServicio,
              clientId: plan.client.id,
              clienteName: `${plan.client.name}  ${plan.client.apellido}`,
              horaInicio: plan.infoServicio[0].hora == undefined ? plan.horaDeInicio : plan.infoServicio[0].hora,
              diasAgendado: diasConfirmados,
              numServicios: plan.cantidadServicios,
              horasDeServicio: plan.horasDeServicio,
              precioServicio: plan.precioIndividual,
              idServerAsignada: plan.server.id,
              serverName: `${plan.server.name}  ${plan.server.apellido}`,
            };
            // console.log(plan,planRecurrente.diasAgendado)
            this.planRecurrenteService.post(planRecurrente).then(() => {
              console.log("A");
            })
          }
        });
      }
    });

  };

  ajuste_asignarVarDiasDeServicio() {
    this.dataTodos.forEach(plan => {
      console.log("Antes del if", plan.diasDeServicio)
      let diasServicio = [];
      let diasConfirmados = [];
      let contadorDias = 0;
      plan.infoServicio.forEach((element) => {
        let numDia = moment(element.fecha).day();
        const filtro = diasServicio.filter((day) => day == numDia);
        console.log(`Filtro del dia ${numDia}`, filtro.length, "Dias donde busca", diasServicio);
        if (filtro.length >= 0 && filtro.length <= 2) {
          diasServicio.push(numDia);
          contadorDias++;
        } else if (filtro.length == 3) {
          diasServicio.push(numDia);
          diasConfirmados.push({ dia: numDia });
          contadorDias++;
        } else {
          contadorDias++;
        }

        if (contadorDias == plan.infoServicio.length) {
          plan.cities = plan.cities == undefined ? 'Bogota' : plan.cities
          plan.diasDeServicio = diasConfirmados
          console.log(plan)
          this.PlanService.updatedAll(plan)
        }
      })
    })
  }

  ajuste_cambioDeAgente() {
    // alexa y andrea a santiago
    this.dataTodos.forEach(res => {
      if (res.destination.dapartment) {
        if (res.destination.department === "Cundinamarca") {
          res.asesor = 'Leidy Carolina Barrero Briñez';
          this.PlanService.updatedAll(res)
        } else if (res.destination.department === "Antioquia") {
          res.asesor = 'Laura Rippe';
          this.PlanService.updatedAll(res)
        } else {
          console.log("Departamento diferente", res.destination)
        }
      } else {
        if (res.destination.cities === "Bogota") {
          res.asesor = 'Leidy Carolina Barrero Briñez';
          this.PlanService.updatedAll(res)
        } else if (res.destination.cities === "Medellin") {
          res.asesor = 'Laura Rippe';
          this.PlanService.updatedAll(res)
        } else {
          console.log("Departamento diferente", res.destination)
        }
      }
    })
  }

  // public revisarPagoParaReagendamiento(){
  //   let count = 0;
  //   this.planesParaReagendar.forEach( data => {
  //     if(data.estadoPago !== "Pago"){
  //       Swal.fire(
  //         "Espera",
  //        `Antes de reagendar el plan previo debe estar pago,si ya lo esta sube el comprobante ${data.usuario}`
  //       );
  //     }else{
  //       count++
  //     } 
  //     if(count == this.planesParaReagendar.length){
  //       this.prediccionesReagendamientoAutomatico();
  //       console.log('entro a prediccionesReagendamientoAutomatico');
  //     }
  //   })
  // }

  // prediccionesReagendamientoAutomatico(){
  //   Swal.fire(
  //     "Espera",
  //    "Estamos reagendando los planes seleccionados"
  //   );
  //     let idAnterior = [];
  //     this.planesSinDiasPraPrediccion = []
  //     this.planesParaReagendar.forEach(async data=>{
  //       let contador = 0;
  //       if (contador < 50){
  //         contador++
  //         const plan = data;
  //         // console.log("Plan previo",plan);
  //         if(plan.diasDeServicio.length > 0){
  //           console.log("id pan que paso",plan.id,plan.estado);
  //           const fechaFinal = plan.fechaVencimiento;
  //           const diaFinal   = moment(fechaFinal).day();
  //           // console.log("Variable dias de servicio",plan.diasDeServicio)

  //           const arrayDias  = _.orderBy(plan.diasDeServicio,["dia"], ["desc"]);
  //           // console.log(arrayDias,plan.diasDeServicio.length);
  //           let proximaFecha: any;
  //           let ultimaFecha = new Date();
  //           let nuevasFechas = [];
  //           let count = 0;
  //           await arrayDias.forEach((resDia)=> {
  //              // console.log("a",plan.infoServicio[i].fecha)
  //              plan.infoServicio[count].fecha;
  //              ultimaFecha = plan.infoServicio[count].fecha;
  //              let nexDate = new Date();
  //              if(plan.fechaVencimiento <= moment().format('YYYY-MM-DD')){
  //               console.log("Fechas muy antiguas");
  //               nexDate.setDate(nexDate.getDate() + (resDia.dia - 7 - nexDate.getDay()) % 7);
  //               ultimaFecha = nexDate
  //               // console.log("Nuevo fecha para momento actual",resDia,nexDate)
  //              }
  //              for(let a = 1; a < 5;a++){
  //                 proximaFecha = moment(ultimaFecha).add((a*7),'days').format('YYYY-MM-DD');
  //                 let idGenerado = new Date().getTime();
  //                 // console.log("Id generado antes de validar",idGenerado)
  //                 let filtro = idAnterior.filter(res=>res==idGenerado)
  //                 do {
  //                   filtro = idAnterior.filter(res=>res==idGenerado)
  //                   idGenerado = filtro.length > 0 ? idGenerado+a : idGenerado
  //                 } while (filtro.length > 0);
  //                 console.log("Asi queda",idGenerado,moment(proximaFecha).format("YYYY-MM-DD"));
  //                 this.pruebasIdS.push(idGenerado);
  //                 idAnterior.push(idGenerado);
  //                 nuevasFechas.push({
  //                  fecha: moment(proximaFecha).format("YYYY-MM-DD"),
  //                  id: idGenerado,
  //                  // idServicios: new Date().getTime(),
  //                  estado:"Pendiente",
  //                  dia: moment(proximaFecha).format("dddd"),
  //                  gananciaServer: plan.infoServicio[0].gananciaServer, 
  //                  hora:  plan.infoServicio[0].hora,
  //                  horario: plan.infoServicio[0].horario,
  //                  recargo: 0,
  //                  precio: plan.precioIndividual,
  //                  total: plan.precioIndividual,
  //                })
  //              }
  //             count++;     
  //           })
  //           nuevasFechas = _.orderBy(nuevasFechas,['fecha'],['asc']);
  //           console.log("nuevas fechas", nuevasFechas,plan.infoServicio);
  //           // console.log(Math.abs(moment().diff(moment(nuevasFechas[0].fecha),'days')) );
  //           // Dejar el numero del if en 7 
  //           if(Math.abs(moment().diff(moment(nuevasFechas[0].fecha),'days')) <= 15){
  //             // console.log("Plan con fechas para reagendamiento");
  //             this.revisarNuevasFechasReagebdamientoAutomatico(plan,nuevasFechas,plan.idServers)
  //           }
  //         }else{
  //          console.log(plan.usuario)
  //           this.planesSinDiasPraPrediccion.push(plan.usuario)
  //         }
  //       }
  //     })
  // }

  // revisarNuevasFechasReagebdamientoAutomatico(plan,fechas,idServer){
  //   console.log("entro en revisarNuevasFechas()")
  //   this.ServicioService.getProximosServicisoServer(idServer,moment().format('YYYY-MM-DD')).then(res=>{
  //     if(res.docs.length > 0){
  //       let contador = 0;
  //       res.docs.forEach(data=>{
  //         const servicio = data.data();
  //         const filtro = fechas.findIndex(fch=>fch.fecha == servicio.fecha)
  //         if(filtro === -1){
  //           contador++
  //         }else{
  //           console.log("Ajustando fecha",servicio.fecha,fechas)
  //           contador++
  //           let a = fechas[filtro].fecha
  //           let filtro2: any;
  //           let filtro3: any;
  //           let b = 0;
  //           do {
  //            /*  fechas[filtro].fecha */ a = moment(a).add(7,'days').format('YYYY-MM-DD')
  //             console.log(a)
  //             filtro3 = fechas.findIndex(fecha=>fecha.fecha == a)
  //             console.log(filtro3)
  //             b++
  //           } while (filtro3 !== -1 || b == 10 );
  //         }
  //         console.log("contador revisor de fechas",contador,res.docs.length);
  //         if(contador ===  res.docs.length ){
  //           // console.log("Server disponible",fechas)
  //           this.ReagendarPlanAutomatico(plan,fechas) //Descomentar
  //         }
  //       })
  //     }else{  
  //       // console.log("TotalmenteDisponible",fechas)
  //       this.ReagendarPlanAutomatico(plan,fechas) //Descomentar
  //     }
  //   })
  // }

  // ReagendarPlanAutomatico(planEnReagendamiento,fechas) {
  //   console.log("Entro en reagendar plan")
  //   if (planEnReagendamiento.cantidadReagendanientos) {
  //     planEnReagendamiento.cantidadReagendanientos = planEnReagendamiento.cantidadReagendanientos + 1;
  //   } else {
  //     planEnReagendamiento.cantidadReagendanientos = 1;
  //   }

  //   fechas = _.orderBy(fechas,["fecha"],["asc"])
  //   fechas.forEach((info) => {
  //     planEnReagendamiento.historialServicios.push(info);
  //   });
  //   let plan: any = {
  //     id: planEnReagendamiento.id,
  //     idNotificacion: new Date().getTime(),
  //     diasDeServicio: planEnReagendamiento.diasDeServicio,
  //     asesor: planEnReagendamiento.asesor,
  //     areAgendador: "Automatico",
  //     cities: planEnReagendamiento.cities,
  //     cantidadReagendanientos: planEnReagendamiento.cantidadReagendanientos,
  //     cantidadServicios: fechas.length,
  //     categoria: planEnReagendamiento.categoria,
  //     checkList: planEnReagendamiento.checkList,
  //     client: planEnReagendamiento.client,
  //     comprobanteActual: /* Por definir */"",
  //     comprobantePagoHistorial:/* Por definir */"",
  //     correoPorVencer: false,
  //     creado: "admin--",
  //     descuento: planEnReagendamiento.descuento,
  //     destination: planEnReagendamiento.destination,
  //     direccion: planEnReagendamiento.destination.direccion,
  //     enviarCorreo: true,
  //     estado: "Activo",
  //     estadoConfirmacion: "Aceptado",
  //     estadoPostventa: "postventa pendiente",
  //     estadoPago: /* Por definir */"Pendiente",
  //     fechaCreacion: moment().format("YYYY-MM-DD"),
  //     fechaInicio: fechas[0].fecha,
  //     fechaLimitePago: /* Por definir */"",
  //     plazoDePago: planEnReagendamiento.plazoDePago,
  //     fechaReagendamiento: moment().format("YYYY-MM-DD"),
  //     fechaVencimiento: "", // se ajusta aotmaticamentre al cargar la tabla de planes
  //     formaDePago: planEnReagendamiento.formaDePago,
  //     frecuencia: planEnReagendamiento.frecuencia,
  //     horasDeServicio: planEnReagendamiento.horasDeServicio,
  //     idLocation: "4fZC8McSlOvpPfIZnG7w",
  //     historialServicios: planEnReagendamiento.historialServicios,
  //     infoServicio: fechas,
  //     // neighborhood:  this.direccionServicio.neighborhood,
  //     numeroFactura: /* Por definir */"",
  //     observaciones: planEnReagendamiento.destination.observaciones,
  //     precio: planEnReagendamiento.precio,
  //     precioIndividual: planEnReagendamiento.precioIndividual,
  //     recurrente: "si",
  //     referenciaPago: /* Por definir */"",
  //     server: planEnReagendamiento.server,
  //     idServers: planEnReagendamiento.idServers,
  //     serverName: planEnReagendamiento.serverName,
  //     servicio: planEnReagendamiento.servicio,
  //     serviciosConsumidos: 0,
  //     serviciosRestantes: fechas.length,
  //     subCategoria: planEnReagendamiento.subCategoria,
  //     tipoServicio: "Plan Mensual",
  //     recargo: planEnReagendamiento.recargo,
  //     total: planEnReagendamiento.precioIndividual * fechas.length,
  //     userid: planEnReagendamiento.client.id,
  //     totalServicios: fechas.length,
  //   };
  //   let a = Math.abs( 
  //     moment(fechas[0].fecha)
  //     .diff(fechas[fechas.length - 1].fecha,"days")
  //   );
  //   plan.fechaPostventa = moment(fechas[0].fecha).add(a / 2, "days").format("YYYY-MM-DD");
  //   console.log("Informacion final para reagendar el plan", plan)


  //   this.ServicioService.setPlan(plan).then(() => {
  //     console.log("Plan reagendado",plan.id) 
  //     this.reagendamientoAutomatico(planEnReagendamiento,fechas);
  //   }).catch(err=>{
  //     console.log("Ocurrio un error al reagendar el plan",err)
  //   });

  //   // this.servicioService.setPlanPrueba(plan).then((res) => {
  //   //   console.log("Plan reagendado",plan.id) 
  //   // });
  //   // this.validarPagoPlan(plan)
  // }

  // async reagendamientoAutomatico(plan,fechas) {
  //   // console.log(`Entor en reagendarServicios() ${plan.infoServicio.length}` ,fechas)
  //   let contador = 0;
  //   console.log(fechas.length, plan.infoServicio.length)
  //   plan.infoServicio.forEach(async (element) => {
  //     if(contador < fechas.length){ 
  //       // let nuevoServicio = await this.generarServicio(plan,contador,fechas[contador],element)
  //       let servicio: any = {
  //         asesor: plan.asesor,
  //         planAsociado: plan.id,
  //         categoria: plan.categoria,
  //         checkList: plan.taskListFinal,
  //         // cities: this.direccionServicio.cities,
  //         client: plan.client,
  //         comprobante: "",
  //         // country: this.direccionServicio.country, //Ya esta en destination
  //         creado: "admin--",
  //         // department: this.direccionServicio.department, //Ya esta en destination
  //         destination: plan.destination,
  //         dia: element.dia,
  //         direccion: plan.destination.direccion,
  //         estado: "Aceptado",
  //         estadoPago: "Pendiente",
  //         fecha: fechas[contador].fecha,
  //         fechaCreacion: moment().format("YYYY-MM-DD"),
  //         fechaLimitePago: /* Por definir */"",
  //         formaDePago: /* Por definir */"",
  //         hora: element.hora + ":00",
  //         horario: element.horario,
  //         horasDeServicio: parseInt(plan.horasDeServicio),
  //         id: fechas[contador].id,
  //         idServer: plan.idServers,
  //         // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
  //         novedad: false,
  //         numFactura: /* Por definir */"",
  //         observaciones: plan.destination.observaciones,
  //         precio: plan.precioIndividual,
  //         recargo: element.recargo ? element.recargo : 0,
  //         referenciaPago: /* Por definir */"",
  //         server: plan.server,
  //         servicio: plan.servicio,
  //         subCategoria: plan.subCategoria,
  //         // tipoPropiedad: this.direccionServicio.tipoPropiedad,
  //         tipoServicio: "Plan Mensual",
  //         total: element.precio + element.recargo,
  //         userid: plan.client.id,
  //         subasta: false,
  //         estadoPagoServer: "Pendiente",
  //         gananciaServer: element.gananciaServer,
  //         gananciaServ: element.precio + element.recargo - element.gananciaServer,
  //       };
  //       contador++
  //       console.log(contador,servicio.id)
  //       // Descomentar para que sse creen el la base de datos
  //       // console.log(servicio.id)
  //       this.ServicioService.registerServicio(servicio).then(() => {
  //         console.log("Servicio agendado");
  //         this.toastrService.show('Success', `Servicio creado`,{ position: NbGlobalPhysicalPosition.TOP_RIGHT});
  //       });
  //     }else{
  //       console.log("Pasa aotro servicio" ,contador,fechas[contador] )
  //     }
  //   });
  // }



  ///////////// Reagendamiento a multiples meses ///////////////////
  public diasSelccionadoPlan = [];
  public copiaDiasSelccionadoPlan = [];
  public mesesSelccionadoPlan = [];
  public arrayMeses = [];
  public fechasPlan = [];
  calcularMesesSiguentes() {
    for (let i = 0; i < 7; i++) {
      const m = moment().add(i, 'months').format('MMMM')
      const mI = moment().add(i, 'months').format('M')
      this.arrayMeses.push({
        name: m,
        index: mI
      })
    }
    console.log(this.arrayMeses)
    // @ts-ignore
    this.dialogService.open(this.mdlReagendar, {
      context: "this is some additional data passed to dialog",
    });
    this.loadingTablaPlan = false;
  }
  
  seleccionariDiasPlan(i, tipo) {
    let index = this.diasSelccionadoPlan.findIndex(res => res.diaInt == i)
    console.log("Index dias", index)
    let diaString = ""
    if (i == 0) {
      diaString = "domingo"
    } else if (i == 1) {
      diaString = "lunes"
    } else if (i == 2) {
      diaString = "martes"
    } else if (i == 3) {
      diaString = "miercoles"
    } else if (i == 4) {
      diaString = "jueves"
    } else if (i == 5) {
      diaString = "viernes"
    } else if (i == 6) {
      diaString = "sabado"
    }
    if (index == -1) {
      if (tipo == 'paraVisualizar') {
        this.diasSelccionadoPlan.push({
          hora: this.planRecurrente.horaInicio,
          diaInt: i,
          dia: diaString,
          diaString: diaString,
        })
        this.diasSelccionadoPlan = _.orderBy(this.diasSelccionadoPlan, ['diaInt'], ['asc'])
        this.copiaDiasSelccionadoPlan.push({
          hora: this.planRecurrente.horaInicio,
          diaInt: i,
          dia: diaString,
          diaString: diaString,
        })
        this.copiaDiasSelccionadoPlan = _.orderBy(this.copiaDiasSelccionadoPlan, ['diaInt'], ['asc'])
      } else {
        /* Para reagendar */
        let a = JSON.stringify(this.clientTaskList)
        this.diasSelccionadoPlan.push({
          hora: 6,
          diaInt: i,
          diaString: diaString,
          taskList: JSON.parse(a),
          selected:  this.diasSelccionadoPlan.length == 0 ? true : false,
          taskListHours: 0,
          taskListAlert: '',
        })
        this.diasSelccionadoPlan = _.orderBy(this.diasSelccionadoPlan, ['diaInt'], ['asc'])
        console.log(this.diasSelccionadoPlan);
      }
    } else {
      this.diasSelccionadoPlan.splice(index, 1)
    }
    this.mesesSelccionadoPlan = [];
    console.log(this.diasSelccionadoPlan)
    if (tipo == 'visualizandoPlan') {
      this.valorarFrecuencia();
    }
  }

  abilitasBtnModificarDias = false
  validarDiasPlanVisualizado() {
    let a = false;
    if (this.diasSelccionadoPlan.length == this.copiaDiasSelccionadoPlan.length) {
      this.diasSelccionadoPlan.forEach((element, index) => {
        if (element.diaInt != this.copiaDiasSelccionadoPlan[index].diaInt) {
          a = true;
        };
        if (index == this.diasSelccionadoPlan.length - 1) {
          this.abilitasBtnModificarDias = a
        }
      });
    } else {
      this.abilitasBtnModificarDias = true;
    }
  }
  seleccionarMesesPlan(indexMes, indexArray) {
    this.valorarFrecuencia();
    let index = this.mesesSelccionadoPlan.findIndex(res => res.mes == indexMes)
    console.log("Index planes", index, indexArray)

    if (index == -1) {
      this.mesesSelccionadoPlan.push({
        mes: indexMes,
        indexArray: indexArray,
        precio: 0,
        total: 0,
        id: new Date().getTime(),
        precioIndividual: 0,
        fechaInicio: "",
        fechaFin: "",
        serviciosVinculados: [],
      })
    } else {
      this.mesesSelccionadoPlan.splice(index, 1)
    }
    console.log(this.mesesSelccionadoPlan)
  }

  cssDiaSeleccionado(i,type): string {
    // console.log(this.planVisualizado.diasDeServicio[i])
    const result = type == 1 
    ? this.diasSelccionadoPlan.findIndex(res => res.diaInt == i)
    : type == 2
      ? (this.diasSelccionadoPlan[i].selected == true ? 1 : -1)
      : (i == this.daySelectedTaskList ? 1 : -1);
    if (result == -1) {
      return "div-diasPlan"
    } else {
      return "div-diasPlanActive" 
    }
  }


  cssMesPlanes(i): string {
    // console.log("Propiuedadpara  ngClase",i)
    const result = this.mesesSelccionadoPlan.findIndex(res => res.mes == i)
    if (result == -1) {
      return "div-diasPlan"
    } else {
      return "div-diasPlanActive"
    }
  }
  seleccionarHoraDiasPLan(i, event) {
    console.log(i, event)
    console.log(this.diasSelccionadoPlan)
    const index = this.diasSelccionadoPlan.findIndex(res => res.diaInt == i)
    if (event != '') {
      const info = event.target.value;
      this.diasSelccionadoPlan[index].hora = info
    }
    return this.diasSelccionadoPlan[index].hora
  }


  elegirHora(tipo,i) {
    let hora = parseInt(this.horaInicioPlanReg);
    if (tipo == "general") {
      if (hora <= 14)  this.horarioReg = "mañana";
      else if (hora > 14)  this.horarioReg = "tarde";
  
    } else if (tipo == "servicio") {
      if (hora <= 14)  this.arrayPreInfoServiciosPlan[i].horario = "mañana";
      else if (hora > 14) this.arrayPreInfoServiciosPlan[i].horario = "tarde";
    }

    this.getPrice();
  }
  async getPrice(){
    console.log(this.priceList,this.horasDeServicioReg)
    try{
      const info = this.priceList.filter(res=>res.horas == this.horasDeServicioReg)[0];
      this.infoValoresPrecio = this.priceList.filter(res=>res.horas == this.horasDeServicioReg)[0];
      console.log(this.infoValoresPrecio);
      this.precioIndividualReg = parseInt(info.precio);
      this.gananciaServerReg = info.gananciaServer
    }catch(error){
      console.error(error)
    }
  }

  generarFechasPlan() {
    if(this.mesesSelccionadoPlan.length == 0) return;

    this.loadingTablaPlan = true
    let contadorDias = 0;
    this.arrayPreInfoServiciosPlan = [];
    this.mesesSelccionadoPlan.forEach(async (res: any) => {
      console.log("mes--", res.mes, "indexMes--", res.indexArray)
      let mesCalculado = moment().format("YYYY-M-D")
      if (res.indexArray > 0) {
        mesCalculado = moment().add(res.indexArray, "month").startOf("month").format("YYYY-M-D")
      }
      // console.log("Mes a calcular",mesCalculado)
      let indicador1 = 0;
      // this.arrayPreInfoServiciosPlan.push(res.mes);
      for (let dia of this.diasSelccionadoPlan) {
        const nextDate = new Date(mesCalculado);
        // console.log("Paso1",nextDate,mesCalculado)
        let a = 0;
        nextDate.setDate(nextDate.getDate() + (dia.diaInt + 7 - nextDate.getDay()) % 7);
        for (let i = 0; i < 5; i++) {
          let fecha = "";
          fecha = moment(nextDate).add((i + a) * 7, 'days').format("YYYY-MM-DD")
          // console.log("despues de la suma",fecha);
          if (moment(fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
            fecha = moment(fecha).add(7, 'days').format("YYYY-MM-DD");
            a = 1;
          }
          let filterFestivos = this.arrayFestivos.filter(res => res.fecha == moment(fecha).format("YYYY-MM-DD"));
          // console.log("Filtro festivos",filterFestivos,moment(fecha).format("YYYY-MM-DD"));
          // console.log(parseInt(moment(fecha).format("M")),res.mes)

          if (parseInt(moment(fecha).format("M")) == res.mes) {
            let idServicio = new Date().getTime()
            /* Precio servicio */
            // let horario = dia.hora > 14 ? "tarde" : "mañana"
            let i = dia.hora > 14 ? 1 : 0
            if(this.horasDeServicioReg == 8 && this.diasSelccionadoPlan.length >= 5 ) {
              this.precioIndividualReg = this.infoValoresPrecio.precioTiempoCompleto;
            }else if(this.horasDeServicioReg == 4 && this.diasSelccionadoPlan.length >= 5 && this.horaInicioPlanReg >= '14'){
              this.precioIndividualReg = this.infoValoresPrecio.precioTiempoCompleto - this.infoValoresPrecio.descuentoTardes;
            }else if(this.horasDeServicioReg == 4 && this.diasSelccionadoPlan.length >= 5 && this.horaInicioPlanReg < '14'){
              this.precioIndividualReg = this.infoValoresPrecio.precioTiempoCompleto;
            }
    
            let infoServicio = {
              fecha: moment(fecha).format("YYYY-MM-DD"),
              id: idServicio,
              idServicios: idServicio,
              hora: this.horaInicioPlanReg,
              idServer: "",
              dia: dia.diaString,
              horario: this.horarioReg,
              precio: this.precioIndividualReg,
              recargoDominical: dia.diaInt == 0 ? 15000 : filterFestivos.length > 0 ? 15000 : 0,
              recargo: dia.diaInt == 0 ? 15000 : filterFestivos.length > 0 ? 15000 : 0,
              recargoTransporte: 0,
              descuento: 0,
              total: 0,
              gananciaServer: this.gananciaServerReg,
              estado: "Pendiente",
              razonRecargo: dia.int == 0 ? 'Recargo dominical y festivo' : (filterFestivos.length > 0 ? 'Recargo dominical y festivo' : "Sin recargo")
            };
            this.arrayPreInfoServiciosPlan.push(infoServicio)
          }
        }
        // console.log(moment(nextDate).format("YYY-MM-DD"))
        contadorDias++
        console.log(contadorDias, this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)

        if (contadorDias == (this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)) {
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["id"], ["asc"])
          console.log("Array de servicios plan", this.arrayPreInfoServiciosPlan)
          if(this.arrayPreInfoServiciosPlan.length == 0){
            this.editRecargo = false;
            this.loadingTablaPlan = false;
            this.flippedPlanes = false;
            Swal.fire('','No quedan fechas disponibles para ese dia en el meses seleccionado','warning');
            return
          }
          this.validarIdServiciosPlan('');
          if (this.tipoAgendamiento == "serverActual") {
            this.validarServerActual();
          }
        }
      }
    })
  }

  validarIdServiciosPlan(tipo) {
    console.log("validarIdServiciosPlan") //todelete
    let i = 1;
    let count = 0
    let idsEnUso = [];
    this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["id"], ["asc"])
    this.arrayPreInfoServiciosPlan.map(info => {
      // console.log(info.id)
      const filtroId = idsEnUso.findIndex(res => res == info.id)
      // console.log(filtroId,info.id)
      if (filtroId == -1) {
        idsEnUso.push(info.id)
      } else {
        info.id = info.id + i;
        info.idServicios = info.id;
        i++
        // console.log('newId',info.id,(i-1))
        idsEnUso.push(info.id)
      }
      count++
      if (count == this.arrayPreInfoServiciosPlan.length) {
        this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["fecha"], ["asc"])
        console.log(this.arrayPreInfoServiciosPlan);
        this.calcularPrecioPlan();
      }
    })
  }

  eliminarServicioDeRegendaminto(index) {
    console.log(index);
    this.arrayPreInfoServiciosPlan.splice(index, 1)
    this.calcularPrecioPlan();
  }


  async modificarDiasPlan() {
    const query = await this.priceService.getspecificField('tipoAgendamiento','Plan');
    this.priceList =  query.docs.map(res=>{
      return {...res.data()}
    })
    this.getPrice();

    this.loadingTablaPlan = true;
    let meses = []
    this.mesesSelccionadoPlan = [];
    let contador = 0;
    let mesActual = moment().subtract(1,'month').format('YYYY-MM')
    this.planVisualizado.infoServicio.forEach(info => {
      let mesEnValidacion = moment(info.fecha).format('YYYY-MM');
      let filtro = meses.filter((mes) => mes == mesEnValidacion);
      console.log(filtro.length, mesEnValidacion, mesActual)
      if (filtro.length == 0 ) {
        let obj = {
          mes: moment(mesEnValidacion).format('MM'),
          indexArray: Math.abs(moment(mesActual).diff(moment(mesEnValidacion), 'months')),
          precio: 0,
          total: 0,
          id: new Date().getTime(),
          precioIndividual: 0,
          fechaInicio: "",
          fechaFin: "",
          serviciosVinculados: [],
        }
        this.mesesSelccionadoPlan.push(obj);
        meses.push(mesEnValidacion)
        contador++;
      } else {
        contador++;
      }
      // console.log(contador,this.planVisualizado.infoServicio.length)
      if (contador == this.planVisualizado.infoServicio.length) {
        console.log("Meses del plan", this.mesesSelccionadoPlan)
        this.generarNuevasFechasPlan();
      }
    })
  }

  async generarNuevasFechasPlan() {
    let contadorDias = 0;
    this.arrayPreInfoServiciosPlan = [];
    console.log("Dias del plan", this.diasSelccionadoPlan);
    this.mesesSelccionadoPlan.forEach(async (res: any) => {
      // console.log("mes--",res.mes,"indexMes--",res.indexArray)
      let mesCalculado = moment(`${moment().format('YYYY')}-${res.mes}-01`).format("YYYY-M-D")
      let indicador1 = 0;
      console.log(mesCalculado)
      for (let dia of this.diasSelccionadoPlan) {
        const nextDate = new Date(mesCalculado);
        console.log('nextDate',nextDate)
        let a = 0;
        nextDate.setDate(nextDate.getDate() + (dia.diaInt + 7 - nextDate.getDay()) % 7);
        for (let i = 0; i < 5; i++) {
          let fecha = "";
          fecha = moment(nextDate).add((i + a) * 7, 'days').format("YYYY-MM-DD")
          console.log('Fecha generada',fecha)
          if (moment(fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
            fecha = moment(fecha).add(7, 'days').format("YYYY-MM-DD")
            a = 1;
          }
          let filterFestivos = this.arrayFestivos.filter(res => res.fecha == moment(fecha).format("YYYY-MM-DD"));

          if (filterFestivos.length == 0) {
            // console.log(fecha,parseInt(moment(fecha).format("M")),res.mes)
            console.log(fecha,parseInt(moment(fecha).format("M")), res.mes)
            if (parseInt(moment(fecha).format("M")) == res.mes && fecha > moment().format('YYYY-MM-DD') ) {
              let idServicio = new Date().getTime();

              let infoServicio = {
                fecha: moment(fecha).format("YYYY-MM-DD"),
                id: idServicio,
                idServicios: idServicio,
                hora: this.horaInicioPlanReg,
                idServer: "", 
                dia: dia.diaString,
                horario: "",
                precio: this.precioIndividualReg,
                recargo: dia.diaInt == 0 ? 10000 : 0,
                total: 0,
                gananciaServer: this.gananciaServerReg,
                estado: "Pendiente",
                razonRecargo: dia.diaInt == 0 ? 'Recargo dominical y festivo' : 'Sin recargo'
              };
              this.arrayPreInfoServiciosPlan.push(infoServicio)
            }
          }
        }
        // console.log(moment(nextDate).format("YYY-MM-DD"))
        contadorDias++
        console.log(contadorDias, this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)

        if (contadorDias == (this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)) {
          console.log("Array de servicios plan", this.arrayPreInfoServiciosPlan)
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["fecha"], ["desc"])
          this.validarIdServiciosPlan('modificarPlan');
        }
      }
    })
  }

  async validarPorcentajeOcupacionServers() {
    const querry = await this.ServerService.Actives();
    for (let i = 0; i < querry.size; i++) {
      const server = querry.docs[i].data();
      const porcentajeOcupacion = await this.validarPlanesserver(this.dataTodos, server)
      this.ServerService.updateByServer({ id: server.id, porcentajeOcupacionSemana: porcentajeOcupacion })
    }
    console.log('listo')
  }

  validarPlanesserver(array_planes, server) {
    return new Promise(async resolve => {
      console.log("Entor en validarPlanesserver()")
      let countPlan = 0;
      let horasOcupada = 0;
      const planesServer = array_planes.filter(plan => plan.idServers == server.id)
      console.log(planesServer.length, "Server--", server.name, "-", server.apellido)
      if (planesServer.length > 0) {
        planesServer.forEach(async (plan) => {
          if (plan.estado !== 'Cancelado' && plan.estado !== 'Congelado') {
            // console.log(plan.diasDeServicio,plan.horasDeServicio,server.name)
            if (plan.diasDeServicio == undefined) {
              plan.diasDeServicio = [];
            }
            console.log(plan.diasDeServicio);
            horasOcupada += (plan.diasDeServicio.length) * plan.horasDeServicio
          }
          countPlan++
          if (countPlan == planesServer.length) {
            const a = (horasOcupada / 48) * 100;
            return resolve(Math.round(a))
          }
        })
      } else {
        return resolve(0)
      }
    })
  }

  modificarProximosServicios() {
    this.loadingTablaPlan = true;
    //TODO que el array de los servicios del plan se eliminen los de los dias qeu no van
    const estados_eliminables = ['Congelado','Cacelado','Orden de plan','Aceptado'];
    const promesasEliminacion = [];
    this.serviciosPlanVisualizado.forEach((res, index) => {
      if (moment(res.fecha).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') || estados_eliminables.some(estado => estado === res.estado)) {
        console.log("servicio a eliminar", res.fecha);
        promesasEliminacion.push(this.ServicioService.delete(res.id));
      }
    });
  
    Promise.all(promesasEliminacion).then(() => {
        console.log("Todos los servicios fueron eliminados.");
        this.generarNuevosServicios();
    })
    .catch(error => {
        console.error("Error al eliminar servicios:", error);
    });
    this.generarNuevosServicios();    
  }

  generarNuevosServicios() {
    const arrayServiciosActuales = _.orderBy(this.planVisualizado.infoServicio, ["fecha"], ["desc"])
    let arrayServciosPrestados = arrayServiciosActuales.filter(res => moment(res.fecha).format('YYYY-MM-DD') <= moment().format("YYYY-MM-DD"))
    console.log('arrayServiciosActuales',arrayServciosPrestados);
    const arrayConSerivciosNuevos = arrayServciosPrestados.concat(this.arrayPreInfoServiciosPlan)
    this.planVisualizado.infoServicio = arrayConSerivciosNuevos;

    console.log(this.planVisualizado.infoServicio);

    this.arrayPreInfoServiciosPlan.forEach((element, index) => {
      let servicio: any = {
        asesor: this.usuarioEnSecion.displayName,
        planAsociado: this.planVisualizado.id,
        categoria: this.planVisualizado.categoria,
        checkList: this.planVisualizado.checkList,
        client: this.planVisualizado.client,
        comprobante: this.planVisualizado.comprobanteActual,
        creado: "admin",
        calificacion: 'falta',
        destination: this.planVisualizado.destination,
        dia: element.dia,
        direccion: this.planVisualizado.destination.direccion,
        estado: 'Orden de plan',
        estadoPago: this.planVisualizado.estadoPago,
        fecha: element.fecha,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.planVisualizado.formaDePago ? this.planVisualizado.formaDePago : this.planVisualizado.creado == 'App' ? this.planVisualizado.fechaCreacion : 'Falta',
        formaDePago: this.planVisualizado.formaDePago ? this.planVisualizado.formaDePago : this.planVisualizado.creado == 'App' ? 'App' : 'Falta',
        hora: element.hora + ":00",
        horario: element.horario,
        horasDeServicio: parseInt(this.horasDeServicioReg),
        id: element.id,
        idServer: '',
        novedad: false,
        numFactura: 'falta',
        precio: element.precio,
        recargo: element.recargo,
        referenciaPago: this.referenciaPagoReg,
        server: '',
        servicio: this.planVisualizado.servicio,
        subCategoria: this.planVisualizado.subCategoria,
        tipoServicio: "Plan Mensual",
        total: element.precio + element.recargo,
        userid: this.planVisualizado.client.id,
        subasta: false,
        estadoPagoServer: "Pendiente",
        gananciaServer: element.gananciaServer,
        gananciaServ: element.precio + element.recargo - element.gananciaServer,
      };
      // console.log(servicio) 

      this.ServicioService.registerServicio(servicio).then(async res => {
        console.log(index, this.arrayPreInfoServiciosPlan.length - 1)
        if (index == this.arrayPreInfoServiciosPlan.length - 1) {
          this.planVisualizado.horasDeServicio = this.horasDeServicioReg
          this.planRecurrente.horas = this.horasDeServicioReg
          this.planVisualizado.horaDeInicio = this.horaInicioPlanReg;
          this.planVisualizado.diasDeServicio = [];
          this.planRecurrente.diasAgendado = [];
          this.diasSelccionadoPlan.forEach(res => {
            this.planVisualizado.diasDeServicio.push({ dia: res.diaInt })
            this.planRecurrente.diasAgendado.push(res.dia);
          })
          console.log(this.planVisualizado, this.planRecurrente);
          this.ServicioService.updatePlan(this.planVisualizado).then(() => console.log('Plan actualizado'))
          this.ServicioService.updatePlanRecurrente(this.planRecurrente).then(() => console.log('plan recurrente actualizado'));
          this.modificarCobrosPlan();
          this.loadingTablaPlan = false;
        }
      })
    });

  }


  async modificarHoraInicioServicios() {
    console.log(this.horaInicioPlanReg)
    let fechaActual = moment().format("YYYY-MM-DD")
    let fechasInvalidas = ''
    let contadorFechasInvalidas = 0;
    let contadorFechas = 0;
    this.serviciosPlanVisualizado.forEach(async servicio => {
      if (servicio.fecha > fechaActual) {
        contadorFechas++
        if (await this.validarDiaServer(servicio.fecha, servicio.idServer)) {
          servicio.hora = `${this.horaInicioPlanReg}:00`;
          console.log(servicio.hora)
          this.ServicioService.updateServicio(servicio)
        } else {
          fechasInvalidas = `${fechasInvalidas} / ${servicio.fecha}`
          contadorFechasInvalidas
        }
      }
    });
    if (contadorFechasInvalidas < contadorFechas) {
      this.planVisualizado.horaDeInicio = this.horaInicioPlanReg;
      this.planRecurrente.horaInicio = this.horaInicioPlanReg;
      await this.ServicioService.updatePlanRecurrente(this.planRecurrente)
      await this.ServicioService.updatePlan(this.planVisualizado).then(() => {
        Swal.fire('Listo', 'La hora de inicio a sido modificada,para visualizar los cambios sal y entra denuevo al plan', 'success');
      })
    } else if (contadorFechasInvalidas > 0) {
      Swal.fire('Espera', `La server de las siguientes fechas ${fechasInvalidas} tiene otro servicio ese dia que inpiode modifir la duracion`, 'warning')
    }
  }
  async modificarDuracionServicios() {
    let fechaActual = moment().format("YYYY-MM-DD")
    let fechasInvalidas = ''
    let contadorFechasInvalidas = 0;
    let contadorFechas = 0;
    let precios: any = await this.getPreciosPlan()
    const filter = precios.filter(res => res.horas == `${this.horasDeServicioReg}`)[0];
    this.serviciosPlanVisualizado.forEach(async servicio => {
      if (servicio.fecha > fechaActual) {
        contadorFechas++
        if (await this.validarDiaServer(servicio.fecha, servicio.idServer)) {
          servicio.horasDeServicio = this.horasDeServicioReg;
          servicio.precio = filter.precio;
          servicio.gananciaServer = filter.gananciaServer;
          this.ServicioService.updateServicio(servicio).then()
        } else {
          fechasInvalidas = `${fechasInvalidas} / ${servicio.fecha}`
          contadorFechasInvalidas
        }
      }
    });
    if (contadorFechasInvalidas < contadorFechas) {
      this.planVisualizado.horasDeServicio = this.horasDeServicioReg;
      this.planRecurrente.horas = this.horasDeServicioReg;
      await this.ServicioService.updatePlanRecurrente(this.planRecurrente)
      await this.ServicioService.updatePlan(this.planVisualizado).then(() => {
        Swal.fire('Listo', 'La duraciona sido modificada', 'success');
      })
    }
    if (contadorFechasInvalidas > 0) {
      Swal.fire('Espera', `La server de las siguientes fechas ${fechasInvalidas} tiene otro servicio ese dia que inpiode modifir la duracion`, 'warning')
    }
  }

  getPreciosPlan(){
    return new Promise(async resolve=>{
      let precios = []
      const query = await this.priceService.getspecificField('tipoAgendamiento','Plan')
      for(let i = 0;i < query.size;i++){
        precios.push(query.docs[i].data());
      }
      return resolve(precios)
    })
  }

  validarDiaServer(fecha, idServer) {
    return new Promise(result => {
      if (idServer == '') {
        result(true);
      } else {
        this.ServicioService.getByServerOnADate(idServer, fecha).then(async res => {
          if (res.docs.length == 1) {
            console.log('Unico servicio para ese dia');
            result(true)
          } else {
            const servicios = res.docs.filter(res => res.data().estadp !== 'Cancelado' && res.data().estado !== 'Congelado')
            if (servicios.length > 1) {
              result(await this.validarMultipleServicioDia(servicios[0].data(), res.docs[1].data()))
            } else {
              console.log('Tenia otros servicios pero congelados o cancelados')
              result(true)
            }
          }
        })
      }
    })
  }

  validarMultipleServicioDia(info1, info2) {
    return new Promise(resolve => {
      let horaI = parseInt(info1.hora)
      let horaF = horaI + parseInt(info1.horasDeServicio) + 1;
      // Hora de inicio y fin del servicio a asignar
      let horaInicio = parseInt(info2.hora);
      let horaFinal = horaInicio + parseInt(info2.horasDeServicio) + 1
      console.log(horaI, horaF, "----", horaInicio, horaFinal)
      if (horaI == horaInicio) {
        resolve(false)
        console.log("horaI = horaInicio");
      } else if (horaInicio >= horaI && horaInicio <= horaF) {
        resolve(false)
        console.log("HoraInicio entra en medio del planRecurrente");
      } else if (horaFinal >= horaI && horaFinal <= horaF) {
        resolve(false)
        console.log("horaFinal entra en medio del planRecuurente");
      } else {
        console.log('Dispopnible el mimo dia')
        resolve(true);
      }
    })
  }

  modificarCobrosPlan() {
    this.historialDeCobros.forEach(cobro => {
      let info = this.mesesSelccionadoPlan.filter(res => moment(res.fechaInicio).format('YYYY-MM') == moment(cobro.fechaInicio).format('YYYY-MM'))[0];
      console.log(info)
      if (info != undefined) {
        cobro.total = info.total
        cobro.precio = info.precio
        cobro.fechaFin = info.fechaFin
        cobro.fechaInicio = info.fechaInicio
        cobro.numeroServicios = info.servicosDelMes
        console.log(cobro)
        this.ServicioService.postPagoPlanUsuario(cobro);
      }
    })
    console.log(this.historialDeCobros);
    this.loadingTablaPlan = false;
    Swal.fire('Listo', 'El plan fue actualizado');
    this.arrayPreInfoServiciosPlan = [];
  }

  ajustarServiciosEliminados() {
    console.log(this.serviciosPlanVisualizado);
    this.planVisualizado.infoServicio.forEach(element => {
      // console.log(element.destination)
      const a = this.serviciosPlanVisualizado.some(res=>res.id == element.id)
      console.log(a,element.id, element.fecha)
      if (element.fecha >= moment().subtract(2,'days').format('YYYY-MM-DD') && !a) {
        console.log(element.fecha,element.id,a)
        let servicio: any = {
          asesor: this.usuarioEnSecion.displayName,
          planAsociado: this.planVisualizado.id,
          categoria: this.planVisualizado.categoria,
          checkList: this.planVisualizado.checkList,
          // cities: this.direccionServicio.cities,
          client: this.planVisualizado.client,
          comprobante: this.planVisualizado.comprobanteActual,
          // country: this.direccionServicio.country, //Ya esta en destination
          creado: "admin",
          // department: this.direccionServicio.department, //Ya esta en destination
          destination: this.planVisualizado.destination,
          dia: element.dia,
          direccion: this.planVisualizado.destination.direccion,
          estado: element.fecha < moment().format('YYYY-MM-DD') ? "Terminado" : "Aceptado",
          estadoPago: this.planVisualizado.estadoPago,
          fecha: element.fecha,
          fechaCreacion: moment().format("YYYY-MM-DD"),
          fechaLimitePago: this.planVisualizado.fechaLimitePago,
          formaDePago: this.planVisualizado.formaDePago,
          hora: element.hora + ":00",
          horario: element.horario,
          horasDeServicio: parseInt(this.planVisualizado.horasDeServicio),
          id: element.id,
          idServer: this.planVisualizado.server.id,
          // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
          novedad: false,
          numFactura: 'falta',
          precio: element.precio,
          recargo: element.recargo,
          referenciaPago: this.referenciaPagoReg,
          server: this.planVisualizado.server,
          servicio: this.planVisualizado.servicio,
          subCategoria: this.planVisualizado.subCategoria,
          // tipoPropiedad: this.direccionServicio.tipoPropiedad,
          tipoServicio: "Plan Mensual",
          total: element.precio + element.recargo,
          userid: this.planVisualizado.client.id,
          subasta: false,
          estadoPagoServer: "Pendiente",
          gananciaServer: element.gananciaServer,

          gananciaServ: element.precio + element.recargo - element.gananciaServer,
        };
        console.log(servicio);
        this.ServicioService.updateServicio(servicio).then(() => console.log('A'))
      }
    })
  }

  habilitarCambiarDireccion() {
    console.log("Entro en habilitarCambiarDireccion")
    this.usuarioService.detail(this.planVisualizado.userid).then(res => {
      this.clienteDelPlanVisualizado = res.data();
      console.log(this.clienteDelPlanVisualizado)
      this.editarDireccion = true
    })
  }

  /* Funcion para cambiar la direcion de un plan y sus servicios futuros */
  guardarCambioDireccion(index) {
    console.log(index);
    if (index !== 'select') {
      this.loadingTablaPlan = true;
      this.planVisualizado.destination = this.clienteDelPlanVisualizado.direccionesArray[index]
      this.planVisualizado.direccion = this.clienteDelPlanVisualizado.direccionesArray[index].direccion;

      console.log(this.clienteDelPlanVisualizado);
      this.ServicioService.updatePlan(this.planVisualizado);
      this.serviciosPlanVisualizado.forEach(element => {
        console.log(element)
        if (element.fecha >= moment().format('YYYY-MM-DD')) {
          element.destination = this.clienteDelPlanVisualizado.direccionesArray[index];
          element.direccion = this.clienteDelPlanVisualizado.direccionesArray[index].direccion;
          this.ServicioService.updateServicio(element).then((res: any) => {
            console.log("servicio actualizado");
            this.editarDireccion = false;
            this.loadingTablaPlan = false;
          });
        }
      });
    }
  }


  adicinarServicioAlPlan() {

    const info = [
      {
        cobroAsociado: 1674752997868,
        dia: "Domingo",
        estado: "Pendiente",
        fecha: "2023-03-13",
        gananciaServer: 58000,
        hora: "8",
        horario: "",
        id: new Date().getTime(),
        idServer: "",
        idServicios: 0,
        precio: 99900,
        razonRecargo: "Sin recargo",
        recargo: 0,
        total: 99900,
      },
      {
        cobroAsociado: 1674752997868,
        dia: "Domingo",
        estado: "Pendiente",
        fecha: "2023-03-13",
        gananciaServer: 58000,
        hora: "8",
        horario: "",
        id: new Date().getTime(),
        idServer: "",
        idServicios: 0,
        precio: 99900,
        razonRecargo: "Sin recargo",
        recargo: 0,
        total: 99900,
      },
    ]

    info.forEach(element => {
      let servicio: any = {
        asesor: this.usuarioEnSecion.displayName,
        planAsociado: this.planVisualizado.id,
        categoria: this.planVisualizado.categoria,
        checkList: this.planVisualizado.checkList,
        client: this.planVisualizado.client,
        comprobante: this.planVisualizado.comprobanteActual,
        creado: "admin",
        destination: this.planVisualizado.destination,
        dia: element.dia,
        direccion: this.planVisualizado.destination.direccion,
        estado: element.fecha < moment().format('YYYY-MM-DD') ? "Terminado" : "Aceptado",
        estadoPago: this.planVisualizado.estadoPago,
        fecha: element.fecha,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.planVisualizado.fechaLimitePago,
        formaDePago: this.planVisualizado.formaDePago,
        hora: element.hora + ":00",
        horario: element.horario,
        horasDeServicio: this.planVisualizado.horasDeServicio,
        id: element.id,
        idServer: this.planVisualizado.server.id,
        novedad: false,
        numFactura: 'falta',
        precio: element.precio,
        recargo: element.recargo,
        referenciaPago: this.referenciaPagoReg,
        server: this.planVisualizado.server,
        servicio: this.planVisualizado.servicio,
        subCategoria: this.planVisualizado.subCategoria,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: "Plan Mensual",
        total: element.precio + element.recargo,
        userid: this.planVisualizado.client.id,
        subasta: false,
        estadoPagoServer: "Pendiente",
        gananciaServer: element.gananciaServer,

        gananciaServ: element.precio + element.recargo - element.gananciaServer,
      };
      element.idServicios = element.id
      this.planVisualizado.infoServicio.push(element)
      this.ServicioService.updateServicio(servicio).then(() => console.log('A'))
    })
    this.ServicioService.updatePlan(this.planVisualizado).then(() => console.log('P'))
  }

  async filtrarServers(type) {
    this.loadingTablaPlan = true;
    console.log(this.serviciosParaCambio)
    const serviciosValidar = type == 'reagendar' ? this.arrayPreInfoServiciosPlan : this.serviciosParaCambio
    const servicios: any = await this.calcularDiasServicios(serviciosValidar);
    let info: any = {}
    if (type == 'reagendar') {
      info = {
        cliente: { blackList: this.planEnReagendamiento.client.blackList ? this.planEnReagendamiento.client.blackList : [] },
        destination: this.planEnReagendamiento.destination,
        inicio: this.horaInicioPlanReg,
        duracion: this.horasDeServicioReg,
        fechas: servicios.arrayFechas,
        dias: servicios.arrayDias,
        idPlan: this.planEnReagendamiento.id,
        servicio: this.subCategoriaReg,
      }
    } else if (type == 'editar') {
      info = {
        cliente: { blackList: this.clienteDelPlanVisualizado.blackList ? this.clienteDelPlanVisualizado.blackList : [] },
        destination: this.planVisualizado.destination,
        inicio: this.planVisualizado.horaDeInicio,
        duracion: this.planVisualizado.horasDeServicio,
        dias: servicios.arrayDias,
        fechas: servicios.arrayFechas,
        idPlan: this.planVisualizado.id,
        servicio: this.planVisualizado.subCategoria,
      }
    }else{
      this.loadingTablaPlan = false;
      return;
    }

    const infoValidada = await this.convertirNaNyNullAString(info)
    if (infoValidada.destination.lat == 0 || infoValidada.destination.lng == 0) {
      Swal.fire('Espera', 'Los campos de la direccion estan incompletos porfavor actualiza la direccion y asigna al plan', 'warning')
      this.loadingTablaPlan = false;
    } else {
    console.log('Informacion',infoValidada);
		this.servBackendService.post('api/serv/get_servers_avalible', infoValidada).subscribe(res => {
			console.log('Res api/serv/get_servers_avalible', res);
			if (res.success) {
        const data =  _.orderBy(res.data, [
          'filtroObstructor',
          (item) => {
            switch (item.estado) {
              case 'Directa':
                return 0;
              case 'Sepernumeraria':
                return 1;
              case '':
                return 2;
              default:
                return 3; // Manejo de caso inesperado
            }
          },
        ], "asc");
				this.listaServersConRazonBloqueo = data;
        this.filteredOptions = data;
				this.copiaServersAsignables = data;
				this.loadingTablaPlan = false;
			} else {
				this.loadingTablaPlan = false;
				Swal.fire('Error', 'Ocurrio un problema porfavor intentalo de nuevo', 'warning')
			}
		})
    }
  }

  calcularDiasServicios(fechas) {
    return new Promise(resolve => {

      let diasServicio = [];
      let fechasS = [];
      fechas.forEach(element => {
        let numDia = moment(element.fecha).day();
        let dia = "";
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }
        fechasS.push({ fecha: element.fecha, id: element.id })
        let diaAgregado = diasServicio.filter((day) => day == dia);
        if (diaAgregado.length == 0) diasServicio.push(dia);
      })

      resolve({ arrayFechas: fechasS, arrayDias: diasServicio })
    })
  }

  convertirNaNyNullAString(obj: any): any {
    for (const clave in obj) {
      if (typeof obj[clave] === 'object' && obj[clave] !== null) {
        obj[clave] = this.convertirNaNyNullAString(obj[clave]); // Llamada recursiva para objetos anidados
      } else if (isNaN(obj[clave]) || obj[clave] === null) {
        obj[clave] = String(obj[clave]);
      }
    }
    return obj;
  }

  ajustarPlanesRecurrentes() {
    if (this.dataTodos.length > 0) {
      this.PlanService.getAllPlanesRecurentes().then(res => {
        console.log(res.docs.length)
        res.docs.forEach(data => {
          const id = data.data().id
          const filter = this.dataTodos.filter(res => res.id == id)[0]
          if (filter == undefined) {
            this.PlanService.eliminarPlanRecurrente(id).then(() => console.log('a'))
          } else {
            console.log(filter.estado)
            if (filter.estado == 'Cancelado' || filter.estado == 'Congelado' || filter.estado == 'Suspendido') {
              this.PlanService.eliminarPlanRecurrente(id).then(() => console.log('a'))
            }
          }
        })
      })
    }
  }



  cambiarHoraInicio() {
    this.planVisualizado.horaDeInicio = '13:00'
    this.serviciosPlanVisualizado.forEach(element => {
      this.ServicioService.updateServicioTerminado({ id: element.id, hora: '13:00' }).then(() => console.log('B'))
    });
    this.ServicioService.updatePlan(this.planVisualizado).then(() => console.log('A'))
  }


  /* ------------ New tabs whit angularMaterial -------------*/
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  loading = true;
  selecte_table = 'orden';
  filter_table = 'all';
  tableDisplayedColumns = {
    column: ['actions', 'estado', 'usuario', 'serverName', 'cities', 'cantidadServicios', 'estadoPago', 'fechaInicio', 'fechaVencimiento', 'diasDeServicioString', 'creado'],
    title: ['Acciones', 'Estado', 'Cliente', 'Server', 'Ciudad', 'Servicios', 'Estado del pago', 'Fecha inicio', 'Fecha finalizacion', 'Dias agendados', 'Creado']
  }
  cities_planes = [];
  tableDataSource: MatTableDataSource<any>;
  // Funcion que filtra en la tabla los registros con datos que cincidan con lo escrito



  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.tableDataSource)
  }

  onFilterCity(value: string) {
    let f_value = value.trim().toLowerCase();
    let f_array = JSON.parse(JSON.stringify(this.dataTodos))
    let n_array = f_array.filter(e => {
      let c = e.cities ? e.cities.trim().toLowerCase() : "";
      console.log(c);
      return c == f_value
    })
    this.tableDataSource = new MatTableDataSource(n_array);
    console.log(this.tableDataSource)
  }

  changeTable(table) {
    switch (table) {
      case 'orden':
        this.tableDataSource = new MatTableDataSource(this.dataOrdenPlanes);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'activo':
        this.tableDataSource = new MatTableDataSource(this.dataActivos);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'reagendar':
        this.tableDataSource = new MatTableDataSource(this.dataPorVencer);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'suspendido':
        this.tableDataSource = new MatTableDataSource(this.dataSuspendidos);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'desactivado':
        this.tableDataSource = new MatTableDataSource(this.dataDesactivados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'congelado':
        this.tableDataSource = new MatTableDataSource(this.dataCongelados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'todo':
        this.tableDataSource = new MatTableDataSource(this.dataTodos);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
    }
  }

  async actualizarPlanes() {
    const today = moment().format("YYYY-MM-DD");
    const querry = firebase.firestore().collection("plan");
    const task = await querry.get();
    task.forEach(async (element) => {
      const plan = element.data();
      if (plan.estado != "Congelado" && plan.estado != "Cancelado" && plan.estado != "Suspendido") {
        plan.infoServicio = _.orderBy(plan.infoServicio, ["fecha"], ["asc"]);
        // console.log(plan.infoServicio.length)
        plan.fechaVencimiento = plan.infoServicio[(plan.infoServicio.length - 1)].fecha;
        const indexResolveDates = plan.infoServicio.findIndex(res => res >= today)
        let diffDays = moment(plan.fechaVencimiento).diff(today, "days", true);
        if (diffDays <= 0) {
          plan.estado = "Completado";
        } else if (diffDays > 0 && diffDays <= 7) {
          plan.estado = "Por vencer";
        } else if (diffDays > 7) {
          plan.estado = 'Activo';
        }
        let estadoPago: any = await this.getpagosUsuariosPlan(plan.id);
        const dataForUpdate = {
          fechaVencimiento: plan.fechaVencimiento,
          estado: plan.estado,
          estadoPago: estadoPago.estadoPago,
          numeroFactura: estadoPago.estadoPago,
          totalServicios: plan.infoServicio.length,
          serviciosConsumidos: indexResolveDates + 1,
          serviciosRestantes: plan.infoServicio.length - indexResolveDates - 1,
        }
        firebase.firestore().doc("plan/" + plan.id).update(dataForUpdate).then(() => console.log('Actualizado'))
      }
    });
  };

  async getpagosUsuariosPlan(id) {
    const querry = await firebase.firestore().collection("pagosUsuarios").where("idPlanOServicio", "==", id).get()
    let dataForUpdate = {
      estadoPago: '',
      numeroFactura: '',
    }
    for (let i = 0; i < querry.size; i++) {
      const info = querry.docs[i].data();
      if (moment().day() > 26 && moment(info.fechaInicio).format("MM-YYYY") == moment().add(1, 'month').format("MM-YYYY")) {
        console.log("Cobro Proximo mes");
        dataForUpdate.estadoPago = info.estadoDelPago
        dataForUpdate.numeroFactura = info.factura
        break;
      } else if (moment().day() < 26 && moment(info.fechaInicio).format("MM-YYYY") == moment().format("MM-YYYY")) {
        console.log("Cobro actual");
        dataForUpdate.estadoPago = info.estadoDelPago
        dataForUpdate.numeroFactura = info.factura
        break;
      }
    }
    return dataForUpdate

  }

  /* Autocomplete  servers */

  filteredOptions = [];
  activeListOptionServers = false;
  serverSeleccionada = {};
  
  toggleAutocompleteList(){
    this.activeListOptionServers = false;
  }

  onClickAutocomplete(event: Event,type: any): void {
    event.stopPropagation();
    this.toggleAutocompleteList();
    this.updataeStateAutocompletes(type,true,null);
  }

  onInputAutocomplete(event: any,key: any,type: string){
    let value = event.target.value ? event.target.value : ''
    console.log(key)
    this.filteredOptions = this.listaServersConRazonBloqueo.filter((res: any) =>
      res[key].toLowerCase().includes(value.toLowerCase())
    );
    console.log(this.filteredOptions)
    this.updataeStateAutocompletes(type,true,null);
  }

  onSelectAutocomplete(value: any,type: string): void {
    this.updataeStateAutocompletes(type,false,value);
  }

  updataeStateAutocompletes(type: string,state: boolean,value: any){
    console.log(value,type,state)
    switch (type){
      case 'nombreServer':
      this.activeListOptionServers = state;
      if (value != null && value.filtroObstructor == '') {
        this.serverSeleccionada = value;
        if(value.estado == 'Supernumeraria'){
          Swal.fire({
            title: "Estas seguro(a)?",
            text: "La server seleccioada al plan es nueva",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continuar",
          }).then((result) => {
            console.log(result)
            if (result.value) {
              console.log(value);
              this.asignarNuevaServer(value);
            }
          });
        }
        console.log(value);
        this.serverSeleccionada = value;
        this.asignarNuevaServer(value);
      } else {
        console.log('Bloqueada', value)
      }
      break;
    }
  }

  daytoSting(i){
    if(i == 0){
      return 'D'
    }else if(i == 1){
      return 'L'
    }else if(i == 2){
      return 'M'
    }else if(i == 3){
      return 'X'
    }else if(i == 4){
      return 'J'
    }else if(i == 5){
      return 'V'
    }else if(i == 6){
      return 'S'
    }else{
      return 'NaN'
    }
  }

  exportarCronograma(){
    this.helper.exportCronograma(this.planVisualizado.checkList);
  }

  calcWidthGridDias() {
    return `calc(100% / ${this.diasSelccionadoPlan.length})`;
  }


  validarclientesConPlanActivo(){
    const uniqueArray = this.dataTodos.filter((value, index, self) => 
      index === self.map(obj => obj.userid).indexOf(value.userid)
    );

    const dataForExport = uniqueArray.map(element => {
      const data = {
        correo: element.client.email
      }
      return data;
    });
    this.PlanService.exportAsExcelFile(dataForExport, "ClientesConPlan");
  }

}
