import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { truncate } from 'fs/promises';

import * as _ from 'lodash';
import * as moment from 'moment';
import Swal, { SweetAlertType } from 'sweetalert2';
import { IMyDateRangeModel } from 'mydaterangepicker';
import { NbToastrService, NbDialogService } from '@nebular/theme';
// SERVICIOS \\
import { PlanService } from 'src/app/services/plan.service';
import { ServicioService } from 'src/app/services/servicio.service'
import { UsuarioService } from 'src/app/services/usuario.service'
import { AuthService } from 'src/app/services/auth.service'
import { count, filter,finalize } from 'rxjs/operators';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { isThisSecond } from 'date-fns';
import { data } from 'jquery';
import { promise } from 'protractor';
import { AnyRecord } from 'dns';



@Component({
  selector: 'app-facturacion-cartera',
  templateUrl: './facturacion-cartera.component.html',
  styleUrls: ['./facturacion-cartera.component.scss']
})

export class FacturacionCarteraComponent implements OnInit {

  @ViewChild('infoParaPlan',     { static: true }) infoParaPlan:     ElementRef;
  @ViewChild('infoSoloCobro',    { static: true }) infoSoloCobro:    ElementRef;
  @ViewChild('infoParaServicio', { static: true }) infoParaServicio: ElementRef;
  angularFireUploadtask: AngularFireUploadTask;
  angularFireStorageRef: AngularFireStorageReference;

  
  tipoFechaFiltro = "Creacion";


  listaClientes = [];
  dataPlanes_Todos = [];
  dataPlanes_PagoPendiente = [];
  dataPlanes_PagoRealizado = [];
  dataServicios_Todos = [];
  dataServicios_PagoPendiente = [];
  dataServicios_PagoRealizado = [];
  historialDeCobros = [];


  settingsChartPagosPlan = {
    actions: {
      add:    false,
      edit:   false,
      delete: false,
      filter: false,
      custom: [
        { name: 'detalle', title: '<i class="nb-compose"></i>' },
        { name: 'verComprobante', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      clienteName: { width:'10%', title: 'Cliente',},
  //  plazoDePago: { width:'10%', title: 'Plazo del pago'},
      fechaInicio: { width:'10%', title: 'Fecha inicio'},
      fechaFin:    { width:'10%', title: 'Fecha fin'},
      factura:     { width:'10%', title: 'Numero de factura'},
      comprobante: { width:'10%', title: 'Comprobante',
                      valuePrepareFunction: (comprobante) => {
                        if(comprobante == "falta" || comprobante == "Falta" || comprobante == '' ){
                          return 'Falta'
                        }else{
                          return 'Subido';
                        }
                      }
                    },
      total:     { width:'10%', title: 'Valor total'},
      precio:    { width:'10%', title: 'Valor inicial'},
      recargo:   { width:'10%', title: 'Valor recargo'},
      descuento: { width:'10%', title: 'Valor decuento'},
      // precioIndividual: { width:'10%', title: 'Precio invividual'},
    },
        rowClassFunction: (row) => {
          if (row.data.estado == "Por vencer") {
            return 'colorNaranja';
          } else {
            return '';
          }
        },
  };
  settingsChartServicios = {
    actions: {
      add:    false,
      edit:   false,
      delete: false,
      filter: false,
      width:'5%',
      custom: [
        { name: 'detalle', title: '<i class="nb-compose"></i>' },
        { name: 'verComprobante', title: '<i class="nb-search"></i>' },
        // { name: 'verificarPago', title: '<i class="nb-checkmark-circle"></i>' },
      ],
    },
    columns: {
      nombreCliente:    { width:'10%', title: 'Cliente'},
      estadoPago:       { width:'10%', title: 'Estado del pago'},
      fechaLimitePago:  { width:'10%', title: 'Fecha limite de pago'},
      formaDePago:      { with: '10%', title: 'forma de pago'},
      numFactura:       { width:'10%', title: 'Numero de factura'},
      comprobante:      { width:'10%', title: 'Comprobante',
                          valuePrepareFunction: (comprobante) => {
                            if(comprobante == "falta" || comprobante == "Falta" || comprobante == ""){
                              return `Falta`
                            }else{
                              return `Subido`;
                            }
                          } 
                        },
      total:     { width:'10%', title: 'Valor total'},
      precio:    { width:'10%', title: 'Valor inicial'},
      recargo:   { width:'10%', title: 'Valor recargo'},
      descuento: { width:'10%', title: 'Valor decuento'},
    },
  }



  infoPlan: any = {};
  infoServicio: any = {};

  razonCambioCobro = '';
  cobroVisualizado: any = null;
  copiaCobroVisualizado: any = null;
  comprobanteAvisualizar = "";

  constructor(
    private planService: PlanService,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private servicioService: ServicioService,
    private usuarioService: UsuarioService,
    private AngularFireStorage: AngularFireStorage,    
  ){
    this.getAllClientes();
    // this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }
  ngOnInit(){
    // if(this.usuarioEnSecion.id === "vcw6RZPcEDSMrE0l7jibi1oeBcF2"){
    //   this.settingsPlanes.actions.custom.push(
    //     { name: 'verificarPago', title: '<i class="nb-checkmark-circle"></i>' }
    //   )
    // }
    this.getCobrosPlanes();
  } 

  getAllClientes(){
    let source = [];
    let sub = this.usuarioService.getUsersByProperty("estado", "activo").then((res) => {
      res.docs.forEach(data=>{
        const user = data.data();
        user.nombreCliente = `${user.name} ${user.apellido}`
        source.push(user);
        this.listaClientes = source;
      })
    });
  }
  onDateRangeChanged(event: IMyDateRangeModel,tipo) {
    let startDate;
    let endDate;
    const beginDateFormat = moment(event.beginJsDate).format('YYYY-MM-DD');
    const endDateFormat = moment(event.endJsDate).format('YYYY-MM-DD');
    console.log(beginDateFormat + " " + endDateFormat + " " + tipo);
    startDate = beginDateFormat;
    endDate = endDateFormat;
    this.getServicios(startDate, endDate)
  }
  getServicios(startDate,endDate){
    let countServicios: number = 0;
    let data_Todos = [];
    let data_pagoRealizado = [];
    let data_pagoPendiente = [];

    let tipoLlamada: any;
    if(this.tipoFechaFiltro == "Creacion"){
      tipoLlamada =  this.servicioService.getServiciosIndividualesPorFechaCreacion(startDate,endDate)
    }else{
      tipoLlamada = this.servicioService.getServiciosIndividualesPorFecha(startDate,endDate)
    }
    tipoLlamada.then(res=>{
      let lengthServicios = res.docs.length;
      res.docs.forEach(element=>{
        let servicio = element.data();
        servicio.nombreCliente = `${servicio.client.name} ${servicio.client.apellido}`
        data_Todos.push(servicio);
        if(servicio.estadoPago == "Pendiente pago" || servicio.estadoPago == "Pendiente"){
          data_pagoPendiente.push(servicio);
        } else if(servicio.estadoPago == "Pago" || servicio.estadoPago == "Aceptada" ){   
          data_pagoRealizado.push(servicio);
        }
        countServicios++
        if(countServicios == lengthServicios){
          this.dataServicios_Todos         = _.orderBy(data_Todos,         ['fecha'], ['desc']);
          this.dataServicios_PagoRealizado = _.orderBy(data_pagoRealizado, ['fecha'], ['desc']);
          this.dataServicios_PagoPendiente = _.orderBy(data_pagoPendiente, ['fecha'], ['desc']);
          // this.dataServiciosPagosVef = _.orderBy(dataServiciosPagosVef, ['fechaCreacion'],   ['desc']);
        }
      })
    })
  }
  getCobrosPlanes(){
    let data_todos = [];
    let data_pagoPendiente = [];
    let data_pagoRealizado = [];
    this.servicioService.getPagosPlanesTresMesesAtras().then(res=>{
      res.docs.forEach(async (data,index)=>{
        const cobro = data.data();
        data_todos.push(cobro);
        cobro.clienteName = await this.getCliente(cobro.clientId);
        if(cobro.estadoDelPago == 'Pago'){
          data_pagoRealizado.push(cobro);
        }else if(cobro.estadoDelPago == 'Pendiente'){
          data_pagoPendiente.push(cobro);
        }else{
          console.log("Cobros en otro estado de pago",cobro.estado)
        }
        if(index == (res.docs.length-1)){
          this.dataPlanes_Todos = data_todos;
          this.dataPlanes_PagoPendiente = data_pagoPendiente;
          this.dataPlanes_PagoRealizado = data_pagoRealizado;
          console.log(this.dataPlanes_Todos);
          console.log(this.dataPlanes_PagoPendiente);
          console.log(this.dataPlanes_PagoRealizado);
        }
      })
    })
  }
  getCliente(id){
    return new Promise((success) => {
      let filtro = this.listaClientes.filter(res=>res.id == id)
      if(filtro.length > 0){
        const cliente = filtro[0];
        success(`${cliente.name} ${cliente.apellido}`);
      }else{
        success(`NaN - ${id}`);
      }
		});
  }
  
  onCustomPlanes(event){
    // this.infoPlan = {};
    if(event.action == 'detalle'){
      this.getPlan(event.data);
    }else if(event.action == 'verComprobante'){
      this.comprobanteAvisualizar = event.data.comprobante
      console.log(event.data.comprobante,"  ", this.comprobanteAvisualizar)
      setTimeout( ()=> {
        this.verComprobante()
      }, 1000);
    }
  }
  onCustomServicios(event){
    if(event.action == 'detalle'){
      this.infoServicio = event.data;
      this.verInfoParaServicios();
    }
  }
  onCustomPagos(event){
    if(event.action == 'detalle'){
      this.razonCambioCobro = "";
      this.cobroVisualizado = event.data;
      this.copiaCobroVisualizado = {
        precio:event.data.precio,
        recargo:event.data.recargo,
        descuento:event.data.descuento,
      } 
    }else if(event.action == 'verComprobante'){
      this.comprobanteAvisualizar = event.data.comprobante
      console.log(event.data.comprobante,"  ", this.comprobanteAvisualizar)
      setTimeout( ()=> {
        this.verComprobante()
      }, 1000);
    }
  }
  verComprobante(id: string ='verComprobanteTs'){
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  verInfoParaPlanes(){
    this.openModal(this.infoParaPlan);
  }
  verInfoParaServicios(){
    this.openModal(this.infoParaServicio);
  }
  verSoloCobro(){
    this.openModal(this.infoSoloCobro);
  }
  openModal(modal) {
    this.dialogService.open(modal, {
      context: "this is some additional data passed to dialog",
    });   
  }

  getPlan(cobro){
    console.log(cobro);
    this.servicioService.getPlaneById(cobro.idPlanOServicio).then(res=>{
      console.log(res.data())
      if(res.data() != undefined){
        this.infoPlan = res.data();
        let cobrosRelacionados = this.dataPlanes_Todos.filter(cob=>cob.idPlanOServicio == cobro.idPlanOServicio)
        // console.log(cobrosRelacionados);
        this.historialDeCobros = _.orderBy(cobrosRelacionados, ["fechaInicio"], ["desc"])
        // console.log(this.infoPlan);
        this.verInfoParaPlanes();                                                          
      }else{
        this.cobroVisualizado = cobro;
        this.copiaCobroVisualizado = {
          precio:    cobro.precio,
          recargo:   cobro.recargo,
          descuento: cobro.descuento,
        } 
        this.verSoloCobro();
        // Swal.fire('Lo sentimos','El plan asociado a este cobro fue eliminado','warning');
      }
    })
  }

  onUpload(event,tipo,id,cuota) {
    console.log("Entro en onUpLoad",event,tipo,cuota);
    const a = new Date().getTime();
    const filePath = `comprobatesPago${tipo}/${id}/compr${a}`;
    this.angularFireStorageRef = this.AngularFireStorage.ref(filePath);
    this.angularFireUploadtask = this.angularFireStorageRef.put(event.target.files[0]);
    this.angularFireUploadtask.snapshotChanges().pipe(finalize(() => {
      this.angularFireStorageRef.getDownloadURL().subscribe((url) => {
        // this.comprobante = url;
        if(tipo == "Individual"){
          this.infoServicio.comprobante = url;
        }else if(tipo == 'Plan'){
          if(cuota === "primera"){
            this.cobroVisualizado.estadoDelPago = 'Pago';
            this.cobroVisualizado.comprobante = url;
          }
          // else if(cuota === "segunda"){
          //   this.urlSegundaCuota = url
          // }
        }
      });    
    })).subscribe();
  }

  actualizarCobro(){
    console.log(this.copiaCobroVisualizado, this.cobroVisualizado)
    // if(
    //   this.copiaCobroVisualizado.recargo != this.cobroVisualizado.recargo ||
    //   this.copiaCobroVisualizado.precio != this.cobroVisualizado.precio   ||
    //   this.copiaCobroVisualizado.descuento != this.cobroVisualizado.descuento &&
    //   this.razonCambioCobro === "" 
    // ){
    //   const a = document.getElementById("alerta-razoncambio-cobro") as HTMLParagraphElement
    //   a.style.display = "block"
    // }else{
    // }
    // if(this.cobroVisualizado.cambios === undefined){
    //   this.cobroVisualizado.cambios = [];
    // }
    // this.cobroVisualizado.cambios.push({
    //   fecha: moment().format("YYYY-MM-DD"),
    //   descripcion: this.razonCambioCobro,
    // })
    console.log("Guardando factura",this.cobroVisualizado)
    this.servicioService.actualizarCobro(this.cobroVisualizado);
    console.log(moment(this.cobroVisualizado.fechaInicio).format("MM-YYYY"), moment().format("MM-YYYY"))
    if(moment(this.cobroVisualizado.fechaInicio).format("MM-YYYY") == moment().format("MM-YYYY") ){
      this.infoPlan.comprobanteActual = this.cobroVisualizado.comprobante;
      this.infoPlan.estadoPago = this.cobroVisualizado.estadoDelPago;
      this.infoPlan.numeroFactura = this.cobroVisualizado.factura
      this.servicioService.updatePlan(this.infoPlan).then(res=>{
        this.infoPlan.infoServicio.forEach(element=>{
          if(element.fecha >= this.cobroVisualizado.fechaInicio  && element.fecha <=  this.cobroVisualizado.fechaFin){
            let id = element.idServicios ? element.idServicios : element.id;
            this.servicioService.actualizaInfoCobroServicio(id,this.cobroVisualizado.factura, this.cobroVisualizado.estadoDelPag)
          }
        })
      })
    }
    Swal.fire('Listo','El cobro a sid actualizado','success').then(()=>{
      this.cobroVisualizado = {}
    })
  }
  
  actualizarEstadoDelPago(){
    this.dataPlanes_Todos.forEach(element => {
      if(element.comprobante != 'Falta' && element.comprobante != 'falta' && element.comprobante != ''){
        element.estadoDelPago = 'Pago';
        this.servicioService.actualizarCobro(element).then(()=>console.log('A'));
      }
    });
  }



  actualizar(tipo){
    if(tipo == "servicio"){
      if(this.infoServicio.comprobante != "Falta" && this.infoServicio.comprobante != "falta" && this.infoServicio.comprobante != ""){
        this.infoServicio.estadoPago = "Pago";
      }
      this.servicioService.updateServicio(this.infoServicio).then(res=>{
        Swal.fire({
          type: 'success',
          text: 'Se ha actualizado la información',

          showConfirmButton: true,
        });
        // this.actualizarCobro(this.infoServicio)
      })
    }
  }


}




// export class FacturacionCarteraComponent implements OnInit {


//   all: boolean = true;
//   hoyms = moment();
//   hoyForm = moment().format('YYYY-MM-DD')
//   public sourcePlanesTodos:       any = [];
//   public sourcePlanesPagos:       any = [];
//   public sourcePlanesPorPagar:    any = [];
//   public sourcePlanesPagosVef:    any = [];
//   public sourcePlanesPagoVencido: any = [];

//   public sourceServiciosTodos:    any = [];
//   public sourceServiciosPagos:    any = [];
//   public sourceServiciosPorPagar: any = [];
//   public sourceServiciosPagosVef: any = [];

//   public infoPlan: any;
//   public infoServicio: any;

//   public comprobante: string = "Buscar comprbante";
//   public comprobanteAvisualizar: string = "";


//   public historialDeCobros = [];
//   public cobroVisualizado: any = null;
//   public copiaCobroVisualizado: any = null;
//   public razonCambioCobro = "";
//   angularFireStorageRef: AngularFireStorageReference;
//   angularFireUploadtask: AngularFireUploadTask;
//   //valor;descuento;recargo;valorUnitario;usuario;fechaLImite;formaPago;numFactura;telefonoCliente,correoCliente,tipoCliente,direcciontipoIdentificacion
//   settingsPlanes = {
//     actions: {
//       add:    false,
//       edit:   false,
//       delete: false,
//       filter: false,
//       width:'5%', 
//       custom: [
//         { name: 'detallePlan', title: '<i class="nb-compose"></i>' },
//         { name: 'verComprobante', title: '<i class="nb-search"></i>' },

//       ],
//     },
//     columns: {
      
//       nombreCliente:    { width:'10%', title: 'Cliente'},
//       tipoFacturacion:    { width:'10%', title: 'tipoFacturacion'},
//       estadoPago:          { width:'10%', title: 'Estado del pago'},
//       formaDePago:           { with: '10%', title: 'forma de pago'},
//       fechaReagendamiento: { width:'10%', title: 'Fecha del agendaminto'},
//       plazoDePago:         { width:'10%', title: 'Plazo del pago'},
//       fechaLimitePago:     { width:'10%', title: 'Fecha limite de pago'},
//       disasDeVencimiento:  { width:'10%', title: 'Dias vencido',
//                               valuePrepareFunction: (diasVencido) => {
//                                 if(diasVencido > 0){
//                                   return diasVencido
//                                 }else{
//                                   return `0`;
//                                 }
//                               } 
//                            },
//       numeroFactura:    { width:'10%', title: 'Numero de factura'},
//       comprobanteActual:   { width:'10%', title: 'Comprobante',
//                               valuePrepareFunction: (comprobante) => {
//                                 if(comprobante == "falta" || comprobante == "Falta"  ){
//                                   return comprobante
//                                 }else{
//                                   return `Subido`;
//                                 }
//                               } 
//                            },

//       total:     { width:'10%', title: 'Valor total'},
//       precio:    { width:'10%', title: 'Valor inicial'},
//       recargo:   { width:'10%', title: 'Valor recargo'},
//       descuento: { width:'10%', title: 'Valor decuento'},
//       precioIndividual: { width:'10%', title: 'Precio invividual'},
    
// /*    recurrente:       { width:'10%', title: 'Recurrente' },  
//       motivoCancelacion:{ title: 'Motivo de cancelacion'}, */
//     },
//     rowClassFunction: (row) => {
//       if (row.data.estado == "Por vencer") {
//         return 'colorNaranja';
//       } else {
//         return '';
//       }
//     },
//   };
//   settingsServicios = {
//     actions: {
//       add:    false,
//       edit:   false,
//       delete: false,
//       filter: false,
//       width:'5%',
//       custom: [
//         { name: 'detalleServicio', title: '<i class="nb-compose"></i>' },
//         { name: 'verComprobante', title: '<i class="nb-search"></i>' },
//         { name: 'verificarPago', title: '<i class="nb-checkmark-circle"></i>' },
//       ],
//     },
//     columns: {
//       nombreCliente: { width:'10%', title: 'Cliente'},
//       estadoPago:    { width:'10%', title: 'Estado del pago'},
//       tipoServicio:  { width:'10%', title: 'Tipo servicio'},
//       fechaLimitePago:{ width:'10%', title: 'Fecha limite de pago'},
//       formaDePago:   { with: '10%', title: 'forma de pago'},
//       numFactura:    { width:'10%', title: 'Numero de factura'},
//       comprobante:   { width:'10%', title: 'Comprobante',
//                         valuePrepareFunction: (comprobante) => {
//                           if(comprobante == "falta"){
//                             return comprobante
//                           }else{
//                             return `Subido`;
//                           }
//                         } 
//                      },
//       total:     { width:'10%', title: 'Valor total'},
//       precio:    { width:'10%', title: 'Valor inicial'},
//       recargo:   { width:'10%', title: 'Valor recargo'},
//       descuento: { width:'10%', title: 'Valor decuento'},
//       precioIndividual: { width:'10%', title: 'Precio invividual'},
//       /* recurrente:       { width:'10%', title: 'Recurrente' },  
//       motivoCancelacion:{ title: 'Motivo de cancelacion'}, */
//     },
//   }
//   settingsPagosUsuario = {
//     actions: {
//       delete: false,
//       edit: false,
//       add: false,
//       custom: [
//         { name: "verComprobante", title: '<i class="nb-search"></i>' },
//         { name: "verCobro", title: '<i class="nb-compose"></i>' },
//       ],
//     },
//     columns: {
//       comprobante: {
//         title: "Comprobante",
//         valuePrepareFunction: (comprobante) => {
//           if (comprobante == "falta" || comprobante == "Falta" || comprobante == "") {
//             return "Falta";
//           } else {
//             return `Subido`;
//           }
//         },
//       },
//       numeroServicios: {title: "Total servicio"},
//       fechaInicio: { title: "Fecha inicial" },
//       fechaFin: { title: "Fecha final" },
//       estadoDelPago: { title: "Estado del pago" },
//       factura: { title: "Factura" },
//       fechaAgendamiento: { title: "Fecha de creacion" },
//       precio: { title: "Precio" },
//       recargo: { title: "Recargo" },
//       descuento: { title: "Descuento" },
//       total: { title: "Total" },
//     },
//     pager: {
//       perPage: 15,
//     },
//   };

//   urlSegundaCuota = 'Falta'
//   usuarioEnSecion: any;
//   constructor(
//     private planService: PlanService,
//     private authService: AuthService,
//     private dialogService: NbDialogService,
//     private servicioService: ServicioService,
//     private AngularFireStorage: AngularFireStorage,
//   ){
//     this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
//   }

//   ngOnInit(){
//     let b = 4;

//     if(this.usuarioEnSecion.id === "vcw6RZPcEDSMrE0l7jibi1oeBcF2"){
//       this.settingsPlanes.actions.custom.push(
//         { name: 'verificarPago', title: '<i class="nb-checkmark-circle"></i>' }
//       )
//     }
//     this.getPlanes("1","1");
//   }

//   onDateRangeChanged(event: IMyDateRangeModel,tipo) {
//     let startDate;
//     let endDate;
//     const beginDateFormat = moment(event.beginJsDate).format('YYYY-MM-DD');
//     const endDateFormat = moment(event.endJsDate).format('YYYY-MM-DD');
//     console.log(beginDateFormat + " " + endDateFormat + " " + tipo);
//     startDate = beginDateFormat;
//     endDate = endDateFormat;
//     this.all = false
//     if(tipo == "servicios"){
//       this.getServicios(startDate, endDate)
//     } else if(tipo == "planes"){
//       this.getPlanes(startDate, endDate);
//     }
//   }

//   getPlanes(startDate,endDate){
//     let tipoLlamada:any;
//     let count: number = 0;
//     let dataTodos:    any = [];
//     let dataPagos:    any = [];
//     let dataPorPagar: any = [];
//     let dataPagosVef: any = [];
//     let dataPagoVencido: any = [];
//     if (this.all == false) {
//       console.log("entro en planes por fecha");
//       tipoLlamada = this.planService.getPlanRangoFecha(startDate, endDate)
//     } else if (this.all == true) {
//       console.log("entro a todos");
//       tipoLlamada = this.planService.getAllPlan()
//     }
    
//     tipoLlamada.then(res=>{
//       let length = res.docs.length;
//       res.docs.forEach(element => {
//         let plan = element.data();
//         plan.nombreCliente =  `${plan.client.name} ${plan.client.apellido}`;
//         plan.tipoFacturacion = `Mes ${plan.client.plazoFacturacion}`;
//         dataTodos.push(plan);
//         // console.log(plan.estadoPago)
//         if(plan.estadoPago == "Pendiente pago" || plan.estadoPago == 'Pendiente' || plan.estadoPago == 'Pendiente pago'){
//           dataPorPagar.push(plan);
//           let fechaLimite = moment(plan.fechaLimitePago).format('YYYY-MM-DD');
//           // console.log(fechaLimite,this.hoyForm)
//           if(fechaLimite < this.hoyForm){
//             //console.log("plan con pago vencido",plan.fechaLimitePago)
//             plan.disasDeVencimiento = moment(plan.fechaLimitePago).diff(this.hoyForm, 'days', true)
//             dataPagoVencido.push(plan)
//           }
//         } else if(plan.estadoPago == "Pago"){
//           dataPagos.push(plan);
//         } else if(plan.estadoPago == "Pago verificado"){
//           dataPagosVef.push(plan);
//         } else {}
//         count++
//         if(count == length){
//           this.sourcePlanesTodos    = _.orderBy(dataTodos,    ['fechaCreacion'],   ['desc']);
//           this.sourcePlanesPorPagar = _.orderBy(dataPorPagar, ['fechaLimitePago'], ['asc']);
//           this.sourcePlanesPagos    = _.orderBy(dataPagos,    ['fechaCreacion'],   ['desc']);
//           this.sourcePlanesPagosVef = _.orderBy(dataPagosVef, ['fechaCreacion'],   ['desc']);
//           this.sourcePlanesPagoVencido = _.orderBy(dataPagoVencido, ['fechaCreacion'],   ['desc']);
//         }
//       });
//     })
//   }

//   public tipoFechaFiltro: string = "Creacion"
//   getServicios(startDate,endDate){
//     let countServicios: number = 0;
//     let dataServiciosTodos:    any = [];
//     let dataServiciosPagos:    any = [];
//     let dataServiciosPorPagar: any = [];
//     let dataServiciosPagosVef: any = [];    
//     let tipoLlamada: any;
//     if(this.tipoFechaFiltro == "Creacion"){
//       tipoLlamada =  this.servicioService.getServiciosPorFechaCreacion(startDate,endDate)
//     }else{
//       tipoLlamada = this.servicioService.getServiciosSemanaActual(startDate,endDate)
//     }
//     tipoLlamada.then(res=>{
//       let lengthServicios = res.docs.length;
//       res.docs.forEach(element=>{
//         let servicio = element.data();
//         servicio.nombreCliente = `${servicio.client.name} ${servicio.client.apellido}`
//         if(servicio.tipoServicio == "Plan Individual"){
//           dataServiciosTodos.push(servicio);
//           if(servicio.estadoPago == "Pendiente pago" || servicio.estadoPago == "Pendiente"){
//             dataServiciosPorPagar.push(servicio);
//           } else if(servicio.estadoPago == "Pago" || servicio.estadoPago == "Aceptada" ){   
//             dataServiciosPagos.push(servicio);
//           } else if(servicio.estadoPago == "Pago verificado"){
//             dataServiciosPagosVef.push(servicio);
//           }
//         } 
//         countServicios++
//         console.log(countServicios,lengthServicios);
//         if(countServicios == lengthServicios){
//           this.sourceServiciosTodos    = _.orderBy(dataServiciosTodos,    ['fechaCreacion'],   ['desc']);
//           this.sourceServiciosPorPagar = _.orderBy(dataServiciosPorPagar, ['fechaLimitePago'], ['asc']);
//           this.sourceServiciosPagos    = _.orderBy(dataServiciosPagos,    ['fechaCreacion'],   ['desc']);
//           this.sourceServiciosPagosVef = _.orderBy(dataServiciosPagosVef, ['fechaCreacion'],   ['desc']);
//           console.log(this.sourceServiciosTodos)
//         }
//       })
//     })
//   }

//   onCustom(event,tipo){
//     console.log(event.data,tipo ,event.action);
//     if(tipo == 'plan'){
//       // this.infoPlan = {};
//       if(event.action == 'detallePlan'){
//         this.infoPlan = event.data;
//         this.getCobro(this.infoPlan)
//         // if(this.infoPlan.cuota != undefined){
//         // }
//         this.verInfoParaPlanes()
//       }else if(event.action == 'verComprobante'){
//         this.comprobanteAvisualizar = event.data.comprobanteActual
//         console.log(event.data.comprobanteActual,"  ", this.comprobanteAvisualizar)
//         setTimeout( ()=> {
//           this.verComprobante()
//         }, 1000);
//       }else if(event.action == "verificarPago"){
//         if(event.data.estadoPago == "Pago"){
//           event.data.estadoPago = "Pago verificado";
//           setTimeout( ()=> {
//             this.servicioService.updatePlan(event.data).then(res=>{
//               this.Message("Verificado","Se verifico el pago con exito","success");
//               this.infoPlan.infoServicio.forEach(async (element) => {
//                 element.comprobante = this.infoPlan.comprobanteActual;
//                 element.estadoPago = "Pago"
//                 await this.actualizarServicioPlan(element);
//               });
//             })
//           }, 1000);
//         }
//       }else if(event.action == "verCobro" ){
//         this.copiaCobroVisualizado = {}
//         this.razonCambioCobro = "";
//         this.cobroVisualizado = event.data;
//         this.copiaCobroVisualizado ={
//           precio:event.data.precio,
//           recargo:event.data.recargo,
//           descuento:event.data.descuento,
//         } 
//         console.log(this.cobroVisualizado);
//         console.log(this.infoPlan)
//       }
//     }

//     if(tipo == 'servicio'){
//       this.infoServicio = [];
//       if(event.action == 'detalleServicio'){
//         console.log(event.data);
//         this.infoServicio = event.data;
//         this.verInfoParaServicios()
//       }else  if(event.action == 'verComprobante'){
//         this.comprobanteAvisualizar = event.data.comprobante
//         console.log(event.data.comprobanteActual,"  ", this.comprobanteAvisualizar)
//         setTimeout( ()=> {
//           this.verComprobante()
//         }, 1000);
//       } else if(event.action == "verificarPago"){
//         if(event.data.estadoPago == "	Pago"){
//           event.data.estadoPago = "Pago verificado";
//           setTimeout( ()=> {
//             this.servicioService.updateServicio(this.infoServicio).then(res=>{
//               this.Message("Verificado","Se verifico el pago con exito","success");
//             })
//           }, 1000);
//         }
//       }
//     }
//   }

//   verInfoParaPlanes(){
//     this.openModal(this.infoParaPlan);
//   }
//   verInfoParaServicios(){
//     this.openModal(this.infoParaServicio);
//   }
//   public openModal(modal) {
//     this.dialogService.open(modal, {
//       context: "this is some additional data passed to dialog",
//     });
//   }

//   getCobro(plan){
//     console.log(plan)
//     let cobros = [];
//     this.servicioService.getPagosUsuarioByIdPlanOServicio(plan.id).then(res=>{
//       console.log(res.docs.length)
//       if(res.docs.length > 0){
//         res.docs.forEach(data=>{
//           console.log(data.data());
//           cobros.push(data.data())
          
//           this.historialDeCobros = _.orderBy(cobros,["fechaInicio"],["asc"]);
//           // cobros.cobrosRealizados.forEach(element => {
//           //   if(element.fechaAgendamiento == plan.fechaReagendamiento && element.idPlanOServicio == plan.id ){
//           //     this.urlSegundaCuota = element.comprobanteDos
//           //   }

//           // });
//         })
//       }else{
//         this.historialDeCobros = []
//       } 
//     })
//   }
//   actualizarCobro(){ 
//     console.log(this.copiaCobroVisualizado, this.cobroVisualizado)
//     if(
//       this.copiaCobroVisualizado.recargo != this.cobroVisualizado.recargo ||
//       this.copiaCobroVisualizado.precio != this.cobroVisualizado.precio   ||
//       this.copiaCobroVisualizado.descuento != this.cobroVisualizado.descuento &&
//       this.razonCambioCobro === "" 
//       ){
//         const a = document.getElementById("alerta-razoncambio-cobro") as HTMLParagraphElement
//         a.style.display = "block"
//       }else{
//         if( this.cobroVisualizado.cambios === undefined){
//           this.cobroVisualizado.cambios = [];
//         }
//         this.cobroVisualizado.cambios.push({
//           responsable: this.usuarioEnSecion.displayName,
//           fecha: moment().format("YYYY-MM-DD"),
//           descripcion: this.razonCambioCobro,
//         })
//         console.log("Guardando factura",this.cobroVisualizado)
//         this.servicioService.actualizarCobro(this.cobroVisualizado);
//         if(moment(this.cobroVisualizado.fechaInicio).format("M") == moment().format("M") ){
//           this.infoPlan.comprobanteActual = this.cobroVisualizado.comprobante 
//           this.infoPlan.estadoPago = this.cobroVisualizado.estadoDelPago;
//           this.servicioService.updatePlan(this.infoPlan)
//         }
//         Swal.fire('Listo','El cobro a sid actualizado','success').then(()=>{
//           this.cobroVisualizado = {}
//         })
        
//       }
//   }

//   // onUpload(event,tipo,cuota) {
//   //   let file: any;
//   //   let pictureName:any;
//   //   let image = false;
//   //   let nameImage:any;
//   //   let fotoSeleccionada: any;
//   //   console.log("files",event.target.files[0])
//   //   file = event.target.files[0];a
//   //   pictureName = Date.now() + file.name;
//   //   image = true;
//   //   if (event.target.files && file) {
//   //     nameImage = file.name;
//   //     let reader = new FileReader();
//   //     reader.onload = (event: ProgressEvent) => {
//   //       fotoSeleccionada = (<FileReader>event.target).result;
//   //       console.log("foto seeccionada",fotoSeleccionada)
//   //     }
//   //     reader.readAsDataURL(event.target.files[0]);
//   //   }else{console.log("no pasa el primer if")}
//   //   if (file && pictureName) {
//   //     this.servicioService.putPicture(pictureName, file).then(value => {
//   //       console.log("value",value)
//   //       if (value.state == 'success') {
//   //         this.servicioService.getURL(pictureName).subscribe(url => {
        
//   //         }); 
//   //       }
//   //     }).catch(err => {
//   //       console.log(err);
//   //     });
//   //   }else{console.log("no asa el segundo if")}
//   //   console.log(this.comprobante);
//   // }


//   onUpload(event,info,cuota) {
//     console.log("Entro en onUpLoad",event,info,cuota);
//     const dia= new Date().getTime();
//     const filePath = `Novedades/${dia}`;
//     this.angularFireStorageRef = this.AngularFireStorage.ref(filePath);
//     this.angularFireUploadtask = this.angularFireStorageRef.put(event.target.files[0]);
//     this.angularFireUploadtask.snapshotChanges().pipe(finalize(() => {
//       this.angularFireStorageRef.getDownloadURL().subscribe((url) => {
//         this.comprobante = url;
//         if(info == "individual"){
//           this.infoServicio.comprobante = url;
//         }else{
//           if(cuota === "primera"){
//             this.infoPlan.comprobanteActual = url;
//             this.cobroVisualizado.comprobante = url;
//           }else if(cuota === "segunda"){
//             this.urlSegundaCuota = url
//           }
//         }
//       });    
//     })).subscribe();
//     // this.uploadProgress = this.task.percentageChanges();
//     // this.downloadURL = this.ref.getDownloadURL();
//   }


//   // public actualizarCobro(info){
//   //   let comprobante = info.tipoServicio == "Plan Mensual" ? info.comprobanteActual : info.comprobante
//   //   let factura     = info.tipoServicio == "Plan Mensual" ? info.numeroFactura : info.numFactura
//   //   this.servicioService.getPagosUsuarioByIdPlanOServicio(info.id).then(res=>{
//   //     res.docs.forEach(data=>{
//   //       if(info.fechaInicio == data.data().fechaInicio){
//   //         let cobro = data.data();
//   //         console.log(cobro);
//   //         cobro.factura = factura
//   //         cobro.comprobante = comprobante
//   //         cobro.estadoDelPago = info.estadoPago
//   //         if(this.urlSegundaCuota != "Falta"){
//   //           cobro.comprobanteDos = this.urlSegundaCuota;
//   //         }
//   //         this.servicioService.actualizarCobro(cobro);
//   //       }
//   //     })
//   //   })
//   // }

//   public actualizarServicioPlan(servicio) {
//     return new Promise(success => {
//       this.servicioService.updateServicio(servicio).then((res: any) => {
//         success(res);
//         console.log("servicio actualizado");
//       }).catch(err=>{console.log("ocurrio un erro al actualizar el servicio",err)});
//     });
//   }

//   crearPagoUsuario(datos,traeId){
//     console.log("id que busca",datos.client.id)
//     if(datos.tipoServicio == "Plan Mensual"){
//       datos.infoServicio = _.orderBy(datos.infoServicio, ['fecha'], ['desc']);
//       datos.fechaVencimiento = datos.infoServicio[0].fecha;
//       if(datos.recargo){

//       }else{
//         datos.recargo = 0;
//       }
//     }else{
//       datos.fechaInicio = datos.fecha;
//       datos.fechaVencimiento = datos.fecha;
//       datos.cantidadServicios = 1;
//       datos.comprobanteActual = datos.comprobante;
//       datos.numeroFactura = datos.numFactura
//     }
//     if(traeId == true){
//       datos.idPlanOServicio = datos.id
//     }
//      let infoDelPago = {
//       horas:  datos.horasDeServicio,
//       numeroServicios: datos.cantidadServicios,
//       factura: datos.numeroFactura,
//       comprobante: datos.comprobanteActual,
//       precio: datos.precio,
//       descuento: datos.descuento,
//       idPlanOServicio: datos.idPlanOServicio,
//       recargo: datos.recargo,
//       total:  datos.total,
//       fechaInicio: datos.fechaInicio,
//       fechaFin: datos.fechaVencimiento,
//       estadoDelPago: datos.estadoPago,
//       tipoServicio: datos.tipoServicio,
//       cantidadServicios: datos.cantidadServicios,
//     }

//     this.servicioService.getPagosUsuarioByClientId(datos.client.id).then(res=>{
//       if(res.docs.length <= 0 ){
//         console.log("sin histoial");
//        let pagoUsuario = {
//         IdCliente:   datos.client.id,
//         cobrosRealizados: [],
//        }
//       pagoUsuario.cobrosRealizados.push(infoDelPago)
//       console.log("datos para crer un nuevo cobro",pagoUsuario)
//       this.servicioService.postPagosUsuario(pagoUsuario,traeId).then(res=>{
//          console.log("se guardo el cobro")
//        })
//       }else{
//         let count = 0;
//         res.docs.forEach(data=>{
//           let historialPagos = data.data();
//           console.log("historial de pagos",historialPagos)
         
//           historialPagos.cobrosRealizados.map(res=>{
//             if(res.idPlanOServicio == datos.idPlanOServicio ){
//               if(res.fechaInicio == datos.fechaInicio && res.fechaFin == datos.fechaVencimiento){
//                 console.log("Encontro cobro con coincidenia",res);
//                   res.factura       = datos.numeroFactura;
//                   res.comprobante   = datos.comprobanteActual;
//                   res.estadoDelPago  = datos.estadoPago;
//               }
//             }else{
//               count++
//             }
//             if(count ==  historialPagos.cobrosRealizados.length){
//               console.log("no se a registrado el cobro aun",infoDelPago)
//               historialPagos.cobrosRealizados.unshift(infoDelPago)
//               //this.servicioService.guardaruevoCobro(historialPagos,true);
//             }
//           })
//           console.log("lo que va guardar",historialPagos);
//           this.servicioService.actualizarCobro(historialPagos).then(res=>{
//             console.log("el cobro fue subido")
//           })
//         })
//       }
//     })
//   }

//   private Message(
//     title: string, 
//     message: string, 
//     type: SweetAlertType){
//     setTimeout( ()=> {
//       Swal.fire(title, message, type)
//     }, 900);
//   }


//  /*  actualixarfecha(){
//     this.sourcePlanesTodos.forEach(element => {
//      console.log(element.fechaReagendamiento,element.fechaCreacion)
     
//      if(element.fechaReagendamiento == undefined ){
//        console.log("sin fecha de reagendaminto")
//        if(element.id){
//          element.fechaReagendamiento = element.fechaCreacion;
//          console.log("nueva fecha",element.fechaReagendamiento)
//         //  this.servicioService.updatePlan(element).then(res=>{
//         //  console.log("fecha actualizada")
//         //}); 
//        } 
//      }
   
//     });
//   } */

//   verComprobante(id: string ='verComprobanteTs'){
//     console.log("etro e ver comprobante");
//     console.log(this.comprobanteAvisualizar);
//     const element: HTMLElement = document.getElementById(id) as HTMLElement;
//     element.click();
//   }

// }
// crear tabla vencidos, y asignar variable de cuantos dias lleva vencido el pago