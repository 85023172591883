import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ServerService } from "../../../services/server.service";
import { Router, Params } from "@angular/router";

import { LocalDataSource } from "ng2-smart-table";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import * as _ from "lodash";
import { Observable, Subject, observable } from "rxjs";
import { CalendarView, CalendarMonthViewDay } from "angular-calendar";
import { ServicioService } from "src/app/services/servicio.service";
import * as moment from "moment";
moment.locale('es');
import { AuthService } from "src/app/services/auth.service";
import swal, { SweetAlertType } from "sweetalert2";
import { GoogleService } from "src/app/services/google.service";
import { Gps } from "src/app/models/interface/Gps.interface";
import { ServerInterface } from "../../../models/interface/Server.interface";
import Swal from "sweetalert2";
import { Helper } from "src/app/utility/helper";
import { IMyDateRangeModel } from "mydaterangepicker";
import {
  Origin,
  Avatar,
  Genders,
  Children,
  BloodType,
  Nationality,
  LaboralRisk,
  BankAccount,
  DocumentTypes,
  PensionFund,
  HealthAgency,
  Compensation,
  OriginDefault,
  TypeDocuments,
  socialSecurity,
  CardinalPoints,
  ColorMonitoring,
  PropertiesTypeList,
  CalendarResponsive,
  ResetServerInterface,
  habilidades
} from "../../../utility/dataInfo";

import { JSONServerLocalDataSource } from "src/app/models/interface/LocalDataSouce/ServersStatus";
import { Neighborhoodinterface } from "src/app/models/interface/Neighborhoodinterface";
import { departamentos } from "src/app/utility/country";
import { LocationService } from "src/app/services/location.service";
import { productosServers } from "src/app/utility/precios";
import { PlanService } from "src/app/services/plan.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { PlanMensualService } from "src/app/services/plan-mensual.service";
import { ServBackendService } from "src/app/services/serv-backend.service";
import { StorageService } from "src/app/services/storage.service";
import { CandidatoService } from "src/app/services/candidato.service";
import { CodigoReferidoServerService } from "src/app/services/codigo-referido-server.service";

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-modal-server",
  templateUrl: "./modal-server.component.html",
  styleUrls: ["./modal-server.component.scss"],
})
export class ModalServerComponent implements OnInit {
  @ViewChild("mdlCrear", { static: true }) mdlCrear: ElementRef;
  @ViewChild("mdlEdit", { static: true }) mdlEdit: ElementRef;
  @Input() infoServer: any = "falta";
  @Input() tipoModal: any;

  private fireStoreRef: AngularFireStorageReference;

  public certificadoVacunacionC19: any = "falta";
  public frmCreate: FormGroup;
  public frmUpdate: FormGroup;

  public submitForm: boolean;
  public idLocation: string;
  public activePendientes = false;

  public server: Array<Object> = [];
  public serviciosPendientes = [];
  public planPendientes = [];
  public isUpdateidLocation: boolean = false;
  public spinnerTable: boolean = true;
  public spinerModal: boolean = false;
  public SpinnerStatus: string = "primary";
  public SpinnerMessage: string = "Cargando Server...";
  public SpinnerMessageModal: string = "Procesando...";
  public fechaHoy: any = 1;
  public fechaCerca: any = 0;
  public vencidos: Array<String> = [];
  public venciendose: Array<String> = [];
  // Procesamiento de archivo
  public inputFile: any;
  public filenameToRender: any;
  public disabledUpdateBtn: boolean = false;

  public edit: boolean = false;
  public nameImage: string = "";
  public fotoSeleccionada: any;
  public Image: boolean = false;
  public editEstado: boolean = false;
  public editDay: boolean = false;
  public activeDayIsOpen: boolean = true;
  public selectedMonthViewDay: CalendarMonthViewDay;
  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public refresh: Subject<any> = new Subject();
  public listaCalendarioServicio: any[];
  public listaServicios: any[];
  public arrayServicios: Array<Object> = [];
  public daysInWeek = 7;
  private destroy$ = new Subject();
  public servicio: any;
  public clickedDate: Date;
  public clickedColumn: number;
  public selectedDays: any = [];
  public selectedDaysCopia: any = [];
  public arrayDisponibilidad;
  public infoDateCopia: any = [];
  public serverExcel: any = [];
  public servers: any[];
  public calidad1: number = 0;
  public actitud: number = 0;
  public uniforme: number = 0;
  public puntualidad: number = 0;
  public calificacionPromedio: number = 0;
  public placePredictions: any[];

  public hijos: any = Children;
  public origin: Gps = Origin;
  public avatar: any = Avatar;
  public generos: any = Genders;
  public bloodType: any = BloodType;
  public pensionFund: any = PensionFund;
  public compensation: any = Compensation;
  public laboralRisk: any = LaboralRisk;
  public healthAgency: any = HealthAgency;
  public nationality: any = Nationality;
  public originDefault: Gps = OriginDefault;
  public tipoDocumentos: any = TypeDocuments;
  public puntosCardenales: any = CardinalPoints;
  public bankAccounts: any = BankAccount;
  public documentTypes: any = DocumentTypes;
  public cambioDeEstado = false;
  public cambioTipoManejoSeguridadSoscial = false;

  /*_____Direccion_____*/
  public direccionResidencia: any;
  public tipoPropiedades: any = PropertiesTypeList;
  public locationDeparments = departamentos;

  public locationCities = [];
  public locationSuburbs = [];
  public locationNeighborhood: Array<Neighborhoodinterface> = [];

  /*___Articulos de compra___*/
  public productosServers = productosServers;
  public articulosComprados: any = [];
  public deudaArticulos: number = 0;

  /*____Horario____*/
  horasDisponible: number = 0;
  horarioServer = [
    {
      day: 0,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 1,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 2,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 3,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 4,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 5,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
    {
      day: 6,
      hours: 0,
      start_time: '',
      end_time: '',
      time_slot: 'allDay',
    },
  ];
  /*__________Habilidades_________*/
  public habilidades = habilidades;
  public categoriasServicios = {
    Hogares: {
      aseoSerGene: [],
      desi: [],
      asistPer: [],
      cuida: [],
      limPro: [],
      otro: [],
    },
    Empresas: {
      aseoSerGene: [],
      desi: [],
      asistPer: [],
      cuida: [],
      limPro: [],
      otro: [],
    },
  };

  /*_____ExperienciaLaborar_____*/
  agregarExperiencia = false;
  public experiencia = {
    jefe: "",
    fecha: "",
    cargo: "",
    empresa: "",
    funciones: "",
  };

  /*_____Estudios_____ */
  agregarEstudios = false;
  public estudios = {
    fecha: "",
    ciudad: "",
    titulo: "",
    institucion: "",
    departamento: "",
  };

  public tableLocalDataSource: JSONServerLocalDataSource = {
    sourceActive: new LocalDataSource([]),
    lengthActive: 0,
    sourceInactive: new LocalDataSource([]),
    lengthInactive: 0,
  };
  public infoDate: any;
  public isDisabled: boolean = false;
  public datosServerReset: ServerInterface;
  public datosEdit: ServerInterface = ResetServerInterface;
  estadoSeguridadSocial: String;

  constructor(
    private router: Router,
    private helper: Helper,
    private authService: AuthService,
    private planService: PlanService,
    private googleService: GoogleService,
    private ServerService: ServerService,
    private dialogService: NbDialogService,
    private StorageService: StorageService,
    private usuarioService: UsuarioService,
    private locationService: LocationService,
    private ServicioService: ServicioService,
    private candidatoService: CandidatoService,
    private angularFireStorage: AngularFireStorage,
    private servBackendService: ServBackendService,
    private codigoReferidoServerService: CodigoReferidoServerService,
  )
  { 
    const today = moment().format('DD MMMM YYYY')
    console.log(today)
  }

  selectedSkill(skill){
    return this.infoServer.habilidades.includes(skill)
  }

  onSelectSkill(skill_selected){
    const index = this.infoServer.habilidades.findIndex(skill=> skill == skill_selected);
    if (index !== -1) {
      this.infoServer.habilidades.splice(index, 1);
    } else {
      this.infoServer.habilidades.push(skill_selected);
    }
  }
  ngAfterViewInit() {
    if (this.tipoModal === "crear") {
      this.infoServer = {
        habilidades: [],
        experiencia: [],
        estudios: [],
      };
    } else if (this.tipoModal === "editar") {
      this.prepararFormularioEditarServer();
    }
    // @ts-ignore
    this.dialogService.open(this.mdlCrear, {
      context: "this is some additional data passed to dialog",
    });
  }

  ngOnInit() {
    // console.log("info de la server", this.infoServer);
    this.getPlace();
    this.getGps();
  }

  prepararFormularioEditarServer() {
    this.origin = this.infoServer.gps;
    this.tipoModal = "editar";
    this.estadoSeguridadSocial = this.infoServer.estadoSeguridadSocial;
    this.direccionResidencia = this.infoServer.direccionResidencia;
    this.horarioServer = this.infoServer.horario;
    this.horasDisponible = this.infoServer.horasDisponible;
    this.piture(this.infoServer.foto);
    this.selectLocation(this.infoServer.department, 'ciudades');
  }

  async generateRandomCode() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }
  async actualizarServer() {
    this.infoServer.direccionResidencia = this.direccionResidencia.description
      ? this.direccionResidencia.description
      : this.direccionResidencia;
    this.infoServer.horario = this.horarioServer;
    this.infoServer.horasDisponible = this.horasDisponible;
    this.infoServer.gps = this.origin;
    this.infoServer.fechaActualizacion = moment().format("YYYY-MM-DD");
      console.log(this.infoServer);
    if (this.infoServer.estado == "desactivado" && this.cambioDeEstado){
      this.desactivarServer();
    }

    if (this.infoServer.estado == "congelado" && this.cambioDeEstado) {
      const today = moment().format("YYYY-MM-DD");
      this.ServicioService.getByServerFromADate(this.infoServer.id,today).then(res => {
        if (res.docs.length > 0) {
          res.docs.forEach(data => {
            const servicio = data.data();
            if(servicio.estado != "Congelado" && servicio.estado != "Cancelado" && servicio.fecha > moment().format('YYYY-MM-DD') ){
              servicio.server = ''
              servicio.idServer = ''
              servicio.serverName = ''
              servicio.estado = servicio.tipoServicio == 'Plan Mensual' ? 'Orden de plan' : 'Orden de servicio'
              this.ServicioService.updateServicio(servicio).then((result) => {
                console.log('se actualizo el servicio con exito');
              }).catch(err => {
                console.log('error:', err);
              });
            }
          })
        }
      })
    }

    if((this.infoServer.tipoManejoSeguridadSoscial == "directa" || this.infoServer.tipoManejoSeguridadSoscial == "pagoCuotas" ) && this.cambioTipoManejoSeguridadSoscial){
      const a = await this.solicitarInscripcionSs();
      if(this.infoServer.tipoManejoSeguridadSoscial == "directa") this.infoServer.estadoSeguridadSocial = 'Por pagar';
    }

    if (this.infoServer.estado == "activo" && this.cambioDeEstado) {
      this.infoServer.serviciosTerminados = 0;
      this.candidatoService.delete(this.infoServer.id).catch(err => console.log('error al eliminar aspirante', err))
        if(!this.infoServer.fechaIngreso) this.infoServer.fechaIngreso = moment().format('YYYY-MM-DD')
    }

    if(this.infoServer.tipoContrato == "directo"){
      if(!(await this.codigoReferidoServerService.detail(this.infoServer.id)).exists){
        let isUnique = false
        let uniqueCode = ''
        while (!isUnique) {
          uniqueCode = await this.generateRandomCode();
          if ((await this.codigoReferidoServerService.getByCode(`serv-${uniqueCode}`)).size == 0 ) {
            isUnique = true;
          }
        }
        const data = {
          id: this.infoServer.id,
          idServer: this.infoServer.id,
          createDate: moment().format('YYYY-MM-DD'),
          referredCode: `serv-${uniqueCode}`,
        }
        await this.codigoReferidoServerService.set(data);
      }
    }

    if (this.estadoSeguridadSocial !== this.infoServer.estadoSeguridadSocial && this.infoServer.estadoSeguridadSocial == 'aprobada') {
      this.infoServer.fechaDeAprobacionSeguridadSocial = moment().format('YYYY-MM-DD');
      this.infoServer.fechaVencimientoSeguridadSocial = moment().add(1,'month').format('YYYY-MM-DD');
      console.log(this.infoServer.fechaDeAprobacionSeguridadSocial);
    }

    if (this.articulosComprados.length > 0) {
      // this.infoServer.deudaArticulos = this.infoServer.deudaArticulos === undefined ? this.deudaArticulos : this.deudaArticulos + this.infoServer.deudaArticulos;
      this.guardarInfoArticulos(this.infoServer.id)
    }
    
    this.ServerService.updateServ(this.infoServer).then(() => {
      Swal.fire('Actualizado', 'Server actualizada con éxito!', 'success');
      const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
      element.click();
    }).catch((error) => {
      Swal.fire('Error', `No pudimos actualizar la server ${error}`, 'warning');
      console.error("Error!", error);
    });
  }

  desactivarServer(){
    this.infoServer.fechaDesactivacion = moment().format("YYYY-MM-DD");
    /* Des-asignar server de los planes */
    this.planService.getPlanesByServerId(this.infoServer.id).then((res) => {
      if (res.docs.length > 0) {
        res.docs.forEach((data) => {
          let plan = data.data();
          if (plan.estado == "Activo" || plan.estado == "Por Vencer") {
            plan.estado = "Orden de plan";
            plan.server = {
              id: "123",
              serverName: "Orden de plan"
            };
            plan.idServer = "";
            plan.serverName = "falta por desactivacion";

            this.ServicioService.updatePlan(plan).then((result) => {
              console.log('se actualizo plan con exito');
            }).catch(err => {
              console.log('error:', err);
            });
          }
        });
      }
    });
    /* Des-asignar server de los servicios */
    this.ServicioService.getServiciosServer(this.infoServer.id).then((res) => {
      if (res.docs.length > 0) {
        res.docs.forEach((data) => {
          let servicio = data.data();
          if (servicio.estado != 'Cancelado' && servicio.estado != 'Congelado' && servicio.fecha > moment().format('YYYY-MM-DD')) {
            servicio.estado =  servicio.tipoServicio == 'Plan Individual' ? "Orden de servicio" : 'Orden de plan';
            servicio.server = {
              id: "",
              name: "Orden"
            };
            servicio.idServer = "";
            servicio.idServers = "";
            servicio.serverName = "falta por desactivacion";
            this.ServicioService.updateServicio(servicio).then((result) => {
              console.log('se actualizo el servicio con exito');
            }).catch(err => {
              console.log('error:', err);
            });
          }
        });
      }
    });

    if(this.infoServer.tipoManejoSeguridadSoscial == "pagoCuotas"){
      /* Enviar email para desafiliar server a S.s */
      const today = moment().format('DD MMMM YYYY')
      console.log(today)
      const info = {
        // addressee: "ricardontre_94@hotmail.com",
        // addressee: "edgaryesidsanchezbueno@gmail.com",
        addressee: "talentohumanoservapp@gmail.com",
        subject: "Desafiliar persana de Seguridad social",
        text: `Buen día Sr.Renteria, la persona ${this.infoServer.name} ${this.infoServer.appellido} no estara activa desde el ${today}  por lo que requerimos que el pago de aportes no se realize mas para dicha persona,`
      }
      this.servBackendService.post('api/sendgrid/send-email-with-text',info).subscribe(res =>{
        console.log('Res api/sendgrid/send-email-with-text',res);
      })
    }


    // this.usuarioService.all().then((item) => {
    //   for (let data of item.docs) {
    //     let client = data.data();
    //     if (client['serverFavorita'] !== undefined && client['serverFavorita'].id == this.infoServer.id) {
    //       client['serverFavorita'] = {
    //         foto: '',
    //         id: '',
    //         nombreServer: '',
    //       }
    //       this.usuarioService.updateClient(client);
    //     }
    //   }
    // });
    
  }

  guardarServer() {
    this.infoServer.rol = "Server";
    this.infoServer.gps = this.origin;
    this.infoServer.estado = "activo";
    this.infoServer.fecha = Date.now();
    this.infoServer.fechaIngreso = moment().format('YYYY-MM-DD');
    this.infoServer.deudaSegridadSocial = 0;
    this.infoServer.horario = this.horarioServer;
    this.infoServer.horasDisponible = this.horasDisponible;
    this.infoServer.terminos = true;
    this.infoServer.tipoManejoSeguridadSoscial = true;
    this.infoServer.numeraria = {
      estado: false,
      fecha: "",
    };
    this.infoServer.direccionResidencia = this.direccionResidencia.description
      ? this.direccionResidencia.description
      : this.direccionResidencia;
    if (this.infoServer.genero == "Femenino") {
      this.infoServer.certificadoEmpresas = true;
      this.infoServer.certificadoHogares = true;
    } else {
      this.infoServer.certificadoTodero = true;
    }
    // horario: this.horarioServer,
    if (this.infoServer.idEstuduiante) {
      this.infoServer.id = this.infoServer.idEstuduiante;
    }
    this.createServer(this.infoServer);
  }

  createServer(server) {
    // server.gps = gps;
    // server.foto = avatar;
    if (this.infoServer.utilizaGoogleMaps) {
      let credenciales = {
        email: this.infoServer.email,
        password: "123456",
      };
      console.log("Server creada desde aspirantes", server);
      this.authService
        .createServerAspirante(server, credenciales)
        .then((userData: any) => {
          this.guardarServerEnBd(server, userData);
          if (this.articulosComprados.length > 0) {
            // this.infoServer.deudaArticulos = this.infoServer.deudaArticulos === undefined ? this.deudaArticulos : this.deudaArticulos + this.infoServer.deudaArticulos;
            // console.log("Entro en en articulosComprados");
            this.guardarInfoArticulos(server.id);
          }
          Swal.fire("Resgistro", "Server registrada con éxito!", "success");
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `Esta credencial ya existe en nuestro sistema!`,
            "warning"
          );
          console.error("Error!", error);
        });
    } else {
      console.log("Lo que va a guardar", server);
      this.authService.registerServer(server).then((userData: any) => {
          this.guardarServerEnBd(server, userData);
          if (this.articulosComprados.length > 0) {
            // this.infoServer.deudaArticulos = this.infoServer.deudaArticulos === undefined ? this.deudaArticulos : this.deudaArticulos + this.infoServer.deudaArticulos;
            // console.log("Entro en en articulosComprados");
            this.guardarInfoArticulos(userData.user.uid);
          }
          this.Message(
            "Resgistro",
            "Server registrada con éxito!",
            "success",
            true
          );
        })
        .catch((error) => {
          this.Message("Error", `${error}`, "warning");
          console.error("Error!", error);
        });
    }
  }
  guardarServerEnBd(server, userData) {
    if (this.fotoSeleccionada != undefined) {
      const filePath = `Servers/${userData.user.uid}/foto`;
      this.fireStoreRef = this.angularFireStorage.ref(filePath);
      this.angularFireStorage
        .ref(filePath)
        .put(this.fotoSeleccionada)
        .then((res) => {
          this.angularFireStorage
            .ref(filePath)
            .getDownloadURL()
            .subscribe((url) => {
              server.foto = url;
              this.authService.guardarServer(server, userData.user);
            });
        })
        .catch((err) => {
          console.log("Ocurrio un error---", err);
        });
    } else {
      this.authService.guardarServer(server, userData.user);
    }
  }

  guardarInfoArticulos(id) {
    this.ServerService.getProductosServers(id).then((res) => {
      let info: any;
      if (res.docs.length > 0) {
        info = res.docs[0].data();
        for (let item of this.articulosComprados) {
          info.productos.push(item);
        }
      } else {
        info = {
          id: id,
          productos: this.articulosComprados,
        };
      }
      console.log("info de los productos---------------", info);

      this.ServerService.guardarProductos(info)
        .then(() => {
          this.deudaArticulos = 0;
          this.articulosComprados = [];
        })
        .catch((err) => {
          console.log(
            "Ocurrio un error en  this.ServerService.guardarProductos---",
            err
          );
        });
    });
  }

  async selectLocation(event, type) {
    let name: string = ''
    if(typeof event == 'string') name = event
    else name =  event.target.value;
    let filter = null;
    filter = await this.locationDeparments.filter( (departments) => departments.name == name )[0];
    // console.log(filter)
    this.locationCities = filter[type];
  }
  async idLocationSelect(name: string, type: string) {
    let filter = null;
    this.resetLocation(type);
    if (type == "cities") {

    } else if (type == "suburbs") {
      filter = await this.locationCities.filter( (cities) => cities.name == name )[0];
      this.locationSuburbs = filter[type];
      this.locationService.getLocationByCity(name).then((res) => {
        const { docs } = res;
        if (docs.length > 0) {
          const { id } = docs[0].data();
          this.idLocation = id;
          if (
            this.helper.notUndefined(this.datosEdit) &&
            this.helper.notUndefined(this.datosEdit.idLocation)
          ) {
            this.datosEdit.idLocation = id;
          }
        } else {
          console.error("not data");
        }
      });
    } else if (type == "neighborhood") {
      filter = await this.locationSuburbs.filter(
        (neighborhood) => neighborhood.name == name
      )[0];
      this.locationNeighborhood = filter[type];
    }
    // console.log({ name });
    // console.log({ filter: filter[type] });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateCalificacion() {
    this.calificacionPromedio =
      (this.calidad1 + this.actitud + this.puntualidad + this.uniforme) / 4;
    console.log(this.calificacionPromedio);
  }
  public descargar() {
    console.log(this.calidad1);
  }
  // Obtener todas las prediciones de direccion
  getPlace() {
    this.googleService.currentData.subscribe((place) => {
      this.placePredictions = place;
      // console.log({ getPlace: place });
    });
  }

  getGps() {
    this.googleService.currentGps.subscribe((origin: Gps) => {
      this.origin =
        origin != null && origin.lat != null && origin.lng != null
          ? origin
          : this.originDefault;
      if (this.datosEdit) {
        this.datosEdit.gps =
          origin != null && origin.lat != null && origin.lng != null
            ? this.origin
            : this.datosEdit.gps;
      }
      // console.log({origin});
    });
  }

  onSearchPlace(_term: string, _city: string) {
    const text: string = _term;
    const city: string = _city;
    const status: boolean = city.includes("D.C.");
    if (text === "") {
      return;
    }
    const search = `${status ? city.replace("D.C.", "") : city
      } ${text}, Colombia`;
    console.log({ search, status, text });
    this.googleService.getPlacePredictions(search);
  }

  onSelectedPlace(place) {
    //console.log({onSelectedPlace: place})
    this.googleService.getGpsPlace(place.description);
  }

  //Permite Obtener los servicios perteneciente a la server
  /**
   *
   * @param id
   */
  getServicios(id) {
    let arrayprueba = [];
    this.ServicioService.getServiciosServer(id).then((value) => {
      value.docs.forEach((doc) => {
        let aux = doc.data();
        arrayprueba.push(aux);
        this.isDisabled = true;
      });
      this.arrayServicios = arrayprueba;
      this.calendarioServicios();
    });
  }

  calendarioServicios() {
    let lista = [];
    this.arrayServicios.forEach((element) => {
      let servicio: any = element;
      let numeroHoras = parseInt(servicio.horasDeServicio);
      let horaInicioServicio = parseInt(servicio.hora);
      let horaFinServicio = horaInicioServicio + numeroHoras;
      let horaInicio: string;
      let horaFin: string;
      if (horaInicioServicio < 10) {
        horaInicio = "0" + horaInicioServicio.toString();
      } else {
        horaInicio = horaInicioServicio.toString();
      }

      if (horaFinServicio < 10) {
        horaFin = "0" + horaFinServicio.toString();
      } else {
        horaFin = horaFinServicio.toString();
      }
      let inicio = moment(
        servicio.fecha + " " + horaInicio.toString()
      ).toDate();
      let fin = moment(servicio.fecha + " " + horaFin.toString()).toDate();
      const evento = {
        start: inicio,
        end: fin,
        color: colors.red,
        title: "",
        id: servicio.id,
        obj: servicio,
      };
      lista.push(evento);
    });
    this.listaCalendarioServicio = lista;
  }

  getDisponibilidad(idServer) {
    let arrayOcupacion = [];
    this.ServerService.getDisponibilidad(idServer).onSnapshot((value) => {
      if (value.data() != undefined) {
        let info = value.data();
        let date = [];
        info.fechas.forEach((obj) => {
          let cadena = obj.fecha.split("-");
          obj.fecha = new Date(cadena[0], parseInt(cadena[1]) - 1, cadena[2]);
          let datos = {
            date: obj.fecha,
            //hora: obj.hora
          };
          date.push(datos);
          this.infoDateCopia.push(datos);
        });
        info = date;
        this.infoDate = info;
      }
      if (this.infoDate) {
        this.selectedDays = this.infoDate;
      }
    });
  }

  public calendarioOcupacion() {
    this.arrayDisponibilidad.forEach((disponibilidad) => {
      let inicio = moment(disponibilidad.fecha + " " + 6).toDate();
      let fin = moment(disponibilidad.fecha + " " + 22).toDate();

      const evento = {
        start: inicio,
        end: fin,
        color: colors.red,
        title: "",
        id: disponibilidad.id,
        obj: disponibilidad,
      };
      //lista.push(evento);
    });
  }

  dayClicked(day: CalendarMonthViewDay): void {
    if (day.events.length == 0) {
      this.selectedMonthViewDay = day;
      const selectedDateTime = this.selectedMonthViewDay.date.getTime();
      let dateIndex: any;
      if (this.selectedDays) {
        dateIndex = this.selectedDays.findIndex(
          (selectedDay) => selectedDay.date.getTime() === selectedDateTime
        );
      }
      if (dateIndex > -1) {
        delete this.selectedMonthViewDay.cssClass;
        this.selectedDays.splice(dateIndex, 1);
      } else {
        this.selectedDays.push(this.selectedMonthViewDay);
        this.selectedMonthViewDay = day;
      }
      this.refreshView();
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    if (this.selectedDays) {
      if (this.selectedDays.length == 0) {
        (<HTMLInputElement>document.getElementById("btnBloquear")).disabled =
          true;
      } else {
        (<HTMLInputElement>document.getElementById("btnBloquear")).disabled =
          false;
      }
      body.forEach((day) => {
        for (let fechaNoDisponible of this.selectedDays) {
          if (fechaNoDisponible.date.getTime() == day.date.getTime()) {
            day.cssClass = "cal-day-selected";
          }
        }
      });
    }
  }

  FechaExp(event: IMyDateRangeModel) {
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    if (beginDate != "Invalid date" && endDate != "Invalid date") {
      this.experiencia.fecha = `${beginDate} hasta ${endDate}`;
    }
  }

  FechaEstud(event: IMyDateRangeModel) {
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    if (beginDate != "Invalid date" && endDate != "Invalid date") {
      this.estudios.fecha = `${beginDate} hasta ${endDate}`;
    }
  }
  modficarExperienciaLaboral(info, type) {
    if (this.infoServer.experiencia == undefined) {
      this.infoServer.experiencia = [];
    }
    if (type === "guardar") {
      this.infoServer.experiencia.push(info);
      this.experiencia = {
        jefe: "",
        fecha: "",
        cargo: "",
        empresa: "",
        funciones: "",
      };
    }
  }
  modficarEstudios(info, type) {
    if (type === "guardar") {
      if (this.infoServer.estudios == undefined) {
        this.infoServer.estudios = [];
      }
      this.infoServer.estudios.push(info);
      this.estudios = {
        fecha: "",
        ciudad: "",
        titulo: "",
        institucion: "",
        departamento: "",
      };
    }
  }

  refreshView(): void {
    this.refresh.next();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  public serversActivas: any;

  ordenByJson(json: any, property: string = "fecha", order: string = "desc") {
    const Order = _.orderBy(json, [property]);
    return new LocalDataSource(Order);
  }

  calculoProductos(type, item) {
    // console.log(this.productosServers,this.articulosComprados,item)
    const index = this.articulosComprados.findIndex(
      (res) => res.name == item.name
    );
    const index2 = this.productosServers.findIndex(
      (res) => res.name == item.name
    );
    console.log(index, "-----", index2);
    if (type == "mas") {
      if (index != -1) {
        this.articulosComprados[index].cantidad++;
        this.articulosComprados[index].total =
          this.articulosComprados[index].cantidad *
          this.articulosComprados[index].precio;
        this.productosServers[index2].cantidad++;
        this.articulosComprados[index].saldo = this.articulosComprados[index].total
      } else {
        this.articulosComprados.push({
          name: item.name,
          cantidad: 1,
          precio: item.precio,
          total: item.precio * 1,
          fecha: moment().format("YYYY-MM-DD"),
          saldo: item.precio * 1,
        });
        this.productosServers[index2].cantidad++;
      }
    } else {
      if (index != -1) {
        this.articulosComprados[index].cantidad--;
        this.productosServers[index2].cantidad--;
        this.articulosComprados[index].total =
          this.articulosComprados[index].cantidad *
          this.articulosComprados[index].precio;
        if (this.articulosComprados[index].cantidad == 0) {
          this.articulosComprados.splice(index, 1);
        }
      }
    }
    this.deudaArticulos = 0;
    console.log(this.articulosComprados);
    for (let info of this.articulosComprados) {
      this.deudaArticulos = this.deudaArticulos + info.total;
      console.log(this.deudaArticulos, info.total);
    }
  }

  cambiarDisponibilidadSemana(){
    this.horasDisponible = parseInt(this.infoServer.disponibilidadSenama);
  }

  asignarDia(i, event) {
    if (event.target.checked == true) {
      this.horarioServer[i].start_time = "8:00";
      this.horarioServer[i].end_time = "17:00";
      this.horarioServer[i].hours = 8;
    } else {
      this.horarioServer[i].start_time = "";
      this.horarioServer[i].end_time = "";
      this.horarioServer[i].hours = 0;
    }
    this.horasDisponible = 0;
    for (let h of this.horarioServer) {
      this.horasDisponible += h.hours;
    }
    this.horasDisponible = Math.min(this.horasDisponible, 48);
    this.infoServer.horasDisponible = this.horasDisponible;
    console.log(this.horarioServer, this.horasDisponible);
  }

  asignarHorario(i, event, type) {
    console.log(i,type, this.horarioServer);
    let hora = event.target.value;
    console.log(event);
    console.log(hora);
    if(hora == 'allDay'){
      console.log('entro en allDay')
      this.horarioServer[i].time_slot = 'allDay';
      this.horarioServer[i].start_time = "8:00";
      this.horarioServer[i].end_time = "17:00";
      this.horarioServer[i].hours = 8;
    } else {
      this.horarioServer[i].time_slot = "";
      type == "inc"
        ? (this.horarioServer[i].start_time = hora)
        : (this.horarioServer[i].end_time = hora);
      this.horarioServer[i].hours = parseInt(this.horarioServer[i].end_time) - parseInt(this.horarioServer[i].start_time);
    }
    this.horasDisponible = 0;
    for (let h of this.horarioServer) {
      this.horasDisponible += h.hours;
    }
    this.horasDisponible = Math.min(this.horasDisponible, 48);
    console.log(this.horarioServer, this.horasDisponible);
  }

  /**
   *
   * @param server
   * @param avatar
   * @param gps
   */
  updateServer(server, avatar: string, gps: Gps) {
    server.gps = gps;
    server.foto = avatar;
    console.log("Datos a guardar", server);
    this.ServerService.updateServ(server)
      .then(() => {
        this.Message(
          "Actualización!",
          "Registro actualizado con éxito!",
          "success",
          true,
          "btn-close-edit"
        );
        this.editEstado = false;
        this.server = [];
      })
      .catch(() => {
        this.editEstado = false;
        this.spinerModal = false;
        this.Message(
          "Oops...",
          "Esta credencial ya existe en nuestro sistema!",
          "error"
        );
      });
  }

  public piture(imgURL) {
    this.filenameToRender = imgURL;
  }

  //Permite cargar la foto
  onUpload(event) {
    console.log(event.target.files[0]);
    this.inputFile = this.helper.onFileReader(event, this.piture.bind(this));
    this.fotoSeleccionada = event.target.files[0];
    if (this.infoServer.id) {
      const filePath = `Servers/${this.infoServer.id}/foto`;
      this.fireStoreRef = this.angularFireStorage.ref(filePath);
      this.angularFireStorage.ref(filePath).put(event.target.files[0]).then((res) => {
        let sub = this.angularFireStorage.ref(filePath).getDownloadURL().subscribe((url) => {
          sub.unsubscribe();
          this.infoServer.foto = url;
        });
      })
      .catch((err) => {
        console.log("Ocurrio un error---", err);
      });
    }
  }

  public file;
  public pictureName;

  onUploadcertificadoVacunacionC19(event) {
    this.file = event.target.files[0];
    this.pictureName = Date.now() + this.file.name;

    if (event.target.files && this.file) {
      this.nameImage = this.file.name;
      var reader = new FileReader();
      console.log(this.nameImage, this.file, this.pictureName);
      this.subirImagen();
      reader.onload = (event: ProgressEvent) => {
        this.fotoSeleccionada = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public subirImagen() {
    this.ServicioService.putPicture(this.pictureName, this.file).then(
      (value) => {
        if (value.state == "success") {
          this.ServicioService.getURL(this.pictureName).subscribe((url) => {
            this.certificadoVacunacionC19 = url;
            console.log(this.certificadoVacunacionC19);
          });
        }
      }
    );
  }

  notUn(info: any) {
    return !this.helper.notUndefined(info);
  }

  /**
   *
   * @param dialog
   * @param server
   */

  public EditDisponibilidad() {
    this.editDay = true;
  }

  public formatDate(date) {
    return typeof date == "string"
      ? this.helper.dateDefaultNotHour(date)
      : typeof date == "number"
        ? this.helper.dateDefaultNotHour(date)
        : "";
  }

  verServer(obj) {
    let server = JSON.stringify(obj);
    this.router.navigate(["dashboard/ver-server", server]);
  }

  //Exportamos los registros de usuario a un archivo excel
  export() {
    this.ServerService.exportAsExcelFile(this.serverExcel, "Servers");
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }
  /**
   *
   * @param title
   * @param message
   * @param type
   * @param close
   * @param modal
   */
  private Message(
    title: string,
    message: string,
    type: SweetAlertType,
    close: boolean = false,
    modal: string = "btn-close-save",
    reset: boolean = false
  ) {
    setTimeout(() => {
      Swal.fire(title, message, type);
      this.spinerModal = false;
      if (reset) {
        this.resetData(true);
      }
      if (close) {
        this.isUpdateidLocation = false;
        this.resetData(true);
        const element: HTMLElement = document.getElementById(
          modal
        ) as HTMLElement;
        element.click();
      }
    }, 900);
  }
  resetData(resetEdit: boolean = false) {
    this.submitForm = false;
    this.spinerModal = false;
    this.SpinnerMessage = "Cargando Clientes...";
    this.SpinnerMessageModal = "Procesando...";

    if (resetEdit) this.frmCreate.reset();
    this.resetLocation("departments");
    this.idLocation = "";
  }
  resetLocation(type) {
    if (type == "departments") {
      this.locationDeparments = [];
      this.locationCities = [];
      this.locationSuburbs = [];
      this.locationNeighborhood = [];

      if (this.datosEdit) {
        this.datosEdit.department = "";
        this.datosEdit.cities = "";
        this.datosEdit.suburbs = "";
        this.datosEdit.neighborhood = "";
      }
    } else if (type == "cities") {
      this.locationSuburbs = [];
      this.locationNeighborhood = [];
      if (this.datosEdit) {
        this.datosEdit.suburbs = "";
        this.datosEdit.neighborhood = "";
      }
    } else if (type == "suburbs") {
      this.locationNeighborhood = [];
      if (this.datosEdit) {
        this.datosEdit.neighborhood = "";
      }
    }

    console.log({ edit: this.datosEdit });
  }
  maskLoad(
    spinerStatus: boolean = true,
    message: string = "Procesando...",
    time: number = 4000,
    auto: boolean = true
  ) {
    this.spinerModal = spinerStatus;
    this.SpinnerMessageModal = message;
    auto ? setTimeout(() => (this.spinerModal = !spinerStatus), time) : null;
  }
  enableInput() {
    this.isUpdateidLocation = !this.isUpdateidLocation;
  }
  showError(status: boolean) {
    this.submitForm = status;
    status
      ? this.Message(
        "Oops...",
        "Por favor verifica la información ingresada",
        "warning"
      )
      : null;
    return !status;
  }
  activeError(form: any, property: string, valid: string = "") {
    try {
      const element = form[property];
      const value = !valid
        ? typeof element.errors !== "undefined" && element.errors
        : typeof element.errors[valid] !== "undefined" && element.errors[valid];
      /*console.log({
        value,
        valid,
        error: element.errors,
        statu: element.errors[valid],
        form: this.submitForm
      });*/

      return this.submitForm && value;
    } catch (error) {
      return false;
    }
  }

  async solicitarInscripcionSs(){
    return new Promise(resolve=>{
      Swal.fire({
        title: 'Espera',
        text: 'Estas seguro de activar esta server, se enviara un correo para la inscripccion de seguridad social',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Contunuar',
      }).then(async res=>{
        if(res.value){

          const filepath = `/Servers/${this.infoServer.id}/documento150`
          const file: any = await this.getFileUrl(filepath);
          console.log(file)

          const plan_afiliar =  this.infoServer.tipoManejoSeguridadSoscial == 'directa' 
            ? 'Directa con SERV SAS'
            : this.infoServer.tipoSeguradaSocial == 'tipo1' 
              ? 'ARL & EPS'
              : "ARL + EPS + CCF";
          const edad = moment().diff(moment(this.infoServer.fechaNacimiento),'years')
          const body = this.infoServer.tipoManejoSeguridadSoscial == 'directa'
            ? 'esta persona sera directa de la empresa SERV SAS,si es extranjero no incluir AFP' 
            : 'esta persona sera afiliada con ustedes en el plan que aparecera a continuacion'
          const info = {
            // addressee: "ricardontre_94@hotmail.com",
            addressee: "talentohumanoservapp@gmail.com",
            subject: "Afiliacion de persana a Seguridad social",
            html_body: `<p>Buen día Sr.Renteria, solicito ayuda con la afilicaion de una nueva persona,${body}, los datos son los siguientes </p>
            <ul>
              <li> Nombre completo: ${this.infoServer.name} ${this.infoServer.apellido}. </li>
              <li> Direcciopn: ${this.infoServer.direccionResidencia}. </li>
              <li> Correo: ${this.infoServer.email}. </li>
              <li> Documento: ${this.infoServer.tipoDocumento}. </li>
              <li> Num. Documento: ${this.infoServer.cedula}. </li>
              <li> Telefono: ${this.infoServer.telefono}. </li>
              <li> Fecha naciemiento: ${this.infoServer.fechaNacimiento}. </li>
              <li> Edad: ${edad}. </li>
              <li> Plan que va a tomar: ${plan_afiliar}. </li>
            </ul>
            `,
            dataFile: {
              file: file._url,
              type: file.type,
              file_name: 'Documento'
            }
          } 
          console.log(info)
          if(file != 'falta'){
            this.servBackendService.post('api/sendgrid/send-email-with-html',info).subscribe(res =>{
              console.log('Res api/sendgrid/send-email-by-text',res);
              resolve(true);
            })
          }else{
            Swal.fire('Espera','No se a encontrado el archivo Documeto para el envio del correo').finally(()=>{
              resolve(false);
            })
          }
        }
      })
    })
  }

  getFileUrl(filepath){
    
    return new Promise(async resolve=>{
      this.StorageService.getData(filepath).then(res=>{
        console.log('link1',res.contentType);
        let stingDividido = res.contentType.split('/');
        res.ref.getDownloadURL().then(url=>{
          const data = {
            _url: url,
            type: stingDividido[1]
          }
          resolve(data);
        })
        res.ref
      }).catch(error=>{
        console.log('Error al optener el link',error)
        resolve('falta');
      })
    })
  }

  cambiarTipoContrato(){
    if(this.infoServer.tipoContrato == 'directo'){
      if( this.infoServer.tipoManejoSeguridadSoscial != 'directa') {
        this.cambioTipoManejoSeguridadSoscial = true;
        this.infoServer.tipoManejoSeguridadSoscial = 'directa';
      }
    }
  }

}
