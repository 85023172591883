import * as firebase from 'firebase';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UsuarioAdminService{
  constructor(
  ) { }

  // public registerUser(usuario, userData) {
  //   usuario.id = userData.uid;
  //   return firebase.firestore().doc(`usersAdmin/${usuario.id}`).set(Object.assign({}, usuario));
  // }

  // registerAdminUser(datos) {
  //   return this.afAuth.auth.createUserWithEmailAndPassword(datos.email, datos.clave)
  //     .then(userData => { this.registerUser(datos, userData.user); })
  //     .catch(error=> console.log('Esta credencial ya existe en nuestro sistema!',error));
  // }
   
  // getUsuariosPorRol(rol){
  //   return firebase.firestore().collection('usersAdmin')
  //   .where('rol','==',rol)
  //   .get()
  // }
}