import { Injectable } from '@angular/core';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { Http,Headers } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AngularFirestore } from 'angularfire2/firestore';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})

export class PagosServerService {

  back_end_url = 'http://localhost:8080/'
  headers: Headers;

  constructor(
    private afd: AngularFireDatabase,
    private angularFireStorage: AngularFireStorageModule,
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private http: Http,
  ) { 
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  /* CRUD aspirantes */
  public post(pagosServer) {
    return firebase.firestore().doc('pagos-server/' + pagosServer.id).set(pagosServer);
  }

  public getPagosServer(id) {
    console.log(id)
    return firebase.firestore().collection('pagos-server').where('id', "==", Number(id)).get()
  }
  
  // public getPagosById(id) {
  //   return firebase.firestore().doc(`pagos-server/${id}`).get();
  // }

  public editPagosServer(pagosServer) {
    let id = pagosServer.id.toString();
    return firebase.firestore().collection('pagos-server').doc(id).set(pagosServer)
  }
  public updatePagoServer(info) {
    let id = info.id.toString();
    return firebase.firestore().collection('pagos-server').doc(id).update(info)
  }

  public getPagosServers() {
    return firebase.firestore().collection('pagos-server').get()
  }

  public deletePagosServer(id) {
    return firebase.firestore().doc('pagos-server/' + id).delete()
  }

  public eliminarPagosServer(pagosServer) {
    return firebase.firestore().collection('pagos-server').doc(pagosServer.id).delete();
  }

  public getPagosServersPorServicio(inicioSemana, finSemana, estado) {
    return firebase.firestore().collection('pagos-server')
      .where('fechaInicio', '>=', inicioSemana)
      .where('fechaInicio', '<=', finSemana)
      .where('estadoComprobante', '==', estado)
      .where('tipoPago', '==', "Por servicio")
      .get()
  }
  public getPagosServersDirectas(inicioSemana, finSemana) {
    console.log(inicioSemana,finSemana)
    return firebase.firestore().collection('pagos-server')
      .where('fechaInicio', '==', inicioSemana)
      .where('fechaFin', '==', finSemana)
      .where('tipoPago','==', "Por contrato")
      .get()
  }
  public getPorFecha(inicioSemana, finSemana) {
    return firebase.firestore().collection('pagos-server')
      .where('fechaInicio', '>=', inicioSemana)
      .where('fechaInicio', '<=', finSemana)
      .get()
  }

  public getComprobantePorId(id) {
    return firebase.firestore().collection('pagos-server').where('id', '==', id).get()
  }

  public getComprobanteEstado(estado){
    return firebase.firestore().collection('pagos-server')
    .where('estadoComprobante', '==', estado).get()
  }

  public getComprobantePorIdServer(idSever) {
    return firebase.firestore().collection('pagos-server')
    .where('idSever', '==', idSever).get()
  }



  public exportAsExcelFile(sheets: { name: string, data: any[] }[], fileName: string): void {
    console.log(sheets)
    const workbook: XLSX.WorkBook = {
      Sheets: {},
      SheetNames: [],
    };
  
    for(let i = 0;i < sheets.length; i++){
      console.log('a')
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheets[i].data);
      workbook.Sheets[sheets[i].name] = worksheet;
      workbook.SheetNames.push(sheets[i].name);
      console.log('sheet added');
    };
  
    console.log(workbook);
    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }


}
