import { Component, OnInit } from '@angular/core';
import { ServicioService } from 'src/app/services/servicio.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ServerService } from 'src/app/services/server.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-ver-transaccion',
  templateUrl: './ver-transaccion.component.html',
  styleUrls: ['./ver-transaccion.component.scss']
})
export class VerTransaccionComponent implements OnInit {
  public user: any;
  public server: any;
  public transaccion: any;
  public transaccionId: any;
  public usuario: any;
  public dato: any = {};
  public fechaServicio: Array<object> = [];
  public source: LocalDataSource;
  public verInfoServicio = false;
  public servicio: any;
  public servicioById: any;
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [

        { name: 'detalle', title: '<i class="nb-search"></i>' }
      ],
    },
    columns: {
      fecha: { title: 'Fecha del servicio' },
    }
  };

  constructor(public servicioService: ServicioService,
    private rutaActiva: ActivatedRoute,
    public ServerService: ServerService,
    public usuarioService: UsuarioService,
    public ServicioService: ServicioService,
    public router: Router,
  ) {
    this.transaccionId = this.rutaActiva.snapshot.params.idTransaccion;
  }

  ngOnInit() {
    this.getTransacciones(this.transaccionId);
  }

  //Se obtienen la transacción
  getTransacciones(id) {
    this.servicioService.getTransaccionId(id).then(value => {
      let aux = (value.data());
      this.servicioService.getUser(aux).subscribe(infoUser => {
        this.user = infoUser;
        this.usuario = (this.user.name + ' ' + this.user.apellido);
        aux.usuario = this.usuario;
        if (aux.formaDePago == "efectivo") {
          aux.id_payco = "N/A";
          aux.ref_payco = "N/A";
          aux.respuesta = "N/A";
          aux.estado = "N/A";
          aux.token = "N/A";
        }
        if (aux.detalle == undefined) {
          aux.detalle = "Servicio dia";
        }
        this.transaccion = aux;
        if (this.transaccion.detalle != "Full clean") {
          this.ServicioService.getServiciosbyId(this.transaccion.idServicio).then(value => {
            var servi = value.data();
            this.dato.fecha = servi.fecha;
            this.dato.idServicio = servi.id;
          });
          this.fechaServicio.push(this.dato);
        } else {
          this.transaccion.idServicios.forEach(element => {
            this.ServicioService.getServiciosbyId(element).then(value => {
              let servicioById = value.data();
              this.dato.fecha = servicioById.fecha;
              this.dato.idServicio = servicioById.id;
            });
            this.fechaServicio.push(this.dato);
          });
        }
        setTimeout(() => {
          this.source = new LocalDataSource(this.fechaServicio);
        }, 5000);
      });
    });
  }

  onCustom(event) {
    if (event.action = "detalle") {
      this.verServicio(event.data.idServicio);
    }
  }

  verServicio(id) {
    this.verInfoServicio = true;
    this.ServicioService.getServiciosbyId(id).then(value => {
      this.servicio = value.data();
      this.ServerService.detail(this.servicio.idServer).then(elemento=> {
        if (elemento.data().name == undefined && elemento.data().apellido == undefined) {
          this.server = "Esperando confirmacion de server";
        }
        else {
          this.server = elemento.data().name + " " + elemento.data().apellido;
        }
      });
      this.usuarioService.detail(this.servicio.userid).then(elemento => {
        this.usuario = elemento.data().name + " " + elemento.data().apellido;
      });
    });
  }

  ocultarServicio() {
    this.verInfoServicio = false;
  }
}
