import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class DisponibilidadAppService {

  constructor() { }

  get(){
    return firebase.firestore().collection('fechasBloqeuadasApp').get()
  }
  add(info){
    firebase.firestore().collection('fechasBloqeuadasApp').add(info).then(res=>{
      res.update({id: res.id})
      return {fecha: info, id: res.id}
    })
  }
  delete(id){
    return firebase.firestore().collection('fechasBloqeuadasApp').doc(`${id}`).delete();
  }
}
