import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PriceService } from '../../services/prices.service'
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-gestionar-precios',
  templateUrl: './gestionar-precios.component.html',
  styleUrls: ['./gestionar-precios.component.scss']
})
export class GestionarPreciosComponent implements OnInit {

  @ViewChild('mdlDetail', { static: true }) mdlCrear: ElementRef;
  servicesArray = [];
  settingsServices = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [ { name: 'detail', title: '<i class="nb-search"></i>' } ],
    },
    columns: {
      servicio: { title: 'Tipo de servicio' },
      horas: { title: 'Horas' },
      agendamiento: { title: 'Agendamiento' },
    }
  };
  settingsPrecios = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [],
    },
    columns: {
      tipoAgendamiento: { title: 'Tipo de servicio' },
      horas: { title: 'Duracion' },
      precio: { title: 'Precio' },
      descuentoApp: { title: 'Descuento en app' },
      gananciaServer: { title: 'Ganancia server' },
    }
  };

  infoService: any = null;
  infoPrice: any = null;
  priceList: any;
  editPrice = false;
  constructor(
    private authService: AuthService,
    private priceService: PriceService,
    private dialogService: NbDialogService,
  ) {
    const usuarioEnSecion =  this.authService.storeGetUserData('usuario')
    if(usuarioEnSecion.rol == 'administrador'){
      this.settingsPrecios.actions.custom = [ { name: 'edit', title: '<i class="nb-search"></i>' }]
    }
  }

  ngOnInit() {
    this.onLoad();
  }

  onLoad(){
    this.servicesArray = [];
    this.priceService.get().then(res=>{
      const info = res.docs.map(data=>{
        return {
          ...data.data()
        }
      })
      this.servicesArray = _.orderBy(info,'servicio','asc');
    })
  }

  async onCustom(event){
    switch (event.action) {
      case 'detail':
        this.infoService = event.data
        const list: any = await this.getValoresServicio();
        this.priceList = _.orderBy(list,['tipoAgendamiento','horas'],['desc','asc']);
        console.log(this.priceList);
        this.openDetailModal(this.mdlCrear)
        break;
      case 'edit':
        this.infoPrice = event.data;
        this.editPrice = true;
    }
  }

  getValoresServicio(){
    return new Promise(async resolve=> {
      const query_valures = await this.priceService.getSubColecction(this.infoService.id,'valores');
      const priceList = query_valures.docs.map(data=> { return {...data.data()} });
      resolve(priceList)
    })
  }

  openDetailModal(dialog){
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  onUpdate(){
    this.priceService.updateSubColecction(this.infoService.id,this.infoPrice).then(()=>{
      this.editPrice = false;
      Swal.fire('Listo','La informacion a sido actualizada','success')
    })
  }

}
