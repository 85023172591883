import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { zip } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CodigoReferidoServerService {

  db = firebase.firestore().collection('codigoReferidoServer');
  constructor() { }

  set(data){
    return this.db.doc(data.id).set(data)
  }
  detail(id){
    return this.db.doc(id).get();
  }
  getByCode(code){
    return this.db.where('referredCode','==',code).get();
  }

  updateRedeemed(parentDocId: string, data: any) {
    const docRef = this.db.doc(parentDocId);
    return docRef.collection("redeemed").doc(data.idUser).set(data)
  }

  getByDateInSubcolection(parentDocId: string, subcollectionName: string, startDate: string, endDate: string) {
    return this.db.doc(parentDocId).collection(subcollectionName)
      .where('fecha', '>=', startDate)
      .where('fecha', '<=', endDate)
      .get();
  }
  
  detailInSubcoleccion(parentDocId: string, subcollectionName: string,docId: string){
    const docRef = this.db.doc(parentDocId);
    console.log("consultando en subcoleccion",);
    return docRef.collection(subcollectionName).doc(docId).get();
  }
}