const festivosColombia = [
    {"fecha": "2024-01-01"},
    {"fecha": "2024-01-08"},
    {"fecha": "2024-03-25"},
    {"fecha": "2024-03-28"},
    {"fecha": "2024-03-29"},
    {"fecha": "2024-05-01"},
    {"fecha": "2024-05-13"},
    {"fecha": "2024-06-03"},
    {"fecha": "2024-06-10"},
    {"fecha": "2024-07-01"},
    {"fecha": "2024-07-20"},
    {"fecha": "2024-08-07"},
    {"fecha": "2024-08-19"},
    {"fecha": "2024-10-14"},
    {"fecha": "2024-11-04"},
    {"fecha": "2024-11-11"},
    {"fecha": "2024-12-08"},
    {"fecha": "2024-12-25"}
]

export{
    festivosColombia,
}
