import { Component, OnInit,ViewChild,ElementRef,ChangeDetectorRef  } from '@angular/core';
import { NbDialogService } from "@nebular/theme";
/*Servicios*/
import { Metricas } from '../../services/metricas.service'
import { ServerService } from '../../services/server.service'
import { ServicioService } from '../../services/servicio.service'
import { posventaService } from '../../services/posventa.service'
import { uniservService } from '../../services/uniserv.service'
/*Utilidades*/
import * as moment from 'moment';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexMarkers,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
  ApexTooltip,
  ApexAnnotations,
} from "ng-apexcharts";
import { Subject } from 'rxjs';
import { IMyDateRangeModel } from 'mydaterangepicker';
import * as _ from "lodash";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
};
@Component({
  selector: 'app-observador',
  templateUrl: './observador.component.html',
  styleUrls: ['./observador.component.scss']
})

export class ObservadorComponent implements OnInit {
  refresh: Subject<any> = new Subject();
  @ViewChild("mdlVerServer", { static: true }) mdlVerServer: ElementRef;
  public loadingModal = false
  public chartServiciosPrimerRango: Partial<ChartOptions>;
  public chartUniserv: Partial<ChartOptions>;
  public today = moment().format('YYYY-MM-DD');
  public listaServers: any;
  public source_listaServers:any;
  public settings: any = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'verServer', title: '<i class="nb-search"></i>' },
        // { name: "delete", title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombreServer: { title: 'Server' },
      cities: {title: 'Ciudad'},
      diasBloqueados: {title: "Dias Bloqueados"},
      numNovedades: {title: "Total de novedades"},
      totalCalificacion: {title: "Calificacion"},
      arrayPostVentas: {title: "Veses puntuada",
      valuePrepareFunction: (data) => {
        return data.length;
      }
    },
      serviciosRealizados: {title: "Servicios realizados(mes)"},
      horasTrabajadas: {title: "Horas trabajadas(mes)"},
      horasDisponible: {title: "Horas disponible(semana)"},
      porcentageDeOcupacion: {title: "Porcentage de ocupacion(mes)"},
      fechaIngreso: { title: 'Fecha de ingreso',
        valuePrepareFunction: (data) => {
          return `${data} / ${moment().diff(data,'days')} dias`
        }
      },
    },
    rowClassFunction: (row) => {
      if (parseInt(row.data.porcentageDeOcupacion) < 60 && row.data.diasTrabajados > 30 ) {
        return "colorRojo";
      } else if (parseInt(row.data.porcentageDeOcupacion) < 85 && row.data.diasTrabajados > 30) {
        return "colorNaranja";
      } else if (parseInt(row.data.porcentageDeOcupacion) < 60 && row.data.diasTrabajados < 30) {
        return "colorAmarillo";
      } else {
        return "colorAzul";
      }
    },
  }

  setingsStrikes: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'llamadoAtencion', title: '<i class="nb-checkmark-circle" title="Llamado de atención"></i>' },
      ],
    },
    columns: {
      nombreServer: { title: 'Server' },
      telefono: {title: 'Telefono'},
      fecha: {title: "Fecha"},
      razon: {title: "Motivo"},
    },
    rowClassFunction: (row) => {
      if (row.data.revisada == false) {
        return 'colorRojo';
      } else {
        return 'colovVerde';
      }
    },
  }
  public serversCalificacionCuatro: any = [];
  public serversCalificacionTres:   any = [];
  public serversCalificacionDos:    any = [];
  public serversCalificacionUno:    any = [];

  public serversUnStrike:    any  = [];
  public serversDosStrike:   any = [];
  public serversTresStrike:  any = [];
  public servrMasTresStrike: any = [];
  public serverVisualizada: any;
  public serversPorLLamar = 0;
  constructor(
    private cdr: ChangeDetectorRef,
    private metricasService: Metricas,
    private uniservService: uniservService,
    private serverService:   ServerService,
    private nbDialogService: NbDialogService,
    private posventaService: posventaService,
    private servicioService: ServicioService,
  ) { 
    this.chartServiciosPrimerRango = {};
    this.chartUniserv = {};
  }

  ngOnInit() {
    this.getServers()
  }

  async getServers(){
    let source = [];
    const a = await this.serverService.Actives();
    a.docs.forEach(async (res,index)=>{
      let server = res.data();
      server.diasTrabajados = moment().diff(server.fechaIngreso,'days');
      source.push(server);
      if(index == (a.size - 1) ){
        source.filter((server) =>
          (server["nombreServer"] = `${server.name} ${server.apellido}`) &&
          server.id != "56VnqwDwXhdWQToWbflQMJEMcyB2"
        ); // cambiar el 2 final por un 3 para sacar adigital
        this.listaServers = source;
        console.log("Lista server de listar-plan",this.listaServers)
        await Promise.all([
          this.getNovedades(),
          this.getPorcentageOcupacion(),
          this.getDisponibilidad(),
          this.getPuntuacion()
        ]);
    
        // Actualizar la tabla
        this.source_listaServers = this.listaServers;
        this.cdr.detectChanges();
        this.refreshView();
      }
    })
  }

  async getNovedades(){
    return new Promise(resolve=>{
      let serversUnStrike    = [];
      let serversDosStrike   = [];
      let serversTresStrike  = [];
      let servrMasTresStrike = [];
      let countServer = 0;
      this.listaServers.map(server => {   
        let count = 0;
        this.metricasService.getNovedadesPorServer(server.id).then(res=>{
          server.numNovedades = res.docs.length;
          server.novedadesAsociadas = [];
          let countInicidencias = 0;
          if(res.docs.length === 0){
            countServer++
          }
          res.docs.forEach(element => {
            const novedad = element.data();
            if(novedad.categoria == 'Server directa') server.novedadesAsociadas.push(element.data())
          });
        });
        if(server.strikes === undefined){

        }else if(server.strikes.length === 1){
          serversUnStrike.push({
            server: server,
            nombreServer: `${server.name} ${server.apellido}`,
            telefono: server.telefono,
            fecha:    server.strikes[0].fecha,
            razon:    server.strikes[0].razon,
            revisada: server.strikes[0].revisada
          })
          if(server.strikes[0].revisada == false){
            this.serversPorLLamar++
          }
        }else if(server.strikes.length === 2){
          serversDosStrike.push({
            server: server,
            nombreServer: `${server.name} ${server.apellido}`,
            telefono: server.telefono,
            fecha:    server.strikes[1].fecha,
            razon:    server.strikes[1].razon,
            revisada: server.strikes[1].revisada
          })
          if(server.strikes[1].revisada == false){
            this.serversPorLLamar++
          }
        }else if(server.strikes.length === 3){
          serversTresStrike.push({
            server: server,
            nombreServer: `${server.name} ${server.apellido}`,
            telefono: server.telefono,
            fecha:    server.strikes[2].fecha,
            razon:    server.strikes[2].razon,
            revisada: server.strikes[2].revisada
          })
          if(server.strikes[2].revisada == false){
            this.serversPorLLamar++
          }
        }else if(server.strikes.length > 3){
          servrMasTresStrike.push({
            server: server,
            nombreServer: `${server.name} ${server.apellido}`,
            telefono: server.telefono,
            fecha:    server.strikes[server.strikes.length - 1].fecha,
            razon:    server.strikes[server.strikes.length - 1].razon,
            revisada: server.strikes[server.strikes.length - 1].revisada,
          })
          if(server.strikes[server.strikes.length - 1].revisada == false){
            this.serversPorLLamar++
          }
        }
        countServer++;
        if(countServer === this.listaServers.length){
          this.serversUnStrike    =  _.orderBy(serversUnStrike,    ['fecha'], ['asc']);
          this.serversDosStrike   =  _.orderBy(serversDosStrike,   ['fecha'], ['asc']);
          this.serversTresStrike  =  _.orderBy(serversTresStrike,  ['fecha'], ['asc']);
          this.servrMasTresStrike =  _.orderBy(servrMasTresStrike, ['fecha'], ['asc']);
          resolve(true);
        }
      });
    })
  }

  async getPuntuacion(){
    return new Promise(resolve=>{
      let serversCalificacionCuatro = []
      let serversCalificacionTres   = []
      let serversCalificacionDos    = []
      let serversCalificacionUno    = []
      let conutGeneral = 0
      this.listaServers.map(server =>{
        this.posventaService.getPosventaIdServer(server.id).then(res=>{
          conutGeneral++
          if(res.docs.length > 0){
            res.docs.forEach(data=>{
              let posventas = data.data()
              server.arrayPostVentas   = posventas.postventasCreadas
              server.idPostVentas = posventas.id
              server.numCalificaciones = posventas.postventasCreadas.length
              let count = 0
              let totalCalificacion = 0
              posventas.postventasCreadas.forEach(element => {
                totalCalificacion += element.puntuacionTotal
                count++
                if (count == posventas.postventasCreadas.length ) {
                  server.totalCalificacion = totalCalificacion/posventas.postventasCreadas.length
                  if((totalCalificacion/posventas.postventasCreadas.length) > 3 && (totalCalificacion/posventas.postventasCreadas.length) <= 4){
                    serversCalificacionCuatro.push(server)
                  }else if((totalCalificacion/posventas.postventasCreadas.length) > 2 && (totalCalificacion/posventas.postventasCreadas.length) <= 3){
                    serversCalificacionTres.push(server)
                  }else if((totalCalificacion/posventas.postventasCreadas.length) > 1 && (totalCalificacion/posventas.postventasCreadas.length) <= 2){
                    serversCalificacionDos.push(server)
                  }else if((totalCalificacion/posventas.postventasCreadas.length) >= 0 && (totalCalificacion/posventas.postventasCreadas.length) <= 1){
                    serversCalificacionUno.push(server)
                  }
                  this.serverService.updateCalificicacion(server.id,(totalCalificacion/posventas.postventasCreadas.length))
                  // console.log(conutGeneral,this.listaServers.length -1)
                  if(conutGeneral == (this.listaServers.length - 1)){
                    this.serversCalificacionCuatro = serversCalificacionCuatro
                    this.serversCalificacionTres   = serversCalificacionTres
                    this.serversCalificacionDos    = serversCalificacionDos
                    this.serversCalificacionUno    = serversCalificacionUno
                  }
                }
              });
            })
          }else{
            server.totalCalificacion = 0;
            this.serverService.updateCalificicacion(server.id,0).then(()=>{
              console.log('cal0')
            })
            if(conutGeneral == this.listaServers.length){
              this.serversCalificacionCuatro = serversCalificacionCuatro
              this.serversCalificacionTres   = serversCalificacionTres
              this.serversCalificacionDos    = serversCalificacionDos
              this.serversCalificacionUno    = serversCalificacionUno
              resolve(true);
            }
          }
        })
      });
    })
  }

  async getPorcentageOcupacion(){
    return new Promise(resolve=>{
      let fchInicio = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      let fchFin = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      this.listaServers.map(server=>{
        server.horasTrabajadas = 0
        this.servicioService.getByServerInDateRange(server.id,fchInicio,fchFin).then(res=>{
          server.serviciosRealizados = res.docs.length
          let count = 0
          if(res.docs.length > 0){
            res.docs.forEach(data=>{
              let servicio = data.data()
              if(servicio.estado == "Terminado"){
                server.horasTrabajadas += parseInt(servicio.horasDeServicio)
              }
              count++
              if(count == res.docs.length){
                server.porcentageDeOcupacion = `${Math.trunc((server.horasTrabajadas/(server.horasDisponible*4))*100)} %`
              }
            })
          }else{
            server.porcentageDeOcupacion = `${Math.trunc((server.horasTrabajadas/(server.horasDisponible*4))*100)} %`
          }
        })
      })
      resolve(true);
    })
  }
  
  async getDisponibilidad(){
    return new Promise(resolve=>{
      this.listaServers.map(server => {
        this.serverService.getDisponibilidad(server.id).onSnapshot(value=>{
          if(value.data() != undefined){
            let info = value.data();
            server.arrayDisponibilidad = info
            server.diasBloqueados = info.fechas.length > 0 ? info.fechas.length : 0;
            server.fechasBloqueadas = info.fechas
          }else{
            server.arrayDisponibilidad = []
            server.diasBloqueados = 0
            server.fechasBloqueadas = []
          }
        })
      });
      resolve(true);
    })
  }

  refreshView(): void {
    this.refresh.next();
  }

  onCustom(event){
    if(event.action == "verServer"){  
      this.serverVisualizada = event.data
      this.loadingModal = true
      this.prepararFormulario()
    }else if(event.action == 'llamadoAtencion'){
      console.log(event.data);
      event.data.revisada = true;
      event.data.server.strikes[event.data.server.strikes.length - 1].revisada = true
      event.data.server.strikes[event.data.server.strikes.length - 1].fechaRevicio = moment().format('YYYY-MM-DD');
      this.serverService.updateServ(event.data.server)
    }
  }

  prepararFormulario(){
    this.verGanancia          = false
    this.verSAgendadosServer  = false
    this.verPOcupacionServer  = false
    this.verSTerminadosServer = true
    console.log(this.serverVisualizada)
    let fchInicio = moment().startOf('year').format('YYYY-MM-DD');
    let fchFin    = moment().endOf('year').format('YYYY-MM-DD');
    let count     = 0
    let anio = {
      meses: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
      ganancia:        [0,0,0,0,0,0,0,0,0,0,0,0],
      S_Agendados:     [0,0,0,0,0,0,0,0,0,0,0,0],
      P_Ocupacion:     ['0','0','0','0','0','0','0','0','0','0','0','0'],
      S_Terminados:    [0,0,0,0,0,0,0,0,0,0,0,0],
      D_Bloqueados:    [0,0,0,0,0,0,0,0,0,0,0,0],
      horasTrabajadas: [0,0,0,0,0,0,0,0,0,0,0,0],
    }
    if(this.serverVisualizada.fechasBloqueadas){
      this.serverVisualizada.fechasBloqueadas.forEach(element => {
        let mes = moment(element.fecha).format('MM')
        if(mes == "01"){
          anio.D_Bloqueados[0]++
        }else if(mes == '02'){
          anio.D_Bloqueados[1]++
        }else if(mes == '03'){
          anio.D_Bloqueados[2]++
        }else if(mes == '04'){
          anio.D_Bloqueados[3]++
        }else if(mes == '05'){
          anio.D_Bloqueados[4]++
        }else if(mes == '06'){
          anio.D_Bloqueados[5]++
        }else if(mes == '07'){
          anio.D_Bloqueados[6]++
        }else if(mes == '08'){
          anio.D_Bloqueados[7]++
        }else if(mes == '09'){
          anio.D_Bloqueados[8]++
        }else if(mes == '10'){
          anio.D_Bloqueados[9]++
        }else if(mes == '11'){
          anio.D_Bloqueados[10]++
        }else if(mes == '12'){
          anio.D_Bloqueados[11]++
        }
      });
    }
    this.servicioService.getByServerInDateRange(this.serverVisualizada.id,fchInicio,fchFin).then(res=>{
      if(res.docs.length > 0){
        res.docs.forEach(data=>{
          let servicio = data.data();
          this.serverVisualizada.historialServicios = []
          let mes = moment(servicio.fecha).format('MM')
          if(mes == '01'){
            anio.S_Agendados[0]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[0]++
              anio.horasTrabajadas[0] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[0] =  `${Math.trunc((anio.horasTrabajadas[0]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[0] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '02'){
            anio.S_Agendados[1]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[1]++
              anio.horasTrabajadas[1] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[1] =  `${Math.trunc((anio.horasTrabajadas[1]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[1] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '03'){
            anio.S_Agendados[2]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[2]++
              anio.horasTrabajadas[2] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[2] =  `${Math.trunc((anio.horasTrabajadas[2]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[0] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '04'){
            anio.S_Agendados[3]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[3]++
              anio.horasTrabajadas[3] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[3] =  `${Math.trunc((anio.horasTrabajadas[3]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[3] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '05'){
            anio.S_Agendados[4]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[4]++
              anio.horasTrabajadas[4] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[4] =  `${Math.trunc((anio.horasTrabajadas[4]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[4] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '06'){
            anio.S_Agendados[5]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[5]++
              anio.horasTrabajadas[5] += parseInt(servicio.horasDeServicio)

              anio.P_Ocupacion[5] =  `${Math.trunc((anio.horasTrabajadas[5]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[5] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '07'){
            anio.S_Agendados[6]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[6]++
              anio.horasTrabajadas[6] += parseInt(servicio.horasDeServicio)

              anio.P_Ocupacion[6] =  `${Math.trunc((anio.horasTrabajadas[6]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[6] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '08'){
            anio.S_Agendados[7]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[7]++
              anio.horasTrabajadas[7] += parseInt(servicio.horasDeServicio)

              anio.P_Ocupacion[7] =  `${Math.trunc((anio.horasTrabajadas[7]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[7] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '09'){
            anio.S_Agendados[8]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[8]++
              anio.horasTrabajadas[4] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[8] =  `${Math.trunc((anio.horasTrabajadas[8]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[8] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '10'){
            anio.S_Agendados[9]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[9]++
              anio.horasTrabajadas[9] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[9] =  `${Math.trunc((anio.horasTrabajadas[9]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[9] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '11'){
            anio.S_Agendados[10]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[10]++
              anio.horasTrabajadas[10] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[10] =  `${Math.trunc((anio.horasTrabajadas[10]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[10] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }else if(mes == '12'){
            anio.S_Agendados[11]++
            if(servicio.estado == 'Terminado'){
              anio.S_Terminados[11]++
              anio.horasTrabajadas[11] += parseInt(servicio.horasDeServicio)
              anio.P_Ocupacion[11] =  `${Math.trunc((anio.horasTrabajadas[11]/(this.serverVisualizada.horasDisponible*4))*100)} %`
              anio.ganancia[11] += servicio.gananciaServer ? servicio.gananciaServer : 0
            }
          }
          count++
          if(count == res.docs.length){
            this.serverVisualizada.anioServicios = anio;
            this.loadingModal = false
            this.cargarGrafica()
            //@ts-ignore
            this.nbDialogService.open(this.mdlVerServer, { context: 'this is some additional data passed to dialog' });
          }
        })
      }else{
        this.serverVisualizada.anioServicios = anio;
        this.loadingModal = false
        this.cargarGrafica()
        //@ts-ignore
        this.nbDialogService.open(this.mdlVerServer, { context: 'this is some additional data passed to dialog' });
      }
    })
    // this.getinfoUniserv();
  }
  
  getinfoUniserv(){
    this.uniservService.getInfoServerId(this.serverVisualizada.id).then(res=>{
      if(res.docs.length > 0){
        res.docs.forEach(data=>{
          let uniserv = data.data();
          this.serverVisualizada.uniserv = uniserv;
          this.serverVisualizada.fechasFinalizacionCursos = []
          if(uniserv.cursoAseoHogar){
            this.serverVisualizada.fechasFinalizacionCursos.push(
              {
                curso: 'Aseo hogar',
                fecha: uniserv.cursoAseoHogar.infoDelCurso.fchFinalizacion,
                promedioAntes: 0,
                certificado: uniserv.cursoAseoHogar.infoDelCurso.certificado
              }
            )
          }
          if(uniserv.cursoAseoEmpresas){
            this.serverVisualizada.fechasFinalizacionCursos.push(
              {
                curso: 'Aseo empresarial',
                fecha: uniserv.cursoAseoEmpresas.infoDelCurso.fchFinalizacion,
                promedioAntes: 0,
                certificado: uniserv.cursoAseoEmpresas.infoDelCurso.certificado

              }
            )
          }
          if(uniserv.cursoTodero){
            this.serverVisualizada.fechasFinalizacionCursos.push(
              {
                curso: 'Todero',
                fecha: uniserv.cursoTodero.infoDelCurso.fchFinalizacion,
                promedioAntes: 0,
                certificado: uniserv.cursoTodero.infoDelCurso.certificado

              }
            )
          }
          if(uniserv.cursoManejoMaquinas){
            this.serverVisualizada.fechasFinalizacionCursos.push(
              {
                curso: 'Manejo de maquinas',
                fecha: uniserv.cursoManejoMaquinas.infoDelCurso.fchFinalizacion,
                promedioAntes: 0,
                certificado: uniserv.cursoManejoMaquinas.infoDelCurso.certificado
              }
            )
          }
          this.serverVisualizada.fechasFinalizacionCursos =
            _.orderBy( this.serverVisualizada.fechasFinalizacionCursos, ['fecha'], ['asc']); 
          this.medirProgreso()
        })
      }else{
        this.serverVisualizada.fechasFinalizacionCursos = []
      }
    }).catch(err=>{
      console.error('ocurrio un error en getInfoServerId',err);
      this.serverVisualizada.fechasFinalizacionCursos = []
    })
  }

  medirProgreso(){
    this.serverVisualizada.fechasFinalizacionCursos.map(element => {
      const calificaciones = this.serverVisualizada.arrayPostVentas.filter(cal=>cal.fecha<=element.fecha)
      let calificacion = 0
      calificaciones.forEach(element => {
        calificacion += element.puntuacionTotal 
      });
      element.promedioAntes = calificacion/calificaciones.length;
    });
  }

  graficoUniserv: any = {
    fechas: [],
    puntuaciones: [],
  };
  eliminarCalificacion(index){
    this.serverVisualizada.arrayPostVentas.splice(index, 1);
    let info = {
      id: this.serverVisualizada.idPostVentas,
      idServer: this.serverVisualizada.id,
      postventasCreadas: this.serverVisualizada.arrayPostVentas,
    }
    this.posventaService.eliminarCalificacion(info)
  }

  datePickerCalificaciones(event: IMyDateRangeModel){
    const startDate = moment(event.beginJsDate).format('YYYY-MM-DD');
    const endDate = moment(event.endJsDate).format('YYYY-MM-DD');
    this.calcularGraficaCalificacione(startDate,endDate)
  }

  calcularGraficaCalificacione(startDate,endDate){
    let count = 0;
    this.graficoUniserv = {
      fechas: [],
      puntuaciones: [],
    };
    this.serverVisualizada.arrayPostVentas.forEach(element => {
      if(element.fecha >= startDate && element.fecha <= endDate){
        this.graficoUniserv.fechas.push(element.fecha)
        this.graficoUniserv.puntuaciones.push(element.puntuacionTotal)
      }
      count++
      if(count == this.serverVisualizada.arrayPostVentas.length){
        console.log("datso para la gafica uniserv",this.graficoUniserv);
        this.cargarGraficaUniserv()
      }
    });
  }
  cargarGraficaUniserv(){
    this.chartUniserv = {
      series: [
        {
          name: "Calificacion",
          data:  this.graficoUniserv.puntuaciones,
        }
      ],
      chart: {
        height: 550,
        type: "area",
      },
      colors: ["#775DD0"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Servcios",
        align: "left"
      },
      xaxis: {
        type: "category",
        categories: this.graficoUniserv.fechas,
        title: {
          text: "Dia"
        },
      },
      yaxis:{
        max:5,
        min: 0,
      },
    }
  }

  public verGrafica           = false;
  public verGanancia          = false;
  public verHorasServer       = false;
  public verSAgendadosServer  = false;
  public verPOcupacionServer  = false;
  public verSTerminadosServer = true;

  cargarGrafica(){
    let datosGrafica = [];
    let coloresGrafica = [];
    console.info('dataos de la server visualizada',this.serverVisualizada);
    if(this.verHorasServer == true){
      datosGrafica.push( 
        {
          name: "H.Trabajadas",
          data: this.serverVisualizada.anioServicios.horasTrabajadas,
        },
      )
      coloresGrafica.push("#95a618")
    }
    if(this.verSAgendadosServer == true){
      datosGrafica.push(
        {
          name: "S.Agendados",
          data: this.serverVisualizada.anioServicios.S_Agendados,
        },
      )
      coloresGrafica.push("#fb6100")
    }
    if(this.verSTerminadosServer == true){
      datosGrafica.push( 
        {
          name: "S.Terminados",
          data: this.serverVisualizada.anioServicios.S_Terminados,
        },
      )
      coloresGrafica.push("#0fe16d")
    }
    if(this.verPOcupacionServer == true){
      datosGrafica.push( 
        {
          name: "% Ocupacion",
          data: this.serverVisualizada.anioServicios.P_Ocupacion,
        },
      )
      coloresGrafica.push("#00d0ff")
    } 
    if(this.verGanancia == true){
      datosGrafica.push( 
        {
          name: "Ganancia",
          data: this.serverVisualizada.anioServicios.ganancia,
        },
      )
      coloresGrafica.push("#4cffc0")
    }
    // console.log(datosGrafica)
    this.chartServiciosPrimerRango = {
      series: datosGrafica,
      chart: {
        height: 550,
        type: "area",
      },
      colors: coloresGrafica,
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Servcios",
        align: "left"
      },
      xaxis: {
        type: "category",
        categories: this.serverVisualizada.anioServicios.meses,
        title: {
          text: "Dia"
        },
      },
    /*   tooltip: {
        x: {
          format: "dddd/MM/yy"
        }
      }, */
      markers: {
        size: 1
      },
    };
    this.verGrafica = true;
  }

  
}