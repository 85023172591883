import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  constructor() { }

  getByDateRange(begin_date,end_date){
    return firebase.firestore().collection('/candidates')
    .where('fecha','>=',begin_date)
    .where('fecha','<=',end_date)
    .get();
  }
}
