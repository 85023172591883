// autocomplete.component.ts
import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  
  keyword = 'name';
  
  data: any = {};

  constructor( public ServerService: ServerService) {  }

  ngOnInit() {

    this.getServers();
    
  }

   public getServers() {
    this.ServerService.all()
    .then((value) => {
      this.data = value
        
      })
   
    
  }
 
  selectEvent(item) {
    // do something with selected item
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }

}