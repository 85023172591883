import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LeadsComponent } from '../leads/leads.component'
import { AuthService } from 'src/app/services/auth.service';



//Servicios
import { LeadsService } from '../../services/leads.service';
import { UsuarioService } from '../../services/usuario.service';
import { ServBackendService } from 'src/app/services/serv-backend.service';

@Component({
  selector: 'app-mdls-leads',
  templateUrl: './mdls-leads.component.html',
  styleUrls: ['./mdls-leads.component.scss']
})
export class MdlsLeadsComponent implements OnInit {
  @ViewChild("mdlCrearLead", { static: true }) mdlCrearLead: ElementRef;
  @ViewChild("mdlGestionarLead", { static: true }) mdlGestionarLead: ElementRef;
  @Input() modalRequerido = '';
  @Input() infoLead: any;

  public leadNuevo: any = {}

  spinerCrearLead = false
  agregarComentarioLead = false;
  nuevoComentarioLead = {
    fecha: moment().format('YYYY-MM-DD'),
    comentario: '',
  }
  usuarioEnSecion: any;
  tipoModal: string;

  public antiguedadCliente: string = "cliente nuevo";
  locationOfService: string = 'hogar';
  sectionsHogar = [
    {
      name: 'Aseo y servicios generales',
      options: [
        { id: 1, name: 'Empleada doméstica' },
        { id: 2, name: 'Empleada internas' },
        { id: 3, name: 'Preparación de alimentos' },
        { id: 4, name: 'Experta planchado' }
      ]
    },
    {
      name: 'Asistencia y cuidado',
      options: [
        { id: 5, name: 'Niñera - Cuidadora de niños' },
        { id: 6, name: 'Asistencia y cuidado de adultos mayores' },
        { id: 7, name: 'Asistente - ayudante multitarea' }
      ]
    },
    {
      name: 'Servicios especializados',
      options: [
        { id: 8, name: 'Instalador' },
        { id: 9, name: 'Pintor' },
        { id: 10, name: 'Jardinero' },
        { id: 11, name: 'Desinfección y termonebulización' },
        { id: 12, name: 'Personal de cathering' },
      ]
    }
  ];
  sectionsEmpresas = [
    {

      name: 'Aseo y servicios generales',
      options: [
        { id: 1, name: 'Limpieza de oficinas' },
        { id: 2, name: 'Limpieza de consultorios' },
        { id: 3, name: 'Servicio express de limpieza' },
      ]
    },
    {
      name: 'Servicios especializados',
      options: [
        { id: 4, name: 'Toderos' },
        { id: 5, name: 'Jardinero' },
        { id: 6, name: 'Instalador' },
        { id: 7, name: 'Personal de cathering' },
        { id: 8, name: 'Asistente de mudanzas' },
        { id: 9, name: 'Desinfección y termonebulización' },
      ]
    },
  ];
  sectionsConjuntos = [
    {
      name: 'Aseo y servicios generales',
      options: [
        { id: 1, name: 'Limpieza de conjuntos' },
        { id: 2, name: 'Empleada internas' },
      ]
    },
    {
      name: 'Servicios especializados',
      options: [
        { id: 3, name: 'Instalador' },
        { id: 4, name: 'Pintor' },
        { id: 5, name: 'Jardinero' },
        { id: 6, name: 'Desinfección y termonebulización' },
        { id: 7, name: 'Personal de cathering' },
      ]
    }
  ];

  constructor(
    public leadsComponent: LeadsComponent,
    public usuarioService: UsuarioService,
    private nbDialogService: NbDialogService,
    private leadsService: LeadsService,
    private servBackendService: ServBackendService,
    private authService: AuthService

  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData('usuario');
  }

  ngOnInit() {
    console.log(this.infoLead)
    console.log(this.leadNuevo)
    this.abrirModal()
  }



  abrirModal() {
    if (this.modalRequerido == 'crearLead') {
      // @ts-ignore 
      this.nbDialogService.open(this.mdlCrearLead)
    } else if (this.modalRequerido == 'gestionarLead') {
      // @ts-ignore 
      this.nbDialogService.open(this.mdlGestionarLead)
    }
  }

  async crearLead() {
    this.spinerCrearLead = true
    console.log(this.leadNuevo)
    if (this.leadNuevo.correo && this.leadNuevo.estado && this.leadNuevo.frecuencia && this.leadNuevo.como && this.leadNuevo.precio) {
      if (this.leadNuevo.estado == 'Prueba' || this.leadNuevo.estado == 'Plan' || this.leadNuevo.estado == 'Servicio unico' || this.leadNuevo.estado == 'Perdido') {
        this.leadNuevo.updateFinalizacion = moment().format('YYYY-MM-DD');
      }

      // this.leadNuevo.estado = 'Solicitud de cotizacion';
      this.leadNuevo.updateInteresados = moment().format('YYYY-MM-DD')
      this.leadNuevo.fechaDeCreacion = moment().format('YYYY-MM-DD')
      this.leadNuevo.agenteComercial = this.usuarioEnSecion.displayName;
      this.leadNuevo.id = '';
      this.leadsService.postLead(this.leadNuevo).then(() => {
        this.spinerCrearLead = false;
        Swal.fire(
          'Éxito',
          'Se agregó el leadNuevo exitosamente',
          'success'
        ).then(() => {
          const element: HTMLElement = document.getElementById("btn-close") as HTMLElement;
          element.click();
        })
      })
    } else {
      Swal.fire(
        'Error',
        'Verifica que esten todos los campos completos',
        'warning')
      this.spinerCrearLead = false
    }
  }
  public makeId() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  public guardarComentario() {
    this.agregarComentarioLead = false;
    if (this.infoLead.comentarios) {
      this.infoLead.comentarios.push({
        fecha: this.nuevoComentarioLead.fecha,
        comentario: this.nuevoComentarioLead.comentario,
      })
    } else {
      this.infoLead.comentarios = []
      this.infoLead.comentarios.push({
        fecha: this.nuevoComentarioLead.fecha,
        comentario: this.nuevoComentarioLead.comentario,
      })
    }
    this.leadsService.editLead(this.infoLead).then(() => {
      this.agregarComentarioLead = false;
      this.nuevoComentarioLead.comentario = '';
      Swal.fire(
        'Listo',
        'Comentario guardado',
        'success',
      )
    })
  }

  modificarEstado(event) {
    if (event.target.value == 'Visita') {
      this.infoLead.fechaDeVisita = 'PorAsigna';
    } else if (event.target.value == 'Prueba') {
      this.infoLead.fechaDeVisita = 'Falta';
      this.infoLead.fechaContactarNuevamente = 'PorAsigna';
    }
  }

  actualizarLead() {
    if (this.infoLead.fechaContactarNuevamente === 'PorAsigna') {
      Swal.fire(
        'Espera',
        'Debes asignar una fecha del proximo contacto',
        'warning',
      )
    } else if (this.infoLead.fechaDeVisita === 'PorAsigna') {
      Swal.fire(
        'Espera',
        'Debes asignar una fecha para la visita',
        'warning',
      )
    } else {
      if (this.infoLead.estado == 'Interesados') {
        this.infoLead.estado = 'Cotizar'
        this.infoLead.updateCotizar = moment().format('YYYY-MM-DD');
      } else if (this.infoLead.estado == 'Visita') {
        this.infoLead.updateVisita = moment().format('YYYY-MM-DD');
      } else if (this.infoLead.estado == 'Prueba' || this.infoLead.estado == 'Plan' || this.infoLead.estado == 'Servicio unico' || this.infoLead.estado == 'Perdido') {
        this.infoLead.updateFinalizacion = moment().format('YYYY-MM-DD');
      } else {
        console.log(this.infoLead.estado)
      }
      if ((this.infoLead.estado == 'Plan' || this.infoLead.estado == 'Servicio unico') && this.infoLead.rd_station_id) {
        let info = {
          "rd_id": this.infoLead.rd_station_id,
          "stage": "Client"
        }
        this.servBackendService.put('api/rd-station/update-lead', info).subscribe(res => {
        })
      }
      console.log(this.infoLead)
      this.leadsService.editLead(this.infoLead).then(() => {
        // this.leadsComponent.onCargar();
        Swal.fire(
          'Listo',
          'Lead actualizado',
          'success',
        ).then(() => {
          if (['Visita', 'Prueba', 'Plan', 'Servicio unico'].includes(this.infoLead.estado)) {
            this.validarClienteLead(this.infoLead.correo)
          }
        })
      })
    }
  }



  clienteLead: any;
  modalClientes: boolean = false
  validarClienteLead(email) {
    let a = this.usuarioService.getUsersByProperty('email', email).then(res => {
      res.docs.forEach(data => {
        const cliente = data.data();
        console.log(cliente)
        if (cliente.length > 0) {
          this.clienteLead = cliente[0]
          this.tipoModal = 'editar'
        } else {
          this.clienteLead = 'falta'
          this.tipoModal = 'crear'
        }
        this.modalClientes = false
        setTimeout(() => {
          this.modalClientes = true
        }, 500)
      })
    })
  }
}
