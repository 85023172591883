import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NbDialogService, NbToastrService, } from "@nebular/theme";
import { AngularFireFunctions } from '@angular/fire/functions';
import { LocalDataSource } from "ng2-smart-table";
import { CompleterService } from "ng2-completer";
import Swal from 'sweetalert2';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { CardinalPoints, ClientType, Genders, PropertiesTypeList, States, TypeDocuments,OriginDefault } from 'src/app/utility/dataInfo';
import {localidadesColombia,departamentos} from "src/app/utility/country";
import { Gps } from 'src/app/models/interface/Gps.interface';

/* Servicios */
import { LeadsService } from 'src/app/services/leads.service';
import * as moment from 'moment';
import { getTreeNoValidDataSourceError } from '@angular/cdk/tree';
import { ServicioService } from 'src/app/services/servicio.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { GoogleService } from 'src/app/services/google.service';
import { ServerService } from 'src/app/services/server.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import {SelectionModel} from '@angular/cdk/collections';

import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';

import * as XLSX from 'xlsx';
import { info } from "console";
import { ServBackendService } from "src/app/services/serv-backend.service";
// import * as fs from 'fs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {
  @ViewChild("mdlMarketing", { static: true }) mdlMarketing: ElementRef;
  textForSms = '';
  textForEmail = '';
  tipoDeMensaje = '';
  clientsForMarketing: any = [];

  mode: 'over';
  showFiller = false; 
  modalRequerido = 'crearLead';
  leadVisualizado: any;
  abrirModal = false;

  fechaInicioGeneral = moment().subtract(30,'days').format('YYYY-MM-DD');
  fechaFinGeneral = moment().format('YYYY-MM-DD');
  public zonas: any = [
    { value: "norte", viewValue: 'Zona Norte' },
    { value: "sur", viewValue: 'Zona Sur' },
    { value: "occidente", viewValue: 'Zona occidente' },
    { value: "oriente", viewValue: 'Zona Oriente' },
    { value: "centro", viewValue: 'Zona Centro' },
    { value: "sabana", viewValue: 'Sabana' },
    { value: "chia", viewValue: 'chia' },
    { value: "funza", viewValue: 'funza' },
  ];

  public enviado: boolean;
  public leadNuevo: any = {};
  public leadExport: any = [];
  public data99: any = [];
  public source99: any;
  public lead: any = {};
  public data: any = [];
  public data1: any = [];
  public data2: any = [];
  public data3: any = [];
  public data4: any = [];
  public data5: any = [];
  public data6: any = [];
  public data7: any = [];
  public data8: any = [];
  public data9: any = [];
  public dataEcxel: any = [];



  public selectedRows: any;
  public motrarBotonEliminar: boolean = false;
  public mostrarBotonEstado: boolean = false;
  public  nuevaDireccion = {
    country: "Colombia",
    nombreDireccion: "Mi casa",
    department: "",
    cities: "",
    neighborhood: "",
    direccion: "",
    observaciones: "",
    lat: 0,
    lng: 0,
    tipoPropiedad: "",
    idLocation:"",
  }
  public usuariosVentas = [];
  public usuarioEnSecion: any;

  permisoTotal = false;
  filtroFrecuencia = []
  /*  Para el modal */
  @ViewChild("crearLead", { static: true }) crearLead: ElementRef;
  @ViewChild("mdlCrearCliente", { static: true }) mdlCrearCliente: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  dataAllLeads: any = [];
  miercoles: string;
  jueves: string;
  activarMetricas: boolean = false;
  verModalClientes: boolean = false;
  tipoModal: string;
  /*****/

  constructor(
    public completerService: CompleterService,
    private googleService: GoogleService,
    private dialogService: NbDialogService,
    private leadsService: LeadsService,
    private servicioService: ServicioService,
    private toastrService: NbToastrService,
    public ServerService: ServerService,
    public usuarioService: UsuarioService,
    private fns: AngularFireFunctions,
    public notificationsService: NotificationsService,
    public datepipe: DatePipe,
    private authService: AuthService,
    private servBackendService: ServBackendService
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData('usuario');
    if(this.usuarioEnSecion.rol == 'administrador' || this.usuarioEnSecion.id == '0GWa82yo31deU15knHdUXFio4rv2'){
      this.permisoTotal = true;
      this.getUsuariosRolVentas();
    }else{
      this.usuariosVentas = [this.usuarioEnSecion]
    }
    if(this.authService.idBuquedaNotificacion != "falta"){
      this.verLeadDesdeNoti()
    }
  }

  ngOnInit() {
    this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,true);
    // this.getPlace();
    // this.getGps();
  }
  public verLeadDesdeNoti(){
    let id = this.authService.idBuquedaNotificacion;
    this.authService.idBuquedaNotificacion = "falta";
    this.leadsService.getLeadbyId(id).then(res=>{
      this.leadVisualizado = res.docs[0].data();
      this.abrirModals('gestionarLead');
    })
  }

  getUsuariosRolVentas(){
    this.authService.getUsuarioAdminUnicaPropiedad('rol','ventas').then(res=>{
      this.usuariosVentas = res.docs.map(doc=>{
        return {
          ...doc.data()
        };
      })
    })
   }

  abrirModals(type){
    this.modalRequerido = type;
    this.abrirModal = false
    setTimeout(()=>{
      this.abrirModal = true
    },1000)
  }

  clienteLead: any;
  validarClienteLead(info){
    let a = this.usuarioService.getUsersByProperty('email',info.correo).then(res=>{
      res.docs.forEach(data=>{
        const cliente = data.data();
        if(cliente.length > 0){
          this.clienteLead = cliente[0]
          this.tipoModal = 'editar';
        }else{
          this.clienteLead = info
          this.tipoModal = 'crear';
        }
        this.verModalClientes = false
        setTimeout(()=>{
          this.verModalClientes = true
        },500)
      })

    })
  }

  public onCargar(fechaInicio,fechaFin,primeraConsulta) {
    this.data   = []; //Solicitud de cotizacion
    this.data1  = []; //Interesados
    this.data2  = []; //No contesta
    this.data3  = []; //Plan
    this.data4  = []; //ServicioUnico
    this.data5  = []; //Perdido
    this.data6  = []; //Visita
    this.data7  = []; //Cotizacion

    this.leadsService.getLeadsRangoFecha(fechaInicio,fechaFin).then(async res => {
      console.info('Total leads consultados',res.size)

      const a = await this.ordenarleads(res.docs)
      if(a){
        if(primeraConsulta){
          this.data = _.orderBy(this.data,['fechaDeCreacion'],['desc'])
          this.tableDataSource = this.permisoTotal ? new MatTableDataSource(this.data) : new MatTableDataSource(this.data1);
          this.tableDataSource.paginator = this.paginator;
          this.tableDataSource.sort = this.sort;
          if(!this.permisoTotal){
            this.tableDisplayedColumns = ['select','fechaDeCreacion','nombre','telefono','correo','servicio','precio','como','ciudad']
          }
          this.loading = false;
        }else{
          this.changeTable(this.tablaVsualizada)
        }
      }
    }).catch((err) => {
      console.error('Error al consultar los leads',err);
      this.lead = this.data;
    });
  }
  ordenarleads(data) {
    return new Promise(result=>{
      try{
        for(let i = 0;i< data.length;i++){
          const lead = data[i].data();
          lead.fechaDeCreacion = moment(lead.fechaDeCreacion).format('YYYY-MM-DD')
          lead.id = data[i].id;
          lead.phoneNumber = lead.telefono;
          lead.name = lead.nombre;
          this.dataAllLeads.push(lead)

          switch (lead.estado){
            case 'Solicitud de cotizacion':
              this.data.push(lead)
              break;
            case 'Interesados':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data1.push(lead)
              break
            case 'No contesta':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data2.push(lead)
              break
            case 'Plan':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data3.push(lead)
              break
            case 'Servicio unico':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data4.push(lead)
              break
            case 'Perdido':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data5.push(lead)
              break
            case 'Visita':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data6.push(lead)
              break
            case 'Cotizacion':
              if(this.usuarioEnSecion.displayName == lead.agenteComercial || this.permisoTotal) this.data7.push(lead)
              break
            default:
              console.log("Estado no contemplado",lead.estado)
              this.data.push(lead)
              break;
          }
        }
        return result(true)
      }catch{
        return result(false)
      }
    })
  }

  // asignarLeads(){
  //   if(this.permisoTotal == false && this.usuarioEnSecion.rol === 'ventas'){
  //     if(this.data1.length < 20){
  //       let leadsPosibles = []
  //       if(this.usuarioEnSecion.rango == 'comercial'){
  //         this.data.map(lead =>{
  //           if(['Unico servicio','1 vez por semana','2 vez por semana','3 vez por semana'].includes(lead.frecuencia)){
  //             leadsPosibles.push(lead) 
  //           }
  //         })
  //       }else{
  //         leadsPosibles = this.data;
  //       } 
  //       leadsPosibles = _.orderBy(leadsPosibles,['fechaDeCreacion'],['desc'])
  //       if(leadsPosibles.length > 0){
  //         for(let i = this.data1.length; i < 20 ;i++){
  //           leadsPosibles[0].agenteComercial = this.usuarioEnSecion.displayName
  //           leadsPosibles[0].estado = 'Interesados'
  //           leadsPosibles[0].updateInteresados = moment().format('YYYY-MM-DD')
  //           this.data1.push(leadsPosibles[0])
  //           this.leadsService.editLead(leadsPosibles[0]).then(()=>{
  //           }).catch(err=>{
  //           })
  //           leadsPosibles.splice(0,1);
  //         }
  //         this.cargarTablas()
  //       }else{
  //         this.cargarTablas()
  //       }
  //     }else{
  //       this.cargarTablas()
  //     }
  //   }else{
  //     this.cargarTablas()
  //   }
  // }

  public onUserRowSelect(event) {
    this.selectedRows = event.selected;
    if (event.selected.length > 0) {
      this.motrarBotonEliminar = true;
      this.mostrarBotonEstado = true;
    } else {
      this.motrarBotonEliminar = false;
      this.mostrarBotonEstado = false;
    }
  }

  public agentes = [
    {
      nombre: 'Maria Camila Cadavid Alzate',
      Interesados: 0,
      Cotizar:    0,
      Visita:     0,
      Prueba:     0,
      Plan:     0,
      Perdido:     0,
      ServicioUnico: 0,
      totalAsignados: 0,
    },
    {
      nombre: 'Alexandra Gamboa',
      Interesados:    0,
      Cotizar:        0,
      Visita:         0,
      Prueba:         0,
      Plan:           0,
      Perdido:        0,
      ServicioUnico: 0,
      totalAsignados: 0,
    },
    {
      nombre: 'Meztly Castillo',
      Interesados:    0,
      Cotizar:        0,
      Visita:         0,
      Prueba:         0,
      Plan:           0,
      Perdido:        0,
      ServicioUnico: 0,
      totalAsignados: 0,
    },
    {
      nombre: 'Maria Alejandra Arteaga Hernandez',
      Interesados:    0,
      Cotizar:        0,
      Visita:         0,
      Prueba:         0,
      Plan:           0,
      Perdido:        0,
      ServicioUnico: 0,
      totalAsignados: 0,
    },

  ]

  datePickerMetricas(event){
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    this.jueves    = beginDate
    this.miercoles = endDate
    this.metricasAgentes();
  }
  public metricasAgentes(){
    this.agentes = [
      {
        nombre: 'Maria Camila Cadavid Alzate',
        Interesados: 0,
        Cotizar:    0,
        Visita:     0,
        Prueba:     0,
        Plan:     0,
        Perdido:     0,
        ServicioUnico: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Alexandra Gamboa',
        Interesados:    0,
        Cotizar:        0,
        Visita:         0,
        Prueba:         0,
        Plan:           0,
        Perdido:        0,
        ServicioUnico: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Meztly Castillo',
        Interesados:    0,
        Cotizar:        0,
        Visita:         0,
        Prueba:         0,
        Plan:           0,
        Perdido:        0,
        ServicioUnico: 0,
        totalAsignados: 0,
      },
      {
        nombre: 'Sin agente asignado',
        Interesados:  0,
        Cotizar:      0,
        Visita:       0,
        Prueba:       0,
        Plan:         0,
        Perdido:      0,
        ServicioUnico: 0,
        totalAsignados: 0,
      }, 
    ]
    let a = 0;
    this.dataAllLeads.forEach(element => {
      if(element.updateInteresados == undefined){
        element.updateInteresados = "2022-01-01"
        this.ajustarLeads(element)
      }
      if(element.updateInteresados >= this.jueves && element.updateInteresados <= this.miercoles){
        if(element.agenteComercial == 'Maria Camila Cadavid Alzate'){
          a = 0
        }else if(element.agenteComercial == 'Alexandra Gamboa'){
            a = 1;
            // this.agentes[1][element.estado] ++
        }else if(element.agenteComercial == 'Meztly Castillo'){
            a = 2;
        }else{
          a = 2;
        }
        if(element.estado == 'Servicio unico'){
          this.agentes[a].ServicioUnico ++
        }else{
          this.agentes[a][element.estado] ++
        }
        this.agentes[a].totalAsignados ++
      }
    });
    this.activarMetricas = true
  }
  datePicker(event){
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD"); 
    this.onCargar(beginDate,endDate,false);
  }

  public showToast(position, status, titulo, mensaje) {
    this.toastrService.show(
      mensaje,
      titulo,
    );
  }

  public onVer(event) {

    this.lead = event.data
    if (this.lead.servicio == "empleada domestica" || this.lead.servicio == "cuidado de adultos y niños") { this.lead.idTemplate = "d-4c7d7a9588f044e686aed12684a3927c" }
    else if (this.lead.servicio == "desinfeccion termonebulizacion hogares" || this.lead.servicio == "desinfeccion termonebulizacion empresas" || this.lead.servicio == "desinfeccion aspersion hogares" || this.lead.servicio == "desinfeccion aspersion empresas") { this.lead.idTemplate = "d-939b7e13e051467e89095ed89e4bd99c" }
    else if (this.lead.servicio == "Limpieza propiedad horizontal") { this.lead.idTemplate = "d-24fb32494a8d4e2b8e72a4a575ed274f" }
    else { this.lead.idTemplate = "d-24fb32494a8d4e2b8e72a4a575ed274f" }
    this.lead.estado = "lead por contactar"
    this.leadsService.editLead(this.lead) 
    this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,false);
  }
  
  public crear() {
    this.enviado = false;
    this.leadNuevo.id = this.makeId();
    this.leadNuevo.estado = 'Solicitud de cotizacion';
    this.leadNuevo.fechaDeCreacion = Date.now();
    if (this.leadNuevo.nombre && this.leadNuevo.asesor && _.includes(this.leadNuevo.correo, "@") && this.leadNuevo.horas && this.leadNuevo.frecuencia && this.leadNuevo.servicio && this.leadNuevo.estado && this.leadNuevo.id) {
      this.leadsService.postLead(this.leadNuevo).then((result) => {
        Swal.fire(
          'Éxito',
          'Se agregó el leadNuevo exitosamente',
          'success'
        );
        this.cerrarModal();
        this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,false);
      }).catch(err => {
        this.enviado = true
        Swal.fire(
          'Error',
          'No se pudo agregar el leadNuevo, verifique la información',
          'warning'
        );
      });
    } else {
      Swal.fire(
        'Error',
        'No se pudo agregar el aspirante, verifique la información',
        'warning'
      );
    }
  }

  /**
   * Funcion para borrar una aspirante
   * @param event aspirante que se desea borrar
   */
  public onEliminar(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el lead?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res.value) {
        this.leadsService.eliminarLeads(event.data);
        Swal.fire(
          'Éxito',
          'Lead eliminado con éxito',
          'success'
        );
        this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,false);
      }
    }, (err) => {
      console.error('Error al eliminar el lead',err);
      Swal.fire(
        'Error',
        'Problema eliminando el Lead',
        'warning'
      )
    });
    this.spinerGuardar = false;
  }

  /**
   * Funcion para abrir el modal de aspirantes
   */
  public modalLead() {
    this.leadNuevo = {};
    this.leadNuevo.estado = "Solicitud recibida";
    this.openDialogSave(this.crearLead);
  }

  /**
   * Funcion para abrir los modales
   * @param dialog Modal que se desee abrir
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, {
      context: "this is some additional data passed to dialog",
    });
  }

  /**
   * Funcion para cerrar el modal
   */

  public cerrarModal() {
    this.spinerEdit = false;
    const element: HTMLElement = document.getElementById("btn-close") as HTMLElement;
    element.click();
  }

  /**
   * Funcion que determina que accion se selecciono en la tabla
   * @param event datos seleccionados
   */

  onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.onEliminar(event);
        break;
      case 'gestionarLead':
        this.leadVisualizado = event.data
        this.abrirModals('gestionarLead')
        break;
      case 'crearCliente':
        this.validarClienteLead(event.data)
        break;
    }
  }

  /**
   * Funcion para crear id aleatorio
   */
  public makeId() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  /*----------Modal crear cliente------------------*/
  public clienteNuevo: any = {
    direccionesArray: [],
  }

  public estadoClte:       any = States;
  public generoClte:       any = Genders;
  public tipoCliente:      any = ClientType;
  public tipoDocumento:    any = TypeDocuments;
  public puntosCardenales: any = CardinalPoints;
  public tipoPropiedades:  any = PropertiesTypeList;

  
  arrayDepartamentos: any = departamentos
  arrayCiudades: any;
  public paisArray:         any = localidadesColombia;
  public departmentArray:   any = [];
  public citiesArray:       any = [];
  public suburbsArray:      any = [];
  public neighborhoodArray: any = [];
  public placePredictions:  any;

  public arrayClienteNuevoComentario: any = [];
  public arrayClienteNuevoComentarioServer: any = [];
  public comentario = {
    name: "",
    comentario: "IngresaComentario",
    fecha: moment().format("YYYY-MM-DD"),
  }
  public ingresarComentarioServer: boolean = false;
  public ingresarComentarioLogistica: boolean = false;

  modalCiente(event){
    this.clienteNuevo.name = event.nombre
    this.clienteNuevo.apellido = event.apellido
    this.clienteNuevo.telefono = event.telefono
    this.clienteNuevo.email = event.correo
    this.openDialogSave(this.mdlCrearCliente);
  }

  buscarCiudades(event){
    let value = event.target.value;
    this.nuevaDireccion.idLocation =  value == "Cundinamarca" ? "4fZC8McSlOvpPfIZnG7w" : "4fZC8McSlOvpPfIZnG7w"
    let index = this.arrayDepartamentos.findIndex(data => data.name == value);
    this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
  }

  getPlace(){
    this.googleService.currentData.subscribe((place)=>{
      this.placePredictions = place;
    })
  }

  public origin: any;
  public originDefault: Gps = OriginDefault;
   getGps(){
    this.googleService.currentGps.subscribe((origin: Gps)=>{
      this.origin = (origin != null && origin.lat != null && origin.lng != null ) ? origin : this.originDefault;
      this.nuevaDireccion.lat = (origin != null && origin.lat != null && origin.lng != null ) ? this.origin.lat : 0;
      this.nuevaDireccion.lng = (origin != null && origin.lat != null && origin.lng != null ) ? this.origin.lng : 0;
    })
  }

  onSearchPlace(_term: string, _city: string) {
    const text: string    = _term;
    const city: string    = _city;
    const status: boolean = city.includes('D.C.');
    if (text === '') { return; }
    const search = `${status ? city.replace("D.C.", '') : city } ${text}, Colombia`;
    this.googleService.getPlacePredictions(search);
  }

  onSelectedPlace(place){
    this.googleService.getGpsPlace(place.description);
  }

  seleccionarDireccion(place){
    this.googleService.getGpsPlace(place.description);
    this.nuevaDireccion.direccion = place.description;
  }
  
  guardarDireccion(){
    this.clienteNuevo.direccionesArray.push(this.nuevaDireccion)
    this.nuevaDireccion = {
      country: "Colombia",
      nombreDireccion: "Mi casa",
      department: "",
      cities: "",
      neighborhood: "",
      direccion: "",
      observaciones: "",
      lat: 0,
      lng: 0,
      tipoPropiedad: "",
      idLocation:"",
    }
  }

  onUpload(event,tipo) {
    let file: any;
    let pictureName:any;
    let image = false;
    let nameImage:any;
    let fotoSeleccionada: any;
    console.log("files",event.target.files[0])
    file = event.target.files[0];
    pictureName = Date.now() + file.name;
    image = true;
    if (event.target.files && file) {
      nameImage = file.name;
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>event.target).result;
        console.log("foto seeccionada",fotoSeleccionada)
      }
      reader.readAsDataURL(event.target.files[0]);

    }else{console.log("no pasa el primer if")}
    if (file && pictureName) {
      this.servicioService.putPicture(pictureName, file).then(value => {
        if (value.state == 'success') {
          this.servicioService.getURL(pictureName).subscribe(url => {
            if(tipo == 'contrato'){
              this.clienteNuevo.contrato = url;
            }else if(tipo == 'rut'){
              this.clienteNuevo.rut = url;
              console.log("tipo rut",this.clienteNuevo.rut)
            }else if(tipo == 'cedula'){
              this.clienteNuevo.cedula = url;
              console.log("tipo cedula",this.clienteNuevo.cedula)
            }else{
              console.log(tipo)
            }
          }); 
        }
      }).catch(err => {
        console.log(err);
      });
    }else{console.log("no asa el segundo if")}
  }

  AddComentario(tipo) {
    this.comentario.name = this.usuarioEnSecion.displayName;
    if(tipo == 'server'){
      this.ingresarComentarioServer = true;
      console.log( this.comentario,this.usuarioEnSecion.displayName)
    } else if(tipo == 'logistica'){
      this.ingresarComentarioLogistica = true;
    }
  }

  agregarComentario(tipo){
    if(tipo == 'logistica'){
      this.comentario.name = this.usuarioEnSecion.displayName;
      this.arrayClienteNuevoComentario.push(this.comentario)
      console.log(this.arrayClienteNuevoComentario);
      this.ingresarComentarioLogistica = false;
    }else{
      this.comentario.name = this.usuarioEnSecion.displayName;
      this.arrayClienteNuevoComentarioServer.push(this.comentario)
      console.log(this.arrayClienteNuevoComentarioServer);
      this.ingresarComentarioServer = false;
    }
    this.comentario = {
      name: '',
      comentario: 'ingresaComentario',
      fecha: moment().format("YYYY-MM-DD"),
    }
  }

  Cancelar() {
    this.ingresarComentarioLogistica = false;
    this.ingresarComentarioServer = false;
    this.comentario = {
      name: '',
      comentario: 'ingresaComentario',
      fecha: moment().format("YYYY-MM-DD"),
    }
  }

  verOcultarClave(){
    console.log("entro en verOcultarClave")
    let x = (<HTMLInputElement>document.getElementById("inputClave"))
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  
  RegistrarClienteNuevo(){
    this.clienteNuevo.rol        = 'usuario',
    this.clienteNuevo.registrado = "leads"
    this.clienteNuevo.estado     = "activo"
    this.clienteNuevo.fecha      = Date.now(),
    this.clienteNuevo.cread_at   = Date.now(),
    this.clienteNuevo.update_at  = Date.now(),
    this.clienteNuevo.comentario       = this.arrayClienteNuevoComentario;
    this.clienteNuevo.comentarioServer = this.arrayClienteNuevoComentarioServer;
    this.authService.registerUser(this.clienteNuevo)
    console.log(this.clienteNuevo)
  }

  eliminarDuplicados(){
    this.dataAllLeads.forEach(element => {
      let duplicado = this.dataAllLeads.filter(info=>info.correo == element.correo)
      if(duplicado.length > 1){
        console.log(1,duplicado);
        this.eliminaLead(duplicado)
      }
    });
  }
  eliminaLead(duplicado){
    for(let i = 0;i < (duplicado.length-1);i++){
      console.log(duplicado[i]);
      this.leadsService.eliminarLeads(duplicado[i].id).then(()=>{
        console.log("A");
      })
    }
  }

  ajustarLeads(element){
    this.leadsService.getLeadbyId(element.id).then(res=>{
      if(res.docs.length>0){
        console.log(res.docs[0].id,res.docs)
        if(res.docs[0].id !== res.docs[0].data().id){
          console.log("a")
          let lead = res.docs[0].data()
          lead.id = res.docs[0].id
          this.actualizarLead(lead)
        }else{
          this.actualizarLead(element)
        }
      }
    })
  }

  /* ------------ New tabs whit angularMaterial -------------*/
  @ViewChild('paginator' , { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  loading = true;
  tableDisplayedColumns = ['select','fechaDeCreacion','nombre','telefono','correo','servicio','precio','como','ciudad','agenteComercial']
  tableDataSource: MatTableDataSource<any>;
  expandedElement:  any | null;
  selection = new SelectionModel<any>(true, []);
  selectionLeng = 0;
  visualizacion = 'global'
  tablaVsualizada = '1';
  agenteParaAsignar = '';
  estadoParaCambio = '';
  verModalAgendar = false;
  clienteParaAgendar: any = {};
  aggregarComentario = false;
  comentarioLead = '';

  // Funcion que filtra en la tabla los registros con datos que cincidan con lo escrito
  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  async changeTable(opcion){
    this.tablaVsualizada = opcion;
    let info = [];
    switch (this.tablaVsualizada) {
      case '0': 
        info = this.visualizacion == 'global' ? this.data : await this.data.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '1':
        info = this.visualizacion == 'global' ? this.data1 : await this.data1.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '2':
        info = this.visualizacion == 'global' ? this.data2 : await this.data2.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '3':
        info = this.visualizacion == 'global' ? this.data3 : await this.data3.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '4':
        info = this.visualizacion == 'global' ? this.data4 : await this.data4.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '5':
        info = this.visualizacion == 'global' ? this.data5 : await this.data5.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '6':
        info = this.visualizacion == 'global' ? this.data6 : await this.data6.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      case '7':
        info = this.visualizacion == 'global' ? this.data7 : await this.data7.filter(lead=>lead.agenteComercial == this.visualizacion)
        this.tableDataSource = new MatTableDataSource(info);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.selection.clear();
        break;
      default:
        console.log('opcion Invalida');
    }
  }

  desplegarIngfo(info){
    // this.prepararFormulario(info);
    this.expandedElement = this.expandedElement === info ? null : info
  }

  
  /** Seleecionamos todas las filas si no estan seleccionadas, sino las desseleccionamos */
  masterToggle() {
    this.selectionLeng = 0;
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableDataSource.data.forEach((row,index) => {
        if(index < this.tableDataSource.paginator.pageSize){
          this.selection.select(row)
          this.selectionLeng ++
        }
      });
  }

  /*validamos si las filas visulizadas son igual a las seleccionadas */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.paginator.pageSize >= this.tableDataSource.data.length ? this.tableDataSource.data.length : this.tableDataSource.paginator.pageSize;
    return numSelected === numRows;
  }


  public exportExcel() {
    this.leadsService.exportAsExcelFile(this.tableDataSource.filteredData, 'Leads');
  }

  actualizarLead(lead){
    lead.agenteComercial = this.usuarioEnSecion.displayName
    this.leadsService.updateLead(lead).then(()=>{

      if ((lead.estado == 'Plan' || lead.estado == 'Servicio unico') && lead.rd_station_id) {
        let info = {
          "rd_id": lead.rd_station_id,
          "stage": "Client"
        }
        this.servBackendService.put('api/rd-station/update-lead', {user:info}).subscribe(res => {
        })
      }

      Swal.fire({
        type: 'success',
        text: 'Éxito, se actualizo el lead exitosamente',
        showConfirmButton: true,
      });
      this.ngOnInit();
    }).catch(err=>{
      console.error('Ocurrio un erro al actualizar el lead---',err)
    })
  }

  //Eliminar lead
  public eliminarLeds() {
    if ( this.selection.selected.length > 0) {
      let confirmar = confirm('¿Estás seguro que quieres eliminar los leads seleccionados?');
      if (confirmar) {
        this.loading = true;
        this.selection.selected.forEach((element,index) => {
          console.log(element)
          if(index == (this.selection.selected.length - 1)){
            this.showToast('top-right', 'success', 'Éxito!', 'Leads eliminados!');
          }
          // this.leadsService.eliminarLeads(element.id).then(() => {
          //   if(index == (this.selection.selected.length - 1)){
          //     this.showToast('top-right', 'success', 'Éxito!', 'Lead eliminado!');
          //   }
          // })
        });
      }
    }
  }

  guardarComentario(){
    if(this.comentarioLead !== ''){
      if(this.selection.selected[0].comentarios){
        this.selection.selected[0].comentarios.push({
          fecha: moment().format('YYYY-MM-DD'),
          comentario: this.comentarioLead,
        })
      }else{
        this.selection.selected[0].comentarios = [];
        this.selection.selected[0].comentarios.push({
          fecha: moment().format('YYYY-MM-DD'),
          comentario: this.comentarioLead,
        })
      }
      this.comentarioLead = '';
      this.aggregarComentario = false;
    }
  }

  //Asignar agente a leads selecionados
  public asignarAgente(){
    let confirmar = confirm(`¿Estás seguro que quieres asignar a ${this.agenteParaAsignar} a los leds?`);
    if(confirmar){
      for(let i = 0;i < this.selection.selected.length;i++){
        let lead = this.selection.selected[i];
        lead.agenteComercial = this.agenteParaAsignar;
        lead.estado =  this.estadoParaCambio !== '' ? this.estadoParaCambio : 'Interesados';
        lead.updateInteresados = moment().format('YYYY-MM-DD');
        this.leadsService.updateLead(lead)
      }
      this.showToast('top-right', 'success', 'Éxito!', 'Leads asignado');
      this.agenteParaAsignar = '';
      this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,false);
    }
  }

  public actualizarEstado(){
    this.selection.selected.forEach((element,index) => {
      element.estado = this.estadoParaCambio;
      this.leadsService.updateLead(element).then(() => {
        if(index == (this.selection.selected.length - 1)){
          this.showToast('top-right', 'success', 'Éxito!', 'Leads asignado');
          this.estadoParaCambio = '';
          this.onCargar(this.fechaInicioGeneral,this.fechaFinGeneral,false);
        }
      })
    });
  }

  agendarLead(info){
  console.info("Info lead agendado",info)
  this.usuarioService.getUsersByProperty('email', info.correo).then(res=>{
    if(res.docs.length > 0){
      this.clienteParaAgendar = res.docs[0].data();
      this.verModalAgendar = false
      setTimeout(()=>{
        this.verModalAgendar = true
      },500)
    }else{
      this.clienteLead = info
      this.tipoModal = 'crear';
      this.verModalClientes = false;
      setTimeout(()=>{
        this.verModalClientes = true
      },500)
    }
  })
  }

  openModalMarketing(type){
    this.tipoDeMensaje = type
    this.clientsForMarketing = this.selection.selected
    this.textForSms = '';
    this.textForEmail = '';
    // @ts-ignore
    this.dialogService.open(this.mdlMarketing, {
      context: "this is some additional data passed to dialog",
    });
  }

  // excelSubido: any
  // extraerExcel(event){
  //   const excel = XLSX.readFile(
  //    this.excelSubido
  //   );
  //   var nombreHoja = excel.SheetNames; // regresa un array
  //   let datos = XLSX.utils.sheet_to_json(excel.Sheets[nombreHoja[0]]);
  //   // const jDatos = [];
  //   // for (let i = 0; i < datos.length; i++) {
  //   //   const dato = datos[i];
  //   //   jDatos.push({
  //   //     ...dato,
  //   //     Fecha: new Date((dato.Fecha - (25567 + 2)) * 86400 * 1000)
  //   //   });
  //   // }

  // };


}
