import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NbDialogService, NbToastrService, } from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { CompleterService } from "ng2-completer";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import * as _ from "lodash";
/* Servicios */
import { CandidatoService } from 'src/app/services/candidato.service';
import { Location } from '@angular/common';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-ver-candidato-entrevista',
  templateUrl: './ver-candidato.component.html',
  styleUrls: ['./ver-candidato.component.scss']
})

export class VerCandidatoEntrevistaComponent implements OnInit {

  public descuentoSeguridad: any;
  public pictureName3: any;
  public file3: any;
  public nameImage3: string = '';
  public fotoSeleccionada3: any;
  public image3: boolean = false;

  public contrato: any;
  public pictureName2: any;
  public file2: any;
  public nameImage2: string = '';
  public fotoSeleccionada2: any;
  public image2: boolean = false;
  public route: ActivatedRoute;

  public antecedentes2: any;
  public pictureName: any;
  public file: any;
  public nameImage: string = '';
  public fotoSeleccionada: any;
  public image: boolean = false;
  public aspirante: any = {};
  public zonas: any = [];
  
  profileUrl: Observable<String | null>;
  constructor(
    private router: Router,
    public completerService: CompleterService,
    public _location: Location,
    private candidatoService: CandidatoService,
    public activatedRoute: ActivatedRoute,
    private storage: AngularFireStorage
  ) {
    const foto= this.storage.ref('Aspirante/Cedula_'+123456789+'/Foto');
    this.profileUrl=foto.getDownloadURL();
    console.log(this.profileUrl);
    this.aspirante.id = this.activatedRoute.snapshot.params['id'];
  
  }

  async ngOnInit() {
    this.aspirante.id = this.activatedRoute.snapshot.params['id'];
    this.aspirante = ( await this.candidatoService.getCandidato(this.aspirante.id)).data()
    console.log(this.aspirante); 
    console.log(this.candidatoService.getFoto(123456789));
  }
  
  public goBack() {
    this._location.back();
  }
  public enviarPresentacionAspirante(){
    this.aspirante.estado = "Presentacion y prueba psicotecnica";
    this.actualizarAspirante()
  }
  public entrevistarAspirante(){
    this.aspirante.estado = "Entrevista Online";
    console.log(this.aspirante)

    this.actualizarAspirante()
  }
  public verificacionDeAspirante(){
    this.aspirante.estado = "Verificacion de aspirante";
    this.actualizarAspirante()
  }

  public induccionAspirante(){
    this.aspirante.estado = "Induccion y contrato";
    this.actualizarAspirante()
  }
  

  public activacionAspirante(){
    this.aspirante.estado = "Activacion";
    this.actualizarAspirante()
  }

  public visitaAspirante(){
    this.aspirante.estado = "Visita Domiciliaria";
    this.actualizarAspirante()
  }
public subirAntecedentes(){
  if (this.file && this.pictureName) {
    this.candidatoService.putAntecedentes(this.pictureName, this.file).then(value => {
      if (value.state == 'success') {
        this.candidatoService.getURLAntecedentes(this.pictureName).subscribe(url => {
          this.aspirante.antecedentes = url;
          console.log(this.aspirante.antecedentes)
        });
      }
    }).catch(err => {
      console.log(err);
    });
  } else { console.log("no se subio archivo de antecedentes")}
}

//Permite cargar documentos a storage
onUpload(event) {
  this.file = event.target.files[0];
  this.pictureName = Date.now() + this.file.name;
  this.image = true;
  if (event.target.files && this.file) {
    this.nameImage = this.file.name;
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
      this.fotoSeleccionada = (<FileReader>event.target).result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }
}

onUpload2(event) {
  this.file2 = event.target.files[0];
  this.pictureName2 = Date.now() + this.file2.name;
  this.image2 = true;
  if (event.target.files && this.file2) {
    this.nameImage2 = this.file2.name;
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
      this.fotoSeleccionada2 = (<FileReader>event.target).result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }
}

onUpload3(event) {
  this.file3 = event.target.files[0];
  this.pictureName3 = Date.now() + this.file3.name;
  this.image3 = true;
  if (event.target.files && this.file3) {
    this.nameImage3 = this.file3.name;
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
      this.fotoSeleccionada3 = (<FileReader>event.target).result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }
}


CancelUpload() {
  this.nameImage = '';
  this.file = undefined;
  this.image = false;
}

public actualizarAspirante2() {
  
}

public actualizarAspirante() {


  if (this.file && this.pictureName) {
    this.candidatoService.putAntecedentes(this.pictureName, this.file).then(value => {
      if (value.state == 'success') {
        this.candidatoService.getURLAntecedentes(this.pictureName).subscribe(url => {
          this.antecedentes2 = url;
          console.log(this.antecedentes2)
          this.aspirante.antecedentes2 = this.antecedentes2
//funcion edit candidato
this.candidatoService.editCandidato(this.aspirante).then((result) => {
  Swal.fire(
    'Éxito',
    'Se actualizo el aspirante exitosamente',
    'success'
  );
  this._location.back();
}).catch(err => {
  Swal.fire(
    'Error',
    'Problemas con la base de datos',
    'warning'
  );
});
//fin funcion edit candidato
 });
      };
    });}
    else if (this.file2 && this.pictureName2) {
      this.candidatoService.putContrato(this.pictureName2, this.file2).then(value => {
        if (value.state == 'success') {
          this.candidatoService.getURLContrato(this.pictureName2).subscribe(url => {
            this.contrato = url;
            console.log(this.contrato)
            this.aspirante.contrato = this.contrato
  //funcion edit candidato
  this.candidatoService.editCandidato(this.aspirante).then((result) => {
    Swal.fire(
      'Éxito',
      'Se actualizo el aspirante exitosamente',
      'success'
    );
    this._location.back();
  }).catch(err => {
    Swal.fire(
      'Error',
      'Problemas con la base de datos',
      'warning'
    );
  });
  //fin funcion edit candidato
   });
        };
      });}
      
      else if (this.file3 && this.pictureName3) {
        this.candidatoService.putDescuentoSeguridad(this.pictureName3, this.file3).then(value => {
          if (value.state == 'success') {
            this.candidatoService.getURLDescuentoSeguridad(this.pictureName3).subscribe(url => {
              this.descuentoSeguridad = url;
              console.log(this.descuentoSeguridad)
              this.aspirante.descuentoSeguridad = this.descuentoSeguridad
    //funcion edit candidato
    this.candidatoService.editCandidato(this.aspirante).then((result) => {
      Swal.fire(
        'Éxito',
        'Se actualizo el aspirante exitosamente',
        'success'
      );
      this._location.back();
    }).catch(err => {
      Swal.fire(
        'Error',
        'Problemas con la base de datos',
        'warning'
      );
    });
    //fin funcion edit candidato
     });
          };
        });} else {
//funcion edit candidato
this.candidatoService.editCandidato(this.aspirante).then((result) => {
  Swal.fire(
    'Éxito',
    'Se actualizo el aspirante exitosamente',
    'success'
  );
  this._location.back();
}).catch(err => {
  Swal.fire(
    'Error',
    'Problemas con la base de datos',
    'warning'
  );
});
//fin funcion edit candidato

    }



  } 
  public entrvistaOnline() {
    
  }

}

